import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { caseStudyFields } from '../../../../assets/content/case-study-fields.json';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormTemplateComponent } from '../form-template/form-template.component';
import { CaseStudyFormService } from 'src/app/services/case-study-form.service';
import { GlowService } from 'src/app/services/glow.service';
import { Utils } from 'src/utils';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';

@Component({
  selector: 'app-installation-form',
  templateUrl: './installation-form.component.html',
  styleUrls: ['./installation-form.component.scss'],
})
export class InstallationFormComponent implements OnInit {
  section;
  caseStudy;
  formattedCaseStudy;
  csSection = 'after';

  completionStatus: 'completed' | 'in-progress' = 'in-progress';

  installationForm: FormGroup;
  installerForm: FormGroup;
  installerFormName = 'installerForm';
  installationFormName = 'installationForm';
  installers: any[];
  installation: any;

  selectedService: any;
  subscriptionSelectedService: Subscription;
  loadObj: any;
  constructor(private fb: FormBuilder, private caseStudyFormService: CaseStudyFormService, private glowService: GlowService, private router: Router, private serviceSelectorSvc: ServiceSelectorSvc) {
    this.loadObj = this.caseStudyFormService.initLoadObj();
    this.section = this.caseStudyFormService.getSectionFromRouter();

    this.caseStudyFormService.caseStudyAnnouncer.subscribe((data) => {
      if (data) {
        this.caseStudy = data.caseStudy;
        this.formattedCaseStudy = data.formattedCaseStudy;
        this.processChanges();
      }
    });

    this.caseStudyFormService.formAnnouncer.subscribe((data) => {
      if (data && data.formName === this.installationFormName) {
        this.installationForm = data.form;
      }
      if (data && data.formName === this.installerFormName) {
        this.installerForm = data.form;
      }
    });
    this.subscriptionSelectedService = this.serviceSelectorSvc.serviceSet.subscribe((selectedService) => {
      this.selectedService = selectedService;
      this.caseStudyFormService.initServiceVariables(selectedService).then();
      this.loadObj = this.caseStudyFormService.initLoadObj();

    });
  }

  ngOnInit() {
    this.selectedService = this.serviceSelectorSvc.getSelectedService();
    if (!this.caseStudyFormService.hasInitServiceVariables) {
      this.caseStudyFormService.initServiceVariables(this.selectedService).then();
    } else {
      this.caseStudyFormService.announceLatestCaseStudy();
    }
    this.caseStudyFormService.announceForm(this.installationFormName, this.caseStudyFormService.installationForm);
    this.caseStudyFormService.announceForm(this.installerFormName, this.caseStudyFormService.installerForm);
    this.processChanges();
  }

  processChanges() {
    this.installation = this.caseStudyFormService.getHPInstallationValueFromCs();
    this.installers = this.installation && this.installation.installers;
  }

  async saveChanges(completionStatus: 'completed' | 'in-progress' = 'in-progress') {
    this.completionStatus = completionStatus;

    this.loadObj = this.caseStudyFormService.handleLoadObjLoading(this.loadObj, 'Saving changes...');
    let err, data;

    [err, data] = await Utils.promiseHandler(this.installation ? this.processExistingInstallation() : this.processNewInstallation());
    if (err) {
      this.loadObj = this.caseStudyFormService.handleLoadObjError(this.loadObj, "Couldn't save changes - please try again later.");
      return this.loadObj;
    }
    this.caseStudyFormService.setFormAndAnnounce(this.installationFormName, this.installationForm);
    this.caseStudyFormService.setFormAndAnnounce(this.installerFormName, this.installerForm);
    await this.caseStudyFormService.announceLatestCaseStudy();
    this.caseStudyFormService.markSectionAsPristine(this.section);
    this.loadObj = this.caseStudyFormService.handleLoadObjSuccess(this.loadObj, 'Changes saved successfully.');
    return this.loadObj;
  }

  async saveAndContinue() {
    this.completionStatus = 'completed';
    this.loadObj = await this.caseStudyFormService.saveAndContinue(this.saveChanges.bind(this), this.section, this.loadObj);
  }

  getInstallationData() {
    const data = { ...this.installationForm.value };
    data.installers = [...this.installerForm.value.installers];
    data.journalEntryDataType = 'installation';
    return { data };
  }

  async createInstallationJe(component) {
    const data = this.getInstallationData();
    return this.caseStudyFormService.createJournalEntry({
      journalEntryData: { ...data },
      componentDataType: component.componentDataType,
      componentVersion: component.componentVersion,
      componentId: component.componentId,
      completionStatus: this.completionStatus,
    });
  }

  async processExistingInstallation() {
    const data = this.getInstallationData();
    const updated = await this.glowService.updateJournalEntry(this.installation.jeId, data).toPromise();
  }

  async processNewInstallation() {
    let err, data;
    let hpSystem = await this.caseStudyFormService.getOrCreateHpSystem(this.completionStatus);
    [err, data] = await Utils.promiseHandler(this.createInstallationJe(hpSystem));
    await this.caseStudyFormService.addJournalEntryToCaseStudy({ journalEntry: data, caseStudy: this.caseStudy, section: 'after' });
  }

  addInstaller() {
    return this.caseStudyFormService.addGroupToFormArray('installer', this.installerForm, 'installers');
  }

  removeForm(index) {
    this.caseStudyFormService.removeForm(index, this.installerForm, 'installers');
  }
}
