
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  template: `<div class="center">
    <clr-icon shape="download" aria-label="details" (click)="onDownload()"></clr-icon>
  </div>
  `})

export class CustomDownloadButtonComponent implements OnInit {

  renderValue: string;

  @Input() value;
  @Input() rowData: any;
  @Output() download = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    this.renderValue = this.value;
  }

  onDownload() {
    // console.log('onDownload', this.rowData)
    this.download.emit(this.rowData);
  }

}