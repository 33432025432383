import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CaseStudyFormService } from 'src/app/services/case-study-form.service';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-microgenerator-form',
  templateUrl: './microgenerator-form.component.html',
  styleUrls: ['./microgenerator-form.component.scss'],
})
export class MicrogeneratorFormComponent implements OnInit {
  @Input() caseStudyFields;
  @Input() section;
  @Input() caseStudy;
  @Input() formattedCaseStudy;
  @Input() journal;

  hasMicrogeneratorFormName = 'hasMicrogeneratorPreForm';
  hasMicrogeneratorForm: FormGroup;
  microgeneratorFormName = 'microgeneratorPreForm';
  microgeneratorForm: FormGroup;

  components: any[];
  completionStatus: 'completed' | 'in-progress' = 'in-progress';

  subscriptionSelectedService: Subscription;
  csSection;
  selectedService: any;
  constructor(private fb: FormBuilder, private caseStudyFormService: CaseStudyFormService, private serviceSelectorSvc: ServiceSelectorSvc) {
    this.subscriptionSelectedService = this.serviceSelectorSvc.serviceSet.subscribe((selectedService) => {
      this.selectedService = selectedService;
    });
    this.caseStudyFormService.formAnnouncer.subscribe((data) => {
      if (data && data.formName === this.microgeneratorFormName) {
        this.microgeneratorForm = data.form;
      }
      if (data && data.formName === this.hasMicrogeneratorFormName) {
        this.hasMicrogeneratorForm = data.form;
      }
      if (this.hasMicrogeneratorForm) this.caseStudyFormService.processChangeBooleanControl('microgenerator', this.hasMicrogeneratorForm, this.microgeneratorForm, 'microgenerators', 'isTrue', this.microgeneratorFormName);
    });
  }

  ngOnInit() {
    this.selectedService = this.serviceSelectorSvc.getSelectedService();
    this.caseStudyFormService.announceForm(this.hasMicrogeneratorFormName, this.caseStudyFormService[this.hasMicrogeneratorFormName]);
    this.caseStudyFormService.announceForm(this.microgeneratorFormName, this.caseStudyFormService[this.microgeneratorFormName]);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.section && changes.section.currentValue) {
      this.csSection = this.section === 'pre-installation-heating' ? 'before' : 'after';
      this.microgeneratorFormName = this.csSection === 'before' ? 'microgeneratorPreForm' : 'microgeneratorPostForm';
      this.hasMicrogeneratorFormName = this.csSection === 'before' ? 'hasMicrogeneratorPreForm' : 'hasMicrogeneratorPostForm';
      if (this.formattedCaseStudy) {
        this.components = this.formattedCaseStudy[this.csSection].microgenerator;
      }
    }
    if (changes.formattedCaseStudy && changes.formattedCaseStudy.currentValue) {
      this.components = this.formattedCaseStudy[this.csSection].microgenerator;
    }
  }

  ngOnDestroy() {
    if (this.subscriptionSelectedService) this.subscriptionSelectedService.unsubscribe();
  }

  async saveChanges(completionStatus: 'completed' | 'in-progress' = 'in-progress') {
    this.completionStatus = completionStatus;

    let err, data;
    [err, data] = await Utils.promiseHandler(this.caseStudyFormService.processDeletedComponents(this.components, this.microgeneratorForm, 'microgenerators', this.csSection));
    if (this.microgeneratorForm) {
      for (let form of this.microgeneratorForm.controls.microgenerators['controls']) {
        const existingComponent = this.components && this.components.find((component) => component.componentId === form.metadata.componentId && component.componentVersion === form.metadata.componentVersion);
        if (existingComponent) {
          [err, data] = await Utils.promiseHandler(this.processExistingMicrogenerator(form.value, existingComponent));
        } else {
          [err, data] = await Utils.promiseHandler(this.processNewMicrogenerator(form.value));
        }
        if (err) throw err;
      }
    }
    this.caseStudyFormService.setFormAndAnnounce(this.microgeneratorFormName, this.microgeneratorForm);
  }

  async processExistingMicrogenerator(formValue, existingComponent) {
    const componentDataType = 'microgenerator';
    const component = await this.caseStudyFormService.updateComponent({
      formValue,
      componentDataType,
      componentVersion: existingComponent.componentVersion,
      componentId: existingComponent.componentId,
      fieldsToDelete: ['installedAt'],
      fieldsToAdd: ['installedAt'],
    });
    const descriptionJe = await this.caseStudyFormService.updateDescriptionJournalEntry({
      formValue,
      existingTextDescription: existingComponent.text_description,
    });
  }

  async processNewMicrogenerator(formValue) {
    const componentDataType = 'microgenerator';
    const component = await this.caseStudyFormService.createComponent({ formValue, componentDataType, componentVersion: 1, fieldsToDelete: ['installedAt'], fieldsToAdd: ['installedAt'], completionStatus: this.completionStatus });
    const descriptionJe = await this.caseStudyFormService.createDescriptionJournalEntry({
      formValue,
      componentId: component.componentId,
      componentDataType,
      componentVersion: component.componentVersion,
      completionStatus: this.completionStatus,
    });
    await this.caseStudyFormService.addJournalEntryToCaseStudy({ caseStudy: this.caseStudy, journalEntry: descriptionJe, section: this.csSection });
  }

  addForm() {
    return this.caseStudyFormService.addGroupToFormArray('microgenerator', this.microgeneratorForm, 'microgenerators');
  }

  removeForm(index) {
    // const form = this.microgeneratorForm.controls.microgenerators['controls'][index];
    this.caseStudyFormService.removeForm(index, this.microgeneratorForm, 'microgenerators');
  }
}
