import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FaqsService } from 'src/app/services/faqs.service';
import { SeoService } from 'src/app/services/seo.service';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-faqs-topic',
  templateUrl: './faqs-topic.component.html',
  styleUrls: ['./faqs-topic.component.scss'],
})
export class FaqsTopicComponent implements OnInit {
  isSearching: boolean = false;
  topic!: any;
  url!: Array<string>;
  loading: boolean = true;
  topics!:any
  constructor(
    private route: ActivatedRoute,
    private faqsService: FaqsService,
    private seoService: SeoService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getUrl();
    this.getFAQsTopics()
    this.route.params.subscribe((params) => {
      this.getTopic(params.topic).then((topic) => {
        this.topic = topic;
        this.loading = false;
      });
    });
    // this.faqsService.getFAQsTopic(this.faqsService.topicId).then((topic) => {
    //   this.topic = topic;
    // });
  }

  ngOnDestroy() {
    this.seoService.resetSEOconfig();
  }

  async getFAQsTopics() {
    this.topics = await this.faqsService.getFAQsTopics()
  }

  topicRelativePath(topic){
    return `/support/${topic.attributes.slug}`
  }

  getOtherTopics(){
    return this.topics.filter(t=>t.attributes.name!=this.topic.attributes.name)
  }

  getUrl() {
    this.url = this.route.snapshot.url.map((segment) => segment.path);
  }
  updateIsSearching(isSearching) {
    this.isSearching = isSearching;
  }

  async getTopic(slug) {
    const topic = await this.faqsService.getFAQsTopicBySlug(slug);
    if (topic) {
      this.seoService.configureSEO(
        Utils.removeHTMLFromString(topic.attributes.name),
        Utils.removeHTMLFromString(topic.attributes.description),
        `https://glowmarkt.com${this.router.url}`
      );
    }
    return topic;
  }
}
