
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  template: `<div class='center'>
    <clr-icon shape="pop-out" aria-label="details" (click)="onView()" style="margin-right: 10px"></clr-icon>
    <clr-icon shape="trash" aria-label="details" (click)="onRemove()" style="margin-right: 10px"></clr-icon>
    <clr-icon shape="plus-circle" aria-label="details" (click)="onAdd()"></clr-icon>
  </div>`,
})

export class CustomGroupTagButtonsComponent implements OnInit {

  renderValue: string;

  @Input() value;
  @Input() rowData: any;
  @Output() remove = new EventEmitter<any>();
  @Output() view = new EventEmitter<any>();
  @Output() add = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    this.renderValue = this.value;
  }

  onRemove() {
    // console.log('onRemove', this.rowData)
    this.remove.emit(this.rowData);
  }

  onView() {
    // console.log('onView', this.rowData)
    this.view.emit(this.rowData);
  }

  onAdd() {
    // console.log('onAdd', this.rowData)
    this.add.emit(this.rowData);
  }
}