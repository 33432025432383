import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { tLoadingElement } from 'src/app/services/app-config.typings';
import { CustomUIConfigService } from 'src/app/services/custom-ui-config.service';

@Component({
  selector: 'app-response-modal',
  templateUrl: './response-modal.component.html',
  styleUrls: ['./response-modal.component.scss'],
  host: { class: 'd-block' },
})

export class ResponseModalComponent implements OnInit {

  @Input() resources: any;
  // @Output() updateHtcCard: EventEmitter<any> = new EventEmitter();

  loadObj: tLoadingElement;
  showSuccess: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private uiConfigService: CustomUIConfigService,
  ) {


  }

  ngOnInit() {
    this.loadObj = this.uiConfigService.initialiseLoadingBooleans(true);

    if (this.resources && this.resources.length > 0) {

      this.loadObj.loadError = this.resources.filter(x => x.valid == false).length > 0 ? true : false
      this.showSuccess = this.resources.filter(x => x.valid == false).length > 0 ? false : true

    }

  }


  close() {
    this.activeModal.dismiss()
  }



}