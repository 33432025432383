import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { tLoadingElementWithSuccess } from 'src/app/services/app-config.typings';
import { CustomUIConfigService } from 'src/app/services/custom-ui-config.service';
import { GlowEventService } from 'src/app/services/event.service';
import { GlowService } from 'src/app/services/glow.service';
import { tLoginInfo } from 'src/app/services/glow.typings';
import { FormValidator } from 'src/app/services/user-validators';
import { UserGroupSelectorService } from 'src/app/services/usergroup-selector.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  formInfo: FormGroup;
  loadObj: tLoadingElementWithSuccess;
  viewPassword: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: GlowService,
    private glowEventService: GlowEventService,
    private router: Router,
    private uiConfigService: CustomUIConfigService,
    private userGroupSelectorService: UserGroupSelectorService
  ) {
    this.loadObj = this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false);

    this.formInfo = this.formBuilder.group({
      username: ['', [Validators.required, FormValidator.isUsernameValid]],
      token: ['', Validators.required],
      password: ['', [Validators.required, FormValidator.isPasswordStrong]],
      confirmPassword: ['', [Validators.required, FormValidator.isPasswordSame]],
    });
  }

  togglePassword(viewPassword) {
    this.viewPassword = viewPassword ? false : true;
  }

  submitForm() {
    // console.log('Password Reset attempt');
    this.loadObj = this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(true);

    var resetPasswordBody = {
      username: this.formInfo.value.username,
      token: this.formInfo.value.token,
      newPassword: this.formInfo.value.password,
    };
    this.authService.resetPassword(resetPasswordBody).subscribe(
      (response) => {
        this.loadObj.loading = false;

        if (response.valid === true) {
          this.loadObj.loaded = true;
          // this.loadObj.loadedMessage = 'Your password was successfully reset';
          this.doLogin();
        } else {
          this.loadObj.loadError = true;
          this.loadObj.errorString = 'An error has occurred';
        }
      },
      (err) => {
        console.warn(err);
        let errMsg = 'An error has occurred';
        try {
          const errJson = err.json();
          if (errJson.error) {
            errMsg = errJson.error;
          } else if (errJson.valid === false) {
            errMsg = 'Invalid username or password';
          }
        } catch (e) {
          console.warn(e);
        }
        this.loadObj.loading = false;
        this.loadObj.loadError = true;
        this.loadObj.errorString = errMsg;
      }
    );
  }

  // Attempt to login in through our User service
  doLogin() {
    this.loadObj = this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(true);

    // console.log('Login attempt');
    let loginDetails = this.sanitiseDetails({ ...this.formInfo.value });
    this.authService.login(loginDetails).subscribe(
      (authResponse) => {
        this.loadObj.loading = false;

        if (authResponse === true) {
          this.loadObj.loaded = true;
          // this.loadObj.loadedMessage = 'Success';
          this.glowEventService.sendEvent({}, 'login');
          this.authService.getUserOrganizationsOfUser().subscribe(
            (userOrganizationGroups) => {
              if (
                userOrganizationGroups &&
                Array.isArray(userOrganizationGroups) &&
                userOrganizationGroups.length > 1
              ) {
                this.router.navigateByUrl('/pages/usergroup');
              } else if (userOrganizationGroups.length == 1) {
                this.userGroupSelectorService.setUserOrganization(userOrganizationGroups[0]);
                this.router.navigateByUrl('/pages/overview');
              } else {
                console.warn('No user Organizations found');
                this.router.navigateByUrl(`/login?email=${this.formInfo.value.username}`);
             
              }
            },
            (error) => {
              console.warn(error);
              // console.log('User Organizations loading error is : ' + error);
            }
          );
        } else {
          this.loadObj.loadError = true;
          this.loadObj.errorString = 'Access Denied';
        }
      },
      (err) => {
        console.warn(err);
        let errMsg = 'An error has occurred';
        try {
          const errJson = err.json();
          if (errJson.error) {
            errMsg = errJson.error;
          } else if (errJson.valid === false) {
            errMsg = 'Invalid username or password';
          }
        } catch (e) {
          console.warn(e);
        }
        this.loadObj.loading = false;
        this.loadObj.loadError = true;
        this.loadObj.errorString = errMsg;
      }
    );
  }

  sanitiseDetails(loginDetailsForm: tLoginInfo): tLoginInfo {
    // Lowercase Username:
    if (loginDetailsForm && loginDetailsForm.username) {
      loginDetailsForm.username = loginDetailsForm.username.toLowerCase();
      loginDetailsForm.username = loginDetailsForm.username.replace(/\s+/g, '');
    }

    if (loginDetailsForm.password) {
      loginDetailsForm.password = loginDetailsForm.password.replace(/\s+$/, ''); // Trailing spaces
      loginDetailsForm.password = loginDetailsForm.password.replace(/^\s+/g, ''); // Spaces at the start of the string
    }

    return loginDetailsForm;
  }
}
