import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnergyDataComponent } from './energy-data.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { DashboardModule } from '../../components/dashboard/dashboard.module';

@NgModule({
  declarations: [EnergyDataComponent],
  imports: [SharedModule, CommonModule, DashboardModule],
  exports: [EnergyDataComponent]
})

export class EnergyDataModule { }