import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadiatorSchematicComponent } from './radiator-schematic.component';
import { AddHeatingSystemModalModule } from '../add-heating-system-modal/add-heating-system-modal.module';

@NgModule({
  declarations: [RadiatorSchematicComponent],
  imports: [CommonModule, AddHeatingSystemModalModule],
  exports: [RadiatorSchematicComponent],
  entryComponents: [RadiatorSchematicComponent],
})
export class RadiatorSchematicModule {}
