import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { FaqsService } from 'src/app/services/faqs.service';
import { SeoService } from 'src/app/services/seo.service';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-faq-detailed',
  templateUrl: './faq-detailed.component.html',
  styleUrls: ['./faq-detailed.component.scss'],
})
export class FaqDetailedComponent implements OnInit {
  url!: Array<string>;
  faq:any
  loading:boolean=true
  constructor(private route: ActivatedRoute, private faqsService: FaqsService, private seoService: SeoService, private router:Router) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.getFAQ(params.faq).then(faq=>{this.faq=faq;this.loading=false})
      this.getUrl();
    });
  }

  ngOnDestroy(){
    this.seoService.resetSEOconfig()
  }

  getUrl() {
    // this.url = this.route.snapshot.url.map(
    //   (segment) => segment.path.charAt(0).toLowerCase() + segment.path.slice(1)
    // );
    let url = this.route.snapshot.url.map((segment) => segment.path);
    let question = url[url.length - 1] + '?';
    url.pop();
    url.push(question);
    this.url = url;
  }

  async getFAQ(slug) {
    const faq = await this.faqsService.getFAQBySlug(slug);
    if(faq){
      this.seoService.configureSEO(
        Utils.removeHTMLFromString(faq.attributes.question),
        Utils.removeHTMLFromString(faq.attributes.question),
        `https://glowmarkt.com${this.router.url}`
      );
    }
    return faq
  }
}
