/**
 * Service responsible for dealing with date formats
 */

import { Injectable } from '@angular/core';
import * as d3TimeFormat from 'd3-time-format';

@Injectable()
export class DateFormatService {

    public apiDMYFormat(dateToFormat): string {
        var apiTimeFormatD3 = d3TimeFormat.timeFormat("%d/%m/%Y")
        return apiTimeFormatD3(dateToFormat)
    }

    public apiYMDFormat(dateToFormat): string {
        var apiTimeFormatD3 = d3TimeFormat.timeFormat("%Y-%m-%d")
        return apiTimeFormatD3(dateToFormat)
    }

    public apiTimeFormat(dateToFormat): string {
        const apiTimeFormatD3 = d3TimeFormat.timeFormat('%Y-%m-%dT%H:%M:%S');
        return apiTimeFormatD3(dateToFormat);
    }

    public apiTimeFromFormat(dateToFormat, fromTime = '00:00:00'): string {
        const apiTimeFromFormatD3 = d3TimeFormat.timeFormat('%Y-%m-%dT' + fromTime);
        return apiTimeFromFormatD3(dateToFormat);
    }

    public apiTimeToFormat(dateToFormat, toTime = '23:59:59'): string {
        const apiTimeToFormatD3 = d3TimeFormat.timeFormat('%Y-%m-%dT' + toTime);
        return apiTimeToFormatD3(dateToFormat);
    }

    public getPreviousDate(dateToFormat, numberOfDaysToSlideInPast, fromTime = '00:00:00'): string {
        // step 1 find weekday 0 os sunday
        // if given date is sunday we need to roll over one week further back
        let slider = numberOfDaysToSlideInPast
        const dayInPast = new Date(dateToFormat.getFullYear(), dateToFormat.getMonth(), dateToFormat.getDate() - slider, 0, 0, 0);
        return this.apiTimeFromFormat(dayInPast, fromTime);
    }

    public getStartOfWeek(dateToFormat): string {
        // step 1 find weekday 0 os sunday
        // if given date is sunday we need to roll over one week further back
        let slider;
        if (dateToFormat.getDay() === 0) {
            slider = - 7 + 1;
        } else {
            slider = - dateToFormat.getDay() + 1;
        }
        const startOfWeek = new Date(dateToFormat.getFullYear(), dateToFormat.getMonth(), dateToFormat.getDate() + slider, 0, 0, 0);
        return this.apiTimeFromFormat(startOfWeek);
    }

    public getEndOfWeek(dateToFormat): string {
        // step 1 find weekday 0 os sunday
        // +6 if we want week to end on staturday 23:59:59
        let slider;
        if (dateToFormat.getDay() === 0) {
            slider = 0;
        } else {
            slider = 7 - dateToFormat.getDay(); // +1 because start of week should be Monday
        }
        const endOfWeek = new Date(dateToFormat.getFullYear(), dateToFormat.getMonth(), dateToFormat.getDate() + slider, 23, 59, 59);

        return this.apiTimeToFormat(endOfWeek);
    }

    public getStartOfLastSevenDays(dateToFormat): string {
        const today = new Date();
        const startOflastSevenDays = new Date(dateToFormat.getFullYear(), dateToFormat.getMonth(), dateToFormat.getDate() - 7, 0, 0, 0);
        return this.apiTimeFromFormat(startOflastSevenDays);
    }

    public getEndOfLastSevenDays(dateToFormat): string {
        // step 1 find weekday 0 os sunday
        // +6 if we want week to end on staturday 23:59:59
        const today = new Date();
        const endOflastSevenDays = new Date(dateToFormat.getFullYear(), dateToFormat.getMonth(), dateToFormat.getDate() - 1, 0, 0, 0);

        return this.apiTimeToFormat(endOflastSevenDays);
    }

    public getStartOfMonth(dateToFormat): string {
        const startOfMonth = new Date(dateToFormat.getFullYear(), dateToFormat.getMonth(), 1, 0, 0, 0);
        const getStartOfMonth = d3TimeFormat.timeFormat('%Y-%m-01T00:00:00');
        return getStartOfMonth(startOfMonth);
    }

    public getEndOfMonth(dateToFormat): string {
        let lastMonthDay;
        const findMonth = d3TimeFormat.timeFormat('%m');
        const month = findMonth(dateToFormat);
        if (['01', '03', '05', '07', '08', '10', '12'].indexOf(month) > -1) {
            lastMonthDay = 31;
        } else if (['04', '06', '09', '11'].indexOf(month) > -1) {
            lastMonthDay = 30;
        } else {
            const findYear = d3TimeFormat.timeFormat('%Y');
            const year = findYear(dateToFormat);
            if (year % 4 === 0) {
                lastMonthDay = 29;
            } else {
                lastMonthDay = 28;
            }
        }
        const lastMonthDate = new Date(dateToFormat.getFullYear(), dateToFormat.getMonth(), lastMonthDay, 0, 0, 0);
        const getEndOfMonth = d3TimeFormat.timeFormat('%Y-%m-' + lastMonthDay + 'T23:59:59');
        return getEndOfMonth(lastMonthDate);
    }

    // This function moves the start of the month to the start of the first week of the month. It is important for weekly aggregation graphs
    public getStartOfMonthByFullWeek(dateToFormat): string {
        const startOfMonth = new Date(dateToFormat.getFullYear(), dateToFormat.getMonth(), 1, 0, 0, 0);

        // if the first of the month is not a Monday we need to find the closest preceding Monday.
        if (startOfMonth.getDate() === 1) {
            return this.getStartOfWeek(startOfMonth);
        } else {
            const getStartOfMonth = d3TimeFormat.timeFormat('%Y-%m-01T00:00:00');
            return getStartOfMonth(startOfMonth);
        }
    }

    // This function moves the end of the month to the end of the last week of the month. It is important for weekly aggregation graphs
    public getEndOfMonthByFullWeek(dateToFormat): string {
        let lastMonthDay;
        const findMonth = d3TimeFormat.timeFormat('%m');
        const month = findMonth(dateToFormat);
        if (['01', '03', '05', '07', '08', '10', '12'].indexOf(month) > -1) {
            lastMonthDay = 31;
        } else if (['04', '06', '09', '11'].indexOf(month) > -1) {
            lastMonthDay = 30;
        } else {
            const findYear = d3TimeFormat.timeFormat('%Y');
            const year = findYear(dateToFormat);
            if (year % 4 === 0) {
                lastMonthDay = 29;
            } else {
                lastMonthDay = 28;
            }
        }
        const lastMonthDate = new Date(dateToFormat.getFullYear(), dateToFormat.getMonth(), lastMonthDay, 0, 0, 0);
        if (lastMonthDate.getDate() !== 0) {
            return this.getEndOfWeek(lastMonthDate);
        } else {
            const getEndOfMonth = d3TimeFormat.timeFormat('%Y-%m-' + lastMonthDay + 'T23:59:59');
            return getEndOfMonth(lastMonthDate);
        }
    }

    public getStartOfYear(dateToFormat): string {
        const getStartOfYear = d3TimeFormat.timeFormat('%Y-01-01T00:00:00');
        return getStartOfYear(dateToFormat);
    }

    public getEndOfYear(dateToFormat): string {
        const getEndOfYear = d3TimeFormat.timeFormat('%Y-12-31T23:59:59');
        return getEndOfYear(dateToFormat);
    }

    public fullDateFormat(dateToFormat): string {
        const timestampFormatD3 = d3TimeFormat.timeFormat('%A %d %B %Y at %H:%M:%S');
        return timestampFormatD3(dateToFormat);
    }

    public fullDateFormatNoSeconds(dateToFormat): string {
        const timestampFormatD3 = d3TimeFormat.timeFormat('%A %d %B %Y at %H:%M');
        return timestampFormatD3(dateToFormat);
    }
    public fullDateFormatNoMinutesNoSeconds(dateToFormat){
        const timestampFormatD3 = d3TimeFormat.timeFormat('%A %d %B %Y at %H');
        return timestampFormatD3(dateToFormat);

    }
    public getHourFormat(dateToFormat): string {
        const getHourFormatD3 = d3TimeFormat.timeFormat('%H:%M');
        return getHourFormatD3(dateToFormat);
    }

    public getYearMonthDay(dateToFormat): string {
        const getYearMonthDayD3 = d3TimeFormat.timeFormat('%Y-%m-%d');
        return getYearMonthDayD3(dateToFormat);
    }

    public getWeekDay(wd: number, stringFormat = "SHORT"): string {
        const weekdays = [{ "SHORT": "Sun", "LONG": "Sunday" }, { "SHORT": "Mon", "LONG": "Monday" }, { "SHORT": "Tue", "LONG": "Tuesday" }, { "SHORT": "Wed", "LONG": "Wednesday" }, { "SHORT": "Thu", "LONG": "Thursday" }, { "SHORT": "Fri", "LONG": "Friday" }, { "SHORT": "Sat", "LONG": "Saturday" }]
        let weekdayStr
        if (wd <= weekdays.length - 1) {
            weekdayStr = weekdays[wd][stringFormat]
        }
        return weekdayStr
    }

    public goToWeekday(currentDay, requiredWeekdayId): Date {
        let slider: number;
        let currentWeekdayId = currentDay.getDay()

        // Step 1. Define slider

        if (requiredWeekdayId == currentWeekdayId) {
            slider = 0;
        } else if (requiredWeekdayId < currentWeekdayId) {
            slider = - (currentWeekdayId - requiredWeekdayId);
        } else {
            slider = (- currentWeekdayId - 7 + requiredWeekdayId);
        }
        // Step 2. Calculate date displayed depending on slider
        return new Date(currentDay.getFullYear(), currentDay.getMonth(), currentDay.getDate() + slider, 0, 0, 0);
    };


    public getDateTextLabelMonth(dateDisplayed): string {
        const displayFormatD3 = d3TimeFormat.timeFormat("%B %Y")
        return displayFormatD3(dateDisplayed);
    }

    public getDateTextLabelYear(dateDisplayed): string {
        const displayFormatD3 = d3TimeFormat.timeFormat("%Y")
        return displayFormatD3(dateDisplayed);
    }

    public slideInTime(timeDirection, range, dateDisplayed): Date {
        let slider: number;
        // Step 1. Define slider
        if (timeDirection === 'past') {
            slider = -1;
        } else {
            slider = 1;
        }
        // Step 2. Calculate date displayed depending on slider and time direction
        if (range === 'day') {
            dateDisplayed = new Date(dateDisplayed.getFullYear(), dateDisplayed.getMonth(), dateDisplayed.getDate() + slider, 0, 0, 0)
        } else if (range === '6hours') {
            dateDisplayed = new Date(dateDisplayed.getFullYear(), dateDisplayed.getMonth(), dateDisplayed.getDate(), dateDisplayed.getHours() + 6 * slider, 0, 0)
        } else if (range === 'week') {
            // for day we have to deal with range which is quite different we will nedd to calculate the range in anotheer function
            dateDisplayed = new Date(dateDisplayed.getFullYear(), dateDisplayed.getMonth(), dateDisplayed.getDate() + slider * 7, 0, 0, 0)

        } else if (range === 'month') {
            dateDisplayed = new Date(dateDisplayed.getFullYear(), dateDisplayed.getMonth() + slider, dateDisplayed.getDate(), 0, 0, 0)

        } else {
            //year
            dateDisplayed = new Date(dateDisplayed.getFullYear() + slider, dateDisplayed.getMonth(), dateDisplayed.getDate(), 0, 0, 0)
        }

        return dateDisplayed;

    };

    public getDateTextLabelWeekInYear(dateDisplayed): string {
        const displayFormatD3 = d3TimeFormat.timeFormat("%V")
        return displayFormatD3(dateDisplayed);
    }

    public getDateTextLabelDay(dateDisplayed): string {
        const displayFormatD3 = d3TimeFormat.timeFormat("%a %e %b")
        return displayFormatD3(dateDisplayed);
    }

    public getStartOfMonthForYearTs(dateDisplayed: Date): number[] {
        let timestampsOfYearPerMonth = []
        const year = dateDisplayed.getFullYear()
        for (let i = 0; i < 12; i++) {
            timestampsOfYearPerMonth.push(new Date(year, i, 1, 0, 0, 0).getTime() / 1000)
        }
        return timestampsOfYearPerMonth;
    }

    // This is required for iOS
    public convertToDate(string) {
        return new Date(string);;
    }
}
