import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { any } from 'core-js/fn/promise';
import { Subscription } from 'rxjs';
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component';
import { RadiatorSchematicComponent } from 'src/app/components/radiator-schematic/radiator-schematic.component';
import { GlowService } from 'src/app/services/glow.service';
import { InvitationsService } from 'src/app/services/invitations.service';
import { PdfService } from 'src/app/services/pdf.service';
import { SchematicsService } from 'src/app/services/schematics.service';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-received-invitations',
  templateUrl: './received-invitations.component.html',
  styleUrls: ['./received-invitations.component.scss']
})
export class ReceivedInvitationsComponent implements OnInit {
  pageTitle: string = 'Received invitations'
  estimatesSubscription: Subscription
  estimateRequests
  loading
  dataURL
  stageSubscription: Subscription;
  showSchematic: boolean
  schematics;
  schematicDocument
  schmLoading: boolean
  snapshot
  constructor(private invitationsService: InvitationsService, private glowService: GlowService, private pdfService: PdfService, private modalService: NgbModal,
    private schematicsService: SchematicsService, private changeDetector: ChangeDetectorRef) {
    this.estimatesSubscription = this.invitationsService.EstimatesAnnouncer.subscribe((resp) => {
      this.estimateRequests = resp.estimateRequests.filter(e => e.initiatedBy === 'professional')
      if (this.estimateRequests) {
        // console.log(this.estimateRequests)
        this.loading = false
      }
    })
    this.loading = false

    this.stageSubscription = this.schematicsService.stage$.subscribe((schm) => {
      const dataURL = this.schematicsService.getCanvasDataUrl(2)
      this.pdfService.generatePdf(this.snapshot, dataURL)
      this.showSchematic = false


    })

  }

  ngOnInit() {

    this.invitationsService.getAllEstimateRequests()
  }
  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    if (this.estimatesSubscription) {
      this.estimatesSubscription.unsubscribe()
    }
  }
  getReadableDateFromIso(string) {
    return Utils.getReadableDateFromIso(string)
  }

  async getSchematic(veId, veDocId) {
    this.schematicDocument = await this.schematicsService.getSchematic(veId, veDocId)
    this.showSchematic = true


  }
  async downloadPDF(id) {
    this.glowService.getSnapshotBySnapshotId(id).subscribe(async resp => {
      if (resp) {
        this.snapshot = resp
        if (this.snapshot.heatingSystem) {
          await this.getSchematic(this.snapshot.veId, this.snapshot.heatingSystem.veDocId)
        } else this.pdfService.generatePdf(this.snapshot)
      }
      return resp
    })
  }

  openConfirmationModal(estimateRequestId) {
    const modalReference = this.modalService.open(ConfirmationModalComponent, { centered: true, size: 'lg' });
    modalReference.componentInstance.confirmationTitle = 'Are you sure you want to revoke access?';
    modalReference.componentInstance.confirmationText = 'By revoking access, the installer will no longer be able to view your data through Glowmarkt or send you an estimate request.'
    modalReference.componentInstance.confirmationButtonText = 'Revoke access';
    modalReference.componentInstance.estimateRequestId = estimateRequestId
  }
}
