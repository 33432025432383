
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';

import { tContentWithLocalIndicator, tPromptWithLocalIndicator } from './content.typings';
import { ConfigService } from './app-config.service';
import { GlowService } from './glow.service';
import { tContent, tPromptUser, tPrompt, tPromptUserReq } from './glow.typings';
import { tPromptReferenceMappingElem } from './ui.typings';


@Injectable()
export class CustomTranslateLoader implements TranslateLoader {

    constructor(
        private http: HttpClient,
        private glowService: GlowService) { }

    getTranslation(lang: string): Observable<any> {
        // console.log("getTranslation")

        const configuration = {content: {languageContent:{defaultLanguageCode: "en-GB", reference: "GlowmarktWebLanguageContent", version: "1.0.0"}}}
        if (!lang) {
            lang = configuration.content.languageContent.defaultLanguageCode
            console.warn("Defaulting to " + lang)
        }
        const reference = configuration.content.languageContent.reference
        const version = configuration.content.languageContent.version
        // if (this.glowService.isLoggedIn()) {
        //     return Observable.create(observer => {
        //         this.glowService.getLanguageContent(reference, lang).subscribe((contentList) => {
        //             let content;
        //             let selectedLanguageContent = contentList.find(lc => lc.version === version)
        //             if (!selectedLanguageContent && contentList && Array.isArray(contentList) && contentList.length > 0) {
        //                 console.warn(`Language Version ${version}, not found default to first one in array ${contentList[0].languageContentId}`)
        //                 selectedLanguageContent = contentList[0]
        //             }
        //             // console.log("selectedLanguageContent")

        //             // console.log(selectedLanguageContent)
        //             if (selectedLanguageContent) {
        //                 content = selectedLanguageContent.data
        //                 // console.log("Language Content received via API")
        //             }
        //             if (content) {
        //                 observer.next(content);
        //                 observer.complete();
        //             } else {
        //                 this.http.get("/assets/i18n/" + lang + ".json").subscribe((res: Response) => {
        //                     // console.log("Content received from assets")
        //                     observer.next(res);
        //                     observer.complete();
        //                 }, err => {
        //                     console.warn(err)
        //                 })
        //             }

        //         }, err => {
        //             console.warn(err)
        //             this.http.get("/assets/i18n/" + lang + ".json").subscribe((res: Response) => {
        //                 // console.log("Content received from assets")
        //                 observer.next(res);
        //                 observer.complete();
        //             }, err => {
        //                 console.warn(err)
        //             })
        //         });
        //     });
        // } else {
            return Observable.create(observer => {
                this.http.get("/assets/i18n/" + lang + ".json").subscribe((res: Response) => {
                    // console.log("Content received from assets")
                    observer.next(res);
                    observer.complete();
                }, err => {
                    console.warn(err)
                })
            });
        }

    // }
}

@Injectable()
export class ContentLoaderService {

    private ContentAnnouncer = new Subject<tContentWithLocalIndicator>();

    content$ = this.ContentAnnouncer.asObservable();

    constructor(
        private http: HttpClient,
        private glowService: GlowService) {

    }

    announceContent(content: tContentWithLocalIndicator) {
        // console.log("announcing readings")
        this.ContentAnnouncer.next(content);
    }

    getContent(reference: string) {
        // console.log("getContent: " + reference)
        this.glowService.getContent(reference).subscribe((contentList) => {
            // console.log("Content received via API")
            if (contentList && Array.isArray(contentList) && contentList.length > 0) {
                if (contentList[0].hasOwnProperty("data") && contentList[0].data) {
                    let content = contentList[0]
                    const contentWithLocalIndicator: tContentWithLocalIndicator = { ...content, ...{ isLocal: false } };
                    this.announceContent(contentWithLocalIndicator)
                }
            }
            console.warn(`No content found ${reference}, Getting default content`)
            this.getContentLocally(reference)
        }, err => {
            console.warn(`Unable to get content ${reference}, Getting default content`)
            console.warn(err)
            this.getContentLocally(reference)
        });
    }

    getContentLocally(reference: string) {
        this.http.get<tContent>(`/assets/content/${reference}.json`).subscribe((content) => {
            // console.log("Content received from assets")
            const contentWithLocalIndicator: tContentWithLocalIndicator = { ...content, ...{ isLocal: true } };
            this.announceContent(contentWithLocalIndicator)
        }, err => {
            console.warn(err)
        })
    }

    // *PROMISE METHODS
    async getContentAsync(reference: string): Promise<tContentWithLocalIndicator> {
        const contentList = await this.glowService.getContent(reference).toPromise()
        if (contentList && Array.isArray(contentList) && contentList.length > 0) {
            if (contentList[0].hasOwnProperty("data") && contentList[0].data) {
                const prompt = contentList[0]
                return { ...prompt, ...{ isLocal: false } };
            }
        }
        console.warn(`No content found ${reference}, Getting default content`)
        return this.getContentLocallyAsync(reference)
    }

    async getContentLocallyAsync(reference: string): Promise<tContentWithLocalIndicator> {
        const content = await this.http.get<tContent>(`/assets/content/${reference}.json`).toPromise()
        if (content) {
            return { ...content, ...{ isLocal: true } }
        }
        return
    }
}


@Injectable()
export class PromptService {

    private PromptAnnouncer = new Subject<tPrompt>();
    private PromptUserAnnouncer = new Subject<tPromptUser>();

    prompt$ = this.PromptAnnouncer.asObservable();
    promptUser$ = this.PromptUserAnnouncer.asObservable();

    constructor(
        private http: HttpClient,
        private glowService: GlowService) {

    }
    // *OBSERVABLE METHODS
    announcePrompt(prompt: tPromptWithLocalIndicator) {
        // console.log("announcing prompts")
        this.PromptAnnouncer.next(prompt);
    }

    announcePromptUser(promptUser: tPromptUser) {
        // console.log("announcing promptUser")
        this.PromptUserAnnouncer.next(promptUser);
    }

    getPrompt(reference: string) {
        // console.log("getPrompt: " + reference)
        this.glowService.getPrompts(reference).subscribe((promptList) => {
            if (promptList && Array.isArray(promptList) && promptList.length > 0) {
                // console.log("Prompt received via API")
                if (promptList[0].hasOwnProperty("data") && promptList[0].data) {
                    const prompt = promptList[0]
                    const promptWithLocalIndicator: tPromptWithLocalIndicator = { ...prompt, ...{ isLocal: false } };
                    this.announcePrompt(promptWithLocalIndicator)
                }
            } else {
                // console.log("No Prompt found will look locally")
                this.getPromptLocally(reference)
            }
        }, err => {
            console.warn("Unable to get content " + reference)
            console.warn(err)
            // console.log("Getting default content")
            this.getPromptLocally(reference)
        });
    }

    getPromptLocally(reference: string) {
        this.http.get<tPrompt>("/assets/prompts/" + reference + ".json").subscribe((prompt) => {
            // console.log("Prompt received from assets")
            const promptWithLocalIndicator: tPromptWithLocalIndicator = { ...prompt, ...{ isLocal: true } };
            this.announcePrompt(promptWithLocalIndicator)
        })
    }

    getPromptUser(promptInfo: tPromptReferenceMappingElem) {
        // console.log("getPromptUser: " + promptInfo.reference)
        this.glowService.getPromptUser(promptInfo.reference).subscribe((promptUserList) => {
            // console.log("PromptUser received via API")
            // console.log(promptUserList)
            if (promptUserList && Array.isArray(promptUserList) && promptUserList.length > 0) {
                for (let i = 0; i < promptUserList.length; i++) {
                    if (promptUserList[i] && promptUserList[i].promptId == promptInfo.promptId) {
                        this.announcePromptUser(promptUserList[i])
                        // console.log("Valid PromptUser found")
                        break;
                    }
                }
            }
        }, err => {
            console.warn("Unable to get prompt user " + promptInfo.reference)
            console.warn(err)
            // console.log("Getting default prompt user ")
        });
    }

    postPromptUser(promptReply: tPromptUserReq) {
        this.glowService.sendPromptReply(promptReply).subscribe((promptUserRes) => {
            // console.log("PromptUser send")
            if (promptUserRes) {
                // console.log(promptUserRes)
            }
        }, err => {
            console.warn("Unable to send prompt user")
            console.warn(err)
        });
    }

    // *PROMISE METHODS
    async getPromptAsync(reference: string): Promise<tPromptWithLocalIndicator> {
        // console.log("getPrompt: " + reference)
        const promptList = await this.glowService.getPrompts(reference).toPromise()
        if (promptList && Array.isArray(promptList) && promptList.length > 0) {
            // console.log("Prompt received via API")
            if (promptList[0].hasOwnProperty("data") && promptList[0].data) {
                const prompt = promptList[0]
                return { ...prompt, ...{ isLocal: false } };
            }
        } else {
            // console.log("No Prompt found will look locally")
            return this.getPromptLocallyAsync(reference)
        }
    }

    async getPromptLocallyAsync(reference: string): Promise<tPromptWithLocalIndicator> {
        const prompt = await this.http.get<tPrompt>("/assets/prompts/" + reference + ".json").toPromise()
        if (prompt) {
            return { ...prompt, ...{ isLocal: true } }
        }
        return
    }
    
}

@Injectable()
export class PluralConfigService {
    //https://codeburst.io/using-the-plural-pipe-in-angular-2-2bcc67a14030
    constructor() {

    }

    getPluralConfiguration() {
        const itemPluralMapping = {
            // 'site': {
            //     '=0': '0 Sites',
            //     '=1': '1 Site',
            //     'other': '# Sites'
            // },
            'property': {
                '=0': '0 Properties',
                '=1': '1 Property',
                'other': '# Properties'
            },
            'block': {
                '=0': '0 Blocks',
                '=1': '1 Block',
                'other': '# Blocks'
            },
        };
        return itemPluralMapping
    }
}
