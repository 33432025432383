import { Component, Input, OnInit } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FaqsService } from 'src/app/services/faqs.service';
import { tResourceTariff } from 'src/app/services/glow.typings';

@Component({
  selector: 'app-faq-feedback-form',
  templateUrl: './faq-feedback-form.component.html',
  styleUrls: ['./faq-feedback-form.component.scss']
})


export class FaqFeedbackFormComponent implements OnInit {
  showPositiveFeedbackForm: boolean = false
  showNegativeFeedbackForm: boolean = false
  showThankyouMessage: boolean = false
  positiveFeedbackForm!: FormGroup;
  negativeFeedbackForm!: FormGroup;
  showWarning: boolean = false

  @Input() faq
  constructor(private fb: FormBuilder, private faqsService: FaqsService) { }

  ngOnInit(): void {
    this.createPositiveFeedbackForm()
    this.createNegativeFeedbackForm()
  }

  showFeedbackForm(answer: string) {
    if (answer == 'yes') {
      this.showPositiveFeedbackForm = !this.showPositiveFeedbackForm

    } else this.showNegativeFeedbackForm = true
  }

  createPositiveFeedbackForm() {
    this.positiveFeedbackForm = this.fb.group({
      positiveFeedback: ['', [
        Validators.required,
        Validators.minLength(1),
        // no empty white space validator
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
      ]]
    })
  }

  createNegativeFeedbackForm() {
    this.negativeFeedbackForm = this.fb.group({
      negativeFeedback: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)

      ]],
      radioFeedback: ['', [Validators.required],],

    })

  }
  postFeedback(feedback, isPositive, reason?) {
    this.showWarning = false
    if (!this.positiveFeedbackForm.valid && !this.negativeFeedbackForm.valid) {
      this.showWarning = true
      return false
    } else {
      this.faqsService.postFeedback(this.faq.id, feedback, isPositive, reason)
      this.displayThankyouMessage()
    }
  }

  displayThankyouMessage() {
    this.showThankyouMessage = true
    this.showNegativeFeedbackForm = false
    this.showPositiveFeedbackForm = false
  }
}
