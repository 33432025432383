import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CaseStudyFormService } from 'src/app/services/case-study-form.service';
import { GlowService } from 'src/app/services/glow.service';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';
import { Utils } from 'src/utils';

import postcodeData from '../../../../assets/db/postcodes.json';
import { Router } from '@angular/router';

@Component({
  selector: 'app-building-form',
  templateUrl: './building-form.component.html',
  styleUrls: ['./building-form.component.scss'],
})
export class BuildingFormComponent implements OnInit {
  section;
  caseStudy;
  formattedCaseStudy;

  buildingForm: FormGroup;
  carbonEmissionsForm: FormGroup;
  locationForm: FormGroup;
  hasDoneRetrofitForm: FormGroup;
  hasCarbonEmissionsForm: FormGroup;
  completionStatus: 'completed' | 'in-progress' = 'in-progress';

  building;
  buildingFormName: string = 'buildingPreForm';
  carbonEmissionsFormName: string = 'carbonEmissionsPreForm';
  hasCarbonEmissionsFormName: string = 'hasCarbonEmissionsPreForm';
  hasDoneRetrofitFormName: string = 'hasDoneRetrofitForm';
  csSection;
  selectedService: any;
  passport;

  loadObj;
  subscriptionSelectedService: Subscription;
  constructor(private fb: FormBuilder, private caseStudyFormService: CaseStudyFormService, private glowService: GlowService, private serviceSelectorSvc: ServiceSelectorSvc, private router: Router) {
    this.loadObj = this.caseStudyFormService.initLoadObj();
    this.section = this.caseStudyFormService.getSectionFromRouter();
    this.caseStudyFormService.caseStudyAnnouncer.subscribe((data) => {
      if (data) {
        this.caseStudy = data.caseStudy;
        this.formattedCaseStudy = data.formattedCaseStudy;
        if (this.csSection) this.building = this.formattedCaseStudy[this.csSection].building && this.formattedCaseStudy[this.csSection].building[0];
      }
    });

    this.caseStudyFormService.formAnnouncer.subscribe((data) => {
      if (data.formName === this.buildingFormName) {
        this.buildingForm = data.form;
      }
      if (data.formName === this.carbonEmissionsFormName) {
        this.carbonEmissionsForm = data.form;
      }
      if (this.csSection === 'before' && data && data.formName === 'locationForm') {
        this.locationForm = data.form;
      }
      if (this.csSection === 'after' && data.formName === this.hasDoneRetrofitFormName) {
        this.hasDoneRetrofitForm = data.form;
      }
      if (data && data.formName === this.hasCarbonEmissionsFormName) {
        this.hasCarbonEmissionsForm = data.form;
      }
      if (this.hasCarbonEmissionsForm) this.caseStudyFormService.processChangeBooleanControl('carbonEmissions', this.hasCarbonEmissionsForm, this.carbonEmissionsForm, null, 'isTrue', this.carbonEmissionsFormName);
      if (this.hasDoneRetrofitForm) this.caseStudyFormService.processChangeBooleanControl('buildingPost', this.hasDoneRetrofitForm, this.buildingForm, null, 'isTrue', this.buildingFormName);
    });
    this.subscriptionSelectedService = this.serviceSelectorSvc.serviceSet.subscribe((selectedService) => {
      this.selectedService = selectedService;
      this.caseStudyFormService.initServiceVariables(selectedService).then();
      this.loadObj = this.caseStudyFormService.initLoadObj();
      this.getPropertyPassport();
    });
  }

  ngOnInit() {
    this.selectedService = this.serviceSelectorSvc.getSelectedService();
    if (!this.caseStudyFormService.hasInitServiceVariables) {
      this.caseStudyFormService.initServiceVariables(this.selectedService).then();
    } else {
      this.caseStudyFormService.announceLatestCaseStudy();
    }
    this.processChanges();

    if (this.selectedService && this.selectedService.veId) {
      this.getPropertyPassport();
    }
  }

  processChanges() {
    this.csSection = this.section === 'pre-installation-building' ? 'before' : 'after';

    this.buildingFormName = this.csSection === 'before' ? 'buildingPreForm' : 'buildingPostForm';
    this.carbonEmissionsFormName = this.csSection === 'before' ? 'carbonEmissionsPreForm' : 'carbonEmissionsPostForm';
    this.hasCarbonEmissionsFormName = this.csSection === 'before' ? 'hasCarbonEmissionsPreForm' : 'hasCarbonEmissionsPostForm';

    this.caseStudyFormService.announceForm(this.hasCarbonEmissionsFormName, this.caseStudyFormService[this.hasCarbonEmissionsFormName]);
    this.caseStudyFormService.announceForm(this.buildingFormName, this.caseStudyFormService[this.buildingFormName]);
    this.caseStudyFormService.announceForm(this.carbonEmissionsFormName, this.caseStudyFormService[this.carbonEmissionsFormName]);
    this.caseStudyFormService.announceForm('locationForm', this.caseStudyFormService['locationForm']);
    this.caseStudyFormService.announceForm(this.hasDoneRetrofitFormName, this.caseStudyFormService[this.hasDoneRetrofitFormName]);
    if (this.formattedCaseStudy) {
      this.building = this.formattedCaseStudy[this.csSection].building && this.formattedCaseStudy[this.csSection].building[0];
    }
  }

  getCarbonEmissionsJeInit() {
    const journalEntryData = {};
    journalEntryData['journalEntryDataType'] = 'resource_extract';
    journalEntryData['classifier'] = 'carbon_emissions';
    journalEntryData['totalValue'] = +this.carbonEmissionsForm.get('totalValue').value;
    const startDateSplit = this.carbonEmissionsForm.get('validFrom').value && this.carbonEmissionsForm.get('validFrom').value.split('-');
    const journalEntry: any = {
      validFrom: this.carbonEmissionsForm.get('validFrom').value,
      //a year from start date ("yyyy-MM-dd")
      validTo: startDateSplit && Number(startDateSplit[0]) + 1 + '-' + startDateSplit[1] + '-' + startDateSplit[2],
      data: journalEntryData,
    };
    return journalEntry;
  }

  handleError(message?) {
    this.loadObj = this.caseStudyFormService.handleLoadObjError(this.loadObj, message || "Couldn't save changes. Please try again later.");
    return this.loadObj;
  }

  handleSuccess() {
    this.caseStudyFormService.markSectionAsPristine(this.section);
    this.loadObj = this.caseStudyFormService.handleLoadObjSuccess(this.loadObj, 'Changes saved successfully.');
    return this.loadObj;
  }

  async saveChanges(completionStatus: 'completed' | 'in-progress' = 'in-progress') {
    this.completionStatus = completionStatus;
    const sectionValidity = this.caseStudyFormService.getSectionValidityForNonEmptyControls(this.section);
    if (!sectionValidity) {
      this.loadObj = this.caseStudyFormService.handleLoadObjError(this.loadObj, "Couldn't save changes. Please check there is no errors in the form.");
      return this.loadObj;
    }
    this.loadObj = this.caseStudyFormService.handleLoadObjLoading(this.loadObj, 'Saving changes...');
    let err, data;

    if (this.csSection === 'after' && !(this.buildingForm && this.hasDoneRetrofitForm.value.isTrue)) {
      [err, data] = await Utils.promiseHandler(this.processNoBuildingPostForm());
      if (err) return this.handleError();
      this.caseStudyFormService.setFormAndAnnounce(this.buildingFormName, this.buildingForm);
      this.caseStudyFormService.setFormAndAnnounce(this.hasCarbonEmissionsFormName, this.hasCarbonEmissionsForm);
      this.caseStudyFormService.setFormAndAnnounce(this.hasDoneRetrofitFormName, this.hasDoneRetrofitForm);
      await this.caseStudyFormService.announceLatestCaseStudy();
      return this.handleSuccess();
    }
    if (this.buildingForm && this.buildingForm.value.propertyImage && this.buildingForm.value.propertyImage instanceof FileList) {
      [err, data] = await Utils.promiseHandler(this.uploadPropertyImage(this.buildingForm.value.propertyImage[0]));
      if (err) {
        const message = err.statusCode === 413 ? 'Image size too large. Please upload an image smaller than 1MB.' : "Couldn't upload image. Please try again later.";
        return this.handleError(message);
      }
    }

    if (this.csSection === 'before') {
      [err, data] = await Utils.promiseHandler(this.addLocationToCaseStudy());
      if (err) return this.handleError();
      this.caseStudyFormService.setFormAndAnnounce('locationForm', this.locationForm);
    }

    [err, data] = await Utils.promiseHandler(this.building ? this.processExistingBuildingComponent(this.buildingForm && this.buildingForm.value) : this.processNewBuildingComponent(this.buildingForm && this.buildingForm.value));
    if (err) return this.handleError();
    await this.processCarbonEmissions(data, 'building', this.csSection);

    this.caseStudyFormService.setFormAndAnnounce(this.buildingFormName, this.buildingForm);
    this.caseStudyFormService.setFormAndAnnounce(this.hasCarbonEmissionsFormName, this.hasCarbonEmissionsForm);
    this.caseStudyFormService.setFormAndAnnounce(this.hasDoneRetrofitFormName, this.hasDoneRetrofitForm);
    await this.caseStudyFormService.announceLatestCaseStudy();
    return this.handleSuccess();
  }

  async addLocationToCaseStudy() {
    const postcode = this.locationForm.get('postcode').value;
    if (!postcode) return;
    const postcodeFirstPart = Utils.extractUKPostcodeFirstPart(postcode).toUpperCase();
    const postcodeDistrict = postcodeFirstPart && postcodeData[postcodeFirstPart].Region;
    return this.glowService.updateCaseStudy(this.caseStudy.csId, { postcode, postcodeDistrict }).toPromise();
  }

  async saveAndContinue() {
    this.completionStatus = 'completed';
    this.loadObj = await this.caseStudyFormService.saveAndContinue(this.saveChanges.bind(this), this.section, this.loadObj);
  }

  async createNewComponentsAndJournalEntries(formValue, componentDataType, componentVersion, csSection, componentId?) {
    const buildingComponent = await this.caseStudyFormService.createComponent({
      formValue,
      componentVersion,
      componentDataType,
      componentId,
      fieldsToDelete: ['installedAt', 'decommissionedAt', 'summary', 'smartHTC', 'propertyImage', 'mediaFiles'],
      fieldsToAdd: ['installedAt', 'decommissionedAt'],
      completionStatus: this.completionStatus,
    });
    formValue = await this.caseStudyFormService.uploadComponentMediaFilesFromForm(formValue, buildingComponent.componentId, buildingComponent.componentVersion);
    const buildingJe = await this.caseStudyFormService.createDescriptionJournalEntry({
      formValue,
      componentVersion: buildingComponent.componentVersion,
      componentId: buildingComponent.componentId,
      componentDataType,
      completionStatus: this.completionStatus,
    });
    await this.caseStudyFormService.addJournalEntryToCaseStudy({
      journalEntry: buildingJe,
      section: csSection,
      caseStudy: this.caseStudy,
    });
    return buildingComponent;
  }

  async updateCurrentBuildingComponentAndJournalEntries(formValue, componentDataType) {
    const buildingComponent = await this.caseStudyFormService.updateComponent({
      componentId: this.building.componentId,
      componentVersion: this.building.componentVersion,
      componentDataType,
      formValue,
      fieldsToDelete: ['installedAt', 'decommissionedAt', 'summary', 'smartHTC', 'propertyImage', 'mediaFiles'],
      fieldsToAdd: ['installedAt', 'decommissionedAt', 'mediaFiles'],
    });
    const buildingDescriptionJeId = this.building.text_description && this.building.text_description.jeId;
    const buildingJeInit = this.caseStudyFormService.getDescrptionJournalEntryFromFormValue(formValue);
    await this.glowService.updateJournalEntry(buildingDescriptionJeId, buildingJeInit).toPromise();
    return buildingComponent;
  }

  async createNewCarbonEmissionsJe(buildingComponent, componentDataType, csSection) {
    const carbonEmissionsJeInit = this.getCarbonEmissionsJeInit();
    const carbonEmissionsJe = await this.caseStudyFormService.createJournalEntry({
      journalEntryData: carbonEmissionsJeInit,
      componentId: buildingComponent.componentId,
      componentVersion: buildingComponent.componentVersion,
      componentDataType,
      completionStatus: this.completionStatus,
    });
    await this.caseStudyFormService.addJournalEntryToCaseStudy({
      journalEntry: carbonEmissionsJe,
      section: csSection,
      caseStudy: this.caseStudy,
    });
  }

  async processCarbonEmissions(buildingComponent, componentDataType, csSection) {
    const carbonEmissionsJe = this.building && this.building.carbon_emissions;
    if (!carbonEmissionsJe && !this.hasCarbonEmissionsForm.value.isTrue) return;
    if (!carbonEmissionsJe && this.hasCarbonEmissionsForm.value.isTrue) {
      //if it's after and a building after (retrofit) exists:
      // 1. we check if the building before has a carbon emissions JE saved in the after section of the case study
      // 2. if it does, we update the componentVersion of the JE to the new building componentVersion
      // 3. if it doesn't, we create a new JE for the building after (retrofit)
      if (this.csSection === 'after' && this.building) {
        let buildingBefore = this.formattedCaseStudy.before.building && this.formattedCaseStudy.before.building[0];
        if (!buildingBefore) return;
        const journalEntriesForBuildingBefore = await this.glowService.getJournalEntriesByComponent(buildingBefore.componentId, buildingBefore.componentVersion).toPromise();
        const carbonEmissionsJeForBuildingBefore = journalEntriesForBuildingBefore.filter((je) => je.data.classifier === 'carbon_emissions');
        const carbonEmissionsJeInAfter = this.caseStudy.after.find((je) => carbonEmissionsJeForBuildingBefore.some((jeBefore) => jeBefore.jeId === je.jeId));
        if (carbonEmissionsJeInAfter) {
          return this.glowService.updateJournalEntry(carbonEmissionsJeInAfter.jeId, { componentVersion: this.building.componentVersion }).toPromise();
        }
      }
      return this.createNewCarbonEmissionsJe(buildingComponent, componentDataType, csSection);
    }
    if (this.hasCarbonEmissionsForm.value.isTrue) {
      const carbonEmissionsJeInit = this.getCarbonEmissionsJeInit();
      return this.glowService.updateJournalEntry(carbonEmissionsJe.jeId, carbonEmissionsJeInit).toPromise();
    }
    await this.caseStudyFormService.removeJournalEntryFromCaseStudy({ jeId: carbonEmissionsJe.jeId, section: csSection, caseStudy: this.caseStudy });
    this.caseStudyFormService.setFormAndAnnounce(this.carbonEmissionsFormName, this.carbonEmissionsForm);
  }

  async updateBuildingAfterStaticFields(formValue, componentDataType) {
    //update the unchaging field in the building component after (such as builtForm)
    //todo: refactor backend to allow for updating of unchanging fields
    const buildingAfter = this.formattedCaseStudy.after.building && this.formattedCaseStudy.after.building[0];
    if (!this.caseStudyFormService.buildingPostForm) return;
    const updateBuildingAferBody = {};
    for (const key in formValue) {
      if (!this.caseStudyFormService.buildingPostForm.controls.hasOwnProperty(key)) {
        updateBuildingAferBody[key] = formValue[key];
      }
    }
    Object.assign(updateBuildingAferBody, this.caseStudyFormService.buildingPostForm.value);
    return this.caseStudyFormService.updateComponent({
      componentId: buildingAfter.componentId,
      componentVersion: buildingAfter.componentVersion,
      componentDataType,
      formValue: updateBuildingAferBody,
      fieldsToDelete: ['propertyImage', 'mediaFiles'],
      fieldsToAdd: ['mediaFiles'],
    });
  }

  async processNoBuildingPostForm() {
    const hasDoneRetrofit = this.caseStudyFormService.hasDoneRetrofit(this.formattedCaseStudy);
    if (hasDoneRetrofit) {
      const buildingAfter = this.formattedCaseStudy.after.building && this.formattedCaseStudy.after.building[0];
      await this.glowService.deleteComponent(buildingAfter.componentId, buildingAfter.componentVersion).toPromise();
      const journalEntries = this.caseStudy.after.filter((je) => je.componentId === buildingAfter.componentId && je.componentVersion === buildingAfter.componentVersion);
      for (const je of journalEntries) {
        await this.caseStudyFormService.removeJournalEntryFromCaseStudy({ jeId: je.jeId, section: this.csSection, caseStudy: this.caseStudy });
      }
    }

    let buildingBefore = this.formattedCaseStudy.before.building && this.formattedCaseStudy.before.building[0];
    if (!buildingBefore) {
      buildingBefore = await this.createNewComponentsAndJournalEntries({}, 'building', 1, 'before');
    }
    await this.processCarbonEmissions(buildingBefore, 'building', this.csSection);
  }

  addStaticFieldsToForm(formValue, buildingBefore) {
    for (const key in this.caseStudyFormService.buildingPreForm.controls) {
      if (!this.caseStudyFormService.buildingPostForm.controls.hasOwnProperty(key) && key !== 'propertyImage') {
        formValue[key] = buildingBefore[key] || (buildingBefore.data && buildingBefore.data[key]);
      }
    }
    return formValue;
  }

  async processBuildingPostForm(formValue, componentDataType, buildingBefore) {
    formValue = this.addStaticFieldsToForm(formValue, buildingBefore);
    return this.createNewComponentsAndJournalEntries(formValue, componentDataType, 2, this.csSection, buildingBefore.componentId);
  }

  async processNewBuildingComponent(formValue) {
    const componentDataType = 'building';
    if (this.csSection === 'after') {
      let buildingBefore = this.formattedCaseStudy.before.building && this.formattedCaseStudy.before.building[0];
      if (!buildingBefore) {
        buildingBefore = await this.createNewComponentsAndJournalEntries({}, componentDataType, 1, 'before');
      }
      return this.processBuildingPostForm(formValue, componentDataType, buildingBefore);
    }
    return this.createNewComponentsAndJournalEntries(formValue, componentDataType, 1, this.csSection);
  }

  async processExistingBuildingComponent(formValue) {
    const componentDataType = 'building';
    formValue = await this.caseStudyFormService.uploadComponentMediaFilesFromForm(formValue, this.building.componentId, this.building.componentVersion);
    if (this.csSection === 'after') {
      const buildingBefore = this.formattedCaseStudy.before.building && this.formattedCaseStudy.before.building[0];
      formValue = this.addStaticFieldsToForm(formValue, buildingBefore);
    }
    const buildingComponent = await this.updateCurrentBuildingComponentAndJournalEntries(formValue, componentDataType);
    if (this.csSection === 'before') {
      await this.updateBuildingAfterStaticFields(formValue, componentDataType);
    }
    return buildingComponent;
  }

  getPropertyPassport() {
    this.loadObj.loadingMessage = 'Retrieving your property passport...';
    this.loadObj.loading = true;
    delete this.passport;
    this.glowService.getPropertyPassport(this.selectedService.veId).subscribe(
      (resp) => {
        if (resp.valid) {
          this.passport = resp;
        }
        this.loadObj.loading = false;
        this.loadObj.loadingMessage = '';
      },
      (err) => {
        this.loadObj.errorMessage = err && err.loadObj.error && err.loadObj.error.loadObj.error;
        this.loadObj.errorCode = err && err.loadObj.error && err.loadObj.error.loadObj.errorCode;
        this.loadObj.loading = false;
        this.loadObj.loadingMessage = '';
        this.loadObj.error = true;
      }
    );
  }

  async uploadPropertyImage(imageFile) {
    const formData = await Utils.formDataFromImgFile(imageFile, 'Property Image');
    const [err, data] = await Utils.promiseHandler(this.glowService.editMediaFieldCaseStudy(this.caseStudy.csId, 'propertyImage', formData).toPromise());
    if (err) {
      const message = (err && err.error && err.error.message) || "Couldn't upload image. Please try again later.";
      const statusCode = err && err.error && err.error.statusCode;
      const error = new Error(message);
      error['statusCode'] = statusCode;
      throw error;
    }
  }

  fillFormFromPassport() {
    const postcode = this.passport.epcRecords[0].postcode;
    //capitalise first letter
    const builtForm = this.passport.epcRecords[0].built_form.charAt(0) + this.passport.epcRecords[0].built_form.toLowerCase().slice(1);
    const propertyType = this.passport.epcRecords[0].property_type;
    const floorArea = this.passport.epcRecords[0].total_floor_area;

    this.buildingForm.patchValue({
      builtForm,
      type: propertyType,
      totalFloorArea: Math.round(floorArea),
    });
    if (this.csSection === 'before') {
      this.locationForm.patchValue({ postcode });
    }
  }
}
