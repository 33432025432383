import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StrapiService } from 'src/app/services/strapi.service';
import { Utils } from 'src/utils';
@Component({
  selector: 'app-infographic-card',
  templateUrl: './infographic-card.component.html',
  styleUrls: ['./infographic-card.component.scss']
})
export class InfographicCardComponent implements OnInit {
  @Input() infographic;
  @Input() shouldDisplaySummary: boolean = true;
  @Input() shouldDisplayCommunityTag: boolean = false;
  @Input() titleStyle: any = {};
  @Input() backgroundStyle: any = {}
  @Input() titleLineHeight: any = {}
  @Input() displayTimeToRead: boolean = true
  innerWidth;
  @HostListener('window:resize', []) onResize() {
    // checks size of window to view specific menu
    this.innerWidth = window.innerWidth;
  }
  constructor(private router: Router
    , private strapiService: StrapiService) {   
     this.onResize(); }
 

  ngOnInit() {
  }

  getDisplayImageUrl() {
    return this.strapiService.getSmallDisplayImageUrl(this.infographic);
  }

infographicRelativePath(){
    return `/home/infographics/${this.infographic.attributes.slug}`
  }

  // getTimeToRead() {
  //   return Utils.getTimeToRead(this.infographic.attributes.content);
  // }
  getUpdatedAt() {
    return Utils.formatZDate(this.infographic.attributes.updatedAt);
  }
}
