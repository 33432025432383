import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StrapiService } from 'src/app/services/strapi.service';
import { CookieService } from 'ngx-cookie-service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

@Component({
  selector: 'app-landing-banner',
  templateUrl: './landing-banner.component.html',
  styleUrls: ['./landing-banner.component.scss'],
})

export class LandingBannerComponent implements OnInit {

  formInfo: FormGroup;
  regions = [
    'Greater London',
    'South East',
    'South West',
    'West Midlands',
    'North West',
    'North East',
    'Yorkshire and the Humber',
    'East Midlands',
    'East of England',
  ];
  modalReference: any
  isRegisterSuccessfull = false
  bannerText = "Are you an Installer? Stay updated on new features!"
  cookieClass: boolean = false;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private strapiService: StrapiService,
    private ccService: NgcCookieConsentService
  ) {
    this.formInfo = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', Validators.required],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      region: ['', Validators.required],
      businessName: ['', Validators.required],
    });

    if (this.ccService.hasAnswered() && this.ccService.hasConsented()) {
      this.cookieClass = true
    }
  }

  ngOnInit() { }
  open(content) {
    this.modalReference = this.modalService.open(content, { ariaLabelledBy: 'Register modal' });
  }

  async register() {
    const response = await this.strapiService.addUpdatesInstallerSubscriber(this.formInfo.value)
    this.modalReference.close();
    this.bannerText = "Thanks for registering!"
    this.isRegisterSuccessfull = true
    this.sendGAEvent({ event: 'signUp' })
    // this.cookieService.set('preliminaryRegister', "successfull");
  }

  sendGAEvent(params) {
    if (this.ccService.hasAnswered() && this.ccService.hasConsented()) {
      window.dataLayer.push(params)
    }
  }

}