import { Component, OnInit } from '@angular/core';
import { CaseStudyService } from 'src/app/services/case-study.service';
import { GlowService } from 'src/app/services/glow.service';
import { StrapiService } from 'src/app/services/strapi.service';

@Component({
  selector: 'app-case-studies-landing',
  templateUrl: './case-studies-landing.component.html',
  styleUrls: ['./case-studies-landing.component.scss'],
})
export class CaseStudiesLandingComponent implements OnInit {
  caseStudies: any = [];
  allCaseStudies: any = [];
  loading: boolean = true;
  constructor(private caseStudyService: CaseStudyService, private glowService: GlowService) {}

  ngOnInit() {
    this.glowService
      .getAllCaseStudies('heating_system.heat_pump')
      .toPromise()
      .then(async (caseStudies) => {
        this.caseStudies = await Promise.all(
          caseStudies.map(async (caseStudy) => {
            const fullCaseStudy = await this.caseStudyService.fetchCaseStudyData(caseStudy);
            return fullCaseStudy;
          })
        );
        this.allCaseStudies = this.caseStudies;
        this.loading = false;
      }).catch((err)=>{
        // console.log("err", err)
        this.loading=false
      })
  }

  filterCaseStudies(filter) {
    this.caseStudies = this.allCaseStudies.filter(
      (cs) => filter['postcodeDistrict'][cs.postcodeDistrict] || filter['builtForm'][cs.before.building[0].builtForm]
    );
    if(this.caseStudies.length===0){
      this.caseStudies=this.allCaseStudies
    }
  }
}
