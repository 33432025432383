import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstallerInvitationModalComponent } from './installer-invitation-modal.component';
import { FormValidationMessageModule } from '../form-validation-message/form-validation-message.module';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [InstallerInvitationModalComponent],
  imports: [
    CommonModule, FormValidationMessageModule, SharedModule
    
  ],
  exports: [InstallerInvitationModalComponent],
  entryComponents: [InstallerInvitationModalComponent]
})
export class InstallerInvitationModalModule { }
