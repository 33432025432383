
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  template: `<clr-icon shape="pop-out" aria-label="details" (click)="onClick()"></clr-icon>`,
})

export class CustomViewPropertyButtonComponent implements OnInit {

  renderValue: string;

  @Input() value;
  @Input() rowData: any;
  @Output() save = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    this.renderValue = this.value;
  }

  onClick() {
    this.save.emit(this.rowData);
  }
}