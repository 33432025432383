import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MetaTagsService {
  constructor(private metaService: Meta) {}

  addMetaTags(title: string, description: string, url: string) {
    this.metaService.addTag({name: 'description', content:description})
    this.metaService.addTag({ property: 'og:title', content: title });
    this.metaService.addTag({ property: 'twitter:title', content: title });
    this.metaService.addTag({ property: 'og:description', content: description });
    this.metaService.addTag({ property: 'twitter:description', content: description });
    this.metaService.addTag({ property: 'og:url', content: url });
    this.metaService.addTag({ property: 'twitter:url', content: url });
  }

  removeMetaTags() {
    this.metaService.removeTag("name='description'")
    this.metaService.removeTag("property='og:title'");
    this.metaService.removeTag("property='og:url'");
    this.metaService.removeTag("property='og:description'");
    this.metaService.removeTag("property='twitter:title'");
    this.metaService.removeTag("property='twitter:url'");
    this.metaService.removeTag("property='twitter:description'");
  }
}
