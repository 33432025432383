import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { th } from 'date-fns/locale';
import { ConfigService } from 'src/app/services/app-config.service';
import { tLoadingElement } from 'src/app/services/app-config.typings';
import { CustomUIConfigService } from 'src/app/services/custom-ui-config.service';
import { GlowService } from 'src/app/services/glow.service';
import { Utils } from 'src/utils';

declare var Stripe;

//removed auto-topup functionality

@Component({
  selector: 'app-stripe-card-form',
  templateUrl: './stripe-card-form.component.html',
  styleUrls: ['./stripe-card-form.component.scss'],
})
export class StripeCardFormComponent implements OnInit {
  @Output() onSuccessfulOperation: EventEmitter<any> = new EventEmitter();
  stripe; // = Stripe(environment && environment.stripePublicKey);
  elements;
  loadObj: tLoadingElement;
  loadObjSetUp: tLoadingElement;
  clientSecret: string;

  constructor(private configService: ConfigService, private formBuilder: FormBuilder, private uiConfigService: CustomUIConfigService, private dialogService: NgbModal, public glowService: GlowService) {
    this.loadObjSetUp = this.uiConfigService.initialiseLoadingBooleans(true);
    this.loadObj = this.uiConfigService.initialiseLoadingBooleans(false);
    const configuration = this.configService.loadConfig();
    const env = this.glowService.getEnvironment() || 'prod';
    const publicStripeKey = configuration.environments[env].stripe.publicKey;
    this.stripe = Stripe(publicStripeKey);
  }

  ngOnInit() {
    this.setUp();
  }

  async setUp() {
    this.loadObjSetUp = this.uiConfigService.initialiseLoadingBooleans(true);
    const [error, customer] = await Utils.promiseHandler(this.glowService.srtipeSetUpCustomer().toPromise());
    if (error) {
      this.loadObjSetUp = {
        ...this.uiConfigService.initialiseLoadingBooleans(false),
        loadError: true,
        errorString: 'ERROR',
      };
      return;
    }
    this.setUpIntent()
      .then((clientSecret) => {
        if (clientSecret) {
          this.clientSecret = clientSecret;
          this.setupStripeElements();

        } else {
          console.warn('No Client Secret returns');
          this.loadObjSetUp = {
            ...this.uiConfigService.initialiseLoadingBooleans(false),
            loadError: true,
            errorString: 'ERROR',
          };
        }
      })
      .catch((err) => {
        console.warn(err);
        this.loadObjSetUp = {
          ...this.uiConfigService.initialiseLoadingBooleans(false),
          loadError: true,
          errorString: 'ERROR',
        };
      });
  }

  async setUpIntent() {
    const setUpIntent = await this.glowService.stripeSetupIntent().toPromise();
    return setUpIntent && setUpIntent.client_secret;
  }

  // https://docs.stripe.com/payments/payment-element/migration?integration-path=future
  setupStripeElements() {
    const styles = getComputedStyle(document.documentElement);
    const options = {
      mode: 'setup',
      currency: 'gbp',
      terms: {card:"never"}
    };
    // https://docs.stripe.com/elements/appearance-api
    const appearance = {
      rules: {
        '.Input': {
          padding: `${styles.getPropertyValue('--input-padding-y')} ${styles.getPropertyValue('--input-padding-x')}`,
          border: styles.getPropertyValue('--input-border-width').trim() + ' solid ' + styles.getPropertyValue('--input-border-color').trim(),
          borderRadius: styles.getPropertyValue('--input-border-radius').trim(),
          boxShadow: styles.getPropertyValue('--input-box-shadow').trim(),
          color: styles.getPropertyValue('--input-color').trim(),
          fontSize: styles.getPropertyValue('--input-font-size').trim(),
          fontFamily: 'inherit',
          fontWeight: styles.getPropertyValue('--input-font-weight').trim(),
        },
        '.Input--invalid': {
          border: styles.getPropertyValue('--input-border-width').trim() + ' solid ' + styles.getPropertyValue('--danger').trim(),
          boxShadow: 'none',
        },
        '.Input:focus': {
          borderColor: styles.getPropertyValue('--input-focus-border-color').trim(),
          boxShadow: styles.getPropertyValue('--input-focus-box-shadow').trim(),
        },
      },
    };

    this.elements = this.stripe.elements({ clientSecret: this.clientSecret, appearance: appearance });
    const paymentElement = this.elements.create('payment', options);
    this.loadObjSetUp = {
      ...this.uiConfigService.initialiseLoadingBooleans(false),
      loaded: true,
    };
    paymentElement.mount('#payment-element');    
  }

  async setUpPaymentMethod() {
    const { error: submitError } = await this.elements.submit();
    if (submitError) {
      this.loadObj = {
        ...this.uiConfigService.initialiseLoadingBooleans(false),
        loadError: false,
        errorString: null,
      };
      return;
    }
    this.loadObj = this.uiConfigService.initialiseLoadingBooleans(true);

    // Use the clientSecret and Elements instance to confirm the setup
    const { error } = await this.stripe.confirmSetup({
      elements: this.elements,
      clientSecret: this.clientSecret,
      // confirmParams: {
      //   return_url: 'https://example.com/order/123/complete',
      // },
      // Uncomment below if you only want redirect for redirect-based payments
      redirect: 'if_required',
    });

    if (error) {
      this.loadObj = {
        ...this.uiConfigService.initialiseLoadingBooleans(false),
        loadError: true,
        errorString: error.message || 'ERROR',
      };
      return;
    }

    this.loadObj = {
      ...this.uiConfigService.initialiseLoadingBooleans(false),
      loaded: true,
    };
    this.onSuccessfulOperation.emit({ isSuccess: true });

    console.log('error', error);
  }
}
