import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormValidationService } from 'src/app/services/form-validation.service';
import { GlowService } from 'src/app/services/glow.service';
import { InvitationsService } from 'src/app/services/invitations.service';
import { SchematicsService } from 'src/app/services/schematics.service';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';
import { FormValidator } from 'src/app/services/user-validators';
import { Utils } from 'src/utils';
@Component({
  selector: 'app-invitation-modal',
  templateUrl: './invitation-modal.component.html',
  styleUrls: ['./invitation-modal.component.scss'],
})
export class InvitationModalComponent implements OnInit {
  invitationForm: FormGroup;
  hasSchematic;
  loading;
  error: boolean;
  errorMessage;
  emailError: boolean;
  firstSection = false;
  secondSection = false;
  installers = [];
  missingData;
  user;
  selectedService;
  schematicDocId;
  estimateRequestId;
  username;
  dropdownOptions;
  schematicDoc;
  successMessage: boolean;
  lastMonth;
  lastYear;
  completedSchematics;
  @Input() validVEs;
  @Input() invalidVEs;
  @Input() customerToInstallerInvitation: boolean;
  @Input() installerToCustomerInvitation: boolean;
  constructor(
    public activeModal: NgbActiveModal,
    private formValidationService: FormValidationService,
    private glowService: GlowService,
    private fb: FormBuilder,
    private auth: AuthenticationService,
    private serviceSelector: ServiceSelectorSvc,
    private invitationsService: InvitationsService,
    private schematicsService: SchematicsService
  ) { }

  ngOnInit() {
  
    this.invitationForm = this.fb.group({
      installers: ['', [this.customerToInstallerInvitation ? Validators.required : Validators.nullValidator]],
      installationDate: ['', [Validators.required]],
      spaceHotWaterTank: ['', [Validators.required]],
      selectedService: ['', [Validators.required]],
      radiatorSchematic: ['', [Validators.required]],
      newRadiatorsInstalled: ['', [Validators.required]],
    });
    if (this.validVEs) this.firstSection = true;
    this.selectedService = this.serviceSelector.getSelectedService();
    // this.invitationForm.get('selectedService').patchValue(this.dropdownOptions[0].name)
    this.getAssessmentPeriodsDate()
    if (this.selectedService && this.selectedService.veId) {
      this.schematicsService.getDocumentList(this.selectedService.veId).then((doc) => (this.schematicDoc = doc))
    }
    // this.getSchematicsByStatus()

  }

  
  getSchematics() {
    this.getSchematicsByStatus()
  }

  async getSchematicsByStatus() {
    // console.log(this.invitationForm)
    const documentList = await this.schematicsService.getSchematicsByStatus('complete', this.invitationForm.value.selectedService)
    this.completedSchematics = documentList
    // console.log(this.completedSchematics)
  }

  createInstallerGroup() {
    return this.fb.group({
      installerEmail: ['', [Validators.required]],
    });
  }

  showNextSection() {
    this.firstSection = false;
    this.secondSection = true;
  }
  selectSchematic(doc) {
    const schematicControl = this.invitationForm.controls['radiatorSchematic']
    schematicControl.setValue(doc)
    schematicControl.markAsTouched()
  }

  getValidVesToDisplay(ve) {
    return this.validVEs.find((virtualEntity) => virtualEntity.veId === ve.veId);
  }

  sendInvitation() {
    // console.log(this.invitationForm)
    if (this.invitationForm.valid) {
      if (this.customerToInstallerInvitation) {
        this.invitationForm.value.installers.forEach((installer) => {
          this.generateEstimateRequest(installer.value);
        });
      } else {
        if (this.installerToCustomerInvitation) {
          this.updateEstimateRequest();
        }
      }
    } else {
      this.invitationForm.markAllAsTouched();
      this.errorMessage = 'Please complete form';
    }
  }

  checkEmailValidity(installerArray) {
    this.emailError = false;
    let sanitisedEmails = [];
    installerArray.forEach((obj) => {
      //rid of white space
      sanitisedEmails.push({ value: obj['value'].replace(/[ \t]+$/, '') });
    });
    this.installers = sanitisedEmails;
    // console.log(sanitisedEmails)
    sanitisedEmails.forEach((obj) => {
      if (FormValidator.isEmailValid(obj)) {
        this.emailError = true;
        // console.log(this.emailError)
      }
    });
  }

  setInstallerEmail(event) {
    // console.log(event)
    this.installers = event;
    this.checkEmailValidity(event);
  }

  checkFormValidity(formControlName, translateKey?) {
    return this.formValidationService.checkFormValidity(this.invitationForm, formControlName, translateKey);
  }

  resetError(formControl) {
    this.invitationForm.controls[formControl].markAsUntouched();
    this.error = false;
  }

  getDateIn30Days() {
    return Utils.getDateIn30Days();
  }

  getAssessmentPeriodsDate() {
    var date = new Date(new Date().setMonth(new Date().getMonth() - 1));
    this.lastMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split('T')[0] + 'T23:59:59';

    var t = new Date();
    let lastYear = new Date(new Date(new Date(t.setFullYear(t.getFullYear() - 1))).setDate(1));
    this.lastYear = new Date(lastYear).toISOString().split('T')[0] + 'T00:00:00';
  }

  updateEstimateRequest() {
    this.loading = true;
    const selectedService = this.dropdownOptions.find((ss) => {
      return this.invitationForm.value.selectedService == ss.veId;
    });
    const body = {
      estimateRequestState: 'estimate-request-accepted-by-user',
      assessmentPeriodStart: this.lastYear,
      assessmentPeriodEnd: this.lastMonth,
      overview: {
        hotWaterTankSpace: this.invitationForm.value.spaceHotWaterTank == 'Yes' ? true : false,
        newRadiatorsFitted: this.invitationForm.value.newRadiatorsInstalled == 'Yes' ? true : false,
        installationDate: this.invitationForm.value.installationDate,
      },
      heatingSystem: {
        veDocId: this.schematicDoc[0].documentId,
      },
    };
    this.glowService.updateEstimateRequest(body, this.estimateRequestId, selectedService.veId).subscribe((resp) => {
      if (!resp.valid) {
        this.error = true;
        this.errorMessage = 'Unable to send invitation';
        this.invitationsService.getAllEstimateRequests();
        this.loading = false;
      } else {
        this.invitationsService.getAllEstimateRequests().then(resp => {
          this.loading = false
          this.activeModal.dismiss()
          this.error = false
        })

      }
    });
  }

  generateEstimateRequest(installer) {
    // this.error = false
    // console.log(this.invitationForm)
    const selectedService = this.dropdownOptions.find((ss) => {
      return this.invitationForm.value.selectedService == ss.veId;
    });
    const body = {
      username: this.username,
      estimateType: 'heat-pump-estimate',
      initiatedBy: 'customer',
      professionalUsername: installer,
      needsToBeAcceptedBy: this.getDateIn30Days(),
      assessmentPeriodStart: this.lastYear,
      assessmentPeriodEnd: this.lastMonth,
      overview: {
        hotWaterTankSpace: this.invitationForm.value.spaceHotWaterTank == 'Yes' ? true : false,
        newRadiatorsFitted: this.invitationForm.value.newRadiatorsInstalled == 'Yes' ? true : false,
        installationDate: this.invitationForm.value.installationDate,
      },
      heatingSystem: {
        veDocId: this.invitationForm.value.radiatorSchematic.documentId
      }
    }
    this.glowService.createEstimateRequest(body, selectedService.veId).subscribe((resp) => {
      this.loading = true;
      if (!resp.valid) {
        this.error = true;
        this.loading = false;
        this.errorMessage = 'Unable to send invitation';
        this.invitationsService.getAllEstimateRequests();
      } else {
        this.loading = false;
        this.error = false;
        this.invitationsService.getAllEstimateRequests();
        this.successMessage = true;

        // this.activeModal.dismiss()
      }
    });
  }
}
