import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/authentication.service';
// import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root',
})
export class OIDCAuthGuard implements CanActivate {
  constructor(
    private auth: AuthenticationService // private router: Router, // private oauthService: OAuthService
  ) {}

  public canActivate(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | Promise<any> | boolean {
    if (!this.auth.authenticated) {
      return this.auth.authenticate();
    }
    return this.auth.authenticated;

    // var hasIdToken = this.oauthService.hasValidIdToken();
    // var hasAccessToken = this.oauthService.hasValidAccessToken();
    // // console.log('canActivate', hasIdToken, hasAccessToken)
    // return (hasIdToken && hasAccessToken);

    // // console.log('canActivate this.auth.authenticated', this.auth.authenticated)
    // if (!this.auth.authenticated) {
    //     this.auth.signout()
    //     this.router.navigate(['login']);
    //     return false;
    // }
    // else {
    //     return true;
    // }
  }


}
