import { Component, Input, OnInit } from '@angular/core';
import { InputEditorComponent } from 'ng2-smart-table/lib/components/cell/cell-editors/input-editor.component';

@Component({
  selector: 'app-faqs-subtopic-non-faq-content',
  templateUrl: './faqs-subtopic-non-faq-content.component.html',
  styleUrls: ['./faqs-subtopic-non-faq-content.component.scss']
})
export class FaqsSubtopicNonFaqContentComponent implements OnInit {
  @Input() contentArray:any[]=[]
  @Input() sliceStart:number = 0
  @Input() sliceEnd:number
  @Input() contentType:string
  constructor() { }

  ngOnInit() {
    this.sliceEnd?null:this.sliceEnd=this.contentArray.length
  }

contentRelativePath(content:any){
  return `/home/${this.contentType}/${content.attributes.slug}`
}

}
