import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SchematicsService } from 'src/app/services/schematics.service';

@Component({
  selector: 'app-radiator-schematic-selector-modal',
  templateUrl: './radiator-schematic-selector-modal.component.html',
  styleUrls: ['./radiator-schematic-selector-modal.component.scss'],
})
export class RadiatorSchematicSelectorModalComponent implements OnInit {
  @Input() veId: string;
  schematicDocuments = [];

  constructor(private activeModal: NgbActiveModal, private schematicsService: SchematicsService) {}

  ngOnInit() {
    this.setSchematicDocuments();
  }

  ngOnChanges() {
    this.setSchematicDocuments();
  }

  setSchematicDocuments() {
    this.schematicsService.getSchematicsByStatus('complete', this.veId).then((documents) => {
      this.schematicDocuments = documents;

      // if (documents && documents.length > 1) {
      //   this.schematicDocuments = documents;
      // } else if (documents && documents.length === 1) {
      //   this.selectSchematicDocument(documents[0]);
      // }
    });
  }

  selectSchematicDocument(schematicDocument) {
    this.activeModal.close(schematicDocument);
  }
}
