import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-related-faqs-sidebar',
  templateUrl: './related-faqs-sidebar.component.html',
  styleUrls: ['./related-faqs-sidebar.component.scss']
})
export class RelatedFaqsSidebarComponent implements OnInit {
@Input() relatedFaqs
  constructor() { }

  ngOnInit() {
    
  }
  faqRelativePath(faq:any){
   
    return `/support/${faq.faq_topics.data[0].attributes.slug}/${faq.slug}`
  }
}
