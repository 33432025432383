import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlowService } from 'src/app/services/glow.service';
import { InvitationsService } from 'src/app/services/invitations.service';
import { Utils } from 'src/utils';
import { InvitationModalComponent } from '../invitation-modal/invitation-modal.component';
import { async } from '@angular/core/testing';
import { ProfessionalGlowService } from 'src/app/services/professional-glow.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  @Input() notification;
  @Input() readOnlyNotification: boolean
  @Input() installerToCustomerNotification: boolean
  @Input() customerToInstallerNotification: boolean
  @Input() validVEs
  @Input() virtualEntities
  invitationAccepted;
  errorMessage;
  invitationDeclined;
  loading = false;
  veForm: FormGroup
  constructor(private glowService: GlowService, private professionalGlowService: ProfessionalGlowService, private invitationsService: InvitationsService, private fb: FormBuilder, private modalService: NgbModal) {


  }

  ngOnInit() {
  }

  getReadableDateFromIso(string) {
    return Utils.getReadableDateFromIso(string);


  }



  openInvitationModal(estimateRequestId) {
    const modalRef = this.modalService.open(InvitationModalComponent, { scrollable: true, centered: true, size: 'lg', backdrop: 'static' });
    // modalRef.componentInstance.hasSchematic = this.isSchematicComplete
    // modalRef.componentInstance.schematicDocId = this.schematicDoc[0].documentId
    // modalRef.componentInstance.username = this.account.email
    modalRef.componentInstance.estimateRequestId = estimateRequestId
    modalRef.componentInstance.missingData = []
    modalRef.componentInstance.validVEs = this.validVEs
    modalRef.componentInstance.dropdownOptions = this.virtualEntities

    modalRef.componentInstance.installerToCustomerInvitation = true

  }
  async declineFromCustomer(id) {
    this.loading = true
    const body = {
      estimateRequestState: 'estimate-request-rejected-by-user',
    };
    this.glowService.updateEstimateRequestUserState(body, id).subscribe(async (resp) => {
      if (resp.valid) {
        this.loading = false
        this.invitationDeclined = true
      } else {
        this.loading = false
        this.errorMessage = 'An error has occured';
      }
      await this.invitationsService.getAllEstimateRequests()
    })


  }
  async handleRequest(id, decision) {
    this.loading = true;
    let state;
    decision == 'accepted' ? (state = 'estimate-request-accepted-by-professional') : (state = 'estimate-request-rejected-by-professional');
    const body = {
      estimateRequestState: state,
    };
    this.professionalGlowService.updateEstimateRequestProfessionalState(body, id).subscribe(async (resp) => {
      if (resp.valid) {
        decision == 'accepted' ? (this.invitationAccepted = true) : (this.invitationDeclined = true);
      } else {
        this.errorMessage = 'An error has occured';
        this.loading = false
      }

      await this.invitationsService.getAllEstimateRequestsByState('pending-professional-acceptance');
      this.loading = false;
    });
    await this.invitationsService.getAllProfessionalEstimateRequests();
    this.loading = false;
  }
  
}
