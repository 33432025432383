import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-case-study-relation-table',
  templateUrl: './case-study-relation-table.component.html',
  styleUrls: ['./case-study-relation-table.component.scss'],
})
export class CaseStudyRelationTableComponent implements OnInit {
  @Input() relationType: string;
  @Input() value: any;
  @Input() caseStudyRelatedContentConfig: any;

  displays: any = [];
  constructor() {}

  ngOnInit() {
    this.displays = this.getRelationDisplayOneToMany(this.relationType, this.value);
  }

  returnZero() {
    return 0;
  }

  getRelationDisplayOneToMany(relationType, value) {
    if (!value || value.length == 0) {
      return [];
    }
    let displays = [];
    let fieldsToDisplay = this.caseStudyRelatedContentConfig[relationType];
    for (let i = 0; i < value.length; i++) {
      let item = value[i];
      let display = [];
      for (let field in fieldsToDisplay) {
        if (item[field] || item[field] === false) {
          display.push({ displayName: fieldsToDisplay[field].displayName, value: item[field], unit: fieldsToDisplay[field].unit, unitPosition: fieldsToDisplay[field].unitPosition });
        }
      }
      displays.push(display);
    }
    return displays;
  }
}
