import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DateFormatService } from './date-format.service';
import { tTariffPlan } from '../components/component.typings';
import { GlowService } from './glow.service';

@Injectable({
  providedIn: 'root'
})
export class TariffService {

  private tariffPermissionAnnouncer = new Subject<any>();
  tariffPermission$ = this.tariffPermissionAnnouncer.asObservable();



  constructor(
    private dateFormatService: DateFormatService,
    private glowService: GlowService
  ) { }

  /* example 
  { 
    "classifier": "electricity.consumption.cost", 
    "resourceId": "5e3c2ead-b96c-43f0-b926-2637a41f3e97", 
    "rate": 13.965, 
    "standing": 25, 
    "hasError": false, 
    "errorString": null, 
    "type": "tou", 
    "structure": [ { "planDetail": [ { "standing": 25 }, { "rate": 13.965, "tier": 1 }, { "tourate": 5, "tier": 2, "time": "00:30-04:30" } ] } ] }
  */
  processToUStructureIntoTs(tariff: tTariffPlan) {

    if (!(tariff && tariff.type === 'tou' && Array.isArray(tariff.structure) && tariff.structure.length >= 1) && tariff.structure[0] && tariff.structure[0].planDetail) {
      console.warn("Unexpected input")
      return null
    }

    const planDetail = tariff.structure[0].planDetail;
    const touRates = planDetail.filter(rate => rate.hasOwnProperty("tourate"));
    const nonTouRates = planDetail.filter(rate => rate.hasOwnProperty("rate"));
    if (nonTouRates.length !== 1) {
      console.warn(`Cannot determine default TOU ${JSON.stringify(planDetail)}`)
    }
    const halfHourTimeSeries = [
      "00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30",
      "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30",
      "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30",
      "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30"
    ]
    const processedToURates = touRates.map(elemToU => {
      const [start, end] = elemToU.time.split("-")
      return { ...elemToU, start, end, tourate: (typeof elemToU.tourate === "string") ? Number(elemToU.tourate) : elemToU.tourate }
    })
    // console.log('processedToURates')
    // console.log(processedToURates)

    const sortedToURates = processedToURates.sort((a, b) => {
      if (a.start > b.start) return 1;
      if (a.start < b.start) return -1;
      return 0
    })
    const dateToday = this.dateFormatService.getYearMonthDay(new Date())
    const touTs = halfHourTimeSeries.map(hour => ({ hour, datetime: new Date(`${dateToday}T${hour}:00.000Z`).getTime(), rate: (typeof nonTouRates[0].rate === "string") ? Number(nonTouRates[0].rate) : nonTouRates[0].rate }))
    sortedToURates.forEach(touRate => {
      let foundStart = touTs.findIndex(elem => elem.hour === touRate.start)
      let foundEnd = touTs.findIndex(elem => elem.hour === touRate.end)

      if (foundStart >= 0 && foundEnd >= 0) {
        if (foundEnd < foundStart) {
          for (let i = foundStart; i < touTs.length; i++) {
            touTs[i] = { ...touTs[i], rate: touRate.tourate }
          }
          for (let i = 0; i < foundEnd; i++) {
            touTs[i] = { ...touTs[i], rate: touRate.tourate }
          }
        } else {
          for (let i = foundStart; i < foundEnd; i++) {
            touTs[i] = { ...touTs[i], rate: touRate.tourate }
          }
        }

      } else {
        console.warn(`Unable to find start/end ${JSON.stringify(touRate)}`)
      }

    })
    return touTs
  }

  processToUStructure(tariff: tTariffPlan) {

    // if (!(tariff && tariff.type === 'tou' && Array.isArray(tariff.structure) && tariff.structure.length >= 1) && tariff.structure[0] && tariff.structure[0].planDetail) {
    //   console.warn("Unexpected input")
    //   return null
    // }

    // const planDetail = tariff.structure[0].planDetail;
    // const touRates = planDetail.filter(rate => rate.hasOwnProperty("tourate"));
    // const nonTouRates = planDetail.filter(rate => rate.hasOwnProperty("rate"));
    // // const standingCharge = planDetail.filter(rate => rate.hasOwnProperty("standing"));

    // if (nonTouRates.length !== 1) {
    //   console.warn(`Cannot determine default TOU ${JSON.stringify(planDetail)}`)
    // }
    // const processedToURates = touRates.map(elemToU => {
    //   const [start, end] = elemToU.time.split("-")
    //   return { ...elemToU, start, end }
    // })
    // const sortedToURates = processedToURates.sort((a, b) => {
    //   if (a.start > b.start) return 1;
    //   if (a.start < b.start) return -1;
    //   return 0
    // })

    const ts = this.processToUStructureIntoTs(tariff)
    const touStructure = [] //sortedToURates.map(elem => ({ ...elem, rate: elem.tourate }))

    let distinctRate = { ...ts[0], start: ts[0].hour, startIndex: 0, end: null, time: null };
    for (let i = 1; i < ts.length; i++) {
      const elem = ts[i];
      if (ts[i].rate !== distinctRate.rate) {
        distinctRate = { ...distinctRate, end: elem.hour, time: `${distinctRate.start}-${elem.hour}` }
        touStructure.push(distinctRate)
        distinctRate = { ...ts[i], start: ts[i].hour, startIndex: i, end: null, time: null };
      }
    }

    if (touStructure.length > 0 && distinctRate.rate === touStructure[0].rate) {
      distinctRate = { ...distinctRate, end: touStructure[0].end, time: `${distinctRate.start}-${touStructure[0].end}` }
      touStructure.shift()
    } else {
      distinctRate = { ...distinctRate, end: "00:00", time: `${distinctRate.start}-00:00` }
    }

    touStructure.push(distinctRate)

    return touStructure
  }

  async getTariffPermission(resourceId: string, veId: string) {
    // console.log('getTariffPermission', resourceId, veId)

    const permissions = []

    const adminPermision = await this.glowService.getVeAccessAdminPermission(veId).toPromise()
    permissions.push({ hasOwnerPermission: adminPermision.valid ? true : false })
    permissions.push({ canSetTariff: adminPermision.valid ? true : false })
    permissions.push({ canGetTariffFromMeter: adminPermision.valid ? true : false })

    const viewPermision = await this.glowService.getVeAccessPermission(veId).toPromise()
    permissions.push({ canSeeHistory: viewPermision.valid ? true : false })

    this.tariffPermissionAnnouncer.next(permissions)
  }

}
