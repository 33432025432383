import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseStudySummaryComponent } from './case-study-summary.component';

import { SharedModule } from 'src/app/shared/shared.module';
import { StarRatingModule } from '../../star-rating/star-rating.module';
import { CaseStudyRelationTableModule } from '../case-study-relation-table/case-study-relation-table.module';



@NgModule({
  declarations: [CaseStudySummaryComponent],
  imports: [
    CommonModule,
    SharedModule,
    StarRatingModule,
    CaseStudyRelationTableModule
  ],
  exports:[CaseStudySummaryComponent]
})
export class CaseStudySummaryModule { }
