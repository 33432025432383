import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseStudyRelationTableComponent } from './case-study-relation-table.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [CaseStudyRelationTableComponent],
  imports: [CommonModule, SharedModule],
  exports: [CaseStudyRelationTableComponent],
})
export class CaseStudyRelationTableModule {}
