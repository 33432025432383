import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LandingService {
  // contentToDisplayId!: string | number;
  // userStoryId!: string | number;
  // resourcesListId!: string | number;
  // blogId!: string | number;


  // private blogAnnouncer = new Subject<any>();
  // selectedBlog$ = this.blogAnnouncer.asObservable();
  private contentToDisplayAnnouncer = new Subject<any>();
  selectedContentToDisplay$ = this.contentToDisplayAnnouncer.asObservable();

  relatedContentTypes: Array<string> = ['blogs', 'user-stories', 'resources', 'tags', 'authors'];



  constructor() {
    // this.getIdsFromStorage();
    // this.getContentToDisplayIdFromStorage();
  }

  getForumUserProfileUrl(handle:string){
    return `https://forum.glowmarkt.com/index.php?p=/profile/${handle}`
  }

  // setContentToDisplayId(id: string | number) {
  //   this.contentToDisplayId = id;
  //   localStorage.setItem('contentToDisplayId', JSON.stringify(id));
  //   this.contentToDisplayAnnouncer.next(id);
  // }


  // getContentToDisplayIdFromStorage() {
  //   const contentToDisplayId = localStorage.getItem('contentToDisplayId');
  //   contentToDisplayId ? (this.contentToDisplayId = contentToDisplayId) : null;
  // }
  // setUserStoryId(id: string | number) {
  //   this.userStoryId = id;
  //   localStorage.setItem('userStoryId', JSON.stringify(id));
  // }
  // setBlogId(id: string | number) {
  //   this.blogId = id;
  //   localStorage.setItem('blogId', JSON.stringify(id));
  //   this.blogAnnouncer.next(this.blogId);
  // }

  // setResourceId(id: string | number) {
  //   this.resourcesListId = id;
  //   localStorage.setItem('resourcesListId', JSON.stringify(id))
  // }
  // getIdsFromStorage() {
  //   const userStoryId = localStorage.getItem('userStoryId');
  //   const blogId = localStorage.getItem('blogId');
  //   const resourcesListId = localStorage.getItem('resourcesListId')
  //   userStoryId ? (this.userStoryId = userStoryId) : null;
  //   blogId ? (this.blogId = blogId) : null;
  //   resourcesListId ? (this.resourcesListId = resourcesListId) : null;
  // }



 

}
