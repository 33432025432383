import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomUIConfigService } from 'src/app/services/custom-ui-config.service';
import { GlowService } from 'src/app/services/glow.service';
import { MeterPointVerificationConsentManagementService } from 'src/app/services/meter-point-verification-consent-management.service';

@Component({
  selector: 'app-consent-modal',
  templateUrl: './consent-modal.component.html',
  styleUrls: ['./consent-modal.component.scss']
})
export class ConsentModalComponent implements OnInit {
  meter;
  modalBody
  modalTitle
  loadObj;
  constructor(private activeModal: NgbActiveModal,
    private uiConfigService: CustomUIConfigService,
    private glowService: GlowService, private meterPointConsentSvc: MeterPointVerificationConsentManagementService) { }

  ngOnInit() {
  }
  async renewConsent(meterPoint) {
    meterPoint.loadObj = this.uiConfigService.initialiseLoadingBooleans(true)
    console.log(meterPoint)
    try {
      const response = await this.glowService.renewMeterPointVerification(meterPoint).toPromise()
      this.meterPointConsentSvc.getVerificationStatus()
      this.activeModal.dismiss()
    } catch (e) {
      console.warn(e)
      this.loadObj = {
        ...this.uiConfigService.initialiseLoadingBooleans(false),
        errorString: 'Something went wrong',
        loadError: true
      }
    }
  }
}
