import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component';
import { InvitationModalComponent } from 'src/app/components/invitation-modal/invitation-modal.component';
import { GlowService } from 'src/app/services/glow.service';
import { InvitationsService } from 'src/app/services/invitations.service';
import { PdfService } from 'src/app/services/pdf.service';
import { SchematicsService } from 'src/app/services/schematics.service';
import { Utils } from 'src/utils';
@Component({
  selector: 'app-sent-invitations',
  templateUrl: './sent-invitations.component.html',
  styleUrls: ['./sent-invitations.component.scss']
})
export class SentNotificationsComponent implements OnInit {
  pageTitle: string = 'Sent invitations'
  estimateRequests: any
  loading;
  stageSubscription: Subscription
  showSchematic: boolean;
  snapshot;
  schematicDocument
  estimatesSubscription: Subscription
  constructor(private modalService: NgbModal, private pdfService: PdfService, private glowService: GlowService, private invitationsService: InvitationsService, private schematicsService: SchematicsService, private changeDetector: ChangeDetectorRef

  ) {
    this.estimatesSubscription = this.invitationsService.EstimatesAnnouncer.subscribe((resp) => {
      this.estimateRequests = resp.estimateRequests.filter(e => e.initiatedBy === 'customer')
      console.log(this.estimateRequests)
      if (this.estimateRequests) {
        this.loading = false
      }
    })
    this.loading = false
    this.stageSubscription = this.schematicsService.stage$.subscribe((schm) => {

      // console.log('schematics subscription')
      const dataURL = this.schematicsService.getCanvasDataUrl(2)
      this.pdfService.generatePdf(this.snapshot, dataURL)
      this.showSchematic = false



    })
  }

  ngOnInit() {
    this.loading = true
    this.invitationsService.getAllEstimateRequests()
  }
  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    if (this.estimatesSubscription) {
      this.estimatesSubscription.unsubscribe()
    }
  }

  downloadPDF(id) {
    this.glowService.getSnapshotBySnapshotId(id).subscribe(async (resp) => {
      console.log(resp, 'resp')
      if (resp) {

        this.snapshot = resp
        if (this.snapshot.heatingSystem) {
          await this.getSchematic(this.snapshot.veId, this.snapshot.heatingSystem.veDocId)
        } else this.pdfService.generatePdf(this.snapshot)
      }
      return resp
    })
  }
  async getSchematic(veId, veDocId) {
    this.schematicDocument = await this.schematicsService.getSchematic(veId, veDocId)
    // console.log(this.schematicDocument)
    this.showSchematic = true



  }

  openConfirmationModal(estimateRequestId) {
    const modalReference = this.modalService.open(ConfirmationModalComponent, { centered: true, size: 'lg' });
    modalReference.componentInstance.confirmationTitle = 'Are you sure you want to revoke access?';
    modalReference.componentInstance.confirmationText = 'By revoking access, the installer will no longer be able to view your data through Glowmarkt or send you an estimate request.'
    modalReference.componentInstance.confirmationButtonText = 'Revoke access';
    modalReference.componentInstance.estimateRequestId = estimateRequestId
  }



  getReadableDateFromIso(string) {
    return Utils.getReadableDateFromIso(string)
  }

}
