import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-installer-journey-infographic',
  templateUrl: './installer-journey-infographic.component.html',
  styleUrls: ['./installer-journey-infographic.component.scss']
})
export class InstallerJourneyInfographicComponent implements OnInit {

  constructor() { }
  @Input() background;
  ngOnInit() {
    console.log(this.background)
  }

}
