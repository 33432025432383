import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileModalComponent } from './profile-modal.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormValidationMessageModule } from '../form-validation-message/form-validation-message.module';
import { FileUploadModule } from '../file-upload/file-upload.module';



@NgModule({
  declarations: [ProfileModalComponent],
  imports: [
    CommonModule, SharedModule, FormValidationMessageModule, FileUploadModule
  ],
  exports: [ProfileModalComponent],
  entryComponents: [ProfileModalComponent]
})
export class ProfileModalModule { }
