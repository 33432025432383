import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-heating-system',
  templateUrl: './heating-system.component.html',
  styleUrls: ['./heating-system.component.scss', '../pages.component.scss'],
})

export class HeatingSystemComponent implements OnInit {

  @HostListener('window:resize', []) onResize() {
    this.innerWidth = window.innerWidth;
  }

  private currentPageAnnouncer = new Subject<any>();
  currentPage$ = this.currentPageAnnouncer.asObservable();

  pageTitle: string = 'Your heating system';
  subTitle: string;
  currentPage: string = 'Heating by room';
  innerWidth: number;
  toolLink: string;
  pages: any = [
    { path: '/pages/property/heating-system/heating-by-room', title: 'Heating by room', urlPath: 'heating-by-room' },
  ];

  constructor(private router: Router) { }

  ngOnInit() { 
    this.onResize()
  }

  public onRouterOutletActivate(event: any) {
    if (event.pageTitle) {
      this.currentPage = event.pageTitle;
    }
    if (event.displayTitle) {
      this.subTitle = event.displayTitle;
    }
    if (event.toolLink && event.shouldShowToolLink) {
      this.toolLink = event.toolLink;
    } else {
      this.toolLink = null;
    }
  }

  isCurrentPage(page) {
    return page.title == this.currentPage;
  }

  getOtherPages() {
    return this.pages.filter((p) => p.title != this.currentPage);
  }

  navigateTool() {
    this.router.navigateByUrl(`pages/tools/heating-by-room`)
  }

}