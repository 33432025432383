import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetupSmartMeterModalComponent } from './setup-smart-meter-modal.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { FormValidationMessageModule } from '../form-validation-message/form-validation-message.module';
import { StripeCardFormModule } from '../stripe-card-form/stripe-card-form.module';

@NgModule({
  declarations: [SetupSmartMeterModalComponent],
  imports: [CommonModule, SharedModule, SpinnerModule, FormValidationMessageModule, StripeCardFormModule],
  exports: [SetupSmartMeterModalComponent],
  entryComponents: [SetupSmartMeterModalComponent],
})
export class SetupSmartMeterModalModule {}
