import { Component, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { InvitationsService } from 'src/app/services/invitations.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss', '../../../pages-professional/pages-professional.component.scss']
})
export class NotificationsComponent implements OnInit {
  pageTitle: string = 'Notifications';
  invitationAccepted: boolean = false
  invitationDeclined: boolean = false
  pendingRequests;
  currentPage;
  loading
  pendingRequestSubscription: Subscription
  pages = [
    { path: '/professional/account/notifications/all-notifications', title: 'Notifications', urlPath: 'all-notifications' },

  ]
  private currentPageAnnouncer = new Subject<any>();
  currentPage$ = this.currentPageAnnouncer.asObservable()
  constructor() {

  }

 ngOnInit(): void {
   
 }

  public onRouterOutletActivate(event: any) {
    if (event.pageTitle) {
      this.currentPageAnnouncer.next(event.pageTitle)
      this.currentPage = event.pageTitle;
    }
    // console.log("EVENT",event);
  }
  isCurrentPage(page) {
    return page.title == this.currentPage;
  }

  getOtherPages() {
    return this.pages.filter(p => p.title != this.currentPage)
  
}
}
