import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { ConfigService } from '../services/app-config.service';
import { tFuctionalGroupConfiguration } from '../services/app-config.typings';
import { GlowService } from './glow.service';
import { tPrivilegeStatus } from './ui.typings';


@Injectable()
export class AppPrivilegeService {

    functionalGroupConfiguration: tFuctionalGroupConfiguration;

    // Observable string sources
    private PrivilegesAnnouncer = new Subject<tPrivilegeStatus>();

    // Observable string streams
    privilegeStatus$ = this.PrivilegesAnnouncer.asObservable();

    // Service message commands

    constructor(
        private configService: ConfigService,
        private glowService: GlowService) {

        var configuration = this.configService.loadConfig()
        this.functionalGroupConfiguration = configuration.functionalGroups;
    }

    checkPrivilege(privilegeKey: string) {
        // console.log("Checking Privilege " + privilegeKey)

        if (privilegeKey && this.functionalGroupConfiguration.hasOwnProperty(privilegeKey)) {

            let requiredFunctionalGroupId = this.functionalGroupConfiguration[privilegeKey]
            // console.log(requiredFunctionalGroupId)
            this.glowService.getFunctionalGroupAccounts().subscribe((functionalGroupAccounts) => {
                if (functionalGroupAccounts && Array.isArray(functionalGroupAccounts)) {
                    let privilegeMessage = {
                        privilegeKey: privilegeKey,
                        hasPrivilege: false
                    }
                    for (let i = 0; i < functionalGroupAccounts.length; i++) {     
                        if (functionalGroupAccounts[i].functionalGroupId == requiredFunctionalGroupId) {
                            privilegeMessage.hasPrivilege = true
                            break
                        }
                    }

                    this.announcePrivilegeStatus(privilegeMessage)

                } else {
                    console.warn("Unrecognised response getFunctionalGroupAccounts")
                }
            });
        } else {
            console.warn("Unrecognised privilegeKey")
        }

    }

    refreshPrivileges() {
        var configuration = this.configService.loadConfig()
        this.functionalGroupConfiguration = configuration.functionalGroups;
        // console.log("Refresh Privilege")
        for (let privilegeKey in this.functionalGroupConfiguration) {
            if (this.functionalGroupConfiguration.hasOwnProperty(privilegeKey)) {
                this.checkPrivilege(privilegeKey)
            }
        }
    }
    
    announcePrivilegeStatus(privilegeStatus: tPrivilegeStatus) {
        // console.log("announcing readings")
        this.PrivilegesAnnouncer.next(privilegeStatus);
    }

}
