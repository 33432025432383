import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StrapiService } from 'src/app/services/strapi.service';
import { Utils } from 'src/utils';
@Component({
  selector: 'app-article-card',
  templateUrl: './article-card.component.html',
  styleUrls: ['./article-card.component.scss'],
})
export class ArticleCardComponent implements OnInit {
  @Input() article;
  @Input() shouldDisplaySummary: boolean = true;
  @Input() shouldDisplayCommunityTag: boolean = false;
  @Input() titleStyle: any = {};
  @Input() backgroundStyle: any = {};
  @Input() titleLineHeight: any = {};
  @Input() displayTimeToRead: boolean = true;

  innerWidth;
  @HostListener('window:resize', []) onResize() {
    // checks size of window to view specific menu
    this.innerWidth = window.innerWidth;
  }

  timeToRead = 1;
  createdAt: string;
  displayImageUrl = '../../../assets/images/soft-orange.jpg';
  relativePath:string
  constructor(private router: Router, private strapiService: StrapiService) {}

  ngOnInit() {
    this.timeToRead = this.getTimeToRead();
    this.createdAt = this.getCreatedAt();
    this.displayImageUrl = this.getDisplayImageUrl();
    this.relativePath=this.articleRelativePath()
    this.onResize();
  }

  // navigateToArticle() {
  //   this.router.navigateByUrl(`home/article/${this.article.attributes.slug}`);
  // }

  articleRelativePath(){
    return `/home/articles/${this.article.attributes.slug}`
  }

  getTimeToRead() {
    return Utils.getTimeToRead(this.article.attributes.content);
  }
  getCreatedAt() {
    return Utils.formatZDate(this.article.attributes.createdAt);
  }

  getDisplayImageUrl() {
    return this.strapiService.getSmallDisplayImageUrl(this.article);
  }

  isBookmarked() {
    return this.strapiService.isContentBookmarked(this.article.attributes.slug);
  }

  bookmarkArticle() {
    this.strapiService.bookmarkContent(this.article.attributes.slug, 'articles');
  }

  unbookmarkArticle() {
    this.strapiService.unbookmarkContent(this.article.attributes.slug);
  }
}
