import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupName, Validators } from '@angular/forms';
import { FormValidator } from 'src/app/services/user-validators';
import { tLoadingElementWithSuccess } from 'src/app/services/app-config.typings';
import { PostcodeCheckerService } from 'src/app/services/postcode-checker.service';
import { GlowService } from 'src/app/services/glow.service';
import { CustomUIConfigService } from 'src/app/services/custom-ui-config.service';
import { StrapiService } from 'src/app/services/strapi.service';
import { TermsconditionsModalComponent } from 'src/app/components/termsconditions-modal/termsconditions-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-professionals-register',
  templateUrl: './professionals-register.component.html',
  styleUrls: ['./professionals-register.component.scss', '../register/register.component.scss'],
})
export class ProfessionalsRegisterComponent implements OnInit {
  roles = ['Installers', 'Local Authorities']
  constructor(private router: Router) {
  }
  ngOnInit(): void {

  }

  navigateToForm(role) {
    if (role == 'Installers'){
      return '/professionals-register/installer?type=personal-details';
  } else if (role == 'Local Authorities') {      
    return '/professionals-register/local-authority';


  }}
}