import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchematicsHomepageStatusComponent } from './schematics-homepage-status.component';
import { RadiatorSchematicCardModule } from '../radiator-schematic-card/radiator-schematic-card.module';

@NgModule({
  declarations: [SchematicsHomepageStatusComponent],
  imports: [CommonModule, RadiatorSchematicCardModule],
  exports: [SchematicsHomepageStatusComponent],
})
export class SchematicsHomepageStatusModule {}
