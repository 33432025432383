import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private popupCloseSubscription: Subscription;
  url;
  subscription;

  constructor(private router: Router, private translate: TranslateService, private ccService: NgcCookieConsentService) {
    this.translate.setDefaultLang('en-GB');
    this.translate.use('en-GB');

    const navEndEvent$ = this.router.events.pipe(filter((e) => e instanceof NavigationEnd));

    navEndEvent$.subscribe((e: NavigationEnd) => {
      this.handleRouteEvents(e);
    });
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
      if (this.ccService.hasAnswered() && this.ccService.hasConsented()) {
        // console.log('Cookie consent - accepted.')
      } else {
        // console.log('Cookie consent - denied')
      }
    });
  }

  ngOnDestroy() {
    this.popupCloseSubscription.unsubscribe();
  }

  handleRouteEvents(event) {
    if (this.ccService.hasAnswered() && this.ccService.hasConsented()) {
      let formattedUrl = event.urlAfterRedirects;
      let path = event.urlAfterRedirects;

      if (formattedUrl.includes('/pages/')) {
        formattedUrl = formattedUrl.replace('/pages/', '');
      } else {
        // Remove anything after slash
        if ((path.match(/\//g) || []).length > 2) {
          formattedUrl = formattedUrl.substr(0, formattedUrl.lastIndexOf('/'));
        }
      }
      // Remove any query params
      if ((formattedUrl.match(/\?/g) || []).length > 0) {
        formattedUrl = formattedUrl.substr(0, formattedUrl.lastIndexOf('?'));
      }

      // Get page name
      const words = formattedUrl.replace(/\//g, ' ').replaceAll('-', ' ').split(' '); // Remove / and replace -

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i].toUpperCase().charAt(0) + words[i].substr(1); // Make each word uppercase
      }

      // console.log('pageView', {
      //   page_title: words.join(" ").trim(),
      //   page_path: event.urlAfterRedirects
      // })

      window.dataLayer.push({
        event: 'pageView',
        page_title: words.join(' ').trim(),
        page_path: event.urlAfterRedirects,
      });
    }
  }
}
