import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-successful-register',
  templateUrl: './successful-register.component.html',
  styleUrls: ['./successful-register.component.scss']
})
export class SuccessfulRegisterComponent implements OnInit {

  constructor(public auth: AuthenticationService,) { }

  ngOnInit() {
  }
  tiggerAuthentication(): void {
    this.auth.authenticate();
  }

}
