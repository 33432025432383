import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsComponent } from './notifications.component';
import { NotificationModule } from 'src/app/components/notification/notification.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AllNotificationsModule } from './all-notifications/all-notifications.module';



@NgModule({
  declarations: [NotificationsComponent],
  imports: [
    CommonModule, NotificationModule, SharedModule, AllNotificationsModule
  ], 
  exports: [NotificationsComponent]
})
export class NotificationsModule { }
