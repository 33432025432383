import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-glossary-card',
  templateUrl: './glossary-card.component.html',
  styleUrls: ['./glossary-card.component.scss']
})
export class GlossaryCardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  glossaryRelativePath(){
    return `/glossary`
  }

}
