import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { FormValidationService } from 'src/app/services/form-validation.service';
import { GlowService } from 'src/app/services/glow.service';
import { PostcodeCheckerService } from 'src/app/services/postcode-checker.service';
import { ProfessionalGlowService } from 'src/app/services/professional-glow.service';
import { ServiceAgentService } from 'src/app/services/service-agent.service';
import { FormValidator } from 'src/app/services/user-validators';
import { Utils } from 'src/utils';
@Component({
  selector: 'app-profile-modal',
  templateUrl: './profile-modal.component.html',
  styleUrls: ['./profile-modal.component.scss']
})
export class ProfileModalComponent implements OnInit {
  @Input() serviceAgent
  @Input() heatPumpManufacturerList
  @Input() logoImageSrc
  @Input() profileImageSrc
  postcodeObj: any = { area: '', isValid: null, error: false };
  showPersonalInfoForm: boolean
  showBusinessDetailsForm: boolean
  showAccountForm: boolean
  showPasswordForm: boolean
  personalInfoForm: FormGroup
  businessDetailsForm: FormGroup
  accountForm: FormGroup
  emailForm: FormGroup
  photoForm: FormGroup
  logoForm: FormGroup
  passwordForm: FormGroup
  serviceAgentSubscription: Subscription
  loading: boolean
  imageSizeErrorMessage = "Please upload an image smaller than 1mb"
  showPhotoSizeErrorMessage: boolean = false
  showLogoSizeErrorMessage: boolean = false

  error: boolean
  errorMessage: string
  formattedSelectedCertifications: Array<string>
  regions: Array<string> = ['East of England', 'East Midlands', 'London', 'North East', 'North West', 'South East', 'South West', 'West Midlands', 'Yorkshire and Humberside', 'Scotland', 'Wales', 'Northern Ireland']
  installerCertifications: Array<string> = [
    'GasSafe',
    'NICEIC',
    'The IAA',
    'OFTEC',
    'NAPIT',
    'MSC',
    'HEATAS',
    'APHC',
    'CIPHA',
    'Install under umbrella scheme'
  ];
  skills: Array<string> = ['System design', 'System installation', 'Retrofit']
  technologies: Array<string> = ['Air source heat pump', 'Exhaust air heat pump', 'Gas absorption heat pump', 'Solar assisted heat pump']
  constructor(public activeModal: NgbActiveModal, 
    private fb: FormBuilder, 
    public formValidationService: FormValidationService,
    public postcodeCheckerService: PostcodeCheckerService, 
    private glowService: GlowService, 
    private serviceAgentService: ServiceAgentService,
    private professionalGlowService: ProfessionalGlowService
  ) {
    this.serviceAgentSubscription = this.serviceAgentService.serviceAgentAnnouncer.subscribe(resp => {
      this.serviceAgent = resp
      // console.log(this.serviceAgent)
      this.loading = false
      this.activeModal.dismiss()

    })
  }

  ngOnInit() {
    this.getCertifications()
    this.personalInfoForm = this.fb.group({
      firstName: [this.serviceAgent.profile.firstName, [Validators.required]],
      lastName: [this.serviceAgent.profile.lastName, [Validators.required]],
      regionsCovered: [this.serviceAgent.regionsCovered, [Validators.required]],
      location: [this.serviceAgent.location, [Validators.required]],
      technologies: [this.serviceAgent.technologies, [Validators.required]],
      skills: [this.serviceAgent.skills, [Validators.required]],
      certifications: [this.formattedSelectedCertifications, [Validators.required]],
      businessEmail: [this.serviceAgent.businessEmail, []],
      businessName: [this.serviceAgent.businessName, [Validators.required]],
      mcsNumber: [this.serviceAgent.mcsNumber, []],
      businessURL: [this.serviceAgent.businessURL, []],
      brands: [this.serviceAgent.brands, []],
      professionalDescription: [this.serviceAgent.professionalDescription, []],
      relevantExperience: [this.serviceAgent.relevantExperience, []],


    })
    this.photoForm = this.fb.group({
      photo: [[], []],

    })
    this.logoForm = this.fb.group({
      logo: [[], []]
    })

    this.accountForm = this.fb.group({
      businessPhoneNumber: ['0' + this.serviceAgent.phone.phone.substring(3), [Validators.required, FormValidator.isPhoneNumberValid]],
      email: [this.serviceAgent.email.email, [Validators.required]]
    })

    this.passwordForm = this.fb.group({
      password: ['', [Validators.required, FormValidator.isPasswordStrong]],
      confirmPassword: ['', [Validators.required, FormValidator.isPasswordSame]]
    })

  }


  close() {
    this.activeModal.dismiss()
  }

  findChangedValues(form) {
    const updatedValues = {};
    for (let x in form.controls) {
      if (form.controls[x].touched) {
        updatedValues[x] = form.controls[x].value
      }
    }
    // console.log(updatedValues)
    return updatedValues
  }



  getCertifications() {

    this.formattedSelectedCertifications = this.serviceAgent.certifications.map(c => c.certification)
  }


  getModalTitle() {
    if (this.showAccountForm) {
      return 'Update account details'
    } else if (this.showPersonalInfoForm) {
      return 'Update profile'
    } else if (this.showBusinessDetailsForm) {
      return 'Update business details'
    } else if (this.showPasswordForm) {
      return 'Update password'
    }
  }

  checkFormValidity(form, formControlName, translateKey?) {

    return this.formValidationService.checkFormValidity(form, formControlName, translateKey)
  }

  async checkPostcode() {
    let messages = [];
    await this.checkPostcodeisValid().then((resp) => {
      if (!this.postcodeObj.isValid) {
        messages.push('Invalid postcode');
      }
      return messages
    });
    return
  }

  async checkPostcodeisValid() {
    let postcode;
    if (this.personalInfoForm.get('location').touched) {
      postcode = this.personalInfoForm.value.location;
      await this.postcodeCheckerService
        .checkPostcode(postcode)
        .then((resp: any) => {
          // console.log('IS POSTCODEVALID', resp);
          this.postcodeObj.area = resp.result['admin_district'];
          this.postcodeObj.isValid = true;
          this.postcodeObj.error = false;
        })
        .catch((err) => {
          console.warn('postcode errr', err);
          this.postcodeObj.area = '';
          this.postcodeObj.isValid = false;
          this.postcodeObj.error = true;

        });
    }
  }

  getForm() {
    if (this.showAccountForm) {
      return this.accountForm
    } else if (this.showPersonalInfoForm) {
      return this.personalInfoForm
    } else if (this.showPasswordForm) {
      return this.passwordForm
    }
  }

  async updateDetails() {
    (!this.photoForm.pristine) ? this.checkImageChange('photo', this.photoForm) : '';
    (!this.logoForm.pristine) ? this.checkImageChange('logo', this.logoForm) : ''
    const form = this.getForm()
    let body: any = this.findChangedValues(form)
    if (form.valid && !this.showLogoSizeErrorMessage && !this.showPhotoSizeErrorMessage) {
      this.error = false
      this.loading = true
      if (form == this.personalInfoForm) {
        if (body.firstName && !body.lastName) {
          body.lastName = this.serviceAgent.profile.lastName
        } else if (body.lastName && !body.firstName) {
          body.firstName = this.serviceAgent.profile.firstName
        }
        this.updatePersonalDetails(body)
      } else if (form == this.passwordForm) {
        this.updatePassword(body)
      } else if (form == this.accountForm) {
        if (body.businessPhoneNumber) {
          body = { businessPhoneNumber: '+44' + body.businessPhoneNumber.substring(1) }
          this.updatePhone(body)
        } else if (body.email) {
          this.updateEmail(body)
        } else this.activeModal.dismiss()
      } else { this.activeModal.dismiss() }

    } else {
      this.error = true
      this.errorMessage = 'Please complete form'
    }
  }



  updatePersonalDetails(body) {
    this.professionalGlowService.updateServiceAgentDetails(body).subscribe((resp) => {
      console.log(resp)
      this.serviceAgentService.getServiceAgent()
    }, err => {
      this.setError()

    })
  }

  updateEmail(body) {
    this.professionalGlowService.updateServiceAgentEmail(body).subscribe((resp) => {
      // console.log(resp)
      this.serviceAgentService.getServiceAgent()
    }, err => {
      this.setError()

    })
  }

  updatePassword(body) {
    this.professionalGlowService.updateServiceAgentPassword(body).subscribe((resp) => {
      // console.log(resp)
      this.serviceAgentService.getServiceAgent()
    }, err => {
      this.setError()

    })
  }
  
  updatePhone(body) {
    this.professionalGlowService.updateServiceAgentPhone(body).subscribe((resp) => {
      // console.log(resp)
      this.serviceAgentService.getServiceAgent()
    }, err => {
      this.setError()
    })
  }

  updatePhoto(body, imageType) {
    this.professionalGlowService.updateServiceAgentPhoto(body, imageType).subscribe((resp) => {
      // console.log(resp)
      // this.serviceAgentService.getServiceAgent()
    }, err => {
      console.warn(err.statusCode)
      console.log('error', err.error.statusCode)
      this.setError()
    })
  }


  setError() {
    this.errorMessage = 'Something went wrong'
    this.error = true
    this.loading = false
  }
  async setImageSourceAndClass(blob, id) {
    const imgElm = await Utils.waitForElemById(id);
    if (!imgElm) return;
    const scr = (await Utils.getBlobImageSource(blob)) as string;
    imgElm.setAttribute('src', scr);
  }

  async fileInputChange(event, controlName, form: FormGroup) {
    this.error = false
    this.showPhotoSizeErrorMessage = false
    this.showLogoSizeErrorMessage = false
    const files = event.target.files[0];
    console.log(files.size)
    if (files.size > 1000000 && controlName == 'photo') {
      this.showPhotoSizeErrorMessage = true
    }
    if (files.size > 1000000 && controlName == 'logo') {
      this.showLogoSizeErrorMessage = true
    }
    form.get(controlName).setValue(files);
    await this.setImageSourceAndClass(files, controlName)


  }

  async checkImageChange(controlName, form) {
    // console.log(form.controls[controlName].value)
    const files = form.controls[controlName].value
    const formData: any = await Utils.formDataFromImgFile(files, '')
    this.updatePhoto(formData, controlName)
  }






}
