import { Component, OnInit } from '@angular/core';
import { FormArray, Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { tLoadingElement } from 'src/app/services/app-config.typings';
import { FormValidationService } from 'src/app/services/form-validation.service';
import { GlowService } from 'src/app/services/glow.service';
import { HpJourneyService } from 'src/app/services/hp-journey.service';
import { InvitationsService } from 'src/app/services/invitations.service';
import { SchematicsService } from 'src/app/services/schematics.service';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';
import { FormValidator } from 'src/app/services/user-validators';
import { VirtualEntityService } from 'src/app/services/virtual-entity.service';
import { Utils } from 'src/utils';
declare var require: any;
var FileSaver = require('file-saver');
@Component({
  selector: 'app-hp-journey-modal',
  templateUrl: './hp-journey-modal.component.html',
  styleUrls: ['./hp-journey-modal.component.scss']
})
export class HpJourneyModalComponent implements OnInit {
  invitationForm: FormGroup
  siteVisitForm: FormGroup
  stepNumber: Number = 1
  steps
  error: boolean
  sendEstimateRequestError: boolean
  schematicDocument
  loading: boolean = false
  allVESensorList
  passport;
  account: any
  loadObj: tLoadingElement
  lastMonth: string
  lastYear: string
  installers: Array<any>
  emailError: boolean
  setDateErrorMessage: string
  errorMessage: string
  successMessage: string
  acceptedEstimateRequests = []
  estimateRequestsWithSnapshot
  allEstimateRequests;
  allEstimates = []
  mostRecentSnapshot
  manualHTC
  virtualEntities
  pendingEstimateRequests;
  validVEs
  invalidVEs
  respondToInviteError: boolean
  stepsSubscription: Subscription
  schematicSubscription: Subscription
  subscriptionSensorVirtualEntityList: Subscription
  estimateRequestsSubscription: Subscription
  subscriptionVirtualEntityList: Subscription
  constructor(private activeModal: NgbActiveModal,
    private hpJourneyService: HpJourneyService,
    private glowService: GlowService,
    private fb: FormBuilder,
    private formValidationService: FormValidationService,
    private veService: VirtualEntityService,
    private router: Router,
    private schematicService: SchematicsService,
    private invitationsService: InvitationsService,
    private ServiceSelectorSvc: ServiceSelectorSvc) {


    this.stepsSubscription = this.hpJourneyService.stepsAnnouncer.subscribe(steps => {
      this.steps = steps

    })
    this.siteVisitForm = this.fb.group({ estimateRequest: FormArray })
    this.estimateRequestsSubscription = this.invitationsService.EstimateRequestsByVeIdAnnouncer.subscribe((resp) => {
      this.acceptedEstimateRequests = []
      if (resp.valid) {
        this.allEstimateRequests = resp.estimateRequests

        this.pendingEstimateRequests = resp.estimateRequests.filter(estimateRequest => estimateRequest.estimateRequestState == 'pending-user-eligibility' || estimateRequest.estimateRequestState == 'pending-user-acceptance')

        if (this.checkPendingEstimateRequests()) {
          this.hpJourneyService.setField('pendingInvitations', true)
        } else {
          this.hpJourneyService.setField('pendingInvitations', false)
        }


        this.estimateRequestsWithSnapshot = resp.estimateRequests.filter(estimateRequest => estimateRequest.estimateRequestState == 'estimate-request-accepted-by-user'
          || estimateRequest.estimateRequestState == 'estimate-request-accepted-by-professional'
          || estimateRequest.estimateRequestState === 'estimate-response-submitted' || estimateRequest.estimateRequestState === 'site-visit-arranged' || estimateRequest.estimateRequestState === 'pending-professional-acceptance' || estimateRequest.estimateRequestState === 'pending-professional-registration')

        this.acceptedEstimateRequests = this.invitationsService.getAllActiveEstimateRequests(resp)


        if (this.acceptedEstimateRequests.length) {
          this.enableSteps(['stepTwoDisable'])
          // this.stepNumber = 2
        }
        this.createFormGroup(this.acceptedEstimateRequests)
        this.getEstimate(this.acceptedEstimateRequests)
        //to do - get pending - professioanl acceptance
        if (this.estimateRequestsWithSnapshot.length) {
          this.getSnapshot(this.estimateRequestsWithSnapshot[this.estimateRequestsWithSnapshot.length - 1].snapshotIds[0].snapshotId)
        }
      } else {
        this.error = true
        // this.loading = false
      }
    })

    this.subscriptionSensorVirtualEntityList = this.veService.sensorVirtualEntities$.subscribe(async (sensorList) => {
      if (sensorList && Array.isArray(sensorList)) {
        this.allVESensorList = sensorList;
        this.manualHTC = await this.getLatestHTC()
 
      }
    });
    this.schematicSubscription = this.schematicService.schematicDocumentsAnnouncer.subscribe(schem => {
      this.schematicDocument = schem
    })

    this.invitationForm = this.fb.group({
      installers: ['', [Validators.required]],
      installationDate: ['', [Validators.required]],
      spaceHotWaterTank: ['', [Validators.required]],
      // selectedService: ['', [Validators.required]],
      radiatorSchematic: ['', []],
      newRadiatorsInstalled: ['', [Validators.required]],
      htc: ['', []]
    });


  }




  async ngOnInit() {
    this.hpJourneyService.resetSteps()
    this.loadObj = { ...this.loadObj, loadError: false, loading: false, errorString: '' };
    const accountId = localStorage.getItem("id");
    this.getAccount(accountId)
    this.getAssessmentPeriodsDate()
    this.schematicDocument = await this.hpJourneyService.getSchematicDocuments()
    // console.log(this.schematicDocument)
    this.passport = await this.hpJourneyService.getPropertyPassport()
    this.veService.findVirtualEntities();
    this.getEstimateRequests()
    this.checkFormStatus()

  }


  checkPendingEstimateRequests() {
    return this.pendingEstimateRequests && this.pendingEstimateRequests.length
  }

  updateHTCStep() {
    if (this.invitationForm.controls.htc.value) {
      this.hpJourneyService.setField('htc', true)
    }
  }
  // checkCanAcceptRequest() {
  //   return (!this.invitationForm.controls.installationDate.valid && !this.invitationForm.controls.spaceHotWaterTank.valid
  //     && !this.invitationForm.controls.newRadiatorsInstalled.valid && this.passport && !this.loading ||
  //     !this.invitationForm.controls.installationDate.valid && !this.invitationForm.controls.spaceHotWaterTank.valid
  //     && !this.invitationForm.controls.newRadiatorsInstalled.valid && !this.passport && !this.loading || this.loading)

  // }
  updateSchematicStep() {
    if (this.invitationForm.controls.radiatorSchematic.value) { this.hpJourneyService.setField('schematic', true) }
  }
  async getLatestHTC() {
    const selectedService = this.ServiceSelectorSvc.getSelectedService()
    this.glowService.getHTCHistory(selectedService.veId).toPromise().then((resp: any) => {
      if (resp.valid) {
        this.manualHTC = resp.data[0]
        // console.log(resp)
        return resp.data[0]

      }
      else return
    }, (err) => {

    })

  }





  enableSteps(steps) {
    steps.forEach(step => {
      this.hpJourneyService.setField(step, true)
    })
  }




  createFormGroup(estimateRequests) {
    let estimateRequestForms = []
    estimateRequests.forEach(er => {
      estimateRequestForms.push(this.fb.group({ siteVisitDate: ['', [Validators.required]], estimateRequestId: [er.estimateRequestId] }))
    })
    this.siteVisitForm = this.fb.group({
      estimateRequests: new FormArray(estimateRequestForms)
    })

  }
  setQuestionsOnFormFromSnapshot(questions) {
  
    this.invitationForm.patchValue({ 'spaceHotWaterTank': questions.spaceHotWaterTank ? 'Yes' : 'No' })
    this.invitationForm.patchValue({ 'newRadiatorsInstalled': questions.newRadiatorsInstalled ? 'Yes' : 'No' })
    this.invitationForm.patchValue({ 'installationDate': questions.installationDate })
    this.checkFormStatus()
  }

  getSnapshot(id) {
    this.glowService.getSnapshotBySnapshotId(id).subscribe(async resp => {
      if (resp) {
      
        this.mostRecentSnapshot = resp
        this.setQuestionsOnFormFromSnapshot(this.mostRecentSnapshot.overview)
      }
      return resp
    })
  }

  checkEmailValidity(installerArray) {
    this.emailError = false;
    let sanitisedEmails = [];
    installerArray.forEach((obj) => {
      sanitisedEmails.push({ value: obj['value'].replace(/[ \t]+$/, '') });
    });
    this.installers = sanitisedEmails;
    sanitisedEmails.forEach((obj) => {
      if (FormValidator.isEmailValid(obj)) {
        this.emailError = true;
      } else {
        this.hpJourneyService.setInstallers(installerArray)
      }

    });
  }

  setInstallerEmail(event) {
    if (event.length) {
      this.installers = event;
      this.checkEmailValidity(event);
    } else {
      this.hpJourneyService.setInstallers(event)
    }



  }

  resetError(formControl) {
    this.invitationForm.controls[formControl].markAsUntouched();
    this.error = false;
  }

  changeSection(e) {
    this.stepNumber = e

  }

  getSchematics() {
    this.schematicDocument = this.hpJourneyService.schematicDocuments
    // console.log(this.schematicDocument)

  }
  checkFormValidity(formControlName, translateKey?) {
    return this.formValidationService.checkFormValidity(this.invitationForm, formControlName, translateKey);
  }

  checkFormStatus() {
    if (this.invitationForm.controls.spaceHotWaterTank.valid && this.invitationForm.controls.installationDate.valid && this.invitationForm.controls.newRadiatorsInstalled.valid) {
      this.hpJourneyService.setField('formFilledOut', true)
    } else return
  }

  getStyle(completion, icon?) {
    if (completion) {
      return `text-success ${icon ? icon : ''} `
    } else return `text-dark fw-bolder ${icon ? icon : ''} `


  }


  navigateToSchematicsPage() {
    this.router.navigateByUrl('/pages/tools/heating-by-room/')
    this.activeModal.dismiss()

  }
  getDateIn30Days() {
    return Utils.getDateIn30Days();
  }

  selectSchematic(doc) {
    const schematicControl = this.invitationForm.controls['radiatorSchematic']
    schematicControl.setValue(doc)
    schematicControl.markAsTouched()
  }


  sendInvite() {
    if (this.invitationForm.valid) {
      this.invitationForm.value.installers.forEach((installer) => {
        this.generateEstimateRequest(installer.value);
      });

    } else {
      this.invitationForm.markAllAsTouched();
      this.errorMessage = 'Please complete form';
    }
  }

  getAccount(accountId: string) {
    this.glowService.getAccount().subscribe(account => {
      this.account = account
    }, error => {
      console.warn("An error occurred when retrieving account");
      console.warn(error)
    });
  }
  getEstimateRequests() {
    const selectedService = this.ServiceSelectorSvc.getSelectedService()
    this.invitationsService.getEstimateRequestsByVeIdAndState(selectedService.veId, true)

  }
  generateEstimateRequest(installer) {
    this.error = false
    this.loading = true;

    const selectedService = this.ServiceSelectorSvc.getSelectedService()
    const body = {
      username: this.account.email,
      estimateType: 'heat-pump-estimate',
      initiatedBy: 'customer',
      professionalUsername: installer,
      needsToBeAcceptedBy: this.getDateIn30Days(),
      assessmentPeriodStart: this.lastYear,
      assessmentPeriodEnd: this.lastMonth,
      // htc: this.invitationForm.value.htc,
      overview: {
        hotWaterTankSpace: this.invitationForm.value.spaceHotWaterTank == 'Yes' ? true : false,
        newRadiatorsFitted: this.invitationForm.value.newRadiatorsInstalled == 'Yes' ? true : false,
        installationDate: this.invitationForm.value.installationDate,
      },
      heatingSystem: {
        veDocId: this.invitationForm.value.radiatorSchematic.documentId
      }
    }
    this.glowService.createEstimateRequest(body, selectedService.veId).subscribe((resp) => {
      // this.loading = true;
      if (!resp.valid) {
        this.loadObj = {
          ...this.loadObj,
          loadError: true,
          loading: true,
          errorString: 'Unable to send invitation'
        };
        this.sendEstimateRequestError = true;
        this.loading = false;
        // this.invitationsService.getAllEstimateRequests();
      } else {
        this.loadObj = {
          ...this.loadObj,
          loadError: false,
          loading: false,
        };
        this.loading = false;
        this.error = false;
        this.invitationForm.controls['installers'].reset()
        this.hpJourneyService.setField('installerField', false)
        this.successMessage = "Invitation sent successfully"
        // this.invitationsService.getAllEstimateRequests();
      }
    }, (err) => {
      this.sendEstimateRequestError = true
      this.loading = false
      this.loadObj = {
        ...this.loadObj,
        loadError: true,
        loading: true,
        errorString: 'An error occurred. Try again later.'
      };
    })

  }
  getAssessmentPeriodsDate() {
    var date = new Date(new Date().setMonth(new Date().getMonth() - 1));
    this.lastMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split('T')[0] + 'T23:59:59';

    var t = new Date();
    let lastYear = new Date(new Date(new Date(t.setFullYear(t.getFullYear() - 1))).setDate(1));
    this.lastYear = new Date(lastYear).toISOString().split('T')[0] + 'T00:00:00';
  }



  getEstimate(estimateRequests) {
    let allEstimates = []
    let estimateIds = []
    estimateRequests.forEach(er => {
      if (er.estimateIds) {
        er.estimateIds.map(x => estimateIds.push(x))
      }
    })
    estimateIds.forEach(id => {
      this.getEstimateByEstimateId(id.estimateId).then(resp => { allEstimates.push(resp) })

    })
    this.allEstimates = allEstimates

  }


  getEstimateByEstimateId(estimateId) {
    const response = this.glowService.getEstimateCustomer(estimateId).toPromise()
    return response

  }

  async downloadFile(estimate) {
    const ve = this.ServiceSelectorSvc.getSelectedService()
    estimate.veDocIds.forEach(doc => {
      this.glowService.getVEDocumentByIDMedia(ve.veId, doc.veDocId).then(resp => {
        this.saveAsBlob(resp)
      })
    })
  }

  saveAsBlob(resp) {
    var blob = new Blob([resp], { type: 'application/pdf' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob);
    link.download = 'file';
    const event = new MouseEvent('click')
    link.dispatchEvent(event)

  }

  setSiteVisit(estimateRequestId, siteVisitDate) {
    const body = { siteVisitDate: siteVisitDate }
    this.glowService.updateEstimateRequestByCustomer(estimateRequestId, body).subscribe(resp => {
      if (resp.valid) {
        this.getEstimateRequests()
        this.loadObj = {
          ...this.loadObj,
          loadError: false,
          loading: false,
        };
      } else {
        this.setDateErrorMessage = 'An error occurred.  Please try again later.'
      }

    }, (err) => {
      this.setDateErrorMessage = 'An error occurred.  Please try again later.'

    })
  }


  getEstimateRequestStateFormat(state) {
    return this.invitationsService.formatEstimateRequestState(state)
  }

  getReadableDate(date) {
    return Utils.getReadableDateFromIso(date)
  }
}
