import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormValidationService } from 'src/app/services/form-validation.service';
import { GlowService } from 'src/app/services/glow.service';
import { StrapiService } from 'src/app/services/strapi.service';
import { FormValidator } from 'src/app/services/user-validators';

@Component({
  selector: 'app-system-incidents-mail-subscription-modal',
  templateUrl: './system-incidents-mail-subscription-modal.component.html',
  styleUrls: ['./system-incidents-mail-subscription-modal.component.scss'],
})
export class SystemIncidentsMailSubscriptionModalComponent implements OnInit {
  formInfo: FormGroup;
  formErrorMessage: boolean
  constructor(
    private strapiService: StrapiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private router: Router,
    private activeModal: NgbActiveModal,
    private glowService: GlowService,
    private formValidationService: FormValidationService
  ) {}

  ngOnInit() {
    this.initFormInfo();
  }

  close(result) {
    this.activeModal.close(result);
  }
  checkFormValidity(formControlName, translateKey?){
    return this.formValidationService.checkFormValidity(this.formInfo,formControlName, translateKey)
   }
   showErrorMessage() {
    if (!this.formInfo.valid) {
      this.formErrorMessage = true
      this.formValidationService.markFormGroup(this.formInfo, 'touched')
    }
  }
  initFormInfo() {
    this.formInfo = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: [
        '',
        [Validators.required, FormValidator.isEmailValid],
      ],
      noticeOfCompletion: [false],
    });
  }

  async submitInfo() {
    if (!this.formInfo.valid){
      this.formValidationService.markFormGroup(this.formInfo, 'touched')
    } else {
    this.strapiService
      .addSystemIncidentsMailSubscriber(this.formInfo.value)
      .then((response) => {
        console.log(response);
        this.formInfo.reset();
        this.sendUserAction(this.userActionBody(response.data.id));
        this.close({ response, status: 'success' });
        return response;
      })
      .catch((error) => {
        this.formInfo.reset();
        this.formInfo.reset();
        this.close({ error, status: 'error' });
        return error;
      }); 
    }
  }
  userActionBody(id) {
    return {
      isNew: true,
      reference: 'IncidentReportingSubscription',
      data: {
        subscriptionId: id,
      },
    };
 
  }

  sendUserAction(action) {
    this.glowService.sendUserAction(action).subscribe(
      (resp) => {
      },
      (error) => {
      }
    );
  }
}
