import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-professional-settings',
  templateUrl: './professional-settings.component.html',
  styleUrls: ['./professional-settings.component.scss', '../../../pages-professional/pages-professional.component.scss']
})
export class ProfessionalSettingsComponent implements OnInit {
  pageTitle = "Settings"
  currentPage = ''
  pages = [
    { path: '/professional/account/settings/profile', title: 'Profile', urlPath: 'profile' },
    { path: '/professional/account/settings/account-information', title: 'Account information', urlPath: 'account-information' },

  ]
  private currentPageAnnouncer = new Subject<any>();
  currentPage$ = this.currentPageAnnouncer.asObservable()

  constructor() { }

  ngOnInit() {
  }
  public onRouterOutletActivate(event: any) {
    if (event.pageTitle) {
      this.currentPageAnnouncer.next(event.pageTitle)
      this.currentPage = event.pageTitle;
    }
    // // console.log("EVENT",event);
  }
  isCurrentPage(page) {
    return page.title == this.currentPage;
  }

  getOtherPages() {
    return this.pages.filter(p => p.title != this.currentPage)
  }
}

