import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeEnergyDataComponent } from './home-energy-data.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { HeatLossParameterScaleModule } from 'src/app/components/heat-loss-parameter-scale/heat-loss-parameter-scale.module';
import { EpcCertificateModule } from 'src/app/components/epc-certificate/epc-certificate.module';
import { RadiatorSchematicModule } from 'src/app/components/radiator-schematic/radiator-schematic.module';



@NgModule({
  declarations: [HomeEnergyDataComponent],
  imports: [
    CommonModule, SharedModule, HeatLossParameterScaleModule, EpcCertificateModule, RadiatorSchematicModule
  ],
  exports: [HomeEnergyDataComponent]
})
export class HomeEnergyDataModule { }
