import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponseModalComponent } from './response-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerModule } from '../spinner/spinner.module';

@NgModule({
  declarations: [ResponseModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SpinnerModule,
    FormsModule
  ],
  exports: [ResponseModalComponent],
  entryComponents: [ResponseModalComponent],
  
})

export class ResponseModalModule { }