import { Component, Input, OnInit } from '@angular/core';
import { Utils } from 'src/utils';
import { Router } from '@angular/router';
import { LandingService } from 'src/app/services/landing.service';
import { StrapiService } from 'src/app/services/strapi.service';

@Component({
  selector: 'app-blog-card',
  templateUrl: './blog-card.component.html',
  styleUrls: ['./blog-card.component.scss'],
})

export class BlogCardComponent implements OnInit {

  @Input() blog!: any;
  @Input() shouldDisplaySummary: boolean = false;
  @Input() shouldDisplayCommunityTag: boolean = false;
  @Input() titleStyle: any = {};
  @Input() backgroundStyle: any = {}
  @Input() titleLineHeight: any = {}
  @Input() displayTimeToRead: boolean = true

  timeToRead = 1;
  createdAt: string;
  relativePath:string
  constructor(private router: Router, private strapiService: StrapiService) { }

  ngOnInit(): void { 
    this.timeToRead = this.getTimeToRead();
    this.createdAt = this.getCreatedAt();
    this.relativePath=this.blogRelativePath()
  }

  getCreatedAt() {
    return Utils.formatZDate(this.blog.attributes.createdAt);
  }

  getTimeToRead() {
    return Utils.getTimeToRead(this.blog.attributes.content);
  }

  blogRelativePath(){
    return `/home/blogs/${this.blog.attributes.slug}`
  }

  isBookmarked(){
    return this.strapiService.isContentBookmarked(this.blog.attributes.slug)
  }

  bookmarkBlog(){
    this.strapiService.bookmarkContent(this.blog.attributes.slug, 'blogs')
  }

  unbookmarkBlog(){
    this.strapiService.unbookmarkContent(this.blog.attributes.slug)
  }

  // navigateToBlog() {
  //   // this.landingService.setBlogId(this.blog.id);
  //   // this.landingService.setContentToDisplayId(this.blog.id)
  //   this.router.navigateByUrl(`home/blog/${this.blog.attributes.slug}`);
  // }


}