
import { Component, Input, OnInit } from '@angular/core';

import { ViewCell } from 'ng2-smart-table';

@Component({
  template: `
    {{renderValue | number:'1.2-2'  }}
  `,
})
export class CustomRenderNumberOfDigitsComponent implements ViewCell, OnInit {

  renderValue: number;

  @Input() value: any;
  @Input() rowData: any;

  ngOnInit() {
    this.renderValue = this.value
  }

}