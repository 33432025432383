import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeatPumpCaseStudyComponent } from './heat-pump-case-study.component';
import { CaseStudyFormStepperModule } from 'src/app/components/case-study-form-stepper/case-study-form-stepper.module';
import { BuildingFormModule } from 'src/app/components/case-study/building-form/building-form.module';
import { HeatingSystemFormModule } from 'src/app/components/case-study/heating-system-form/heating-system-form.module';
import { OutcomesFormModule } from 'src/app/components/case-study/outcomes-form/outcomes-form.module';
import { InstallationFormModule } from 'src/app/components/case-study/installation-form/installation-form.module';
import { OtherInfoFormModule } from 'src/app/components/case-study/other-info-form/other-info-form.module';
import { HeatPumpCaseStudyRouting } from './heat-pump-case-study.routing';

@NgModule({
  declarations: [HeatPumpCaseStudyComponent],
  imports: [CommonModule, HeatPumpCaseStudyRouting, CaseStudyFormStepperModule, BuildingFormModule, HeatingSystemFormModule, OutcomesFormModule, InstallationFormModule, OtherInfoFormModule],
  exports: [HeatPumpCaseStudyComponent],
})
export class HeatPumpCaseStudyModule {}
