import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseStudyDetailsComponent } from './case-study-details.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { StarRatingModule } from '../../star-rating/star-rating.module';
import { CaseStudyRelationTableModule } from '../case-study-relation-table/case-study-relation-table.module';

@NgModule({
  declarations: [CaseStudyDetailsComponent],
  imports: [CommonModule, SharedModule, StarRatingModule, CaseStudyRelationTableModule],
  exports: [CaseStudyDetailsComponent],
})
export class CaseStudyDetailsModule {}
