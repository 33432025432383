import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountNoDataCardComponent } from './account-no-data-card.component';



@NgModule({
  declarations: [AccountNoDataCardComponent],
  imports: [
    CommonModule
  ],
  exports: [AccountNoDataCardComponent]
})
export class AccountNoDataCardModule { }
