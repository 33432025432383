import { environment } from './environments/environment';

export class Utils {
  static async promiseHandler(promise: Promise<any>): Promise<any> {
    try {
      const data = await promise;
      return [null, data];
    } catch (error) {
      return [error, null];
    }
  }

  static setNumbersInObject(obj: any) {
    for (let key in obj) {
      if (obj[key] instanceof Blob) continue;
      if (typeof obj[key] === 'object') {
        this.setNumbersInObject(obj[key]);
      } else if (typeof obj[key] === 'string' && !isNaN(Number(obj[key]))) {
        obj[key] = +obj[key];
      }
    }
    return obj;
  }

  static async toFormData(obj: any) {
    console.log(obj)
    const formData = new FormData();
    for (let key in obj) {
 
      formData.append(String(key), obj[key]);
    }

 
    return formData;
  }

  static formDataFromFiles(fileList: FileList): FormData {
    const formData = new FormData();
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i] as any;
      formData.append(`file${i}`, file);
      formData.append(`${i}`, JSON.stringify({ description: file.description, width: file.width, height: file.height }));
    }
    return formData;
  }

  static async formDataFromImgFile(imageFile: File, description: string) {
    if (!imageFile || !imageFile.name) return;
    const fr = new FileReader();
    const dataUrl = await new Promise<string>((resolve) => {
      fr.onload = () => resolve(fr.result as string);
      fr.readAsDataURL(imageFile);
    });

    const img = new Image();
    await new Promise<void>((resolve) => {
      img.onload = () => resolve();
      img.src = dataUrl;
    });

    const imageFormValue = {
      file: imageFile,
      width: img.width,
      height: img.height,
      description,
    };

    return Utils.toFormData(imageFormValue);
  }

  static getImageOrientation(imageInfo: { width: number; height: number }) {
    return imageInfo.width > imageInfo.height ? 'landscape' : 'portrait';
  }

  static getFileDimensions({ file, url }: { file?: File; url?: string }): Promise<{ width: any; height: any }> {
    if (!file && !url) return Promise.reject('No file or url provided');
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = reject;
      img.src = file ? URL.createObjectURL(file) : url;
    });
  }

  static getBlobImageSource(blob) {
    return new Promise((resolve, reject) => {
      if (!blob) {
        reject(new Error('Invalid Blob'));
      }
      console.log(blob)
      const fr = new FileReader();

      fr.onload = function () {
        resolve(fr.result);
      };

      fr.onerror = function () {
        reject(new Error('Error reading Blob'));
      };

      fr.readAsDataURL(blob);
    });
  }

  static extractUKPostcodeFirstPart(inputString) {
    // Define a regular expression pattern for UK postcodes
    const postcodePattern = /([A-Za-z]{1,2}\d{1,2})(\d[A-Za-z]{2})?/i;

    // Search for the postcode pattern in the input string
    const matches = inputString.match(postcodePattern);
    // If a match is found, return the first part; otherwise, return null
    if (matches && matches.length > 1) {
      return matches[1];
    } else {
      return null;
    }
  }

  static pluralContentType(singularContentType) {
    const mapping = {
      blog: 'blogs',
      article: 'articles',
      'resources-list': 'resources-lists',
      tutorial: 'tutorials',
      infographic: 'infographics',
    };
    return mapping[singularContentType];
  }

  static getTimeToRead(longString: string) {
    const wordsPerMinute = 200;
    return Math.round(longString.split(' ').length / wordsPerMinute);
  }

  static splitTextOnNewLines(text: string) {
    return text.split(/\r\n|\n/);
  }

  static formatZDate(stringZDate: string) {
    return new Date(stringZDate).toDateString().split(' ').slice(1).join(' ');
  }

  static formatDateToDdMmYyyy(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  static getApiServerAddress() {
    return environment.contentSystemBaseUrl;
  }

  static removeHTMLFromString(string: string = '') {
    const regex = /(<([^>]+)>)/gi;
    return string.replace(regex, '');
  }

  static fromatSearchContent(content) {
    // console.log("Formatting",content)
    let formatted = { ...content };
    const idFragments = formatted.id.split('-');
    const contentType = this.pluralContentType(idFragments.slice(0, -1).join('-'));
    for (let key in formatted) {
      let value = formatted[`${key}`];
      if (value && value.constructor === Array) {
        const formattedArray = value.map((obj) => {
          return { attributes: obj };
        });
        formatted[`${key}`] = { data: formattedArray };
      } else if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
        formatted[`${key}`] = { data: { id: value['id'], attributes: value } };
      }
    }
    formatted = {
      id: idFragments[idFragments.length - 1],
      attributes: formatted,
      contentType: contentType,
    };
    // console.log(formatted);
    return formatted;
  }

  static waitForElemById(id): Promise<any> {
    return new Promise((resolve) => {
      if (document.getElementById(id) as HTMLInputElement) {
        return resolve(document.getElementById(id) as HTMLInputElement);
      }

      const observer = new MutationObserver((mutations) => {
        if (document.getElementById(id) as HTMLInputElement) {
          resolve(document.getElementById(id) as HTMLInputElement);
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
    console.log('waitforelemebtby iud')
  }

  static getReadableDateFromIso(isoString) {
    var d = new Date(isoString);
    return d.toLocaleDateString('en-GB');
  }
  static getDateIn30Days() {
    var date = new Date();
    return new Date(date.setDate(date.getDate() + 30)).toISOString();
  }

  static getNumberToDecimalPlace(number, decimalPlace = 1) {
    if (!number) return 0;
    return Math.round(number * Math.pow(10, decimalPlace)) / Math.pow(10, decimalPlace);
  }
}
