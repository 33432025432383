import { Injectable, EventEmitter } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse, HttpParams } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { JwtHelperService } from '@auth0/angular-jwt';

import {
  tAccount,
  tVE,
  tVEType,
  tVEwithDetailedResources,
  tFunctionalGroupAccount,
  tDevice,
  tDeviceStatus,
  tDeviceProductDiscovery,
  tProvisioningStatus,
  tDiscoveryInfo,
  tResource,
  tResourceType,
  tResourceTariff,
  tResourceReadings,
  tGroupedResource,
  tResourceInstantReadings,
  tResourceMeterReading,
  tInboxMessage,
  tInboxStatus,
  tInboxDeleteStatus,
  tInboxMessageList,
  tApplicationVersions,
  tFAQ,
  tSupportDetails,
  tAccountStatus,
  tTokenValidityResponse,
  tNewTokenResponse,
  tLoginInfo,
  tRegistrationInfo,
  tApiErrorResponse,
  tGlowStickAccessPointPassword,
  tPrompt,
  tPromptUser,
  tTermsConditionsUser,
  tTermsConditions,
  tContent,
  tUserAction,
  tLanguageContent,
  tPromptUserReq,
  tApplicationEventReq,
  tApplicationEventRes,
  tResourceSummaryReadings,
  tVEwithGroupedChildren,
  tVeState,
  tVeIdListing,
  tUserGroup,
  tUserOrganization,
  tProvisioningAddChildDeviceReq,
  tAlertHistoryUserGroup,
  tVeGroup,
  tAlertHistoryUser,
  tAlertType,
  tAlertStatus,
  tTemplate,
  tOAuthResponse,
  tIsValid,
  tSavedCards,
  tUserGroupCreateRes,
  tUserGroupCreateReq,
  tUserGroupAppPermissionsReq,
  tUserGroupAdminDeletionRes,
  tUserIdElement,
  tUserGroupAdminAdditionReq,
  tUserGroupAdminAdditionRes,
  tUserGrouping,
  tUserFromUserGroupDeletionRes,
  tVeAccessAddUser,
  tVeAccessAddUserGroup,
  tVeAccessAdditionRes,
  tVeAccessPermission,
  tUserOrgCreateReq,
  tDeviceWithSetting,
  tIsUserGroupValid,
  tIsUserOrganizationValid,
  tGlowEventReq,
  tStatusResponse,
  tUserGroupAdmin,
  tDeviceRegisters,
  tSetPasswordReq,
  tTicketCreateResponse,
  tForgottenPasswordResponse,
  tIsValidPaymentRes,
  tPaymentTransactionCallbackInfo,
  tHasVeAccess,
  tHasVeAdminAccess,
  tTermsConditionsUserRaw,
  tTermsConditionsUserCreateRes,
  tResourceFirstTime,
  tDataGroupCreateReq,
  tChangeUsernameReq,
  tUserGroupingWithUserInfo,
  tUserGroupAdminWithUserInfo,
  tCalculateHTC,
  tEuiPostcode,
  tEligibitySmetsIhdPostcodeCheck,
  tEligibitySmetsFuelTypesReq,
  tEligibitySmetsFuelTypes,
  tAccountProfile,
  tAccountProfilesResp,
  tAccountProfileCreateResp,
  tResourceLastTime,
  tSimulation,
  tIsValidTicket,
  tJournalEntry,
  tComponent,
  tCaseStudy,
  tVerificationStatus,
  tVerificationInfoSubmissionIsValidRes,

  //   tmeterPointVerificationList,
  //   tDeviceBalance
} from './glow.typings';

import { ConfigService } from './app-config.service';
import { DateFormatService } from './date-format.service';
import { CacheService } from './cache.service';
import { AuthenticationService } from './authentication.service';

export class AppIdHeadersGlow extends Headers {
  constructor(appId: string, functionalGroupId = null) {
    super();
    this.append('X-GLOW-Version', '0');
    this.append('Content-type', 'application/json');
    this.append('token', localStorage.getItem('id_token'));
    this.append('applicationId', appId);
    // console.log('AppIdHeaders ' + appId);
    if (functionalGroupId) {
      this.append('functionalGroupId', functionalGroupId);
    }
  }
}

@Injectable()
export class GlowService {
  private applicationId: string;
  private applicationName: string;
  private directoryId: string;
  private glowDirectoryId: string;
  private hostUrl; // Check CORS?
  private hostJournalUrl = 'http://localhost:3066/api/v0-1/';
  private authFunctionalGroupId: string;
  private tempAccessDirectoryId: string;
  public getAuthEvent: EventEmitter<any> = new EventEmitter();
  private environment: string;

  private rateLimitingHostUrl: string;

  constructor(private configService: ConfigService, private dateFormatService: DateFormatService, private http: HttpClient, public jwtHelper: JwtHelperService, private auth: AuthenticationService, private storage: CacheService) {
    const configuration = this.configService.loadConfig();
    this.applicationId = configuration.application.applicationId;
    this.applicationName = configuration.application.apipathname;
    // this.hostUrl = configuration.environments.prod.glow.endpoint;
    this.hostUrl = configuration.environments.prod.glow.endpoint;
    this.rateLimitingHostUrl = configuration.environments.prod.glow.rateLimitingHostUrl;
    this.directoryId = configuration.directories.default.directoryId;
    this.glowDirectoryId = configuration.directories.glow.directoryId;
    this.tempAccessDirectoryId = configuration.directories.tempAccess.directoryId;

    if (configuration.hasOwnProperty('functionalGroups') && configuration.functionalGroups.hasOwnProperty('auth')) {
      this.authFunctionalGroupId = configuration.functionalGroups.auth;
    }

    // this.events.subscribe('user:loggedIn', () => {
    //   // console.log('glow service got loggedIn event');
    // });

    // this.events.subscribe('user:logout', () => {
    //   // console.log('glow service got Log OUT event');
    // });
  }

  ngOnDestroy() {}

  // ************************* Environment Functionality *************************/
  /**
   * Function responsible for setting environmnet as given by username and also clean up username from env
   */
  checkEnvironment(username: string): string {
    // console.log('checkEnvironment ' + username);

    const configuration = this.configService.loadConfig();
    const supportedEnvironments = Object.keys(configuration.environments).map((env) => env);

    const usernameNoDomain = username.split('@')[0];
    const usernameSetsEnvironmentCheck = /\[.*\]/;

    if (usernameSetsEnvironmentCheck.test(usernameNoDomain) === true) {
      // console.log('Need to set environment');
      const executedRegex = usernameNoDomain.match(usernameSetsEnvironmentCheck);
      if (executedRegex && executedRegex[0]) {
        const env = executedRegex[0].replace('[', '').replace(']', '');
        // console.log(`Setting environment to ${env}`);
        if (supportedEnvironments.indexOf(env) > -1) {
          this.setEnvironment(env);
        } else {
          console.warn(`env ${env} not in supported list, setting to: ${env}.glowmarkt.com`);
          const url = `https://${env}.glowmarkt.com/api/v0-1/`;
          this.setHostUrl(url);
        }
      }
    } else {
      // console.log('Setting environment to prod');
      this.setEnvironment('prod');
    }
    const usernameNoDomainSplit = usernameNoDomain.split('[');
    return usernameNoDomainSplit[0] + '@' + username.split('@')[1];
  }

  setHostUrl(hostUrl: string) {
    // console.log('Glowservice setting host to: ' + hostUrl);
    this.hostUrl = hostUrl;
  }

  setEnvironment(environment: string) {
    const configuration = this.configService.loadConfig();
    this.environment = environment;
    this.setHostUrl(configuration.environments[environment].glow.endpoint);
  }

  getEnvironment() {
    return this.environment ? this.environment : 'prod';
  }

  getHostUrl() {
    return this.hostUrl;
  }

  // ****************** API checks *********************/

  performPreAPIChecksWithAuth(): tApiErrorResponse {
    let response = {
      isError: false,
      messages: [],
      data: '',
    };
    const authCheck = this.performAuthCheck();
    const networkCheck = this.performNetworkCheck();
    if (authCheck.isError === true) {
      response.isError = true;
      response.messages = response.messages.concat(authCheck.messages);
    }
    if (networkCheck.isError === true) {
      response.isError = true;
      response.messages = response.messages.concat(networkCheck.messages);
    }
    return response;
  }

  performNetworkCheck(): tApiErrorResponse {
    let response = {
      isError: false,
      messages: [],
      data: '',
    };
    // if (this.networkActive === false) {
    //   response.isError = true
    //   response.messages = ['ERROR_NO_NETWORK']
    // }
    return response;
  }

  performAuthCheck(): tApiErrorResponse {
    let response = {
      isError: false,
      messages: [],
      data: '',
    };
    if (this.checkExistingToken() === false) {
      response.isError = true;
      response.messages = ['ERROR_TOKEN_NOT_VALID'];
    }
    return response;
  }

  performPreAPIChecksWithoutAuth(): tApiErrorResponse {
    return this.performNetworkCheck();
  }

  // Checks that do not check the network
  performPreAPIChecksNoNetwork(): tApiErrorResponse {
    return this.performAuthCheck();
  }

  public setHeaders(): AppIdHeadersGlow {
    const headers: Headers = new AppIdHeadersGlow(this.applicationId);
    return headers;
  }

  //******************** Headers *************/
  public getNoAuthHeaders(): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      applicationId: this.applicationId,
    });
    if (this.authFunctionalGroupId) {
      headers = headers.append('functionalGroupId', this.authFunctionalGroupId);
    }
    return headers;
  }

  public getNoAuthOptionsRequestInit(): RequestInit {
    return { headers: { applicationId: this.applicationId } };
  }

  public getAuthHeaders(): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      applicationId: this.applicationId,
      token: localStorage.getItem('id_token'),
      'X-GLOW-Version': '0',
    });
    if (this.authFunctionalGroupId) {
      headers = headers.append('functionalGroupId', this.authFunctionalGroupId);
    }
    return headers;
  }

  public getAuthHeadersCSV(): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/csv',
      applicationId: this.applicationId,
      token: localStorage.getItem('id_token'),
      'X-GLOW-Version': '0',
    });
    if (this.authFunctionalGroupId) {
      headers = headers.append('functionalGroupId', this.authFunctionalGroupId);
    }
    return headers;
  }

  public getNoAuthOptions() {
    const headers = this.getNoAuthHeaders();
    return { headers };
  }

  public getAuthOptions() {
    const headers = this.getAuthHeaders();
    return { headers };
  }

  public getOAuthOptions() {
    const headers = this.getOAuthHeaders();
    return { headers };
  }
  public getOAuthOptionsWithoutContentType() {
    const headers = this.getOAuthHeadersWithoutContentType();
    return { headers };
  }

  public getAuthHeadersWithUserId(userId: string) {
    let headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      applicationId: this.applicationId,
      // token: localStorage.getItem('id_token'),
      'X-GLOW-Version': '0',
      userId: userId,
    });
    if (this.authFunctionalGroupId) {
      headers = headers.append('functionalGroupId', this.authFunctionalGroupId);
    }
    return headers;
  }

  public getOAuthHeadersWithoutContentType() {
    let headers: HttpHeaders = new HttpHeaders({
      applicationId: this.applicationId,
      Authorization: this.auth.authorizationHeader(),
    });
    return headers;
  }

  public getOAuthHeaders() {
    let headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      applicationId: this.applicationId,
      Authorization: this.auth.authorizationHeader(),
    });
    return headers;
  }

  public getAuthOptionsUserId(userId: string) {
    const headers = this.getAuthHeadersWithUserId(userId);
    return { headers };
  }

  public getAuthOptionsCsv() {
    const headers = this.getAuthHeadersCSV();
    const options = { headers, responseType: 'text' as any };

    return options;
  }

  public getAuthOptionsAppId({ applicationId }: any = {}) {
    const headers = this.getAuthHeaders();
    // console.log(headers);
    return { headers };
  }

  public getImageAuthHeaders(): HttpHeaders {
    return new HttpHeaders({
      applicationId: this.applicationId,
      token: localStorage.getItem('id_token'),
      'X-GLOW-Version': '0',
    });
  }

  public getImageAuthOptions() {
    const headers = this.getImageAuthHeaders();
    return { headers };
  }

  // ************************** Service Agent **********************************/
  // public registerServiceAgent(body): Observable<any> {
  //   // console.log('registerServiceAgent');
  //   const response = this.performPreAPIChecksWithoutAuth();
  //   if (response && response.isError === false) {
  //     return this.http.post<any>(this.hostUrl + 'service-agent/register', body, this.getNoAuthOptions());
  //   } else {
  //     return throwError(response);
  //   }
  // }

  // getServiceAgent(): Observable<any> {
  //   const response = this.performPreAPIChecksWithoutAuth();
  //   if (response && response.isError === false) {
  //     return this.http.get<any>(`${this.hostUrl}service-agent`, this.getOAuthOptions());
  //   } else {
  //     return throwError(response);
  //   }
  // }

  // ************************** User Calls **********************************/
  public register(user: tRegistrationInfo): Observable<any> {
    // // console.log('Register user');

    user.applicationId = this.applicationId;
    user.directoryId = this.glowDirectoryId;
    user.alertTypeId = 'Glow_EmailVerification_Glowmarkt';
    const response = this.performPreAPIChecksWithoutAuth();

    if (response && response.isError === false) {
      const headers: HttpHeaders = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      return this.http.post(this.hostUrl + 'register', user, this.getNoAuthOptions());
    } else {
      return throwError(response);
    }
  }

  postTicket(body) {
    const response = this.performPreAPIChecksWithAuth();
    // console.log(response);
    if (response && response.isError === false) {
      return this.http.post<tIsValid>(this.hostUrl + 'ticket', body, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  postTicketPublic(body) {
    const response = this.performPreAPIChecksWithoutAuth();
    // console.log(response);
    if (response && response.isError === false) {
      return this.http.post<tIsValidTicket>(this.hostUrl + 'ticket/public', body, this.getNoAuthOptions());
    } else {
      return throwError(response);
    }
  }

  public login(accountInfo: tLoginInfo): Observable<boolean> {
    // console.log('Log in');
    const response = this.performPreAPIChecksWithoutAuth();

    if (response && response.isError === false) {
      const cleanUsername = this.checkEnvironment(accountInfo.username);
      accountInfo.username = cleanUsername;

      if (accountInfo.username.split('@')[1] == 'null.com') {
        accountInfo['directoryId'] = this.tempAccessDirectoryId;
      } else if (accountInfo.username.split('@')[1] == 'hdb.com' || accountInfo.username.split('@')[1] == 'glowtest.com') {
        accountInfo['directoryId'] = this.glowDirectoryId;
      } else {
        // console.log(this.directoryId);
        if (this.directoryId) accountInfo['directoryId'] = this.directoryId;
      }
      return this.http.post(this.hostUrl + 'auth/', JSON.stringify(accountInfo), this.getNoAuthOptions()).pipe(
        map((res) => {
          return this.saveJwt(res);
        }),
        catchError((error: any) => throwError(error))
      );
    } else {
      return throwError(response);
    }
  }

  public authenticateOAuth(accountInfo: tLoginInfo, applicationId: string): Observable<tOAuthResponse> {
    // console.log('Log in');
    const response = this.performPreAPIChecksWithoutAuth();

    if (response && response.isError === false) {
      const cleanUsername = this.checkEnvironment(accountInfo.username);
      accountInfo.username = cleanUsername;

      if (accountInfo.username.split('@')[1] == 'null.com') {
        accountInfo['directoryId'] = this.tempAccessDirectoryId;
      }
      const headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        applicationId: applicationId,
      });
      return this.http.post<tOAuthResponse>(this.hostUrl + 'auth/oauth', JSON.stringify(accountInfo), { headers });
    } else {
      return throwError(response);
    }
  }

  public logout(): Observable<tIsValid> {
    // console.log('Logging Out');
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      // To log out, delete token from server side, and remove the token and profile from local Storage
      const options = this.getAuthOptions();
      this.deleteJwt();
      this.emitLogOut();
      return this.http.delete<tIsValid>(this.hostUrl + 'auth/deleteToken', options);
    } else {
      return throwError(response);
    }
  }

  public verify(verificationInfo): Observable<tIsValid> {
    // console.log('Verifying Account');
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      if (!verificationInfo.applicationId) {
        verificationInfo.applicationId = this.applicationId;
      }
      return this.http.put<tIsValid>(this.hostUrl + 'user/verify', verificationInfo, this.getNoAuthOptions());
    } else {
      return throwError(response);
    }
  }

  public generateVerificationToken(generateVerificationInfo): Observable<tIsValid> {
    // console.log('Generate Verification Token');
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      generateVerificationInfo.applicationId = this.applicationId;
      return this.http.post<tIsValid>(this.hostUrl + 'user/verify', generateVerificationInfo, this.getNoAuthOptions());
    } else {
      return throwError(response);
    }
  }

  public resetPassword(resetPasswordInfo): Observable<tIsValid> {
    // console.log('Password Reset');
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      resetPasswordInfo.applicationId = this.applicationId;
      return this.http.put<tIsValid>(this.hostUrl + 'user/resetpassword', resetPasswordInfo, this.getNoAuthOptions());
    } else {
      return throwError(response);
    }
  }

  public setPassword(resetPasswordInfo: tSetPasswordReq): Observable<tIsValid> {
    // console.log('Password Set');
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return this.http.post<tIsValid>(this.hostUrl + 'user/setpassword', resetPasswordInfo, this.getNoAuthOptions());
    } else {
      return throwError(response);
    }
  }

  public triggerForgottenPasswordProcess(triggerForgottenPasswordInfo): Observable<tForgottenPasswordResponse> {
    // console.log('Generate Password Reset Token');
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      triggerForgottenPasswordInfo.applicationId = this.applicationId;
      return this.http.post<tForgottenPasswordResponse>(this.hostUrl + 'user/resetpassword', triggerForgottenPasswordInfo, this.getNoAuthOptions());
    } else {
      return throwError(response);
    }
  }

  public changePassword(changePasswordInfo): Observable<tIsValid> {
    // console.log('Change Password');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post<tIsValid>(this.hostUrl + 'account/changepassword', changePasswordInfo, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  public changeEmail(emailData): Observable<tIsValid> {
    // console.log('Change Email');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post<tIsValid>(this.hostUrl + 'account/email', emailData, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  public changeUsername(data: tChangeUsernameReq): Observable<tIsValid> {
    // console.log('Change Username');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.put<tIsValid>(this.hostUrl + 'user/username', data, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  public changeUsernameAndEmail(data: tChangeUsernameReq): Observable<tIsValid> {
    // console.log('Change Username and email');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.put<tIsValid>(this.hostUrl + 'user/username-email', data, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  // Add Firebase token for User.
  public addMobileToken(mobileToken: string) {
    // console.log('Adding mobile app token to glow');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      let addMobileToken = { newToken: mobileToken };
      let accountId = localStorage.getItem('id');
      return this.http.post(this.hostUrl + 'account/' + accountId + '/mobileapptoken', addMobileToken, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  public refreshToken(): Observable<tNewTokenResponse> {
    // console.log('refreshToken function');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tNewTokenResponse>(this.hostUrl + 'auth/newtoken', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  public validateToken(): Observable<tTokenValidityResponse> {
    // console.log('validateToken');
    const response = this.performPreAPIChecksNoNetwork();
    // console.log(response);
    if (response && response.isError === false) {
      const networkCheck = this.performNetworkCheck();
      if (networkCheck.isError === false) {
        return this.http.get<tTokenValidityResponse>(this.hostUrl + 'auth', this.getAuthOptions());
      } else {
        return throwError(response);
      }
    } else {
      return throwError(response);
    }
  }

  public getAccount(): Observable<tAccount> {
    // console.log('Get an account ');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tAccount>(this.hostUrl + 'account/details', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  public getAccountStatus(): Observable<tAccountStatus> {
    // console.log('Get Account Status');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tAccountStatus>(this.hostUrl + 'account/status', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  // ************************** Account Profile Calls **********************************/

  public getProfile(profileName: string) {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tAccountProfile>(this.hostUrl + 'accountprofile/' + profileName, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  public getAllProfiles() {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tAccountProfilesResp>(this.hostUrl + 'accountprofile', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  public addProfile(profileBody: tAccountProfile) {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post<tAccountProfileCreateResp>(this.hostUrl + 'accountprofile', profileBody, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  // ************************** User Groups Calls **********************************/

  getUserGroups(functionalGroupId = ''): Observable<tUserGroup[]> {
    // console.log('Get user groups');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tUserGroup[]>(this.hostUrl + 'usergroup/user', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  createUserGroup(userGroupBody: tUserGroupCreateReq): Observable<tUserGroupCreateRes> {
    // console.log('Get user groups');
    const response = this.performPreAPIChecksWithAuth();

    if (response && response.isError === false) {
      return this.http.post<tUserGroupCreateRes>(this.hostUrl + 'usergroup', userGroupBody, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  createUserOrganization(userOrganizationBody: tUserOrgCreateReq): Observable<tUserOrganization> {
    // console.log('create user organization');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post<tUserOrganization>(this.hostUrl + 'userorganization', userOrganizationBody, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  appAppPermissionsToUserGroup(userGroupId: string, appPermissionsBody: tUserGroupAppPermissionsReq): Observable<tUserGroup> {
    // console.log('Get user groups');
    const response = this.performPreAPIChecksWithAuth();

    if (response && response.isError === false) {
      return this.http.post<tUserGroup>(this.hostUrl + 'usergroup/' + userGroupId + '/application', appPermissionsBody, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  checkIfUserIsUserGroupAdmin(userGroupId): Observable<tIsUserGroupValid> {
    // console.log('Check if user is admin of user group ', userGroupId);

    const response = this.performPreAPIChecksWithAuth();

    if (response && response.isError === false) {
      return this.http.get<tIsUserGroupValid>(this.hostUrl + 'usergroup/' + userGroupId + '/admin', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getUserOrganizationsOfUser(functionalGroupId = ''): Observable<tUserOrganization[]> {
    // console.log('Get user groups');
    const response = this.performPreAPIChecksWithAuth();

    if (response && response.isError === false) {
      return this.http.get<tUserOrganization[]>(this.hostUrl + 'userorganization/user', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getUsersOfUserGroup(userGroupId: string): Observable<tUserGroupingWithUserInfo[]> {
    // console.log('Get users of user group ' + userGroupId);
    const response = this.performPreAPIChecksWithAuth();

    if (response && response.isError === false) {
      return this.http.get<tUserGroupingWithUserInfo[]>(this.hostUrl + 'usergroup/' + userGroupId + '/user', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  addUsersToUserGroup({ userGroupId, usernames }): Observable<tUserGrouping[]> {
    // console.log('Get user groups');
    const response = this.performPreAPIChecksWithAuth();

    if (response && response.isError === false) {
      const body = { usernames };
      return this.http.post<tUserGrouping[]>(`${this.hostUrl}usergroup/${userGroupId}/user`, body, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  deleteUserFromUserGroup(userGroupId, userId: string): Observable<tUserFromUserGroupDeletionRes> {
    // console.log('Delete user ' + userId + ' from user group ' + userGroupId);
    const response = this.performPreAPIChecksWithAuth();

    if (response && response.isError === false) {
      return this.http.delete<tUserFromUserGroupDeletionRes>(this.hostUrl + 'usergroup/' + userGroupId + '/user/' + userId, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getAdminOfUserGroup(userGroupId: string): Observable<tUserGroupAdminWithUserInfo[]> {
    // console.log('Get user groups');
    const response = this.performPreAPIChecksWithAuth();

    if (response && response.isError === false) {
      return this.http.get<tUserGroupAdminWithUserInfo[]>(this.hostUrl + 'usergroup/' + userGroupId + '/admin/user', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  addAdminOfUserGroup({ userGroupId, usernames }): Observable<tUserGroupAdminAdditionRes> {
    // console.log('Add user groups');
    const response = this.performPreAPIChecksWithAuth();

    if (response && response.isError === false) {
      const body = { usernames };
      return this.http.post<tUserGroupAdminAdditionRes>(`${this.hostUrl}usergroup/${userGroupId}/admin/user`, body, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  removeAdminFromUserGroup(userGroupId: string, userId: string): Observable<tUserGroupAdminDeletionRes> {
    // console.log('Remove userId from user group');
    const response = this.performPreAPIChecksWithAuth();

    if (response && response.isError === false) {
      return this.http.delete<tUserGroupAdminDeletionRes>(this.hostUrl + 'usergroup/' + userGroupId + '/admin/user/' + userId, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getUserGroupsOfOrganization(userOrganizationId): Observable<tUserGroup[]> {
    // console.log('Get user groups of user organization ', userOrganizationId);
    const response = this.performPreAPIChecksWithAuth();

    if (response && response.isError === false) {
      return this.http.get<tUserGroup[]>(this.hostUrl + 'userorganization/' + userOrganizationId + '/usergroups', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  checkIfUserIsUserOrganizationAdmin(userOrganizationId): Observable<tIsValid> {
    // console.log('Check if user is admin of organization ', userOrganizationId);
    const response = this.performPreAPIChecksWithAuth();

    if (response && response.isError === false) {
      return this.http.get<tIsValid>(this.hostUrl + 'userorganization/' + userOrganizationId + '/admin', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getUserOrganizationAdmins(userOrganizationId): Observable<tUserGroup[]> {
    // console.log('Get user groups of user organization ', userOrganizationId);
    const response = this.performPreAPIChecksWithAuth();

    if (response && response.isError === false) {
      return this.http.get<tUserGroup[]>(this.hostUrl + 'userorganization/' + userOrganizationId + '/usergroups', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  createUserOrganizationAdmin(userOrganizationId: string, userId: string): Observable<tUserGroup[]> {
    // console.log('Add a user as user organization admin ', userOrganizationId);
    const response = this.performPreAPIChecksWithAuth();

    if (response && response.isError === false) {
      let body = {
        users: [
          {
            userId: userId,
          },
        ],
      };
      return this.http.post<tUserGroup[]>(this.hostUrl + 'userorganization/' + userOrganizationId + '/admin/user', JSON.stringify(body), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  deleteUserOrganizationAdmin(userOrganizationId: string, userId: string): Observable<tUserGroup[]> {
    // console.log('Delete a user from  being an admin of a user organization ' + userOrganizationId);
    const response = this.performPreAPIChecksWithAuth();

    if (response && response.isError === false) {
      return this.http.delete<tUserGroup[]>(this.hostUrl + 'userorganization/' + userOrganizationId + '/admin/user/' + userId, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  // ***************Functional Group Account ***********************

  getFunctionalGroupAccounts(): Observable<tFunctionalGroupAccount[]> {
    // console.log('Get functional group account');
    const response = this.performPreAPIChecksWithAuth();

    if (response && response.isError === false) {
      return this.http.get<tFunctionalGroupAccount[]>(this.hostUrl + 'functionalgroupaccount', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  // ************************** Simulation Calls **********************************/
  public runSimulation(simulation: tSimulation): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post(this.hostUrl + 'simulation/run', JSON.stringify(simulation), this.getAuthOptions());
    }
    return throwError(response);
  }

  public runSimulationVeId(simulation: tSimulation, veId: string): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post(this.hostUrl + 'simulation/virtual-entity/' + veId + '/run', JSON.stringify(simulation), this.getAuthOptions());
    }
    return;
  }

  // ************************** Journal Calls **********************************/

  public createJournal(veId: string): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post(this.hostUrl + 'journal', { veId }, this.getAuthOptions());
    }
    return throwError(response);
  }

  public getJournal(veId: string): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get(this.hostUrl + 'journal/ve/' + veId, this.getAuthOptions());
    }
    return throwError(response);
  }

  public getJournalEntries(journalId: string): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get(this.hostUrl + 'journal/' + journalId + '/entries', this.getAuthOptions());
    }
    return throwError(response);
  }

  public getJournalEntriesByComponent(componentId: string, componentVersion: string): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get(this.hostUrl + 'journal-entry/component/' + componentId + '/version/' + componentVersion + '/entries', this.getAuthOptions());
    }
    return throwError(response);
  }

  public getJournalEntry(entryId: string): Observable<any> {
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return this.http.get(this.hostUrl + 'journal-entry/' + entryId, this.getNoAuthOptions());
    }
    return throwError(response);
  }

  public createJournalEntry(entry: tJournalEntry): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post(this.hostUrl + 'journal-entry', entry, this.getAuthOptions());
    }
    return throwError(response);
  }

  public createComponent(component: tComponent): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post(this.hostUrl + 'journal/component', component, this.getAuthOptions());
    }
    return throwError(response);
  }

  public getComponent(componentId: string, componentVersion: string): Observable<any> {
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return this.http.get(this.hostUrl + 'journal/component/' + componentId + '/version/' + componentVersion, this.getNoAuthOptions());
    }
    return throwError(response);
  }

  public updateComponent(componentId: string, componentVersion: string, component: tComponent): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.patch(this.hostUrl + 'journal/component/' + componentId + '/version/' + componentVersion, component, this.getAuthOptions());
    }
    return throwError(response);
  }

  public updateComponentMedia(componentId: string, componentVersion: string, body: any): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.patch(this.hostUrl + 'journal/component/' + componentId + '/version/' + componentVersion + '/media', body, this.getImageAuthOptions());
    }
    return throwError(response);
  }

  public deleteComponent(componentId: string, componentVersion: string): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.delete(this.hostUrl + 'journal/component/' + componentId + '/version/' + componentVersion, this.getAuthOptions());
    }
    return throwError(response);
  }

  public updateJournalEntry(jeId: string, entry: any): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.patch(this.hostUrl + 'journal-entry/' + jeId, entry, this.getAuthOptions());
    }
    return throwError(response);
  }

  getAllCaseStudiesByVe(veId: string): Observable<any> {
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return this.http.get(this.hostUrl + 'case-study/ve/' + veId, this.getAuthOptions());
    }
    return throwError(response);
  }

  getAllCaseStudiesByVeAndClassifier(veId: string, classifier: string): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get(this.hostUrl + 'case-study/ve/' + veId + '/classifier/' + classifier, this.getAuthOptions());
    }
    return throwError(response);
  }

  getAllCaseStudies(classifier?: string): Observable<any> {
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      const path = classifier ? this.hostUrl + 'case-study?classifier=' + classifier : this.hostUrl + 'case-study';
      return this.http.get(path, this.getNoAuthOptions());
    }
    return throwError(response);
  }

  public createCaseStudy(caseStudy: tCaseStudy): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post(this.hostUrl + 'case-study', caseStudy, this.getAuthOptions());
    }
    return throwError(response);
  }

  public updateCaseStudy(csId, body): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.patch(this.hostUrl + 'case-study/' + csId, body, this.getAuthOptions());
    }
    return throwError(response);
  }

  public getCaseStudy(caseStudyId: string): Observable<any> {
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return this.http.get(this.hostUrl + 'case-study/' + caseStudyId, this.getNoAuthOptions());
    }
    return throwError(response);
  }

  public getCaseStudyByRef(csRef: string): Observable<any> {
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return this.http.get(this.hostUrl + 'case-study/ref/' + csRef, this.getNoAuthOptions());
    }
    return throwError(response);
  }

  //like functionality
  public likeCaseStudy(csId): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post(this.hostUrl + 'case-study-like', { csId }, this.getAuthOptions());
    }
    return throwError(response);
  }

  public removeLikeCaseStudy(csId, csLikeId): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.delete(this.hostUrl + 'case-study-like/' + csLikeId, this.getAuthOptions());
    }
    return throwError(response);
  }

  public getCaseStudyLikesCount(csId): Observable<any> {
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return this.http.get(this.hostUrl + 'case-study-like/case-study/' + csId + '/count', this.getNoAuthOptions());
    }
    return throwError(response);
  }

  public getCaseStudyLikeForUser(csId): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get(this.hostUrl + 'case-study-like/case-study/' + csId + '/user', this.getAuthOptions());
    }
    return throwError(response);
  }

  public editMediaFieldCaseStudy(csId, fieldName, body): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      const url = this.hostUrl + 'case-study/' + csId + '/media/field/' + fieldName;
      return this.http.patch(url, body, this.getImageAuthOptions());
    }
    return throwError(response);
  }

  //comment functionality
  public createCaseStudyComment(body: { csId: string; data: string }): Observable<any> {
    return this.http.post(this.hostUrl + 'case-study-comment', body, this.getAuthOptions());
  }

  public getImage(imageId: string): Promise<any> {
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return fetch(this.hostUrl + 'journal/file/' + imageId, this.getNoAuthOptionsRequestInit())
        .then((res) => res.blob())
        .then((blob) => {
          return blob;
        });
    }
  }

  // ************************** Property Passport **********************************/
  getPropertyPassport(veId: string): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<any>(this.hostUrl + 'virtualentity/' + veId + '/property-passport', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  createPropertyPassport(veId: string): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post<any>(this.hostUrl + 'virtualentity/' + veId + '/property-passport', {}, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  calculateHTC(payload: tCalculateHTC): Observable<any> {
    // console.log('calculateHTC');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      const url = this.hostUrl + 'passport/htc';
      return this.http.post<any>(url, JSON.stringify(payload), this.getAuthOptions());
    }
    return throwError(response);
  }

  getHTCHistory(veId) {
    // console.log('getHTCHistory');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      // console.log(response);
      const url = this.hostUrl + 'passport/ve/' + veId + '/htc-history';
      return this.http.get(url, this.getAuthOptions());
    }
    return throwError(response);
  }

  manualCreatePropertyPassport(veId: string, data: any): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post<any>(this.hostUrl + 'virtualentity/' + veId + '/property-passport/manual', JSON.stringify(data), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  // ************************** Application Calls **********************************/

  getApplicationVersion(): Observable<tApplicationVersions> {
    // console.log('Get application Version');
    const response = this.performPreAPIChecksWithAuth();

    if (response && response.isError === false) {
      return this.http.get<tApplicationVersions>(this.hostUrl + 'application/version', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  // ************************** Support/FAQ/T&C Calls **********************************/
  getFAQs(reference = null): Observable<tFAQ[]> {
    // console.log('Get FAQs');
    const response = this.performPreAPIChecksWithAuth();

    if (response && response.isError === false) {
      if (reference === null) {
        const configuration = this.configService.loadConfig();
        reference = configuration.content.faqs.reference;
      }
      // console.log('the url is ');
      //return this.http.get<tFAQ[]>(this.hostUrl + 'faq?reference=' + reference, this.getAuthOptions())
      return this.http.get<tFAQ[]>(this.hostUrl + 'faq', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getAllFAQs(reference = null): Observable<tFAQ[]> {
    // console.log('Get FAQs');
    const response = this.performPreAPIChecksWithAuth();

    if (response && response.isError === false) {
      const appIdHeaders: Headers = new AppIdHeadersGlow(this.applicationId);

      return this.http.get<tFAQ[]>(this.hostUrl + 'faq', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getSupportDetails(): Observable<tSupportDetails[]> {
    // console.log('Get Support Details');
    const response = this.performPreAPIChecksWithAuth();

    if (response && response.isError === false) {
      const configuration = this.configService.loadConfig();
      const supportDetailsRef = configuration.content.supportDetails.reference;
      return this.http.get<tSupportDetails[]>(this.hostUrl + 'supportdetails?reference=' + supportDetailsRef, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getTermsAndConditions(tcReference: string = null): Observable<tTermsConditions[]> {
    // console.log('Get Current Terms and Conditions');
    if (tcReference === null) {
      const configuration = this.configService.loadConfig();
      tcReference = configuration.termsAndConditions && configuration.termsAndConditions.reference;
      // console.log('Defaulting T&C reference to ' + tcReference);
    }
    const response = this.performPreAPIChecksWithAuth();
    const checkNoAuth = this.performPreAPIChecksWithoutAuth();

    let url;
    if (tcReference) {
      url = this.hostUrl + 'termsconditions?reference=' + tcReference;
    } else {
      url = this.hostUrl + 'termsconditions';
    }

    if (response && response.isError === false) {
      return this.http.get<tTermsConditions[]>(url, this.getAuthOptions());
    } else if (checkNoAuth && checkNoAuth.isError === false) {
      return this.http.get<tTermsConditions[]>(url, this.getNoAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getCurrentTermsAndConditions(tcReference: string = null): Observable<tTermsConditions[]> {
    // console.log('Get Current Terms and Conditions');
    const response = this.performPreAPIChecksWithAuth();
    const checkNoAuth = this.performPreAPIChecksWithoutAuth();

    if (tcReference === null) {
      const configuration = this.configService.loadConfig();
      tcReference = configuration.termsAndConditions && configuration.termsAndConditions.reference;
      // console.log('Defaulting T&C reference to ' + tcReference);
    }

    let url;
    if (tcReference) {
      url = this.hostUrl + 'termsconditions/current?reference=' + tcReference;
    } else {
      url = this.hostUrl + 'termsconditions/current';
    }
    // console.log('getting ts with api', url);
    if (response && response.isError === false) {
      return this.http.get<tTermsConditions[]>(url, this.getAuthOptions());
    } else if (checkNoAuth && checkNoAuth.isError === false) {
      return this.http.get<tTermsConditions[]>(url, this.getNoAuthOptions());
    } else {
      // console.log('throwing error - apichecks with auth failed');
      return throwError(response);
    }
  }

  getUserTermsAndConditionsByReference(tcReference = null): Observable<tTermsConditionsUser[]> {
    // console.log('Get User Current Terms and Conditions');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      let url;
      if (tcReference) {
        url = this.hostUrl + 'termsconditionsuser?reference=' + tcReference;
      } else {
        url = this.hostUrl + 'termsconditionsuser';
      }
      return this.http.get<tTermsConditionsUser[]>(url, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  sendUserTermsAndConditions(tcUser: tTermsConditionsUserRaw): Observable<tTermsConditionsUserCreateRes> {
    // console.log('Send User Response to Current Terms and Conditions');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      const url = this.hostUrl + 'termsconditionsuser';
      return this.http.post<tTermsConditionsUserCreateRes>(url, JSON.stringify(tcUser), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  // ***************Content/Prompt Calls ***********************
  getContent(reference = null, version = null, environment = 'prod'): Observable<tContent[]> {
    // console.log('Get Prompts');
    const response = this.performPreAPIChecksWithAuth();

    if (response && response.isError === false) {
      const url = this.hostUrl + 'content?';

      let params: HttpParams = new HttpParams();
      if (reference) params = params.set('reference', reference);
      if (version) params = params.set('version', version);
      if (environment) params = params.set('environment', environment);

      let options = this.getAuthOptions();
      options['params'] = params;
      return this.http.get<tContent[]>(url, options);
    } else {
      return throwError(response);
    }
  }

  getLanguageContent(reference = null, languageCode = null, version = null, environment = 'prod'): Observable<tLanguageContent[]> {
    // console.log('Get Language Content');
    const response = this.performPreAPIChecksWithAuth();

    if (response && response.isError === false) {
      const url = this.hostUrl + 'languagecontent?';
      let params: HttpParams = new HttpParams();

      if (reference) params = params.set('reference', reference);
      if (languageCode) params = params.set('languageCode', languageCode);
      if (version) params = params.set('version', version);
      if (environment) params = params.set('environment', environment);

      let options = this.getAuthOptions();
      options['params'] = params;
      return this.http.get<tLanguageContent[]>(url, options);
    } else {
      return throwError(response);
    }
  }

  getPrompts(reference = null): Observable<tPrompt> {
    // console.log('Get Prompts');
    const response = this.performPreAPIChecksWithAuth();

    if (response && response.isError === false) {
      const url = this.hostUrl + 'prompt?';
      let params: HttpParams = new HttpParams();
      if (reference) params = params.set('reference', reference);

      let options = this.getAuthOptions();
      options['params'] = params;
      return this.http.get<tPrompt>(url, options);
    } else {
      return throwError(response);
    }
  }

  getPromptUser(reference = null): Observable<tPromptUser> {
    // console.log('Get Prompt User');
    const response = this.performPreAPIChecksWithAuth();

    if (response && response.isError === false) {
      const url = this.hostUrl + 'promptuser?';
      let params: HttpParams = new HttpParams();
      if (reference) params = params.set('reference', reference);

      let options = this.getAuthOptions();
      options['params'] = params;
      return this.http.get<tPromptUser>(url, options);
    } else {
      return throwError(response);
    }
  }

  sendPromptReply(promptReply: tPromptUserReq): Observable<tPromptUser> {
    // console.log('Send Prompt Reply');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      let url = this.hostUrl + 'promptuser';
      return this.http.post<tPromptUser>(url, JSON.stringify(promptReply), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getUserActions(userActionRef: string): Observable<tUserAction[]> {
    // console.log('Send User Action');
    const response = this.performPreAPIChecksWithAuth();

    if (response && response.isError === false) {
      let url;
      if (userActionRef) {
        url = this.hostUrl + 'useraction?reference=' + userActionRef;
      } else {
        url = this.hostUrl + 'useraction';
      }
      return this.http.get<tUserAction[]>(url, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  sendUserAction(userActionBody): Observable<any> {
    // console.log('Send User Action');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      const url = this.hostUrl + 'useraction';
      return this.http.post(url, userActionBody, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  // ************************** VE Calls **********************************/

  getVEs(): Observable<tVE[]> {
    // console.log('Get virtual entities ' + this.applicationId);
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tVE[]>(this.hostUrl + 'virtualentity/', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getVE(veId: string): Observable<tVE> {
    // console.log('Get virtual entity ' + veId);
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tVE>(this.hostUrl + 'virtualentity/' + veId, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getResourcesOfVE(veId: string): Observable<tVEwithDetailedResources> {
    // console.log('Get virtual entity ' + veId);
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tVEwithDetailedResources>(this.hostUrl + 'virtualentity/' + veId + '/resources', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getResourceLastTime(resourceId: string): Observable<tResourceLastTime> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tResourceLastTime>(`${this.hostUrl}resource/${resourceId}/last-time`, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getVETypes(): Observable<tVEType[]> {
    // console.log('Get virtual entity types ' + this.applicationId);
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tVEType[]>(this.hostUrl + 'vetype/', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getVEType(veTypeId: string): Observable<tVEType> {
    // console.log('Get virtual entity type ' + veTypeId);
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tVEType>(this.hostUrl + 'vetype/' + veTypeId, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getGroupedChildrenOfVe(veId: string): Observable<tVEwithGroupedChildren> {
    // console.log('getGroupedChildrenOfVe ' + veId);
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tVEwithGroupedChildren>(this.hostUrl + 'virtualentity/' + veId + '/child?vechildmode=grouped', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getVeState(veId: string): Observable<tVeState> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tVeState>(this.hostUrl + 'virtualentity/' + veId + '/vestate', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getVeGroup(veId: string): Observable<tVeGroup[]> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tVeGroup[]>(this.hostUrl + 'virtualentity/' + veId + '/group', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  addChildrenToVe(parentVeId: string, childrenVEs: tVeIdListing[]): Observable<tVEwithGroupedChildren> {
    // console.log('Add virtual entity children ' + parentVeId);
    const addChildrenBody = {
      add: childrenVEs,
    };
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post<tVEwithGroupedChildren>(this.hostUrl + 'virtualentity/' + parentVeId + '/child', JSON.stringify(addChildrenBody), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  removeChildrenFromVe(parentVeId: string, childVeId: string): Observable<tVEwithGroupedChildren> {
    // console.log('remove virtual entity children ' + parentVeId);
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.delete<tVEwithGroupedChildren>(this.hostUrl + 'virtualentity/' + parentVeId + '/child/' + childVeId, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  updateVirtualEntity(veId: string, updateBody: any): Observable<tVE> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.put<tVE>(this.hostUrl + 'virtualentity/' + veId, JSON.stringify(updateBody), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  createVirtualEntityDocument(veId: string, body: any): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post<any>(this.hostUrl + 'virtualentity/' + veId + '/document', JSON.stringify(body), this.getAuthOptions());
    }
    return throwError(response);
  }

  updateVirtualEntityDocument(veId: string, documentId: string, updateBody: any): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.put<any>(this.hostUrl + 'virtualentity/' + veId + '/document/' + documentId, JSON.stringify(updateBody), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getVirtualEntityDocumentCount(veId: string, queryString?): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<any>(this.hostUrl + 'virtualentity/' + veId + '/document/count?' + queryString, this.getAuthOptions());
    }
    return throwError(response);
  }

  deactivateVirtualEntity(veId: string): Observable<tVE> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.delete<tVE>(this.hostUrl + 'virtualentity/' + veId, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getVe10DayForecast({ veId, lat = null, lon = null, msl = 10, postcode = null, postcodeDistrict = null }): Observable<tVE> {
    // console.log('Get weather forecast');
    const response = this.performPreAPIChecksWithAuth();

    if (response && response.isError === false) {
      const url = this.hostUrl + 'virtualentity/' + veId + '/10dayweather?';
      let params: HttpParams = new HttpParams();
      if (lat && lon) {
        params = params.set('lat', lat);
        params = params.set('lon', lon);
        params = params.set('msl', msl.toString());
      } else if (postcode) {
        params = params.set('postcode', postcode);
      } else if (postcodeDistrict) {
        params = params.set('postcodeDistrict', postcodeDistrict);
      }
      const options = this.getAuthOptions();
      options['params'] = params;
      return this.http.get<tVE>(url, options);
    } else {
      return throwError(response);
    }
  }

  updateVeResource(veId: string, resourceId: string, resourceUpdateBody: any, userId: string = null): Observable<tVE> {
    // console.log('Update resource ' + resourceId + ' of virtual entity ' + veId);
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      const url = this.hostUrl + 'virtualentity/' + veId + '/resources/' + resourceId;
      return this.http.put<tVE>(url, JSON.stringify(resourceUpdateBody), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getVEDocumentList(veId: string, queryString = ''): Observable<any> {
    // console.log('Get virtual entity document list ' + veId);
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<any>(this.hostUrl + 'virtualentity/' + veId + '/document' + queryString, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getVEDocumentByID(veId: string, documentId: string): Observable<any> {
    // console.log('Get virtual entity document list ' + veId);
    // const response = this.performPreAPIChecksWithAuth();
    // if (response && response.isError === false) {

    return this.http.get<any>(this.hostUrl + 'virtualentity/' + veId + '/document/' + documentId, this.getAuthOptionsCsv());
    // } else {
    //   return throwError(response);
    // }
  }

  getVEDocumentByIDMedia(veId: string, documentId: string): Promise<any> {
    const headers = {
      headers: {
        applicationId: this.applicationId,
        token: localStorage.getItem('id_token'),
        'X-GLOW-Version': '0',
      },
    };
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return fetch(this.hostUrl + 'virtualentity/' + veId + '/document/' + documentId, headers)
        .then((res) => res.blob())
        .then((blob) => {
          return blob;
        });
    }
  }

  updateVEDocumentStatus(veId: string, documentId: string, status: string): Observable<any> {
    // console.log('Get virtual entity document list ' + veId);
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.patch<any>(this.hostUrl + 'virtualentity/' + veId + '/document/' + documentId + '/status', { status }, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  deleteVEDocumentByID(veId: string, documentId: string): Observable<any> {
    // console.log('Get virtual entity document list ' + veId);
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.delete<any>(this.hostUrl + 'virtualentity/' + veId + '/document/' + documentId, this.getAuthOptions());
    }
    return throwError(response);
  }

  getVirtualEntityAttributes(veId: string): Observable<tVE> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tVE>(this.hostUrl + 'virtualentity/' + veId + '/attribute', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  addVirtualEntityAttributes(veId: string, updateBody: any): Observable<tVE> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.put<tVE>(this.hostUrl + 'virtualentity/' + veId + '/attribute', JSON.stringify(updateBody), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  updateVirtualEntityAttributes(veId: string, updateBody: any): Observable<tVE> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.put<tVE>(this.hostUrl + 'virtualentity/' + veId + '/attribute', JSON.stringify(updateBody), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  deleteVirtualEntityAttributes(veId: string, updateBody: any): Observable<tVE> {
    const response = this.performPreAPIChecksWithAuth();
    const options = {
      headers: this.getAuthHeaders(),
      body: updateBody,
    };
    if (response && response.isError === false) {
      return this.http.delete<tVE>(this.hostUrl + 'virtualentity/' + veId + '/attribute', options);
    } else {
      return throwError(response);
    }
  }

  /**************************** Virtual Entity Access ******************************/

  getVeAccessOfUser(userOrganizationId = null): Observable<tVeAccessPermission[]> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      let url = this.hostUrl + 'veaccess?';
      if (userOrganizationId) {
        url += 'userOrganizationId=' + userOrganizationId;
      }
      return this.http.get<tVeAccessPermission[]>(url, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getVeAccessOfVirtualEntity(veId: string): Observable<tVeAccessPermission[]> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tVeAccessPermission[]>(this.hostUrl + 'veaccess/ve/' + veId, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getVeAccessByUserGroup(userGroupId: string): Observable<tVeAccessPermission[]> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tVeAccessPermission[]>(this.hostUrl + 'veaccess/usergroup/' + userGroupId, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getVeAccessByUser(userId: string): Observable<tVeAccessPermission[]> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tVeAccessPermission[]>(this.hostUrl + 'veaccess/user/' + userId, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  addVirtualEntityAccessForUser(veAccessBody: tVeAccessAddUser, resourcePermission: boolean = true): Observable<tVeAccessAdditionRes> {
    // console.log('Add virtual entity access ' + JSON.stringify(veAccessBody));
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post<tVeAccessAdditionRes>(this.hostUrl + 'veaccess?resourcePermission=' + resourcePermission, JSON.stringify(veAccessBody), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  addVirtualEntityAccessForUserGroup(veAccessBody: tVeAccessAddUserGroup, resourcePermission: boolean = true): Observable<tVeAccessAdditionRes> {
    // console.log('Add virtual entity access ' + JSON.stringify(veAccessBody));
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post<tVeAccessAdditionRes>(this.hostUrl + 'veaccess?resourcePermission=' + resourcePermission, JSON.stringify(veAccessBody), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  deleteVirtualEntityAccess(veAccessId: string): Observable<tVeAccessAdditionRes> {
    // console.log('Remove virtual entity access');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.delete<tVeAccessAdditionRes>(this.hostUrl + 'veaccess/' + veAccessId, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  checkHasVeAccess(veId: string): Observable<tHasVeAccess> {
    // console.log('Check user has access to ve ' + veId);
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tHasVeAccess>(this.hostUrl + 'veaccess/ve/' + veId + '/permission', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  checkHasVeAdminAccess(veId: string): Observable<tHasVeAdminAccess> {
    // console.log('Check user has admin access to ve ' + veId);
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tHasVeAdminAccess>(this.hostUrl + 'veaccess/ve/' + veId + '/permission/admin', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getVeAccessAdminPermission(veId: string): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<any>(this.hostUrl + 'veaccess/ve/' + veId + '/permission/admin', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getVeAccessPermission(veId: string): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<any>(this.hostUrl + 'veaccess/ve/' + veId + '/permission', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  // ************************** Resource Calls **********************************/

  getResources(): Observable<tResource[]> {
    // console.log('Get resources ' + this.applicationId);
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tResource[]>(this.hostUrl + 'resource/', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getResource(resourceId: string): Observable<tResource> {
    // console.log('Get resource ' + resourceId);
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tResource>(this.hostUrl + 'resource/' + resourceId, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getResourceReadingsAPI(url: string, newTimestampCacheIsValid: number, dimension = null): Observable<tResourceReadings> {
    const response = this.performPreAPIChecksWithAuth();
    let conventToWmin = false;
    let conventTokWmin = false;
    if (response && response.isError === false) {
      return this.http.get(url, this.getAuthOptions()).pipe(
        map((res: any) => {
          const resource = res;
          // console.log('Getting from http');
          // console.log(url);
          let saveResource = false;

          if (dimension === 'Wmin') {
            conventToWmin = true;
          }
          if (dimension === 'kWmin') {
            conventTokWmin = true;
          }
          if (resource.data && Array.isArray(resource.data)) {
            for (let i = 0; i < resource.data.length; i++) {
              resource.data[i][0] = resource.data[i][0] * 1000;
              if (resource.data[i][1] === 18446744073709552000) {
                resource.data[i][1] = 0;
              }
              if (resource.data[i][1] !== 0 && saveResource === false) {
                saveResource = true;
              }
              if (resource.data[i][1]) {
                if (conventToWmin === true) {
                  resource.data[i][1] = Number(resource.data[i][1].toFixed(2) * 60000);
                } else if (conventTokWmin === true) {
                  resource.data[i][1] = Number(resource.data[i][1].toFixed(4) * 60);
                  // If units in pence convert to £
                } else if (resource.units === 'pence') {
                  resource.data[i][1] = Number((resource.data[i][1] / 100).toFixed(2));
                } else {
                  resource.data[i][1] = Number(resource.data[i][1].toFixed(4));
                }
              }
            }
          }
          if (resource.units === 'pence') {
            resource.units = '£';
          }
          // console.log('Setting in storage');
          resource.timestampCacheIsValid = newTimestampCacheIsValid;
          if (saveResource === true) {
            this.storage.set(url, resource);
          } else {
            // console.log('Received data will not be put in cache');
          }
          return resource;
        }),
        catchError((error: any) => throwError(error))
      );
    } else {
      return throwError(response);
    }
  }

  getResourceReadings(resourceId: string, fromDate: string, toDate: string, period: string, func: string = 'sum', getFromHttp = false, dimension = null): Observable<tResourceReadings> {
    const response = this.performPreAPIChecksNoNetwork();
    if (response && response.isError === false) {
      const offset = localStorage.getItem('offset');
      // console.log(
      //   'Get resource readings ' +
      //     resourceId +
      //     ' for app=' +
      //     this.applicationId +
      //     ' offset ' +
      //     offset +
      //     ' funct ' +
      //     func
      // );
      // use a hash of the resource URL query and store the result
      let url: string;
      if (offset && offset != '0') {
        url = this.hostUrl + 'resource/' + resourceId + '/readings?from=' + fromDate + '&to=' + toDate + '&period=' + period + '&offset=' + offset + '&function=' + func;
      } else {
        url = this.hostUrl + 'resource/' + resourceId + '/readings?from=' + fromDate + '&to=' + toDate + '&period=' + period + '&function=' + func;
      }

      const currentDatetime = new Date();
      const currentDate = this.dateFormatService.apiTimeFormat(new Date());
      let maxCacheDate = this.dateFormatService.apiTimeFormat(new Date(currentDatetime.getFullYear(), currentDatetime.getMonth(), currentDatetime.getDate() + 1, 23, 59, 59));
      let minCacheDate = this.dateFormatService.apiTimeFormat(new Date(currentDatetime.getFullYear(), currentDatetime.getMonth(), currentDatetime.getDate(), currentDatetime.getHours(), currentDatetime.getMinutes() + 30, 59));

      // if is after 23:00 set the cache to current date
      if (currentDatetime.getHours() >= 22) {
        minCacheDate = currentDate;
      }
      var newTimestampCacheIsValid;

      if (currentDate > toDate) {
        newTimestampCacheIsValid = maxCacheDate;
        // // console.log('Valid Timestamp Cache set to maximum', maxCacheDate, toDate, fromDate)
      } else {
        // // console.log('Valid Timestamp Cache set to minimum', minCacheDate, toDate, fromDate)
        newTimestampCacheIsValid = minCacheDate;
      }

      //Get from http (force refresh)
      if (getFromHttp === true) {
        return this.getResourceReadingsAPI(url, newTimestampCacheIsValid, dimension);
      } else {
        //Look into cache first
        return from(this.getDataFromStorage(url)) //convert to Promise and return chain.
          .pipe(
            switchMap((value) => {
              //use Observable.switchMap to move to second observable
              var proceedWithHTTP = false;
              if (value != null) {
                // console.log('Getting from cache ' + url);
                if (value.timestampCacheIsValid) {
                  // // console.log('Timestamp Cache is valid', value.timestampCacheIsValid)
                  // // console.log('TImestamp now', currentDate)

                  if (value.timestampCacheIsValid <= currentDate) {
                    // console.log('Cache is no longer valid, proceeding with http');
                    proceedWithHTTP = true;
                  }
                } else {
                  // console.log('No timestamp to check validity of cache, proceeding with http');
                  proceedWithHTTP = true;
                }
              } else {
                // console.log('No data was found in cache, proceeding with http');
                proceedWithHTTP = true;
              }

              if (proceedWithHTTP === true) {
                return this.getResourceReadingsAPI(url, newTimestampCacheIsValid, dimension);
              } else {
                // console.log('Returning from cache ' + url);
                // console.log(value);
                return of(JSON.parse(JSON.stringify(value)));
              }
            })
          );
      }
    } else {
      return throwError(response);
    }
  }

  getBalanceResourceReadings(resourceId: string, fromDate: string, toDate: string, getFromHttp = false, dimension = null): Observable<tResourceReadings> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      const offset = localStorage.getItem('offset');
      // console.log('Get resource readings ' + resourceId + ' for app=' + this.applicationId + ' offset ' + offset);
      // use a hash of the resource URL query and store the result
      let url: string;
      if (offset && offset != '0') {
        url = this.hostUrl + 'resource/' + resourceId + '/readings?from=' + fromDate + '&to=' + toDate;
      } else {
        url = this.hostUrl + 'resource/' + resourceId + '/readings?from=' + fromDate + '&to=' + toDate;
      }

      const currentDatetime = new Date();
      const currentDate = this.dateFormatService.apiTimeFormat(new Date());
      let maxCacheDate = this.dateFormatService.apiTimeFormat(new Date(currentDatetime.getFullYear(), currentDatetime.getMonth(), currentDatetime.getDate() + 1, 23, 59, 59));
      let minCacheDate = this.dateFormatService.apiTimeFormat(new Date(currentDatetime.getFullYear(), currentDatetime.getMonth(), currentDatetime.getDate(), currentDatetime.getHours(), currentDatetime.getMinutes() + 30, 59));

      // if is after 23:00 set the cache to current date
      if (currentDatetime.getHours() >= 22) {
        minCacheDate = currentDate;
      }
      var newTimestampCacheIsValid;

      if (currentDate > toDate) {
        newTimestampCacheIsValid = maxCacheDate;
      } else {
        newTimestampCacheIsValid = minCacheDate;
      }

      //Get from http (force refresh)
      if (getFromHttp === true) {
        return this.getResourceReadingsAPI(url, newTimestampCacheIsValid, dimension);
      } else {
        //Look into cache first
        return from(this.getDataFromStorage(url)) //convert to Promise and return chain.
          .pipe(
            switchMap((value) => {
              //use Observable.switchMap to move to second observable
              var proceedWithHTTP = false;
              if (value != null) {
                // console.log('Getting from cache ' + url);
                if (value.timestampCacheIsValid) {
                  // // console.log('Timestamp Cache is valid', value.timestampCacheIsValid)
                  // // console.log('TImestamp now', currentDate)

                  if (value.timestampCacheIsValid <= currentDate) {
                    // console.log('Cache is no longer valid, proceeding with http');
                    proceedWithHTTP = true;
                  }
                } else {
                  // console.log('No timestamp to check validity of cache, proceeding with http');
                  proceedWithHTTP = true;
                }
              } else {
                // console.log('No data was found in cache, proceeding with http');
                proceedWithHTTP = true;
              }

              if (proceedWithHTTP === true) {
                return this.getResourceReadingsAPI(url, newTimestampCacheIsValid, dimension);
              } else {
                // console.log('Returning from cache ' + url);
                // console.log(value);
                return of(value);
              }
            })
          );
      }
    } else {
      return throwError(response);
    }
  }

  getForecastResourceReadings(resourceId: string, fromDate: string, toDate: string, period: string, func: string = 'sum', offset: string = '0', forecastType: string = 'long', getFromHttp = false, dimension = null): Observable<tResourceReadings> {
    const response = this.performPreAPIChecksNoNetwork();
    if (response && response.isError === false) {
      // console.log(
      // 'Get forecast resource readings ' +
      //     resourceId +
      //     ' for app=' +
      //     this.applicationId +
      //     ' offset ' +
      //     offset +
      //     ' funct ' +
      //     func
      // );
      // use a hash of the resource URL query and store the result
      let url: string;
      if (offset && offset != '0') {
        url = this.hostUrl + 'resource/' + resourceId + '/readings?from=' + fromDate + '&to=' + toDate + '&period=' + period + '&forecastType=' + forecastType + '&offset=' + offset + '&function=' + func;
      } else {
        url = this.hostUrl + 'resource/' + resourceId + '/readings?from=' + fromDate + '&to=' + toDate + '&period=' + period + '&forecastType=' + forecastType + '&function=' + func;
      }

      // console.log('url', url);

      const currentDatetime = new Date();
      const currentDate = this.dateFormatService.apiTimeFormat(new Date());
      let maxCacheDate = this.dateFormatService.apiTimeFormat(new Date(currentDatetime.getFullYear(), currentDatetime.getMonth(), currentDatetime.getDate() + 1, 23, 59, 59));
      let minCacheDate = this.dateFormatService.apiTimeFormat(new Date(currentDatetime.getFullYear(), currentDatetime.getMonth(), currentDatetime.getDate(), currentDatetime.getHours(), currentDatetime.getMinutes() + 30, 59));

      // if is after 23:00 set the cache to current date
      if (currentDatetime.getHours() >= 22) {
        minCacheDate = currentDate;
      }
      var newTimestampCacheIsValid;

      if (currentDate > toDate) {
        newTimestampCacheIsValid = maxCacheDate;
      } else {
        newTimestampCacheIsValid = minCacheDate;
      }

      //Get from http (force refresh)
      if (getFromHttp === true) {
        return this.getResourceReadingsAPI(url, newTimestampCacheIsValid, dimension);
      } else {
        //Look into cache first
        return from(this.getDataFromStorage(url)) //convert to Promise and return chain.
          .pipe(
            switchMap((value) => {
              //use Observable.switchMap to move to second observable
              var proceedWithHTTP = false;
              if (value != null) {
                // console.log('Getting from cache ' + url);
                if (value.timestampCacheIsValid) {
                  if (value.timestampCacheIsValid <= currentDate) {
                    // console.log('Cache is no longer valid, proceeding with http');
                    proceedWithHTTP = true;
                  }
                } else {
                  // console.log('No timestamp to check validity of cache, proceeding with http');
                  proceedWithHTTP = true;
                }
              } else {
                // console.log('No data was found in cache, proceeding with http');
                proceedWithHTTP = true;
              }
              if (proceedWithHTTP === true) {
                return this.getResourceReadingsAPI(url, newTimestampCacheIsValid, dimension);
              } else {
                // console.log('Returning from cache ' + url);
                // console.log(value);
                return of(value);
              }
            })
          );
      }
    } else {
      return throwError(response);
    }
  }

  getGroupedResourceAPI(url: string, newTimestampCacheIsValid: number): Observable<tGroupedResource> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get(url, this.getAuthOptions()).pipe(
        map((res: any) => {
          let resource = res;
          // console.log('Getting from http');
          var saveResource = false;

          if (resource.data && Array.isArray(resource.data)) {
            for (let i = 0; i < resource.data.length; i++) {
              resource.data[i][0] = resource.data[i][0] * 1000;
              if (resource.data[i][1] == 18446744073709552000) {
                resource.data[i][1] = 0;
              }
              if (resource.data[i][1] != 0 && saveResource === false) {
                saveResource = true;
              }
              if (resource.data[i][1]) {
                resource.data[i][1] = Number(resource.data[i][1].toFixed(4));
              }
            }
          }
          // console.log('Setting in storage');
          resource.timestampCacheIsValid = newTimestampCacheIsValid;
          if (saveResource === true) {
            this.storage.set(url, resource);
          } else {
            // console.log('Received data will not be put in cache');
          }
          return resource;
        }),
        catchError((error: any) => throwError(error))
      );
    } else {
      return throwError(response);
    }
  }

  getGroupedResource(resourceId: string, fromDate: string = null, toDate: string = null, getFromHttp = false): Observable<tGroupedResource> {
    // console.log('getGroupedResource');

    const response = this.performPreAPIChecksNoNetwork();
    if (response && response.isError === false) {
      const offset = localStorage.getItem('offset');
      // console.log('Get resource readings ' + resourceId + ' for app=' + this.applicationId + ' offset ' + offset);
      // use a hash of the resource URL query and store the result
      let url: string;

      if (fromDate && toDate) {
        url = this.hostUrl + 'resource/' + resourceId + '/readings?from=' + fromDate + '&to=' + toDate;
      } else {
        url = this.hostUrl + 'resource/' + resourceId + '/readings';
      }

      const currentDatetime = new Date();
      const currentDate = this.dateFormatService.apiTimeFormat(new Date());
      let maxCacheDate = this.dateFormatService.apiTimeFormat(new Date(currentDatetime.getFullYear(), currentDatetime.getMonth(), currentDatetime.getDate() + 1, 23, 59, 59));
      let minCacheDate = this.dateFormatService.apiTimeFormat(new Date(currentDatetime.getFullYear(), currentDatetime.getMonth(), currentDatetime.getDate(), currentDatetime.getHours(), currentDatetime.getMinutes() + 30, 59));

      // if is after 23:00 set the cache to current date
      if (currentDatetime.getHours() >= 22) {
        minCacheDate = currentDate;
      }

      var newTimestampCacheIsValid;
      if (currentDate > toDate) {
        newTimestampCacheIsValid = maxCacheDate;
      } else {
        newTimestampCacheIsValid = minCacheDate;
      }

      //Get from http (force refresh)
      if (getFromHttp === true) {
        return this.getGroupedResourceAPI(url, newTimestampCacheIsValid);
      } else {
        //Look into cache first
        return from(this.getDataFromStorage(url)) //convert to Promise and return chain.
          .pipe(
            switchMap((value) => {
              //use Observable.switchMap to move to second observable
              var proceedWithHTTP = false;
              if (value != null) {
                // console.log('Getting from cache ' + url);
                if (value.timestampCacheIsValid) {
                  if (value.timestampCacheIsValid <= currentDate) {
                    // console.log('Cache is no longer valid, proceeding with http');
                    proceedWithHTTP = true;
                  }
                } else {
                  // console.log('No timestamp to check validity of cache, proceeding with http');
                  proceedWithHTTP = true;
                }
              } else {
                // console.log('No data was found in cache, proceeding with http');
                proceedWithHTTP = true;
              }

              if (proceedWithHTTP === true) {
                return this.getGroupedResourceAPI(url, newTimestampCacheIsValid);
              } else {
                // console.log('Returning from cache ' + url);
                // console.log(value);
                return of(value);
              }
            })
          );
      }
    } else {
      return throwError(response);
    }
  }

  getResourceFirstTime(resourceId: string): Observable<tResourceFirstTime> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tResourceFirstTime>(this.hostUrl + 'resource/' + resourceId + '/firsttime', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getDataFromStorage(key: string): Promise<any> {
    return this.storage.get(key);
  }

  getResourceSummaryReadings(resourceId: string): Observable<tResourceSummaryReadings> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tResourceSummaryReadings>(this.hostUrl + 'resource/' + resourceId + '/summary', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getResourceCurrentReading(resourceId: string): Observable<tResourceInstantReadings> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tResourceInstantReadings>(this.hostUrl + 'resource/' + resourceId + '/current', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getResourceMeterReading(resourceId: string): Observable<tResourceMeterReading> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tResourceMeterReading>(this.hostUrl + 'resource/' + resourceId + '/meterread', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getResourceTariff(resourceId: string): Observable<any> {
    // console.log('Get resource tariff ' + resourceId);
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<any>(this.hostUrl + 'resource/' + resourceId + '/tariff', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getResourceTypes(): Observable<tResourceType[]> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tResourceType[]>(this.hostUrl + 'resourcetype/', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getResourceType(resourceTypeId: string): Observable<tResourceType> {
    // console.log('Get resource type ' + resourceTypeId);
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tResourceType>(this.hostUrl + 'resourcetype/' + resourceTypeId, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  // ************************** Device Calls / Provisioning *********************************/
  getDevices(tag = null): Observable<tDevice[]> {
    // console.log('Get devices ');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      let url = this.hostUrl + 'device?';
      if (tag) {
        url += 'tags=' + tag;
      }
      return this.http.get<tDevice[]>(url, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getDevice(deviceId: string): Observable<tDevice> {
    // console.log('Get device ' + deviceId);
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tDevice>(this.hostUrl + 'device/' + deviceId, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getDeviceByResourceId(resourceId: string): Observable<tDevice> {
    // console.log('Get device by resource Id ' + resourceId);
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tDevice>(this.hostUrl + 'device/resource/' + resourceId, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getDeviceStatus(deviceId: string): Observable<tDeviceStatus> {
    // console.log('Get device status ' + deviceId);
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tDeviceStatus>(this.hostUrl + 'device/' + deviceId + '/status', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getDeviceRegisters(deviceId: string): Observable<tDeviceRegisters> {
    // console.log(`Get device registers ${deviceId}`);
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tDeviceRegisters>(`${this.hostUrl}device/${deviceId}/registers`, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  //   getDeviceBalance(deviceId: string): Observable<tDeviceBalance> {
  //     // console.log(`Get device balance ${deviceId}`);
  //     const response = this.performPreAPIChecksWithAuth();
  //     if (response && response.isError === false) {
  //       return this.http.get<tDeviceBalance>(`${this.hostUrl}device/${deviceId}/balance`, this.getAuthOptions());
  //     } else {
  //       return throwError(response);
  //     }
  //   }

  //   getDeviceBalanceByHardwareId(hardwareId: string): Observable<tDeviceBalance> {
  //     // console.log(`Get device balance ${hardwareId}`);
  //     const response = this.performPreAPIChecksWithAuth();
  //     if (response && response.isError === false) {
  //       return this.http.get<tDeviceBalance>(`${this.hostUrl}device/balance?tags=${hardwareId}`, this.getAuthOptions());
  //     } else {
  //       return throwError(response);
  //     }
  //   }

  getDeviceSetting(setting: string, deviceId: string, params: any = {}): Observable<tDeviceWithSetting> {
    // console.log('Get device setting' + deviceId);
    const response = this.performPreAPIChecksWithAuth();

    let url = `${this.hostUrl}device/${deviceId}/settings/${setting}?`;
    if (params) {
      const { operation } = params;
      url = `${url}operation=${operation}`;
    }

    if (response && response.isError === false) {
      return this.http.get<tDeviceWithSetting>(url, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  setDeviceSetting(setting: string, deviceId: string, settingBody: any): Observable<tDeviceWithSetting> {
    // console.log('setDeviceSetting ' + deviceId);
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post<tDeviceWithSetting>(this.hostUrl + 'device/' + deviceId + '/settings/' + setting, JSON.stringify(settingBody), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getResourceDevice(resourceId: string): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<any>(this.hostUrl + 'device/resource/' + resourceId, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  refreshResourceBinary(resourceId: string): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.put<any>(`${this.hostUrl}resource/${resourceId}/glowbinary?clearCache=1`, {}, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  // TODO fix typing for product
  getProduct(productId: string): Observable<tDevice> {
    // console.log('Get product ' + productId);
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post<tDevice>(this.hostUrl + 'product/' + productId, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getGlowStickAccessPointCredentials(hardwareDetails: tDiscoveryInfo): Observable<tGlowStickAccessPointPassword> {
    // console.log('getGlowStickAccessPointCredentials');
    const response = this.performPreAPIChecksWithoutAuth();

    if (response && response.isError === false) {
      if (hardwareDetails && hardwareDetails.hasOwnProperty('MAC') && hardwareDetails.hasOwnProperty('serialNumber')) {
        let params: HttpParams = new HttpParams();
        params = params.set('MAC', hardwareDetails.MAC);
        params = params.set('serialNumber', hardwareDetails.serialNumber);
        let options = this.getNoAuthOptions();
        options['params'] = params;
        return this.http.get<tGlowStickAccessPointPassword>(this.hostUrl + 'wifisetup?', options);
      } else {
        return throwError('missing query parameters, MAC, serial Number');
      }
    } else {
      return throwError(response);
    }
  }

  discoverHardware(hardwareInfo: tDiscoveryInfo): Observable<tDeviceProductDiscovery> {
    // console.log('Discover assets and devices');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post<tDeviceProductDiscovery>(this.hostUrl + 'discover', JSON.stringify(hardwareInfo), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  addKit(hardwareInfo: tDiscoveryInfo): Observable<tProvisioningStatus> {
    // console.log('Add kit');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      const provisionBody = hardwareInfo;
      return this.http.post<tProvisioningStatus>(this.hostUrl + 'provision/devices/' + this.applicationName, JSON.stringify(provisionBody), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  onboardSmartMeterEuiPostcode(euiPostcode: tEuiPostcode): Observable<tIsValid> {
    // console.log('onboardSmartMeterEuiPostcode');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post<tIsValid>(`${this.hostUrl}provision/eui-postcode`, JSON.stringify(euiPostcode), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  onboardSmartMeterMpxnAvs({ mpxn, consent }): Observable<any> {
    // POST 'https://HOST_GLOW/api/v0-1/provision/smart-meter-mpxn-avs'
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post(`${this.hostUrl}provision/smart-meter-mpxn-avs`, JSON.stringify({ consent, mpxn: { key: 'mpan', value: mpxn } }), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  addChildDevice(provisionBody: tProvisioningAddChildDeviceReq) {
    // console.log('Add Sensor');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post(this.hostUrl + 'provision/devices/children', JSON.stringify(provisionBody), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  // ****************************** EligibilityChecks ******************************************/
  checkSmetsIhdPostcodeIsEligible(data: tEligibitySmetsIhdPostcodeCheck) {
    // console.log('Add Sensor');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post(this.rateLimitingHostUrl + 'eligibility/checksmetsihd/postcode', JSON.stringify(data), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  checkMpxnAvailableFuelTypes(data: tEligibitySmetsFuelTypesReq): Observable<tEligibitySmetsFuelTypes> {
    // console.log('Add Sensor');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post<tEligibitySmetsFuelTypes>(this.rateLimitingHostUrl + 'eligibility/checksmetsmpxn/fuels', JSON.stringify(data), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  checkSmetsMpxnIsEligible(data: tEligibitySmetsFuelTypesReq): Observable<tIsValid> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post<tIsValid>(this.rateLimitingHostUrl + 'eligibility/checksmetsmpxn', JSON.stringify(data), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }
  gethealthCheckReport(userOrganizationId: string, reportType: string): Observable<any> {
    // console.log('getHealthCheckReport');
    let url = this.hostUrl + `devices/report/mpxn-health-check/userorganization/${userOrganizationId}?reportType=${reportType}`;
    return this.http.get<any>(url, this.getAuthOptions());
  }

  gethealthCheckCount(userOrganizationId: string, reportType: string): Observable<any> {
    // console.log('getHealthCheckReport');
    let url = this.hostUrl + `devices/report/mpxn-health-check-count/userorganization/${userOrganizationId}?reportType=${reportType}`;
    return this.http.get<any>(url, this.getAuthOptions());
  }

  //   getmeterPointVerification(): Observable<tmeterPointVerificationList> {
  //     // console.log("getMeterPointVerification");
  //     let url = this.hostUrl + `provision/meter-points/smart-meter-consent`;
  //     // console.log("the url ")
  //     // console.log(url)
  //     return this.http.get<tmeterPointVerificationList>(url, this.getAuthOptions());
  //   }

  postmeterPointBulkConsent(bulkmeter: any): Observable<any> {
    // console.log('getMeterPointVerification');
    let url = this.hostUrl + `provision/meter-points/smart-meter-consent`;
    // console.log('the url ');
    // console.log(url);
    return this.http.post<any>(this.hostUrl + 'provision/meter-points/smart-meter-consent/bulk-consent', JSON.stringify(bulkmeter), this.getAuthOptions());
  }

  postmeterPointRemoveConsent(bulkmeter: any): Observable<any> {
    // console.log('getMeterPointVerification');
    let url = this.hostUrl + `provision/meter-points/smart-meter-consent`;
    // console.log('the url ');
    // console.log(url);
    return this.http.post<any>(this.hostUrl + 'user/verification/status/mpxns/revocation', JSON.stringify(bulkmeter), this.getAuthOptions());
  }

  // gethealthCheckReport( userOrganizationId: string, reportType: string): Promise<any> {
  //   // console.log('getHealthCheckReport');
  //   let url = this.hostUrl + `devices/report/mpxn-health-check/userorganization/${userOrganizationId}?reportType=${reportType}`;

  //   return new Promise((resolve, reject) => {
  //     this.http.get<any>(url, this.getAuthOptions( )).subscribe((response: any) => {
  //       // console.log("the result of health check ")
  //       // console.log(JSON.stringify(response))
  //       resolve(response);
  //     }, reject);
  //   });

  //   //return this.http.get<any>(url, this.getAuthOptions( ))
  // }

  // ****************************** Message/Alert Calls ******************************************/

  getInboxStatus(): Observable<tInboxStatus> {
    // console.log('Get inbox status');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tInboxStatus>(this.hostUrl + 'inbox/message/status', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getInboxMessages(): Observable<tInboxMessageList> {
    // console.log('Get inbox messages');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tInboxMessageList>(this.hostUrl + 'inbox/message', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  readInboxMessage(messageId: string): Observable<tInboxMessage> {
    // console.log('Read inbox message');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tInboxMessage>(this.hostUrl + 'inbox/message/' + messageId, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  deleteInboxMessage(messageId: string): Observable<tInboxDeleteStatus> {
    // console.log('deleteInboxMessage');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      const messagesToBeDeleted = {
        MessageIds: [messageId],
      };
      return this.http.post<tInboxDeleteStatus>(this.hostUrl + 'inbox/message/delete', JSON.stringify(messagesToBeDeleted), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  deleteInboxMessages(messageIds: string[]): Observable<tInboxDeleteStatus> {
    // console.log('deleteInboxMessages');
    const response = this.performPreAPIChecksWithAuth();

    if (response && response.isError === false) {
      const messagesToBeDeleted = {
        MessageIds: messageIds,
      };
      return this.http.post<tInboxDeleteStatus>(this.hostUrl + 'inbox/message/delete', JSON.stringify(messagesToBeDeleted), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }
  // ****************************** Notification Calls ******************************************/
  getTemplates(applicationId = ''): Observable<tTemplate[]> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tTemplate[]>(this.hostUrl + 'ns/template', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getTemplatebyProperties(alertTypeId: string, channelType: string, cultureCode: string): Observable<tTemplate> {
    // note that the applicationId is an optional parameter and needs to be listed last
    //https://www.typescriptlang.org/docs/handbook/functions.html
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      // console.log('getTemplatebyProperties');
      // console.log(
      //   'Get template for app ' + this.applicationId + ' alert Type ' + alertTypeId + channelType + cultureCode
      // );
      return this.http.get<tTemplate>(this.hostUrl + 'ns/template/' + alertTypeId + '/' + channelType + '/' + cultureCode, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  sendAlert(alertInfo: any, applicationId = ''): Observable<tAlertStatus> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      // console.log('Send Alert');
      return this.http.post<tAlertStatus>(this.hostUrl + 'ns/alert', JSON.stringify(alertInfo), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getAlertTypes(): Observable<tAlertType[]> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      // console.log('Get alert types');
      return this.http.get<tAlertType[]>(this.hostUrl + 'ns/alerttype', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getAlertsOfUser(userId, applicationId = ''): Observable<tAlertHistoryUser[]> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      // console.log('Get alerts');
      return this.http.get<tAlertHistoryUser[]>(this.hostUrl + 'ns/alert/user/' + userId, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getAlertsOfUserGroup(userGroupId, applicationId = ''): Observable<tAlertHistoryUserGroup[]> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      // console.log('Get alerts, getAlertsOfUserGroup');
      // use a Promise in this instance as it is a straight forward single GET
      return this.http.get<tAlertHistoryUserGroup[]>(this.hostUrl + 'ns/alert/usergroup/' + userGroupId, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  // ****************************** Ticket Calls ******************************************/

  createTicket(ticket: any): Observable<tTicketCreateResponse> {
    // console.log('Create ticket');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post<tTicketCreateResponse>(this.hostUrl + 'ticket', JSON.stringify(ticket), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  // ****************************** Payment Calls ******************************************/
  // October 2019 (better doc required eventually). Expected input is:
  // {"amount": 0.0,"cardType":"MC_DEBIT","cardToken":"PAYPOINT TOKEN","cardholderName":"firstName lastName","expiryDate":"MM/YYYY","cvv":"000"}
  makePayment(paymentInfo: any, paymentProvider: string): Observable<tIsValidPaymentRes> {
    // console.log('Make payment');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post<tIsValidPaymentRes>(this.hostUrl + 'payment/' + paymentProvider, JSON.stringify(paymentInfo), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getPaymentTransaction(transactionId: string): Observable<tPaymentTransactionCallbackInfo> {
    // console.log('Get Payment Transaction');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tPaymentTransactionCallbackInfo>(this.hostUrl + 'payment/transaction/' + transactionId + '/callback', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getSavedCards(paymentProvider: string): Observable<tSavedCards> {
    // console.log('Get Saved Cards');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tSavedCards>(this.hostUrl + 'paymentmethod/' + paymentProvider, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  removeSavedCard(paymentCardId: string): Observable<tSavedCards> {
    // console.log('Remove Saved Card');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.delete<tSavedCards>(this.hostUrl + 'paymentmethod/paypoint/' + paymentCardId, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getPaymentLocation(paymentProvider: string, searchCriteria): Observable<any> {
    // console.log('Get payment provider location');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<any>(`${this.hostUrl}payment/${paymentProvider}/locations?search=${searchCriteria}`, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  saveCard(paymentInfo: any, paymentProvider: string): Observable<tIsValidPaymentRes> {
    // console.log('Save Card');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post<tIsValidPaymentRes>(this.hostUrl + 'paymentmethod/' + paymentProvider, JSON.stringify(paymentInfo), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  paymentValidation(paymentInfo: any, paymentType: string): Observable<tIsValidPaymentRes> {
    // console.log('Payment Validation');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post<tIsValidPaymentRes>(this.hostUrl + 'payment/' + paymentType + '/validation', JSON.stringify(paymentInfo), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  // ****************************** Event Calls ******************************************/

  //App events typically trigger a customised process
  sendAppEvent(applicationEvent: tApplicationEventReq[]): Observable<tApplicationEventRes> {
    // console.log('Send App Event');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post<tApplicationEventRes>(this.hostUrl + 'appevent', JSON.stringify(applicationEvent), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  //Glow events are used to log events that we care about happening to a user.
  sendGlowEvent(glowEvent: tGlowEventReq): Observable<tStatusResponse> {
    // console.log('Send Glow Event');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post<tStatusResponse>(this.hostUrl + 'glowevent', JSON.stringify(glowEvent), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  onError(res: HttpResponse<tApiErrorResponse>) {
    const statusCode = res.status;
    const body = res;
    const error = {
      statusCode: statusCode,
      error: body['error'],
    };
    return throwError(error);
  }

  public isLoggedIn(): boolean {
    const token = localStorage.getItem('id_token');
    if (token) {
      if (this.jwtHelper.isTokenExpired(token) == false) {
        return true;
      } else {
        console.warn('Token expired');
      }
    } else {
      console.warn('Token non-existent');
    }
    return false;
  }

  // ************************** Token Check *******************************/
  /* This function is the same as loggedIn but also does the following:
  1. If token not good,
      i) it logs the user out
      ii) emits a logout event (this is picked up by app component that is responsible for setting root page)
  2. If token good: it checks at whether the token should be refreshed.
  A token is good if it is not expired. (note: this function does not check the token in the back-end system)
  */
  public checkExistingToken(): boolean {
    const loggedIn = this.isLoggedIn();

    if (loggedIn === false) {
      this.emitLogOut();
    }
    return loggedIn;
  }

  checkValidateExistingToken(): void {
    const validToken = this.checkExistingToken();
    if (validToken === true) {
      this.validateToken().subscribe(
        (validToken) => {
          // console.log('Validate Token HttpResponse');
          // console.log(validToken);
          if (validToken && validToken.hasOwnProperty('valid') && validToken.valid == true) {
            this.checkRefreshToken();
          } else {
            this.emitLoggingOut();
          }
        },
        (error) => {
          console.warn('validateToken returns with error');
          console.warn(error);
          let emitLogOut = true;
          // Do not emit logout if there is a network error.
          if (error && error.hasOwnProperty('messages') && Array.isArray(error.messages) && error.messages.length > 0) {
            for (let i = 0; i < error.messages.length; i++) {
              if (error.messages[i] == 'ERROR_NO_NETWORK') {
                emitLogOut = false;
              }
            }
            if (emitLogOut === true) {
              console.warn('Token Validity Check Error : ' + error);
              this.emitLoggingOut();
            }
          }
        }
      );
    }
  }

  // Function that checks expiration date on existing token and evaluates whether it should refresh the token.
  checkRefreshToken(forceUpdate = false): void {
    const token = localStorage.getItem('id_token');
    if (token) {
      const tokenExpirationDate = this.jwtHelper.getTokenExpirationDate(token);
      // // console.log(tokenExpirationDate);

      // Step 1. Check the limit after which we can proceed with expiring a token
      let numberOfDaysBeforeExpiry = this.configService.getTokenRefreshParameters();

      if (numberOfDaysBeforeExpiry > 6) {
        console.warn('Setting numberOfDaysBeforeExpiry to default');
        numberOfDaysBeforeExpiry = 6;
      }
      const minExpiryDate = new Date(new Date().getTime() + numberOfDaysBeforeExpiry * 24 * 60 * 60 * 1000);

      if (tokenExpirationDate <= minExpiryDate || forceUpdate === true) {
        // console.log('Will Refresh token due to forceUpdate: ' + forceUpdate + ' or expiry ' + !forceUpdate);
        // TO DO. trigger respective call . Need to take care that we are not in the middle of multiple
        // calls being made bcs refresh token makes other token invalid (?).
        this.refreshToken().subscribe(
          (newToken) => {
            if (newToken && newToken.hasOwnProperty('valid') && newToken.hasOwnProperty('token')) {
              // console.log('Setting new token', newToken);

              if (newToken.valid == true) {
                this.setToken(newToken.token);
              }
            }
          },
          (error) => {
            // console.log('Token Validity Check Error : ' + error);
          }
        );
      } else {
        // console.log('Token not required to be refreshed, skipping');
      }
    }
  }

  public isAuthenticated(): Observable<boolean> {
    return of(this.checkExistingToken());
  }

  setToken(token: string) {
    localStorage.setItem('id_token', token);
  }

  //This event activates toasts and features when the application actively logs someone out, use with caution
  emitLoggingOut() {
    // console.log('emitting logging out');
    this.getAuthEvent.emit('user:logging_out');
    this.getAuthEvent.emit('user:logout');
  }

  emitLogIn() {
    // console.log('emitting login ');
    this.getAuthEvent.emit('user:loggedIn');
  }

  emitLogOut() {
    // console.log('emitting logout');
    this.getAuthEvent.emit('user:logout');
  }

  //removes token, name and accountId from localStorage
  //reomoves ve
  deleteJwt() {
    // console.log('deleteJWT token from local storage');
    localStorage.removeItem('id');
    localStorage.removeItem('id_token');
    localStorage.removeItem('name');
    localStorage.removeItem('isTempAuth');
    localStorage.removeItem('passport');
    localStorage.removeItem('manualHTC');
    localStorage.removeItem('selectedService');
    this.storage.remove('selectedVeApplicationDetails'); // Should not be here
    return;
  }

  saveJwt(data: any): boolean {
    // console.log(data);
    if (data.valid === true) {
      // console.log('got a token and storing JWT locally');
      localStorage.setItem('id_token', data.token);
      localStorage.setItem('id', data.accountId);
      localStorage.setItem('name', data.name);
      if (data.isTempAuth === true) {
        localStorage.setItem('isTempAuth', data.isTempAuth);
      }
      this.emitLogIn();
      return true;
    }
    return false;
  }

  /* Septemeber 2018. 
     This function is responsible for checking the status of external directory services.
     This check may be required for some applications and is depndedant firstly on the external authentication systems used,
     and secondly on the functionality, requirements and user journey that the app supports. It could be the case that 
     in order to perform specific action a user will need to have a valid external authentication token, in addition to
     their Glow JWT token.
   */
  checkExternalDirectory() {
    this.getAccountStatus().subscribe(
      (accountStatus) => {
        // console.log('Account Status successfully retrieved', accountStatus);

        if (accountStatus && accountStatus.directory && accountStatus.directory.data && accountStatus.directory.data.tokenExpiryDate) {
          var minimumExpiryDate = new Date(new Date().getTime() + 60 * 60 * 1000); // The token should have at least one hour of life.
          // console.log(new Date(accountStatus.directory.data.tokenExpiryDate), minimumExpiryDate);
          if (new Date(accountStatus.directory.data.tokenExpiryDate) <= minimumExpiryDate) {
            console.warn('External token is about to expire, logging out');
            this.emitLoggingOut();
          } else {
            // console.log('Token not required to be refreshed');
          }
        }
      },
      (error) => {
        // console.log('Account Status loading error is : ' + error);
        console.warn(error);
      }
    );
  }

  isTempAuth(): boolean {
    return localStorage.getItem('isTempAuth') === 'true' ? true : false;
  }

  // ************************** Groups *******************************/

  createGroup(groupBody: tDataGroupCreateReq): Observable<any> {
    // console.log('create group ' + groupBody);
    // console.log('the body sending to the groups system @glowservice ' + JSON.stringify(groupBody));
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post(this.hostUrl + 'data-group/group/entities', groupBody, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  addEntitiesToGroup(groupId: string, groupBody: any): Observable<any> {
    // console.log('add entities to group ' + groupBody);
    // console.log('the body sending to the groups system @glowservice ' + JSON.stringify(groupBody));
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post(this.hostUrl + 'data-group/group/' + groupId + '/entities', groupBody, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  registerDFSEventParticipation(eventInfo): Observable<tIsValid> {
    // console.log('register user to dfs event');
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return this.http.post<tIsValid>(this.hostUrl + '/public-dfs/event/user/participation', eventInfo, this.getNoAuthOptions());
    } else {
      return throwError(response);
    }
  }

  /// request estimates

  validateVirtualEntityRequirements(veId) {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      // let headers: HttpHeaders = new HttpHeaders({
      //   userId: 'f2c4c4b6-df3e-4688-aa8f-0d6f603db9d6',
      //   applicationId: 'b0f1b774-a586-4f72-9edd-27ead8aa7a8d',
      // });
      return this.http.get(this.hostUrl + 'estimate-requirement/heat-pump-estimate/virtual-entity/' + veId + '/validation', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getSnapshotBySnapshotId(snapshotId) {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get(this.hostUrl + 'snapshot/' + snapshotId, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  findAllEstimateRequestsByUserId(showProfessionalMeta?): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get(this.hostUrl + `estimate-request?includeProfessionalMeta=${showProfessionalMeta ? true : false}`, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  findEstimateRequestsByVeIdAndState(veId, showProfessionalMeta = false, state = '') {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get(this.hostUrl + '/estimate-request/virtual-entity/' + veId + `/lookup?estimateRequestState=${state}&includeProfessionalMeta=${showProfessionalMeta}`, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  // findAllEstimateRequestsByState(state) {
  //   const response = this.performPreAPIChecksWithoutAuth();
  //   if (response && response.isError === false) {
  //     return this.http.get<any>(this.hostUrl + 'service-agent/estimate-requests?state=' + state, this.getOAuthOptions());
  //   } else {
  //     return throwError(response);
  //   }
  // }

  createEstimateRequest(body, veId) {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post<any>(this.hostUrl + 'estimate-request/virtual-entity/' + veId, JSON.stringify(body), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  updateEstimateRequestUserState(body, estimateRequestId) {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.put<any>(this.hostUrl + 'estimate-request/' + estimateRequestId + '/state', JSON.stringify(body), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getMpanInventory(mpan) {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<any>(this.hostUrl + '/device/meter-point/' + mpan + '/inventory', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  updateEstimateRequest(body, estimateRequestId, veId) {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.put<any>(this.hostUrl + 'estimate-request/' + estimateRequestId + '/virtual-entity/' + veId + '/accept', JSON.stringify(body), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getEstimateCustomer(estimateId) {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get(this.hostUrl + 'estimate-response/' + estimateId, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getEstimateRequestById(estimateId) {
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return this.http.get(this.hostUrl + 'estimate-request/' + estimateId, this.getOAuthOptions());
    } else {
      return throwError(response);
    }
  }

  updateEstimateRequestByCustomer(estimateRequestId, body) {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.put<any>(this.hostUrl + 'estimate-request/' + estimateRequestId + '/update', JSON.stringify(body), this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  checkUserEmailLogin(body) {
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return this.http.post(this.hostUrl + 'user/determine-login/', JSON.stringify(body), this.getNoAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getUserVerificationStatus(): Observable<tVerificationStatus> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<tVerificationStatus>(`${this.hostUrl}user/verification/status`, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }
  renewMeterPointVerification({ mpxn }: any) {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post<tVerificationInfoSubmissionIsValidRes>(`${this.hostUrl}user/verification/status/mpxn/${mpxn}/renewal`, {}, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }
  revokeMeterPointVerification({ mpxn, isValidUntil }: any) {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post<tVerificationInfoSubmissionIsValidRes>(`${this.hostUrl}user/verification/status/mpxn/${mpxn}/revocation`, { isValidUntil }, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getUserVerificationStatusRevocations(): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<any>(`${this.hostUrl}user/verification/status/mpxn/revocation`, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  declineEstimateRequest(estimateRequestId, veId): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.put<any>(`${this.hostUrl}estimate-request/${estimateRequestId}/virtual-entity/${veId}/decline`, {}, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }
  // ****************************** Stripe Calls ******************************************/

  srtipeSetUpCustomer(): Observable<any> {
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post<any>(this.hostUrl + 'payment/stripe/setup', {}, this.getAuthOptions());
    }
    return throwError(response);
  }

  stripeSetupIntent(): Observable<any> {
    console.log('stripeSetupIntent');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.post<any>(this.hostUrl + 'paymentmethod/stripe', { usage: 'on_session' }, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getPaymentMethods(): Observable<any> {
    console.log('Get user groups');
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.get<any>(this.hostUrl + 'paymentmethod/stripe', this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  deleteSavedCard(id) {
    console.log('deleteSavedCard', id);
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return this.http.delete(this.hostUrl + 'paymentmethod/stripe/' + id, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }

  updateDefaultPaymentMethod(paymentMethodId: string) {
    console.log(`updateDefaultPaymentMethod ${paymentMethodId}`);
    const response = this.performPreAPIChecksWithAuth();
    if (response && response.isError === false) {
      return this.http.put<any>(`${this.hostUrl}paymentmethod/stripe/${paymentMethodId}/set-default`, {}, this.getAuthOptions());
    } else {
      return throwError(response);
    }
  }
}
