import { Pipe } from '@angular/core';
@Pipe({
  name: 'displaygreaterdate'
})
export class DisplayGreaterDatePipe {
  transform(value) {
    const dates = ["lastprofile", "lread", "lasttariff", "lastcommscheck", "lasttariffcheck", "lastcomms", "lastprofilecheck"]
    const dateFields = dates.reduce((acc, dateKey) => {
      if (value[dateKey]) {
        acc.push(value[dateKey])
      }
      return acc
    }, []);
    
    return dateFields.sort()[0];
  }
}