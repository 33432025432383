import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertyComponent } from './property.component';
import { ProfessionalAccountSideMenuModule } from 'src/app/components/professional-account-side-menu/professional-account-side-menu.module';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [PropertyComponent],
  imports: [
    CommonModule, SharedModule, ProfessionalAccountSideMenuModule
  ],
  exports: [PropertyComponent]
})
export class PropertyModule { }
