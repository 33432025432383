import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstallerJourneyInfographicComponent } from './installer-journey-infographic.component';



@NgModule({
  declarations: [InstallerJourneyInfographicComponent],
  imports: [
    CommonModule
  ],
  exports: [InstallerJourneyInfographicComponent]
})
export class InstallerJourneyInfographicModule { }
