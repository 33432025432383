import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SchematicsComponent } from './schematics.component';
import { SchematicsHomepageStatusComponent } from 'src/app/components/schematics-homepage-status/schematics-homepage-status.component';

export const routes: Routes = [
  {
    path: '',
    component: SchematicsComponent,
    children: [
      {
        path: ':status',
        component: SchematicsHomepageStatusComponent,
      },
      { path: '', redirectTo: 'in-progress', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SchematicsRouting {}
