import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss', '../pages.component.scss']
})
export class SettingsComponent implements OnInit {
  pageTitle = "Settings"
  currentPage = 'Account settings';
  pages = [
    { path: '/pages/account/settings/account-settings', title: 'Account settings', urlPath: 'account-settings' },
    { path: '/pages/account/settings/properties', title: 'Properties and meters', urlPath: 'properties' },
    { path: '/pages/account/settings/subscriptions', title: 'Subscriptions', urlPath: 'subscriptions' },


  ]
  private currentPageAnnouncer = new Subject<any>();
  currentPage$ = this.currentPageAnnouncer.asObservable()

  constructor() { }

  ngOnInit() {
  }
  public onRouterOutletActivate(event: any) {
    if (event.pageTitle) {
      this.currentPageAnnouncer.next(event.pageTitle)
      this.currentPage = event.pageTitle;
    }
    // // console.log("EVENT",event);
  }
  isCurrentPage(page) {
    return page.title == this.currentPage;
  }

  getOtherPages() {
    return this.pages.filter(p => p.title != this.currentPage)
  }
}
