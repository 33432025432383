import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstallationFormComponent } from './installation-form.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormTemplateModule } from '../form-template/form-template.module';
import { FormValidationMessageModule } from '../../form-validation-message/form-validation-message.module';

@NgModule({
  declarations: [InstallationFormComponent],
  imports: [CommonModule, SharedModule, FormTemplateModule, FormValidationMessageModule],
  exports: [InstallationFormComponent],
})
export class InstallationFormModule {}
