import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfographicComponent } from './infographic.component';
import { HpjInfographicModule } from 'src/app/components/infographics/hpj-infographic/hpj-infographic.module';
import { NotFoundPageModule } from '../not-found-page/not-found-page.module';
import { InstallerJourneyInfographicComponent } from 'src/app/components/infographics/installer-journey-infographic/installer-journey-infographic.component';
import { InstallerJourneyInfographicModule } from 'src/app/components/infographics/installer-journey-infographic/installer-journey-infographic.module';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [InfographicComponent],
  imports: [
    CommonModule, SharedModule, HpjInfographicModule, NotFoundPageModule, InstallerJourneyInfographicModule
  ],
  exports:[InfographicComponent]
})
export class InfographicModule { }
