import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfessionalRegisterFormComponent } from './professional-register-form.component';
import { InstallerRegisterFormModule } from 'src/app/components/installer-register-form/installer-register-form.module';
import { LocalAuthorityRegisterFormModule } from 'src/app/components/local-authority-register-form/local-authority-register-form.module';



@NgModule({
  declarations: [ProfessionalRegisterFormComponent],
  imports: [
    CommonModule, InstallerRegisterFormModule, LocalAuthorityRegisterFormModule
  ],
  exports: [ProfessionalRegisterFormComponent]
})
export class ProfessionalRegisterFormModule { }
