import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstallerCardComponent } from './installer-card.component';
import { TagModule } from '../tag/tag.module';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [InstallerCardComponent],
  imports: [
    CommonModule,
    TagModule,
    SharedModule
  ],
  exports: [InstallerCardComponent]
})
export class InstallerCardModule { }
