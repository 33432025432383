import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormTemplateComponent } from './form-template.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FileUploadModule } from '../../file-upload/file-upload.module';
import { FormValidationMessageModule } from '../../form-validation-message/form-validation-message.module';



@NgModule({
  declarations: [FormTemplateComponent],
  imports: [
    CommonModule, SharedModule, FileUploadModule, FormValidationMessageModule
  ],
  exports: [FormTemplateComponent]
})
export class FormTemplateModule {}
