import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FaqsService } from 'src/app/services/faqs.service';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-faq-detailed-content',
  templateUrl: './faq-detailed-content.component.html',
  styleUrls: ['./faq-detailed-content.component.scss'],
})
export class FaqDetailedContentComponent implements OnInit {
  @Input() faq!: any;
  answer!: any;
  constructor(private route: ActivatedRoute, private faqsService: FaqsService) {}

  ngOnInit(): void {


  }
}
