import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StripeCardFormComponent } from './stripe-card-form.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormValidationMessageModule } from '../form-validation-message/form-validation-message.module';
import { SpinnerModule } from '../spinner/spinner.module';

@NgModule({
  declarations: [StripeCardFormComponent],
  imports: [CommonModule, SharedModule, FormValidationMessageModule, SpinnerModule],
  exports: [StripeCardFormComponent],
})
export class StripeCardFormModule {}
