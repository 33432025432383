import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomUIConfigService } from 'src/app/services/custom-ui-config.service';
import { DateFormatService } from 'src/app/services/date-format.service';
import { GlowService } from 'src/app/services/glow.service';
import { ConsentModalComponent } from '../consent-modal/consent-modal.component';
import { MeterPointVerificationConsentManagementService } from 'src/app/services/meter-point-verification-consent-management.service';

@Component({
  selector: 'app-consent-meter-card',
  templateUrl: './consent-meter-card.component.html',
  styleUrls: ['./consent-meter-card.component.scss']
})
export class ConsentMeterCardComponent implements OnInit {
  @Input() meter;
  @Input() expired: boolean
  @Output() event = new EventEmitter<any>();

  constructor(private dateFormatService: DateFormatService,
    private uiConfigService: CustomUIConfigService,
    private glowService: GlowService,
    private modalService: NgbModal,
    private meterPointConsentSvc: MeterPointVerificationConsentManagementService) { }

  ngOnInit() {
  }
  formatTime(date) {
    return this.dateFormatService.fullDateFormatNoSeconds(new Date(date))
  }

  async revokeConsent(meterPoint) {
    meterPoint.loadObj = this.uiConfigService.initialiseLoadingBooleans(true)
    const isValidUntil = this.dateFormatService.apiTimeFormat(new Date())
    try {
      const response = await this.glowService.revokeMeterPointVerification({ mpxn: meterPoint.mpxn, isValidUntil }).toPromise()
      console.log(response)
      this.meterPointConsentSvc.getVerificationStatus()
    } catch (e) {
      console.warn(e)
      meterPoint.loadObj = {
        ...this.uiConfigService.initialiseLoadingBooleans(false),
        errorString: 'Something went wrong',
        loadError: true
      }
      console.log(meterPoint.loadObj)
    }
  }

 

  getStatus(meter) {
    if (meter.isVerified && this.expired) {
      return 'Expired'
    } else if (meter.isVerified && !this.expired) {
      return 'Active'
    } else if (!meter.isVerified && this.expired) {
      return 'Revoked'
    }
  }


  openConsentModal(meter) {
    const modalRef = this.modalService.open(ConsentModalComponent, { size: 'lg' });
    modalRef.componentInstance.meter = meter
    modalRef.componentInstance.modalHeader = 'Renew consent'

  }

}