import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationModalComponent } from './confirmation-modal.component';
import { FormValidationMessageModule } from '../form-validation-message/form-validation-message.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SpinnerModule } from '../spinner/spinner.module';

@NgModule({
  declarations: [ConfirmationModalComponent],
  imports: [CommonModule, FormValidationMessageModule, SharedModule, SpinnerModule],
  exports: [ConfirmationModalComponent],
  entryComponents: [ConfirmationModalComponent],
})
export class ConfirmationModalModule {}
