import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class FormValidationService {
  constructor(private translate: TranslateService) {}

  checkFormValidity(formGroup: FormGroup, formControlName, translateKey?) {
    if (!(formGroup.get(formControlName).errors || formGroup.get(formControlName).value instanceof FileList)) return;

    let messages = [];
    let formFieldName;
    translateKey ? (formFieldName = this.translate.instant(translateKey)) : (formFieldName = 'This field');
    //check if form control has extra validators and user has begun typing
    if (
      (formGroup.get(formControlName).dirty || formGroup.get(formControlName).touched) &&
      formGroup.get(formControlName).value &&
      !(formGroup.get(formControlName).value instanceof FileList) &&
      (formGroup.get(formControlName).value.length || formGroup.get(formControlName).value.size)
    ) {
      const errors = formGroup.get(formControlName).errors;
      const errorsWithMsg = Object.values(errors).filter((v) => v.errMsg);
      messages = errorsWithMsg.map((v) => v.errMsg);
   
      return messages;
    }
    if (
      formGroup.get(formControlName).errors &&
      formGroup.get(formControlName).errors.required &&
      formGroup.get(formControlName).touched &&
      !formGroup.get(formControlName).valid &&
      !(formGroup.get(formControlName).value && formGroup.get(formControlName).value.length)
    ) {
      const errorMsg = `${formFieldName} is required.`;
      messages = [errorMsg];    
      return messages;
    }
    if (formGroup.get(formControlName).value instanceof FileList) {
      const fileArray = Array.from(formGroup.get(formControlName).value);
      const isControlValid = fileArray.every((file: any) => !file.type.includes('image') || (file.type.includes('image') && file.width && file.height && file.description));
      if (!isControlValid) {
        messages = ['Please add a description to all images.'];
        return messages;
      }
      
    }
  }

  isFormValidForNonEmptyControls(formGroup: FormGroup) {
    return Object.values(formGroup.controls).every((control) => {
      const isfileList = control.value instanceof FileList;
      let isControlValid = control.valid;
      if (isfileList) {
        const fileArray = Array.from(control.value);
        isControlValid = fileArray.every((file: any) => !file.type.includes('image') || (file.type.includes('image') && file.width && file.height && file.description));
      }
      return !control.value || isControlValid;
    });
  }

  markFormGroup(formGroup: FormGroup, type) {
    Object.values(formGroup.controls).forEach((control) => {
      if (type == 'touched') control.markAsTouched();
      if (type == 'untouched') control.markAsUntouched();
    });
  }
}
