import { Injectable } from '@angular/core';
import { tDeviceTypeConnectivityMapping, tAssetTypeConnectivityMapping, tResourceTypeSmartMeterMapping, tResourceTypeMapping, tResourceTypeRestrictionConfig, tResourceTypeGroupMapping } from './configuration.typings';
import { GlowService } from './glow.service';

@Injectable({
    providedIn: 'root'
})
//June 2018: DO not use DeviceTypes to evaluate WiFi/ethernet
export class DeviceTypeConfigService {

    //This service classifies the resource types as are required for the vatious vetypes
    deviceTypeConnectivityMappings: tDeviceTypeConnectivityMapping;

    getDeviceTypeConnectivityConfigurations() {

        this.deviceTypeConnectivityMappings = {
            connectivity: {
                wifi: ["9613ffb0-fb70-48fb-8927-4a936fd98c44", "1027b6e8-9bfd-4dcb-8068-c73f6413cfaf", "b91cf82f-aafe-47f4-930a-b2ed1c7b2691", "7adcd7d3-3cd5-4c6d-882d-e5af636cc46f"],
                ethernet: ["a6224773-f6de-4808-b422-dcf9cece1201", "00a5f229-ae7e-4ee0-bcf3-309b2211f3d5"]
            },
            type: {
                network: ["9613ffb0-fb70-48fb-8927-4a936fd98c44", "b91cf82f-aafe-47f4-930a-b2ed1c7b2691", "1027b6e8-9bfd-4dcb-8068-c73f6413cfaf", "a6224773-f6de-4808-b422-dcf9cece1201", "00a5f229-ae7e-4ee0-bcf3-309b2211f3d5", "b91cf82f-aafe-47f4-930a-b2ed1c7b2691", "7adcd7d3-3cd5-4c6d-882d-e5af636cc46f"],
                sensor: ["1ff18981-76ab-4f54-ba5c-eba107e1d6d2", "12cac22f-2fe3-4219-bf56-42fda3505605", "fa72a7b9-a574-4bec-b2a4-fa1eb36fd9a4", "4d090136-a8d5-4ef0-a76a-d4b090dc1ac6"]
            }
        }
        return this.deviceTypeConnectivityMappings;
    }

    getDeviceTypesToShowSetUpWiFiButton() {
        // IHD WiFi Set Up should happen on the IHD itself. If it is a Glow Stick we need to prompt to use the Mobile Set up process.
        const deviceTypesGlowSticks = ["9613ffb0-fb70-48fb-8927-4a936fd98c44", "1027b6e8-9bfd-4dcb-8068-c73f6413cfaf"]
        return deviceTypesGlowSticks;
    }
};

@Injectable()
export class AssetTypeConfigService {

    //This service classifies the resource types as are required for the vatious vetypes
    assetTypeConnectivityMappings: tAssetTypeConnectivityMapping;

    getAssetTypeConnectivityConfigurations() {

        this.assetTypeConnectivityMappings = {
            connectivity: {
                wifi: ["ecf6a7e3-eff8-4e7d-af12-b24f23b7a5c7", "00012fd2-51b7-471e-965f-00e6535c0a37", "764ef28d-6cc5-48b2-8ab9-7a659d7ffb18", "07e1802c-d2af-47b9-bd11-c34e17a6301d"],
                ethernet: ["172b6b4b-aa59-463c-a3b6-117106dd0f36", "60619589-9249-4f04-9480-960faa5f9c18"]
            },
            wiFiPassword: {
                fixed: ["ecf6a7e3-eff8-4e7d-af12-b24f23b7a5c7", "00012fd2-51b7-471e-965f-00e6535c0a37"]
            }
        }
        return this.assetTypeConnectivityMappings;
    }
}

@Injectable({
    providedIn: 'root'
})
export class ResourceTypeConfigService {

    public resourceTypeMapping: tResourceTypeMapping
    public resourceTypeGroupMapping: tResourceTypeGroupMapping

    public resourceClassifierMapping: tResourceTypeMapping
    public resourceClassifierGroupMapping: tResourceTypeGroupMapping

    public resourceClassifierToResourceTypeMap: { [key: string]: string[] }


    constructor(private glowService: GlowService) {

    }
    loadResourceTypeMappings() {
        this.resourceTypeMapping = {
            dataStreamType: {
                "electricity": ["ea02304a-2820-4ea0-8399-f1d1b430c3a0", "b4158501-a678-484a-837a-874194d3bd48",
                    "b5791d9a-6f58-4372-bae4-ae79d1cc0712", "23e9084a-19d4-4849-ab78-bd22753457f5",
                    "9ec2bd19-bfa3-430b-9f08-9550c8cd4bbe", "bb0b2429-7776-44da-a3b4-f87ce3a47dd0",
                    "b3c80261-f9d3-4453-a6e7-5fd02e89fc67", "c58b4039-3cdf-425a-873d-f9e82d8c4009",
                    "b8a8b8ca-ee59-4fc9-97c1-77dfa6bd9b46", "177a0707-154b-4666-be59-2a5eff88a160",
                    "e3a5db34-6e0c-4221-9653-8d33e27511ba", "78859e39-611e-4e84-a402-1d4460abcb56", // DCC
                    "92d6d2fb-26bc-4ada-9698-9f678ecdfdcf", "ba9d973a-ffc8-4f29-98f7-0fe36dbbc136",  // AMR
                    "0bb1fb70-c443-416f-843b-c166dcff0a87", "55326886-dbc3-4840-ae24-8cdbf51a8485", // Secure
                    "4bae327d-787f-43e1-8b2d-07cbc8235c7a", "1bbc8103-cc49-46ff-8fe0-209924697e9c",
                    "5d3a6b5f-78f4-4ade-8f57-a9db10bc2b30", "2f5fc03f-771e-48e9-a6bc-9d49edd8bfae"// DH
                ],
                "gas": ["672b8071-44ff-4f23-bca2-f50c6a3ddd02", "32d9821b-34fd-46d1-9ba1-56259afd4734",
                    "08ab415f-d851-423f-adf4-c2b1e0529e27", "a6b95f41-771d-4bd2-99f4-93ee43c38f5a", // DCC
                    "5bc20a07-f50a-4b7b-931f-836a9e6188e0", "972c95ba-9c99-4461-95fa-9d072eaa20af", // AMR
                    "f6c3da95-f81a-4640-ac20-cfd82b88412e", "5c4bbe04-1fee-4701-9366-cb37285167f5" // Secure
                ],
                cooling: [
                    "54a86b22-1ec0-4404-b968-e136864e4cf2", "c9b94bfe-31d3-48f2-8b2d-5c4386ea45cd",
                    "388527d2-b51b-4aa0-b065-c70bc1b287ad", "67779bc1-a8eb-4934-a3df-097ac4b0548e"
                ],
                heat: ["a16c0dfc-8cb8-4d15-a7bc-59c9d5555eff", "de946467-1e32-4f65-a3c6-bf65b66b3b0c",
                    "dd272457-d812-4bfc-b25e-811a4a7207c6", "9cdc3482-3f9c-4afc-b59d-bcfa098b19b6",
                    "5415d743-ffc5-4659-ae7e-f4c400ded4dc", "8a60dd47-0589-4700-a018-ce52fbf38aab"],
                competitionPoints: ['9d90695b-2173-48af-9e1c-c53a4badc659'],
                water: ['17ccf7b8-d887-4029-bc97-a8a70900a738', 'b9684b04-4b78-4d20-8c6c-e0119e7e0367'],
                "consumption": ["45b6b096-1a23-4584-9a58-9631ea1f3274"],
                "generation": ["9b4ff990-60db-4d6c-9a0c-ab02bdd62fd3"],
                "diversion": ["7f464f1b-14ad-48a0-bc9d-0e464e9d6c36"],
                "import": ["d4181ac4-f182-412e-9106-3b298e0d4c62"],
                "export": ["20dc3d46-f07d-4d61-af52-b0ac148206bf"],
                temperature: ["temperatureResourceTypeId", "5a0ac248-ab63-4bd7-9788-a9fa26b85c1a"],
                relativeHumidity: ["34b43bd1-d724-4c3f-954e-e74b5bbdcc7e"],
                daylight: ["dayightTimeSeriesResourceTypeId"],
                illuminance: ['61af065b-d737-44d2-b30a-f9f1a3bd1b0f']
            },
            analytics: {
                forecast: ["67779bc1-a8eb-4934-a3df-097ac4b0548e", "8a60dd47-0589-4700-a018-ce52fbf38aab", "2f5fc03f-771e-48e9-a6bc-9d49edd8bfae"]
            },
            dimension: {
                energy: [
                    "ea02304a-2820-4ea0-8399-f1d1b430c3a0", "672b8071-44ff-4f23-bca2-f50c6a3ddd02",
                    "b5791d9a-6f58-4372-bae4-ae79d1cc0712", "23e9084a-19d4-4849-ab78-bd22753457f5",
                    "9ec2bd19-bfa3-430b-9f08-9550c8cd4bbe", "bb0b2429-7776-44da-a3b4-f87ce3a47dd0",
                    "a16c0dfc-8cb8-4d15-a7bc-59c9d5555eff", "de946467-1e32-4f65-a3c6-bf65b66b3b0c",
                    '17ccf7b8-d887-4029-bc97-a8a70900a738',
                    "45b6b096-1a23-4584-9a58-9631ea1f3274", "9b4ff990-60db-4d6c-9a0c-ab02bdd62fd3",
                    "7f464f1b-14ad-48a0-bc9d-0e464e9d6c36", "d4181ac4-f182-412e-9106-3b298e0d4c62",
                    "20dc3d46-f07d-4d61-af52-b0ac148206bf", "e3a5db34-6e0c-4221-9653-8d33e27511ba",
                    "92d6d2fb-26bc-4ada-9698-9f678ecdfdcf", "08ab415f-d851-423f-adf4-c2b1e0529e27",
                    "5bc20a07-f50a-4b7b-931f-836a9e6188e0",
                    "0bb1fb70-c443-416f-843b-c166dcff0a87", "f6c3da95-f81a-4640-ac20-cfd82b88412e",
                    "4bae327d-787f-43e1-8b2d-07cbc8235c7a",
                    "54a86b22-1ec0-4404-b968-e136864e4cf2",
                    "8bfe5602-5f35-45f7-b8bb-3c43d7859432"
                ], // Rename energy to USAGE
                cost: [
                    "b4158501-a678-484a-837a-874194d3bd48", "32d9821b-34fd-46d1-9ba1-56259afd4734",
                    "b3c80261-f9d3-4453-a6e7-5fd02e89fc67", "c58b4039-3cdf-425a-873d-f9e82d8c4009",
                    "b8a8b8ca-ee59-4fc9-97c1-77dfa6bd9b46", "177a0707-154b-4666-be59-2a5eff88a160",
                    'b9684b04-4b78-4d20-8c6c-e0119e7e0367', "78859e39-611e-4e84-a402-1d4460abcb56",
                    "ba9d973a-ffc8-4f29-98f7-0fe36dbbc136", "a6b95f41-771d-4bd2-99f4-93ee43c38f5a",
                    "972c95ba-9c99-4461-95fa-9d072eaa20af",
                    "dd272457-d812-4bfc-b25e-811a4a7207c6", "9cdc3482-3f9c-4afc-b59d-bcfa098b19b6",
                    "55326886-dbc3-4840-ae24-8cdbf51a8485", "5c4bbe04-1fee-4701-9366-cb37285167f5",
                    "c9b94bfe-31d3-48f2-8b2d-5c4386ea45cd", "1bbc8103-cc49-46ff-8fe0-209924697e9c"
                ],
                balance: [
                    "5415d743-ffc5-4659-ae7e-f4c400ded4dc", "5d3a6b5f-78f4-4ade-8f57-a9db10bc2b30", "388527d2-b51b-4aa0-b065-c70bc1b287ad"
                ]
            }
        }
        return this.resourceTypeMapping;
    }

    async loadClassifierResourceTypeMap() {
        const resourceTypes = await this.glowService.getResourceTypes().toPromise();

        this.resourceClassifierToResourceTypeMap = resourceTypes.reduce((acc, elem) => {
            if(acc[elem.classifier] ) {
                acc[elem.classifier].push(elem.resourceTypeId)
            } else {
                acc[elem.classifier] =[elem.resourceTypeId]
            }
            return acc
        }, {})
        return this.resourceClassifierToResourceTypeMap
    }

    async getClassifierToResourceTypeMap() {
        if (this.resourceClassifierToResourceTypeMap) {
            return this.resourceClassifierToResourceTypeMap
        } else {
            return this.loadClassifierResourceTypeMap()
        }
    }

    loadResourceClassiffierMappings(): tResourceTypeMapping {
        this.resourceClassifierMapping = {
            dataStreamType: {
                competitionPoints: ['points.competition'],
                electricity: [
                    'electricity.consumption', 'electricity.consumption.cost',
                    'balance.electricity', "electricity.forecast"],
                gas: ['gas.consumption', 'gas.consumption.cost'],
                heat: [
                    'heat.energy', 'heat.consumption', 'heat.consumption.cost',
                    'gas.consumption', 'gas.consumption.cost', 'heat.energy.cost',
                    'balance.heat', "heat.forecast"],
                cooling: ['cool.energy', 'cool.energy.cost', 'balance.cooling', "cooling.forecast"],
                water: ['water.consumption', 'water.consumption.cost'],
                consumption: ['electricity.consumption'],
                generation: ['electricity.generation'],
                diversion: ['electricity.diversion'],
                import: ['electricity.import'],
                export: ["electricity.export"],
                illuminance: ['illuminance'],
                daylight: ["dayight.normalization"],
                temperature: ["temperature"],
                relativeHumidity: ["relative_humidity"],
            }, // Rename energy to USAGE
            analytics: {
                forecast: ["heat.forecast", "cooling.forecast", "electricity.forecast"]
            },
            dimension: {
                energy: ['electricity.consumption', 'gas.consumption', 'heat.consumption', 'heat.energy', 'water.consumption',
                    'electricity.generation', 'electricity.diversion', 'electricity.import', "electricity.export", 'cool.energy'
                ],
                cost: ['electricity.consumption.cost', 'gas.consumption.cost', 'heat.consumption.cost', 'water.consumption.cost', 'heat.energy.cost', 'cool.energy.cost'],
                balance: ['balance.cooling', 'balance.electricity', 'balance.heat']
            }
        };
        return this.resourceClassifierMapping;
    };

    loadResourceTypeGroupMappings(): tResourceTypeGroupMapping {
        this.resourceTypeGroupMapping = {
            summary: {
                "heat": ['fc143f87-221a-4294-9fde-5cb871df6bb9'],
                elecGas: ['2bac239e-32c6-4518-a1b2-6a6e855c0dfa']

            },
            value: {
                "heat": ['00d5ea56-6895-49b2-a6d9-85ad2e1b6547'],
                elecGas: ['e3f4fc6b-3505-4f3a-a989-d5ddb6210aed']
            },
            meterReadCount: {
                "heat": ["f5454a33-46a7-423e-a140-5acd5ab95ebe"],
                elecGas: []
            },
            groups: {
                summary: ["52440667-0e66-485d-bece-abaed22f374f"]
            }
        }
        return this.resourceTypeGroupMapping;
    }

    loadResourceClassifierGroupMappings(): tResourceTypeGroupMapping {
        this.resourceClassifierGroupMapping = {
            summary: {
                "heat": ['group.heat.summary'],
                elecGas: ['group.energy.summary']
            },
            meterReadCount: {
                "heat": ["group.meter-read.count"],
                elecGas: []
            },
            value: {
                "heat": ['group.heat.consumption'],
                elecGas: ['group.energy.consumption']
            },
            groups: {
                summary: ["groups.energy.summary"]
            }
        }
        return this.resourceClassifierGroupMapping;
    }

    public loadResourceTypeRestrictionConfig(): tResourceTypeRestrictionConfig {
        const resourceTypeConfig = {
            // heat
            "a16c0dfc-8cb8-4d15-a7bc-59c9d5555eff": {
                minimumAggPeriod: 'P1D',
                minimumCurrentSummaryValue: 'week',
                defaultDayToDisplay: -1
            },
            "de946467-1e32-4f65-a3c6-bf65b66b3b0c": {
                minimumAggPeriod: 'P1D',
                minimumCurrentSummaryValue: 'week',
                defaultDayToDisplay: -1
            },
            "dd272457-d812-4bfc-b25e-811a4a7207c6": {
                minimumAggPeriod: 'P1D',
                minimumCurrentSummaryValue: 'week',
                defaultDayToDisplay: -1
            },
            "9cdc3482-3f9c-4afc-b59d-bcfa098b19b6": {
                minimumAggPeriod: 'P1D',
                minimumCurrentSummaryValue: 'week',
                defaultDayToDisplay: -1
            },
            "672b8071-44ff-4f23-bca2-f50c6a3ddd02": { // gas
                minimumAggPeriod: 'PT30M',
                minimumCurrentSummaryValue: 'day',
                defaultDayToDisplay: 0
            },
            "32d9821b-34fd-46d1-9ba1-56259afd4734": {
                minimumAggPeriod: 'PT30M',
                minimumCurrentSummaryValue: 'day',
                defaultDayToDisplay: 0
            },
            "17ccf7b8-d887-4029-bc97-a8a70900a738": { // water
                minimumAggPeriod: 'PT30M',
                minimumCurrentSummaryValue: 'day',
                defaultDayToDisplay: 0
            },
            "b9684b04-4b78-4d20-8c6c-e0119e7e0367": {
                minimumAggPeriod: 'PT30M',
                minimumCurrentSummaryValue: 'day',
                defaultDayToDisplay: 0
            },

            // electricity cost
            "b4158501-a678-484a-837a-874194d3bd48": {
                minimumAggPeriod: 'PT30M',
                minimumCurrentSummaryValue: 'day',
                defaultDayToDisplay: 0
            },
            "b3c80261-f9d3-4453-a6e7-5fd02e89fc67": {
                minimumAggPeriod: 'PT30M',
                minimumCurrentSummaryValue: 'day',
                defaultDayToDisplay: 0
            },
            "c58b4039-3cdf-425a-873d-f9e82d8c4009": {
                minimumAggPeriod: 'PT30M',
                minimumCurrentSummaryValue: 'day',
                defaultDayToDisplay: 0
            },
            "b8a8b8ca-ee59-4fc9-97c1-77dfa6bd9b46": {
                minimumAggPeriod: 'PT30M',
                minimumCurrentSummaryValue: 'day',
                defaultDayToDisplay: 0
            },
            "177a0707-154b-4666-be59-2a5eff88a160": {
                minimumAggPeriod: 'PT30M',
                minimumCurrentSummaryValue: 'day',
                defaultDayToDisplay: 0
            },
            // DCC
            "e3a5db34-6e0c-4221-9653-8d33e27511ba": {
                minimumAggPeriod: 'PT30M',
                minimumCurrentSummaryValue: 'day',
                defaultDayToDisplay: -1
            },
            "78859e39-611e-4e84-a402-1d4460abcb56": {
                minimumAggPeriod: 'PT30M',
                minimumCurrentSummaryValue: 'day',
                defaultDayToDisplay: -1
            },
            "08ab415f-d851-423f-adf4-c2b1e0529e27": {
                minimumAggPeriod: 'PT30M',
                minimumCurrentSummaryValue: 'day',
                defaultDayToDisplay: -1
            },
            "a6b95f41-771d-4bd2-99f4-93ee43c38f5a": {
                minimumAggPeriod: 'PT30M',
                minimumCurrentSummaryValue: 'day',
                defaultDayToDisplay: -1
            },
            // AMR
            "92d6d2fb-26bc-4ada-9698-9f678ecdfdcf": {
                minimumAggPeriod: 'PT30M',
                minimumCurrentSummaryValue: 'day',
                defaultDayToDisplay: -1
            },
            "ba9d973a-ffc8-4f29-98f7-0fe36dbbc136": {
                minimumAggPeriod: 'PT30M',
                minimumCurrentSummaryValue: 'day',
                defaultDayToDisplay: -1
            },
            "5bc20a07-f50a-4b7b-931f-836a9e6188e0": {
                minimumAggPeriod: 'PT30M',
                minimumCurrentSummaryValue: 'day',
                defaultDayToDisplay: -1
            },
            "972c95ba-9c99-4461-95fa-9d072eaa20af": {
                minimumAggPeriod: 'PT30M',
                minimumCurrentSummaryValue: 'day',
                defaultDayToDisplay: -1
            },
            // Secure
            "0bb1fb70-c443-416f-843b-c166dcff0a87": {
                minimumAggPeriod: 'PT30M',
                minimumCurrentSummaryValue: 'day',
                defaultDayToDisplay: -1
            },
            "55326886-dbc3-4840-ae24-8cdbf51a8485": {
                minimumAggPeriod: 'PT30M',
                minimumCurrentSummaryValue: 'day',
                defaultDayToDisplay: -1
            },
            "f6c3da95-f81a-4640-ac20-cfd82b88412e": {
                minimumAggPeriod: 'PT30M',
                minimumCurrentSummaryValue: 'day',
                defaultDayToDisplay: -1
            },
            "5c4bbe04-1fee-4701-9366-cb37285167f5": {
                minimumAggPeriod: 'PT30M',
                minimumCurrentSummaryValue: 'day',
                defaultDayToDisplay: -1
            }
        }
        return resourceTypeConfig
    }

}