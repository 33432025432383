import { Injectable } from '@angular/core';
import { GlowService } from './glow.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CaseStudyService {
  constructor(private glowService: GlowService, private http: HttpClient) {}

  async fetchCaseStudyData(caseStudy: any) {
    ;
    let fullCs = { ...caseStudy };
    const before = await this.fetchCaseStudyItems(caseStudy.before);
    const after = await this.fetchCaseStudyItems(caseStudy.after);
    const outcome = await this.fetchCaseStudyItems(caseStudy.outcome);
    fullCs.before = this.convertObject(before);
    fullCs.after = this.convertObject(after);
    fullCs.outcome = this.convertObject(outcome);
    return fullCs;
  }

  async fetchCaseStudyItems(sectionArray: any[]) {
    const section = {};
    if (!(Array.isArray(sectionArray) && sectionArray.length)) return {};
    for (let je of sectionArray) {
      if (!section[je.componentDataType]) {
        section[je.componentDataType] = {};
      }
      let data = {};
      const journalEntry = await this.glowService.getJournalEntry(je.jeId).toPromise();
      journalEntry.data['validFrom'] = journalEntry.validFrom && journalEntry.validFrom.split('T')[0];
      journalEntry.data['validTo'] = journalEntry.validTo && journalEntry.validTo.split('T')[0];
      journalEntry.data['jeId'] = journalEntry.jeId;
      if (journalEntry.data.journalEntryDataType !== 'resource_extract') {
        data[journalEntry.data.journalEntryDataType] = journalEntry.data;
      } else {
        data[journalEntry.data.classifier] = journalEntry.data;
      }
      if (!section[je.componentDataType][je.componentId]) {
        const component = await this.glowService.getComponent(je.componentId, je.componentVersion).toPromise();
        const compData = component.data;
        delete component.data;
        data = { ...data, ...compData, ...component };
        data['installedAt'] = component.installedAt && component.installedAt.split('T')[0];
        data['decommissionedAt'] = component.decommissionedAt && component.decommissionedAt.split('T')[0];
        section[je.componentDataType][je.componentId] = data;
      } else {
        section[je.componentDataType][je.componentId] = { ...section[je.componentDataType][je.componentId], ...data };
      }
    }
    return section;
  }

  fileExists(url: any, baseUrl) {
    const urlObj = new URL(url, baseUrl);
    return this.http
      .head(urlObj.href)
      .toPromise()
      .then(() => true)
      .catch(() => false);
  }

  async getDefaultPropertyImage(formattedCaseStudy) {
    const builtForm = formattedCaseStudy.before.building ? formattedCaseStudy.before.building[0].builtForm.toLowerCase() : '';
    const baseUrl = window.location.href.split('/').slice(0, -1).join('/');
    const url = `../../assets/images/case-study/building/${builtForm}.jpg`;
    const absUrl = new URL(url, baseUrl).href;
    const fileExists = await this.fileExists(absUrl, baseUrl);
    if (!fileExists) {
      return `../../assets/images/case-study/building/detached.jpg`;
    }
    return url;
  }

  async getImage(caseStudy, field, index?) {
    const imageInfo = index >= 0 ? caseStudy[field][index] : caseStudy[field]
    if (!imageInfo) return null;
    const image = await this.glowService.getImage(imageInfo.fileId);
    return image;
  }

  convertObject(inputObject) {
    const convertedObject = {};

    for (const key in inputObject) {
      if (inputObject.hasOwnProperty(key)) {
        const innerObject = inputObject[key];
        convertedObject[key] = Object.values(innerObject);
      }
    }

    return convertedObject;
  }

  //todo: include loans
  calculateNetCosts(totalCost, awardedGrants) {
    if (!totalCost) {
      return null;
    }
    if (!awardedGrants) {
      return totalCost;
    }
    const totalGrantAmount = awardedGrants.reduce((acc, ag) => {
      const accAmountWon = acc.amount || 0;
      const agAmountWon = ag.amount || 0;
      return accAmountWon + agAmountWon;
    }, 0);
    return totalCost - totalGrantAmount;
  }

  calculateNetRunningCosts(componentArray) {
    if (!componentArray) {
      return null;
    }
    const runningCosts = componentArray.reduce((acc, hs) => {
      const accRunningCost = acc['gas.cost'] ? acc['gas.cost'].totalValue : acc['electricity.cost'] ? acc['electricity.cost'].totalValue : 0;
      const hsRunningCost = hs['gas.cost'] ? hs['gas.cost'].totalValue : hs['electricity.cost'] ? hs['electricity.cost'].totalValue : 0;
      return accRunningCost + hsRunningCost;
    }, 0);
    return runningCosts;
  }
}
