import { NgModule } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { SharedModule } from '../shared/shared.module';
import { FaqsLandingComponent } from '../public/faqs-landing/faqs-landing.component';
import { FaqsTopicComponent } from '../public/faq-topic/faqs-topic.component';
import { FaqDetailedComponent } from '../public/faq-detailed/faq-detailed.component';
import { UserStoryDetailedComponent } from '../public/user-story-detailed/user-story-detailed.component';
import { GeneratePasswordTokenComponent } from '../public/generate-password-token/generate-password-token.component';
import { ResetPasswordComponent } from '../public/reset-password/reset-password.component';
import { FaqsSearchbarComponent } from '../components/faqs-searchbar/faqs-searchbar.component';
import { FaqsTopicsComponent } from '../components/faqs-topics/faqs-topics.component';
import { FaqsSubtopicsComponent } from '../components/faqs-subtopics/faqs-subtopics.component';
import { BreadcrumbComponent } from '../components/breadcrumb/breadcrumb.component';
import { FaqFeedbackFormComponent } from '../components/faq-feedback-form/faq-feedback-form.component';
import { FaqQuestionFormComponent } from '../components/faq-question-form/faq-question-form.component';
import { FaqDetailedContentComponent } from '../components/faq-detailed-content/faq-detailed-content.component';
import { SideBarComponent } from '../components/side-bar/side-bar.component';
import { UserStoryDetailedContentComponent } from '../components/user-story-detailed-content/user-story-detailed-content.component';
// import { TagComponent } from '../components/tag/tag.component';
import { BlockquoteComponent } from '../components/blockquote/blockquote.component';
import { SocialMediaButtonsComponent } from '../components/social-media-buttons/social-media-buttons.component';
import { UserStoryCardComponent } from '../components/user-story-card/user-story-card.component';
import { BlogComponent } from './blog/blog.component';
import { BlogContentComponent } from '../components/blog-content/blog-content.component';
import { LandingComponent } from './landing/landing.component';
import { LandingSearchbarComponent } from '../components/landing-searchbar/landing-searchbar.component';
import { LandingCarouselComponent } from '../components/landing-carousel/landing-carousel.component';
import { BlogCardComponent } from '../components/blog-card/blog-card.component';
import { LandingCarouselCardComponent } from '../components/landing-carousel-card/landing-carousel-card.component';
import { ResourcesListComponent } from './resources-list/resources-list.component';
import { ResourcesListContentComponent } from '../components/resources-list-content/resources-list-content.component';
import { ResourcesListCardComponent } from '../components/resources-list-card/resources-list-card.component';
import { ComingSoonCardComponent } from '../components/coming-soon-card/coming-soon-card.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { TutorialContentComponent } from '../components/tutorial-content/tutorial-content.component';
import { NgxScrollspyModule } from '@uniprank/ngx-scrollspy';
import { TutorialCardComponent } from '../components/tutorial-card/tutorial-card.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { ArticleComponent } from './article/article.component';
import { ArticleContentComponent } from '../components/article-content/article-content.component';
import { ArticleCardComponent } from '../components/article-card/article-card.component';
import { GlossaryComponent } from './glossary/glossary.component';
import { GlossaryTermComponent } from '../components/glossary-term/glossary-term.component';
import { GlossarySearchbarComponent } from '../components/glossary-searchbar/glossary-searchbar.component';
import { LandingSidebarComponent } from '../components/landing-sidebar/landing-sidebar.component';
import { CommunityCardComponent } from '../components/community-card/community-card.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { AboutCommunityComponent } from './about-community/about-community.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { ScrollProgressBarComponent } from '../components/scroll-progress-bar/scroll-progress-bar.component';
import { CommunityHomeComponent } from './community-home/community-home.component';
import { CommunityHomepageContentComponent } from '../components/community-homepage-content/community-homepage-content.component';
import { GlossarySidebarComponent } from '../components/glossary-sidebar/glossary-sidebar.component';
import { TopGlossaryTermsComponent } from './top-glossary-terms/top-glossary-terms.component';
import { GlossaryCardComponent } from '../components/glossary-card/glossary-card.component';
import { LandingCommunitiesContentComponent } from '../components/landing-communities-content/landing-communities-content.component';
import { NgbCarouselModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { RelatedFaqsSidebarComponent } from '../components/related-faqs-sidebar/related-faqs-sidebar.component';
import { TagPageComponent } from './tag-page/tag-page.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { RegisterComponent } from './register/register.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { TermsconditionsModalComponent } from '../components/termsconditions-modal/termsconditions-modal.component';
import { DelightEventParticipationComponent } from './delight-event-participation/delight-event-participation.component';
import { BrightComponent } from './bright/bright.component';
import { CookiePolicyComponent } from '../components/cookie-policy/cookie-policy.component';
import { TermsAndConditionsComponent } from '../components/terms-and-conditions/terms-and-conditions.component';
import { FaqsSubtopicNonFaqContentComponent } from '../components/faqs-subtopic-non-faq-content/faqs-subtopic-non-faq-content.component';
import { RightHorizontalHeroComponent } from '../components/landing-community-layouts/right-horizontal-hero/right-horizontal-hero.component';
import { LeftVerticalHeroComponent } from '../components/landing-community-layouts/left-vertical-hero/left-vertical-hero.component';
import { LeftHorizontalHeroComponent } from '../components/landing-community-layouts/left-horizontal-hero/left-horizontal-hero.component';
import { LandingBannerComponent } from '../components/landing-banner/landing-banner.component';
import { InfographicCardComponent } from '../components/infographic-card/infographic-card.component';
import { SystemIncidentsContentComponent } from '../components/system-incidents-content/system-incidents-content.component';
import { SystemIncidentsMailSubscriptionModalComponent } from '../components/system-incidents-mail-subscription-modal/system-incidents-mail-subscription-modal.component';
import { UnsubscribePageComponent } from './unsubscribe-page/unsubscribe-page.component';
import { LoginModalComponent } from '../components/login-modal/login-modal.component';
import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';
// import { CaseStudyComponent } from './case-study/case-study.component';
import { CaseStudyFeedbackComponent } from '../components/case-study/case-study-feedback/case-study-feedback.component';
import { CaseStudiesLandingComponent } from './case-studies-landing/case-studies-landing.component';
import { CaseStudyCardComponent } from '../components/case-study-card/case-study-card.component';
import { AngularSvgIconModule, SvgLoader } from 'angular-svg-icon';
import { ProfessionalsRegisterComponent } from './professionals-register/professionals-register.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ImageModalComponent } from '../components/image-modal/image-modal.component';
import { StarRatingModule } from '../components/star-rating/star-rating.module';
import { TagModule } from '../components/tag/tag.module';
import { ContentCardModule } from '../components/content-card/content-card.module';
import { LandingSearchBarModule } from '../components/landing-searchbar/landing-searchbar.module';
import { InfographicModule } from './infographic/infographic.module';
import { NotFoundPageModule } from './not-found-page/not-found-page.module';
import { HpjInfographicModule } from '../components/infographics/hpj-infographic/hpj-infographic.module';
import { InstallerJourneyInfographicModule } from '../components/infographics/installer-journey-infographic/installer-journey-infographic.module';
import { ProfessionalRegisterStepperModule } from '../components/professional-register-stepper/professional-register-stepper.module';
import { FormValidationMessageModule } from '../components/form-validation-message/form-validation-message.module';
import { ProfessionalRegisterFormModule } from './professional-register-form/professional-register-form.module';
import { InstallerRegisterFormModule } from '../components/installer-register-form/installer-register-form.module';
import { LocalAuthorityRegisterFormModule } from '../components/local-authority-register-form/local-authority-register-form.module';
import { SuccessfulRegisterModule } from './successful-register/successful-register.module';
import { ConfirmationModalModule } from '../components/confirmation-modal/confirmation-modal.module';
import { CaseStudyFilterModule } from '../components/case-study-filter/case-study-filter.module';
import { InstallerPageModule } from './installer-page/installer-page.module';
import { RegisterHomeModule } from './register/register-home/register-home.module';
import { FindAProfessionalModule } from './find-a-professional/find-a-professional.module';

@NgModule({
  declarations: [
    BlockquoteComponent,
    BreadcrumbComponent,
    FaqsLandingComponent,
    FaqsTopicComponent,
    FaqsTopicsComponent,
    FaqsSubtopicsComponent,
    FaqDetailedComponent,
    FaqsSearchbarComponent,
    FaqFeedbackFormComponent,
    FaqQuestionFormComponent,
    FaqDetailedComponent,
    FaqDetailedContentComponent,
    GeneratePasswordTokenComponent,
    LoginComponent,
    ResetPasswordComponent,
    SideBarComponent,
    SocialMediaButtonsComponent,
    // TagComponent,
    UserStoryCardComponent,
    UserStoryDetailedComponent,
    UserStoryDetailedContentComponent,
    // LandingSearchbarComponent,
    LandingCarouselComponent,
    LandingComponent,
    BlogCardComponent,
    BlogComponent,
    BlogContentComponent,
    LandingCarouselCardComponent,
    ResourcesListComponent,
    ResourcesListContentComponent,
    ResourcesListCardComponent,
    ComingSoonCardComponent,
    TutorialComponent,
    TutorialContentComponent,
    TutorialCardComponent,
    ArticleComponent,
    ArticleContentComponent,
    ArticleCardComponent,
    GlossaryComponent,
    GlossaryTermComponent,
    GlossarySearchbarComponent,
    LandingBannerComponent,
    LandingSidebarComponent,
    CommunityCardComponent,
    AboutUsComponent,
    AboutCommunityComponent,
    ScrollProgressBarComponent,
    CommunityHomeComponent,
    CommunityHomepageContentComponent,
    SearchResultsComponent,
    RelatedFaqsSidebarComponent,
    GlossarySidebarComponent,
    TopGlossaryTermsComponent,
    GlossaryCardComponent,
    LandingCommunitiesContentComponent,
    LandingCarouselCardComponent,
    TagPageComponent,
    BrightComponent,
    ContactUsComponent,
    RegisterComponent,
    VerifyEmailComponent,
    TermsconditionsModalComponent,
    DelightEventParticipationComponent,
    CookiePolicyComponent,
    TermsAndConditionsComponent,
    FaqsSubtopicNonFaqContentComponent,
    RightHorizontalHeroComponent,
    LeftVerticalHeroComponent,
    LeftHorizontalHeroComponent,
    InfographicCardComponent,
    SystemIncidentsContentComponent,
    SystemIncidentsMailSubscriptionModalComponent,
    UnsubscribePageComponent,
    LoginModalComponent,
    // ConfirmationModalComponent,
    // CaseStudyComponent,
    CaseStudyFeedbackComponent,
    // CaseStudySummaryComponent,
    // CaseStudyInstallationComponent,
    // CaseStudyPreInstallationComponent,
    // CaseStudyOutcomesComponent,
    // CaseStudyInstallatonHeatingSystemTechnicalComponent,
    // CaseStudyDetailsComponent,
    // CaseStudyOtherContentComponent,
    CaseStudiesLandingComponent,
    CaseStudyCardComponent,
    // StarRatingComponent,
    ProfessionalsRegisterComponent,
    PrivacyPolicyComponent,
    ImageModalComponent,
    // ContentCardComponent,
  ],
  imports: [
    SharedModule,
    NgxScrollspyModule.forRoot(),
    IvyCarouselModule,
    NgbCarouselModule,
    NgbPopoverModule,
    StarRatingModule,
    TagModule,
    LandingSearchBarModule,
    ContentCardModule,
    InfographicModule,
    NotFoundPageModule,
    ProfessionalRegisterStepperModule,
    HpjInfographicModule,
    ConfirmationModalModule,
    InstallerJourneyInfographicModule,
    FormValidationMessageModule,
    InstallerRegisterFormModule,
    ProfessionalRegisterFormModule,
    AngularSvgIconModule.forRoot(),
    LocalAuthorityRegisterFormModule,
    SuccessfulRegisterModule,
    CaseStudyFilterModule,
    InstallerPageModule,
    RegisterHomeModule,
    FindAProfessionalModule
  ],
  exports: [
    BlockquoteComponent,
    BreadcrumbComponent,
    FaqsLandingComponent,
    FaqsTopicComponent,
    FaqsTopicsComponent,
    FaqsSubtopicsComponent,
    FaqDetailedComponent,
    FaqsSearchbarComponent,
    FaqFeedbackFormComponent,
    FaqQuestionFormComponent,
    FaqDetailedComponent,
    FaqDetailedContentComponent,
    GeneratePasswordTokenComponent,
    LoginComponent,
    ResetPasswordComponent,
    SideBarComponent,
    SocialMediaButtonsComponent,
    // TagComponent,
    UserStoryCardComponent,
    UserStoryDetailedComponent,
    UserStoryDetailedContentComponent,
    LandingComponent,
    // LandingSearchbarComponent,
    LandingCarouselComponent,
    BlogCardComponent,
    LandingCarouselCardComponent,
    ResourcesListComponent,
    ResourcesListContentComponent,
    ResourcesListCardComponent,
    ComingSoonCardComponent,
    TutorialComponent,
    TutorialContentComponent,
    TutorialCardComponent,
    ArticleComponent,
    ArticleContentComponent,
    ArticleCardComponent,
    GlossaryComponent,
    GlossaryTermComponent,
    GlossarySearchbarComponent,
    LandingBannerComponent,
    LandingSidebarComponent,
    CommunityCardComponent,
    AboutCommunityComponent,
    AboutUsComponent,
    GlossarySidebarComponent,
    TopGlossaryTermsComponent,
    ScrollProgressBarComponent,
    CommunityHomeComponent,
    CommunityHomepageContentComponent,
    LandingCommunitiesContentComponent,
    LandingCarouselCardComponent,
    SearchResultsComponent,
    RelatedFaqsSidebarComponent,
    GlossaryCardComponent,
    TagPageComponent,
    BrightComponent,
    RegisterComponent,
    VerifyEmailComponent,
    TermsconditionsModalComponent,
    ContactUsComponent,
    CookiePolicyComponent,
    TermsAndConditionsComponent,
    FaqsSubtopicNonFaqContentComponent,
    RightHorizontalHeroComponent,
    LeftVerticalHeroComponent,
    LeftHorizontalHeroComponent,
    InfographicCardComponent,
    SystemIncidentsContentComponent,
    SystemIncidentsMailSubscriptionModalComponent,
    UnsubscribePageComponent,
    LoginModalComponent,
    ConfirmationModalComponent,
    // CaseStudyComponent,
    CaseStudyFeedbackComponent,
    // CaseStudySummaryComponent,
    // CaseStudyInstallationComponent,
    // CaseStudyPreInstallationComponent,
    // CaseStudyOutcomesComponent,
    // CaseStudyOtherContentComponent,
    // CaseStudyInstallatonHeatingSystemTechnicalComponent,
    // CaseStudyDetailsComponent,
    CaseStudiesLandingComponent,
    CaseStudyCardComponent,
    // StarRatingComponent,
    ProfessionalsRegisterComponent,
    PrivacyPolicyComponent,
    ImageModalComponent,
    // FindAProfessionalComponent
    // ContentCardComponent,
  ],
  providers: [FormBuilder],
  entryComponents: [
    TermsconditionsModalComponent,
    SystemIncidentsMailSubscriptionModalComponent,
    LoginModalComponent,
    ConfirmationModalComponent,
    CaseStudyFeedbackComponent,
    ImageModalComponent,
  ],
})
export class PublicPagesModule {}
