import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MetaTagsService } from 'src/app/services/meta-tags.service';
import { StrapiService } from 'src/app/services/strapi.service';
import { Utils } from 'src/utils';
import { Title } from '@angular/platform-browser';
import { SeoService } from 'src/app/services/seo.service';
import { JsonLdService } from 'src/app/services/json-ld.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
})
export class BlogComponent implements OnInit {
  url!: Array<string>;
  blog: any;
  loading:boolean=true
  constructor(
    private route: ActivatedRoute,
    private strapiService: StrapiService,
    private router: Router,
    private seoService: SeoService
  ) {
    route.params.subscribe((val) => {
      this.getUrl();
      this.getBlog(val.blog).then((blog) => {
        this.blog = blog;
        if(blog){
          this.configureSEO(blog);
        }
        this.loading=false
      });
    });
  }

  ngOnInit(): void {}
  ngOnDestroy() {
    this.seoService.resetSEOconfig();
  }

  getUrl() {
    this.url = this.route.snapshot.url.map((segment) => segment.path);
  }

  async getBlog(slug) {
    const blog = await this.strapiService.getContentBySlug('blogs', slug, ['tags', 'authors', 'blogs', 'articles', 'infographics']);
    return blog;
  }

  configureSEO(blog) {
    const title = Utils.removeHTMLFromString(blog.attributes.title);
    const summary = Utils.removeHTMLFromString(blog.attributes.summary);
    const authorNames = blog.attributes.authors.data.map((author) => author.attributes.name);
    const schema = JsonLdService.articleSchema(title, summary, [], authorNames);
    this.seoService.configureSEO(title, summary, `https://glowmarkt.com${this.router.url}`, schema);
  }
}
