import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';
import { LandingSearchBarModule } from '../landing-searchbar/landing-searchbar.module';
import { PublicPagesModule } from 'src/app/public/public.module';

@NgModule({
  declarations: [HeaderComponent],
  imports: [CommonModule, NgbModule, SharedModule, LandingSearchBarModule ],
  exports:[HeaderComponent]
})
export class HeaderModule {}