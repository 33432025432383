import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-heat-loss-parameter-scale',
  templateUrl: './heat-loss-parameter-scale.component.html',
  styleUrls: ['./heat-loss-parameter-scale.component.scss']
})

export class HeatLossParameterScaleComponent implements OnInit {

  @Input()HLP: Number = 0

  constructor() { }

  ngOnInit() { }

}