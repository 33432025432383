import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { QuoteModalComponent } from 'src/app/components/quote-modal/quote-modal.component';
import { tLoadingElement } from 'src/app/services/app-config.typings';
import { CustomUIConfigService } from 'src/app/services/custom-ui-config.service';
import { GlowService } from 'src/app/services/glow.service';
import { InvitationsService } from 'src/app/services/invitations.service';
import { ProfessionalGlowService } from 'src/app/services/professional-glow.service';
import { Utils } from 'src/utils';

/// service for all APIs for professional accounts (service-agent)
@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss', '../../pages-professional.component.scss']
})
export class QuotesComponent implements OnInit {
  pageTitle = 'Manage quotes and estimates';
  pages = ['Sent quotes']
  currentTab = 'Sent quotes';
  property = ''
  blob;
  estimateRequest;
  loading: boolean
  estimateRequestId
  estimateRequestSubscription: Subscription
  estimatesList: Array<any> = []
  loadObj: tLoadingElement
  setSiteVisitForm: FormGroup
  constructor(private modalService: NgbModal, 
    private invitationsService: InvitationsService,
     private glowService: GlowService, 
     private uiConfigService: CustomUIConfigService, 
     private fb: FormBuilder,
     private professionalGlowService: ProfessionalGlowService) {
   this.estimateRequestSubscription = this.invitationsService.EstimateRequestAnnouncer.subscribe(resp=>{
    console.log(resp)
    this.estimateRequest = resp
    this.getEstimate(this.estimateRequest)

   })
   
   
    this.setSiteVisitForm = this.fb.group({
      siteVisitDate: ['', [Validators.required]]
    })

    
  }

  async ngOnInit() {
    this.loadObj = this.uiConfigService.initialiseLoadingBooleans(true);
   this.invitationsService.getEstimateRequestById(this.estimateRequestId)

  }


  getEstimate(estimateRequest) {
    const estimates = []
    if (estimateRequest.estimateIds.length){
    estimateRequest.estimateIds.forEach((estimateId) => {
      this.professionalGlowService.getEstimateProfessional(estimateId.estimateId).subscribe((resp: any) => {
        if (resp.valid) {
          this.loadObj = {
            ...this.loadObj,
            loadError: false,
            loading: false,
          }
          estimates.push(resp)
          this.estimatesList = estimates

        } else {
          this.loadObj = {
            ...this.loadObj,
            loadError: true,
            loading: false,
            errorString: 'An error occurred when retrieving your quotes/ estimates. Please try again later.'
          }
        }
      }, (err) => {
        this.loadObj = {
          ...this.loadObj,
          loadError: true,
          loading: false,
          errorString: 'An error occurred when retrieving your quotes/ estimates. Please try again later.'
        }
      })
    
      // console.log(estimates)
      return estimates
      
    })
  } else (
    this.loadObj = {
      ...this.loadObj,
      loadError: false,
      loading: false,
      errorString: ''
    }
  )

  }

  async getFile(estimate) {
    estimate.veDocIds.forEach((doc) => {
      this.professionalGlowService.getServiceAgentFile(doc.veDocId).then(async resp => {
        this.saveAsBlob(resp)
      })
    })

  }

  saveAsBlob(blob) {
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob);
    link.download = 'file';
    const event = new MouseEvent('click')
    link.dispatchEvent(event)

  }


  showPage(page) {
    this.currentTab = page
  }
  openModal() {
    const modalRef = this.modalService.open(QuoteModalComponent, {
      scrollable: true,
      centered: true,
      size: 'xl',
    });
    modalRef.componentInstance.estimateRequestId = this.estimateRequestId
  }


  formatDate(date) {
    return Utils.getReadableDateFromIso(date)
  }

  setSiteVisitDate() {
    // console.log(this.setSiteVisitForm)
    const body = {
      siteVisitDate: this.setSiteVisitForm.value.siteVisitDate
    }
    this.professionalGlowService.updateEstimateRequestByProfessional(this.estimateRequest.estimateRequestId, body).subscribe(resp => {
      this.invitationsService.getEstimateRequestById(this.estimateRequestId)
      console.log(resp)
    })
  }
}
