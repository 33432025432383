import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormValidationService } from 'src/app/services/form-validation.service';

@Component({
  selector: 'app-add-radiator-modal',
  templateUrl: './add-radiator-modal.component.html',
  styleUrls: ['./add-radiator-modal.component.scss'],
})
export class AddRadiatorModalComponent implements OnInit {
  private room: any = { title: 'New Room' };
  private floor: any;
  radiatorsForm: FormGroup;
  roomForm: FormGroup;
  pipeworkForm: FormGroup;
  formErrorMessage: boolean = false;
  //units: output-watts, room-meters, radiators-sm, underfloor-heating-meters
  //todo: add units to the saved data

  roomOptions = [
    { imgPath: '../../../assets/images/schematics/bedroom.jpeg', title: 'Bedroom', id: 'bedroom' },
    { imgPath: '../../../assets/images/schematics/living-room.jpeg', title: 'Living Room', id: 'livingRoom' },
    { imgPath: '../../../assets/images/schematics/kitchen.jpeg', title: 'Kitchen', id: 'kitchen' },
    { imgPath: '../../../assets/images/schematics/bathroom.jpeg', title: 'Bathroom', id: 'bathroom' },
    { imgPath: '../../../assets/images/schematics/other.jpeg', title: 'Other', id: 'other' },
  ];

  radiatorOptions = [
    {
      id: 'underFloorHeating',
      title: 'Under-floor Heating',
      imgPath: '../../../assets/images/schematics/underFloorHeating.svg',
    },
    {
      id: 'type11',
      title: 'Single Panel Radiator (Type 11)',
      imgPath: '../../../assets/images/schematics/type11.svg',
      radiatorConfigurationConstant: 4.1,
    },
    {
      id: 'type21',
      title: 'Double Panel Plus Radiator (Type 21)',
      imgPath: '../../../assets/images/schematics/type21.svg',
      radiatorConfigurationConstant: 5.8,
    },
    {
      id: 'type22',
      title: 'Double Panel Radiator (Type 22)',
      imgPath: '../../../assets/images/schematics/type22.svg',
      radiatorConfigurationConstant: 7,
    },
    {
      id: 'other',
      title: 'Other',
      examples: ['Towel Rail', 'Electric Radiator', 'Column Radiator'],
      imgPath: '../../../assets/images/schematics/other.svg',
    },
  ];

  flooringOptions = [
    { id: 'wood', title: 'Wood' },
    { id: 'tiles', title: 'Tiles' },
    { id: 'carpet', title: 'Carpet' },
  ];

  pipeworkMaterialOptions = [
    { id: 'copper', title: 'Copper' },
    { id: 'mlcp', title: 'MLCP' },
    { id: 'other', title: 'Other' },
  ];

  customFloorArea = false;
  isInstaller = false;

  constructor(private fb: FormBuilder, private activeModal: NgbActiveModal, private formValidationService: FormValidationService, private auth: AuthenticationService) {}

  ngOnInit() {
    this.isInstaller = this.auth.checkIsRole('installer');
    this.initRadiatorsForm();
    if (this.isInstaller) {
      this.initPipeworkForm(this.room.pipework);
    }
    this.initRoomForm({
      height: this.room.height,
      width: this.room.width,
      length: this.room.length,
      floorArea: this.room.floorArea,
      flooring: this.room.flooring,
      type: this.room.type,
      radiators: this.room.radiators,
      pipework: this.room.pipework,
    });
    if (this.room.floorArea) {
      this.customFloorArea = true;
    }
    this.resetControls();
  }

  onCustomFloorArea() {
    this.customFloorArea = !this.customFloorArea;
    this.resetControls();
  }

  resetControls() {
    if (this.customFloorArea) {
      this.roomForm.get('floorArea').setValidators([Validators.required]);
      this.roomForm.get('floorArea').updateValueAndValidity();
      this.roomForm.get('width').clearValidators();
      this.roomForm.get('width').reset();
      this.roomForm.get('width').updateValueAndValidity();
      this.roomForm.get('length').clearValidators();
      this.roomForm.get('length').reset();
      this.roomForm.get('length').updateValueAndValidity();
    } else {
      this.roomForm.get('floorArea').clearValidators();
      this.roomForm.get('floorArea').reset();
      this.roomForm.get('floorArea').updateValueAndValidity();
      this.roomForm.get('width').setValidators([Validators.required]);
      this.roomForm.get('length').setValidators([Validators.required]);
    }
  }

  initRadiatorsForm() {
    const group: any = {};
    this.radiatorOptions.forEach((radiatorType) => {
      const radiatorTypeId = radiatorType.id;
      const radiators =
        this.room && this.room.radiators
          ? this.room.radiators.filter((radiator) => radiator.type === radiatorType.id).map((rad) => (rad.type == 'underFloorHeating' ? this.createRadiatorGroup(rad.height, rad.length, 1) : this.createRadiatorGroup(rad.height, rad.length, 10)))
          : [];
      group[radiatorTypeId] = this.fb.array(radiators);
    });
    this.radiatorsForm = this.fb.group(group);
  }

  resetErrorMsg() {
    this.formErrorMessage = false;
  }

  checkFormValidity(formControlName, formGroup, translateKey?, type?) {
    if (type && (type == 'rads' || type == 'pipework')) {
      return this.formValidationService.checkFormValidity(formGroup, formControlName, translateKey);
    }
    return this.formValidationService.checkFormValidity(this.roomForm, formControlName, translateKey);
  }

  showErrorMessage() {
    this.formErrorMessage = true;
    this.radiatorsForm.markAllAsTouched();
    this.pipeworkForm.markAllAsTouched();
    this.formValidationService.markFormGroup(this.roomForm, 'touched');
  }

  initRoomForm(
    { height, width, length, floorArea, flooring, type, radiators, pipework } = {
      height: '',
      width: '',
      length: '',
      floorArea: '',
      flooring: '',
      type: '',
      radiators: [],
      pipework: [],
    }
  ) {
    this.roomForm = this.fb.group({
      height: [height, [Validators.required]],
      width: [width],
      length: [length],
      floorArea: [floorArea],
      flooring: [flooring, [Validators.required]],
      type: [type, [Validators.required]],
      radiators: [radiators],
      pipework: [pipework],
    });
  }

  initPipeworkForm(pipework = []) {
    if (!pipework) pipework = [];
    const pipeworkGroups = pipework.map((pipe) => this.createPipeworkGroup(pipe.internalDiameter, pipe.length, pipe.material));
    this.pipeworkForm = this.fb.group({
      pipework: this.fb.array(pipeworkGroups),
    });
  }

  createPipeworkGroup(internalDiameter: number | string = '', length: number | string = '', material: string = '') {
    return this.fb.group({
      internalDiameter: [internalDiameter, [Validators.required, Validators.min(1)]],
      length: [length, [Validators.required, Validators.min(0.1)]],
      material: [material, [Validators.required]],
    });
  }

  addPipework() {
    const pipework = this.pipeworkForm.get('pipework') as FormArray;
    pipework.push(this.createPipeworkGroup());
  }

  removePipework(index: number) {
    const pipework = this.pipeworkForm.get('pipework') as FormArray;
    pipework.removeAt(index);
  }

  createRadiatorGroup(height: number | string = '', length: number | string = '', minValue) {
    return this.fb.group({
      height: [height, [Validators.required, Validators.min(minValue)]],
      length: [length, [Validators.required, Validators.min(minValue)]],
    });
  }

  addRadiator(radiatorId, height: number | string = '', length: number | string = '') {
    const radiators = this.radiatorsForm.get(radiatorId) as FormArray;
    radiatorId === 'underFloorHeating' ? radiators.push(this.createRadiatorGroup(this.room.length, this.room.width, 1)) : radiators.push(this.createRadiatorGroup(height, length, 10));
  }

  removeRadiator(radiatorId, index: number) {
    const radiators = this.radiatorsForm.get(radiatorId) as FormArray;
    radiators.removeAt(index);
    // this.updateRoom();
  }

  displayImage(radiatorType) {
    return radiatorType.imgPath ? { 'background-image': `url(${radiatorType.imgPath})` } : { 'background-image': 'url("../../../assets/images/soft-orange.jpg")' };
  }

  closeModal(msg?: string) {
    if (!this.roomForm.valid || (this.radiatorsForm && !this.radiatorsForm.valid) || (this.pipeworkForm && !this.pipeworkForm.valid)) {
      // console.log(this.radiatorsForm)
      this.showErrorMessage();
      // console.log('closing')
    } else {
      this.updateRoom();
      msg ? this.activeModal.close({ msg, room: this.room }) : this.activeModal.close(this.room);
    }
  }

  calculateRadiatorOutput(radiator, radiatorType) {
    //assuming  temperature difference between the radiator surface and the ambient air is 50oC (inlet water temperature 80oC, outlet water temperature 60oC and ambient air 20oC).
    const radiatorConfigurationConstant = this.radiatorOptions.find((r) => r.id === radiatorType).radiatorConfigurationConstant;
    const radiatorOutput = (((41 * radiatorConfigurationConstant * radiator.length) / 100) * (1 + (8 * radiator.height) / 100)).toFixed(2);
    return radiatorOutput;
  }

  updateRoom() {
    for (let roomProperty in this.roomForm.value) {
      this.room[roomProperty] = this.roomForm.value[roomProperty];
    }
    if (this.pipeworkForm) {
      this.room.pipework = this.pipeworkForm.value.pipework.map((pipe) => {
        return {
          ...pipe,
          units: {
            internalDiameter: 'mm',
            length: 'm',
          },
        };
      });
    }
    this.room.radiators = [];
    for (let radiatorType of this.radiatorOptions) {
      this.room.radiators = [
        ...this.room.radiators,
        ...this.radiatorsForm.value[radiatorType.id].map((radiator, i) => {
          return {
            ...radiator,
            units: {
              width: radiatorType.id === 'underFloorHeating' ? 'm' : 'cm',
              length: radiatorType.id === 'underFloorHeating' ? 'm' : 'cm',
              output: 'W',
            },
            type: radiatorType.id,
            id: `${radiatorType.id}-${i + 1}`,
            title: `${radiatorType.title}`,
            output: this.calculateRadiatorOutput(radiator, radiatorType.id),
          };
        }),
      ];
    }
  }
}
