import { Injectable } from '@angular/core';
import { Subject, Observable, forkJoin } from 'rxjs';

import { ConfigService } from './app-config.service';

import { tSupportedVETypeListing } from './app-config.typings';
import { GlowService } from './glow.service';
import { tVE, tVEwithDetailedResources, tVEwithGroupedChildrenDetailedResources } from './glow.typings';
import { ResourceTypeConfigService } from './configuration.service';
import { tVeApplicationDetails } from './app-data.typings';
import { tResourceTypeMapping } from './configuration.typings';
import { CacheService } from './cache.service';

@Injectable()
export class VirtualEntityService {
  supportedVETypesList: string[];
  supportedVETypesPriorityList: any;

  supportedVETypes: tSupportedVETypeListing[];
  isVeSelected: boolean = false;
  virtualEntity: tVE;
  selectedVeApplicationDetails: tVeApplicationDetails;
  priority: number;
  selectedtVEwithDetailedResources: tVEwithDetailedResources;
  resourceClassifierMapping: tResourceTypeMapping;
  selectedVirtualEntities: tVEwithDetailedResources[];
  sensorVirtualEntities: tVEwithDetailedResources[];

  private SelectedVEAnnouncer = new Subject<tVeApplicationDetails>();
  private SelectedVEsAnnouncer = new Subject<tVEwithDetailedResources[]>();
  private SensorVEsAnnouncer = new Subject<tVEwithDetailedResources[]>();
  private VEsFoundAnnouncer = new Subject<boolean>();
  private virtualEntityErrorAnnouncer = new Subject<any>();

  selectedVeApplicationDetails$ = this.SelectedVEAnnouncer.asObservable();
  isVeFound$ = this.VEsFoundAnnouncer.asObservable();
  virtualEntitiesForDashboard$ = this.SelectedVEsAnnouncer.asObservable();
  sensorVirtualEntities$ = this.SensorVEsAnnouncer.asObservable();
  virtualEntityError$ = this.virtualEntityErrorAnnouncer.asObservable();

  constructor(
    private glowService: GlowService,
    private storage: CacheService,
    private configService: ConfigService,
    private resourceTypeConfiguration: ResourceTypeConfigService
  ) {
    this.isVeSelected = false;
    this.supportedVETypes = this.configService.loadSupportedVETypes();
  }

  findVirtualEntity() {
    this.glowService.getVEs().subscribe(
      (ves) => {
        var virtualEntities = ves;
        // console.log('found list selecting the ves');
        if (virtualEntities && virtualEntities.length > 0) {
          this.selectVirtualEntity(virtualEntities);
        } else {
          // console.log('No virtual entities found');
          this.cleanVirtualEntityCache();
          this.announceVENotFound();
        }
      },
      (error) => {
        // console.log('An error occurred when querying for the VEs');
        console.warn(error);
      }
    );
  }

  findVirtualEntities() {
    this.glowService.getVEs().subscribe(
      (virtualEntities) => {
        if (virtualEntities && virtualEntities.length > 0) {
          // // console.log(`found list selecting the ves, ${virtualEntities.length} ${JSON.stringify(virtualEntities)}`)
          this.selectVirtualEntities(virtualEntities);
        } else {
          // console.log('No virtual entities found');
          this.cleanVirtualEntityCache();
          this.announceSelectedVes([]);
        }
      },
      (error) => {
        // console.log('An error occurred when querying for the VEs');
        console.warn(error);
        this.announceError(error);
      }
    );
  }

  announceError(error: any) {
    this.virtualEntityErrorAnnouncer.next(error);
  }

  announceExistingVE() {
    // console.log('Announce Existing tVE in storage');

    this.storage.get('selectedVeApplicationDetails').then((veDB) => {
      if (veDB) {
        this.SelectedVEAnnouncer.next(veDB);
      } else {
        // console.log('No storage tVE found');
        this.VEsFoundAnnouncer.next(false);
      }
    });
  }

  announceSelectedVE(selectedVeApplicationDetails: tVeApplicationDetails) {
    // console.log('Announce VE that is selected');
    this.SelectedVEAnnouncer.next(selectedVeApplicationDetails);
  }

  announceSelectedVes(selectedVes: tVEwithDetailedResources[]) {
    // console.log('Announce VE list that is selected');
    this.updateVirtualEntityListCache();
    this.SelectedVEsAnnouncer.next(selectedVes);
  }

  announceSensorVes(sensorVes: tVEwithDetailedResources[]) {
    // console.log('Announce sensor VE that is selected');
    this.SensorVEsAnnouncer.next(sensorVes);
  }

  announceVENotFound() {
    // console.log('Announce VE has not been set or found');
    // some process needs to start potential that triggers potential rechecking
    this.VEsFoundAnnouncer.next(false);
  }

  // This function will find all the service ves and add them to the ve list
  selectVirtualEntities(virtualEntities: tVE[]) {
    this.selectedVirtualEntities = [];
    let veListObs: Observable<tVEwithDetailedResources>[] = [];
    this.supportedVETypes.forEach((veTypeSupported) => {
      let vesOfType = virtualEntities.filter((ve) => ve.veTypeId == veTypeSupported.veTypeId);
      vesOfType.forEach((veOfType) => {
        veListObs.push(this.glowService.getResourcesOfVE(veOfType.veId));
      });
    });

    forkJoin(veListObs).subscribe(
      (veWithDetailedResources) => {
        this.selectedVirtualEntities = veWithDetailedResources;
        this.updateVirtualEntityListCache();
        this.announceSelectedVes(this.selectedVirtualEntities);
      },
      (error) => {
        console.warn('An error occurrred when getting parent virtual entities');
        console.warn(error);
        this.VEsFoundAnnouncer.next(false);
      }
    );

    let tempSensorVeListObs: Observable<tVEwithDetailedResources>[] = [];
    const allSupportedSensorTypes = ['56779b69-2fa8-4701-a416-c8d4bfad6f49',"7117e859-b2cd-4c1c-b1da-10e32e9911fe"]

    let tempSensorVes = virtualEntities.filter((ve) => allSupportedSensorTypes.includes(ve.veTypeId));
    tempSensorVes.forEach((ve) => {
      tempSensorVeListObs.push(this.glowService.getResourcesOfVE(ve.veId));
    });

    forkJoin(tempSensorVeListObs).subscribe(
      (sensorVeWithDetailedResources) => {
        this.sensorVirtualEntities = sensorVeWithDetailedResources;
        this.announceSensorVes(this.sensorVirtualEntities);
      },
      (error) => {
        console.warn('An error occurrred when getting sensor virtual entities');
        console.warn(error);
      }
    );
  }

  selectVirtualEntity(virtualEntities: tVE[]) {
    //add some logic if user has multiple suitable ve's
    //set to false when entering this function!!
    this.priority = 100000;
    this.isVeSelected = false;

    this.supportedVETypesList = [];
    this.supportedVETypesPriorityList = {};

    for (let j = 0; j < this.supportedVETypes.length; j++) {
      this.supportedVETypesList.push(this.supportedVETypes[j].veTypeId);
      this.supportedVETypesPriorityList[this.supportedVETypes[j].veTypeId] = this.supportedVETypes[j].priority;
    }

    for (let i = 0; i < virtualEntities.length; i++) {
      if (this.supportedVETypesList.indexOf(virtualEntities[i].veTypeId) > -1) {
        //if priority of vetype is smaller in value than given priority overwrite seleected option -priority 1 is highest
        if (this.supportedVETypesPriorityList[virtualEntities[i].veTypeId] < this.priority) {
          this.isVeSelected = true;
          this.virtualEntity = virtualEntities[i];
          this.priority = this.supportedVETypesPriorityList[virtualEntities[i].veTypeId];
          // console.log('Found ve with greater priority', this.virtualEntity.veTypeId);
        } else if (this.supportedVETypesPriorityList[virtualEntities[i].veTypeId] == this.priority) {
          // console.log('Found ve with equal priority. checking number of resources ' + this.virtualEntity.veTypeId);
          if (virtualEntities[i].resources.length > this.virtualEntity.resources.length) {
            this.virtualEntity = virtualEntities[i];
            this.priority = this.supportedVETypesPriorityList[virtualEntities[i].veTypeId];
            this.isVeSelected = true;
          }
        } else {
          // console.log('Found ve with lower priority, continuing');
        }
      }
    }
    if (this.isVeSelected == true) {
      // console.log(this.virtualEntity);
      this.glowService.getResourcesOfVE(this.virtualEntity.veId).subscribe(
        (detailedResourcesVE) => {
          this.selectedtVEwithDetailedResources = detailedResourcesVE;
          var veApplicationDetails = this.setVeApplicationDetails(this.selectedtVEwithDetailedResources); // This is done to create the object that demonstartes what datastreams are available to the UI components
          this.storage.set('selectedVeApplicationDetails', veApplicationDetails);
          this.announceSelectedVE(veApplicationDetails);
        },
        (error) => {
          // console.log('An error has occurred when getting the resources of the tVE obj');
          console.warn(error);
        }
      );
    } else {
      // console.log("Amongst the users' existing ves, no ve was selected. User may not have appropriate vetype");
      this.VEsFoundAnnouncer.next(false);
    }
  }

  getVeApplicationDetails(): tVeApplicationDetails {
    this.selectedVeApplicationDetails = JSON.parse(localStorage.getItem('selectedVeApplicationDetails'));

    if (!this.selectedVeApplicationDetails) {
      this.selectedVeApplicationDetails = this.initialiseVeApplicationDetails();
    }
    return this.selectedVeApplicationDetails;
  }

  setVeApplicationDetails(selectedVirtualEntityObj: tVEwithDetailedResources): tVeApplicationDetails {
    //TO DO: Adjust logic for elec to use multiphase electricity
    this.selectedVeApplicationDetails = this.initialiseVeApplicationDetails();
    this.resourceClassifierMapping = this.resourceTypeConfiguration.loadResourceClassiffierMappings();

    if (selectedVirtualEntityObj && selectedVirtualEntityObj.hasOwnProperty('resources')) {
      this.selectedVeApplicationDetails.virtualEntity = selectedVirtualEntityObj;

      for (let i = 0; i < selectedVirtualEntityObj.resources.length; i++) {
        const resourceInfo = {
          resourceId: selectedVirtualEntityObj.resources[i].resourceId,
          resourceTypeId: selectedVirtualEntityObj.resources[i].resourceTypeId,
          classifier: selectedVirtualEntityObj.resources[i].classifier,
          name: selectedVirtualEntityObj.resources[i].name,
        };

        if (this.resourceClassifierMapping.dataStreamType.electricity.indexOf(resourceInfo.classifier) > -1) {
          if (this.resourceClassifierMapping.dimension.energy.indexOf(resourceInfo.classifier) > -1) {
            this.selectedVeApplicationDetails.electricity.energy.availability = true;
            this.selectedVeApplicationDetails.electricity.energy = {
              ...this.selectedVeApplicationDetails.electricity.energy,
              ...resourceInfo,
            };
            // this.selectedVeApplicationDetails.electricity.co2.availability = true
            // this.selectedVeApplicationDetails.electricity.co2 = { ...this.selectedVeApplicationDetails.electricity.energy, ...resourceInfo }
          } else if (this.resourceClassifierMapping.dimension.cost.indexOf(resourceInfo.classifier) > -1) {
            this.selectedVeApplicationDetails.electricity.cost.availability = true;
            this.selectedVeApplicationDetails.electricity.cost = {
              ...this.selectedVeApplicationDetails.electricity.cost,
              ...resourceInfo,
            };
          } else {
            console.warn('Unrecognised dimesion for electricity');
            // console.log(resourceInfo);
          }
        }
        if (this.resourceClassifierMapping.dataStreamType.gas.indexOf(resourceInfo.classifier) > -1) {
          if (this.resourceClassifierMapping.dimension.energy.indexOf(resourceInfo.classifier) > -1) {
            this.selectedVeApplicationDetails.gas.energy.availability = true;
            this.selectedVeApplicationDetails.gas.energy = {
              ...this.selectedVeApplicationDetails.gas.energy,
              ...resourceInfo,
            };
            // this.selectedVeApplicationDetails.gas.co2.availability = true
            // this.selectedVeApplicationDetails.gas.co2 = { ...this.selectedVeApplicationDetails.gas.energy, ...resourceInfo }
          } else if (this.resourceClassifierMapping.dimension.cost.indexOf(resourceInfo.classifier) > -1) {
            this.selectedVeApplicationDetails.gas.cost.availability = true;
            this.selectedVeApplicationDetails.gas.cost = {
              ...this.selectedVeApplicationDetails.gas.cost,
              ...resourceInfo,
            };
          } else {
            console.warn('Unrecognised dimesion for gas');
            // console.log(resourceInfo);
          }
        }
      }
    }

    localStorage.setItem('selectedVeApplicationDetails', JSON.stringify(this.selectedVeApplicationDetails));
    return this.selectedVeApplicationDetails;
  }

  cleanVirtualEntityCache() {
    this.storage.remove('selectedVeApplicationDetails');
  }
  // app-ve.service.ts
  // selectedVeApplicationDetails -> ve
  initialiseVeApplicationDetails(): tVeApplicationDetails {
    const selectedVeApplicationDetails = {
      electricity: {
        energy: {
          type: 'electicity.energy',
          availability: false,
          resourceId: null,
          resourceTypeId: null,
          classifier: null,
          name: null,
        },
        cost: {
          type: 'electicity.cost',
          availability: false,
          resourceId: null,
          resourceTypeId: null,
          classifier: null,
          name: null,
        },
        // co2: {
        //   type: "electicity.energy",
        //   availability: false,
        //   resourceId: null,
        //   resourceTypeId: null,
        //   classifier: null,
        //   name: null
        // }
      },
      gas: {
        energy: {
          type: 'gas.energy',
          availability: false,
          resourceId: null,
          resourceTypeId: null,
          classifier: null,
          name: null,
        },
        cost: {
          type: 'gas.cost',
          availability: false,
          resourceId: null,
          resourceTypeId: null,
          classifier: null,
          name: null,
        },
        // co2: {
        //   type: "electicity.energy",
        //   availability: false,
        //   resourceId: null,
        //   resourceTypeId: null,
        //   classifier: null,
        //   name: null
        // }
      },
      virtualEntity: null,
    };
    return selectedVeApplicationDetails;
  }

  getCachedChildVirtualEntity(veId: string) {
    var virtualEntityChild = JSON.parse(localStorage.getItem('ve' + veId));

    if (virtualEntityChild) {
      return virtualEntityChild;
    } else {
      return;
    }
  }

  async getGroupedChildrenVirtualEntityForkJoin(veId: string, type = 'parent'): Promise<any> {
    // console.log('getGroupedChildrenVirtualEntity ' + veId);
    let virtualEntity;

    let veObs = [this.glowService.getGroupedChildrenOfVe(veId), this.glowService.getResourcesOfVE(veId)];
    return forkJoin(veObs).toPromise();
  }

  // async getVirtualEntity(veId: string, proceedWithHttp = false): Promise<tVEwithGroupedChildrenDetailedResources> {
  //     let childVirtualEntity;
  //     let error;

  //     if (proceedWithHttp === false) {
  //         childVirtualEntity = this.getCachedChildVirtualEntity(veId)
  //         if (childVirtualEntity) {
  //             proceedWithHttp = false;
  //         } else {
  //             proceedWithHttp = true;
  //         }
  //     }

  //     if (proceedWithHttp === true) {
  //         await this.getGroupedChildrenVirtualEntityForkJoin(veId, 'child').then((data) => {
  //             let isValid = true;
  //             for (let i = 0; i < data.length; i++) {
  //                 if (data[i] && data[i].veId) {

  //                 } else {
  //                     isValid = false
  //                 }
  //             }
  //             if (isValid === true) {
  //                 childVirtualEntity = { ...data[0], ...data[1] };
  //                 // console.log(childVirtualEntity)

  //             } else {
  //                 console.warn("Unexpected data returned")
  //             }
  //             // console.log("COMPLETE FORKJOIN")

  //         }, (errorMsg) => {
  //             try {
  //                 error = errorMsg.json();
  //             } catch (e) {
  //                 console.warn(e)
  //                 error = errorMsg;
  //             }
  //         })
  //     }
  //     // console.log('getVirtualEntity returns response')
  //     // console.log(childVirtualEntity)
  //     return new Promise((resolve, reject) => {
  //         if (childVirtualEntity) {
  //             resolve(childVirtualEntity)

  //         } else {
  //             childVirtualEntity = {
  //                 veId: veId,
  //                 hasError: true,
  //                 error: error.error
  //             }
  //             resolve(childVirtualEntity)
  //         }
  //     });
  // }

  updateVirtualEntityListCache() {
    // console.log('updateVirtualEntityListCache');
    this.cleanVirtualEntityCache();
    if (Array.isArray(this.selectedVirtualEntities)) {
      this.selectedVirtualEntities.forEach((virtualEntityFullResources) =>
        this.setVirtualEntityToLocalStorage(virtualEntityFullResources)
      );
    }
  }

  getCachedVirtualEntity(veId: string) {
    // console.log('getCachedVirtualEntity veId', veId);
    const virtualEntity = JSON.parse(localStorage.getItem('ve' + veId));
    // console.log('getCachedVirtualEntity virtualEntity', virtualEntity);

    if (virtualEntity) {
      return virtualEntity;
    } else {
      return;
    }
  }

  async getVirtualEntity(veId: string, proceedWithHttp: boolean = false): Promise<tVEwithDetailedResources> {
    // console.log('getVirtualEntity veId', veId);
    let virtualEntity, error;
    if (proceedWithHttp === false) {
      virtualEntity = this.getCachedVirtualEntity(veId);
    }

    if (!virtualEntity) {
      try {
        virtualEntity = await this.glowService.getResourcesOfVE(veId).toPromise();
        this.setVirtualEntityToLocalStorage(virtualEntity);
      } catch (e) {
        console.warn(e);
        try {
          error = e.json();
        } catch (e) {
          console.warn(e);
          error = e;
        }
        throw {
          veId: veId,
          hasError: true,
          error: error,
        };
      }
    }
    // // console.log(virtualEntity)
    return virtualEntity;
  }

  setVirtualEntityToLocalStorage(virtualEntityFullResources: tVEwithDetailedResources) {
    // // console.log('setVirtualEntityToLocalStorage', virtualEntityFullResources);
    if (virtualEntityFullResources && virtualEntityFullResources.veId) {
      localStorage.setItem(`ve${virtualEntityFullResources.veId}`, JSON.stringify(virtualEntityFullResources));
    }
  }

  getVirtualEntityWithFullResources(veId: string) {
    // console.log('getVirtualEntityWithFullResources', veId);
    this.glowService.getResourcesOfVE(veId).subscribe((virtualEntityFullResources) => {
      this.setVirtualEntityToLocalStorage(virtualEntityFullResources);
      // this.announceVirtualEntityWithResources(virtualEntityFullResources)
    });
  }

  setSelectedVirtualEntity(virtualEntity: tVEwithDetailedResources) {
    // console.log('setSelectedVirtualEntity', virtualEntity);
    this.selectedtVEwithDetailedResources = virtualEntity;
  }
}
