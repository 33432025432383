import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, Meta, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PagesModule } from './pages/pages.module';
import { PagesProfessionalModule } from './pages-professional/pages-professional.module';
import { PublicPagesModule } from './public/public.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { GlowService } from './services/glow.service';
import { ConfigService } from './services/app-config.service';
import { HttpClient, HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { GlowModule } from './services/glow.module';
import { CommonModule } from '@angular/common';
import { CustomTranslateLoader } from './services/content.service';
import { AuthGuard } from './services/auth-guard.service';
import { CustomUIConfigService } from './services/custom-ui-config.service';
import { HeaderModule } from './components/header/header.module';
import { ErrorMessageParser } from './services/error-handler.service';
import { PicardService } from './services/picard.service';
import { FooterModule } from './components/footer/footer.module';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { CookieService } from 'ngx-cookie-service';
import { NgbActiveModal, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from './../environments/environment';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { AuthConfig, OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { StorageService } from './services/storage.service';
import { StatehandlerService, StatehandlerServiceImpl } from './services/statehandler.service';
import { StatehandlerProcessorService, StatehandlerProcessorServiceImpl } from './services/statehandler-processor.service';
import { OIDCAuthGuard } from './services/oidc-auth-guard.service';

const authConfig: AuthConfig = {
  scope: 'openid profile email offline_access urn:zitadel:iam:org:project:role:engineer urn:zitadel:iam:org:project:id:zitadel:aud',
  responseType: 'code',
  oidc: true,
  clientId: environment.zitadel.clientId,
  issuer: environment.zitadel.issuer,
  redirectUri: environment.zitadel.redirectUri,
  postLogoutRedirectUri: environment.zitadel.postLogoutRedirectUri,
  requireHttps: environment.zitadel.requireHttps
};

const stateHandlerFn = (stateHandler: StatehandlerService) => {
  return () => {
    return stateHandler.initStateHandler();
  };
};

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookieDomain,
  },
  position: "top",
  palette: {
    popup: {
      background: 'rgba(34,43,69, 0.4)'
    },
    button: {
      background: '#fff'
    }
  },
  type: 'opt-out',
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": '{{htmlContent}}{{compliance}}'
  },
  elements: {
    htmlContent: `
    <div class="cookieContent  m-0 p-3 w-100 ">
    <h3 style="color: #fff">{{header}}</h3>
    <p style="color: #fff">{{message}} {{cookie}}</p>`,
  },
  content: {
    header: "Cookies used on the website!",
    message: "This website uses cookies to drive research and ensure you get the best experience on our website. No data will be used for marketing purposes.",
    cookie: "See our <a aria-label=\"learn more about cookies\" tabindex=\"0\" class=\"cc-link\" href=\"/cookies\">Cookie policy</a> for more information.",
    allow: "Accept all cookies",
    deny: "Refuse cookies"
  }
};
@NgModule({
  declarations: [AppComponent], // UserComponent
  imports: [
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: environment.zitadel.allowedUrls,
        sendAccessToken: true,
      },
    }),
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    GlowModule,
    HeaderModule,
    FooterModule,
    HttpClientModule,
    HttpClientJsonpModule,
    PagesModule,
    PagesProfessionalModule,
    PublicPagesModule,
    IvyCarouselModule,
    NgbCarouselModule,
    NgcCookieConsentModule.forRoot(cookieConfig), TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient, HttpClientModule, GlowService, ConfigService]
      }
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: stateHandlerFn,
      multi: true,
      deps: [StatehandlerService],
    },
    {
      provide: AuthConfig,
      useValue: authConfig,
    },
    {
      provide: StatehandlerProcessorService,
      useClass: StatehandlerProcessorServiceImpl,
    },
    {
      provide: StatehandlerService,
      useClass: StatehandlerServiceImpl,
    },
    {
      provide: OAuthStorage,
      useClass: StorageService,
    },
    AuthGuard,
    OIDCAuthGuard,
    ConfigService,
    ErrorMessageParser,
    GlowService,
    CustomUIConfigService,
    PicardService,
    Meta,
    CookieService,
    Title,
    NgbActiveModal
  ],
  exports: [],
  bootstrap: [AppComponent]
})

export class AppModule { }