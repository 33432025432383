import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FaqsService } from 'src/app/services/faqs.service';
import { Utils } from 'src/utils';
@Component({
  selector: 'app-faqs-subtopics',
  templateUrl: './faqs-subtopics.component.html',
  styleUrls: ['./faqs-subtopics.component.scss'],
})
export class FaqsSubtopicsComponent implements OnInit {
  @Input() topic!: any;
  isSystemIncidents: boolean = false;
  constructor(
    private faqsService: FaqsService,
    private route: ActivatedRoute,
    private router: Router,
    private ref: ChangeDetectorRef
  ) {
    this.route.queryParams.subscribe((params) => {
      this.navigateToSubtopic(params.subtopic);
    });
  }

  ngOnInit(): void {
    this.isSystemIncidents = this.checkIsSystemIncidents();
  }
  ngOnChanges() {
    this.isSystemIncidents = this.checkIsSystemIncidents();
  }

  slugifyFAQ(faq: any) {
    return faq.attributes.question
      .split(' ')
      .map((word: string) => word.toLowerCase())
      .join('-');
  }
  // navigateToFAQ(faq:any){
  //   this.router.navigateByUrl(`/faqs/${this.topic.attributes.slug}/${faq.attributes.slug}`)
  // }

  faqRelativePath(faq: any) {
    return `/support/${this.topic.attributes.slug}/${faq.attributes.slug}`;
  }

  blogRelativePath(blog: any) {
    return `/home/blogs/${blog.attributes.slug}`;
  }

  contentRelativePath() {}

  noFaqs() {
    return !(
      this.topic &&
      this.topic.attributes.faq_sub_topics.data.length > 0 &&
      this.topic.attributes.faq_sub_topics.data.some((subtopic) => subtopic.attributes.faqs.data.length > 0)
    );
  }

  checkIsSystemIncidents() {
    return this.topic.attributes.slug === 'system-incidents';
  }
  navigateToSubtopic(subtopicSlug: string) {
    Utils.waitForElemById(subtopicSlug).then((elm) =>
      elm.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
    );
    // this.router.navigate([], { fragment: subtopicSlug });
  }
}
