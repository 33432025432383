import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MetaTagsService } from 'src/app/services/meta-tags.service';
import { SeoService } from 'src/app/services/seo.service';
import { StrapiService } from 'src/app/services/strapi.service';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent implements OnInit {
  content;

  constructor(private strapiService: StrapiService, private router: Router, private seoService: SeoService) {
    this.getContent().then((content) => {
      this.content = content;
      // console.log(content)
    });
  }

  ngOnInit() {}

  ngOnDestroy(){
    this.seoService.resetSEOconfig()
  }

  async getContent() {
    const content = await this.strapiService.getGlowmarktAbout();
    this.seoService.configureSEO(
      Utils.removeHTMLFromString("About Glowmarkt"),
      Utils.removeHTMLFromString(content.attributes.shortDescription),
      `https://glowmarkt.com${this.router.url}`
    );
    return content;
  }
}
