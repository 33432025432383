import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EstimateRequestCardComponent } from './estimate-request-card.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormValidationMessageModule } from '../form-validation-message/form-validation-message.module';



@NgModule({
  declarations: [EstimateRequestCardComponent],
  imports: [
    CommonModule, SharedModule, FormValidationMessageModule
  ],
  exports: [EstimateRequestCardComponent]
})
export class EstimateRequestCardModule { }
