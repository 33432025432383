import Konva from 'konva';

export class KonvaUtils {
  public static attachIconToNode(node, iconSVGSource, iconW, iconH, xPosition, yPosition, stage) {
    const tr = new Konva.Transformer({
      node: node,
      height: iconH,
      borderEnabled: false,
      anchorStroke: '',
      enabledAnchors: ['top-left', 'top-center', 'top-right', 'middle-right', 'middle-left', 'bottom-left', 'bottom-center', 'bottom-right'],
      resizeEnabled: false,
      rotateEnabled: false,
    });
    const iconGroup = new Konva.Group({
      height: iconH,
      width: iconW,
    });
    let iconObj = new Image();
    iconObj.src = iconSVGSource;
    let icon = new Konva.Image({
      width: iconW,
      height: iconH,
      image: iconObj,
    });
    iconGroup.add(icon);
    tr.add(iconGroup);
    iconGroup.position({ x: xPosition, y: yPosition });
    iconGroup.on('mouseenter', () => {
      stage.container().style.cursor = 'pointer';
    });
    iconGroup.on('mouseleave', () => {
      stage.container().style.cursor = 'default';
    });
    return { iconGroup, tr, icon };
  }

  public static resizeImageHeight(image, newHeight) {
    let ratio = image.height() / image.width();
    image.height(newHeight);
    image.width(newHeight / ratio);
    return image;
  }

  public static virticalAlignCenter(node, y) {
    node.y(y - node.height() / 2);
  }

  public static horizontalAlignCenter(node, x) {
    node.x(x - node.width() / 2);
  }

  public static horizontalAlignRight(node, x) {
    node.x(x - node.width());
  }

  public static horizontalAlignLeft(node, x) {
    node.x(x);
  }

  public static getNumberToDecimalPlace(number, decimalPlace = 1) {
    if (!number) {
      return 0;
    }
    return Math.round(number * Math.pow(10, decimalPlace)) / Math.pow(10, decimalPlace);
  }

  public static generateSolidLine(x1, y1, x2, y2, stroke, strokeWidth = 1) {
    const line = new Konva.Line({
      points: [x1, y1, x2, y2],
      stroke: stroke,
      strokeWidth,
    });
    return line;
  }

  public static breakTextIntoLines(konvaText, maxWidth) {
    let isOutside = konvaText.x()+konvaText.width() > maxWidth;
    if(!isOutside)return konvaText.text();
    let startingPoint = konvaText.x();
    const text = konvaText.text();
    const words = text.split(' ');
    let line = '';
    let lines = [];
    for (let i = 0; i < words.length; i++) {
      const testLine = line + words[i] + ' ';
      const testWidth = konvaText.getTextWidth(testLine);
      isOutside = startingPoint+testWidth >= maxWidth;  
      if (isOutside) {
        lines.push(line);
        konvaText.x(0)
        startingPoint = 0;  
        line = words[i] + ' ';
      } else {
        line = testLine;
      }
    }
    lines.push(line);
    return lines.join('\n');    
  }

  public static generateDashedLine(x1, y1, x2, y2, stroke) {
    const line = new Konva.Line({
      points: [x1, y1, x2, y2],
      stroke: stroke,
      strokeWidth: 1,
      dash: [10, 5],
    });
    return line;
  }

  public static getCenter(node) {
    return {
      x: node.x() + node.width() / 2,
      y: node.y() + node.height() / 2,
    };
  }

  public static getRectangleBorderPoint(radians, size, sideOffset = 0) {
    const width = size.width + sideOffset * 2;

    const height = size.height + sideOffset * 2;

    radians %= 2 * Math.PI;
    if (radians < 0) {
      radians += Math.PI * 2;
    }

    const phi = Math.atan(height / width);

    let x, y;
    if ((radians >= 2 * Math.PI - phi && radians <= 2 * Math.PI) || (radians >= 0 && radians <= phi)) {
      x = width / 2;
      y = Math.tan(radians) * x;
    } else if (radians >= phi && radians <= Math.PI - phi) {
      y = height / 2;
      x = y / Math.tan(radians);
    } else if (radians >= Math.PI - phi && radians <= Math.PI + phi) {
      x = -width / 2;
      y = Math.tan(radians) * x;
    } else if (radians >= Math.PI + phi && radians <= 2 * Math.PI - phi) {
      y = -height / 2;
      x = y / Math.tan(radians);
    }

    return {
      x: -Math.round(x),
      y: Math.round(y),
    };
  }

  public static getConnectorPoints(from, to) {
    const c1 = this.getCenter(from);
    const c2 = this.getCenter(to);

    const dx = c1.x - c2.x;
    const dy = c1.y - c2.y;
    const angle = Math.atan2(-dy, dx);

    const startOffset = this.getRectangleBorderPoint(angle + Math.PI, from.size());
    const endOffset = this.getRectangleBorderPoint(angle, to.size());

    const start = {
      x: c1.x - startOffset.x,
      y: c1.y - startOffset.y,
    };

    const end = {
      x: c2.x - endOffset.x,
      y: c2.y - endOffset.y,
    };

    return [start.x, start.y, end.x, end.y];
  }

  public static getCorners(targets, radWidth, radHeight) {
    let maxY = targets.reduce((prev, curr) => (prev.y > curr.y ? prev : curr), 0).y + radHeight;
    const cornerCoords = [];
    const existingCoords = {};
    const rowInRoom = new Set(targets.map((target) => target.rowInRoom));
    const numRows = rowInRoom.size;
    for (let i = 0; i < numRows; i++) {
      const rowTargets = targets.filter((target) => target.rowInRoom === i);
      const rowMaxY = maxY - i * radHeight;
      const rowMinY = rowMaxY - radHeight;
      const rowMaxX = rowTargets.reduce((prev, curr) => (prev.x > curr.x ? prev : curr), 0).x + radWidth;
      const rowMinX = rowTargets.reduce((prev, curr) => (prev.x < curr.x ? prev : curr), 0).x;
      const corner1Id = `${rowMinX}-${rowMaxY}`;
      const corner2Id = `${rowMaxX}-${rowMaxY}`;
      const corner3Id = `${rowMaxX}-${rowMinY}`;
      const corner4Id = `${rowMinX}-${rowMinY}`;
      if (!existingCoords[corner1Id]) {
        cornerCoords.push([rowMinX, rowMaxY]);
        existingCoords[corner1Id] = true;
      }
      if (!existingCoords[corner2Id]) {
        cornerCoords.push([rowMaxX, rowMaxY]);
        existingCoords[corner2Id] = true;
      }
      if (!existingCoords[corner3Id]) {
        cornerCoords.push([rowMaxX, rowMinY]);
        existingCoords[corner3Id] = true;
      }
      if (!existingCoords[corner4Id]) {
        cornerCoords.push([rowMinX, rowMinY]);
        existingCoords[corner4Id] = true;
      }
    }
    return cornerCoords;
  }

  public static findCentroid(points) {
    const n = points.length;
    let cx = 0,
      cy = 0;

    for (let i = 0; i < n; i++) {
      cx += points[i][0];
      cy += points[i][1];
    }

    return [cx / n, cy / n];
  }

  public static sortCorners(coordinates) {
    const centroid = KonvaUtils.findCentroid(coordinates);

    coordinates.sort((a, b) => {
      const angleA = Math.atan2(a[1] - centroid[1], a[0] - centroid[0]);
      const angleB = Math.atan2(b[1] - centroid[1], b[0] - centroid[0]);

      return angleA - angleB;
    });

    return coordinates;
  }

  public static getEdgeCoordsFromCorners(corners) {
    corners = KonvaUtils.sortCorners(corners); // Access the static member 'sortCorners' of the 'KonvaUtils' class
    const edges = [];

    for (let i = 0; i < corners.length; i++) {
      const startPoint = corners[i];
      const endPoint = corners[(i + 1) % corners.length];

      edges.push([startPoint, endPoint]);
    }

    return edges;
  }
}
