import { Injectable } from '@angular/core';
import { Subject, forkJoin } from 'rxjs';
import { tVETypeConfiguredInfo, tIndexedVirtualEntity } from './app-config.typings';
import { tVE, tVEwithDetailedResources, tVEwithGroupedChildren, tVeAccessPermission, tVEwithGroupedChildrenDetailedResources, tUserOrganization } from './glow.typings';

import { ConfigService } from './app-config.service';
import { GlowService } from './glow.service';;
import { tUserOrganizationWithVes } from './app-data.typings';
import { VirtualEntityService } from './virtual-entity.service'

@Injectable()
export class VirtualEntityGroupService {
    serviceId: string = 'VirtualEntityGroupService';
    groupsTagsObject: any[];
    userOrganizationId: string;
    virtualEntity: tVE;
    selectedVEWithDetailedChildren: tVEwithGroupedChildren;
    userOrganization: tUserOrganization;

    private SelectedVEAnnouncer = new Subject<tVEwithGroupedChildren>();
    private ChildVirtualEntityAnnouncer = new Subject<tVEwithGroupedChildrenDetailedResources>();
    private VirtualEntityDetailedResourcesAnnouncer = new Subject<tVEwithDetailedResources>();
    private VEsFoundAnnouncer = new Subject<boolean>();

    selectedVE$ = this.SelectedVEAnnouncer.asObservable();
    veFound$ = this.VEsFoundAnnouncer.asObservable();
    veDetailedResources$ = this.VirtualEntityDetailedResourcesAnnouncer.asObservable();
    childVE$ = this.ChildVirtualEntityAnnouncer.asObservable();

    constructor(
        private virtualEntityService: VirtualEntityService,
        private configService: ConfigService) {
    };

    async filterGroupVe(userOrganization: tUserOrganization, getFromHttp: boolean = false) {
        // console.log("the current get from http ")
        // console.log(getFromHttp)
        // let userOrgByVes = await this.virtualEntityService.getVesByUserOrganization(userOrganization, getFromHttp)
        // if (userOrgByVes && userOrgByVes.virtualEntities) {
        //     const groupVeTypes = this.configService.loadVeTypeGroupConfigurations()
        //     this.groupsTagsObject = userOrgByVes.virtualEntities.filter((ve) => groupVeTypes.groups.indexOf(ve.veTypeId) > -1)
        //     return this.groupsTagsObject
        // } else {
        //     // console.log("error the list of groups was not found")
        //     return this.groupsTagsObject
        // }
    }

};