import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PostcodeCheckerService {
  postcodecheckURL = 'https://mapserver.glowpro.shop/postcodes/';

  constructor(private http: HttpClient) {}

  // checkPostcode(postcode): any{
  //   return this.http.get(`${this.postcodecheckURL}${postcode}`)
  // }
  checkPostcode(postcode): Promise<any> {
    return this.http.jsonp(`${this.postcodecheckURL}${postcode}`, 'callback').toPromise();
  }
}
