import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { set } from 'core-js/core/dict';
import { LandingService } from 'src/app/services/landing.service';
import { SearchService } from 'src/app/services/search.service';
import { StrapiService } from 'src/app/services/strapi.service';

@Component({
  selector: 'app-landing-searchbar',
  templateUrl: './landing-searchbar.component.html',
  styleUrls: ['./landing-searchbar.component.scss'],
})
export class LandingSearchbarComponent implements OnInit {
  @Output() searchResults = new EventEmitter();
  @Output() searchParameters = new EventEmitter();
  @Input() placeholder: string = 'Search Glowmarkt by tags or keywords...';
  @Input() communityTag: string;
  @Input() searchBarClass: string = '';
  //set isDropdownOpen to null for normal behaviour, set to true fo always open, set to false for always closed
  @Input() isDropdownOpen: boolean = false;
  @Output() state = new EventEmitter<string>();
  @ViewChild('ngSelect', { static: true }) ngSelect: NgSelectComponent;
  tags!: any[];
  results: any[] | boolean = [];
  searchTerm = '';
  tagsToFilter = [];
  selectedTags = [];

  searchParametersForm: FormGroup;
  currentSearchTerm = '';

  community = '';
  constructor(
    private landingService: LandingService,
    private router: Router,
    private searchService: SearchService,
    private strapiService: StrapiService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    // this.searchService.updateSearchSettingsForSearchableContent()
    // this.strapiService.getAllOfContentType('tags').then((tags) => (this.tags = tags));
    this.initSearchParametersForm();
  }

  initSearchParametersForm() {
    this.searchParametersForm = this.fb.group({
      searchParameters: [[], Validators.required],
    });
  }

  processCurrentSearchTerm(event) {
    this.currentSearchTerm = event.items[0];
    if (
      event.items.length == 1 &&
      event.term.toLowerCase().split(' ').join('') === this.currentSearchTerm.toLowerCase().split(' ').join('')
    ) {
      this.setTagsAndSearchTerm([this.currentSearchTerm]);
    } else {
      this.setTagsAndSearchTerm([{ label: event.term }]);
    }
  }

  focusSearchBar() {
    this.ngSelect.focus()
  }

  async searchAllContent() {
    if (this.searchTerm || this.tagsToFilter.length) {
      this.results = await this.searchService.filterAllContentWithTags('landing', this.searchTerm, this.tagsToFilter);
      this.emitSearchResults();
      // if (!this.searchTerm.length) {
      //   this.emitSearchResults();
      // }
    } else {
      this.results = [];
      this.emitSearchResults();
    }
  }

  redirectToSearchResultsPage() {
    this.emitCloseMenu('true');
    // console.log('redirectToSearchResultsPage');
    const tagsAsString =
      this.communityTag && !this.tagsToFilter.includes(this.communityTag)
        ? `${this.communityTag},${this.tagsToFilter.join(',')}`
        : this.tagsToFilter.join(',');
    this.router.navigateByUrl(`search?q[s]=${this.searchTerm}&&q[tags]=${tagsAsString}`);
  }

  setTagsAndSearchTerm(tags: any[], community?: string) {
    const tagsWithoutNull = tags.filter((tag) => tag && (typeof tag === 'string' || tag.label));
    if (tags.length || tagsWithoutNull.length) {
      this.community = community ? community : '';
      this.tagsToFilter = tagsWithoutNull.filter((tag) => typeof tag === 'string');
      this.searchTerm = tagsWithoutNull
        .filter((tag) => typeof tag != 'string')
        .map((tag) => tag.label)
        .join(' ');
    }
    return tagsWithoutNull;
  }

  setSearchParameters(tags: any[], community?: string) {
    const tagsWithoutNull = this.setTagsAndSearchTerm(tags, community);
    if (tags.length || tagsWithoutNull.length) {
      this.searchParametersForm.setValue({ searchParameters: tagsWithoutNull });
    }
    this.emitSearchParameters();
  }

  async setSearchParametersAndSearch(tags: any[], community?: string) {
    this.setSearchParameters(tags, community);
    this.redirectToSearchResultsPage();
  }

  emitSearchResults(results = this.results) {
    this.searchResults.emit({
      results: results,
      searchTerm: this.searchTerm,
      tagsToFilter: this.tagsToFilter,
      communityTag: this.getCommunityFiltered(),
    });
  }

  emitSearchParameters(tags = this.searchParametersForm.value.searchParameters) {
    this.searchParameters.emit({
      tags: tags,
      searchTerm: this.searchTerm,
      tagsToFilter: this.tagsToFilter,
      communityTag: this.getCommunityFiltered(),
    });
  }

  getCommunityFiltered() {
    if (this.tagsToFilter && this.tagsToFilter.length && this.tags && this.tags.length) {
      const communityTags = this.tagsToFilter.filter((tag) => {
        const t = this.tags.filter((ft) => ft.attributes.title == tag)[0];
        return t && t.attributes.isCommunity;
      });
      return communityTags[0];
    }
  }

  // navigateToContent(content) {
  //   const idFragments = content.id.split('-');
  //   this.landingService.setContentToDisplayId(idFragments[idFragments.length - 1]);
  //   idFragments.pop();
  //   this.router.navigateByUrl(`home/${idFragments.join('-')}/${content.title}`);
  // }

  emitCloseMenu(value: string) {
    this.state.emit(value);
  }
}
