import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionsComponent } from './subscriptions.component';
import { SystemIncidentSubscriptionManagementModule } from 'src/app/components/system-incident-subscription-management/system-incident-subscription-management.module';



@NgModule({
  declarations: [SubscriptionsComponent],
  imports: [
    CommonModule, SystemIncidentSubscriptionManagementModule
  ],
  exports: [SubscriptionsComponent]
})
export class SubscriptionsModule { }
