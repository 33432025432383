import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ProfileModalComponent } from 'src/app/components/profile-modal/profile-modal.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlowService } from 'src/app/services/glow.service';
import { ServiceAgentService } from 'src/app/services/service-agent.service';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss', '../../../pages-professional.component.scss']
})

export class PersonalDetailsComponent implements OnInit {

  pageTitle = 'Account information'
  user: any;
  loading: boolean = true
  serviceAgent: any;
  roleSubscription: Subscription
  serviceAgentSubscription: Subscription
  roles

  constructor(private glowService: GlowService, private auth: AuthenticationService, private modalService: NgbModal, private serviceAgentService: ServiceAgentService) {
    this.roleSubscription = this.auth.roleSet.subscribe((resp) => {
      this.roles = resp
    })

    this.serviceAgentSubscription = this.serviceAgentService.serviceAgentAnnouncer.subscribe(resp=>{
      this.serviceAgent = resp
      this.loading = false 
    })
   }
  

  ngOnInit() {
    this.serviceAgent = this.getServiceAgent();
    this.loading = false
    this.roles = this.auth.getOIDCUserRolesFromLocalStorage()
  }
  

  getServiceAgent() {
   this.serviceAgentService.getServiceAgent()
  }
  openModal(formToShow) {
    const modalRef = this.modalService.open(ProfileModalComponent, { scrollable: true, centered: true, size: 'lg', backdrop: 'static' })
    modalRef.componentInstance.serviceAgent = this.serviceAgent
    modalRef.componentInstance[formToShow] = true

  }
}