import { Component, OnInit } from '@angular/core';
import { async } from '@angular/core/testing';
import { Subscription } from 'rxjs';
import { GlowService } from 'src/app/services/glow.service';
import { StrapiService } from 'src/app/services/strapi.service';

@Component({
  selector: 'app-bookmarks',
  templateUrl: './bookmarks.component.html',
  styleUrls: ['./bookmarks.component.scss'],
})
export class BookmarksComponent implements OnInit {
  pageTitle: string = 'Bookmarks';
  bookmarks = [];
  bookmarkedContent = [];
  bookmarkedContentSubsciption: Subscription;
  loading: boolean = true
  constructor(private glowService: GlowService, private strapiService: StrapiService) {
    this.bookmarkedContentSubsciption = this.strapiService.bookmarkedContent$.subscribe(
      async (bookmarks) => {
        this.loading = true;
        this.bookmarks = bookmarks;
        if (this.bookmarks && this.bookmarks.length > 0) {
          this.bookmarkedContent = await this.getContentFromBookmarks(this.bookmarks);
        }
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  ngOnInit() {
    this.loading = true;
    this.strapiService.getBookmarkedContent();
  }

  ngOnDestroy() {
    if (this.bookmarkedContentSubsciption) {
      this.bookmarkedContentSubsciption.unsubscribe();
    }
  }

  async getContentFromBookmarks(bookmarks) {
    this.loading = true;
    let allContent = [];
    for (let bookmark of bookmarks) {
      const content = await this.strapiService.getContentBySlug(bookmark.contentType, bookmark.slug);
      if (content) {
        content.contentType = bookmark.contentType;
        allContent.push(content);
      }
    }
    this.loading = false;
    return allContent;
  }
}
