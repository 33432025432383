import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import mcshp from '../../assets/db/mcshp.json';

const GCN_GAS_PREFIX = '4';
const LOCAL_DATABASE_FILE = '../../assets/db/pcdb10.dat';
const TABLE_IDENTIFIER = '$';
const COMMENT_IDENTIFIER = '#';
const ILLUSTRATIVE_IDENTIFIER = '69';
const MANIFACTURERS = TABLE_IDENTIFIER + '301,207';
const GAS_AND_OIL_BOILERS = TABLE_IDENTIFIER + '105,211';
const SOLID_FUEL_BOILERS = TABLE_IDENTIFIER + '122,225';
const TWIN_BURNER_BOILERS = TABLE_IDENTIFIER + '131,234';
const HEAT_PUMPS = TABLE_IDENTIFIER + '362,465';

@Injectable({
  providedIn: 'root',
})
export class HeatingSystemService {
  constructor(private httpClient: HttpClient) {}

  async getManufacturers() {
    const repo = new PcdbRepository(this.httpClient);
    const manifacturers = await repo.getManufacturers();
    return manifacturers.map((manifacturer) => {
      if (manifacturer.toApiModel) {
        return manifacturer.toApiModel();
      }
      return manifacturer;
    });
  }

  getManufacturersFromArray(hsArray) {
    return hsArray
      .map((hs) => hs.brand||hs.manufacturer)
      .filter((value, index, self) => self.indexOf(value) === index)
      .map((brand) => {
        return { name: brand };
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
  }

  async getHeatPumps(ignoreQualifiers = false) {
    const repo = new PcdbRepository(this.httpClient);
    let heatPumps = await repo.getHeatPumps(HEAT_PUMPS);
    heatPumps = heatPumps.map((heatPump) => {
      if (heatPump.toApiModel) {
        return heatPump.toApiModel();
      }
      return heatPump;
    });
    if (ignoreQualifiers) {
      return heatPumps
        .filter((heatPump, index, self) => {
          return self.findIndex((hp) => hp.model.name === heatPump.model.name) === index;
        })
        .sort((a, b) => {
          if (a.model.name < b.model.name) {
            return -1;
          }
          if (a.model.name > b.model.name) {
            return 1;
          }
          return 0;
        });
    }
    return heatPumps;
  }

  getHeatPumpsFromMcs(ignoreQualifiers = false) {
    if (!mcshp) {
      return [];
    }
    // console.log(mcshp)
    if (!ignoreQualifiers) {
      return mcshp;
    }
    
    return mcshp
      .filter((heatPump, index, self) => {
        return self.findIndex((hp) => hp.model === heatPump.model) === index;
      })
      .sort((a, b) => {
        if (a.model < b.model) {
          return -1;
        }
        if (a.model > b.model) {
          return 1;
        }
        return 0;
      });
  }

  async getBoilers(tableIdentifier, ignoreQualifiers = false) {
    const repo = new PcdbRepository(this.httpClient);
    const boilers = (await repo.getBoilers(tableIdentifier)).map((boiler) => {
      if (boiler.toApiModel) {
        return boiler.toApiModel();
      }
      return boiler;
    });
    if (ignoreQualifiers) {
      return boilers
        .filter((boiler, index, self) => {
          return self.findIndex((b) => b.model.name === boiler.model.name) === index;
        })
        .sort((a, b) => {
          if (a.model.name < b.model.name) {
            return -1;
          }
          if (a.model.name > b.model.name) {
            return 1;
          }
          return 0;
        });
    }
    return boilers;
  }

  async getGasAndOilBoilers(ignoreQualifiers = false, gasOnly = false, oilOnly = false) {
    const boilers = await this.getBoilers(GAS_AND_OIL_BOILERS, ignoreQualifiers);
    if (gasOnly) {
      return boilers.filter(
        (boiler) =>
          (boiler.gasCouncilNumber && boiler.gasCouncilNumber.startsWith(GCN_GAS_PREFIX)) ||
          (boiler.model.qualifier && !boiler.model.qualifier.toLowerCase().includes('oil')) ||
          !boiler.model.qualifier
      );
    }
    if (oilOnly) {
      return boilers.filter(
        (boiler) =>
          (boiler.gasCouncilNumber && !boiler.gasCouncilNumber.startsWith(GCN_GAS_PREFIX)) ||
          (boiler.model.qualifier && boiler.model.qualifier.toLowerCase().includes('oil')) ||
          !boiler.model.qualifier
      );
    }
    return boilers;
  }

  async getSolidFuelBoilers(ignoreQualifiers = false) {
    return this.getBoilers(SOLID_FUEL_BOILERS, ignoreQualifiers);
  }

  async getTwinBurnerBoilers(ignoreQualifiers = false) {
    return this.getBoilers(TWIN_BURNER_BOILERS, ignoreQualifiers);
  }

  async getGasAndOilBoilerManufacturers() {
    const gasAndOilBoilers = await this.getGasAndOilBoilers();
    const manufacturers = this.getManufacturersFromArray(gasAndOilBoilers);
    return manufacturers;
  }

  async getSolidFuelBoilerManufacturers() {
    const solidFuelBoilers = await this.getSolidFuelBoilers();
    const manufacturers = this.getManufacturersFromArray(solidFuelBoilers);
    return manufacturers;
  }

  async getTwinBurnerBoilerManufacturers() {
    const twinBurnerBoilers = await this.getTwinBurnerBoilers();
    const manufacturers = this.getManufacturersFromArray(twinBurnerBoilers);
    return manufacturers;
  }
}

class PcdbRepository {
  cachedBoilers: any;
  constructor(private httpClient: HttpClient) {
    // this.cachedBoilers = JSON.parse(localStorage.getItem('gasAndOilBoilers'));
  }

  open(): any {
    return this.httpClient.get(LOCAL_DATABASE_FILE, { responseType: 'text' }).toPromise();
  }

  // cacheBoilers(boilers) {
  //   localStorage.setItem('gasAndOilBoilers', JSON.stringify(boilers));
  // }

  getManufacturers() {
    return this.getTable(MANIFACTURERS).then((data) => {
      const manifacturers = data.map((row) => new ManufacturerModel(row));
      return manifacturers;
    });
  }

  getTable(tableIdentifier) {
    return this.open().then((data) => {
      let startIndex, endIndex;
      let rows: any = '';
      while ((startIndex = data.indexOf(tableIdentifier, endIndex)) > -1) {
        endIndex = data.indexOf(TABLE_IDENTIFIER, startIndex + 1);
        rows += data.substring(startIndex, endIndex);
      }
      let rawData = [];
      rows = rows.split('\n');
      for (let index = 0; index < rows.length; index++) {
        let row = rows[index];
        if (
          row &&
          !row.startsWith(TABLE_IDENTIFIER) &&
          !row.startsWith(COMMENT_IDENTIFIER) &&
          !row.startsWith(ILLUSTRATIVE_IDENTIFIER)
        ) {
          rawData.push(row);
        }
      }
      return rawData;
    });
  }

  getHeatPumps(heatPumpsIdentifier) {
    return this.getTable(heatPumpsIdentifier).then((data) => {
      return data.map((row) => new HeatPumpModel(row));
    });
  }

  getBoilers(boilersIdentifier) {
    // if (this.cachedBoilers) {
    //   return Promise.resolve(this.cachedBoilers);
    // }
    return this.getTable(boilersIdentifier).then((data) => {
      return data.map((row) => new BoilerModel(row));
    });
  }
}

class HeatPumpModel {
  id: string;
  brand: string;
  model: any;
  name: string;
  manufacturedYears: any;

  constructor(csv) {
    if (!csv) {
      throw new SyntaxError('CSV parameter must be specified');
    }
    this.processCsv(csv);
  }

  processCsv(csv) {
    csv = csv.split(',');

    this.id = csv[0];
    this.brand = csv[5];
    this.model = {
      name: csv[6] || null,
      qualifier: csv[7] || null,
    };

    let name = this.brand.split(' ');
    if (this.model.name) {
      name = name.concat(this.model.name.split(' '));
    }
    if (this.model.qualifier) {
      name = name.concat(this.model.qualifier.split(' '));
    }
    this.name = [...new Set(name)].join(' '); // de-dup name parts

    this.manufacturedYears = {
      range: csv[9] + '-' + csv[10],
      first: parseInt(csv[9]) || null,
      last: parseInt(csv[10]) || null,
    };
  }

  toApiModel() {
    return {
      id: this.id,
      name: this.name,
      brand: this.brand,
      model: this.model,
      manufacturedYears: this.manufacturedYears,
    };
  }
}

class BoilerModel {
  id: string;
  brand: string;
  model: any;
  name: string;
  manufacturedYears: any;
  gasCouncilNumber: string;
  sapEfficiencies: any;
  searchName: string;
  searchNumber: string;

  constructor(csv) {
    if (!csv) {
      throw new SyntaxError('CSV parameter must be specified');
    }
    this.processCsv(csv);
  }

  processCsv(csv) {
    csv = csv.split(',');

    this.id = csv[0];
    this.brand = csv[5];
    this.model = {
      name: csv[6] || null,
      qualifier: csv[7] || null,
    };

    let name = this.brand.split(' ');
    if (this.model.name) {
      name = name.concat(this.model.name.split(' '));
    }
    if (this.model.qualifier) {
      name = name.concat(this.model.qualifier.split(' '));
    }
    this.name = [...new Set(name)].join(' '); // de-dup name parts

    this.manufacturedYears = {
      range: csv[9] + '-' + csv[10],
      first: parseInt(csv[9]) || null,
      last: parseInt(csv[10]) || null,
    };
    this.gasCouncilNumber = this.getGCNumber(csv[8]);
    this.sapEfficiencies = {
      annual: parseFloat(csv[24]) || null,
      winterSeasonal: parseFloat(csv[25]) || null,
      summerSeasonal: parseFloat(csv[26]) || null,
      hotWater: parseFloat(csv[28]) || null,
    };

    this.searchName = this.name.toLowerCase().replace(/[^a-z0-9]/g, '');
    this.searchNumber = (this.gasCouncilNumber || '').replace(/[^0-9]/g, '');
  }

  toApiModel() {
    return {
      id: this.id,
      name: this.name,
      brand: this.brand,
      model: this.model,
      manufacturedYears: this.manufacturedYears,
      gasCouncilNumber: this.gasCouncilNumber,
      sapEfficiencies: this.sapEfficiencies,
    };
  }

  getGCNumber(boilerId) {
    if (boilerId) {
      boilerId = boilerId.replace(/\D/g, '');

      if (boilerId.length == 7 && boilerId.startsWith(GCN_GAS_PREFIX)) {
        // return formatted GC no. with hyphens
        return boilerId.substr(0, 2) + '-' + boilerId.substr(2, 3) + '-' + boilerId.substr(5, 2);
      }
    }

    return null;
  }
}

class ManufacturerModel {
  id: string;
  name: string;
  searchName: string;

  constructor(csv) {
    if (!csv) {
      throw new SyntaxError('CSV parameter must be specified');
    }

    csv = csv.split(',');

    this.id = csv[0];
    this.name = csv[1];

    this.searchName = this.name.toLowerCase().replace(/[^a-z0-9]/g, '');
  }

  toApiModel() {
    return {
      id: this.id,
      name: this.name,
    };
  }
}
