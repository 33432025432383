import { Component, Input, OnInit } from '@angular/core';
import { ScrollSpyService } from '@uniprank/ngx-scrollspy';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ViewportScroller } from '@angular/common';
import { StrapiService } from 'src/app/services/strapi.service';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-tutorial-content',
  templateUrl: './tutorial-content.component.html',
  styleUrls: ['./tutorial-content.component.scss'],
})
export class TutorialContentComponent implements OnInit {
  @Input() tutorial;
  //dummy placeholder data

  content = [];
  timeToRead = 1
  createdAt:string
  displayImageUrl = { 'background-image': 'url("../../../assets/images/soft-orange.jpg")' };

  public activeSection: BehaviorSubject<{ id?: string; elementId?: string; nativeElement?: HTMLElement }> =
    new BehaviorSubject({});

  private _subscription: Subscription;

  constructor(
    private _scrollSpyService: ScrollSpyService,
    private viewportScroller: ViewportScroller,
    private strapiService: StrapiService
  ) {}

  ngOnInit() {
    this.content = this.getSections(this.tutorial);
    this.timeToRead=this.getTimeToRead()
    this.createdAt = this.getCreatedAt(this.tutorial)
    this.displayImageUrl=this.getDisplayImageUrl(this.tutorial)
    // set offset because 2 sticky menu bars width single height of 50px
    this._scrollSpyService.setOffset('window', 100);
    // subscribe to window scroll listener, it is also possible to use an ScrollSpyElement id
    this._subscription = this._scrollSpyService.observe('window').subscribe((item) => {
      if (item != null) {
        const _nextSection = {
          id: item.id,
          elementId: item.scrollElementId,
        };
        this.activeSection.next(_nextSection);
      }
    });
  }

  ngOnDestroy() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  public onClick(elementId: string) {
    document.getElementById(elementId).scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
  }

  getSections(tutorial) {
    if (tutorial.attributes.tutorial_sections && tutorial.attributes.tutorial_sections.data.length) {
      return tutorial.attributes.tutorial_sections.data.sort((s1, s2) => {
        return s1.attributes.stepNumber - s2.attributes.stepNumber;
      });
    }
  }

  getTimeToRead() {
    let totalMin = 1;
    this.content.forEach((c: any) => {
      totalMin = totalMin + Utils.getTimeToRead(c.attributes.content);
    });
    return totalMin;
  }

  getCreatedAt(content) {
    return Utils.formatZDate(content.attributes.createdAt);
  }

  isBookmarked() {
    return this.strapiService.isContentBookmarked(this.tutorial.attributes.slug);
  }

  bookmarkContent() {
    this.strapiService.bookmarkContent(this.tutorial.attributes.slug, 'articles');
  }

  unbookmarkContent() {
    this.strapiService.unbookmarkContent(this.tutorial.attributes.slug);
  }


  getDisplayImageUrl(content) {
    const url = this.strapiService.getSmallDisplayImageUrl(content)
    return { 'background-image': `url("${url}")` };
  }
}
