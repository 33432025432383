import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstallerPageComponent } from './installer-page.component';
import { InstallerJourneyInfographicModule } from 'src/app/components/infographics/installer-journey-infographic/installer-journey-infographic.module';



@NgModule({
  declarations: [InstallerPageComponent],
  imports: [
    CommonModule, InstallerJourneyInfographicModule
  ],
  exports: [InstallerPageComponent]
})
export class InstallerPageModule { }
