import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { StrapiService } from 'src/app/services/strapi.service';

@Component({
  selector: 'app-landing-sidebar',
  templateUrl: './landing-sidebar.component.html',
  styleUrls: ['./landing-sidebar.component.scss'],
})
export class LandingSidebarComponent implements OnInit {
  @Output() selectCommunity = new EventEmitter();
  @Input()communityTag
  communities = [];
  selectedCommunity:any
  constructor(private strapiService: StrapiService) {
  }

  ngOnInit() {
    this.getCommunities().then(communities=> this.communities = communities)
  }

  ngOnChanges(changes:SimpleChanges){
    this.selectedCommunity=this.getCommunityFromTag(changes.communityTag.currentValue)
  }
  // async getCommunityTags() {
  //   const tags = await this.strapiService.getAllOfContentType('tags');
  //   this.communities = tags.filter((tag) => tag.attributes.isCommunity);
  // }

  getCommunityFromTag(tagTitle){
    return this.communities.filter(community=>community.attributes.name==tagTitle)[0]
  }

  async getCommunities(){
    const communities = await this.strapiService.getAllOfContentType("communities")
    return communities
  }

  emitSelectedCommunity(community){
    this.selectedCommunity=community
    this.selectCommunity.emit(community)
  }
}
