import { Component, Input, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { StrapiService } from 'src/app/services/strapi.service';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss'],
})
export class ContentCardComponent implements OnInit {
  @Input() content: any;
  @Input() heroContent: any;
  @Input() isTagBgWhite: boolean;
  @Input() showTag: boolean;
  displayImageUrl;
  constructor(private strapiService: StrapiService) { }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {
    this.displayImageUrl = this.getDisplayImageUrl(this.content);
  }

  contentRelativePath(content: any) {
    return `/home/${content.contentType}/${content.attributes.slug}`;
  }

  getTimeToRead(content) {
    if (content.contentType === 'tutorials') {
      let totalMin = 1;
      content.attributes.tutorial_sections.data.forEach((c: any) => {
        totalMin = totalMin + Utils.getTimeToRead(c.attributes.content);
      });
      return totalMin;
    }
    return content.attributes.content ? Utils.getTimeToRead(content.attributes.content) : 3;
  }

  getDisplayImageUrl(content) {
    return this.strapiService.getSmallDisplayImageUrl(content);
  }
}
