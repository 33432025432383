import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { tLoadingElement } from 'src/app/services/app-config.typings';
import { CustomUIConfigService } from 'src/app/services/custom-ui-config.service';
import { GlowService } from 'src/app/services/glow.service';
import { SearchService } from 'src/app/services/search.service';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';

@Component({
  selector: 'app-heat-pump-cost-estimation-tool',
  templateUrl: './heat-pump-cost-estimation-tool.component.html',
  styleUrls: ['./heat-pump-cost-estimation-tool.component.scss'],
})
export class HeatPumpCostEstimationToolComponent implements OnInit {
  pageTitle = 'Heat Pump Cost Estimation';

  selectedService: any;
  subscriptionSelectedService: Subscription;

  formInputs: any = {};

  parametersConfig = {
    annualGasConsumption: {
      key: 'annualGasConsumption',
      displayName: 'Annual gas consumption',
      unitsPlacement: 'after',
    },
    annualGasCost: {
      key: 'annualGasCost',
      displayName: 'Annual gas cost',
      unitsPlacement: 'before',
    },
    heatPumpCop: { key: 'heatPumpCop', displayName: 'Heat pump COP', unitsPlacement: 'after' },
    unitRateGas: { key: 'unitRateGas', displayName: 'Unit rate gas', unitsPlacement: 'after' },
    standingChargeGas: { key: 'standingChargeGas', displayName: 'Standing charge gas', unitsPlacement: 'after' },
    unitRateElectricity: { key: 'unitRateElectricity', displayName: 'Unit rate electricity', unitsPlacement: 'after' },
    standingChargeElectricity: {
      key: 'standingChargeElectricity',
      displayName: 'Standing charge electricity',
      unitsPlacement: 'after',
    },
  };

  results;
  checkValidity = false;
  parameters;
  loadObj: tLoadingElement;

  page = 'start';
  pageHistory = ['start'];
  heatPumpCopPopover = '';
  innerWidth: number;
  @HostListener('window:resize', []) onResize() {
    this.innerWidth = window.innerWidth;
  }

  constructor(
    private uiConfigService: CustomUIConfigService,
    private serviceSelectorSvc: ServiceSelectorSvc,
    private glowService: GlowService,
    private searchService: SearchService
  ) {
    this.loadObj = this.uiConfigService.initialiseLoadingBooleans(false);
    this.loadObj.loaded = true;

    this.subscriptionSelectedService = this.serviceSelectorSvc.serviceSet.subscribe((selectedService) => {
      this.selectedService = selectedService;
      this.formInputs = {};
      this.results = null;
      this.parameters = null;
      this.updatePage('start');
    });
  }

  ngOnInit() {
    this.onResize();
    this.selectedService = this.serviceSelectorSvc.getSelectedService();
    this.getGlossaryTerm('cop').then((term) => {
      this.heatPumpCopPopover = term.shortDefinition;
    });
  }

  async getGlossaryTerm(term) {
    const results = await this.searchService.filterContentWithTags('glossary-term', term, []);
    return results[0];
  }

  setFormInputs(parameters, checkValidity = false) {
    this.checkValidity = checkValidity;
    for (let field of parameters.fields) {
      let value = parameters.values[field].value || parameters.values[field];
      if (Array.isArray(value)) {
        value = value[0];
        if (value.rate) {
          value = value.rate;
        }
      }
      if (!isNaN(value)) {
        value = parseFloat(value).toFixed(2);
      }
      this.formInputs[field] = value;
    }
  }

  updatePage(page) {
    if (page === 'back') {
      this.pageHistory.pop();
      this.page = this.pageHistory[this.pageHistory.length - 1];
    } else {
      this.page = page;
      if (page === 'start') {
        this.pageHistory = ['start'];
      } else {
        this.pageHistory.push(page);
      }
    }
  }

  processOutput(output) {
    this.results = output.results;
    this.parameters = output.inputs;
    this.setFormInputs(this.parameters);
    this.loadObj.loading = false;
    this.loadObj.loaded = true;
    this.updatePage('results');
  }

  processError(err) {
    // console.log('processError', err);
    if (err.error && err.error.isValid === false && err.error.missingElements) {
      if (err.error.inputs) {
        this.setFormInputs(err.error.inputs, true);
      }
      this.updatePage('missingInfo');
    } else {
      this.updatePage('error');
    }
    this.loadObj.loading = false;
    this.loadObj.loaded = true;
  }

  async estimateAnnualHeatPumpCost(event?) {
    this.loadObj.loading = true;
    let input = {};
    if (event) {
      const {
        annualGasConsumption,
        heatPumpCop,
        unitRateGas,
        standingChargeGas,
        unitRateElectricity,
        standingChargeElectricity,
      } = event;
      input = {
        annualGasConsumption,
        heatPumpCop,
        unitRateGas,
        standingChargeGas,
        unitRateElectricity,
        standingChargeElectricity,
      };
      this.glowService
        .runSimulation({
          simulationTypeId: 'heatPumpCostEstimationFromGasData',
          input,
        })
        .subscribe(
          (resp) => {
            this.processOutput(resp);
          },
          (err) => {
            this.processError(err);
          }
        );
    } else {
      this.glowService
        .runSimulationVeId(
          {
            simulationTypeId: 'heatPumpCostEstimationFromGasData',
            input,
          },
          this.selectedService.veId
        )
        .subscribe(
          (resp) => {
            this.processOutput(resp);
          },
          (err) => {
            this.processError(err);
          }
        );
    }
  }

  changeParams() {
    this.updatePage('paramsForm');
  }
}
