import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CaseStudyFeedbackComponent } from '../case-study-feedback/case-study-feedback.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlowService } from 'src/app/services/glow.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { LoginModalComponent } from '../../login-modal/login-modal.component';
import { CaseStudyService } from 'src/app/services/case-study.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-case-study-header',
  templateUrl: './case-study-header.component.html',
  styleUrls: ['./case-study-header.component.scss'],
})
export class CaseStudyHeaderComponent implements OnInit {
  @Input() caseStudy;
  @Input() caseStudyPhotoUrl;
  @Input() imageOrientation;
  @Input() locationImageUrl;
  @Input() navBackground;
  @Input() categories;
  @Input() category = 'summary';
  @Input() isAccountView: boolean;
  categoryPage;
  categoriesArray;
  hasAccessToken: boolean = false;
  isLoggedIn: boolean = false;
  showMap: boolean = false;
  like: any;
  likesCount: number;
  roles: any = [];
  constructor(private router: Router, private modalService: NgbModal, private glowService: GlowService, private oauthService: OAuthService, private caseStudyService: CaseStudyService, private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.getAuth();
    this.getUserLike();
    this.getLikeCount();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.categories && this.categories) {
      this.categories = Object.values(this.categories);
    }
    if (changes.caseStudy && changes.caseStudy.currentValue) {
      if (this.caseStudy.propertyImage) {
        this.caseStudyService.getImage(this.caseStudy, 'propertyImage').then(async (blob) => {
          // const imgElement = await Utils.waitForElemById(this.caseStudy.propertyImage.fileId);
          // imgElement.setAttribute('src', URL.createObjectURL(blob));
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            this.caseStudyPhotoUrl = reader.result;
          };
        });
      }
      if (this.caseStudy.mediaFiles) {
        if (this.caseStudy.mediaFiles.find(file => file.description == 'map')){
        const index = this.caseStudy.mediaFiles.findIndex(file => file.description == 'map')
          this.caseStudyService.getImage(this.caseStudy, 'mediaFiles', index).then(async (blob) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              this.locationImageUrl = reader.result;
           
            };
          });
        }

        }

      }

    }
  

  isUserPermitted(category) {
    if (!category.restrictedViewPermission) return true;
    for (let permission of category.restrictedViewPermission) {
      if (this.roles.some((role) => role.role === permission)) {
        return true;
      }
    }
    return false;
  }

  shouldDisplayCategory(category) {
    return (
      !(category.restrictedPages.length && category.restrictedPages.includes('account') && this.isAccountView) &&
      (!category.restrictedView || this.isUserPermitted(category) || (category.restrictedView && category.restrictedViewPermission.includes('all') && this.isLoggedIn))
    );
  }

  getAuth() {
    this.isLoggedIn = this.glowService.isLoggedIn();
    this.hasAccessToken = this.oauthService.hasValidAccessToken();
    if (this.hasAccessToken) {
      this.roles = this.authenticationService.getOIDCUserRolesFromLocalStorage();
    }
  }

  openFeedback() {
    const modalRef = this.modalService.open(CaseStudyFeedbackComponent, {
      scrollable: true,
      centered: true,
      size: 'lg',
    });
    modalRef.componentInstance.title = 'Feedback';
    modalRef.componentInstance.caseStudyId = this.caseStudy.csId;
  }

  async getUserLike() {
    if (!this.isLoggedIn) {
      return null;
    }
    this.like = await this.glowService.getCaseStudyLikeForUser(this.caseStudy.csId).toPromise();
  }

  async updateCaseStudyLikes() {
    if (!this.isLoggedIn) {
      const modalRef = this.modalService.open(LoginModalComponent, {
        ariaLabelledBy: 'Login modal',
        backdrop: 'static',
      });
      const result = await modalRef.result;
      if (result === 'success') {
        this.isLoggedIn = true;
      } else {
        return;
      }
    }
    // console.log('like', this.like);

    if (this.like) {
      await this.removeLikeCaseStudy();
    } else {
      await this.likeCaseStudy();
    }
    this.like = this.getUserLike();
  }

  async likeCaseStudy() {
    this.like = await this.glowService.likeCaseStudy(this.caseStudy.csId).toPromise();
    await this.getLikeCount();
  }

  async removeLikeCaseStudy() {
    this.like = await this.glowService.removeLikeCaseStudy(this.caseStudy.csId, this.like.csLikeId).toPromise();
    await this.getLikeCount();
  }

  async getLikeCount() {
    this.likesCount = (await this.glowService.getCaseStudyLikesCount(this.caseStudy.csId).toPromise()).count;
  }

  navigate(id) {
    this.category = id;
    let currentScrollHeight = window.pageYOffset;
    if (this.isAccountView) {
      this.router.navigateByUrl(`pages/property/case-studies/${this.caseStudy.csRef}/${this.category}`).then(() => window.scrollTo({ top: currentScrollHeight }));
    } else {
      this.router.navigateByUrl(`/case-studies/${this.caseStudy.csRef}/${this.category}`).then(() => window.scrollTo({ top: currentScrollHeight }));
    }
  }

  getCategoryPage() {
    const x = this.categories.find((x) => x.id == this.category);
    this.navBackground = x.colour;
    this.categoryPage = x.title;
    return this.categoryPage.slice(0, 1).toUpperCase() + this.categoryPage.slice(1, this.categoryPage.length);
  }

  toggleMap() {
    this.showMap = !this.showMap;
  }
}
