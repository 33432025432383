import { Component, HostListener, Input, OnInit } from '@angular/core';
import { StrapiService } from 'src/app/services/strapi.service';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-sidebar-content-card',
  templateUrl: './sidebar-content-card.component.html',
  styleUrls: ['./sidebar-content-card.component.scss'],
})
export class SidebarContentCardComponent implements OnInit {
  @Input() content;
  @Input() shouldDisplaySummary;
  @Input() shouldDisplayTimeToRead;
  @Input() titleStyle;

  innerWidth;
  displayImageUrl;
  @HostListener('window:resize', []) onResize() {
    // checks size of window to view specific menu
    this.innerWidth = window.innerWidth;
  }
  constructor(private strapiService: StrapiService) {}

  ngOnInit() {
    this.onResize();
  }

  ngAfterContentChecked() {
    this.displayImageUrl = this.getDisplayImageUrl(this.content);
  }

  // navigateTocontent() {
  //   this.router.navigateByUrl(`home/content/${this.content.attributes.slug}`);
  // }

  contentRelativePath() {
    return `/home/${this.content.contentType}/${this.content.attributes.slug}`;
  }

  getTimeToRead() {
    return Utils.getTimeToRead(this.content.attributes.content);
  }
  getUpdatedAt() {
    return Utils.formatZDate(this.content.attributes.updatedAt);
  }

  getCreatedAt() {
    return Utils.formatZDate(this.content.attributes.createdAt);
  }

  isBookmarked() {
    return this.strapiService.isContentBookmarked(this.content.attributes.slug);
  }

  bookmarkContent() {
    this.strapiService.bookmarkContent(this.content.attributes.slug, this.content.contentType);
  }

  unbookmarkContent() {
    this.strapiService.unbookmarkContent(this.content.attributes.slug);
  }

  getDisplayImageUrl(content) {
    return this.strapiService.getThumbnailDisplayImageUrl(content);
  }
}
