import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-glossary-term',
  templateUrl: './glossary-term.component.html',
  styleUrls: ['./glossary-term.component.scss']
})
export class GlossaryTermComponent implements OnInit {
  @Input() glossaryTerm:any
  constructor() { }

  ngOnInit() {
  }

}
