import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GlowService } from 'src/app/services/glow.service';
import { FormValidator } from 'src/app/services/user-validators';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  contactForm: FormGroup
  submitMessage = ''
  reasonOptions = ['Application Reseller', 'Consulting', 'Distributor', 'Media', 'Product Demo', 'Retailer', 'SaaS Reseller', 'Speaking', 'Other']
  successful: boolean = false
  unsuccessful: boolean = false
  date = new Date().toLocaleDateString("en-GB")
  constructor(private fb: FormBuilder, private translate: TranslateService, private glow: GlowService) {
    this.contactForm = this.fb.group({
      email: ['', [Validators.required, FormValidator.isEmailValid]],
      name: ['', [Validators.required]],
      message: ['', [Validators.required]]
    })
  }

  ngOnInit() {

  }

  checkFormValidity(formControlName, translateKey?) {
    let messages = []
    let formFieldName = ''
    translateKey ? formFieldName = (this.translate.instant(translateKey)) : null

    //check if form control has extra validators and user has begun typing

    if (formControlName == 'email') {
      if (this.contactForm.get(formControlName).errors && this.contactForm.get(formControlName).dirty && this.contactForm.get(formControlName).value.length) {
        const errors = this.contactForm.get(formControlName).errors;
        Object.values(errors).forEach((v) => {
          v.errMsg ? (messages = [...messages, v.errMsg]) : null;
        });
        return messages
        //if it has extra validators and is required
      } else if (this.contactForm.get(formControlName).errors && this.contactForm.get(formControlName).touched && !this.contactForm.get(formControlName).valid && !this.contactForm.get(formControlName).value.length && this.contactForm.get(formControlName).errors.required) {
        const errorMsg = `${formFieldName} is required.`
        messages = [errorMsg]
        return messages
      }
    }
    //no extra validators but required
    else if (this.contactForm.get(formControlName).touched && !this.contactForm.get(formControlName).valid && this.contactForm.get(formControlName).errors.required) {
      const errorMsg = `${formFieldName} is required.`
      messages = [errorMsg]
      return messages

    }

    return
  }

  markFormGroupTouched(formGroup: FormGroup,) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();

    });
  }

  submit() {
    if (this.contactForm.valid) {
      // // console.log(this.contactForm)
      let body = {
        data: {
          description: this.contactForm.get('message').value,
          subject: `New Glowmarkt Message on ${this.date}`,
          type: 'contactUsGlowmarkt'
        },
        user: {
          name: this.contactForm.get('name').value,
          username: this.contactForm.get('email').value
        }
      }
      // console.log(body)
      this.glow.postTicketPublic(body).subscribe((response) => {
        // console.log(response);
        if (response.valid) {
          this.submitMessage ='Message sent successfully' ;
          this.successful = true
          this.contactForm.reset()
        } else {
        this.submitMessage = 'There was an error sending your message. Please try again later.';
        this.unsuccessful = true
        }
      });
    } else {
      this.markFormGroupTouched(this.contactForm)
    }
  }
}
