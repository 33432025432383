import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SchematicsService } from 'src/app/services/schematics.service';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';

@Component({
  selector: 'app-schematics-homepage-status',
  templateUrl: './schematics-homepage-status.component.html',
  styleUrls: ['./schematics-homepage-status.component.scss'],
})
export class SchematicsHomepageStatusComponent implements OnInit {
  schematicDocuments: any;
  schematicDocumentsDisplay: any;
  schematicDocumentsSubscripion: Subscription;
  subscriptionSelectedService: Subscription;
  statusToFilterBy = 'in-progress';
  //ping observable
  ping: EventEmitter<any> = new EventEmitter();
  shouldPing: boolean = false;
  statusToPing: string;

  constructor(private schematicsService: SchematicsService, private serviceSelectorSvc: ServiceSelectorSvc, private router: Router, private route: ActivatedRoute) {
    route.params.subscribe(async (params) => {
      this.statusToFilterBy = params['status'];
      this.schematicDocumentsDisplay = await this.schematicsService.getSchematicsByStatus(this.statusToFilterBy);
      this.setPing();
    });

    this.subscriptionSelectedService = this.serviceSelectorSvc.serviceSet.subscribe(async (selectedService) => {
      this.schematicsService.setSelectedService(selectedService);
      this.schematicDocumentsDisplay = await this.schematicsService.getSchematicsByStatus(this.statusToFilterBy);
    });
  }

  ngOnInit() {
    const selectedService = this.serviceSelectorSvc.getSelectedService();
    this.serviceSelectorSvc.announceSelectedService(selectedService);
  }

  setPing() {
    this.shouldPing = !(this.schematicDocumentsDisplay && this.schematicDocumentsDisplay.length > 0);
    if (this.shouldPing) {
      this.statusToPing = 'new';
    }
    this.ping.emit({ shouldPing: this.shouldPing, statusToPing: this.statusToPing });
  }


}
