import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonalDetailsComponent } from './personal-details.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SpinnerModule } from 'src/app/components/spinner/spinner.module';
import { ProfileModalModule } from 'src/app/components/profile-modal/profile-modal.module';



@NgModule({
  declarations: [PersonalDetailsComponent],
  imports: [
    CommonModule, SharedModule, SpinnerModule, ProfileModalModule
  ],
  exports: [PersonalDetailsComponent]
})
export class PersonalDetailsModule { }
