import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnergyOverviewComponent } from './energy-overview.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [EnergyOverviewComponent],
  imports: [
    SharedModule, 
    CommonModule
  ],
  exports: [EnergyOverviewComponent]
})

export class EnergyOverviewModule { }