import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { tLoadingElementWithSuccess } from 'src/app/services/app-config.typings';
import { CustomUIConfigService } from 'src/app/services/custom-ui-config.service';
import { GlowService } from 'src/app/services/glow.service';
import { FormValidator } from 'src/app/services/user-validators';

@Component({
  selector: 'app-generate-password-token',
  templateUrl: './generate-password-token.component.html',
  styleUrls: ['./generate-password-token.component.scss'],
})
export class GeneratePasswordTokenComponent {
  formInfo: FormGroup;
  loadObj: tLoadingElementWithSuccess;

  constructor(
    private formBuilder: FormBuilder,
    private authService: GlowService,
    private router: Router,
    private uiConfigService: CustomUIConfigService
  ) {
    this.loadObj = this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false);
    this.formInfo = this.formBuilder.group({
      username: ['', [Validators.required, FormValidator.isUsernameValid]],
    });
  }

  // Attempt to login in through our User service
  submitForm() {
    if (this.formInfo.valid) {
      this.loadObj = this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(true);

      this.authService.triggerForgottenPasswordProcess(this.formInfo.value).subscribe(
        (generateRestPasswordToken) => {
          this.loadObj.loading = false;

          if (generateRestPasswordToken.valid === true) {
            this.loadObj.loaded = true;
            this.loadObj.loadedMessage = 'Success!';
            this.router.navigateByUrl('/reset-password');
          } else {
            this.loadObj.loadError = true;
            this.loadObj.errorString = 'An error has occurred';
          }
        },
        (err) => {
          console.warn(err);
          let errMsg = 'An error has occurred';
          try {
            const errJson = err.json();
            if (errJson.error) {
              errMsg = errJson.error;
            } else if (errJson.valid === false) {
              errMsg = 'Invalid information';
            }
          } catch (e) {
            console.warn(e);
          }
          this.loadObj.loading = false;
          this.loadObj.loadError = true;
          this.loadObj.errorString = errMsg;
        }
      );
    }else{
      this.loadObj.loadError = true;
      this.loadObj.errorString = 'Incorrect email address';
    }
  }
}
