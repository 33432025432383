import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtcCardComponent } from './htc-card.component';
import { HeatLossParameterScaleModule } from '../heat-loss-parameter-scale/heat-loss-parameter-scale.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [HtcCardComponent],
  imports: [
    CommonModule,
    TranslateModule,
    HeatLossParameterScaleModule
  ],
  exports: [HtcCardComponent]
})

export class HtcCardModule { }