import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';

@Component({
  selector: 'app-energy-data',
  templateUrl: './energy-data.component.html',
  styleUrls: ['./energy-data.component.scss', '../pages.component.scss'],
})
export class EnergyDataComponent implements OnInit {
  showGas: boolean = false;
  pageTitle = 'Energy data';
  consumptionType: string = 'electricity.consumption';
  innerWidth;
  pages = [{ param: 'electricity.consumption', title: 'Electricity', active: true }];
  selectedService: any;
  @HostListener('window:resize', []) onResize() {
    this.innerWidth = window.innerWidth;
  }
  constructor(private serviceSelectorSvc: ServiceSelectorSvc) {}

  ngOnInit() {
    this.onResize();
    this.selectedService = this.serviceSelectorSvc.getSelectedService();
    if (this.selectedService) {
      this.showGas = this.selectedService.resources.find((x) => x.classifier.includes('gas'));
      if (this.showGas) {
        this.pages.push({ param: 'gas.consumption', title: 'Gas', active: false });
      }
    }
  }

  toggleFuelType(param) {
    this.consumptionType = param;
    this.pages.map((x) => (x.active = false));
    const match = this.pages.find((x) => x.param == param);
    match.active = true;
  }
}
