import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemperatureSensorsComponent } from './temperature-sensors.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TemperatureSensorDataTableModule } from 'src/app/components/temperature-sensor-data-table/temperature-sensor-data-table.module';
import { AccountNoDataCardModule } from 'src/app/components/account-no-data-card/account-no-data-card.module';



@NgModule({
  declarations: [TemperatureSensorsComponent],
  imports: [SharedModule, TemperatureSensorDataTableModule, CommonModule, AccountNoDataCardModule
  ]
})
export class TemperatureSensorsModule { }
