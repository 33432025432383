import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SeoService } from 'src/app/services/seo.service';
import { StrapiService } from 'src/app/services/strapi.service';
import _ from 'underscore';

@Component({
  selector: 'app-tag-page',
  templateUrl: './tag-page.component.html',
  styleUrls: ['./tag-page.component.scss'],
})
export class TagPageComponent implements OnInit {
  tag: any;
  allContent: any[] = [];
  tagSlug: string;
  sortType: string;
  featuredContent: any = [];
  loading: boolean = true;

  constructor(private strapiService: StrapiService, private route: ActivatedRoute, private seoService: SeoService, private ref: ChangeDetectorRef) {
    route.params.subscribe((params) => {
      this.tagSlug = params.tag;
      this.getTag(this.tagSlug).then((tag) => {
        if (tag) {
          this.tag = tag;
          this.configureSEO(tag);
          this.sortType = params['q[sort]'];
          this.allContent = this.getSortedContent(this.tag, this.sortType);
        }
        this.loading = false;
      });
    });

    route.queryParams.subscribe((params) => {
      this.getTag(this.tagSlug).then((tag) => {
        if (tag) {
          this.tag = tag;
          this.configureSEO(tag);
          this.sortType = params['q[sort]'];
          this.allContent = this.getSortedContent(this.tag, this.sortType);
        }
        this.loading = false;
      });
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.seoService.resetSEOconfig();
  }

  configureSEO(tag) {
    this.seoService.configureSEO(tag.attributes.title, `Content tagged with ${tag.attributes.title}`, `https://glowmarkt.com/tag/${tag.attributes.slug}`);
  }

  sortHREF(sortType: 'latest' | 'oldest') {
    switch (sortType) {
      case 'latest':
        return `/tag/${this.tag.attributes.slug}?q[sort]=latest`;
      case 'oldest':
        return `/tag/${this.tag.attributes.slug}?q[sort]=oldest`;
      default:
        return `/tag/${this.tag.attributes.slug}`;
    }
  }

  async getTag(slug) {
    const tag = await this.strapiService.getContentBySlug('tags', slug, ['blogs', 'articles']);
    return tag;
  }

  getSortedContent(tag, sortType) {
    const blogs = tag.attributes.blogs.data.map((blog: any) => Object.assign(blog, { contentType: 'blogs' }));
    const articles = tag.attributes.articles.data.map((article: any) => Object.assign(article, { contentType: 'articles' }));
    let sorted = [];
    switch (sortType) {
      case 'oldest':
        sorted = [...blogs, ...articles].sort((a: any, b: any) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf());
        break;
      default:
        sorted = [...blogs, ...articles].sort((a: any, b: any) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()).reverse();
        break;
    }
    // this.featuredContent = this.getFeatured(sorted);
    return sorted;
  }

  getFeatured(contentArray) {
    return contentArray.filter((content) => content.attributes.tags.data.some((tag) => tag.attributes.slug === 'featured'));
  }

  getShuffledContent(tag) {
    const blogs = tag.attributes.blogs.data.map((blog: any) => Object.assign(blog, { contentType: 'blogs' }));
    const articles = tag.attributes.articles.data.map((article: any) => Object.assign(article, { contentType: 'articles' }));
    return _.shuffle([...blogs, ...articles]);
  }
}
