import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SchematicsService } from 'src/app/services/schematics.service';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';
import { VirtualEntityService } from 'src/app/services/virtual-entity.service';

@Component({
  selector: 'app-tools-pages',
  templateUrl: './tools-pages.component.html',
  styleUrls: ['./tools-pages.component.scss'],
})
export class ToolsPagesComponent implements OnInit {
  titlePage: string = 'Glowmarkt Tools';
  currentPage: string = '';
  dropdownOptions: any[] = [];
  formInfo: FormGroup;
  loading: boolean = true;
  selectedService: any;
  virtualEntities: any[];
  subscriptionSelectedService: Subscription;
  subscriptionVirtualEntityList: Subscription;
  virtualEntityErrorSubscription: Subscription;

  errorMessage: string;
  error;

  constructor(private serviceSelectorSvc: ServiceSelectorSvc, private formBuilder: FormBuilder, private veService: VirtualEntityService, private schematicsService: SchematicsService) {
    this.formInfo = this.formBuilder.group({
      selectedService: ['', Validators.required],
    });
    this.subscriptionSelectedService = this.serviceSelectorSvc.serviceSet.subscribe((selectedService) => {
      this.selectedService = selectedService;
    });

    this.virtualEntityErrorSubscription = this.veService.virtualEntityError$.subscribe((error) => {
      this.loading = false;
      if (error) {
        this.errorMessage = "There was an error loading your information. Please try again later. If the problem persists, please contact support at <a class='text-info' href='mailto:support@glowmarkt.com'>support@glowmarkt.com</a>";
        this.error = error;
      }
    });

    this.subscriptionVirtualEntityList = this.veService.virtualEntitiesForDashboard$.subscribe(async (veList) => {
      this.loading = false;

      if (veList && Array.isArray(veList) && veList.length > 0) {
        this.virtualEntities = veList;
        // if (!passport) {
        //   this.getPropertyPassport()
        // }
        this.processVeList(veList);
      }
    });
  }

  ngOnInit() {
    this.loading = true;
    this.veService.findVirtualEntities();
    // this.selectedService = this.serviceSelectorSvc.getSelectedService()
  }

  ngAfterContentChecked() {
    if (this.dropdownOptions.length) {
      this.selectedService = this.dropdownOptions.find((x) => x.active == true);
      this.formInfo.setValue({ selectedService: this.selectedService.name });
    }
  }
  processVeList(veList) {
    this.virtualEntities = veList;
    this.dropdownOptions = this.virtualEntities.map((ve) => {
      const active = this.selectedService && this.selectedService.veId && this.selectedService.veId === ve.veId ? true : false;
      return { name: ve.name, veId: ve.veId, veTypeId: ve.veTypeId, active }; // icon
    });

    if (!this.selectedService) {
      if (!this.serviceSelectorSvc.getSelectedService()) {
        this.serviceSelectorSvc.setSelectedService(veList[0]);
        this.selectedService = veList[0];
        this.dropdownOptions[0].active = true;
      } else {
        this.selectedService = this.serviceSelectorSvc.getSelectedService();
        this.dropdownOptions.find((x) => x.veId === this.selectedService.veId).active = true;
      }
    }
  }
  async switchService(event) {
    const match = this.dropdownOptions.find((x) => {
      return x.name == event.name;
    });
    if (match) {
      this.dropdownOptions.map((x) => (x.active = false));
      match.active = true;
      this.selectedService = match;
      const virtualEntity = await this.veService.getVirtualEntity(this.selectedService.veId, false);
      this.serviceSelectorSvc.setSelectedService(virtualEntity);
    }
  }
  public onRouterOutletActivate(event: any) {
    if (event.pageTitle) {
      // this.currentPageAnnouncer.next(event.pageTitle)
      this.currentPage = event.pageTitle;
    }
  }
}
