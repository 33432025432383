import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeatPumpCaseStudyComponent } from './heat-pump-case-study.component';
import { BuildingFormComponent } from 'src/app/components/case-study/building-form/building-form.component';
import { HeatingSystemFormComponent } from 'src/app/components/case-study/heating-system-form/heating-system-form.component';
import { InstallationFormComponent } from 'src/app/components/case-study/installation-form/installation-form.component';
import { OutcomesFormComponent } from 'src/app/components/case-study/outcomes-form/outcomes-form.component';
import { OtherInfoFormComponent } from 'src/app/components/case-study/other-info-form/other-info-form.component';
import { FormGuard } from 'src/app/services/form.guard';
import { HeatPumpCsFormGuard } from 'src/app/guards/heat-pump-cs-form.guard';

const routes = [
  {
    path: '',
    component: HeatPumpCaseStudyComponent,
    canDeactivate: [HeatPumpCsFormGuard],
    children: [
      { path: 'pre-installation-building', canDeactivate: [FormGuard], component: BuildingFormComponent },
      { path: 'pre-installation-heating', canDeactivate: [FormGuard], component: HeatingSystemFormComponent },
      { path: 'installation', canDeactivate: [FormGuard], component: InstallationFormComponent },
      { path: 'post-installation-heating', canDeactivate: [FormGuard], component: HeatingSystemFormComponent },
      { path: 'post-installation-building', canDeactivate: [FormGuard], component: BuildingFormComponent },
      { path: 'post-installation-outcomes', canDeactivate: [FormGuard], component: OutcomesFormComponent },
      { path: 'other-information', canDeactivate: [FormGuard], component: OtherInfoFormComponent },
      { path: '', redirectTo: 'pre-installation-building', pathMatch: 'full' },
      { path: '**', redirectTo: 'pre-installation-building', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HeatPumpCaseStudyRouting {}
