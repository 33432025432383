import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentCardComponent } from './content-card.component';
import { TagModule } from '../tag/tag.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [ContentCardComponent],
  imports: [CommonModule, TagModule],
  exports: [ContentCardComponent],
})
export class ContentCardModule {}
