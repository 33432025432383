import { Component, Input, OnInit } from '@angular/core';
import { LandingService } from 'src/app/services/landing.service';
import { StrapiService } from 'src/app/services/strapi.service';
import { Utils } from 'src/utils';
@Component({
  selector: 'app-article-content',
  templateUrl: './article-content.component.html',
  styleUrls: ['./article-content.component.scss'],
})
export class ArticleContentComponent implements OnInit {
  @Input() article: any;
  @Input() url;

  timeToRead = 1;
  createdAt: string;
  displayImageUrl = { 'background-image': 'url("../../../assets/images/soft-orange.jpg")' };
  constructor(private strapiService: StrapiService, private landingService: LandingService) {}

  ngOnInit() {
    this.timeToRead = this.getTimeToRead();
    this.createdAt = this.getCreatedAt();
    this.displayImageUrl = this.getDisplayImageUrl(this.article);
  }

  getTimeToRead() {
    if (this.article && this.article.attributes.displayImage) {
      return Utils.getTimeToRead(this.article.attributes.content);
    }
  }

  getCreatedAt() {
    if (this.article && this.article.attributes.displayImage) {
      return Utils.formatZDate(this.article.attributes.createdAt);
    }
  }

  isBookmarked() {
    return this.strapiService.isContentBookmarked(this.article.attributes.slug);
  }

  bookmarkArticle() {
    this.strapiService.bookmarkContent(this.article.attributes.slug, 'articles');
  }

  unbookmarkArticle() {
    this.strapiService.unbookmarkContent(this.article.attributes.slug);
  }

  getForumUserProfileHref(handle) {
    return this.landingService.getForumUserProfileUrl(handle);
  }

  getDisplayImageUrl(content) {
    const url = this.strapiService.getSmallDisplayImageUrl(content)
    return { 'background-image': `url("${url}")` };
  }
}
