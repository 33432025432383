import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormValidationService } from 'src/app/services/form-validation.service';
import { GlowService } from 'src/app/services/glow.service';
import { InvitationsService } from 'src/app/services/invitations.service';
import { ProfessionalGlowService } from 'src/app/services/professional-glow.service';
import { FormValidator } from 'src/app/services/user-validators';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-installer-invitation-modal',
  templateUrl: './installer-invitation-modal.component.html',
  styleUrls: ['./installer-invitation-modal.component.scss']
})
export class InstallerInvitationModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal, private professionalGlowService: ProfessionalGlowService, private fb: FormBuilder, private formValidationService: FormValidationService, private glowService: GlowService, private invitationService: InvitationsService) { }
  invitationForm: FormGroup
  addTag: boolean
  error;
  formErrorMessage;
  loading;
  errorMessage
  firstSection = true
  secondSection
  successMessage: boolean

  ngOnInit() {
    this.invitationForm = this.fb.group({
      email: ['', [Validators.required, FormValidator.isEmailValid]],
      firstLine: [''],
      postcode: [''],
      reference: ['']

    })
  }
  checkFormValidity(formControlName, translateKey?) {
    return this.formValidationService.checkFormValidity(this.invitationForm, formControlName, translateKey)
  }



  generateEstimateRequest() {

    if (this.invitationForm.valid) {
      this.loading = true

      const data = {
        "username": this.invitationForm.value.email,
        "estimateType": "heat-pump-estimate",
        "needsToBeAcceptedBy": this.getAcceptedByDate(),
        "address": {
          "postcode": this.invitationForm.value.postcode,
          "firstLine": this.invitationForm.value.firstLine,
        },

        "externalReference": this.invitationForm.value.reference
      }


      this.professionalGlowService.createEstimateRequestForCustomer(data).subscribe((resp) => {     
         this.loading = true

        if (!resp.valid) {
          this.error = true
          this.formErrorMessage = 'Unable to send estimate request'
          this.invitationService.getAllProfessionalEstimateRequests()
          this.loading = false
        } else {
          this.error = false
          this.invitationService.getAllProfessionalEstimateRequests()
          this.loading = false
          this.successMessage = true
          // this.activeModal.dismiss()

        }
      }, (err) => {
        this.error = true
        this.loading = false
        this.errorMessage = "Something went wrong, please try again later."

      })
    } else {
      this.invitationForm.markAllAsTouched()
      // this.error = true
      this.formErrorMessage = 'Please complete form'
    }
  }

  getAcceptedByDate() {
    return Utils.getDateIn30Days()

  }

  changeSection() {
    this.secondSection = true
    this.firstSection = false
  }
}
