import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-epc-certificate',
  templateUrl: './epc-certificate.component.html',
  styleUrls: ['./epc-certificate.component.scss'],
})
export class EpcCertificateComponent implements OnInit {
  @Input() epcRatingEnergy ='a'
  @Input() epcRatingEnergyPotential ='b'
  constructor() {}

  ngOnInit() {}
}
