import { Component, HostListener, Input, OnInit, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Konva from 'konva';
import { Subscription } from 'rxjs';
import { SchematicsService } from 'src/app/services/schematics.service';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';
import { AddRadiatorModalComponent } from '../add-radiator-modal/add-radiator-modal.component';
import { AddHeatingSystemModalComponent } from '../add-heating-system-modal/add-heating-system-modal.component';
import { KonvaUtils } from './raditator-schematic.utils';
import { Utils } from 'src/utils';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-radiator-schematic',
  templateUrl: './radiator-schematic.component.html',
  styleUrls: ['./radiator-schematic.component.scss'],
})
export class RadiatorSchematicComponent implements OnInit {
  @Input() schematicDocument;
  @Input() schematicId;
  @HostListener('window:resize', []) onResize() {
    this.fitStageIntoParentContainer();
  }
  @Input() isEditing: boolean = false;
  @Input() isCustomWidth: boolean = false;
  schematic: any;

  container: HTMLElement;
  displayTitle: string = 'Heating elements schematic';
  toolLink: string = '/pages/tools/heating-by-room';
  shouldShowToolLink: boolean = true;
  selectedService;
  width = 0;
  height = 0;

  layerWidth;
  layerHeight;

  // rectSpacing = 50;
  rectSpacing = window.innerWidth > 500 ? 40 : 30;
  rectH = 100;
  rectW = 190;

  pipeHeight = 20;
  pipeWidth = 8;

  radWidth = this.rectW + this.pipeWidth * 2;
  radHeight = this.rectH + this.pipeHeight / 2;

  titleBoxHeight = window.innerWidth >= 992 ? this.rectSpacing : 110;
  schematicDescriptionBoxHeight = window.innerWidth >= 992 ? 220 : 320;

  sideBarWidth = window.innerWidth >= 992 ? 300 : 0;

  maxX = 0;

  layer: Konva.Layer;
  // targets: any;

  // connectors: any;
  stage: Konva.Stage;

  floorGroups: any;

  schematicSubscription: Subscription;
  subscriptionSelectedService: Subscription;

  drawingAttributes: any = { floors: {} };

  colors;

  iconPaths = {
    trash: '../../../assets/icons/trash3-fill.svg',
    edit: '../../../assets/icons/pencil-square.svg',
    add: '../../../assets/icons/plus-circle.svg',
  };

  pipeworkColors;
  pipeworkSizeColor = {};

  isInstaller: boolean = false;
  constructor(private schematicService: SchematicsService, private modalService: NgbModal, private auth: AuthenticationService) {}

  ngOnInit() {
    this.isInstaller = this.auth.checkIsRole('installer');
    this.container = document.querySelector('#container');
    this.setColors();
    this.initStage();
    this.setSizes();
    this.onResize();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.schematicDocument && changes.schematicDocument.currentValue.data && (!changes.schematicDocument.currentValue.data.version || changes.schematicDocument.currentValue.data.version < 2.0)) {
      this.schematicDocument = this.schematicService.addVersionSchematicDocument(changes.schematicDocument.currentValue);
      this.schematicDocument = this.schematicService.addUnitsSchematicDocument(this.schematicDocument);
    }
    this.schematic = { ...this.schematicDocument.data };
    this.updateDrawing();
  }

  ngOnDestroy() {
    if (this.schematicSubscription) {
      this.schematicSubscription.unsubscribe();
    }
  }

  setColors() {
    //defined in _variables.scss :root
    const styles = getComputedStyle(document.documentElement);
    this.colors = {
      black: styles.getPropertyValue('--black'),
      white: styles.getPropertyValue('--white'),
      dark: styles.getPropertyValue('--dark'),
      'gray-100': styles.getPropertyValue('--gray-100'),
      'gray-400': styles.getPropertyValue('--gray-400'),
      'gray-500': styles.getPropertyValue('--gray-500'),
      'grey-purple': styles.getPropertyValue('--grey-purple'),
      purple: styles.getPropertyValue('--purple'),
      'light-purple': styles.getPropertyValue('--light-purple'),
      blue: styles.getPropertyValue('--blue'),
      'mid-yellow': styles.getPropertyValue('--mid-yellow'),
      'light-yellow': styles.getPropertyValue('--light-yellow'),
      yellow: styles.getPropertyValue('--yellow'),
      red: styles.getPropertyValue('--red'),
      'light-red': styles.getPropertyValue('--light-red'),
      orange: styles.getPropertyValue('--orange'),
      'dark-orange': styles.getPropertyValue('--dark-orange'),
      pink: styles.getPropertyValue('--pink'),
      green: styles.getPropertyValue('--green'),
      'light-green': styles.getPropertyValue('--light-green'),
    };
    this.pipeworkColors = [this.colors['pink'], this.colors['yellow'], this.colors['orange'], this.colors['red'], this.colors['blue'], this.colors['purple'], this.colors['green']];
  }

  shouldBreak(breakpoint) {
    const width = this.isCustomWidth ? this.container.offsetWidth : window.innerWidth;
    switch (breakpoint) {
      case 'sm':
        return width < 576;
      case 'md':
        return width < 768;
      case 'lg':
        return width < 992;
      case 'xl':
        return width < 1200;
      default:
        return false;
    }
  }

  setSizes() {
    this.rectSpacing = this.shouldBreak('sm') ? 30 : 40;
    this.radWidth = this.rectW + this.pipeWidth * 2;
    this.radHeight = this.rectH + this.pipeHeight / 2;
    this.sideBarWidth = this.shouldBreak('xl') ? 0 : 250;
    this.titleBoxHeight = this.shouldBreak('xl') ? 110 : this.rectSpacing;
    // this.schematicDescriptionBoxHeight = this.shouldBreak('sm') ? (this.isEditing ? 400 : 410) : this.shouldBreak('lg') ? (this.isEditing ? 330 : 320) : this.isEditing ? 230 : 220;
    this.schematicDescriptionBoxHeight = this.getSchematicDescriptionBoxHeight();
    this.width = this.container.offsetWidth;

    this.height = this.getHeight(this.schematic);
    this.layerWidth = this.width;
    this.layerHeight = this.height;
    this.layer.width(this.layerWidth);
    this.layer.height(this.layerHeight);
    this.stage.width(this.width);
    this.stage.height(this.height);
    this.maxX = this.layerWidth - this.radWidth - this.rectSpacing - this.sideBarWidth;
  }

  initStage() {
    this.layer = new Konva.Layer({});
    this.stage = new Konva.Stage({
      container: 'container',
      width: this.width,
      height: this.height,
    });
    this.stage.add(this.layer);
  }

  getSchematicDescriptionBoxHeight() {
    const schematicDescription = this.generateSchematicDescription(0, 0, this.layerWidth - 40, false);
    return schematicDescription.height() + 40;
  }

  getHeight(schematic) {
    let height = this.schematicDescriptionBoxHeight;
    schematic.floors
      ? schematic.floors.forEach((floor) => {
          height += this.getFloorGroupHeight(floor);
        })
      : null;
    return height;
  }

  getFloorGroupHeight(floor) {
    let radNumber = 0;
    let x = 0;
    let rows = 1;
    let rowHeight = this.rectH + this.pipeHeight / 2 + this.rectSpacing;

    floor.rooms
      ? floor.rooms.forEach((room) => {
          room.radiators && room.radiators.length > 0 ? (radNumber += room.radiators.length) : (radNumber += 1);
        })
      : null;

    for (let i = 0; i < radNumber; i++) {
      if (x * (this.radWidth + this.rectSpacing) >= this.maxX) {
        x = 1;
        rows += 1;
      } else {
        x++;
      }
    }
    const height = this.isEditing ? rows * rowHeight + this.titleBoxHeight * 1.5 : rows * rowHeight + this.titleBoxHeight;
    return radNumber ? height : rowHeight;
  }

  fitStageIntoParentContainer() {
    this.updateDrawing();
  }

  updateDrawing() {
    if (this.stage) {
      this.pipeworkSizeColor = this.getPipeworkSizeColor(this.schematic);
      this.layer.destroyChildren();
      this.setSizes();
      this.drawSchematic(this.schematic);
    }
  }

  updateObjects(targets, connectors) {
    targets.forEach((target) => {
      var node = this.layer.findOne('#' + target.id);
      node.x(target.x);
      node.y(target.y);
    });
    connectors.forEach((connect) => {
      var line = this.layer.findOne('#' + connect.id) as any;
      var fromNode = this.layer.findOne('#' + connect.from);
      var toNode = this.layer.findOne('#' + connect.to);

      // const points = this.getConnectorPoints(fromNode.position(), toNode.position());
      const points = KonvaUtils.getConnectorPoints(fromNode, toNode);
      line.points(points);
    });
  }

  fitGroupIntoWidth(group, width) {
    if (group.width() > width) {
      const scale = width / group.width();
      group.scale({ x: scale, y: scale });
      group.width(group.width() * scale);
      group.height(group.height() * scale);
    }
  }

  getPipeworkSizeColor(schematic) {
    const pipeworkSizeColor = {};
    let pipeworkCount = 0;
    schematic.floors.forEach((floor) => {
      floor.rooms.forEach((room) => {
        if (!(room.pipework && room.pipework.length)) {
          return;
        }
        // assumes there is only one pipe per room
        const internalDiameter = room.pipework[0].internalDiameter;
        if (!pipeworkSizeColor[internalDiameter]) {
          if (!this.pipeworkColors[pipeworkCount]) {
            // get random color - not ideal but will do for now as not expecting more than 7 different sizes
            pipeworkSizeColor[internalDiameter] = '#' + Math.floor(Math.random() * 16777215).toString(16);
          } else {
            pipeworkSizeColor[internalDiameter] = this.pipeworkColors[pipeworkCount];
          }
          pipeworkCount++;
        }
      });
    });
    return pipeworkSizeColor;
  }

  drawSchematic(schematic) {
    let h = 0;
    schematic.floors
      ? schematic.floors.forEach((floor, i) => {
          h = this.drawFloor(floor, h);
        })
      : null;

    this.generateDashedLinesBetweenFloors();
    const yPosBuildingDescription = this.stage.height() - this.schematicDescriptionBoxHeight;
    this.generateSchematicDescriptionBorder(this.schematicDescriptionBoxHeight, 0, yPosBuildingDescription);
    this.generateSchematicDescription(this.schematicDescriptionBoxHeight / 2, 0, yPosBuildingDescription + 20);

    this.schematicService.setStage(this.stage);
  }

  drawFloor(floor, totalHeight) {
    let floorHeight = this.getFloorGroupHeight(floor);
    totalHeight += floorHeight;
    const yOffset = -totalHeight;
    const y = this.stage.height() - this.schematicDescriptionBoxHeight + yOffset;
    const floorGroup = new Konva.Group({ id: floor.id, width: this.layerWidth, height: floorHeight, x: 0, y });
    floorGroup.addName('floorGroup' + floor.id);
    const radGroup = new Konva.Group({ id: floor.id, width: this.layerWidth, height: floorHeight, x: 0, y: 0 });
    radGroup.addName('radGroup' + floor.id);
    const border = new Konva.Rect({ x: 0, y: 0, width: this.layerWidth, height: floorHeight, fill: this.colors['white'] });
    const leftBorder = KonvaUtils.generateSolidLine(0, 0, 0, floorHeight, this.colors['purple']);
    const rightBorder = KonvaUtils.generateSolidLine(this.layerWidth, 0, this.layerWidth, floorHeight, this.colors['purple']);
    floorGroup.add(border);
    floorGroup.add(leftBorder);
    floorGroup.add(rightBorder);
    const targets = this.generateTargets(floor.rooms, floorHeight);
    const connectors = this.generateConnectors(targets);
    this.generateNodes(connectors, targets, radGroup, floor);
    let minX;
    let maxX;
    if (targets.length) {
      minX = targets.reduce((prev, curr) => (prev.x < curr.x ? prev : curr)).x;
      maxX = targets.reduce((prev, curr) => (prev.x > curr.x ? prev : curr)).x;
    }

    radGroup.width(maxX + this.radWidth + this.rectSpacing - minX);
    this.alignRadGroup(radGroup, this.layerWidth);
    floorGroup.add(radGroup);
    const floorTitleYpos = floorHeight > this.rectSpacing ? this.rectSpacing : 5;
    this.generateFloorTitle(floor, this.titleBoxHeight / 2, floorTitleYpos);
    floorGroup.add(this.drawingAttributes.floorTitle);
    if (this.isEditing) {
      this.generateAddRoomButton(floor, floorGroup, this.drawingAttributes.floorTitle.y() + this.drawingAttributes.floorTitle.height() + 5);
    }
    this.drawingAttributes.floors[floor.id] = floorGroup;
    this.layer.add(floorGroup);
    this.updateObjects(targets, connectors);
    return totalHeight;
  }

  generateTargets(roomsArray = [], floorGroupHeight) {
    const result = [];
    let yOffset = -1.5 * this.radHeight;
    let offsetXPos = 0;
    let direction = 1;
    for (let room of roomsArray) {
      let radNumber = (room.radiators && room.radiators.length) || 1;
      let rowNum = 0;
      for (let i = 0; i < radNumber; i++) {
        let x = offsetXPos * (this.radWidth + this.rectSpacing);
        if (x >= this.maxX || x < 0) {
          yOffset -= this.radHeight + this.rectSpacing;
          direction = direction * -1;
          offsetXPos = direction > 0 ? offsetXPos + 1 : offsetXPos - 1;
          x = offsetXPos * (this.radWidth + this.rectSpacing);
          if (radNumber > 1 && i > 0) {
            rowNum++;
          }
        }
        const title = (room.radiators[i] && room.radiators[i].title) || 'No Heating Elements';
        const radH = (room.radiators[i] && room.radiators[i].height) || 0;
        const radL = (room.radiators[i] && room.radiators[i].length) || 0;
        const radOutput = (room.radiators[i] && room.radiators[i].output) || 0;
        const id = (room.radiators[i] && room.radiators[i].id) || `no-radiators-${room.id}`;
        result.push({ roomObj: room, rowInRoom: rowNum, room: room.title, roomH: room.height, roomW: room.width, roomL: room.length, roomFloorArea: room.floorArea, title, radH, radL, radOutput, id, x: x, y: floorGroupHeight + yOffset });
        offsetXPos = direction > 0 ? offsetXPos + 1 : offsetXPos - 1;
      }
    }
    return result;
  }

  generateConnectors(targets) {
    const result = [];
    for (let i = 0; i < targets.length; i++) {
      const from = targets[i].id;
      const to = targets[i + 1] ? targets[i + 1].id : targets[i].id;
      if (from === to) {
        continue;
      }
      result.push({
        id: `connector-${from}-${to}`,
        from: from,
        to: to,
      });
    }
    return result;
  }

  generateSchematicDescriptionBorder(height, x = 0, y = 0) {
    const border = new Konva.Group({ x, y, width: this.layerWidth, height: height });
    border.addName('schematicDescriptionBorder');
    const background = new Konva.Rect({
      x: 0,
      y: 0,
      width: this.layerWidth,
      height: height,
      stroke: this.colors['purple'],
      strokeWidth: 1,
      fill: this.colors['white'],
    });
    border.add(background);
    this.layer.add(border);
    return border;
  }

  generateWaterCylinderDescription(x, y) {
    const waterCylinderDescription = new Konva.Group({ x, y, width: this.layerWidth });
    waterCylinderDescription.addName('waterCylinderDescription');
    let text = 'TBC';
    let sizeText = 'TBC';
    if (this.schematic.heatingSystem && this.schematic.heatingSystem.DHWHasCylinder) {
      text = this.schematic.heatingSystem.DHWHasCylinder;
      if (this.schematic.heatingSystem.DHWHasCylinder == 'No') {
        sizeText = 'N/A';
      } else if (this.schematic.heatingSystem.DHWHasCylinder == 'Yes') {
        sizeText = this.schematic.heatingSystem.DHWCylinderSize ? `${this.schematic.heatingSystem.DHWCylinderSize} L` : 'TBC';
      }
    }

    const textGroup = new Konva.Group({ x: 0, y: 0 });
    textGroup.addName('waterCylinderDescriptionTextGroup');
    const cylinderGroup = new Konva.Group({ x: 0, y: 0 });
    cylinderGroup.addName('waterCylinderDescriptionCylinderGroup');
    const cylinderTitle = new Konva.Text({
      text: 'Water Cylinder:',
      fontSize: 18,
      y: 0,
      fontStyle: 'bold',
      fontFamily: 'Open Sans',
      fill: this.colors['dark'],
      padding: 5,
      align: 'center',
    });
    const waterCylinder = new Konva.Text({
      text,
      fontSize: 18,
      y: 0,
      x: cylinderTitle.width(),
      fontStyle: 'normal',
      fontFamily: 'Open Sans',
      fill: this.colors['dark'],
      padding: 5,
      align: 'center',
    });
    cylinderGroup.add(cylinderTitle);
    cylinderGroup.add(waterCylinder);
    cylinderGroup.height(cylinderTitle.height());
    cylinderGroup.width(cylinderTitle.width() + waterCylinder.width());

    const sizeGroup = new Konva.Group({ x: 0, y: 0 });
    sizeGroup.addName('waterCylinderDescriptionSizeGroup');
    const sizeTitle = new Konva.Text({
      text: 'Size:',
      fontSize: 18,
      y: 0,
      fontStyle: 'bold',
      fontFamily: 'Open Sans',
      fill: this.colors['dark'],
      padding: 5,
      align: 'center',
    });
    const sizeValue = new Konva.Text({
      text: sizeText,
      fontSize: 18,
      y: 0,
      x: sizeTitle.width(),
      fontStyle: 'normal',
      fontFamily: 'Open Sans',
      fill: this.colors['dark'],
      padding: 5,
      align: 'center',
    });

    sizeGroup.add(sizeTitle);
    sizeGroup.add(sizeValue);
    sizeGroup.height(sizeTitle.height());
    sizeGroup.width(sizeTitle.width() + sizeValue.width());

    cylinderGroup.y(0);
    sizeGroup.y(cylinderGroup.height());

    textGroup.add(cylinderGroup);
    textGroup.add(sizeGroup);
    textGroup.width(Math.max(cylinderGroup.width(), sizeGroup.width()));
    textGroup.height(cylinderGroup.height() + sizeGroup.height());

    const background = new Konva.Rect({
      x: 0,
      y: 0,
      width: Math.max(cylinderGroup.width(), sizeGroup.width()) + 20,
      height: cylinderGroup.height() + sizeGroup.height() + 20,
      // stroke: this.colors['grey-purple'],
      fill: this.colors['light-red'],
    });

    KonvaUtils.virticalAlignCenter(textGroup, background.y() + background.height() / 2);

    waterCylinderDescription.add(background);
    waterCylinderDescription.add(textGroup);
    waterCylinderDescription.width(background.width());
    waterCylinderDescription.height(background.height());
    return waterCylinderDescription;
  }

  generateHeatingSystemDescription(x, y, maxW) {
    let heatingSystemDescription = new Konva.Group({ x, y });
    heatingSystemDescription.addName('heatingSystemDescription');
    let heatingSystemType, heatingSystemManufacturer, heatingSystemModel, background, editIconGroup;
    let textGroup = new Konva.Group({});
    textGroup.addName('heatingSystemDescriptionTextGroup');
    heatingSystemType = new Konva.Text({
      text: this.schematic.heatingSystem && this.schematic.heatingSystem.type ? `${this.schematic.heatingSystem.type}` : `Heating System`,
      fontSize: 18,
      fontStyle: 'bold',
      fontFamily: 'Open Sans',
      fill: this.colors['dark'],
      padding: 5,
      align: 'start',
    });
    heatingSystemManufacturer = new Konva.Text({
      text: this.schematic.heatingSystem && this.schematic.heatingSystem.manufacturer ? `${this.schematic.heatingSystem.manufacturer}:` : 'Manufacturer TBC:',
      fontSize: 18,
      fontStyle: 'bold',
      fontFamily: 'Open Sans',
      fill: this.colors['dark'],
      padding: 5,
      align: 'start',
    });
    heatingSystemModel = new Konva.Text({
      text: this.schematic.heatingSystem && this.schematic.heatingSystem.model ? `${this.schematic.heatingSystem.model}` : 'Model TBC',
      fontSize: 18,

      x: heatingSystemManufacturer.width(),
      fontStyle: 'normal',
      fontFamily: 'Open Sans',
      fill: this.colors['dark'],
      padding: 5,
      align: 'start',
    });

    if (this.isEditing) {
      //attach edit icon to heating system type
      const { iconGroup, tr, icon } = KonvaUtils.attachIconToNode(heatingSystemType, this.iconPaths.edit, 20, 20, heatingSystemType.width() + 5, heatingSystemType.height() - 25, this.stage);
      iconGroup.on('pointerup', () => {
        this.openAddHeatingSystemModal();
      });
      editIconGroup = iconGroup;
      textGroup.add(tr);
    }

    const linedText = KonvaUtils.breakTextIntoLines(heatingSystemModel, maxW);
    heatingSystemModel.text(linedText);

    textGroup.add(heatingSystemType);
    textGroup.add(heatingSystemManufacturer);
    textGroup.add(heatingSystemModel);
    textGroup.width(Math.max(heatingSystemManufacturer.width() + heatingSystemModel.width(), heatingSystemType.width() + ((editIconGroup && editIconGroup.width()) || 0)));
    textGroup.height(heatingSystemType.height() + Math.max(heatingSystemManufacturer.height(), heatingSystemModel.height()));

    background = new Konva.Rect({
      width: textGroup.width() + 20,
      height: textGroup.height() + 20,
      fill: this.colors['mid-yellow'],
    });

    heatingSystemDescription.add(background);
    heatingSystemDescription.add(textGroup);

    heatingSystemDescription.width(background.width());
    heatingSystemDescription.height(background.height());
    heatingSystemDescription.x(0);
    background.position({ x: 0, y: 0 });

    heatingSystemType.y(0);
    heatingSystemManufacturer.y(heatingSystemType.height());
    //on the same line as heatingSystemManufacturer
    heatingSystemModel.y(heatingSystemManufacturer.y());
    KonvaUtils.virticalAlignCenter(textGroup, background.y() + background.height() / 2);

    return heatingSystemDescription;
  }

  generateFloorAreaDescription(x, y, maxW) {
    const floorAreaDescription = new Konva.Group({
      x: x,
      y: y,
      width: this.layerWidth,
    });
    floorAreaDescription.addName('floorAreaDescription');
    const floorAreaTitle = new Konva.Text({
      text: `Floor Area:`,
      fontSize: 18,
      fontStyle: 'bold',
      fontFamily: 'Open Sans',
      fill: this.colors['dark'],
      padding: 5,
      align: 'start',
    });
    const floorArea = new Konva.Text({
      text: `${Utils.getNumberToDecimalPlace(this.schematic.totalFloorArea) || 0} m²`,
      x: floorAreaTitle.width(),
      fontSize: 18,
      fontStyle: 'normal',
      fontFamily: 'Open Sans',
      fill: this.colors['dark'],
      padding: 5,
      align: 'start',
    });

    const linedText = KonvaUtils.breakTextIntoLines(floorArea, maxW);
    floorArea.text(linedText);

    floorAreaDescription.add(floorArea);
    floorAreaDescription.add(floorAreaTitle);
    floorAreaDescription.width(floorArea.width() + floorAreaTitle.width());
    floorAreaDescription.height(Math.max(floorArea.height(), floorAreaTitle.height()));
    return floorAreaDescription;
  }

  generateHeatingPowerOutputDescription(x, y, maxW) {
    const heatingPowerOutputDescription = new Konva.Group({ x, y, width: this.layerWidth });
    heatingPowerOutputDescription.addName('heatingPowerOutputDescription');
    const heatingPowerOutputTitle = new Konva.Text({
      text: `Heating Power Output:`,
      fontSize: 18,
      fontStyle: 'bold',
      fontFamily: 'Open Sans',
      fill: this.colors['dark'],
      padding: 5,
      align: 'center',
    });

    const heatingPowerOutput = new Konva.Text({
      text: `${Utils.getNumberToDecimalPlace(this.schematic.totalHeatingPowerOutput) || 0} W`,
      fontSize: 18,
      x: heatingPowerOutputTitle.width(),
      fontStyle: 'normal',
      fontFamily: 'Open Sans',
      fill: this.colors['dark'],
      padding: 5,
      align: 'start',
    });

    const linedText = KonvaUtils.breakTextIntoLines(heatingPowerOutput, maxW);
    heatingPowerOutput.text(linedText);
    heatingPowerOutputDescription.add(heatingPowerOutput);
    heatingPowerOutputDescription.add(heatingPowerOutputTitle);
    heatingPowerOutputDescription.width(heatingPowerOutput.width() + heatingPowerOutputTitle.width());
    heatingPowerOutputDescription.height(Math.max(heatingPowerOutput.height(), heatingPowerOutputTitle.height()));
    return heatingPowerOutputDescription;
  }

  generateBuildingDescription(x, y, maxW) {
    const buildingDescription = new Konva.Group({ x, y, width: this.layerWidth });
    buildingDescription.addName('buildingDescription');
    const textGroup = new Konva.Group({ x: 0, y: 0 });
    textGroup.addName('buildingDescriptionTextGroup');
    const totalArea = this.generateFloorAreaDescription(0, 0, maxW);
    const totalHeatingPowerOutput = this.generateHeatingPowerOutputDescription(0, totalArea.height(), maxW);
    textGroup.add(totalArea);
    textGroup.add(totalHeatingPowerOutput);
    textGroup.width(Math.max(totalArea.width(), totalHeatingPowerOutput.width()));
    textGroup.height(totalArea.height() + totalHeatingPowerOutput.height());
    const background = new Konva.Rect({
      x: 0,
      y: 0,
      width: Math.max(totalArea.width(), totalHeatingPowerOutput.width()) + 20,
      height: totalArea.height() + totalHeatingPowerOutput.height() + 20,
      fill: this.colors['light-yellow'],
    });

    KonvaUtils.virticalAlignCenter(textGroup, background.y() + background.height() / 2);

    buildingDescription.add(background);
    buildingDescription.add(textGroup);
    buildingDescription.width(background.width());
    buildingDescription.height(background.height());
    return buildingDescription;
  }

  generateHeatingDescription(x, y, maxW) {
    const heatingSystemAndWaterCylinderGroup = new Konva.Group({ x, y });
    heatingSystemAndWaterCylinderGroup.addName('heatingSystemAndWaterCylinderGroup');
    const heatingSystemDescription = this.generateHeatingSystemDescription(x, y, maxW);
    const waterCylinderDescription = this.generateWaterCylinderDescription(heatingSystemDescription.x() + heatingSystemDescription.width() + this.rectSpacing, 0);
    this.alignHsAndWcDescription(heatingSystemDescription, waterCylinderDescription);
    const points = KonvaUtils.getConnectorPoints(waterCylinderDescription, heatingSystemDescription);
    const connector = KonvaUtils.generateSolidLine(points[0], points[1], points[2], points[3], this.colors['gray-400'], 3);

    heatingSystemAndWaterCylinderGroup.add(heatingSystemDescription);
    heatingSystemAndWaterCylinderGroup.add(connector);
    heatingSystemAndWaterCylinderGroup.add(waterCylinderDescription);
    if (heatingSystemDescription.y() == waterCylinderDescription.y()) {
      heatingSystemAndWaterCylinderGroup.height(Math.max(heatingSystemDescription.height(), waterCylinderDescription.height()));
      heatingSystemAndWaterCylinderGroup.width(heatingSystemDescription.width() + waterCylinderDescription.width() + connector.width());
    } else {
      heatingSystemAndWaterCylinderGroup.height(heatingSystemDescription.height() + waterCylinderDescription.height() + connector.height());
      heatingSystemAndWaterCylinderGroup.width(Math.max(heatingSystemDescription.width(), waterCylinderDescription.width()));
    }
    return { heatingSystemAndWaterCylinderGroup, heatingSystemDescription, waterCylinderDescription };
  }

  generateSchematicDescription(height, x = 0, y = 10, addToLayer = true) {
    const groupH = height;
    const groupW = this.layerWidth;
    const descriptionGroup = new Konva.Group({ x, y, width: groupW, height: groupH });
    descriptionGroup.addName('schematicDescription');

    let addFloorButton, heatingSystemAndWaterCylinderGroup;

    const buildingDescription = this.generateBuildingDescription(this.rectSpacing, 0, this.layerWidth - 40);
    if (this.isEditing) {
      addFloorButton = this.generateAddFloorButton(buildingDescription.x(), buildingDescription.y() + buildingDescription.height() + 5);
      descriptionGroup.add(addFloorButton);
    }
    descriptionGroup.add(buildingDescription);
    ({ heatingSystemAndWaterCylinderGroup } = this.generateHeatingDescription(buildingDescription.x() + buildingDescription.width() + this.rectSpacing, 0, this.layerWidth - 40));
    descriptionGroup.add(heatingSystemAndWaterCylinderGroup);

    this.alignSchematicDescription(heatingSystemAndWaterCylinderGroup, buildingDescription, addFloorButton);

    if (buildingDescription.y() === heatingSystemAndWaterCylinderGroup.y()) {
      descriptionGroup.height(Math.max(buildingDescription.height(), heatingSystemAndWaterCylinderGroup.height()) + (addFloorButton ? addFloorButton.height() + 5 : 0));
    } else {
      descriptionGroup.height(buildingDescription.height() + heatingSystemAndWaterCylinderGroup.height() + (addFloorButton ? addFloorButton.height() + 5 : 0));
    }

    this.drawingAttributes.schematicDescription = descriptionGroup;
    if (addToLayer) {
      this.layer.add(descriptionGroup);
    }
    return descriptionGroup;
  }

  generateAddFloorButton(x: number = 0, y: number = 0) {
    const groupH = this.titleBoxHeight;
    const groupW = this.layerWidth;
    const buttonGroup = new Konva.Group({ x, y, width: groupW, height: groupH, listening: true });
    buttonGroup.addName('addFloorButton');
    const background = new Konva.Rect({
      width: groupW / 2,
      y: 0,
      fill: this.colors['purple'],
      shadowBlur: 0,
      stroke: '',
      strokeWidth: 1,
      cornerRadius: 0,
    });

    const textGroup = new Konva.Group({ x: 0, y: 0 });
    textGroup.addName('addFloorButtonText');

    const addFloor = new Konva.Text({
      text: `New Floor`,
      y: 0,
      x: 20,
      fontSize: 16,
      fontStyle: 'normal',
      fontFamily: 'Open Sans',
      fill: this.colors['white'],
      align: 'center',
      verticalAlign: 'middle',
    });
    const { iconGroup, tr, icon } = KonvaUtils.attachIconToNode(addFloor, this.iconPaths.add, 15, 15, addFloor.x() - 40, addFloor.y(), this.stage);
    textGroup.add(tr);
    textGroup.add(addFloor);
    textGroup.height(addFloor.height());
    textGroup.width(addFloor.width() + iconGroup.width());
    background.height(textGroup.height() + 15);
    background.width(textGroup.width() + 30);
    KonvaUtils.horizontalAlignCenter(textGroup, background.width() / 2);
    KonvaUtils.virticalAlignCenter(textGroup, background.y() + background.height() / 2);

    buttonGroup.add(background);
    buttonGroup.add(textGroup);
    buttonGroup.height(background.height());
    buttonGroup.width(background.width());
    buttonGroup.on('pointerup', () => {
      this.schematicService.addFloor(this.schematicDocument, this.schematicId, { title: `Floor ${this.schematic.floors.length + 1}`, id: `floor${this.schematic.floors.length + 1}`, rooms: [], estimatedArea: 0 });
    });
    buttonGroup.on('mouseenter', () => {
      this.stage.container().style.cursor = 'pointer';
    });
    buttonGroup.on('mouseleave', () => {
      this.stage.container().style.cursor = 'default';
    });

    this.drawingAttributes.addFloorButton = buttonGroup;
    return buttonGroup;
  }

  generateAddRoomButton(floor, floorGroup, yPos) {
    const groupH = this.titleBoxHeight;
    const groupW = this.layerWidth;
    const buttonGroup = new Konva.Group({ x: 0, y: yPos, width: groupW, height: groupH, listening: true });
    buttonGroup.addName('addRoomButton');

    const background = new Konva.Rect({
      y: 0.1 * groupH,
      fill: this.colors['purple'],
      shadowBlur: 0,
      padding: 5,
      strokeWidth: 1,
      cornerRadius: 0,
    });

    const textGroup = new Konva.Group({ y: 0.1 * groupH });
    textGroup.addName('addRoomButtonText');

    const addRoom = new Konva.Text({
      text: `New Room`,
      y: 0,
      x: 20,
      fontSize: 16,
      fontStyle: 'normal',
      fontFamily: 'Open Sans',
      fill: this.colors['white'],
      align: 'center',
      verticalAlign: 'middle',
    });

    //add icon to left of text
    const { iconGroup, tr, icon } = KonvaUtils.attachIconToNode(addRoom, this.iconPaths.add, 15, 15, addRoom.x() - 40, addRoom.y(), this.stage);
    textGroup.add(tr);
    textGroup.add(addRoom);
    textGroup.width(addRoom.width() + iconGroup.width());
    textGroup.height(addRoom.height());

    background.height(textGroup.height() + 15);
    background.width(textGroup.width() + 30);
    //center text in background
    KonvaUtils.horizontalAlignCenter(textGroup, background.width() / 2);
    KonvaUtils.virticalAlignCenter(textGroup, background.y() + background.height() / 2);
    buttonGroup.add(background);
    buttonGroup.add(textGroup);
    buttonGroup.height(background.height());
    buttonGroup.width(background.width());

    this.alignAddRoomButton(buttonGroup, groupW);

    buttonGroup.on('pointerup', () => {
      this.openAddRoomModal({}, floor);
    });
    buttonGroup.on('mouseenter', () => {
      this.stage.container().style.cursor = 'pointer';
    });
    buttonGroup.on('mouseleave', () => {
      this.stage.container().style.cursor = 'default';
    });

    this.drawingAttributes.addRoomButton = buttonGroup;
    floorGroup.add(buttonGroup);
  }

  generateFloorTitle(floor, height, yPos = 0) {
    const groupH = height;
    const groupW = this.layerWidth;
    const iconH = 15;
    const iconW = 15;
    let deleteButton, trashIcon;
    const titleGroup = new Konva.Group({
      x: 0,
      y: yPos,
      width: groupW,
      height: groupH,
    });
    titleGroup.addName('floorTitle');

    const title = new Konva.Text({
      text: `${floor.title} (${Utils.getNumberToDecimalPlace(floor.estimatedArea)} m²)`,
      fontSize: 18,
      fontStyle: 'bold',
      fontFamily: 'Open Sans',
      // fill: this.colors['purple'],
      fill: this.colors['dark'],
      // width: groupW / 2,
      padding: 5,
      align: 'center',
      y: 0.1 * groupH,
    });

    const background = new Konva.Rect({
      y: 0.1 * groupH,
      fill: this.colors['light-yellow'],
      // stroke: this.colors['grey-purple'],
      shadowBlur: 0,
      cornerRadius: 0,
    });

    background.width(title.width() + 10);
    background.height(title.height());
    titleGroup.add(background);
    titleGroup.add(title);
    titleGroup.width(background.width());
    titleGroup.height(background.height());

    if (this.isEditing) {
      const { iconGroup, tr, icon } = KonvaUtils.attachIconToNode(title, this.iconPaths.trash, 15, 15, title.width() + 5, title.height() - 20, this.stage);
      iconGroup.on('pointerup', () => {
        this.schematicService.removeFloor(this.schematic, this.schematicId, floor.id);
      });
      deleteButton = iconGroup;
      trashIcon = icon;
      background.width(title.width() + 20 + iconGroup.width());
      titleGroup.add(tr);
    }
    this.alignFloorTitle(titleGroup, groupW);

    this.drawingAttributes.floorTitle = titleGroup;

    return titleGroup;
  }

  getRadiatorShape(backgroundColor) {
    const rad = new Konva.Group({
      width: this.radWidth,
      height: this.radHeight,
      draggable: false,
    });

    rad.addName('radiatorShape');
    const radBody = new Konva.Rect({ x: this.pipeHeight, y: 0, fill: backgroundColor, width: this.rectW, height: this.rectH, shadowBlur: 0, stroke: this.colors['purple'], strokeWidth: 1 });
    const rightPipeGroup = new Konva.Group({ x: radBody.x() + radBody.width(), y: this.rectH - 10 });
    rightPipeGroup.addName('rightPipeGroup');
    const virticalPart = new Konva.Rect({ fill: 'transparent', width: this.pipeWidth, height: this.pipeHeight, x: this.pipeWidth / 2, y: 0, shadowBlur: 0, stroke: this.colors['purple'], strokeWidth: 1 });
    const horizontalPart = new Konva.Rect({ fill: this.colors['light-purple'], width: this.pipeHeight, height: this.pipeWidth, x: 0, y: 0, shadowBlur: 0, stroke: this.colors['purple'], strokeWidth: 1 });
    rightPipeGroup.add(virticalPart);
    rightPipeGroup.add(horizontalPart);
    const leftPipeGroup = new Konva.Group({ x: 0, y: this.rectH - 10 });
    leftPipeGroup.addName('leftPipeGroup');
    const virticalPartLeft = new Konva.Rect({ fill: 'transparent', width: this.pipeWidth, height: this.pipeHeight, x: this.pipeWidth / 2, y: 0, shadowBlur: 0, stroke: this.colors['purple'], strokeWidth: 1 });
    const horizontalPartLeft = new Konva.Rect({ fill: this.colors['light-purple'], width: this.pipeHeight, height: this.pipeWidth, x: 0, y: 0, shadowBlur: 0, stroke: this.colors['purple'], strokeWidth: 1 });
    leftPipeGroup.add(virticalPartLeft);
    leftPipeGroup.add(horizontalPartLeft);
    rad.add(radBody);
    rad.add(rightPipeGroup);
    rad.add(leftPipeGroup);
    return rad;
  }

  generateRadiatorBox(target, group, floor) {
    const backgroundColor = target.id.includes('no-radiators') ? this.colors['light-purple'] : this.colors['mid-yellow'];
    const nodeGroup = new Konva.Group({
      id: target.id,
      width: this.radHeight,
      height: this.radWidth,
      draggable: false,
    });
    nodeGroup.addName('radiator-' + target.id);

    const rad = this.getRadiatorShape(backgroundColor);
    nodeGroup.add(rad);
    const roomTitle = `${target.room.toUpperCase()}`;

    let roomInfo = ``;
    if (target.roomFloorArea) {
      roomInfo += `\nFloor Area: ${target.roomFloorArea} m²`;
    } else {
      const floorArea = target.roomL * target.roomW;
      roomInfo += `\nFloor Area: ${Utils.getNumberToDecimalPlace(floorArea)} m²`;
    }
    roomInfo += `; Height: ${target.roomH}m`;

    let heatingElementInfo = target.id.includes('no-radiators')
      ? `\n\n${target.title}\n\n\n`
      : target.title === 'Under-floor Heating'
      ? `\n\nL ${target.radH}m x W ${target.radL}m\n${target.title}\n\n`
      : `\n\nH ${target.radH}cm x L ${target.radL}cm\n${target.title}\n\n`;

    if (target.radOutput && !isNaN(target.radOutput)) {
      heatingElementInfo += `Output: ${Utils.getNumberToDecimalPlace(target.radOutput)}W @ 50°C`;
    }
    const roomTitleBox = new Konva.Text({ x: this.pipeHeight, text: roomTitle, fontSize: 15, fontStyle: 'bold', fontFamily: 'Open sans', fill: this.colors['dark'], padding: 3, y: 5, align: 'start', verticalAlign: 'top' });
    nodeGroup.add(roomTitleBox);

    const roomInfoBox = new Konva.Text({ x: this.pipeHeight, text: roomInfo, fontSize: 15, fontFamily: 'Open sans', fill: this.colors['dark'], width: this.rectW, padding: 3, y: 5, align: 'start' });
    nodeGroup.add(roomInfoBox);

    const radInfoBox = new Konva.Text({ x: this.pipeHeight, text: heatingElementInfo, fontSize: 15, fontFamily: 'Open sans', fill: this.colors['dark'], width: this.rectW, padding: 3, y: roomInfoBox.height(), align: 'start' });
    nodeGroup.add(radInfoBox);

    const trashBox = new Konva.Rect({ fill: 'transparent' });
    let editIconGroup;
    if (floor && this.isEditing) {
      //attach edit icon to room title
      const { iconGroup, tr, icon } = KonvaUtils.attachIconToNode(roomTitleBox, this.iconPaths.edit, 10, 10, roomTitleBox.width() + roomTitleBox.x() + 5, roomTitleBox.y() + 3, this.stage);
      editIconGroup = iconGroup;
      iconGroup.on('pointerup', () => {
        this.openAddRoomModal(target.roomObj, floor);
      });

      nodeGroup.add(iconGroup);
      nodeGroup.add(tr);

      //attach trash icon to bottom right corner
      if (!target.id.includes('no-radiators')) {
        const { iconGroup, tr, icon } = KonvaUtils.attachIconToNode(trashBox, this.iconPaths.trash, 15, 15, this.rectW + this.pipeWidth - 10, this.rectH - 20, this.stage);
        iconGroup.on('pointerup', () => {
          this.schematicService.removeRadiator(this.schematic, this.schematicId, target.id, target.roomObj.id);
        });
        nodeGroup.add(iconGroup);
        nodeGroup.add(tr);
      }
    }

    while (radInfoBox.height() + roomInfoBox.height() > this.rectH) {
      radInfoBox.fontSize(radInfoBox.fontSize() - 0.1);
      roomTitleBox.fontSize(roomInfoBox.fontSize() - 0.1);
      editIconGroup && editIconGroup.x(roomTitleBox.width() + roomTitleBox.x() + 5) && editIconGroup.y(roomTitleBox.y() + 3);
      roomInfoBox.fontSize(roomInfoBox.fontSize() - 0.1);
      radInfoBox.y(0.5 * roomInfoBox.height());
    }
    group.add(nodeGroup);
  }

  generateNodes(connectors, targets, group, floor) {
    connectors.forEach((connect) => {
      var line = new Konva.Line({
        id: connect.id,
        fill: 'transparent',
        points: [],
      });
      group.add(line);
    });

    floor.rooms.forEach((room) => {
      const roomTargets = targets.filter((target) => target.roomObj.id === room.id);
      // const roomBorder = this.generateRoomBorder(roomTargets);
      // group.add(roomBorder);
      const roomPipes = this.generateRoomPipe(roomTargets, floor);
      roomPipes.forEach((pipe) => {
        group.add(pipe);
      });
      roomTargets.forEach((target) => {
        this.generateRadiatorBox(target, group, floor);
      });
    });
  }

  generateRoomPipe(targets, floor) {
    // this function assumes there is only one pipe per room
    const rowInRoom = new Set(targets.map((target) => target.rowInRoom));
    const numRows = rowInRoom.size;
    const pipeLines = [];
    const rowPadding = 10;
    // assumes only one pipe per room
    const pipe = targets[0].roomObj.pipework && targets[0].roomObj.pipework[0];
    let pipeColor = this.colors['grey-purple'];
    if (pipe) {
      pipeColor = this.pipeworkSizeColor[pipe.internalDiameter];
    }
    for (let i = 0; i < numRows; i++) {
      const targetsInRow = targets.filter((target) => target.rowInRoom === i);
      const xCoords = targetsInRow.map((target) => target.x);
      const minX = Math.min(...xCoords);
      const maxX = Math.max(...xCoords) + this.radWidth + this.rectSpacing;
      const maxY = Math.max(...targetsInRow.map((target) => target.y)) + this.radHeight + rowPadding;
      const pipeGroup = new Konva.Group({});
      const pipeLine = KonvaUtils.generateSolidLine(minX, maxY, maxX, maxY, pipeColor, 3);
      if (pipe) {
        const pipeLabel = new Konva.Text({
          text: pipe.internalDiameter + ' ' + pipe.units.internalDiameter + ' ' + pipe.material,
          fontSize: 12,
          y: maxY,
          x: minX,
          fontStyle: 'regular',
          fontFamily: 'Open Sans',
          fill: this.colors['dark'],
          padding: 5,
          align: 'center',
        });

        pipeGroup.add(pipeLabel);
      }
      pipeGroup.add(pipeLine);
      pipeLines.push(pipeGroup);
      if (this.isEditing && this.isInstaller) {
        pipeGroup.on('mouseenter', () => {
          this.stage.container().style.cursor = 'pointer';
        });
        pipeGroup.on('mouseleave', () => {
          this.stage.container().style.cursor = 'default';
        });
        pipeGroup.on('pointerup', () => {
          this.openAddRoomModal(targets[0].roomObj, floor);
        });
      }
    }
    return pipeLines;
  }

  generateRoomBorder(roomTargets) {
    const roomGroup = new Konva.Group({});
    const cornerCoords = KonvaUtils.getCorners(roomTargets, this.radWidth, this.radHeight);
    const edges = KonvaUtils.getEdgeCoordsFromCorners(cornerCoords);
    roomGroup.addName('roomBorder');
    const roomBorder = new Konva.Shape({
      fill: this.colors['light-success'],
      stroke: this.colors['purple'],
      strokeWidth: 1,
      draggable: false,
      sceneFunc: function (context) {
        context.beginPath();
        context.moveTo(edges[0][0][0], edges[0][0][1]);
        edges.forEach((edge) => {
          context.lineTo(edge[1][0], edge[1][1]);
        });
        context.closePath();
        // Konva specific method
        context.fillStrokeShape(this);
      },
    });
    roomGroup.add(roomBorder);
    return roomGroup;
  }

  generateDashedLinesBetweenFloors() {
    const floors = Object.values(this.drawingAttributes.floors);
    floors.forEach((floor: any, index) => {
      if (index === floors.length - 1) {
        const topBorder = KonvaUtils.generateSolidLine(0, floor.y(), floor.width(), floor.y(), this.colors['purple'], 2);
        this.layer.add(topBorder);
      }
      if (index !== floors.length - 1) {
        const line = KonvaUtils.generateDashedLine(0, floor.y(), floor.width(), floor.y(), this.colors['purple']);
        this.layer.add(line);
      }
    });
  }

  alignRadGroup(radGroup, groupW) {
    if (this.shouldBreak('xl')) {
      KonvaUtils.horizontalAlignCenter(radGroup, groupW / 2);
    } else {
      KonvaUtils.horizontalAlignRight(radGroup, groupW - 2 * this.rectSpacing);
    }
  }

  alignPipe(pipe, groupW) {
    if (this.shouldBreak('xl')) {
      KonvaUtils.horizontalAlignCenter(pipe, groupW / 2);
    }
  }

  alignFloorTitle(titleGroup, groupW) {
    if (this.shouldBreak('xl')) {
      KonvaUtils.horizontalAlignCenter(titleGroup, groupW / 2);
    } else {
      KonvaUtils.horizontalAlignLeft(titleGroup, this.rectSpacing);
    }
  }

  alignAddRoomButton(buttonGroup, groupW) {
    if (this.shouldBreak('xl')) {
      KonvaUtils.horizontalAlignCenter(buttonGroup, groupW / 2);
    } else {
      KonvaUtils.horizontalAlignLeft(buttonGroup, this.rectSpacing);
    }
  }

  alignHsAndWcDescription(heatingSystemDescription, waterCylinderDescription) {
    if (!this.shouldBreak('xl')) return;
    if (this.shouldBreak('lg')) {
      const maxW = Math.max(heatingSystemDescription.width(), waterCylinderDescription.width());
      heatingSystemDescription.children[0].width(maxW);
      heatingSystemDescription.width(maxW);
      heatingSystemDescription.x(0);
      // KonvaUtils.horizontalAlignCenter(heatingSystemDescription, this.layerWidth / 2);
      if (waterCylinderDescription) {
        waterCylinderDescription.y(heatingSystemDescription.y() + heatingSystemDescription.height() + 5);
        //extend background size
        waterCylinderDescription.children[0].width(maxW);
        waterCylinderDescription.width(maxW);
        waterCylinderDescription.x(0);
        // KonvaUtils.horizontalAlignCenter(waterCylinderDescription, this.layerWidth / 2);
      }
    }
  }

  alignSchematicDescription(heatingSystemAndWaterCylinderGroup, buildingDescription, addFloorButton) {
    const hsWcXPos = heatingSystemAndWaterCylinderGroup.x();
    const isOutside = hsWcXPos + heatingSystemAndWaterCylinderGroup.width() + this.rectSpacing >= this.layerWidth;
    if (!this.shouldBreak('xl') && !isOutside) return;

    if (this.shouldBreak('lg')) {
      this.resizeHsAndWcDescription(heatingSystemAndWaterCylinderGroup, buildingDescription);
      KonvaUtils.horizontalAlignCenter(buildingDescription, this.layerWidth / 2);
      if (addFloorButton) {
        KonvaUtils.horizontalAlignCenter(addFloorButton, this.layerWidth / 2);
      }
    }
    if (isOutside || this.shouldBreak('lg')) {
      heatingSystemAndWaterCylinderGroup.y(buildingDescription.y() + buildingDescription.height() + 10 + ((addFloorButton && addFloorButton.height() + 5) || 0));

      heatingSystemAndWaterCylinderGroup.x(buildingDescription.x());
    }
  }

  resizeHsAndWcDescription(heatingSystemAndWaterCylinderGroup, buildingDescription) {
    let maxW = Math.max(buildingDescription.width(), heatingSystemAndWaterCylinderGroup.width());
    if (maxW > this.layerWidth) {
      maxW = this.layerWidth - this.rectSpacing;
    }
    const rectChildren = heatingSystemAndWaterCylinderGroup.children
      .filter((child) => child.getClassName() === 'Group')
      .map((group) => group.children)
      .flat()
      .filter((child) => child.getClassName() === 'Rect');
    const rectChildrenBuildDesc = buildingDescription.children.filter((child) => child.getClassName() === 'Rect');

    rectChildrenBuildDesc.forEach((rect) => {
      rect.width(maxW);
    });
    rectChildren.forEach((rect) => {
      rect.width(maxW);
    });
    heatingSystemAndWaterCylinderGroup.width(maxW);
    buildingDescription.width(maxW);
  }

  openAddRoomModal(room, floor) {
    const addRadiatorModal = this.modalService.open(AddRadiatorModalComponent, {
      scrollable: true,
      // backdrop: 'static',
      centered: true,
      size: 'xl',
    });
    addRadiatorModal.componentInstance.room = room;
    addRadiatorModal.componentInstance.floor = floor;
    addRadiatorModal.result.then((result) => {
      if (result.msg && result.msg === 'deleteRoom') {
        this.schematicService.removeRoom(this.schematic, this.schematicId, room, floor);
      } else if (result.radiators || result.height) {
        this.schematicService.updateRoom(this.schematicDocument, this.schematicId, result, floor);
      }
    });
  }

  openAddHeatingSystemModal() {
    const addHeatingSystemModal = this.modalService.open(AddHeatingSystemModalComponent, {
      scrollable: true,
      // backdrop: 'static',
      centered: true,
      size: 'xl',
    });
    addHeatingSystemModal.componentInstance.heatingSystem = this.schematic.heatingSystem;
    addHeatingSystemModal.result.then((result) => {
      if (result && result.heatingSystem && result.domesticHotWater) {
        const heatingSystem = { ...result.heatingSystem, ...result.domesticHotWater };
        this.schematicService.updateHeatingSystem(this.schematicDocument, this.schematicId, heatingSystem);
      }
    });
  }
}
