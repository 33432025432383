import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DateFormatService } from 'src/app/services/date-format.service';
import { GlowService } from 'src/app/services/glow.service';

declare var require: any
var FileSaver = require("file-saver");

@Component({
  selector: 'app-temperature-sensor-data-table',
  templateUrl: './temperature-sensor-data-table.component.html',
  styleUrls: ['./temperature-sensor-data-table.component.scss']
})

export class TemperatureSensorDataTableComponent implements OnInit {

  @Input() pageTitle = ""
  @Input() currentPage = ""
  @Input() sensorVEList: any
  @Input() innerWidth;
  @Output() selectResourceToDownload = new EventEmitter()

  constructor(private glowService: GlowService,
    private dateFormatService: DateFormatService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => {
      this.pageTitle = params && params.sensor && params.sensor.replace("-", " ")
      this.currentPage = this.pageTitle
    });
  }

  ngOnInit() {
    if (this.sensorVEList) {
      this.processSensorData()
    }
 
  }

  ngOnChanges() {
    if (this.sensorVEList) {
      this.processSensorData()
    }
  }

  processResourceDownload(id) {
    this.selectResourceToDownload.emit(id)
  }

  processSensorData() {


    this.sensorVEList.map(async ve => {
      ve.resources = await ve.resources.sort((a, b) => a.name.localeCompare(b.name))
      ve.resources.map((x, i) => { if (x.classifier == "illuminance") { ve.resources.splice(i, 1); } })
    })
  }

  async getResourceDownloadData(resource,) {

    // Get last 10 days
    var from = new Date();
    const fromDate = this.dateFormatService.apiTimeFromFormat(new Date(from.setDate(from.getDate() - 11)));

    var to = new Date();
    const toDate = this.dateFormatService.apiTimeToFormat(new Date(to.setDate(to.getDate() - 1)));

    this.glowService.getResourceReadings(resource.resourceId, fromDate, toDate, 'PT30M').subscribe(response => {
      if (response && response.data) {
        this.downloadResult(response)
      }
    })
  }

  downloadResult(resource) {
    var date = new Date();
    var dateString = date.toISOString().split(".");
    var dateInFileName = dateString[0];
    var reportName = resource.name.replace(',', '');
    var filename = `${reportName}-${dateInFileName}.csv`;
    var csvContent = toCSV(resource.data, resource.units);
    var blob = new Blob([csvContent], { type: "text/json" });
    FileSaver.saveAs(blob, filename);
  }

}

function toCSV(json, units) {
  var jsonVal = Object.keys(json).map(function (e) { return json[e] })
  var csv = "";
  csv += ['timestamp', units, 'epoch'].join(",") + "\n";
  for (var line of jsonVal) {
    csv += (line[0] / 1000) + ","
    csv += (line[1] ? (line[1]).toFixed(3) : '') + ","
    csv += epochToIso(line[0]) + '\n'
  }
  return csv;
}

function epochToIso(epoch) {
  let newDate = new Date(epoch)
  let formattedDate = newDate.toISOString().split('T')
  let result = formattedDate[0] + ' ' + formattedDate[1].split('.')[0]
  return result
}