import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SearchService } from 'src/app/services/search.service';
import _ from 'underscore';
import { Utils } from 'src/utils';
import { StrapiService } from 'src/app/services/strapi.service';

@Component({
  selector: 'app-community-homepage-content',
  templateUrl: './community-homepage-content.component.html',
  styleUrls: ['./community-homepage-content.component.scss'],
})
export class CommunityHomepageContentComponent implements OnInit {
  @Input() community: any;

  loading = true;
  allContent: any[] = [];
  communityTags: any[] = [];
  categories: any[] = [];
  audienceOptions = [
    { value: 'Beginner', label: 'I am a novice - show me the basics' },
    { value: 'Intermediate', label: 'I am an amateur - show me more details' },
    { value: 'Advanced', label: 'I am well seasoned - show me the nitty gritty' },
  ];
  tags = [];
  tagsCopy = [];

  filterForm: FormGroup;
  constructor(private fb: FormBuilder, private searchService: SearchService, private strapiService: StrapiService) {
    this.initFilterForm();
  }

  ngOnInit() {}

  ngOnChanges() {
    this.loading = true;
    this.initFilterForm();
    this.allContent = [];
    this.getCategories(this.community).then((c) => {
      this.categories = c;
      this.getTags(this.community);
      this.loading = false;
    });
  }
  ngAfterContentInit() {}

  async getCategories(community: any) {
    const categories = [];
    for (let i = 0; i < community.attributes.audience_categories.data.length; i++) {
      const c = community.attributes.audience_categories.data[i];
      const cat = await this.strapiService.getContentBySlug('audience-categories', c.attributes.slug, ['articles', 'blogs', 'infographics', 'resources_lists']);
      categories.push(cat);
    }
    const sortedCategories = categories.sort((a: any, b: any) => a.attributes.order - b.attributes.order);
    return sortedCategories;
  }

  initFilterForm() {
    this.filterForm = this.fb.group({
      audience: [''],
      tagFilters: ['', [Validators.required]],
    });
  }

  getAvailableAudienceOptions() {
    if (this.community) {
      const communityTagTitles = this.community.attributes.tags.data.map((tag) => tag.attributes.title);
      const availableAudienceOptions = this.audienceOptions.filter((option) => {
        return communityTagTitles.includes(option.value);
      });
      return availableAudienceOptions;
    }
    return [];
  }

  async filterAudience() {
    const audience = this.filterForm.value.audience;
    if (!audience) return;
    const audienceTag = this.audienceOptions.find((option) => option.label === audience).value;
    const tags = this.getTagFilters();
    if (tags.includes(audienceTag)) return;
    const tagsToFilter = [...tags, audienceTag, this.community.attributes.name];
    const results = await this.searchService.filterAllContentWithTags('community', '', tagsToFilter);
    this.allContent = this.formatSearchResultsToDisplay(results);
  }

  async removeAudienceFilter() {
    const audience = this.filterForm.value.audience;
    if (!audience) return;
    const audienceTag = this.audienceOptions.find((option) => option.label === audience).value;
    const tags = this.getTagFilters();
    const tagsToFilter = tags.filter((tag) => tag !== audienceTag);
    const results = await this.searchService.filterAllContentWithTags('community', '', tagsToFilter);
    this.filterForm.patchValue({
      audience: '',
    });
    this.allContent = this.formatSearchResultsToDisplay(results);
  }

  addTagFilter(tag) {
    this.loading = true;
    if (tag.attributes && tag.attributes.title && !this.getTagFilters().includes(tag.attributes.title))
      this.filterForm.patchValue({
        tagFilters: this.filterForm.value.tagFilters + ',' + tag.attributes.title,
      });
  }

  removeTagFilter(tag) {
    const tags = this.getTagFilters();
    const tagsToFilter = tags.filter((t) => t !== tag);
    this.filterForm.patchValue({
      tagFilters: tagsToFilter.join(','),
    });
  }

  async removeTagAndFilter(tag) {
    this.addTagToList(tag);
    this.removeTagFilter(tag);
    if (this.filterForm.valid) {
      await this.filterTag(tag);
    } else this.allContent = [];
    this.loading = false;
  }

  async addTagAndFilter(e, tag) {
    this.removeTagFromList(tag);
    this.addTagFilter(tag);
    await this.filterTag(tag);
    this.loading = false;
  }
  // removes tags from list when clicked

  removeTagFromList(tag) {
    const index = this.tags.findIndex((t) => t === tag);
    this.tags.splice(index, 1);
  }

  getTags(community) {
    let tags = [];
    community.attributes.tags.data.forEach((t) => tags.push(t));
    this.tags = tags;
    this.tagsCopy = [...tags];
  }
  // adds tag back to list after removing
  addTagToList(tagName) {
    const index = this.tagsCopy.findIndex((tag) => tagName === tag.attributes.title);
    const tag = this.tagsCopy.find((tag) => tagName === tag.attributes.title);
    this.tags.splice(index, 0, tag);
  }
  async filterTag(tag) {
    const tags = this.getTagFilters();
    const audienceTag = this.getAudienceTag();
    if (!tags.length && !audienceTag) {
      this.allContent = this.getSortedContent(this.community);
      return;
    }
    let tagsToFilter = [...tags, this.community.attributes.name];
    if (audienceTag && !tags.includes(audienceTag)) {
      tagsToFilter = [...tagsToFilter, audienceTag];
    }

    const results = await this.searchService.filterAllContentWithTags('community', '', tagsToFilter);

    this.allContent = this.formatSearchResultsToDisplay(results);
    this.loading = false;
  }

  getAudienceTag() {
    const audience = this.filterForm.value.audience;
    if (!audience) return null;
    const audienceTag = this.audienceOptions.find((option) => option.label === audience).value;
    return audienceTag;
  }

  getTagFilters() {
    //get tags as arrays, remove empty strings
    const tags = this.filterForm.value.tagFilters.split(',').filter((tag) => tag);
    return tags;
  }

  formatSearchResultsToDisplay(results) {
    if (!(results && results.length)) return [];
    let formattedContents = [];
    results.forEach((content) => {
      const formattedContent = Utils.fromatSearchContent(content);
      formattedContents.push(formattedContent);
    });
    return formattedContents;
  }

  getSortedContent(community) {
    const blogs = (community.attributes.blogs && community.attributes.blogs.data.map((blog: any) => Object.assign(blog, { contentType: 'blogs' }))) || [];
    const articles = (community.attributes.articles && community.attributes.articles.data.map((article: any) => Object.assign(article, { contentType: 'articles' }))) || [];
    const resourceList =
      (community.attributes.resources_lists && community.attributes.resources_lists.data.map((resourceList: any) => Object.assign(resourceList, { contentType: 'resources-lists' }))) || [];

    const tutorials = community.attributes.tutorials ? community.attributes.tutorials.data.map((tutorial: any) => Object.assign(tutorial, { contentType: 'tutorials' })) : [];
    const infographics = (community.attributes.infographics && community.attributes.infographics.data.map((infographic: any) => Object.assign(infographic, { contentType: 'infographics' }))) || [];

    const sorted = [...blogs, ...articles, ...resourceList, ...tutorials, ...infographics].sort((a: any, b: any) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()).reverse();

    return sorted;
  }

  getShuffledContent(community) {
    const blogs = community.attributes.blogs.data.map((blog: any) => Object.assign(blog, { contentType: 'blogs' }));
    const articles = community.attributes.articles.data.map((article: any) => Object.assign(article, { contentType: 'articles' }));
    const resourceList = community.attributes.resources_lists.data.map((resourceList: any) => Object.assign(resourceList, { contentType: 'resourceLists' }));
    const infographics = community.attributes.infographics.data.map((infographics: any) => Object.assign(infographics, { contentType: 'infographics' }));

    return _.shuffle([...blogs, ...articles, ...resourceList, ...infographics]);
  }
}
