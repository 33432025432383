import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TermsconditionsModalComponent } from 'src/app/components/termsconditions-modal/termsconditions-modal.component';

@Component({
  selector: 'app-bright',
  templateUrl: './bright.component.html',
  styleUrls: ['./bright.component.scss']
})
export class BrightComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  openModal(){
    const termsconditionsRef = this.modalService.open(TermsconditionsModalComponent, {
      scrollable: true,
      centered: true,
      size: 'xl',
    });
  termsconditionsRef.componentInstance.options = {
    allowModalToExit: true,
    showPermissions: true,
    requireAccept: true,
    referenceKey: 'DfsTermsConditions',
    };
    termsconditionsRef.componentInstance.title = 'Delight'
  }
  
}
