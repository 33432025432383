import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlowService } from 'src/app/services/glow.service';
import { HpJourneyService } from 'src/app/services/hp-journey.service';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';


@Component({
  selector: 'app-hp-journey-stepper',
  templateUrl: './hp-journey-stepper.component.html',
  styleUrls: ['./hp-journey-stepper.component.scss']
})
export class HpJourneyStepperComponent implements OnInit {
  loading: boolean
  steps;
  hasPassport;
  @Input() currentStep: Number = 1;
  selectedService: any
  stepsSubscription: Subscription
  hasCompletedSchematics: boolean
  innerWidth: Number
  @Output() step: EventEmitter<any> = new EventEmitter();
  @HostListener('window:resize', []) onResize() {
    this.innerWidth = window.innerWidth;
  }
  constructor(private hpJourneyService: HpJourneyService) {
    this.stepsSubscription = this.hpJourneyService.stepsAnnouncer.subscribe(steps => {
      this.steps = steps


    })
  }

  ngOnInit() {
    this.onResize()
    this.setSteps()

  }


  async setSteps() {
    this.loading = true
    this.hasPassport = await this.hpJourneyService.getPropertyPassport()
    this.hasCompletedSchematics = await this.hpJourneyService.getSchematicDocuments()
    // get last estimate request and prefill forms
    this.loading = false


  }
  showStep(step) {
    this.currentStep = step
    this.step.emit(step)
  }

  getStyle(completion, icon?) {
    if (completion) {
      return `text-success ${icon ? icon : ''} `
    } else return `text-dark fw-bolder ${icon ? icon : ''} `
  }

  getCurrentStep() {
    return this.steps.filter(step => step.step == this.currentStep)

  }

}
