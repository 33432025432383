import { ChangeDetectorRef, Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { th } from 'date-fns/locale';
import { LandingSearchbarComponent } from 'src/app/components/landing-searchbar/landing-searchbar.component';
import { StrapiService } from 'src/app/services/strapi.service';
import { Utils } from 'src/utils';
import _ from 'underscore';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent implements OnInit {
  @ViewChild('searchBar', { static: true }) searchBar: LandingSearchbarComponent;
  @HostListener('window:resize', []) onResize() {
    // checks size of window to view specific menu
    this.innerWidth = window.innerWidth;
  }
  loading: boolean = true;
  innerWidth = window.innerWidth;

  results: any;
  allContent: any[];
  invalidQuery: boolean = true;
  displayableTypes = ['resources-lists', 'articles', 'blogs', 'tutorials', 'infographics'];
  professionalTagSlugs = ['professional', 'installer'];
  tags: any[] = [];
  availableTags: any[] = [];
  qFormatted: any;
  constructor(
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef,
    private strapiService: StrapiService,
    private router: Router
  ) {
    route.queryParams.subscribe(async (params) => {
      if (typeof params['q[tags]'] === 'string' && typeof params['q[s]'] === 'string') {
        this.invalidQuery = false;
        const tagsAsArray = params['q[tags]'].split(',');
        const qFormatted = { label: params['q[s]'] };
        this.qFormatted = qFormatted;
        const allTags = (await this.strapiService.getAllOfContentType('tags')).filter((tag) => !this.professionalTagSlugs.includes(tag.attributes.slug));
        this.tags = allTags;
        this.availableTags = allTags.filter((tag) => !tagsAsArray.includes(tag.attributes.title));
        Utils.waitForElemById('searchBar').then((el) => {
          this.searchBar.setSearchParameters([...tagsAsArray, qFormatted]);
          this.searchBar.searchAllContent();
        });
      }
    });
  }

  ngOnInit() {}

  processSearchParameters(event) {
    // console.log("processSearchParameters", event)
    if (event.tagsToFilter.length && event.tags.length) {
      this.availableTags = this.tags.filter((tag) => !event.tagsToFilter.includes(tag.attributes.title));
    } else {
      this.availableTags = this.tags;
    }
  }

  addTag(event, tag) {
    const searchParameters = Array.from(
      new Set([...this.searchBar.searchParametersForm.value.searchParameters, tag.attributes.title, this.qFormatted])
    );
    this.searchBar.setSearchParameters(searchParameters);
    this.searchBar.focusSearchBar();
  }

  formatSearchResultsToDisplay(results) {
    let formattedContents = [];
    results.forEach((content) => {
      const formattedContent = Utils.fromatSearchContent(content);
      formattedContents.push(formattedContent);
    });
    return formattedContents;
  }

  async displaySearchResults(results) {
    this.loading = true;
    this.results = { ...results };
    if (results.searchTerm || results.tagsToFilter.length) {
      const formattedResults = this.formatSearchResultsToDisplay(results.results).filter((content) =>
        this.displayableTypes.includes(content.contentType)
      );
      this.allContent = formattedResults.sort(
        (a: any, b: any) => new Date(b.attributes.createdAt).valueOf() - new Date(a.attributes.createdAt).valueOf()
      );
    }
    this.loading = false;
    this.ref.markForCheck();
  }
}
