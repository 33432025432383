import { Component, Input, OnInit } from '@angular/core';
import { GlowService } from 'src/app/services/glow.service';
import { InvitationsService } from 'src/app/services/invitations.service';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';

@Component({
  selector: 'app-estimate-request-card',
  templateUrl: './estimate-request-card.component.html',
  styleUrls: ['./estimate-request-card.component.scss']
})
export class EstimateRequestCardComponent implements OnInit {
  @Input() er
  @Input()passport
  @Input()invitationForm;
  loading;
  @Input()lastYear
  @Input()lastMonth
  respondToInviteError

  constructor(private invitationsService: InvitationsService, private ServiceSelectorSvc: ServiceSelectorSvc, private glowService: GlowService) { }

  ngOnInit() {
  }
  checkCanAcceptRequest() {
    return (!this.invitationForm.controls.installationDate.valid && !this.invitationForm.controls.spaceHotWaterTank.valid
      && !this.invitationForm.controls.newRadiatorsInstalled.valid && this.passport && !this.loading ||
      !this.invitationForm.controls.installationDate.valid && !this.invitationForm.controls.spaceHotWaterTank.valid
      && !this.invitationForm.controls.newRadiatorsInstalled.valid && !this.passport && !this.loading || this.loading)

  }
  getEstimateRequestStateFormat(state) {
    return this.invitationsService.formatEstimateRequestState(state)
  }

  acceptEstimateRequest(estimateRequestId) {
    if (this.passport && this.invitationForm.controls.newRadiatorsInstalled.valid && this.invitationForm.controls.spaceHotWaterTank.valid && this.invitationForm.controls.installationDate.valid) {
      this.updateEstimateRequest(estimateRequestId)
    }
    // TO DO: error handle
  }
  updateEstimateRequest(estimateRequestId) {
    this.loading = true;
    const selectedService = this.ServiceSelectorSvc.getSelectedService()
    const body = {
      estimateRequestState: 'estimate-request-accepted-by-user',
      assessmentPeriodStart: this.lastYear,
      assessmentPeriodEnd: this.lastMonth,
      htc: this.invitationForm.value.htc,

      overview: {
        hotWaterTankSpace: this.invitationForm.value.spaceHotWaterTank == 'Yes' ? true : false,
        newRadiatorsFitted: this.invitationForm.value.newRadiatorsInstalled == 'Yes' ? true : false,
        installationDate: this.invitationForm.value.installationDate,
      },
      heatingSystem: {
        veDocId: this.invitationForm.value.radiatorSchematic.documentId
      },
    };
    this.glowService.updateEstimateRequest(body, estimateRequestId, selectedService.veId).subscribe((resp) => {
      if (!resp.valid) {
        this.respondToInviteError = true
        this.loading = false
        this.getEstimateRequests()
      } else {

        this.getEstimateRequests()
        this.loading = false
      }
    }, (err) => {
      this.respondToInviteError = true
      this.loading = false
    });
   }

   denyEstimateRequest(estimateRequestId) {
    this.loading = true

    const selectedService = this.ServiceSelectorSvc.getSelectedService()
    this.glowService.declineEstimateRequest(estimateRequestId, selectedService.veId).subscribe(resp => {
      if (resp.valid) {
        console.log(resp)
        this.getEstimateRequests()
      } else {
        this.respondToInviteError = true
        this.loading = false
      }
    }, (err) => {
      this.respondToInviteError = true
      this.loading = false


    })

  }
  getEstimateRequests() {
    const selectedService = this.ServiceSelectorSvc.getSelectedService()
    this.invitationsService.getEstimateRequestsByVeIdAndState(selectedService.veId, true)

  }
  
}
