export class tAccount {
  accountId: string;
  username: string;
  name: string;
  email: string;
  password: string;
  directoryId: string;
  createdAt: string;
  applicationId: string;
}

export class tAccountProfile {
  profileName: string;
  data: any;
}

export class tAccountProfilesResp {
  accountId: string;
  profileNames: string[];
}

export class tAccountProfileCreateResp {
  status: string;
  devUserId: string;
  profileName: string;
}

export class tAccountStatus {
  accountId: string;
  password: string;
  directoryId: string;
  createdAt: string;
  applicationId: string;
  directory: tExternalDirectoryStatus;
}

export class tAlertHistoryUser {
  alertTypeId: string;
  userId: string;
  AlertTime: Date;
  Timestamp: Date;
  email: string;
  inbox: string;
  push: string;
}

export class tAlertHistoryUserGroup {
  alertTypeId: string;
  userGroupId: string;
  AlertTime: Date;
  Timestamp: Date;
  email: string;
  inbox: string;
  push: string;
}

export class tAlertStatus {
  ns_alertid: string;
  status: string;
}

export class tAlertType {
  alertTypeId: string;
  applicationId: string;
  name: string;
  systemAlertTypeId: string;
  createdAt: Date;
  updatedAt: Date;
  description: string;
}

export class tApiErrorResponse {
  isError: boolean;
  messages: string[];
  data: any;
}

export class tApplication {
  applicationId: string;
  name: string;
  description: string;
  status: string;
  createdAt: string;
}

export class tApplicationEventReq {
  eventType: string;
  eventName: string;
  data: any;
  veId: string;
  veTypeId: string;
  systemName: string;
}

export class tApplicationEventRes {
  eventId: string;
  status: string;
}

export class tApplicationVersion {
  operatingSystem: string;
  version: string;
  minimumVersion: string;
}

export class tApplicationVersions {
  applicationId: string;
  name: string;
  version: tApplicationVersion[];
}

export class tAsset {
  assetId: string;
  assetTypeId: string;
  category: string;
  hardwareIds: any;
  hardwareIdTags: string[];
  hardwareIdNames: string[];
  name: string;
  productId: string;
  productTypeId: string;
}

export class tCaseStudy {
  csId?: string;
  csRef?: number;
  userId?: string;
  veId: string;
  classifier: string;
  filterAttributes?: object;
  userForumHandle?: string;
  canContactOwner: boolean;
  canVisitProperty: boolean;
  summary?: string;
  postcode?: string;
  publishedStatus: string
  before?: any[];
  after?: any[];
  outcome?: any[];
  mediaFiles?: any[];
}

export class tChangeUsernameReq {
  username: string;
  password: string;
}

export class tComponent {
  componentId: string;
  userId: string;
  veId: string;
  componentVersion: number;
  installedAt: Date;
  decommissionedAt?: Date;
  data: any;
  mediaFiles?: any[];
}

export class tContent {
  contentId: string;
  applicationId: string;
  environment: string;
  version: string;
  minimumVersion: string;
  reference: string;
  data: any;
}

export class tCreateDevices {
  status: string;
}

export class tDataGroupCreateReq {
  userOrganizationId: string;
  userGroupId: string;
  'name': string;
  'description': string;
  'groupCriteria': string;
  'groupCriteriaValue': string;
}

export class tDeviceType {
  deviceTypeId: string;
  name: string;
  description: string;
  status: string;
  createdAt: string;
  configuration: any;
}

export class tDevice {
  deviceId: string;
  deviceTypeId: string;
  hardwareId: string;
  parentHardwareId: string[];
  ownerId: string;
  protocol: {
    protocol: string;
    sensors: tSensorListing[];
  };
  name: string;
  description: string;
  status: string;
  createdAt: string;
  configuration: any;
  resources: any;
}

export class tDeviceWithSetting extends tDevice {
  setting: any;
}

export class tDeviceProductDiscovery {
  assetTypeId: string;
  deviceId: string;
  deviceTypeId: string;
  discoveredEntity: string;
  productId: string;
  productTypeId: string;
  configuration: {
    wifiPassword: string;
  };
}

export class tDeviceStatus {
  deviceId: string;
  hardwareId: string;
  timestamp: string;
  status: any;
}

export class tDirectory {
  directoryId: string;
  applicationId: string;
  directoryTypeId: string;
  name: string;
  description: string;
  status: string;
  createdAt: string;
}

export class tDiscoveryInfo {
  MAC: string;
  serialNumber: string;
}

export class tEligibitySmetsIhdPostcodeCheck {
  postcode: string;
  eui: string;
}

export class tEligibitySmetsFuelTypesReq {
  mpxn: string;
}

export class tEligibitySmetsFuelTypes {
  hasElectricityMeter: boolean;
  hasGasMeter: boolean;
}

export class tExternalDirectoryStatus {
  data: {
    tokenIssueDate: Date;
    tokenExpiryDate: Date;
  };
}

export class tEuiPostcode {
  eui: string;
  postcode: string;
}

export class tFAQ {
  reference: string;
  faqId: string;
  data: tFAQelem[];
}

export class tFAQelem {
  question: string;
  reply: string;
}

export class tForgottenPasswordResponse {
  valid: boolean;
  authenticationTypeService: 'string';
}

export class tAddAccountResponse {
  status: string;
  accountId: string;
  userId: string;
  applicationId: string;
  isNew: boolean;
}

export class tFunctionalGroupAccount {
  accountId: string;
  applicationId: string;
  functionalGroupId: string;
  functionalGroupAccountId: string;
  createdAt: string;
  updatedAt: string;
}

export class tFunctionalGroupAccountIdElement {
  functionalGroupAccountId: string;
}

export class tFunctionalGroupIdListing {
  functionalGroupId: string;
}

export class tFunctionalGroupListing extends tFunctionalGroupIdListing {
  name: string;
}

export class tGeoCoordinates {
  point: LatLngElement;
  boundingBox: {
    southWest: LatLngElement;
    northEast: LatLngElement;
  };
}

export class tGlowEventReq {
  categoryReference: string;
  data: object;
  event: string;
}

export class tGlowStickAccessPointPassword {
  accessPointPassword: string;
}

export class tInboxDeleteStatus {
  status: string;
  Deleted: string;
}

export class tInboxMessage {
  MessageId: string;
  Timestamp: Date;
  ReadStatus: string;
  applicationId: string;
  Priority: string;
  Attributes: string;
  Title: string;
  TruncatedBody: string;
}

export class tInboxMessageList {
  status: string;
  MoreMessagesAvailable: boolean;
  Messages: tInboxMessage[];
}

export class tInboxStatus {
  status: string;
  TotalCount: number;
  UnreadCount: number;
}

export class tIsValid {
  valid: boolean;
}

export class tHasVeAccess extends tIsValid {
  veId: boolean;
}

export class tHasVeAdminAccess extends tIsValid {
  veId: boolean;
}

export class tIsValidTicket extends tIsValid {
  ticketId: string;
  type: string;
}

export class tIsValidPaymentRes extends tIsValid {
  transactionId: string;
}

export class tIsUserGroupValid extends tIsValid {
  userGroupId: string;
}

export class tIsUserOrganizationValid extends tIsValid {
  userOrganizationId: string;
}

export class tJournalEntry {
  jeId: string;
  journalId: string;
  propertyPassportId: string;
  veId: string;
  componentId: string;
  componentVersion: string;
  componentDataType: string;
  journalEntryDataType: string;
  summary: string;
  data: any;
  relatedJournalEntryIds: string[];
  validFrom?: Date;
  validTo?: Date;
  mediaFiles?: any[];
}

export class tLanguageContent {
  contentId: string;
  applicationId: string;
  environment: string;
  version: string;
  languageCode: string;
  languageContentId: string;
  minimumVersion: string;
  reference: string;
  data: any;
}

export class LatLngElement {
  lng;
  lat;
}

export class tLoginInfo {
  username: string;
  password: string;
}

export class tLoginToken {
  valid: boolean;
  name: string;
  accountId: string;
  token: string;
  exp: Date;
}

export class tNewTokenResponse {
  exp: number;
  token: string;
  valid: boolean;
}

export class tOAuthResponse {
  valid: boolean;
  userGroups: string[];
  functionalGroupAccounts: tFunctionalGroupAccountIdElement[];
  code: string;
  accountId: string;
  name: string;
}

export class tPaymentTransaction {
  applicationId: string;
  transactionId: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
  status: string;
}

export class tPaymentTransactionCallbackInfo extends tPaymentTransaction {
  callbackInfo: any;
}
export class tSavedCards {
  data: any;
}

export class tPrompt {
  promptId: string;
  promptTypeId: string;
  reference: string;
  data: any;
  dataSourcePromptTypeInfo: any;
}

export class tPromptUser {
  promptId: string;
  promptTypeId: string;
  applicationId: string;
  data: any;
  userId: string;
  createdAt: Date;
  updatedAt: Date;
}

export class tPromptUserReq {
  promptId: string;
  data: any;
}

export class tProvisioningInfoReq {
  hardwareIds: {
    MAC: string;
    serialNumber: string;
  };
  virtualEntity: any;
}

export class tProvisioningAddChildDeviceReq {
  MAC: string;
  uniqueSID: string;
  virtualEntity: {
    veId: string;
  };
}

export class tProvisioningStatus {
  status: string;
  virtualEntity: tVE;
  resources: tResource[];
  devices: tDevice[];
}

export class tRegistrationInfo {
  username: string;
  name: string;
  email: string;
  password: string;
  directoryId: string;
  applicationId: string;
  alertTypeId: string;
}

export class tResource {
  resourceId: string;
  resourceTypeId: string;
  ownerId: string;
  name: string;
  description: string;
  status: string;
  createdAt: string;
  classifier: string;
}

export class tResourceDataBase {
  resourceId: string;
  resourceTypeId: string;
  name: string;
  data: any;
  status: string;
  classifier: string;
}

export class tResourceMeterReading extends tResourceDataBase {
  data: [[number, number]];
  units: string;
}

export class tResourceFirstTime extends tResourceDataBase {
  data: {
    firstTs: number;
  };
}

export class tResourceAddPermissionRes {
  valid: boolean;
  resourcePermissions: tResourceUserPermission[];
}

export class tResourceInstantReadings {
  name: string;
  resourceTypeId: string;
  data: [[number, number]];
  resourceId: string;
  status: string;
  classifier: string;
  units: string;
}

export class tResourceListing {
  resourceId: string;
  resourceTypeId: string;
}

export class tResourceReadings {
  resourceId: string;
  resourceTypeId: string;
  name: string;
  data: any[];
  status: string;
  units: string;
  classifier: string;
  query: {
    from: string;
    to: string;
    period: string;
    function: string;
  };
}

export class tGroupedResource {
  resourceId: string;
  resourceTypeId: string;
  name: string;
  data: any[];
  status: string;
}

export class tResourceSummaryReadings {
  resourceId: string;
  resourceTypeId: string;
  name: string;
  data: any;
  status: string;
  classifier: string;
  query: {
    from: string;
    to: string;
    period: string;
    function: string;
  };
}

export class tResourceTariff {
  resourceId: string;
  resourceTypeId: string;
  name: string;
  classifier: string;
  status: string;
  data: [
    {
      commodity: string;
      from: string;
      name: string;
      id: number;
      plan: {
        planDetail: [
          {
            rate: number;
            standing: number;
          }
        ];
      };
    }
  ];
}

export class tResourceType {
  resourceTypeId: string;
  applicationId: string;
  ownerId: string;
  name: string;
  description: string;
  status: string;
  classifier: string;
  createdAt: string;
}

export class tResourceUserPermission {
  resourceId: string;
  resourceTypeId: string;
  userId: string;
  ownerId: string;
  createdAt: string;
  updatedAt: string;
  isNew: boolean;
  valid: boolean;
  resourceAccessId: string;
}

export class tSensorListing {
  resourceId: string;
  resourceTypeId: string;
  protocolId: string;
}

export class tSetPasswordReq {
  username: string;
  newPassword: string;
  token: string;
  applicationId: string;
}

export class tSimulation {
  simulationTypeId: string;
  input: any;
}

export class tStatusResponse {
  status: string;
}

export class tSupportDetails {
  reference: string;
  supportDetailsId: string;
  data: tSupportDetailsElem[];
}

export class tSupportDetailsElem {
  availability: string;
  contactDetails: string;
  type: string;
}

export class tTemplate {
  templateId: string;
  applicationId: string;
  name: string;
  desc: string;
  active: string;
  updateDtm: string;
  alertTypeId: string;
  channelType: string;
  cultureCode: string;
  systemAlertTypeId: string;
  datapayloadTemplate: string;
}

export class tTermsConditions {
  tcId: string;
  reference: string;
  data: any;
  current: boolean;
}

export class tTermsConditionsUserRaw {
  reference: string;
  data: any;
}

export class tTermsConditionsUser extends tTermsConditionsUserRaw {
  tcId: string;
  tcUserId: string;
  userId: string;
  current: boolean;
  createdAt: string;
}

export class tTermsConditionsUserCreateRes {
  applicationId: string;
  isNew: boolean;
  reference: string;
  tcId: string;
  tcUserId: string;
  userId: string;
}

export class tTokenValidityResponse {
  valid: boolean;
  accountId: string;
  applicationId: string;
}

export class tUserAction {
  reference: string;
  applicationId: string;
  userId: string;
  userActionId: string;
  userActionTypeId: string;
  data: any;
}

export class tUserFromUserGroupDeletionRes {
  userGroupId: string;
  userId: string;
  valid: boolean;
}

export class tUserGroupAdmin {
  userGroupAdminId: string;
  userOrganizationId: string;
  userGroupId: string;
  userId: string;
  addedBy: {
    userId: string;
  };
}

export class tUserGroupAdminWithUserInfo extends tUserGroupAdmin {
  username: string;
  email: string;
  name: string;
}

export class tUserGroupAdminAdditionReq {
  usernames: tUserIdElement[];
}

export class tUserGroupAdminAdditionRes {
  valid: boolean;
  users: tUserGroupAdminAdditionResElement[];
}

export class tUserGroupAdminAdditionResElement {
  userId: string;
  valid: boolean;
  isNew: boolean;
}

export class tUserIdElement {
  userId: string;
}

export class tUserGroupAdminDeletionRes {
  userGroupId: string;
  userId: string;
  valid: boolean;
}

export class tUserGroup {
  userGroupId: string;
  userOrganizationId: string;
  name: string;
  applicationAccess: tUserGroupApplicationAccess[];
}

export class tUserGrouping {
  status: string;
  userGroupingId: string;
  userGroupId: string;
  userId: string;
  updatedAt: string;
  createdAt: string;
}

export class tUserGroupingWithUserInfo extends tUserGrouping {
  username: string;
  email: string;
  name: string;
}

export class tUserGroupCreateReq {
  userOrganizationId: string;
  name: string;
}

export class tUserGroupCreateRes extends tUserGroupCreateReq {
  userGroupId: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

export class tUserGroupAppPermissionsReq {
  applications: tUserGroupAppPermissionsReqApp[];
}

export class tUserGroupAppPermissionsReqApp {
  applicationId: string;
  functionalGroups: tFunctionalGroupIdListing[];
}

export class tUserGroupApplicationAccess {
  applicationId: string;
  functionalGroups: tFunctionalGroupListing[];
}

export class tUserGroupUserAdditionRes {
  status: string;
  userList: tUserGroupUserAdditionResElement[];
}

export class tUserGroupUserAdditionResElement {
  userId: string;
  isInGroup: boolean;
  isNew: boolean;
}

export class tUserOrgCreateReq {
  name: string;
  email: string;
}

export class tUserOrganization {
  userOrganizationId: string;
  name: string;
  userGroups: tUserGroup[];
}

export class tUserOrganizationAdmin {
  userOrgAdminId: string;
  userId: string;
  userOrganizationId: string;
  addedBy: {
    userId: string;
  };
  createdAt: string;
  updatedAt: string;
}

export class tAddUserOrganizationResponse {
  userOrgAdminId: string;
  userId: string;
  userOrganizationId: string;
  userList: {
    userId: string;
  };
  createdAt: string;
  updatedAt: string;
}

export class tVerificationInfo {
  username: string;
  password: string;
  token: string;
}

export class tVE {
  veId: string;
  veTypeId: string;
  location: string;
  openStreetMapsRef: any;
  geoCoordinates: tGeoCoordinates;
  applicationId: string;
  postalCode: string;
  ownerId: string;
  name: string;
  attributes: any;
  description: string;
  status: string;
  createdAt: string;
  resources: tResourceListing[];
  veChildren: tVEChild[];
}

export class tVeAccessAddUser {
  veId: string;
  userId: string;
}

export class tVeAccessAddUserGroup {
  veId: string;
  userGroupId: string;
  userOrganizationId: string;
}

export class tVeAccessAdditionRes {
  veId: string;
  userId: string;
  userGroupId: string;
  isNew: boolean;
  valid: boolean;
  resources: tResourceAddPermissionRes;
}

export class tVeAccessPermission {
  applicationId: string;
  ownerId: string;
  userId: string;
  userGroupId: string;
  userOrganizationId: string;
  veAccessId: string;
  veId: string;
  createdAt: string;
  updatedAt: string;
}

export class tVEChild {
  veId: string;
  veTypeId: string;
  name: string;
}

export class tVEwithDetailedResources extends tVE {
  resources: tResource[];
}

export class tVEGroupedChildListing {
  name: string;
  label: string;
  veTypeId: string;
  veChildren: tVE[];
}

export class tVEwithGroupedChildren extends tVE {
  veId: string;
  veTypeId: string;
  location: string;
  openStreetMapsRef: any;
  geoCoordinates: tGeoCoordinates;
  applicationId: string;
  ownerId: string;
  name: string;
  description: string;
  status: string;
  createdAt: string;
  resources: tResourceListing[];
  veChildren: tVEChild[];
  groupedVeChildren: tVEGroupedChildListing[];
}

export class tVEwithGroupedChildrenDetailedResources extends tVEwithDetailedResources {
  veChildren: tVEChild[];
  groupedVeChildren: tVEGroupedChildListing[];
}

export class tVEType {
  veTypeId: string;
  location: string;
  applicationId: string;
  ownerId: string;
  name: string;
  description: string;
  status: string;
  createdAt: string;
  attributes: any;
}

export class tVeState {
  reference: string;
  veId: string;
  stateTypeId: string;
  veStateId: string;
  data: any;
}

export class tVeIdListing {
  veId: string;
}

export class tTicketCreateResponse {
  ticket: any;
  valid: boolean;
}

export class tVeGroup {
  veTypeId: string;
  name: string;
  veId: string;
}

export class tDeviceBase {
  deviceId: string;
  hardwareId: string;
  timestamp: string;
}

export class tDeviceRegisters extends tDeviceBase {
  registers: any;
}

export class tHeatControlStatusConfig {
  showNavToHeatSettings: boolean;
}

export class tUserActionResponse {
  reference: string;
  applicationId: string;
  userId: string;
  userActionId: string;
  userActionTypeId: string;
}

export class tCalculateHTC {
  veId: string;
  // propertyPassportId: string;
  temperatureResources: [];
}

export class tResourceLastTime extends tResourceDataBase {
  data: {
    lastTs: number;
  };
}
export class tMeterPointVerificationElement {
  isVerified: boolean;
  isValidUntil: Date
  mpxnKey: string;
  mpxn: string;
}
export class tVerificationStatus {
  identityVerification: {
    isVerified: boolean
  }
  meterPointVerification: tMeterPointVerificationElement[]
}
export class tVerificationInfoSubmissionIsValidRes {
  verificationInfoSubmissionId: string;
  valid: boolean
}
