import { Component, HostListener, Input, OnInit } from '@angular/core';
import { StrapiService } from 'src/app/services/strapi.service';

@Component({
  selector: 'app-landing-carousel-card',
  templateUrl: './landing-carousel-card.component.html',
  styleUrls: ['./landing-carousel-card.component.scss'],
})
export class LandingCarouselCardComponent implements OnInit {
  @Input() content;
  @Input() type;
  innerWidth;
  @HostListener('window:resize', []) onResize() {
    // checks size of window to view specific menu
    this.innerWidth = window.innerWidth;
  }
  constructor(private strapiService: StrapiService) {}

  ngOnInit() {
    this.onResize();
  }

  contentRelativePath() {
    return `/home/${this.type}/${this.content.attributes.slug}`;
  }

  getDisplayImageUrl(content) {
    const url = this.strapiService.getSmallDisplayImageUrl(content);
    return { 'background-image': `url(${url})` };
  }
}
