import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtcModalComponent } from './htc-modal.component';
import { SpinnerModule } from '../spinner/spinner.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [HtcModalComponent],
  imports: [
    CommonModule,
    SpinnerModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [HtcModalComponent],
  entryComponents: [HtcModalComponent]
})

export class HtcModalModule { }