import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { GlowService } from 'src/app/services/glow.service';
import { InvitationsService } from 'src/app/services/invitations.service';
import { VirtualEntityService } from 'src/app/services/virtual-entity.service';

@Component({
  selector: 'app-all-notifications',
  templateUrl: './all-notifications.component.html',
  styleUrls: ['./all-notifications.component.scss']
})

export class AllNotificationsComponent implements OnInit {
  estimateRequests: any[] = []
  loading = true
  error
  subscriptionVirtualEntityList: Subscription
  pageTitle: string = 'Notifications'
  estimateRequestsSubscription: Subscription;
  virtualEntities
  validVEs: any[] = []
  invalidVEs: any[] = []
  missingData: boolean
  pendingEstimateRequests;
  constructor(private invitationsService: InvitationsService, private veService: VirtualEntityService, private glowService: GlowService) {
    this.loading = true
    this.estimateRequestsSubscription = this.invitationsService.EstimatesAnnouncer.subscribe((resp) => {
      console.log(resp)
      if (resp.valid) {
       this.estimateRequests = resp.estimateRequests.filter(estimateRequest => estimateRequest.estimateRequestState == 'pending-user-eligibility')
       this.pendingEstimateRequests = resp.estimateRequests.filter(estimateRequest => estimateRequest.estimateRequestState == 'pending-user-acceptance')

      } else {
        this.error = true
        // this.loading = false
      }
    })

    this.subscriptionVirtualEntityList = this.veService.virtualEntitiesForDashboard$.subscribe(async (veList) => {
      if (veList && veList.length) {
        this.processVeList(veList)
        // console.log(this.estimateRequests)
        if (this.estimateRequests && this.estimateRequests.length) {
          // console.log('validating ves')
          await this.invitationsService.validateVirtualEntitites(this.virtualEntities)

          if (this.invitationsService.validVEs.length) {
            this.validVEs = this.invitationsService.validVEs
            this.loading = false
          }
          if (this.invitationsService.invalidVEs.length) {
            this.invalidVEs = this.invitationsService.invalidVEs
            this.loading = false
          }

        } else {
          this.loading = false
        }

      } else{ 
        this.processVeList(veList)
        this.loading = false
      }
    });
  }

  ngOnInit() {
    this.getEstimateRequests()
    this.veService.findVirtualEntities()
  }



  getEstimateRequests() {
    this.invitationsService.getAllEstimateRequests()

  }

  processVeList(veList) {
    // console.log(veList, 'velist')
    this.virtualEntities = veList;
  }

}


