import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component';
import { GlowService } from 'src/app/services/glow.service';
import { InvitationsService } from 'src/app/services/invitations.service';
import { PdfService } from 'src/app/services/pdf.service';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-received-invitations',
  templateUrl: './received-invitations.component.html',
  styleUrls: ['./received-invitations.component.scss']
})
export class ReceivedInvitationsComponent implements OnInit {
  pageTitle = 'Received invitations'
  estimateRequests: any
  loading: boolean = true;
  error: boolean
  estimatesSubscription: Subscription
  constructor(private invitationsService: InvitationsService, private glowService: GlowService, private pdfService: PdfService, private modalService: NgbModal) {
    this.estimatesSubscription = this.invitationsService.ProfessionalEstimatesAnnouncer.subscribe((resp) => {
      if (resp.error) {
        this.loading = false
        this.error = true
      } else if (!resp.valid) {
        this.loading = false
        this.estimateRequests = []
      }

      else {
        this.estimateRequests = resp.estimateRequests.filter(e => e.initiatedBy === 'customer')
        // console.log(this.estimateRequests)
        if (this.estimateRequests) {
          this.loading = false
        }
      }
      this.loading = false
    })

  }

  ngOnInit() {
    this.invitationsService.getAllProfessionalEstimateRequests()

  }
  ngOnDestroy() {
    if (this.estimatesSubscription) {
      this.estimatesSubscription.unsubscribe()
    }
  }
  getReadableDateFromIso(string) {
    return Utils.getReadableDateFromIso(string)
  }
  downloadPDF(id) {
    this.glowService.getSnapshotBySnapshotId(id).subscribe((resp) => {
      if (resp) {
        this.pdfService.generatePdf(resp)
      }
      return resp
    })
  }

  openConfirmationModal(estimateRequestId) {
    const modalReference = this.modalService.open(ConfirmationModalComponent, { centered: true, size: 'lg' });
    modalReference.componentInstance.confirmationTitle = 'Are you sure you want to remove this property?';
    (modalReference.componentInstance.confirmationText = 'In doing so, you will no longer be able to view or download their data.'),
    (modalReference.componentInstance.confirmationButtonText = 'Remove property');
    modalReference.componentInstance.estimateRequestId = estimateRequestId;
  }
}
