import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { GlowService } from 'src/app/services/glow.service';
import { SchematicsService } from 'src/app/services/schematics.service';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';

import { InvitationModalComponent } from 'src/app/components/invitation-modal/invitation-modal.component';
import { ResponseModalModule } from 'src/app/components/response-modal/response-modal.module';
import { VirtualEntityService } from 'src/app/services/virtual-entity.service';
import { InvitationsService } from 'src/app/services/invitations.service';
import { HelpModalComponent } from 'src/app/components/help-modal/help-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-account-pages',
  templateUrl: './account-pages.component.html',
  styleUrls: ['./account-pages.component.scss'],
})
export class AccountPagesComponent implements OnInit {
  loading = true
  schematic
  isSchematicComplete: boolean
  selectedService;
  subscriptionSelectedService: Subscription;
  schematicsSubscription: Subscription
  subscriptionVirtualEntityList: Subscription
  pageTitle = 'Account';
  error: Boolean
  schematicDoc;
  account;
  virtualEntities: any[];
  dropdownOptions: any[] = [];
  missingData = []
  currentPage = '';
  validVEs;
  invalidVEs;
  private currentPageAnnouncer = new Subject<any>();
  currentPage$ = this.currentPageAnnouncer.asObservable();
  constructor(private modalService: NgbModal, private schematicsService: SchematicsService,
    private serviceSelectorSvc: ServiceSelectorSvc,
    private glowService: GlowService,
    private veService: VirtualEntityService,
    private invitationService: InvitationsService,
    private translate: TranslateService) {
   
    this.subscriptionVirtualEntityList = this.veService.virtualEntitiesForDashboard$.subscribe(async (veList) => {
      if (veList.length) {
        this.processVeList(veList)
        await this.invitationService.validateVirtualEntitites(veList)
        if (this.invitationService.validVEs.length) {
          this.validVEs = this.invitationService.validVEs
        }
        if (this.invitationService.invalidVEs.length) {
          this.invalidVEs = this.invitationService.invalidVEs
        }

      }
    });
  }

  ngOnInit() {
    this.veService.findVirtualEntities();

    const accountId = localStorage.getItem("id");
    this.getAccount(accountId)
    this.selectedService = this.serviceSelectorSvc.getSelectedService()
    // this.schematicsService.getDocumentList(this.selectedService.veId).then((doc) => (this.schematicDoc = doc))
    // this.schematicsService.setSelectedService(this.selectedService);
    // this.schematicsService.getSchematics();


  }
  public onRouterOutletActivate(event: any) {
    if (event.pageTitle) {
      this.currentPageAnnouncer.next(event.pageTitle);
      this.currentPage = event.pageTitle;
    }

  }
  openInvitationModal() {

    const modalRef = this.modalService.open(InvitationModalComponent, { scrollable: true, centered: true, size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.hasSchematic = this.isSchematicComplete
    // modalRef.componentInstance.schematicDocId = this.schematicDoc[0].documentId
    modalRef.componentInstance.customerToInstallerInvitation = true
    modalRef.componentInstance.username = this.account.email
    modalRef.componentInstance.validVEs = this.validVEs
    modalRef.componentInstance.invalidVEs = this.invalidVEs
    modalRef.componentInstance.missingData = this.missingData
    modalRef.componentInstance.dropdownOptions = this.virtualEntities
    modalRef.componentInstance.customerToInstallerInvitation = true
  
  }
  
  getAccount(accountId: string) {
    this.glowService.getAccount().subscribe(account => {
      this.account = account
    }, error => {
      console.warn("An error occurred when retrieving account");
      console.warn(error)
    });
  }

  processVeList(veList) {
    this.virtualEntities = veList;
    this.dropdownOptions = this.virtualEntities.map(ve => {
      const active = this.selectedService && this.selectedService.veId && this.selectedService.veId === ve.veId ? true : false
      return { name: ve.name, postalCode: ve.postalCode, veId: ve.veId, veTypeId: ve.veTypeId, active } // icon 
    })

    if (!this.selectedService) {
      this.selectedService = veList[0];
      this.serviceSelectorSvc.setSelectedService(veList[0])
      this.dropdownOptions[0].active = true
      // this.switchService(this.selectedService.veId)
    }
    // console.log(this.dropdownOptions)
  }
  openInfoModal() {
    const modalRef = this.modalService.open(HelpModalComponent, { scrollable: true, centered: true, size: 'lg' });
    modalRef.componentInstance.pageTitle = 'Invitations';
    modalRef.componentInstance.modalBodyContent = this.translate.instant('INVITATIONS_EXPLANATION')



  }

  


}
