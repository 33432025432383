import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { caseStudyFields } from '../../../../assets/content/case-study-fields.json';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormTemplateComponent } from '../form-template/form-template.component';
import { MicrogeneratorFormComponent } from '../microgenerator-form/microgenerator-form.component';
import { CaseStudyFormService } from 'src/app/services/case-study-form.service';
import { EnergyStorageFormComponent } from '../energy-storage-form/energy-storage-form.component';
import { Subscription } from 'rxjs';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';
import { GlowService } from 'src/app/services/glow.service';
import { Router } from '@angular/router';
import { Utils } from 'src/utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RadiatorSchematicSelectorModalComponent } from '../../radiator-schematic-selector-modal/radiator-schematic-selector-modal.component';

@Component({
  selector: 'app-heating-system-form',
  templateUrl: './heating-system-form.component.html',
  styleUrls: ['./heating-system-form.component.scss'],
})
export class HeatingSystemFormComponent implements OnInit {
  section;
  caseStudy;
  formattedCaseStudy;

  heatingSystems: any[];

  completionStatus: 'completed' | 'in-progress' = 'in-progress';

  caseStudyFields = caseStudyFields;
  heatingSystemForm: FormGroup;
  csSection;
  selectedService: any;
  loadObj: any;
  subscriptionSelectedService: Subscription;

  heatingSystemFormName = 'heatingSystemPreForm';

  @ViewChild('microgeneratorView', { static: false }) microgeneratorView: MicrogeneratorFormComponent;
  @ViewChild('energyStorageView', { static: false }) energyStorageView: EnergyStorageFormComponent;
  constructor(private caseStudyFormService: CaseStudyFormService, private modalService: NgbModal, private serviceSelectorSvc: ServiceSelectorSvc, private router: Router) {
    this.loadObj = this.caseStudyFormService.initLoadObj();
    this.section = this.caseStudyFormService.getSectionFromRouter();

    this.caseStudyFormService.caseStudyAnnouncer.subscribe((data) => {
      if (data) {
        this.caseStudy = data.caseStudy;
        this.formattedCaseStudy = data.formattedCaseStudy;
        this.heatingSystems = this.formattedCaseStudy[this.csSection].heating_system;
      }
    });
    this.subscriptionSelectedService = this.serviceSelectorSvc.serviceSet.subscribe((selectedService) => {
      this.selectedService = selectedService;
      this.caseStudyFormService.initServiceVariables(selectedService).then();
      this.loadObj = this.caseStudyFormService.initLoadObj();
    });
    this.caseStudyFormService.formAnnouncer.subscribe((data) => {
      if (data.formName === this.heatingSystemFormName) {
        this.heatingSystemForm = data.form;
      }
    });
  }

  ngOnInit() {
    this.selectedService = this.serviceSelectorSvc.getSelectedService();
    if (!this.caseStudyFormService.hasInitServiceVariables) {
      this.caseStudyFormService.initServiceVariables(this.selectedService).then();
    } else {
      this.caseStudyFormService.announceLatestCaseStudy();
    }
    this.processChanges();
  }

  ngOnDestroy() {
    if (this.subscriptionSelectedService) this.subscriptionSelectedService.unsubscribe();
  }

  openSelectSchematicModal(formIndex) {
    const modalRef = this.modalService.open(RadiatorSchematicSelectorModalComponent, { size: 'lg' });
    modalRef.result.then((result) => {
      this.fillFormWithSchematic(formIndex, result);
      console.log('result', result);
    });
  }

  fillFormWithSchematic(formIndex, schematicDocument) {
    const form = this.heatingSystemForm.controls.heatingSystems['controls'][formIndex];
    const schematicData = schematicDocument.data;
    const heatingSystem = schematicData.heatingSystem;
    for (let key in heatingSystem) {
      if (form.controls[key] && heatingSystem[key]) {
        form.controls[key].setValue(heatingSystem[key]);
        form.controls[key].markAsDirty();
        form.controls[key].markAsTouched();
      }
    }
    if (heatingSystem.validFrom) {
      form.controls['installedAt'].setValue(heatingSystem.validFrom);
      form.controls['installedAt'].markAsDirty();
      form.controls['installedAt'].markAsTouched();
    }
    this.caseStudyFormService.setFormAndAnnounce(this.heatingSystemFormName, this.heatingSystemForm);
  }

  processChanges() {
    this.loadObj = this.caseStudyFormService.initLoadObj();
    this.csSection = this.section === 'pre-installation-heating' ? 'before' : 'after';
    this.heatingSystemFormName = this.csSection === 'before' ? 'heatingSystemPreForm' : 'heatingSystemPostForm';
    this.caseStudyFormService.announceForm(this.heatingSystemFormName, this.caseStudyFormService[this.heatingSystemFormName]);
    if (this.formattedCaseStudy) {
      this.heatingSystems = this.formattedCaseStudy[this.csSection].heating_system;
    }
  }

  addHeatingSystem() {
    return this.caseStudyFormService.addGroupToFormArray('heatingSystem', this.heatingSystemForm, 'heatingSystems');
  }

  async saveChanges(completionStatus: 'completed' | 'in-progress' = 'in-progress') {
    this.completionStatus = completionStatus;

    this.loadObj = this.caseStudyFormService.handleLoadObjLoading(this.loadObj, 'Saving changes...');
    let err, data;

    //remove deleted heating system journal entries
    await this.caseStudyFormService.processDeletedComponents(this.heatingSystems, this.heatingSystemForm, 'heatingSystems', this.csSection);

    for (let form of this.heatingSystemForm.controls.heatingSystems['controls']) {
      const existingComponent = this.heatingSystems && this.heatingSystems.find((hs) => hs.componentId === form.metadata.componentId && hs.componentVersion === form.metadata.componentVersion);

      [err, data] = await Utils.promiseHandler(existingComponent ? this.processExistingHeatingSystem(form.value, existingComponent) : this.processNewHeatingSystem(form.value));
      if (err) {
        this.loadObj = this.caseStudyFormService.handleLoadObjError(this.loadObj, "Couldn't save changes - please try again later.");
        return this.loadObj;
      }
    }

    this.caseStudyFormService.setFormAndAnnounce(this.heatingSystemFormName, this.heatingSystemForm);

    [err, data] = await Utils.promiseHandler(this.microgeneratorView.saveChanges(this.completionStatus));
    if (err) return (this.loadObj = this.caseStudyFormService.handleLoadObjError(this.loadObj, "Couldn't save changes - please try again later."));
    [err, data] = await Utils.promiseHandler(this.energyStorageView.saveChanges(this.completionStatus));
    if (err) return (this.loadObj = this.caseStudyFormService.handleLoadObjError(this.loadObj, "Couldn't save changes - please try again later"));
    await this.caseStudyFormService.announceLatestCaseStudy();
    this.caseStudyFormService.markSectionAsPristine(this.section);
    this.loadObj = this.caseStudyFormService.handleLoadObjSuccess(this.loadObj, 'Changes saved successfully.');
    return this.loadObj;
  }

  async saveAndContinue() {
    this.completionStatus = 'completed';
    this.loadObj = await this.caseStudyFormService.saveAndContinue(this.saveChanges.bind(this), this.section, this.loadObj);
  }

  async processNewHeatingSystem(formValue) {
    const componentDataType = 'heating_system';
    const component = await this.caseStudyFormService.createComponent({
      formValue,
      componentDataType,
      componentVersion: 1,
      fieldsToDelete: ['installedAt', 'decommissionedAt', 'summary', 'mediaFiles'],
      fieldsToAdd: ['installedAt', 'decommissionedAt'],
      completionStatus: this.completionStatus,
    });
    formValue = await this.caseStudyFormService.uploadComponentMediaFilesFromForm(formValue, component.componentId, component.componentVersion);
    const descriptionJe = await this.caseStudyFormService.createDescriptionJournalEntry({ formValue, componentVersion: 1, componentId: component.componentId, componentDataType, completionStatus: this.completionStatus });
    await this.caseStudyFormService.addJournalEntryToCaseStudy({
      caseStudy: this.caseStudy,
      journalEntry: descriptionJe,
      section: this.csSection,
    });
    return component;
  }

  async processExistingHeatingSystem(formValue, existingComponent) {
    const componentDataType = 'heating_system';
    formValue = await this.caseStudyFormService.uploadComponentMediaFilesFromForm(formValue, existingComponent.componentId, existingComponent.componentVersion);
    const component = await this.caseStudyFormService.updateComponent({
      formValue,
      componentDataType,
      componentVersion: existingComponent.componentVersion,
      componentId: existingComponent.componentId,
      fieldsToDelete: ['installedAt', 'decommissionedAt', 'summary', 'mediaFiles'],
      fieldsToAdd: ['installedAt', 'decommissionedAt', 'mediaFiles'],
    });
    const updateTextDescription = await this.caseStudyFormService.updateDescriptionJournalEntry({ formValue, existingTextDescription: existingComponent.text_description });
    return component;
  }

  getConsumptionJournalEntry(consumptionForm) {
    const journalEntryData = {
      validFrom: consumptionForm.value.validFrom,
      validTo: consumptionForm.value.validTo,
      data: {
        journalEntryDataType: 'resoure_extract',
        classifier: 'gas_consumption',
        totalValue: consumptionForm.value.totalValue,
      },
    };
    return journalEntryData;
  }

  createConsumptionJournalEntry(consumptionForm, componentVersion, componentId) {
    const journalEntryData = this.getConsumptionJournalEntry(consumptionForm);
    return this.caseStudyFormService.createJournalEntry({
      journalEntryData,
      componentId,
      componentVersion,
      componentDataType: 'heating_system',
      completionStatus: this.completionStatus,
    });
  }

  async removeForm(index) {
    // const form = this.heatingSystemForm.controls.heatingSystems['controls'][index];
    this.caseStudyFormService.removeForm(index, this.heatingSystemForm, 'heatingSystems');
  }
}
