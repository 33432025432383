import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MetaTagsService } from 'src/app/services/meta-tags.service';
import { SeoService } from 'src/app/services/seo.service';
import { StrapiService } from 'src/app/services/strapi.service';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-resources-list',
  templateUrl: './resources-list.component.html',
  styleUrls: ['./resources-list.component.scss'],
})
export class ResourcesListComponent implements OnInit {
  url;
  resourcesList: any;
  loading: any = false;
  constructor(private route: ActivatedRoute, private router: Router, private strapiService: StrapiService, private seoService: SeoService) {
    route.params.subscribe((params) => {
      // // console.log(params)
      this.loading = true;
      this.getUrl();
      this.getResourcesList(params.resourceList).then((resourcesList) => {
        this.resourcesList = resourcesList;
        this.loading = false;
      });
    });
  }

  ngOnInit() {
    // // console.log(this.url)
  }

  ngOnDestroy() {
    this.seoService.resetSEOconfig();
  }

  getUrl() {
    this.url = this.route.snapshot.url.map((segment) => segment.path);
  }

  async getResourcesList(slug) {
    const resourcesList = await this.strapiService.getContentBySlug('resources-lists', slug, ['tags', 'authors', 'resources', 'blogs', 'articles', 'infographics']);
    if (resourcesList) {
      const summary = resourcesList && resourcesList.attributes.summary ? resourcesList.attributes.summary : '';
      this.seoService.configureSEO(Utils.removeHTMLFromString(resourcesList.attributes.title), Utils.removeHTMLFromString(summary), `https://glowmarkt.com${this.router.url}`);
    }
    return resourcesList;
  }
}
