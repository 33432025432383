import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { AccountSideMenuComponent } from 'src/app/components/account-side-menu/account-side-menu.component';
import { tVeIdNameListing } from 'src/app/services/app-config.typings';
import { InvitationsService } from 'src/app/services/invitations.service';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';
import { VirtualEntityService } from 'src/app/services/virtual-entity.service';

@Component({
  selector: 'app-property-pages',
  templateUrl: './property-pages.component.html',
  styleUrls: ['./property-pages.component.scss'],
})
export class PropertyPagesComponent implements OnInit {
  @ViewChild('pages', { static: true }) accMenu: AccountSideMenuComponent;
  @HostListener('window:resize', []) onResize() {
    this.innerWidth = window.innerWidth;
  }

  private currentPageAnnouncer = new Subject<any>();
  currentPage$ = this.currentPageAnnouncer.asObservable();

  pageTitle: string = 'Property';
  currentPage: string = 'General attributes';
  innerWidth: number;
  virtualEntities: any[];
  dropdownOptions: any[] = [];
  selectedService: any;
  dimension: string = 'energy';
  subscriptionSelectedService: Subscription;
  subscriptionVirtualEntityList: Subscription;

  constructor(private veService: VirtualEntityService, private serviceSelectorSvc: ServiceSelectorSvc, private router: Router, private invitationsService: InvitationsService) {
    this.subscriptionVirtualEntityList = this.veService.virtualEntitiesForDashboard$.subscribe(async (veList) => {
      if (veList.length) {
        this.processVeList(veList);
      }
    });

    this.subscriptionSelectedService = this.serviceSelectorSvc.serviceSet.subscribe((selectedService) => {
      this.setSelectedService(selectedService);
    });

    this.onResize();
  }

  ngOnInit() {
    this.invitationsService.getAllEstimateRequests();
    this.selectedService = this.serviceSelectorSvc.getSelectedService();
    this.veService.findVirtualEntities();
  }

  ngOnDestroy() {
    if (this.subscriptionVirtualEntityList) {
      this.subscriptionVirtualEntityList.unsubscribe();
    }
    if (this.subscriptionSelectedService) {
      this.subscriptionSelectedService.unsubscribe();
    }
  }

  public onRouterOutletActivate(event: any) {
    if (event.pageTitle) {
      this.currentPageAnnouncer.next(event.pageTitle);
      this.currentPage = event.pageTitle;
    }
  }

  processVeList(veList) {
    this.virtualEntities = veList;
    this.dropdownOptions = this.virtualEntities.map((ve) => {
      const active = this.selectedService && this.selectedService.veId && this.selectedService.veId === ve.veId ? true : false;
      return { name: ve.name, postalCode: ve.postalCode, veId: ve.veId, veTypeId: ve.veTypeId, active }; // icon
    });

    if (!this.selectedService) {
      this.selectedService = veList[0];
      this.serviceSelectorSvc.setSelectedService(veList[0]);
      this.dropdownOptions[0].active = true;
      this.switchService(this.selectedService.veId);
    }
  }

  async switchService(veId) {
    const virtualEntity = await this.veService.getVirtualEntity(veId, false);
    this.serviceSelectorSvc.setSelectedService(virtualEntity);
  }

  setSelectedService(selectedService: tVeIdNameListing) {
    if (selectedService && selectedService.veId) {
      this.selectionChanged(selectedService, true);
    } else {
      console.warn('Selected Service Unexpected format');
    }
  }

  async selectionChanged(selectedService: any, refresh: boolean) {
    if (selectedService) {
      if (refresh == false) {
        this.veService.getVirtualEntityWithFullResources(selectedService.veId);
      }
      this.selectedService = selectedService;
      this.veService.setSelectedVirtualEntity(this.selectedService);
    }
  }
  getOtherPages() {
    const subPages = [];
    this.accMenu.pages.forEach((category) => {
      subPages.push(category.pages);
    });
    return subPages.flat().filter((p) => p.title != this.currentPage);
  }

  navigateToPage(path) {
    this.router.navigate([path]);
  }
}
