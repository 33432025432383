import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeatLossParameterScaleComponent } from './heat-loss-parameter-scale.component';

@NgModule({
  declarations: [HeatLossParameterScaleComponent],
  imports: [
    CommonModule
  ],
  exports: [HeatLossParameterScaleComponent],
  entryComponents: [HeatLossParameterScaleComponent]
})

export class HeatLossParameterScaleModule { }