import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemperatureSensorDataTableComponent } from './temperature-sensor-data-table.component';



@NgModule({
  declarations: [TemperatureSensorDataTableComponent],
  imports: [
    CommonModule
  ],
  exports:[TemperatureSensorDataTableComponent]
})
export class TemperatureSensorDataTableModule { }
