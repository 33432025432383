import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-room-modal',
  templateUrl: './add-room-modal.component.html',
  styleUrls: ['./add-room-modal.component.scss'],
})
export class AddRooomModalComponent implements OnInit {
  roomOptions = [
    { imgPath: '../../../assets/images/schematics/bedroom.jpeg', title: 'Bedroom', id: 'bedroom' },
    { imgPath: '../../../assets/images/schematics/living-room.jpeg', title: 'Living Room', id: 'livingRoom' },
    { imgPath: '../../../assets/images/schematics/kitchen.jpeg', title: 'Kitchen', id: 'kitchen' },
    { imgPath: '../../../assets/images/schematics/bathroom.jpeg', title: 'Bathroom', id: 'bathroom' },
    { imgPath: '../../../assets/images/schematics/other.jpeg', title: 'Other', id: 'other' },
  ];
  flooringOptions = [
    { id: 'wood', title: 'Wood' },
    { id: 'tiles', title: 'Tiles' },
    { id: 'carpet', title: 'Carpet' },
  ];

  floor: any = {};

  roomsForm: FormGroup;
  constructor(private activeModal: NgbActiveModal, private fb: FormBuilder) {}

  ngOnInit() {
    this.initRoomsForm();
  }

  displayImage(room) {
    return { 'background-image': `url(${room.imgPath})` };
  }

  initRoomsForm() {
    const group: any = {};
    this.roomOptions.forEach((roomType) => {
      const roomTypeId = roomType.id;
      const rooms =
        this.floor && this.floor.rooms  
          ? this.floor.rooms.filter(room=>room.type===roomType.id).map((room) =>
              this.createRoomGroup(room.height, room.width, room.length, room.flooring, room.radiators)
            )
          : [];
      // : [this.createRoomGroup()];
      group[roomTypeId] = this.fb.array(rooms);
    });
    this.roomsForm = this.fb.group(group);
  }

  createRoomGroup(
    height: number | string = '',
    width: number | string = '',
    length: number | string = '',
    flooring: string = '',
    radiators: any[] = []
  ) {
    return this.fb.group({
      height: [height, [Validators.required, Validators.pattern("^[0-9]*$")]],
      width: [width, [Validators.required, Validators.pattern("^[0-9]*$")]],
      length:[length,[Validators.required, Validators.pattern("^[0-9]*$")]],
      flooring: [flooring, [Validators.required]],
      radiators: [radiators],
    });
  }

  addRoom(roomType: string, height: number | string = '', width: number | string = '', length: number | string = '',flooring: string = '') {
    const rooms = this.roomsForm.get(roomType) as FormArray;
    rooms.push(this.createRoomGroup(height, width,length, flooring));
  }

  removeRoom(roomType: string, index: number) {
    const rooms = this.roomsForm.get(roomType) as FormArray;
    rooms.removeAt(index);
  }

  closeModal() {
    this.updateFloor();
    this.activeModal.close(this.floor);
  }

  updateFloor() {
    this.floor.rooms = [];
    for (let roomType of this.roomOptions) {
      this.floor.rooms ? null : (this.floor.rooms = {});
      // this.floor.rooms[roomType.id] = this.roomsForm.value[roomType.id].map((room, i) => {
      //   return { ...room,  type: roomType.id, id: `${roomType.id}${i + 1}`, title: `${roomType.title} ${i + 1}` };
      // });
      this.floor.rooms = [
        ...this.floor.rooms,
        ...this.roomsForm.value[roomType.id].map((room, i) => {
          return { ...room, type: roomType.id, id: `${roomType.id}-${this.floor.id}-${i + 1}`, title: `${roomType.title} ${i + 1}` };
        }),
      ];
    }
  }
}
