import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseStudyCreatorToolComponent } from './case-study-creator-tool.component';
import { BuildingFormModule } from 'src/app/components/case-study/building-form/building-form.module';
import { CaseStudyFormStepperModule } from 'src/app/components/case-study-form-stepper/case-study-form-stepper.module';
import { HeatPumpCaseStudyModule } from './heat-pump-case-study/heat-pump-case-study.module';



@NgModule({
  declarations: [CaseStudyCreatorToolComponent],
  imports: [
    CommonModule,  BuildingFormModule, CaseStudyFormStepperModule, HeatPumpCaseStudyModule
  ],
  exports: [CaseStudyCreatorToolComponent]
})
export class CaseStudyCreatorToolModule { }
