import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-installer-card',
  templateUrl: './installer-card.component.html',
  styleUrls: ['./installer-card.component.scss']
})
export class InstallerCardComponent implements OnInit {
  @Input() installer
  constructor() { }

  ngOnInit() {
  }
  getMiles(meters) {
    return (meters / 1609.344).toFixed(1)
  }
  checkWebsiteURL(url) {
    return url.includes('http')

  }
}
