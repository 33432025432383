/**
 * August 2018
 * This service is responsible for setting up some configuration to the Glow Serivce APK.
 */

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class GlowConfigurationService {

    resourceTypeDimensionConfig: tResourceTypeDimensionConfig;

    constructor() {
        // this.alive = true;
    };
    
    getResourceTypeDivisors(): tResourceTypeDimensionConfig {
        this.resourceTypeDimensionConfig = {
            energy: {
                Wmin: {
                    resourceTypeIds: [], // This has now been transferred to the back end
                    divisor: 60000.0
                },
                pence: {
                    resourceTypeIds:["b4158501-a678-484a-837a-874194d3bd48", , "32d9821b-34fd-46d1-9ba1-56259afd4734", "b3c80261-f9d3-4453-a6e7-5fd02e89fc67","c58b4039-3cdf-425a-873d-f9e82d8c4009","b8a8b8ca-ee59-4fc9-97c1-77dfa6bd9b46", "177a0707-154b-4666-be59-2a5eff88a160"],
                    divisor: 100.0
                }
            }
        }
        return this.resourceTypeDimensionConfig;

    }

}

export class tResourceTypeDimensionConfig{
    energy: {
        Wmin: {
            resourceTypeIds: string[],
            divisor: number
        };
        pence: {
            resourceTypeIds: string[],
            divisor: number
        };
    };
}


