import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchematicToolComponent } from './schematic-tool.component';
import { RadiatorSchematicModule } from '../radiator-schematic/radiator-schematic.module';
import { SpinnerModule } from '../spinner/spinner.module';

@NgModule({
  declarations: [SchematicToolComponent],
  imports: [CommonModule, RadiatorSchematicModule, SpinnerModule],
  exports: [SchematicToolComponent],
})
export class SchematicToolModule {}
