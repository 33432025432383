import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SearchService } from 'src/app/services/search.service';
import { Utils } from 'src/utils';
import { GlowService } from 'src/app/services/glow.service';
import { InvitationsService } from 'src/app/services/invitations.service';
import { Subscription } from 'rxjs';
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component';
import { InstallerInvitationModalComponent } from 'src/app/components/installer-invitation-modal/installer-invitation-modal.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StrapiService } from 'src/app/services/strapi.service';
import { ProfessionalGlowService } from 'src/app/services/professional-glow.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  // public user$: Observable<any>;
  user: any;
  installerInfographic;
  loading = true;
  allContent: any = [];
  roles;
  acceptedEstimateRequests = [];
  loadingUpdateReference: boolean;
  errorMessage;
  isUpdating: any;
  formShow: boolean;
  error;
  referenceForm: FormGroup;
  estimatesSubscription: Subscription;
  constructor(
    private auth: AuthenticationService,
    private searchService: SearchService,
    private modalService: NgbModal,
    private router: Router,
    private invitationsService: InvitationsService,
    private glowService: GlowService,
    private fb: FormBuilder,
    private strapiService: StrapiService,
    private professionalGlowService: ProfessionalGlowService
  ) {
    this.getOIDCUser();
    this.estimatesSubscription = this.invitationsService.ProfessionalEstimatesAnnouncer.subscribe((resp) => {
      console.log(resp)
      if (!resp || resp.error) {
        this.error = true;
        this.errorMessage = true;
        this.loading = false;
      } else if (!resp.valid) {
        this.loading = false
        this.acceptedEstimateRequests = []
      } else {
        this.acceptedEstimateRequests = this.invitationsService.getAllActiveEstimateRequests(resp)

        this.loading = false;
      }
    });

    this.referenceForm = this.fb.group({
      reference: [this.isUpdating ? this.isUpdating.externalReference : '', [Validators.required]],
    });
  }

  ngOnInit() {
    this.loading = true;
    this.invitationsService.getAllProfessionalEstimateRequests()
    this.getInfographic()

  }

  ngOnDestroy() {
    if (this.estimatesSubscription) {
      this.estimatesSubscription.unsubscribe();
    }
  }

  showForm(property) {
    this.referenceForm.reset();
    this.formShow = true;
    this.isUpdating = property;
    if (this.isUpdating.externalReference) {
      this.referenceForm.setValue({ reference: this.isUpdating.externalReference });
    }
  }

  closeForm() {
    this.formShow = false;
    this.isUpdating = {};
  }

  async updateReference(estimateRequestId) {
    this.loadingUpdateReference = true;
    const body = { externalReference: this.referenceForm.value.reference };
    const resp = await this.professionalGlowService.updateExternalReferenceEstimateRequest(estimateRequestId, body).toPromise();
    await this.invitationsService.getAllProfessionalEstimateRequests();
    this.formShow = false;
    this.isUpdating = {};
    this.loadingUpdateReference = false;
    this.loading = false;
  }

  getOIDCUser() {
    this.auth.getOIDCUser().subscribe((resp) => {
      this.user = resp;
      this.roles = this.auth.setOIDCUserRoles(this.user);
      this.getRelevantContent().then((c) => (this.allContent = c));
    });
  }

  triggerSignout(): void {
    this.auth.signout();
  }

  async getRelevantContent() {
    const role = this.roles[0].role == 'local-authority' ? 'Local Authority' : 'Installer';
    const content = await this.searchService.filterAllContentWithTags('landing', '', [role]);
    const professionalContent = await this.searchService.filterAllContentWithTags('landing', '', ['Professional']);
    const res = [...content, ...professionalContent];
    const formattedRes = res.map((r) => Utils.fromatSearchContent(r));
    return formattedRes;
  }

  navigateToProperty(id) {
    this.router.navigateByUrl(`/professional/property/${id}`);
  }
  openConfirmationModal(estimateRequestId) {
    const modalReference = this.modalService.open(ConfirmationModalComponent, { centered: true, size: 'lg' });
    modalReference.componentInstance.confirmationTitle = 'Are you sure you want to remove this property?';
    (modalReference.componentInstance.confirmationText = 'In doing so, you will no longer be able to view or download their data.'),
      (modalReference.componentInstance.confirmationButtonText = 'Remove property');
    modalReference.componentInstance.estimateRequestId = estimateRequestId;
  }

  openInvitationModal() {
    this.modalService.open(InstallerInvitationModalComponent, { scrollable: true, centered: true, size: 'lg', backdrop: 'static' });
  }


  getInfographic() {
    this.strapiService.getOneContent('infographics', 2).then(resp => {
      this.installerInfographic = resp
    })
  }
}
