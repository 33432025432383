import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-social-media-buttons',
  templateUrl: './social-media-buttons.component.html',
  styleUrls: ['./social-media-buttons.component.scss'],
})
export class SocialMediaButtonsComponent implements OnInit {
  @Input() content:any
  constructor(private router:Router) {}

  ngOnInit(): void {}

  getTwitterUrl() {
    return `https://twitter.com/intent/tweet?text=${this.content.attributes.title}&&url=${this.router.url}`;
  }

  getFacebookUrl(){
    return `https://www.facebook.com/sharer/sharer.php?u=${this.router.url}`
  }

  getEmailUrl(){
    return `mailto:?subject=${this.content.attributes.title}&body=I found this interesting, check it out ${this.router.url}`
  }

  getLinkedInUrl(){
    return `https://www.linkedin.com/sharing/share-offsite/?url=${this.router.url}`
  }
}
