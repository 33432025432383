import { Component, Input, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlowService } from 'src/app/services/glow.service';
import { StrapiService } from 'src/app/services/strapi.service';

@Component({
  selector: 'app-case-study-feedback',
  templateUrl: './case-study-feedback.component.html',
  styleUrls: ['./case-study-feedback.component.scss'],
})
export class CaseStudyFeedbackComponent implements OnInit {
  @Input() title;
  @Input() caseStudyId;
  formArray = new FormArray([]);
  submittedFeedback = false;
  feedbackForm = new FormGroup({ feedbackForm: this.formArray });
  // radioFormOptions =
  //   ['Very unhelpful', 'Not very helpful', 'Slightly helpful', 'Helpful', 'Very helpful']
  constructor(
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private strapiService: StrapiService,
    private glowService: GlowService
  ) {
    this.feedbackForm = this.formBuilder.group({
      commentFeedback: ['', Validators.required],
      // radioFeedback: ['', Validators.required],
    });
  }

  ngOnInit() {}
  // addControl() {
  //   this.radioFormOptions.forEach((option) => this.formArray.push(new FormControl(option, [Validators.required])))
  //   // console.log(this.formArray)

  // }
  async postFeedback(commentFeedback) {
    const data = {
      csId: this.caseStudyId,
      data: commentFeedback,
    };

    await this.glowService.createCaseStudyComment(data).toPromise();
    this.submittedFeedback = true;
    setTimeout(() => {
      this.activeModal.dismiss();
    }, 1200);
  }
}
