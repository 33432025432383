import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelpModalComponent } from '../../help-modal/help-modal.component';
import { TranslateService } from '@ngx-translate/core';

import { CaseStudyService } from 'src/app/services/case-study.service';
import { Utils } from 'src/utils';
import { GlowService } from 'src/app/services/glow.service';
import { ImageModalComponent } from '../../image-modal/image-modal.component';

@Component({
  selector: 'app-case-study-installaton-heating-system-technical',
  templateUrl: './case-study-installaton-heating-system-technical.component.html',
  styleUrls: ['./case-study-installaton-heating-system-technical.component.scss'],
})
export class CaseStudyInstallatonHeatingSystemTechnicalComponent implements OnInit {
  @Input() caseStudy: any;
  @Input() caseStudyRelatedContentConfig: any;
  @Input() caseStudyConfig: any;
  @Input() colour: any;

  heatingSystem: any;
  hasTechnicalDetails: boolean = true;
  restrictedConfigs: any = [];
  radSchematicMap: any = {};
  constructor(private modalService: NgbModal, private translateService: TranslateService, private glowService: GlowService) {}

  ngOnInit() {
    this.restrictedConfigs = this.getRestictedConfigs();
    this.hasTechnicalDetails = this.hasDetails(this.restrictedConfigs);
  }

  getRestictedConfigs() {
    // return Object.values(this.caseStudyRelatedContentConfig['heating_system']);
    return Object.values(this.caseStudyRelatedContentConfig['heating_system']).filter((config: any) => {
      return config.restrictedView || (config.relation && this.isRelationRestricted(config.type));
    });
  }

  setInputs({ caseStudy, caseStudyRelatedContentConfig, caseStudyConfig, colour }) {
    this.caseStudy = caseStudy;
    this.heatingSystem = caseStudy.after.heating_system.find((item: any) => item.type.toLowerCase().includes('heat pump'));
    this.populateRadSchematicMap().then();
    this.caseStudyRelatedContentConfig = caseStudyRelatedContentConfig;
    this.caseStudyConfig = caseStudyConfig;
    this.colour = colour;
  }

  returnZero() {
    return 0;
  }

  isRelationRestricted(relationField) {
    return this.caseStudyRelatedContentConfig[relationField] && Object.values(this.caseStudyRelatedContentConfig[relationField]).some((v: any) => v.restrictedView);
  }

  hasDetails(configs) {
    return configs.some(
      (config: any) => this.heatingSystem[config.field] !== undefined && this.heatingSystem[config.field] !== null && (this.heatingSystem[config.field].length || config.relation != 'oneToMany')
    );
  }

  getRelationDisplay(relation, value, unit) {
    if (!value || value.length == 0) {
      return [];
    }
    switch (relation) {
      case 'consumption':
        return this.resourceExtractDisplay(value, 'totalValue', unit);
      case 'humidity':
        return this.resourceExtractDisplay(value, 'meanValue', unit);
      case 'installation':
        return this.installationDisplay(value);
      default:
        return;
    }
  }

  resourceExtractDisplay(value, summaryValueType: 'totalValue' | 'meanValue', unit) {
    return `${value[summaryValueType]} ${this.translateService.instant(unit)} (${Utils.formatDateToDdMmYyyy(new Date(value.validFrom))} - ${Utils.formatDateToDdMmYyyy(new Date(value.validTo))})  `;
  }

  installationDisplay(installation) {
    return `<span>Start/End: ${Utils.formatDateToDdMmYyyy(new Date(installation.installationStartDate))} - ${Utils.formatDateToDdMmYyyy(
      new Date(installation.installationEndDate)
    )}</span> <br> <span>Installers: ${installation.installers.map((installer) => installer.businessName).join(' ')}</span>`;
  }

  async populateRadSchematicMap() {
    const fileReader = new FileReader();
    if (!this.heatingSystem || !this.heatingSystem.heatEmitterSchematics || !this.heatingSystem.heatEmitterSchematics.length) return null;
    const blob = await this.glowService.getImage(this.heatingSystem.heatEmitterSchematics[0].fileId);
    fileReader.readAsDataURL(blob);
    fileReader.onloadend = () => {
      this.radSchematicMap[this.heatingSystem.componentId] = fileReader.result;
    };
  }

  getRelationDisplayOneToMany(relationType, value) {
    if (!value || value.length == 0) {
      return [];
    }
    let displays = [];
    let fieldsToDisplay = this.caseStudyRelatedContentConfig[relationType];
    for (let i = 0; i < value.length; i++) {
      let item = value[i];
      let display = [];
      for (let field in fieldsToDisplay) {
        if (item[field] || item[field] === false) {
          display.push({ displayName: fieldsToDisplay[field].displayName, value: item[field], unit: fieldsToDisplay[field].unit });
        }
      }
      displays.push(display);
    }
    return displays;
  }

  getRelationDisplaySummary(relationType, value) {
    if (!value || value.length == 0) {
      return [];
    }
    let fieldsToDisplay = this.caseStudyRelatedContentConfig[relationType];
    let fieldsCounter: any = {};
    let i = 0;
    while (i < value.length) {
      let item = value[i];
      for (let field in fieldsToDisplay) {
        if ((item[field] || item[field] === false) && fieldsToDisplay[field].isPrimary) {
          const key = field + item[field];
          if (fieldsCounter[key]) {
            fieldsCounter[key].count += 1;
          } else {
            fieldsCounter[key] = {
              count: 1,
              value: item[field],
              displayName: fieldsToDisplay[field].displayName,
              unit: fieldsToDisplay[field].unit,
            };
          }
        }
      }
      i += 1;
    }
    // return Object.values(fieldsCounter)
    return Object.values(fieldsCounter).sort((a: any, b: any) => a.displayName.localeCompare(b.displayName));
  }

  getRelationDisplayHTML(relationType, value) {
    const displays = this.getRelationDisplayOneToMany(relationType, value);
    let html = '<div class="table-responsive" style="max-height: 100px; overflow-y: sroll"><table class="table table-striped table-bordered">';
    //generate table header
    html += '<thead><tr>';
    for (let field in this.caseStudyRelatedContentConfig[relationType]) {
      html += `<th>${this.translateService.instant(this.caseStudyRelatedContentConfig[relationType][field].displayName)}</th>`;
    }
    html += '</tr></thead>';
    //generate table body
    displays.forEach((display) => {
      html += '<tr>';
      display.forEach((item) => {
        const unit = item.unit ? this.translateService.instant(item.unit) : null;
        const value = item.value === true ? 'Yes' : item.value === false ? 'No' : item.value;
        let unitHTML = unit ? '<span>' + unit + '</span>' : '';
        html += `<td>${value} ${unitHTML}</td>`;
      });
      html += '</tr>';
    });
    html += '</table></div>';

    return html;
  }

  openHelpModal(relationType, relationDisplayName, value) {
    const modalRef = this.modalService.open(HelpModalComponent, { size: 'lg', centered: true, scrollable: true });
    modalRef.componentInstance.pageTitle = relationDisplayName;
    modalRef.componentInstance.modalBodyContent = this.getRelationDisplayHTML(relationType, value);
  }

  openImage(component) {
    const modalRef = this.modalService.open(ImageModalComponent, { size: 'lg' });
    modalRef.componentInstance.imageUrl = this.radSchematicMap[component.componentId];
  }
}
