import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormValidationService } from 'src/app/services/form-validation.service';

@Component({
  selector: 'app-heat-pump-cost-estimation-params-form',
  templateUrl: './heat-pump-cost-estimation-params-form.component.html',
  styleUrls: ['./heat-pump-cost-estimation-params-form.component.scss'],
})
export class HeatPumpCostEstimationParamsFormComponent implements OnInit {
  @Output() submit = new EventEmitter();
  @Input() inputs: any = {};
  @Input() checkValidity: boolean=false

  loading = false;
  detailsForm;
  formErrorMessage: boolean;
  constructor(private fb: FormBuilder, private formValidationService: FormValidationService) {}

  ngOnInit() {
    this.initDetailsForm();
    if (!this.detailsForm.valid && this.checkValidity) {
      this.showErrorMsg();
    }
  }

  initDetailsForm() {
    this.detailsForm = this.fb.group({
      annualGasConsumption: [this.inputs.annualGasConsumption, [Validators.required, Validators.min(1)]],
      unitRateGas: [this.inputs.unitRateGas, [Validators.required, Validators.min(1)]],
      standingChargeGas: [this.inputs.standingChargeGas, [Validators.required, Validators.min(1)]],
      unitRateElectricity: [this.inputs.unitRateElectricity, [Validators.required, Validators.min(1)]],
      standingChargeElectricity: [this.inputs.standingChargeElectricity, [Validators.required, Validators.min(1)]],
      heatPumpCop: [this.inputs.heatPumpCop ? this.inputs.heatPumpCop : 3.7, [Validators.required, Validators.min(1)]],
    });
  }

  checkFormValidity(formControlName, translateKey?) {
    return this.formValidationService.checkFormValidity(this.detailsForm, formControlName, translateKey);
  }

  showErrorMsg() {
    this.formErrorMessage = true;
    this.formValidationService.markFormGroup(this.detailsForm, 'touched');
  }
  resetErrorMsg() {
    this.formErrorMessage = false;
  }

  onSubmit() {
    // console.log(this.detailsForm.valid);
    if (!this.detailsForm.valid) {
      this.showErrorMsg();
    } else {
      this.loading = true;
      this.submit.emit(this.detailsForm.value);
    }
  }
}
