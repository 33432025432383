import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { GlowService } from './glow.service';

import { tUserGroup, tUserOrganization } from './glow.typings';
import { tIsUserOranizationAdmin, tIsUserGroupAdmin } from './app-data.typings';

@Injectable()
export class UserGroupSelectorService {

    private userOrganizations: tUserOrganization[] = [];

    private defaultErrorMsgObj = { error: "An error has occurred"}
    private UserOrganizationsAnnouncer = new Subject<tUserOrganization[]>();
    private UserGroupsAnnouncer = new Subject<tUserGroup[]>(); 
    private UserGroupsOfUserAnnouncer = new Subject<tUserGroup[]>();    


    userOrganizations$ = this.UserOrganizationsAnnouncer.asObservable();
    userGroupsOfUser$ = this.UserGroupsOfUserAnnouncer.asObservable();
    userGroups$ = this.UserGroupsAnnouncer.asObservable();

    public getUserOrganizationSet: EventEmitter<any> = new EventEmitter();
    public isUserGroupAdmin: EventEmitter<tIsUserGroupAdmin> = new EventEmitter();
    public isUserOrganizationAdmin: EventEmitter<tIsUserOranizationAdmin> = new EventEmitter();

    constructor(    
                private glowService: GlowService) {
    };

    private announceUserOrganizations(): void{
        this.UserOrganizationsAnnouncer.next(this.userOrganizations);
    };

    private announceUserGroupsOfUser(userGroups): void{
        this.UserGroupsOfUserAnnouncer.next(userGroups);
    };

    private announceUserGroups(userGroups): void{
        this.UserGroupsAnnouncer.next(userGroups);
    };

    public getUserOrganizations(functionalGroupId=""): void {

        this.glowService
            .getUserOrganizationsOfUser(functionalGroupId)
            .subscribe(userOrganizations => {
                    this.userOrganizations = userOrganizations;
                    // console.log(this.userOrganizations);
                    this.announceUserOrganizations();

                },
                error => {
                    // console.log('User Organizations loading error is : ' + error);
                    // this.loadingerror = true;
                    // this.loadingerrortext = "An error has occurred";
                    // this.loading = false;
                }
            );
    };

    public checkUserIsUserGroupAdmin(userGroupId: string) : void {
        this.glowService
        .checkIfUserIsUserGroupAdmin(userGroupId)
        .subscribe(isUserGroupAdminRes => {

            if (isUserGroupAdminRes.valid){
                let isUserGroupAdmin = {
                    isAdmin: true,
                    userGroupId: userGroupId
                };
                this.isUserGroupAdmin.emit(isUserGroupAdmin);
            }
        },
        error => {
            // console.log('User Organizations loading error is : ' + error);
            this.announceUserGroups(this.defaultErrorMsgObj);
        });
    };

    public getUserGroupsOfOrganization(userOrganizationId: string): void {

        this.glowService
            .getUserGroupsOfOrganization(userOrganizationId)
            .subscribe(userGroups => {
                this.announceUserGroups(userGroups);
            },
            error => {
                // console.log('User Organizations loading error is : ' + error);
                this.announceUserGroups(this.defaultErrorMsgObj);
            });
    };

    public getUserGroupsOfOrganizationPromise(userOrganizationId: string): Promise<tUserGroup[]> {

        return this.glowService.getUserGroupsOfOrganization(userOrganizationId).toPromise()
    };


    public checkUserIsUserOrganizationAdmin(userOrganizationId: string) : void {
        this.glowService
        .checkIfUserIsUserOrganizationAdmin(userOrganizationId)
        .subscribe(isUserOrganizationAdmin => {
            let isUserOrgganizationAdmin = {
                isAdmin: true,
                userOrganizationId: userOrganizationId
            };
            if (isUserOrganizationAdmin.valid){
                this.isUserOrganizationAdmin.emit(isUserOrgganizationAdmin);
            }
        },
        error => {
            // console.log('User Organizations loading error is : ' + error);
            this.announceUserGroups(this.defaultErrorMsgObj);
        });
    }

    // private selectUserOrganization(): void {
    //     var redirectToOrgSelection = true;
    //     if(this.userOrganizations.length==1){
    //         // console.log("User has a single user organization, selection not required");
            
    //         // if(this.userOrganizations[0].userGroups && this.userOrganizations[0].userGroups.length==1){
    //         // }
    //         redirectToOrgSelection
    //     }   

    // };

    private selectUserOrganizations(): void {

        this.glowService
            .getUserOrganizationsOfUser()
            .subscribe(userOrganizations => {
                    this.userOrganizations = userOrganizations;
                    // console.log(this.userOrganizations);
                    // this.announceUserOrganizations();
                },
                error => {
                    // console.log('User Organizations loading error is : ' + error);
                    // this.loadingerror = true;
                    // this.loadingerrortext = "An error has occurred";
                    // this.loading = false;
                }
            );
    };
    
    /**
     * selectUserOrganizationFromJWT:
     * Find the user groups and check for unique organizations. If there is only one use it
     * else redirect to user organization page.
     * 
     * Error Handling: I this function fails for any reason, redirect to user organization page 
     * --the user will need to maully select thei r orgainization even if they are just i one
     */
    public selectUserOrganizationFromJWT(): boolean {

        var uniqueUserOrganizations = [];
        var redirectToOrgSelection = true;
        var userGroups;
        try{
            userGroups = JSON.parse(localStorage.getItem('userGroups'));
        }
        catch (e){
            // console.log(e);
        }
        if(userGroups && Array.isArray(userGroups)){
            for(var i=0; i<userGroups.length; i++){
                if(uniqueUserOrganizations.indexOf(userGroups[i].userOrganizationId)<0){
                    uniqueUserOrganizations.push(userGroups[i].userOrganizationId);
                }
            }
            // console.log("Unique user organizations", uniqueUserOrganizations);
    
            if (uniqueUserOrganizations.length == 1){
                redirectToOrgSelection = false;
                this.getAndSetUserOrganization(uniqueUserOrganizations[0]);
                // this.getUserOrganizations();
            }
        }

        return redirectToOrgSelection;
    };

    public getUserOrganization(): tUserOrganization {
        // console.log("get user organization from storage")
        return (localStorage.getItem('userOrganization')) ? JSON.parse(localStorage.getItem('userOrganization')) : null
    };

    public setUserOrganization(userOrganization: tUserOrganization){
        // console.log("setting user organization")
        localStorage.setItem('userOrganization', JSON.stringify(userOrganization));
        localStorage.setItem('userOrganizationId', userOrganization.userOrganizationId);
        localStorage.setItem('userOrganizationName', userOrganization.name);
        this.getUserOrganizationSet.emit(userOrganization);
        
    };

    public getAndSetUserOrganization(userOrganizationId: string, functionalGroupId=""){

        /// to do change this call to get speccific userOrganizationId
        // this call should return an array with a single userOrganization
        this.glowService
            .getUserOrganizationsOfUser(functionalGroupId)
            .subscribe(userOrganizations => {

                    if(userOrganizations && Array.isArray(userOrganizations)){
                        for(var i=0; i<userOrganizations.length; i++){
                            if(userOrganizations[i].userOrganizationId == userOrganizationId){
                                this.setUserOrganization(userOrganizations[i]);
                            }
                        }
                    }
                },
                error => {
                    // console.log('User Organizations loading error is : ' + error);
                    // this.loadingerror = true;
                    // this.loadingerrortext = "An error has occurred";
                    // this.loading = false;
                }
            );
    };

    /**
     * Caution must be taken when saving the userGroups that are extracted from this call.
     * This call returns all the user groups of a user org (the user may not be part of all groups)
     */
    public getUserGroupsOfUserOrganizatiion(userOrganizationId: string, functionalGroupId=""){

        this.glowService
            .getUserGroupsOfOrganization(userOrganizationId)
            .subscribe(userGroups => {

                    this.announceUserGroupsOfUser(userGroups);
                },
                error => {
                    // console.log('User Organizations loading error is : ' + error);
                    // this.loadingerror = true;
                    // this.loadingerrortext = "An error has occurred";
                    // this.loading = false;
                }
            );
    };

    public getUserOrganizationFromCache(userOrganizationId: string): tUserOrganization {

        return (Array.isArray(this.userOrganizations))? this.userOrganizations.find((userOrg)=> userOrg.userOrganizationId == userOrganizationId) : null
    };


};
