import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseStudyFilterComponent } from './case-study-filter.component';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [CaseStudyFilterComponent],
  imports: [
    CommonModule, SharedModule
  ],
  exports: [CaseStudyFilterComponent],
})
export class CaseStudyFilterModule { }
