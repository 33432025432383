import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FaqsService } from 'src/app/services/faqs.service';

@Component({
  selector: 'app-faqs-topics',
  templateUrl: './faqs-topics.component.html',
  styleUrls: ['./faqs-topics.component.scss'],
})
export class FaqsTopicsComponent implements OnInit {
  loading: boolean = true;

  topics: any;
  constructor(private faqsService:FaqsService,private router:Router) {}

  ngOnInit(): void {this.getFAQsTopics()



  

  }


  async getFAQsTopics() {
    this.topics = await this.faqsService.getFAQsTopics()
    this.loading = false;
  }

  navigateToTopic(topic:any){
    // this.faqsService.setTopicId(topic.id)
    this.router.navigateByUrl(`/support/${topic.attributes.slug}`)
  }

  topicRelativePath(topic){
    return `/support/${topic.attributes.slug}`
  }
}
