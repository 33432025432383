import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent implements OnInit {
  @Input() tag: any;
  @Input() shouldDisplayCommunity: boolean = false;
  @Input() isTagBgWhite: boolean = true;
  @Input() isButton: boolean = false;
  @Output() click: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  tagRelativePath(tag) {
    return tag.attributes.isCommunity ? `/community/${tag.attributes.slug}` : `/tag/${tag.attributes.slug}`;
  }

  tagClick() {
    this.click.emit(this.tag);
  }
}
