import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hpj-infographic',
  templateUrl: './hpj-infographic.component.html',
  styleUrls: ['./hpj-infographic.component.scss']
})
export class HpjInfographicComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
