import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { EpcInfoModalComponent } from 'src/app/components/epc-info-modal/epc-info-modal.component';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { HtcModalComponent } from 'src/app/components/htc-modal/htc-modal.component';
import { GlowService } from 'src/app/services/glow.service';
import { VirtualEntityService } from 'src/app/services/virtual-entity.service';
import { StrapiService } from 'src/app/services/strapi.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';
import { FormValidationService } from 'src/app/services/form-validation.service';
import { Router } from '@angular/router';
import { HpJourneyService } from 'src/app/services/hp-journey.service';

@Component({
  selector: 'app-property-passport',
  templateUrl: './property-passport.component.html',
  styleUrls: ['./property-passport.component.scss', '../pages.component.scss'], // shared css file
})
export class PropertyPassportComponent implements OnInit {
  @Input() heatPumpJourneyModalView: boolean;
  @Output() modal: EventEmitter<any> = new EventEmitter();
  pageTitle: string = 'Property Passport';
  passport: any;
  generatingPassport: any;
  loading: boolean = false;
  errorMessage: string = '';
  loadingMessage: string = '';
  isHtmlError: boolean;
  requestManualUPRN: boolean;
  errorCode: number;
  error = false;
  propertyPassportDefinition;
  htcDefinition;
  virtualEntities: any[];
  subscriptionVirtualEntityList: Subscription;
  subscriptionSelectedService: Subscription;
  showGenerateHTCButton: boolean = false;
  cookieClass: boolean = false;
  hlpValue;
  formInfo: FormGroup;
  selectedService: any;
  dropdownOptions: any[] = [];
  uprnForm: FormGroup;
  uprnFormError: boolean = false;

  constructor(
    private glowService: GlowService,
    private veService: VirtualEntityService,
    private modalService: NgbModal,
    private ccService: NgcCookieConsentService,
    private formBuilder: FormBuilder,
    private serviceSelectorSvc: ServiceSelectorSvc,
    private strapiService: StrapiService,
    private formValidationService: FormValidationService,
    private router: Router,
    private hpJourneyService: HpJourneyService
  ) {
    this.formInfo = this.formBuilder.group({
      selectedService: ['', Validators.required],
    });

    this.uprnForm = this.formBuilder.group({
      uprn: ['', [Validators.required, Validators.maxLength(12), Validators.maxLength(12), Validators.pattern('^[0-9]*$')]],
    });

    this.loading = true;
    this.subscriptionVirtualEntityList = this.veService.virtualEntitiesForDashboard$.subscribe(async (veList) => {
      if (veList && Array.isArray(veList) && veList.length > 0) {
        this.virtualEntities = veList;
        this.processVeList(veList);
      }
    });

    this.subscriptionSelectedService = this.serviceSelectorSvc.serviceSet.subscribe((selectedService) => {
      this.selectedService = selectedService;
      this.getPropertyPassport();
    });

    if (this.ccService.hasAnswered() && this.ccService.hasConsented()) {
      this.cookieClass = true;
    }
  }

  ngOnInit() {
    this.selectedService = this.serviceSelectorSvc.getSelectedService();
    if (this.selectedService && this.selectedService.veId) {
      this.getPropertyPassport();
    }
    this.veService.findVirtualEntities();
    this.getPropertyPassportTerm();
    this.getHTCTerm();
  }

  ngAfterContentChecked() {
    if (this.dropdownOptions.length) {
      this.selectedService = this.dropdownOptions.find((x) => x.active == true);
      this.formInfo.setValue({ selectedService: this.selectedService.name });
    }
  }

  ngOnDestroy() {
    if (this.subscriptionVirtualEntityList) {
      this.subscriptionVirtualEntityList.unsubscribe();
    }
  }

  getHTCTerm() {
    this.strapiService.getOneContent('glossary-terms', 10, []).then((t) => (this.htcDefinition = t.attributes.shortDefinition));
  }

  getPropertyPassportTerm() {
    this.strapiService.getOneContent('glossary-terms', 31, []).then((t) => (this.propertyPassportDefinition = t.attributes.definition));
  }

  open() {
    const selectedVe = this.virtualEntities.find((ve) => ve.veId == this.selectedService.veId);
    const modalRef = this.modalService.open(HtcModalComponent, { scrollable: true, centered: true, size: 'lg' });
    modalRef.componentInstance.propertyPassportId = this.passport.propertyPassportId;
    modalRef.componentInstance.epc = this.getCurrentEpc(this.passport.epcRecords);
    modalRef.componentInstance.veId = this.selectedService.veId;
    modalRef.componentInstance.resources = selectedVe.resources;
    modalRef.componentInstance.updatePassport.subscribe((passport) => {
      if (passport) {
        this.passport = this.formatPassportToDisplay(passport);
      }
      modalRef.dismiss();
    });
  }

  submitManualUPRN() {
    if (!this.uprnForm.valid) {
      this.formValidationService.markFormGroup(this.uprnForm, 'touched');
      this.uprnFormError = true;
    } else {
      this.requestManualUPRN = false;
      this.loadingMessage = '';
      this.loading = true;
      this.generatingPassport = true;
      this.error = false;
      const uprn = this.uprnForm.value.uprn;
      const data = {
        uprn: uprn,
      };
      this.glowService.manualCreatePropertyPassport(this.selectedService.veId, data).subscribe(
        (resp) => {
          this.passport = this.formatPassportToDisplay(resp);
          this.loading = false;
          this.loadingMessage = '';
          this.generatingPassport = false;
        },
        (err) => {
          this.errorMessage = err && err.error && err.error.error;

          this.requestManualUPRN = err && err.error && err.requestManualUPRN;
          this.isHtmlError = err && err.error && err.isHtmlError;
          this.errorCode = err && err.error && err.error.errorCode;
          this.loading = false;
          this.loadingMessage = '';
          this.generatingPassport = false;
          this.error = true;
          console.warn(err);
        }
      );
    }
  }
  createPropertyPassport() {
    this.loadingMessage = 'Creating your property passport...';
    this.loading = true;
    this.generatingPassport = true;
    this.sendGAEvent({ event: 'createPropertyPassport' });
    this.glowService.createPropertyPassport(this.selectedService.veId).subscribe(
      (resp) => {
        this.passport = this.formatPassportToDisplay(resp);
        this.hpJourneyService.getPropertyPassport();

        this.loading = false;
        this.loadingMessage = '';
        this.generatingPassport = false;
      },
      (err) => {
        this.errorMessage = err && err.error && err.error.error;
        this.requestManualUPRN = err && err.error && err.error.requestManualUPRN;
        this.isHtmlError = err && err.error && err.error.isHtmlError;
        this.errorCode = err && err.error && err.error.errorCode;
        this.loading = false;
        this.loadingMessage = '';
        this.generatingPassport = false;
        this.error = true;
        console.warn(err);
      }
    );
  }

  getPropertyPassport() {
    this.loadingMessage = 'Retrieving your property passport...';
    this.loading = true;
    delete this.passport;
    this.glowService.getPropertyPassport(this.selectedService.veId).subscribe(
      (resp) => {
        if (resp.valid) {
          this.passport = this.formatPassportToDisplay(resp);
          this.hpJourneyService.getPropertyPassport();

          // console.log(resp)
        }
        this.loading = false;
        this.loadingMessage = '';
        this.generatingPassport = false;
      },
      (err) => {
        this.errorMessage = err && err.error && err.error.error;
        this.errorCode = err && err.error && err.error.errorCode;
        this.loading = false;
        this.loadingMessage = '';
        this.generatingPassport = false;
        this.error = true;
        console.warn(err);
      }
    );
  }

  formatPassportToDisplay(passport) {
    let formatted: any = { ...passport };
    formatted.address.address = this.formatAddress(passport.address.address);
    formatted.currentEpc = this.getCurrentEpc(passport.epcRecords);
    formatted.currentHtc = this.getCurrentHtc(passport.htcRecords);
    formatted.currentGSME = this.getCurrentGSME(passport.meters);
    formatted.currentESME = this.getCurrentESME(passport.meters);
    return formatted;
  }

  getCurrentGSME(meters) {
    const flattened = meters.flat();
    const gsmeMeters = flattened.filter((meter) => meter.DeviceType == 'GSME');
    return gsmeMeters.length ? gsmeMeters.sort((a, b) => new Date(b.DateCommissioned).valueOf() - new Date(a.DateCommissioned).valueOf())[0] : null;
  }

  getCurrentESME(meters) {
    const flattened = meters.flat();
    const esmeMeters = flattened.filter((meter) => meter.DeviceType == 'ESME');
    return esmeMeters.length ? esmeMeters.sort((a, b) => new Date(b.DateCommissioned).valueOf() - new Date(a.DateCommissioned).valueOf())[0] : null;
  }

  getCurrentEpc(epcRecords) {
    return [...epcRecords].sort((a, b) => new Date(b.lodgement_date).valueOf() - new Date(a.lodgement_date).valueOf())[0];
  }

  getCurrentHtc(htcRecords) {
    const response = htcRecords && htcRecords.length > 0 ? htcRecords.sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf())[0] : null;
    this.showGenerateHTCButton = !response || (response && response.date && new Date().getTime() > new Date(new Date(response.date).getTime() + 86400000).getTime()) ? true : false; // hide if htc generated in last 24 hours
    this.hlpValue = response && response.hlp;
    // console.log(response)
    return response;
  }

  formatAddress(address: string) {
    //remove multiple commas
    return address
      .split(',')
      .filter((fragment) => fragment)
      .join(',');
  }

  openHTCModal() {
    const modalRef = this.modalService.open(EpcInfoModalComponent, { scrollable: true, centered: true, size: 'lg' });
    modalRef.componentInstance.propertyPassportId = this.passport.propertyPassportId;
    modalRef.componentInstance.veId = this.selectedService.veId;
    modalRef.componentInstance.resources = this.selectedService.resources;
  }

  sendGAEvent(params) {
    if (this.ccService.hasAnswered() && this.ccService.hasConsented()) {
      window.dataLayer.push(params);
    }
  }

  processVeList(veList) {
    this.virtualEntities = veList;
    this.dropdownOptions = this.virtualEntities.map((ve) => {
      const active = this.selectedService && this.selectedService.veId && this.selectedService.veId === ve.veId ? true : false;
      return { name: ve.name, veId: ve.veId, veTypeId: ve.veTypeId, active }; // icon
    });

    if (!this.selectedService) {
      this.selectedService = veList[0];
      this.dropdownOptions[0].active = true;
    }
  }

  async switchService(event) {
    this.loading = true;
    this.error = false;
    const match = this.dropdownOptions.find((x) => {
      return x.name == event.name;
    });
    if (match) {
      this.dropdownOptions.map((x) => (x.active = false));
      match.active = true;
      this.selectedService = match;
      const virtualEntity = await this.veService.getVirtualEntity(this.selectedService.veId, false);
      this.serviceSelectorSvc.setSelectedService(virtualEntity);
    }
  }

  checkFormValidity(formControlName, translateKey?) {
    return this.formValidationService.checkFormValidity(this.uprnForm, formControlName);
  }
  navigatePropertyPassportPage() {
    this.modal.emit('close');
    this.router.navigateByUrl('/pages/account/property-passport');
  }
}
