import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';

import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { GlowService } from '../../services/glow.service';
import { tLoginInfo, tTermsConditionsUserCreateRes, tUserOrganization } from '../../services/glow.typings';
// import { UserGroupSelectorService } from "../../services/usergroup-selector.service";
// import { GlowEventService } from "../../services/event.service";
import { CustomUIConfigService } from '../../services/custom-ui-config.service';
import { tLoadingElementWithSuccess } from '../../services/app-config.typings';
import { TermsConditionsService } from '../../services/termsconditions.service';
import { tTermsConditionsUiConfig } from 'src/app/components/component.typings';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TermsconditionsModalComponent } from 'src/app/components/termsconditions-modal/termsconditions-modal.component';
// import { GoogleAnalyticsEventsService } from 'app/services/google-analytics-events-service';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnInit {
  loginDetailsForm: FormGroup;
  loadObj: tLoadingElementWithSuccess;
  isOpenTermsAndConditionsModal: boolean = false;
  termsConditionsUiConfig: tTermsConditionsUiConfig = {
    allowModalToExit: false,
    showPermissions: true,
    requireAccept: true,
  };
  viewPassword: boolean = false;

  termsconditionsRef: any;

  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private authService: GlowService,
    // private glowEventService: GlowEventService,
    private termsConditionsService: TermsConditionsService,
    private route: ActivatedRoute,
    private router: Router,
    private uiConfigService: CustomUIConfigService // private userGroupSelectorService: UserGroupSelectorService, // private analytics: GoogleAnalyticsEventsService
  ) {
    this.loadObj = this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false);

    this.loginDetailsForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.closeModal("NavigationStart")
      }})

    route.queryParams.subscribe((params) => {
      this.loginDetailsForm.get('username').setValue(params['email']);
      const missingElements = [];
      const incorrectElements = [];
      // scope ccould also be a property, but it is not required
      const incomingRequiredProperties = ['applicationId', 'token', 'userKey'];
      const replaceCharacters = [/%0D/g, /%0A/g, /%0D%0A/g, /\r/g, /\n/g];
      const incomingValues: any = Object.keys(params).reduce((acc, incomingProperty) => {
        const sanitisedPropertyName = replaceCharacters.reduce(
          (acc, characterToReplace) => acc.replace(characterToReplace, ''),
          incomingProperty
        );
        if (
          incomingRequiredProperties.indexOf(sanitisedPropertyName) > -1 ||
          incomingRequiredProperties.indexOf(incomingProperty) > -1
        ) {
          const sanitisedValue = replaceCharacters.reduce(
            (acc, characterToReplace) => acc.replace(characterToReplace, ''),
            params[incomingProperty] || params[sanitisedPropertyName]
          );
          acc[sanitisedPropertyName] = sanitisedValue;
        }
        return acc;
      }, {});
      incomingRequiredProperties.forEach((property) => {
        if (!incomingValues[property]) {
          missingElements.push(property);
          console.warn('Missing element: ' + property);
        }
      });
      const { applicationId, token, userKey } = incomingValues;
      const emailVerificationIncomingParams = { applicationId, token, usernameHash: userKey };
      // if (missingElements.length === 0 && incorrectElements.length === 0) {
      //   this.submitEmailVerificationInformation(emailVerificationIncomingParams)
      // }
      this.submitEmailVerificationInformation(emailVerificationIncomingParams);
    });
  }

  ngOnInit(): void {}


  closeModal(result) {
    this.activeModal.close(result);
    // this.router.navigateByUrl('/support/system-incidents');
  }

  submitEmailVerificationInformation(emailVerificationIncomingParams) {
    this.authService.verify(emailVerificationIncomingParams).subscribe(
      (verificationResponse) => {
        if (verificationResponse.valid === true) {
          // console.log('Email successfully verified');
        } else {
          // console.log('email address could not be verified.');
        }
      },
      (err) => {
        // console.log('email verification error', err);
        console.warn(err.error);
      }
    );
  }

  sanitiseDetails(loginDetailsForm: tLoginInfo): tLoginInfo {
    // Lowercase Username:
    if (loginDetailsForm && loginDetailsForm.username) {
      loginDetailsForm.username = loginDetailsForm.username.toLowerCase();
      loginDetailsForm.username = loginDetailsForm.username.replace(/\s+/g, '');
    }

    if (loginDetailsForm.password) {
      loginDetailsForm.password = loginDetailsForm.password.replace(/\s+$/, ''); // Trailing spaces
      loginDetailsForm.password = loginDetailsForm.password.replace(/^\s+/g, ''); // Spaces at the start of the string
    }

    return loginDetailsForm;
  }

  togglePassword(viewPassword) {
    this.viewPassword = viewPassword ? false : true;
  }

  logOut() {
    this.authService.logout().subscribe(
      (resp) => {
        // // console.log('logout', resp);
        // // console.log("Log Out user as T&Cs cound not happen")
      },
      (err) => {
        console.warn(err);
      }
    );
    // this.isOpenTermsAndConditionsModal = false
  }

  goToNextPage(userOrganizations: tUserOrganization[]) {
    this.router.navigateByUrl('/pages/my-data');

    // if (userOrganizations && Array.isArray(userOrganizations) && userOrganizations.length > 1) {
    //   this.router.navigateByUrl('/pages/usergroup');
    // } else if (userOrganizations.length == 1) {
    //   this.userGroupSelectorService.setUserOrganization(userOrganizations[0])
    //   this.router.navigateByUrl('/pages/overview');
    // } else {
    //   console.warn("No user Organizations found")
    //   this.router.navigateByUrl('/pages/user-management');
    // }
  }

  openTermsConditionsModal(termsConditions) {
    this.termsconditionsRef = this.modalService.open(TermsconditionsModalComponent, {
      scrollable: true,
      backdrop: 'static',
      centered: true,
      size: 'xl',
    });
    // this.termsconditionsRef.componentInstance.termsConditions=termsConditions
  }

  findUserOrganizations() {
    this.authService.getUserOrganizationsOfUser().subscribe(
      (userOrganizations) => {
        this.loadObj = {
          ...this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false),
          loaded: true,
          loadedMessage: 'Success',
        };
        // this.goToNextPage(userOrganizations);
        this.closeModal('success');
      },
      (error) => {
        console.warn(error);
        // // console.log('User Organizations loading error is : ' + error);
      }
    );
  }

  async checkTermsConditions() {
    let userTcObj;
    try {
      userTcObj = await this.termsConditionsService.checkIfUserHasAcceptedCurrentTermsAndConditions();
    } catch (e) {
      console.warn(e);
      if (e && e.message) {
        this.loadObj = {
          ...this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false),
          loadError: true,
          errorString: e.message,
        };
      } else {
        this.loadObj = {
          ...this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false),
          loadError: true,
          errorString: 'ERROR',
        };
      }
    }
    // console.log('userTcObj', userTcObj);
    if (userTcObj && userTcObj.hasUserAcceptedTermsConditions === true) {
      this.findUserOrganizations();
    } else {
      // console.log('NOT ACCEPTED TERMS');
      this.isOpenTermsAndConditionsModal = true;
      const ts = await this.termsConditionsService.getCurrentTermsConditionsSync();
      this.openTermsConditionsModal(ts);
      this.termsconditionsRef.result.then(
        (data) => {
          // // console.log('RESULT CLOSE', data);
          if (data.reference && data.data) {
            this.termsConditionsService.setUserTermsConditions(data);
            this.findUserOrganizations();
          } else {
            this.loadObj = {
              ...this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false),
              loadError: true,
              errorString: 'Terms and conditions must be accepted to continue',
            };
          }
        },
        (err) => {
          console.warn(err);
        }
      );
    }
  }

  // async termsConditionsAggreed(termsConditionsUser: tTermsConditionsUserCreateRes) {
  //   let userTcObj;
  //   try {
  //     userTcObj = await this.termsConditionsService.checkIfUserHasAcceptedCurrentTermsAndConditions();
  //   } catch (e) {
  //     console.warn(e)
  //   }
  //   if (userTcObj && userTcObj.hasUserAcceptedTermsConditions === true) {
  //     this.findUserOrganizations()
  //   } else {
  //     this.loadObj = {
  //       ...this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false),
  //       loadError: true,
  //       errorString: "ERROR_TERMS_CONDITIONS_NOT_ACCEPTED"
  //     }
  //     this.logOut()
  //   }
  // }

  // Attempt to login in through our User service
  doLogin() {
    this.loadObj = this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(true);

    // console.log('Login attempt');
    let loginDetails = this.sanitiseDetails({ ...this.loginDetailsForm.value });
    this.authService.login(loginDetails).subscribe(
      (authResponse) => {
        if (authResponse === true) {
          // this.glowEventService.sendEvent({}, 'login')
          const isTempAuth = this.authService.isTempAuth();
          if (isTempAuth === true) {
            // console.log('Temp auth credentials detected, going straight to home');
            this.findUserOrganizations();
          } else {
            // authentication success | Check T&Cs and consent
            this.checkTermsConditions();
            // this.analytics.emitEvent('Login', 'Loggin Event')
          }
        } else {
          this.loadObj = {
            ...this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false),
            loadError: true,
            errorString: 'Access Denied',
          };
        }
      },
      (err) => {
        console.warn(err);
        let errorString = 'An error has occurred';
        // try {
        //   const errJson = err.json()
        //   if (errJson.error) {
        //     errorString = errJson.error
        //   } else if (errJson.valid === false ) {
        //     errorString = 'Invalid username or password'
        //   }
        try {
          if (err.error && typeof err.error === 'string') {
            errorString = err.error;
          } else if ((err.error && !err.error.valid) || err.valid === false) {
            errorString = 'Invalid username or password';
          }
        } catch (e) {
          console.warn(e);
        }
        this.loadObj = {
          ...this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false),
          loadError: true,
          errorString,
        };
      }
    );
  }
}
