import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-coming-soon-card',
  templateUrl: './coming-soon-card.component.html',
  styleUrls: ['./coming-soon-card.component.scss']
})
export class ComingSoonCardComponent implements OnInit {
  @Input() cardContent:string
  constructor() { }

  ngOnInit() {
  }

}
