import { Injectable } from '@angular/core';


@Injectable()
export class StatsService {
    // Source https://stackoverflow.com/questions/48719873/how-to-get-median-and-quartiles-percentiles-of-an-array-in-javascript-or-php
    // WE generally expect arrays of resource readings [[sth, value1],[sth, value2]].
    constructor() {

    }

    calculateQuartile(data, q) {
        data = this.sortArrayNumbersAscending(data);
        const pos = ((data.length) - 1) * q;
        const base = Math.floor(pos);
        const rest = pos - base;
        if ((data[base + 1] !== undefined)) {
            return data[base][1] + rest * (data[base + 1][1] - data[base][1]);
        } else {
            return data[base][1];
        }
    }

    sortArrayNumbersAscending(inputarray) {
        return inputarray.sort((a, b) => {
            return a[1] - b[1];
        });
    }

    sumArrayElem(t) {
        return t.reduce((a, b) => a + b[1], 0);
    }

    calculateArrayAverage(data) {
        return this.sumArrayElem(data) / data.length;
    }

    calculateArrayStd(tab) {
        let i, j, total = 0, mean = 0, diffSqredArr = [];
        for (i = 0; i < tab.length; i += 1) {
            total += tab[i][1];
        }
        mean = total / tab.length;
        for (j = 0; j < tab.length; j += 1) {
            diffSqredArr.push(Math.pow((tab[j] - mean), 2));
        }
        return (Math.sqrt(diffSqredArr.reduce(function (firstEl, nextEl) {
            return firstEl + nextEl;
        }) / tab.length));
    }
}



