import { EventEmitter, Injectable } from '@angular/core';
import { GlowService } from './glow.service';
import { ServiceSelectorSvc } from './service-selector.service';
import { SchematicsService } from './schematics.service';

@Injectable({
  providedIn: 'root'
})
export class HpJourneyService {
  steps
  passport: boolean
  schematic;
  formFilledOut: boolean;
  stepsAnnouncer: EventEmitter<any> = new EventEmitter();
  installerField;
  htc
  stepTwoDisable: boolean
  stepThreeDisable: boolean
  schematicDocuments
  stepFourDisable: boolean
  pendingInvitations: boolean
  constructor(private glowService: GlowService, private serviceSelectorSvc: ServiceSelectorSvc, private schematicsService: SchematicsService) { }




  async getPropertyPassport() {
    const selectedService = this.serviceSelectorSvc.getSelectedService()
    return await this.glowService.getPropertyPassport(selectedService.veId).toPromise().then(resp => {
      console.log(resp)
      if (resp.valid) {
        this.passport = resp
      }
      this.setSteps()
      return this.passport

    })

  }

  async getSchematicDocuments() {
    const selectedService = this.serviceSelectorSvc.getSelectedService()
    return this.schematicsService.getSchematicsByStatus('complete', selectedService.veId).then((documents) => {
      this.schematicDocuments = documents;
      // this.setSteps()
      return this.schematicDocuments
    });
  }

  resetSteps() {
    this.formFilledOut = false
    this.passport = false
    this.htc = false
    this.schematic = false
    this.setSteps()

  }

  setField(field, value){
    this[field] = value
    this.setSteps()
  }

  setInstallers(installers) {
    console.log(installers)
    if (!installers.length) {
      this.installerField = false
    } else {
      this.installerField = true
    }
    this.setSteps()
  }

  // enableSteps(step) {
  //   this[step] = true
  //   this.setSteps()
  // }

  // showStepAcceptInvitations() {
  //   this.pendingInvitations = true
  //   this.setSteps()
  // }



  setSteps() {
    console.log('set steps')
    let steps = [{
      title: 'Share your home information with an installer',
      icon: 'bi bi-circle',
      step: 1,
      steps: [
        {
          title: 'Property passport *',
          icon: this.passport ? 'bi bi-check-circle' : 'bi bi-circle',
          complete: this.passport ? true : false,
          show: true
        },
        {
          title: 'Helpful questions *',
          icon: this.formFilledOut ? 'bi bi-check-circle' : 'bi bi-circle',
          complete: this.formFilledOut ? true : false,
          show: true
        },
        {
          title: 'Radiator schematics',
          icon: this.schematic  ? 'bi bi-check-circle' : 'bi bi-circle',
          complete: this.schematic ? true : false,
          show: true

        },
        {
          title: 'Building fabric score',
          icon: this.htc  ? 'bi bi-check-circle' : 'bi bi-circle',
          complete: this.htc ? true : false,
          show: true
          // complete: this.passport ? true : false 

        },
        {
          title: 'Send an invitation to installer *',
          icon: this.installerField ? 'bi bi-check-circle' : 'bi bi-circle',
          complete: this.installerField ? true : false,
          show: true
        },
        {
          title: 'Accept a request',
          icon: 'bi bi-circle',
          complete: false,
          show: this.pendingInvitations ? true : false
        },

      ]
    },
    {
      title: 'Assess quotes / estimates',
      icon: 'bi bi-circle',
      step: 2,
      disabled: this.stepTwoDisable ? false : true
    },
    {
      title: 'Get heat pump installed',
      icon: 'bi bi-circle',
      step: 3,
      disabled: true
    },
    {
      title: 'Share your experience',
      icon: 'bi bi-circle',
      step: 4,
      disabled: true
    }]
    this.announceSteps(steps)
    return this.steps
  }

  announceSteps(steps) {
    this.stepsAnnouncer.emit(steps)
  }
}