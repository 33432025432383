import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddRooomModalComponent } from './add-room-modal.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [AddRooomModalComponent],
  imports: [CommonModule, SharedModule],
  exports: [AddRooomModalComponent],
  entryComponents: [AddRooomModalComponent],
})
export class AddRoomModalModule {}
