import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeatPumpCostEstimationToolComponent } from './heat-pump-cost-estimation-tool.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { HeatPumpCostEstimationParamsFormModule } from 'src/app/components/heat-pump-cost-estimation-params-form/heat-pump-cost-estimation-params-form.module';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [HeatPumpCostEstimationToolComponent],
  imports: [
    CommonModule, SharedModule, HeatPumpCostEstimationParamsFormModule, NgbPopoverModule
  ],
  exports: [HeatPumpCostEstimationToolComponent]
})
export class HeatPumpCostEstimationToolModule { }
