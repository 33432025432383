import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StrapiService } from 'src/app/services/strapi.service';
import { ImageModalComponent } from '../../image-modal/image-modal.component';
import { Utils } from 'src/utils';
import { CaseStudyService } from 'src/app/services/case-study.service';
import { GlowService } from 'src/app/services/glow.service';

@Component({
  selector: 'app-case-study-details',
  templateUrl: './case-study-details.component.html',
  styleUrls: ['./case-study-details.component.scss'],
})
export class CaseStudyDetailsComponent implements OnInit {
  @Input() caseStudy;
  @Input() caseStudyRelatedContentConfig;
  @Input() caseStudyConfig;
  @Input() colour;
  @Input() caseStudyConfigSection;
  @Input() caseStudySection;
  @Input() isAccountView;

  nonRestrictedConfigs: any = [];
  radSchematicMap: any = {};
  imageSrcMap: any = {};
  isEmptySection = false;
  constructor(private caseStudyService: CaseStudyService, private modalService: NgbModal, private glowService: GlowService) {}

  ngOnInit() {
    this.isEmptySection = this.isEmpty();
  }

  ngAfterContentChecked() {
    this.isEmptySection = this.isEmpty();
  }

  isEmpty() {
    return !(
      this.caseStudy &&
      this.caseStudyConfigSection &&
      this.caseStudyConfig[this.caseStudyConfigSection] &&
      Object.values(this.caseStudyConfig[this.caseStudyConfigSection].contentConfig).some((item: any) => {
        return this.caseStudy[this.caseStudySection] && this.caseStudy[this.caseStudySection][item.contentField] && this.caseStudy[this.caseStudySection][item.contentField].length;
      })
    );
  }

  setInputs({ caseStudy, caseStudyRelatedContentConfig, caseStudyConfig, colour, caseStudyConfigSection, caseStudySection, isAccountView }) {
    this.caseStudySection = caseStudySection;
    this.caseStudy = caseStudy;
    this.caseStudyRelatedContentConfig = caseStudyRelatedContentConfig;
    this.caseStudyConfig = caseStudyConfig;
    this.colour = colour;
    this.caseStudyConfigSection = caseStudyConfigSection;
    this.isAccountView = isAccountView;
    this.populateImageSrcMap().then();
  }

  returnZero() {
    return 0;
  }

  getRelationDisplay(relation, value) {
    switch (relation) {
      case 'heat_emitters':
        return this.heatEmittersDisplay(value);
      case 'consumption':
        return this.resourceExtractDisplay(value, 'totalValue');
      case 'humidity':
        return this.resourceExtractDisplay(value, 'meanValue');
      case 'installation':
        return this.installationDisplay(value);
      case 'tariff':
        return this.tariffDisplay(value);
      case 'awardedGrants':
        return this.awardedGrantsDisplay(value);
      case 'loans':
        return this.loansDisplay(value);
      default:
        return;
    }
  }

  resourceExtractDisplay(value, summaryValueType: 'totalValue' | 'meanValue') {
    return `${value[summaryValueType]} kWh (${Utils.formatDateToDdMmYyyy(new Date(value.validFrom))} - ${Utils.formatDateToDdMmYyyy(new Date(value.validTo))})  `;
  }

  installationDisplay(installation) {
    return `<span>Start/End: ${Utils.formatDateToDdMmYyyy(new Date(installation.installationStartDate))} - ${Utils.formatDateToDdMmYyyy(new Date(installation.installationEndDate))}</span> <br> <span>Installers: ${installation.installers
      .map((installer) => installer.businessName)
      .join(' ')}</span>`;
  }

  tariffDisplay(tariff) {
    if (!tariff || tariff.length == 0) {
      return 'Unknown';
    } else if (tariff.type && !tariff.unitRate && tariff.standingCharge) {
      return `${tariff.type} (Standing charge: ${tariff.standingCharge}p/day)`;
    } else if (tariff.type && tariff.unitRate && !tariff.standingCharge) {
      return `${tariff.type} (Unit rate: ${tariff.unitRate}p/kWh)`;
    } else if (tariff.type && !tariff.unitRate && !tariff.standingCharge) {
      return tariff.type;
    }
    return `${tariff.type} (Unit rate: ${tariff.unitRate}p/kWh, Standing charge: ${tariff.standingCharge}p/day)`;
  }

  awardedGrantsDisplay(awardedGrants) {
    if (!awardedGrants || awardedGrants.length == 0) {
      return 'Unknown';
    }
    return awardedGrants
      .map((ag) => {
        return `${ag.grant.title} - £${ag.amount}`;
      })
      .join(', ');
  }

  loansDisplay(loans) {
    if (!loans || loans.length == 0) {
      return 'Unknown';
    }
    return loans
      .map((loan) => {
        return `${loan.loan.title} - £${loan.amount}@${loan.interestRate}%`;
      })
      .join(', ');
  }

  heatEmittersDisplay(heat_emitters) {
    if (!heat_emitters || heat_emitters.length == 0) {
      return 'Unknown';
    }
    let typeCounter: any = {};
    heat_emitters.forEach((he) => {
      if (typeCounter[he.type]) {
        typeCounter[he.type]++;
      } else {
        typeCounter[he.type] = 1;
      }
    });
    let typeDesc = Object.keys(typeCounter)
      .map((type) => {
        return `${typeCounter[type]} ${type}`;
      })
      .join(', ');
    return typeDesc;
  }

  async populateImageSrcMap() {
    const components = this.caseStudy[this.caseStudySection];
    for (let componentArray in components) {
      for (let component of components[componentArray]) {
        if (component.mediaFiles && component.mediaFiles.length > 0) {
          await Promise.all(
            component.mediaFiles.map(async (mediaFile) => {
              await this.setMeiaFileSrc(mediaFile);
            })
          );
        }
        if (component.heatEmitterSchematics && component.heatEmitterSchematics.length > 0) {
          await Promise.all(
            component.heatEmitterSchematics.map(async (mediaFile) => {
              await this.setMeiaFileSrc(mediaFile);
            })
          );
        }
      }
    }
  }

  async setMeiaFileSrc(mediaFile) {
    const blob = await this.glowService.getImage(mediaFile.fileId);
    const src = (await Utils.getBlobImageSource(blob)) as string;
    this.imageSrcMap[mediaFile.fileId] = src;
  }

  openImage(component) {
    const modalRef = this.modalService.open(ImageModalComponent, { size: 'lg' });
    modalRef.componentInstance.imageUrl = this.radSchematicMap[component.componentId];
  }

  relationHasPublicFields(relationField) {
    return Object.values(this.caseStudyRelatedContentConfig[relationField]).some((v: any) => !v.restrictedView);
  }
  getNonRestrictedConfigs(contentItem) {
    const allConfig = Object.values(this.caseStudyRelatedContentConfig[contentItem]);
    return allConfig.filter((config: any) => {
      return !config.restrictedView || (config.relation && this.relationHasPublicFields(config.type));
    });
  }
}
