import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlowService } from 'src/app/services/glow.service';
import { SchematicsService } from 'src/app/services/schematics.service';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';
import { HelpModalComponent } from '../help-modal/help-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-schematic-tool',
  templateUrl: './schematic-tool.component.html',
  styleUrls: ['./schematic-tool.component.scss'],
})
export class SchematicToolComponent implements OnInit {
  @HostListener('window:resize', []) onResize() {
    this.innerWidth = window.innerWidth;
  }
  innerWidth: number;
  pageTitle = 'Heating by Room';
  currentPage = 'Heating by Room';
  schematic: any;
  schematicDocument: any;
  completionStatus = 'In progress';
  completionStatusColor = 'warning';
  loading = true;

  subscriptionSelectedService: Subscription;
  schematicSubscription: Subscription;
  schematicId;

  constructor(private schematicsService: SchematicsService, private route: ActivatedRoute, private serviceSelectorSvc: ServiceSelectorSvc, private router: Router, private glowService: GlowService, private modalService: NgbModal) {
    route.params.subscribe(async (params) => {
      if (params['id']) {
        this.schematicId = params['id'];
        const veId = this.serviceSelectorSvc.getSelectedService().veId;
        this.schematicDocument = await this.schematicsService.getSchematic(veId, params['id']);
        this.schematic = { ...this.schematicDocument.data };

        this.completionStatus = this.getCompletionStatus();
        if (this.completionStatus === 'Complete') {
          this.schematicsService.setIsEditing(false);
        }
        this.completionStatusColor = this.getCompletionStatusColor();
        this.loading = false;
      }
    });

    route.queryParams.subscribe(async (params) => {
      if (params['edit'] && JSON.parse(params['edit'])) {
        this.schematicsService.setIsEditing(JSON.parse(params['edit']));
      }
    });

    this.subscriptionSelectedService = this.serviceSelectorSvc.serviceSet.subscribe(async (selectedService) => {
      this.schematicsService.setSelectedService(selectedService);
      this.router.navigate(['/pages/tools/heating-by-room']);
    });

    this.schematicSubscription = this.schematicsService.schematicDocument$.subscribe((schematicDocument) => {
      this.schematicDocument = schematicDocument;
      this.schematic = { ...schematicDocument.data };
      this.completionStatus = this.getCompletionStatus();
      if (this.completionStatus === 'Complete') {
        this.schematicsService.setIsEditing(false);
      }
      this.completionStatusColor = this.getCompletionStatusColor();
      this.loading = false;
    });
  }

  ngOnInit() {
    this.onResize()
    this.schematicsService.setSelectedService(this.serviceSelectorSvc.getSelectedService());
  }

  ngOnDestroy() {
    if (this.subscriptionSelectedService) this.subscriptionSelectedService.unsubscribe();
    if (this.schematicSubscription) this.schematicSubscription.unsubscribe();
  }

  getCompletionStatus() {
    if (!this.schematicDocument) return 'Not started';
    if (!this.schematicDocument.status) {
      return this.completionStatus;
    }
    return this.schematicDocument.status.replace('-', ' ').charAt(0).toUpperCase() + this.schematicDocument.status.slice(1);
  }

  getCompletionStatusColor() {
    if (!this.schematicDocument) return 'danger';
    if (!this.schematicDocument.status) {
      return this.completionStatusColor;
    }
    switch (this.schematicDocument.status) {
      case 'complete':
        return 'success';
      case 'in-progress':
        return 'warning';
      case 'not-started':
        return 'danger';
      default:
        return 'warning';
    }
  }

  async edit() {
    if (this.completionStatus === 'Complete') {
      const newSchematic = await this.schematicsService.createNewSchematicFromExisting(this.schematicDocument);
      this.router.navigate(['/pages/tools/heating-by-room/schematic/' + newSchematic.documentId], { queryParams: { edit: true } });
    } else {
      this.schematicsService.setIsEditing(true);
    }
  }

  async save() {
    this.schematicDocument['status'] = 'in-progress';
    await this.schematicsService.setSchematicsAndUpdateSchematicsDocument(this.schematicDocument, this.schematicId);
    this.schematicsService.setIsEditing(false);
  }

  async complete() {
    this.loading = true;
    const validity = this.checkValidity();
    if (!validity) {
      const modalRef = this.modalService.open(HelpModalComponent, { size: 'lg', centered: true, scrollable: true });
      modalRef.componentInstance.pageTitle = 'Error';
      const heatingSystemValidityMessage = this.isHeatingSystemValid() ? '' : '<li>Please complete all heating system details - including the type of heating system, manufacturer and model.</li>';
      const heatEmitterValidityMessage = this.hasMoreThanOneHeatEmitter() ? '' : '<li>You have not added a valid number of heat emitters. Please add at least one heat emitter.</li>';
      modalRef.componentInstance.modalBodyContent = '<p>' + 'Some information is missing from your schematic:' + '</p>' + '<ul>' + heatingSystemValidityMessage + heatEmitterValidityMessage + '</ul>';
    } else {
      await this.schematicsService.updateSchematicsDocumentStatus(this.schematicDocument, this.schematicId, 'complete');
      this.schematicsService.setIsEditing(false);
    }
    this.loading = false;
  }

  isHeatingSystemValid() {
    const heatingSystem = this.schematic.heatingSystem;
    const isHeatingSystemValid = heatingSystem && heatingSystem.type && heatingSystem.manufacturer && heatingSystem.model;
    return isHeatingSystemValid;
  }

  hasMoreThanOneHeatEmitter() {
    console.log('schematic', this.schematic)
    const floors = this.schematic.floors;
    const hasMoreThanOneHeatEmitter =
      floors.reduce((acc, floor) => {
        return (
          acc +
          floor.rooms.reduce((acc, room) => {
            return acc + room.radiators.length;
          }, 0)
        );
      }, 0) > 0;
    return hasMoreThanOneHeatEmitter;
  }

  checkValidity() {
    const heatingSystemValidity = this.isHeatingSystemValid();
    const heatEmitterValidity = this.hasMoreThanOneHeatEmitter();
    return heatingSystemValidity && heatEmitterValidity;
  }

  async deleteSchematic() {
    await this.glowService.deleteVEDocumentByID(this.serviceSelectorSvc.getSelectedService().veId, this.schematicId).toPromise();
    this.router.navigateByUrl('/pages/tools/heating-by-room/in-progress');
  }

  downloadURI({ fileName, uri }) {
    const element = document.createElement('a');
    element.download = fileName;
    element.href = uri;
    element.setAttribute('download', fileName);
    const event = new MouseEvent('click');
    element.dispatchEvent(event);
  }

  downloadImage() {
    const dataURL = this.schematicsService.getCanvasDataUrl(2);
    this.downloadURI({ fileName: 'radiator-schematic', uri: dataURL });
  }
}
