import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertyPagesComponent } from './property-pages.component';
import { AccountSideMenuModule } from 'src/app/components/account-side-menu/account-side-menu.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [PropertyPagesComponent],
  imports: [
    CommonModule, AccountSideMenuModule, RouterModule
  ],
  exports: [PropertyPagesComponent]
})
export class PropertyPagesModule { }
