import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuotesComponent } from './quotes.component';
import { QuoteModalModule } from 'src/app/components/quote-modal/quote-modal.module';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [QuotesComponent],
  imports: [
    CommonModule, QuoteModalModule, SharedModule
  ],
  exports: [QuotesComponent]
})
export class QuotesModule { }
