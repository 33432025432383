import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchematicsComponent } from './schematics.component';
import { AccountSideMenuModule } from 'src/app/components/account-side-menu/account-side-menu.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FloorModule } from 'src/app/components/floor/floor.module';
import { FloorComponent } from 'src/app/components/floor/floor.component';
import { RadiatorSchematicModule } from 'src/app/components/radiator-schematic/radiator-schematic.module';
import { SpinnerModule } from 'src/app/components/spinner/spinner.module';
import { RadiatorSchematicCardModule } from 'src/app/components/radiator-schematic-card/radiator-schematic-card.module';
import { SchematicsRouting } from './schematics.routing';
import { SchematicsHomepageStatusModule } from 'src/app/components/schematics-homepage-status/schematics-homepage-status.module';

@NgModule({
  declarations: [SchematicsComponent],
  imports: [CommonModule, AccountSideMenuModule, NgbModule, FloorModule, RadiatorSchematicModule, SpinnerModule, RadiatorSchematicCardModule, SchematicsRouting, SchematicsHomepageStatusModule],
  exports: [SchematicsComponent],
  entryComponents: [FloorComponent],
})
export class SchematicsModule {}
