import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarContentCardComponent } from './sidebar-content-card.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';



@NgModule({
  declarations: [SidebarContentCardComponent],
  imports: [
    CommonModule, PipesModule
  ],
  exports:[SidebarContentCardComponent]
})
export class SidebarContentCardModule { }
