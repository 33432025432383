import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { PagesComponent } from './pages.component';
import { DashboardModule } from '../components/dashboard/dashboard.module';
import { AppPrivilegeService } from '../services/app-privileges.service';
import { VirtualEntityService } from '../services/virtual-entity.service';
import { UserGroupSelectorService } from '../services/usergroup-selector.service';
import { VirtualEntityGroupService } from '../services/virtual-entity-group.service';
import { GlowEventService } from '../services/event.service';
import { TermsConditionsService } from '../services/termsconditions.service';
import { StatsService } from '../services/stats-service';
import { PagesRouting } from './pages.routing';
import { AccountSideMenuModule } from '../components/account-side-menu/account-side-menu.module';
import { TemperatureSensorDataTableModule } from '../components/temperature-sensor-data-table/temperature-sensor-data-table.module';
import { PropertyPassportModule } from './property-passport/property-passport.module';
import { HtcModalModule } from '../components/htc-modal/htc-modal.module';
import { SharedModule } from '../shared/shared.module';
import { HelpModalModule } from '../components/help-modal/help-modal.module';
import { HeatLossParameterScaleModule } from '../components/heat-loss-parameter-scale/heat-loss-parameter-scale.module';
import { SetupSmartMeterModule } from '../components/setup-smart-meter/setup-smart-meter.module';
import { SetupSmartMeterModalModule } from '../components/setup-smart-meter-modal/setup-smart-meter-modal.module';
import { SchematicsModule } from './schematics/schematics.module';
import { AddRoomModalModule } from '../components/add-room-modal/add-room-modal.module';
import { EpcInfoModalModule } from '../components/epc-info-modal/epc-info-modal.module';
import { HtcModule } from './htc/htc.module';
import { BookmarksModule } from './bookmarks/bookmarks.module';
import { ServiceSelectorSvc } from '../services/service-selector.service';
import { PropertyPagesModule } from './property-pages/property-pages.module';
import { GeneralAttributesModule } from './general-attributes/general-attributes.module';
import { GeneralModule } from './general-attributes/general/general.module';
import { BuildingFabricModule } from './general-attributes/building-fabric/building-fabric.module';
import { HeatingSystemModule } from './heating-system/heating-system.module';
import { EnergyDataModule } from './energy-data/energy-data.module';
import { TemperatureSensorsModule } from './temperature-sensors/temperature-sensors.module';
import { EnergyOverviewModule } from './energy-overview/energy-overview.module';
import { AccountSettingsModule } from './settings/account-settings/account-settings.module';
import { AccountPagesModule } from './account-pages/account-pages.module';
import { AccountNoDataCardModule } from '../components/account-no-data-card/account-no-data-card.module';
import { SettingsModule } from './settings/settings.module';
import { SubscriptionsModule } from './settings/subscriptions/subscriptions.module';
import { ResourceDataService } from '../services/data.service';
import { HtcFullInfoModule } from '../components/htc-full-info/htc-full-info.module';
import { ToolsPagesModule } from './tools-pages/tools-pages.module';
import { HeatPumpCostEstimationToolModule } from './heat-pump-cost-estimation-tool/heat-pump-cost-estimation-tool.module';
import { TariffModule } from './energy-overview/tariff/tariff.module';
import { SitesModule } from './settings/sites/sites.module';
import { SchematicToolModule } from '../components/schematic-tool/schematic-tool.module';
import { NotificationsModule } from './notifications/notifications.module';
import { SentNotificationsModule } from './invitations/sent-invitations/sent-invitations.module';
import { InvitationModalModule } from '../components/invitation-modal/invitation-modal.module';
import { AllNotificationsModule } from './notifications/all-notifications/all-notifications.module';
import { NotificationModule } from '../components/notification/notification.module';
import { InvitationsModule } from './invitations/invitations.module';
import { CaseStudyAccountPageModule } from './case-study-account-page/case-study-account-page.module';
import { CaseStudyCreatorToolModule } from './case-study-creator-tool/case-study-creator-tool.module';
import { BuildingFormModule } from '../components/case-study/building-form/building-form.module';
import { CaseStudyFormStepperModule } from '../components/case-study-form-stepper/case-study-form-stepper.module';
import { HeatPumpCaseStudyModule } from './case-study-creator-tool/heat-pump-case-study/heat-pump-case-study.module';
import { HeatingSystemFormModule } from '../components/case-study/heating-system-form/heating-system-form.module';
import { OutcomesFormModule } from '../components/case-study/outcomes-form/outcomes-form.module';
import { InstallationFormModule } from '../components/case-study/installation-form/installation-form.module';
import { MicrogeneratorFormModule } from '../components/case-study/microgenerator-form/microgenerator-form.module';
import { FormTemplateModule } from '../components/case-study/form-template/form-template.module';
import { EnergyStorageFormModule } from '../components/case-study/energy-storage-form/energy-storage-form.module';
import { FileUploadModule } from '../components/file-upload/file-upload.module';
import { OtherInfoFormModule } from '../components/case-study/other-info-form/other-info-form.module';
import { HeatPumpCaseStudyAccountModule } from './case-study-account-page/heat-pump-case-study-account/heat-pump-case-study-account.module';
import { ReceivedInvitationsModule } from './invitations/received-invitations/received-invitations.module';
import { HeatingByRoomModule } from './heating-system/heating-by-room/heating-by-room.module';
import { ConsentMeterCardModule } from '../components/consent-meter-card/consent-meter-card.module';
import { HeatPumpJourneyModule } from './heat-pump-journey/heat-pump-journey.module';
import { HpJourneyModalModule } from '../components/hp-journey-modal/hp-journey-modal.module';
import { EstimateRequestCardModule } from '../components/estimate-request-card/estimate-request-card.module';

@NgModule({
  declarations: [PagesComponent],
  imports: [
    PagesRouting,
    DashboardModule,
    AccountSideMenuModule,
    TemperatureSensorDataTableModule,
    PropertyPassportModule,
    HtcModalModule,
    HeatLossParameterScaleModule,
    HelpModalModule,
    SetupSmartMeterModule,
    SetupSmartMeterModalModule,
    SharedModule,
    HeatLossParameterScaleModule,
    SchematicsModule,
    AddRoomModalModule,
    EpcInfoModalModule,
    HtcModule,
    BookmarksModule,
    PropertyPagesModule,
    GeneralAttributesModule,
    GeneralModule,
    BuildingFabricModule,
    HeatingSystemModule,
    HtcFullInfoModule,
    EnergyDataModule,
    TemperatureSensorsModule,
    EnergyOverviewModule,
    AccountSettingsModule,
    AccountPagesModule,
    AccountNoDataCardModule,
    SettingsModule,
    SubscriptionsModule,
    ToolsPagesModule,
    HeatPumpCostEstimationToolModule,
    TariffModule,
    CaseStudyAccountPageModule,
    SitesModule,
    CaseStudyCreatorToolModule,
    BuildingFormModule,
    CaseStudyFormStepperModule,
    HeatPumpCaseStudyModule,
    HeatingSystemFormModule,
    OutcomesFormModule,
    InstallationFormModule,
    MicrogeneratorFormModule,
    FormTemplateModule,
    EnergyStorageFormModule,
    FileUploadModule,
    OtherInfoFormModule,
    HeatPumpCaseStudyAccountModule,
    SchematicToolModule,
    NotificationsModule,
    SentNotificationsModule,
    InvitationModalModule,
    AllNotificationsModule,
    InvitationsModule,
    NotificationModule,
    ReceivedInvitationsModule,
    HeatingByRoomModule,
    HeatPumpJourneyModule,
    HpJourneyModalModule,
    ConsentMeterCardModule,
    EstimateRequestCardModule
  ],
  providers: [AppPrivilegeService, GlowEventService, TermsConditionsService, UserGroupSelectorService, VirtualEntityService, VirtualEntityGroupService, ResourceDataService, ServiceSelectorSvc, StatsService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PagesModule {}
