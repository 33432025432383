import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeatPumpJourneyComponent } from './heat-pump-journey.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { HpJourneyStepperModule } from 'src/app/components/hp-journey-stepper/hp-journey-stepper.module';
import { HpJourneyModalModule } from 'src/app/components/hp-journey-modal/hp-journey-modal.module';



@NgModule({
  declarations: [HeatPumpJourneyComponent],
  imports: [
    CommonModule, SharedModule, HpJourneyStepperModule, HpJourneyModalModule
  ],
  exports: [HeatPumpJourneyComponent]
})
export class HeatPumpJourneyModule { }
