import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SeoService } from 'src/app/services/seo.service';
import { StrapiService } from 'src/app/services/strapi.service';
import { Utils } from 'src/utils';
import { JsonLdService } from 'src/app/services/json-ld.service';
@Component({
  selector: 'app-infographic',
  templateUrl: './infographic.component.html',
  styleUrls: ['./infographic.component.scss'],
})
export class InfographicComponent implements OnInit {
  url;
  infographic;
  loading = true;
  displayImageUrl;
  constructor(
    private router: Router,
    private seoService: SeoService,
    private strapiService: StrapiService,
    private route: ActivatedRoute
  ) {
    route.params.subscribe((params) => {
      this.getUrl();
      this.getInfographicContent(params.infographic).then((infographic) => {
        this.infographic = infographic;
        if (infographic) {
          this.configureSEO(infographic);
          this.displayImageUrl = this.getDisplayImageUrl(this.infographic);
        }
        this.loading = false;
      });
    });
  }

  ngOnInit() {}

  getUrl() {
    this.url = this.route.snapshot.url.map((segment) => segment.path);
  }

  async getInfographicContent(slug) {
    const infographicContent = await this.strapiService.getContentBySlug('infographics', slug, ['authors']);
    return infographicContent;
  }

  getDisplayImageUrl(content) {
    return this.strapiService.getSmallDisplayImageUrl(content);
  }

  configureSEO(infographic) {
    const title = Utils.removeHTMLFromString(infographic.attributes.title);
    const summary = Utils.removeHTMLFromString(infographic.attributes.summary);
    const authorNames = infographic.attributes.authors.data.map((author) => author.attributes.name);

    const image = this.infographic.attributes.displayImage.data
      ? this.strapiService.getFileFullUrl(this.infographic.attributes.displayImage.data.attributes.url)
      : '';
    const schema = JsonLdService.articleSchema(title, summary, [image], authorNames);
    this.seoService.configureSEO(title, summary, `https://glowmarkt.com${this.router.url}`, schema);
  }
}
