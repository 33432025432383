import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtcComponent } from './htc.component';
import { AccountSideMenuModule } from 'src/app/components/account-side-menu/account-side-menu.module';
import { EpcInfoModalModule } from 'src/app/components/epc-info-modal/epc-info-modal.module';
import { HeatLossParameterScaleModule } from 'src/app/components/heat-loss-parameter-scale/heat-loss-parameter-scale.module';
import { HtcCardModule } from 'src/app/components/htc-card/htc-card.module';
import { AccountNoDataCardModule } from 'src/app/components/account-no-data-card/account-no-data-card.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SpinnerModule } from 'src/app/components/spinner/spinner.module';

@NgModule({
  declarations: [HtcComponent],
  imports: [
    CommonModule,
    AccountSideMenuModule,
    EpcInfoModalModule,
    HeatLossParameterScaleModule,
    HtcCardModule,
    AccountNoDataCardModule,
    SharedModule,
    SpinnerModule
  ], exports:[HtcComponent]
})
export class HtcModule { }
