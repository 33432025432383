import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvitationModalComponent } from './invitation-modal.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormValidationMessageModule } from '../form-validation-message/form-validation-message.module';
import { FileUploadModule } from '../file-upload/file-upload.module';
import { RadiatorSchematicCardModule } from '../radiator-schematic-card/radiator-schematic-card.module';



@NgModule({
  declarations: [InvitationModalComponent],
  imports: [
    CommonModule,
    SharedModule, FormValidationMessageModule, FileUploadModule, FormValidationMessageModule, RadiatorSchematicCardModule
  ],
  exports: [InvitationModalComponent],
  entryComponents: [InvitationModalComponent],
})
export class InvitationModalModule { }
