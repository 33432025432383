import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-case-study-outcome',
  templateUrl: './case-study-outcome.component.html',
  styleUrls: ['./case-study-outcome.component.scss'],
})
export class CaseStudyOutcomeComponent implements OnInit {
  @Input() caseStudy;
  @Input() caseStudyRelatedContentConfig;
  @Input() colour;
  @Input() isAccountView;
  constructor() {}

  ngOnInit() {}

  setInputs({ caseStudy, caseStudyRelatedContentConfig, colour, isAccountView }) {
    this.caseStudy = caseStudy;
    this.caseStudyRelatedContentConfig = caseStudyRelatedContentConfig;
    this.colour = colour;
    this.isAccountView = isAccountView;
  }

  returnZero() {
    return 0;
  }
}
