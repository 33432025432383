import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-no-data-card',
  templateUrl: './account-no-data-card.component.html',
  styleUrls: ['./account-no-data-card.component.scss']
})
export class AccountNoDataCardComponent implements OnInit {
@Input() title;
@Input() textBody
  constructor() { }

  ngOnInit() {
  }

}
