import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeatPumpCostEstimationParamsFormComponent } from './heat-pump-cost-estimation-params-form.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormValidationMessageModule } from '../form-validation-message/form-validation-message.module';

@NgModule({
  declarations: [HeatPumpCostEstimationParamsFormComponent],
  imports: [CommonModule, SharedModule, FormValidationMessageModule],
  exports: [HeatPumpCostEstimationParamsFormComponent],
})
export class HeatPumpCostEstimationParamsFormModule {}
