import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelpModalComponent } from '../help-modal/help-modal.component';

@Component({
  selector: 'app-htc-card',
  templateUrl: './htc-card.component.html',
  styleUrls: ['./htc-card.component.scss']
})

export class HtcCardComponent implements OnInit {

  @Input() htcValue;
  @Input() accountPageView;
  @Input() htcConfidenceIntervalNegative;
  @Input() htcConfidenceIntervalPositive;
  @Input() hlpValue;
  @Input() toDate;
  @Input() fromDate;
  @Input() createdAt
  @Input() manualHTC;
  @Input() requiredPeakDemand
  @Input() designInternalTemperature
  @Input() designExternalTemperature

  hlpDefinition: string = 'The normalised thermal performance by usable space (HTC divided by the floor area). Measured in Watts per square meter per degree Celsius (W/m²/°C).'
  HTC = 'Heat Transfer Coefficient (HTC)'
  HLP = 'Heat Loss Parameter (HLP)'
  HTC_CONFIDENCE_INTERVAL = 'HTC Confidence Interval'
  htcDefinition: string = 'Thermal performance during the measurement period. Measured in Watts per degree Celsius (W/°C). Read more about what HTC is <a class="text-info" href="https://glowmarkt.com/home/articles/getting-an-efficiency-score-for-your-home"> here</a>.'
  confidenceIntervalDefinition;
  modalRef;
  requiredPeakDemandDefinition = "The peak heat demand is the maximum heating power requirement for your home on the coldest predicted winter day at your home's location, it is used to determine the required size of heating system (e.g. heat pump) for your home. It is calculated by multiplying the HTC by the internal - external design temperature difference."
  designExternalTemperatureDefinition = "The external temperature is determined based on the location and altitude of the site as per  <a class='text-info' href='https://www.cibse.org/'> CIBSE</a> guidelines."
  designInternalTemperatureDefinition = "The internal temperature is set by default to 20 degrees."

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    this.confidenceIntervalDefinition = `<p>Your HTC confidence interval is [${this.htcConfidenceIntervalNegative}, + ${this.htcConfidenceIntervalPositive}].</p>  <p>No measurement can be made with 100% accuracy. The confidence interval is a range of values that is likely to contain the true value. For example, if the HTC result is 100W/°C with a confidence interval of [-15, +10]W/°C, we think the HTC is 100W/°C and the true value is likely (95% chance) to be between 85W/°C and 110W/°C. <p>`
  }

  openInfoModal(title, definition) {
    this.modalRef = this.modalService.open(HelpModalComponent, { scrollable: true, centered: true, size: 'lg' });
    this.modalRef.componentInstance.pageTitle = title;
    this.modalRef.componentInstance.modalBodyContent = definition
  }

}