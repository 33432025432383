import { Component, Input, OnInit } from '@angular/core';
import { StrapiService } from 'src/app/services/strapi.service';
import { Utils } from 'src/utils';
import _ from 'underscore';

export type CommunityLayout = 'leftVerticalHero' | 'rightHorizontalHero';

@Component({
  selector: 'app-landing-communities-content',
  templateUrl: './landing-communities-content.component.html',
  styleUrls: ['./landing-communities-content.component.scss'],
})
export class LandingCommunitiesContentComponent implements OnInit {
  @Input() community;
  @Input() layout: CommunityLayout;
  featuredContent: any[] = [];
  heroContent: any;

  constructor(private strapiService: StrapiService) {}

  ngOnInit() {
    this.featuredContent = this.getFeaturedContent(this.community)
      .flat()
      .filter((c) => c);

  }
  goToCommunityPage() {
    return `/community/${this.community.attributes.slug}`;
  }

  getFeaturedContent(community) {
    const featuredArticles = community.attributes.featured_articles
      ? community.attributes.featured_articles.data.map((article) =>
          Object.assign(article, { contentType: 'articles' })
        )
      : [];
    const featuredBlogs = community.attributes.featured_blogs
      ? community.attributes.featured_blogs.data.map((blog) => Object.assign(blog, { contentType: 'blogs' }))
      : [];
    const featuredTutorials = community.attributes.featured_tutorials
      ? community.attributes.featured_tutorials.data.map((tutorial) =>
          Object.assign(tutorial, { contentType: 'tutorials' })
        )
      : [];
    const featuredInfographics = community.attributes.featured_infographics
      ? community.attributes.featured_infographics.data.map((infographic) =>
          Object.assign(infographic, { contentType: 'infographics' })
        )
      : [];
    return _.zip(featuredArticles, featuredBlogs, featuredTutorials, featuredInfographics);
  }

}
