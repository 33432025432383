import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateDefaultParser } from '@ngx-translate/core';
import { isTomorrow } from 'date-fns';
import { SchematicsComponent } from 'src/app/pages/schematics/schematics.component';
import { AddRadiatorModalComponent } from '../add-radiator-modal/add-radiator-modal.component';
import { AddRooomModalComponent } from '../add-room-modal/add-room-modal.component';

@Component({
  selector: 'app-floor',
  templateUrl: './floor.component.html',
  styleUrls: ['./floor.component.scss'],
})
export class FloorComponent implements OnInit {
  public floor: any;
  public schematicsComponent: SchematicsComponent;

  rooms: any = {};

  roomOptions = [
    { imgPath: '../../../assets/images/schematics/bedroom.jpeg', title: 'Bedroom', id: 'bedroom' },
    { imgPath: '../../../assets/images/schematics/living-room.jpeg', title: 'Living Room', id: 'livingRoom' },
    { imgPath: '../../../assets/images/schematics/kitchen.jpeg', title: 'Kitchen', id: 'kitchen' },
    { imgPath: '../../../assets/images/schematics/bathroom.jpeg', title: 'Bathroom', id: 'bathroom' },
    { imgPath: '../../../assets/images/schematics/other.jpeg', title: 'Other', id: 'other' },
  ];

  constructor(private modalService: NgbModal) {}

  ngOnInit() {
    this.getRoomNumbers(this.floor);
  }

  displayImage(room) {
    return room.flooring
      ? { 'background-image': `url("../../../assets/images/schematics/${room['flooring'].toLowerCase()}.png")` }
      : { 'background-image': 'url("../../../assets/images/soft-orange.jpg")' };
  }

  getRoomNumbers(floor) {
    for (let roomType of this.roomOptions) {
      this.rooms[roomType.id] = floor.rooms.filter((room) => room.type === roomType.id).length;
    }
  }

  // openAddRoom() {
  //   const addRoomModal = this.modalService.open(AddRooomModalComponent, {
  //     scrollable: true,
  //     // backdrop: 'static',
  //     centered: true,
  //     size: 'xl',
  //   });
  //   addRoomModal.componentInstance.floor = this.floor;
  //   addRoomModal.result.then((result) => {
  //     // console.log(result);
  //     this.openAddRadiator({
  //       type: result.type,
  //       title: `${result.title} ${this.rooms[result.type] + 1}`,
  //       id: `${result.type}${this.rooms[result.type] + 1}`,
  //     });
  //     // if (result.rooms) {
  //     //   // console.log(result);
  //     //   // this.addFloor(result)
  //     //   this.floor = result;
  //     //   this.updateSchematic(this.floor);
  //     // // console.log("schematics",this.schematics)
  //     // }
  //   });
  // }

  // openAddRadiator(room) {
  //   const addRadiatorModal = this.modalService.open(AddRadiatorModalComponent, {
  //     scrollable: true,
  //     // backdrop: 'static',
  //     centered: true,
  //     size: 'xl',
  //   });
  //   addRadiatorModal.componentInstance.room = room;
  //   addRadiatorModal.componentInstance.floor = this.floor;
  //   addRadiatorModal.result.then((result) => {
  //     if (result.radiators || result.height) {
  //       this.updateRoom(result);
  //     }
  //   });
  // }

  // updateRoom(room) {
  //   const index = this.floor.rooms.map((r) => r.id).indexOf(room.id);
  //   if (index > -1) {
  //     room.radiators = room.radiators.map((rad) => {
  //       const updated = { ...rad };
  //       updated.id = rad.id + '-' + room.id;
  //       return updated;
  //     });
  //     this.floor.rooms[index] = { ...room };
  //   } else {
  //     this.rooms[room.type] += 1;
  //     room.id = `${room.type}-${this.floor.id}-${this.rooms[room.type]}`;
  //     room.title = this.roomOptions.find((roomType) => roomType.id === room.type).title + ` ${this.rooms[room.type]}`;
  //     room.radiators = room.radiators.map((rad) => {
  //       const updated = { ...rad };
  //       updated.id = rad.id + '-' + room.id;
  //       return updated;
  //     });
  //     this.floor.rooms.push(room);
  //   }
  //   this.updateEstimatedFloorArea();
  //   this.updateSchematic(this.floor);
  // }

  // updateEstimatedFloorArea() {
  //   this.floor.estimatedArea = this.floor.rooms
  //     .map((room) => room.length * room.width)
  //     .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  // }

  // removeRoom(room) {
  //   const index = this.floor.rooms.map((r) => r.id).indexOf(room.id);
  //   const roomNum = room.title.split(' ').slice(-1)[0];
  //   this.floor.rooms.splice(index, 1);

  //   this.floor.rooms = this.floor.rooms.map((r) => {
  //     const rNum = r.title.split(' ').slice(-1)[0];
  //     if (rNum > roomNum && r.type == room.type) {
  //       const updatedRoom = { ...r };
  //       const splitTitle = room.title.split(' ');
  //       splitTitle[splitTitle.length - 1] = String(Number(rNum) - 1);
  //       updatedRoom.title = splitTitle.join(' ');
  //       updatedRoom.id = splitTitle.join('');
  //       return updatedRoom;
  //     } else {
  //       return r;
  //     }
  //   });
  //   this.rooms[room.type] -= 1;
  //   this.updateEstimatedFloorArea();
  //   this.updateSchematic(this.floor);
  // }

  // getRadiatorsArray(room) {
  //   return room.radiators ? Object.values(room.radiators).flat() : [];
  // }

  // getRadSvgPath(rad) {
  //   return `../../../assets/images/schematics/${rad.type}Icon.svg`;
  // }

  // removeSelf() {
  //   this.schematicsComponent.removeFloor(this.floor.id);
  // }

  // updateSchematic(floor) {
  //   this.schematicsComponent.schematics.floors = this.schematicsComponent.schematics.floors.map((f) => {
  //     if (f.id === floor.id) {
  //       f = { ...floor };
  //     }
  //     return f;
  //   });
  //   this.schematicsComponent.setSchematics();
  // }
}
