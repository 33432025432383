import { Component, Input, OnInit } from '@angular/core';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-case-study-summary',
  templateUrl: './case-study-summary.component.html',
  styleUrls: ['./case-study-summary.component.scss'],
})
export class CaseStudySummaryComponent implements OnInit {
  @Input() caseStudy;
  @Input() caseStudyRelatedContentConfig;
  @Input() colour;
  @Input() isAccountView;
  constructor() {}

  ngOnInit() {}

  getDefaultText() {
    return (this.isAccountView && 'To be completed') || 'Unknown';
  }

  setInputs({ caseStudy, caseStudyRelatedContentConfig, colour, isAccountView }) {
    this.caseStudy = caseStudy;
    this.caseStudyRelatedContentConfig = caseStudyRelatedContentConfig;
    this.colour = colour;
    this.isAccountView = isAccountView;
  }

  tariffDisplay(tariff) {
    if (!tariff || tariff.length == 0) {
      return 'Unknown';
    } else if (tariff.type && !tariff.unitRate && tariff.standingCharge) {
      return `${tariff.type} (Standing charge: ${tariff.standingCharge}p/day)`;
    } else if (tariff.type && tariff.unitRate && !tariff.standingCharge) {
      return `${tariff.type} (Unit rate: ${tariff.unitRate}p/kWh)`;
    } else if (tariff.type && !tariff.unitRate && !tariff.standingCharge) {
      return tariff.type;
    }
    return `${tariff.type} (Unit rate: ${tariff.unitRate}p/kWh, Standing charge: ${tariff.standingCharge}p/day)`;
  }

  calculateNetCosts(totalCost, awardedGrants) {
    if (!totalCost || !(awardedGrants && awardedGrants.length)) {
      return this.getDefaultText();
    }
    if (!awardedGrants || awardedGrants.length == 0) {
      return '£' + totalCost;
    }
    const totalGrantAmount = awardedGrants.reduce((acc, ag) => {
      const accAmountWon = acc.amount || 0;
      const agAmountWon = ag.amount || 0;
      return accAmountWon + agAmountWon;
    }, 0);
    return '£' + (totalCost - totalGrantAmount);
  }

  calculateCarbonEmissionsReduction(postInstallPropertyDescription, preInstallPropertyDescription) {
    if (!preInstallPropertyDescription || !postInstallPropertyDescription || !preInstallPropertyDescription.carbonEmissions || !postInstallPropertyDescription.carbonEmissions) {
      return this.getDefaultText();
    }
    return preInstallPropertyDescription.carbonEmissions - postInstallPropertyDescription.carbonEmissions;
  }

  calculateHeatingNetRunningCosts(heatingSystemsArray) {
    if (!heatingSystemsArray) {
      return this.getDefaultText();
    }
    const heatingSystemRunningCosts = heatingSystemsArray.reduce((acc, hs) => {
      const accRunningCost = acc['gas.cost'] ? acc['gas.cost'].totalValue : acc['electricity.cost'] ? acc['electricity.cost'].totalValue : 0;
      const hsRunningCost = hs['gas.cost'] ? hs['gas.cost'].totalValue : hs['electricity.cost'] ? hs['electricity.cost'].totalValue : 0;
      return accRunningCost + hsRunningCost;
    }, 0);
    if (heatingSystemRunningCosts == 0) {
      return this.getDefaultText();
    }
    return '£' + heatingSystemRunningCosts;
  }

  getDate(date ){
    return Utils.getReadableDateFromIso(date)
  }
}
