import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { JsonLdService } from 'src/app/services/json-ld.service';
import { MetaTagsService } from 'src/app/services/meta-tags.service';
import { SeoService } from 'src/app/services/seo.service';
import { StrapiService } from 'src/app/services/strapi.service';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
})
export class ArticleComponent implements OnInit {
  article: any;
  url;
  loading: boolean = true;
  constructor(
    private route: ActivatedRoute,
    private strapiService: StrapiService,
    private router: Router,
    private seoService: SeoService
  ) {
    route.params.subscribe((params) => {
      this.getUrl();
      this.getArticle(params.article).then((article) => {
        this.article = article;
        if (article) {
          this.configureSEO(article);
        }
        this.loading = false;
      });
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.seoService.resetSEOconfig();
  }
  async getArticle(slug) {
    const article = await this.strapiService.getContentBySlug('articles', slug, ['tags', 'authors', 'blogs', 'articles', 'infographics']);
    return article;
  }

  configureSEO(article) {
    const title = Utils.removeHTMLFromString(article.attributes.title);
    const summary = Utils.removeHTMLFromString(article.attributes.summary);
    const authorNames = article.attributes.authors.data.map((author) => author.attributes.name);
    const image = this.article.attributes.displayImage.data
      ? this.strapiService.getFileFullUrl(this.article.attributes.displayImage.data.attributes.url)
      : '';
    const schema = JsonLdService.articleSchema(title, summary, [image], authorNames);
    this.seoService.configureSEO(title, summary, `https://glowmarkt.com${this.router.url}`, schema);
  }
  getUrl() {
    this.url = this.route.snapshot.url.map((segment) => segment.path);
  }
}
