import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { tLoadingElement } from 'src/app/services/app-config.typings';
import { FormValidationService } from 'src/app/services/form-validation.service';
import { GlowService } from 'src/app/services/glow.service';
import { InvitationsService } from 'src/app/services/invitations.service';
import { ProfessionalGlowService } from 'src/app/services/professional-glow.service';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-quote-modal',
  templateUrl: './quote-modal.component.html',
  styleUrls: ['./quote-modal.component.scss']
})
export class QuoteModalComponent implements OnInit {
  fileForm: FormGroup
  @Input() estimateRequestId
  thumbnail;
  formError: boolean;
  loadObj: tLoadingElement
  success: boolean

  constructor(private activeModal: NgbActiveModal, private glowService: GlowService,
    private fb: FormBuilder, private formValidationService: FormValidationService, 
    private invitationsService: InvitationsService,
    private professionalGlowService: ProfessionalGlowService) {
    this.fileForm = this.fb.group({
      file: ['', [Validators.required]],
      amount: ['', [Validators.required]],

    })
  }

  ngOnInit() {
    this.loadObj = { ...this.loadObj, loadError: false, loading: false, errorString: '' };

  }
  async fileInputChange(event, controlName, form: FormGroup) {
    const files = event.target.files[0];
    form.get(controlName).setValue(files)
    await this.setImageSourceAndClass(files)


  }
  async setImageSourceAndClass(blob) {
    const reader = new FileReader()
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      this.thumbnail = reader.result;
    };
  }



  async checkFileChangeAndUpload(controlName, form) {
    let file = form.controls[controlName].value
    file = { file: file, 'estimateType': 'heat-pump-estimate', 'amount': this.fileForm.value.amount }
    const formData: any = await Utils.toFormData(file)
    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }
    this.updateFile(this.estimateRequestId, formData)
  }

  async updateDetails(controlName, form: FormGroup) {   
       this.loadObj.loading = true

    console.log(form)
    if (form.valid) {
      this.checkFileChangeAndUpload(controlName, form)
    } else {
      this.formError = true
      this.fileForm.markAllAsTouched()
    }
  }

  updateFile(estimateRequestId: string, data: any) {
    this.professionalGlowService.uploadQuoteOrEstimate(data, estimateRequestId).subscribe((resp: any) => {

      if (resp.valid) {
        this.loadObj = {
          ...this.loadObj,
          loadError: false,
          loading: false,
        };
        this.success = true
        this.invitationsService.getEstimateRequestById(this.estimateRequestId)

      } else {
        this.loadObj = {
          ...this.loadObj,
          loadError: true,
          loading: false,
          errorString: 'An error occurred. Please try again later.'
        };
      }
    }, (err) => {
      this.loadObj = {
        ...this.loadObj,
        loadError: true,
        loading: false,
        errorString: 'An error occurred. Please try again later.'
      };
    }
    )
  }

  checkFormValidity(form, formControlName, translateKey?) {
    return this.formValidationService.checkFormValidity(form, formControlName, translateKey)
  }
}
