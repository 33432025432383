import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EpcInfoModalComponent } from './epc-info-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerModule } from '../spinner/spinner.module';
import { HeatLossParameterScaleModule } from '../heat-loss-parameter-scale/heat-loss-parameter-scale.module';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormValidationMessageModule } from '../form-validation-message/form-validation-message.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [EpcInfoModalComponent],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    SpinnerModule,
    FormsModule,
    HeatLossParameterScaleModule,
    NgbPopoverModule,
    NgSelectModule,
    FormValidationMessageModule
  ],
  exports: [EpcInfoModalComponent],
  entryComponents: [EpcInfoModalComponent],
  
})

export class EpcInfoModalModule { }
