import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReceivedInvitationsComponent } from './received-invitations.component';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [ReceivedInvitationsComponent],
  imports: [
    CommonModule, SharedModule
  ],
  exports: [ReceivedInvitationsComponent]
})
export class ReceivedInvitationsModule { }
