import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { shareReplay } from 'rxjs/operators';
import { FormValidationService } from 'src/app/services/form-validation.service';
import { GlowService } from 'src/app/services/glow.service';
import { MeterPointVerificationConsentManagementService } from 'src/app/services/meter-point-verification-consent-management.service';
import { PostcodeCheckerService } from 'src/app/services/postcode-checker.service';
import { TermsConditionsService } from 'src/app/services/termsconditions.service';
import { FormValidator } from 'src/app/services/user-validators';
import { VirtualEntityService } from 'src/app/services/virtual-entity.service';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-setup-smart-meter-modal',
  templateUrl: './setup-smart-meter-modal.component.html',
  styleUrls: ['./setup-smart-meter-modal.component.scss'],
})
export class SetupSmartMeterModalComponent implements OnInit {
  @Input() type: string;
  @Input() parentActivatedRoute;
  formErrorMessage;
  modalRef: any;
  loading: boolean = false;
  displayImages: boolean = false;
  ihdForm: FormGroup;
  postcodeObj: any = { area: '', isValid: false, error: false };
  euiObj: any = { error: '', missingElements: [], incorrectElements: [], isValid: false };
  mpanForm: FormGroup;
  mpanObj: any = { error: '', isValid: false };

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private postcodeCheckerService: PostcodeCheckerService,
    private glowService: GlowService,
    private formValidationService: FormValidationService,
    private veService: VirtualEntityService,
    private meterPointVerificationService: MeterPointVerificationConsentManagementService,
    private termsConditionsService: TermsConditionsService
  ) {
    // listen to  query params
    this.route.queryParams.subscribe((params) => {
      if (params['type']) {
        this.type = params['type'];
        this.initForms();
      }
      console.log('params', params);
    });
  }

  ngOnInit() {
    this.initForms();
    this.termsConditionsService.checkIfUserHasAcceptedCurrentTermsAndConditions().then((userTcObj) => {
      console.log('userTcObj', userTcObj);
    });
  }

  initForms() {
    switch (this.type) {
      case 'ihd':
        this.initIhdForm();
        break;
      case 'mpan':
        this.initMpanForm();
        break;
      default:
        break;
    }
  }

  // ************* MODAL OPERATIONS *******************
  close() {
    this.modalService.dismissAll();
    this.router.navigate(['..'], { relativeTo: this.parentActivatedRoute });
    this.meterPointVerificationService.getVerificationStatus();
  }

  // ************* START SCREEN *******************

  navigateToIHD() {
    this.router.navigate(['.'], { relativeTo: this.parentActivatedRoute, queryParams: { type: 'ihd' } });
  }

  navigateToMPAN() {
    this.router.navigate(['.'], { relativeTo: this.parentActivatedRoute, queryParams: { type: 'mpan' } });
  }

  navigateToBright() {
    this.router.navigate(['.'], { relativeTo: this.parentActivatedRoute, queryParams: { type: 'bright' } });
  }

  // ************* IHD SCREEN *******************

  initIhdForm() {
    this.ihdForm = this.fb.group({
      postcode: ['', [Validators.required]],
      eui: ['', [Validators.required, FormValidator.isEUIValid]],
    });
  }

  showImages() {
    this.displayImages = !this.displayImages;
  }

  async checkPostcode() {
    let messages = [];
    await this.checkPostcodeIsValid().then((resp) => {
      if (!this.postcodeObj.isValid) {
        messages.push('Invalid postcode');
      }
      return messages;
    });
    return;
  }
  showErrorMessage() {
    if (this.type == 'ihd') {
      if (!this.ihdForm.valid) {
        this.formErrorMessage = true;
        this.formValidationService.markFormGroup(this.ihdForm, 'touched');
      }
    } else if (!this.mpanForm.valid && this.type == 'mpan') {
      this.formErrorMessage = true;
      this.formValidationService.markFormGroup(this.mpanForm, 'touched');
    }
  }
  resetErrorMsg() {
    this.formErrorMessage = false;
  }
  async checkPostcodeIsValid() {
    let postcode;
    if (this.ihdForm.get('postcode').dirty) {
      postcode = this.ihdForm.value.postcode;
      // console.log(postcode)

      await this.postcodeCheckerService
        .checkPostcode(postcode)
        .then((resp: any) => {
          // // console.log('IS POSTCODEVALID', resp);
          this.postcodeObj.area = resp.result['admin_district'];
          this.postcodeObj.isValid = true;
          this.postcodeObj.error = false;
        })
        .catch((err) => {
          console.warn('postcode errr', err);
          this.postcodeObj.area = '';
          this.postcodeObj.isValid = false;
          this.postcodeObj.error = true;
        });
    }
  }

  checkFormValidity(formControlName, translateKey?) {
    if (this.type == 'ihd') {
      return this.formValidationService.checkFormValidity(this.ihdForm, formControlName, translateKey);
    } else if (this.type == 'mpan') {
      return this.formValidationService.checkFormValidity(this.mpanForm, formControlName, translateKey);
    }
  }

  sanitiseEUI(eui) {
    return eui.replace(/-|\s+/g, '');
  }
  async checkEui() {
    const eui = this.sanitiseEUI(this.ihdForm.value.eui);
    const postcode = this.ihdForm.value.postcode;
    await this.glowService
      .checkSmetsIhdPostcodeIsEligible({ postcode: postcode, eui: eui })
      .toPromise()
      .then((resp: any) => {
        this.euiObj = resp.valid
          ? { error: '', missingElements: [], incorrectElements: [], isValid: resp.valid }
          : {
              error: 'Postcode and EUI do not match',
              missingElements: [],
              incorrectElements: [],
              isValid: resp.valid,
            };
        // // console.log('RESPP', resp);
      })
      .catch((err) => {
        console.warn('ERRRR', err);
        this.euiObj = {
          error: err.error.error,
          missingElements: err.error.missingElements,
          incorrectElements: err.error.incorrectElements,
          isValid: err.error.isValid,
        };
      });
  }

  async checkPostcodeAndEui() {
    // // console.log('Checking postcode and eui');
    await this.checkEui();
    await this.checkPostcode();
  }

  async onboard() {
    if (!this.ihdForm.valid) {
      this.showErrorMessage();
    } else {
      this.loading = true;
      const eui = this.sanitiseEUI(this.ihdForm.value.eui);
      const postcode = this.ihdForm.value.postcode;
      await this.checkPostcodeAndEui();
      // // console.log('**********', this.euiObj.isValid && this.postcodeObj.isValid);
      if (this.euiObj.isValid && this.postcodeObj.isValid) {
        await this.glowService
          .onboardSmartMeterEuiPostcode({ eui, postcode })
          .toPromise()
          .then((resp) => {
            // // console.log('onboard resp', resp);
            this.navigateToSuccess();
            // this.loading = false;
          })
          .catch((err) => {
            console.warn('onboard err', err);
            this.euiObj = { error: 'Something went wrong - please try again later', missingElements: [], incorrectElements: [], isValid: false };
            this.loading = false;
          });
      }
    }
    this.loading = false;
  }

  navigateToSuccess() {
    this.veService.findVirtualEntities();
    this.router.navigate(['.'], { relativeTo: this.parentActivatedRoute, queryParams: { type: 'success' } });
  }

  // ************* MPAN SCREEN *******************

  initMpanForm() {
    this.mpanForm = this.fb.group({
      mpan: ['', [Validators.required, FormValidator.isMpanValid]],
    });
  }

  updateMpanObj() {
    this.mpanObj = this.mpanForm.value.mpan.length > 12 && !this.mpanForm.valid ? { isValid: false, error: 'Invalid MPAN' } : { error: '', isValid: false };
  }

  async checkMpan() {
    if (!this.mpanForm.valid) {
      this.showErrorMessage();
    } else {
      this.loading = true;
      const mpxn = this.mpanForm.value.mpan;
      const [error, mpxnEligiblity] = await Utils.promiseHandler(this.glowService.checkSmetsMpxnIsEligible({ mpxn }).toPromise());
      if (error) {
        console.warn('mpan errrr', error);
        this.mpanObj = { isValid: false, error: 'Validation error - please check your details' };
        this.loading = false;
        return;
      }

      if (mpxnEligiblity && mpxnEligiblity.valid) {
        const userTcObj = await this.termsConditionsService.checkIfUserHasAcceptedCurrentTermsAndConditions();
        const consent = { tcId: userTcObj.record.tcId, reference: userTcObj.record.reference, acceptedAt: userTcObj.record.createdAt, data: {} };
        const [onBoardError, onBoardResp] = await Utils.promiseHandler(this.glowService.onboardSmartMeterMpxnAvs({ consent, mpxn }).toPromise());
        this.loading = false;
        if (onBoardError) {
          console.warn('onboard err', onBoardError);
          this.mpanObj = { isValid: false, error: 'Validation error - please check your details' };
          return;
        }
        if (onBoardResp) {
          console.log('onboard resp', onBoardResp);
        }
        this.navigateToEligible();
      } else {
        this.navigateToNotEligible();
      }
    }
  }

  navigateToEligible() {
    this.router.navigate(['.'], { relativeTo: this.parentActivatedRoute, queryParams: { type: 'eligible' } });
  }

  navigateToNotEligible() {
    this.router.navigate(['.'], { relativeTo: this.parentActivatedRoute, queryParams: { type: 'not-eligible' } });
  }
}
