import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingComponent } from './landing.component';
import { ContentCardModule } from 'src/app/components/content-card/content-card.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [LandingComponent],
  imports: [CommonModule, SharedModule, ContentCardModule],
  exports: [LandingComponent]
})

export class LandingModule { }