import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LandingService } from 'src/app/services/landing.service';

@Component({
  selector: 'app-community-card',
  templateUrl: './community-card.component.html',
  styleUrls: ['./community-card.component.scss']
})
export class CommunityCardComponent implements OnInit {
  @Input() community:any
  constructor(private landingService:LandingService, private router:Router) { }

  ngOnInit() {
  }

 
  



  glossaryRelativePath(){
    return `/glossary?q[tags]=${this.community.name}`
  }
  resourcesRelativePath(resourcesList){
    return `/home/resources-lists/${resourcesList.attributes.slug}`
  }
  communityRelativePath(community) {
    return `/about-community/${community.slug}`;
  }

}
