import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuildingFabricComponent } from './building-fabric.component';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [BuildingFabricComponent],
  imports: [
    CommonModule, SharedModule
  ],
  exports: [
    BuildingFabricComponent,
  ]
})
export class BuildingFabricModule { }
