import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { HpJourneyModalComponent } from '../components/hp-journey-modal/hp-journey-modal.component';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
})
export class PagesComponent implements OnInit {
  url!: String;
  pageTitle!: string;
  currentPage!: string;
  currentPageSubscription: Subscription;
  constructor(private router: Router, private modalService: NgbModal) {
    this.url = this.router.url;
  }

  ngOnInit(): void {
   
  }

  ngOnDestroy() {
    if (this.currentPageSubscription) {
      this.currentPageSubscription.unsubscribe();
    }
  }
  
  public onRouterOutletActivate(event: any) {
    if (event.pageTitle) {
      this.pageTitle = event.pageTitle;
    }
    if (event.currentPage$) {
      this.currentPageSubscription = event.currentPage$.subscribe((currPage) => {
        this.currentPage = currPage;
      });
    }
  }
  openModal() {
    this.modalService.open(HpJourneyModalComponent, {
      scrollable: true,
      centered: true,
      size: 'xl',
    });
  }

}
