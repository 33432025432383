import { EventEmitter, Injectable } from '@angular/core';
import { GlowService } from './glow.service';
import { ProfessionalGlowService } from './professional-glow.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceAgentService {
  serviceAgentAnnouncer: EventEmitter<any> = new EventEmitter
  constructor(private professionalGlowService: ProfessionalGlowService) {

  }

  async getServiceAgent() {
    this.professionalGlowService.getServiceAgent().subscribe( resp => {
        this.announceServiceAgent(resp)
    });
  }
   announceServiceAgent(serviceAgent) {
    this.serviceAgentAnnouncer.emit(serviceAgent)
  }
}
