import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { PagesProfessionalComponent } from './pages-professional.component';
import { PagesProfessionalRouting } from './pages-professional.routing';
import { LandingModule } from './landing/landing.module';
import { AccountPagesModule } from './account-pages/account-pages.module';
import { PersonalDetailsModule } from './account-pages/professional-settings/personal-details/personal-details.module';
import { ProfessionalSettingsModule } from './account-pages/professional-settings/professional-settings.module';
import { NotificationsModule } from './account-pages/notifications/notifications.module';
import { PropertyModule } from './property/property.module';
import { HomeEnergyDataModule } from './property/home-energy-data/home-energy-data.module';
import { QuotesModule } from './property/quotes/quotes.module';
import { NotificationModule } from '../components/notification/notification.module';
import { AllNotificationsModule } from './account-pages/notifications/all-notifications/all-notifications.module';
import { InvitationsModule } from './account-pages/invitations/invitations.module';
import { SentInvitationsModule } from './account-pages/invitations/sent-invitations/sent-invitations.module';
import { InstallerInvitationModalModule } from '../components/installer-invitation-modal/installer-invitation-modal.module';
import { ReceivedInvitationsModule } from './account-pages/invitations/received-invitations/received-invitations.module';
import { ProfileModule } from './account-pages/professional-settings/profile/profile.module';
import { InstallerSupportModalModule } from '../components/installer-support-modal/installer-support-modal.module';
import { CommonModule } from '@angular/common';
import { QuoteModalModule } from '../components/quote-modal/quote-modal.module';

@NgModule({
  declarations: [PagesProfessionalComponent],
  imports: [
    CommonModule,
    PagesProfessionalRouting,
    LandingModule,
    AccountPagesModule,
    ProfessionalSettingsModule,
    PersonalDetailsModule,
    SentInvitationsModule,
    NotificationsModule,
    PropertyModule,
    HomeEnergyDataModule,
    QuotesModule,
    NotificationModule,
    AllNotificationsModule,
    InvitationsModule,
    InstallerInvitationModalModule,
    ReceivedInvitationsModule,
    InstallerSupportModalModule,
    ProfileModule,
    QuoteModalModule
    
    
    
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class PagesProfessionalModule { }