import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlowService } from 'src/app/services/glow.service';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';
import { StrapiService } from 'src/app/services/strapi.service';

@Component({
  selector: 'app-htc-full-info',
  templateUrl: './htc-full-info.component.html',
  styleUrls: ['./htc-full-info.component.scss'],
})
export class HtcFullInfoComponent implements OnInit {
  accountPageView: boolean;
  pageTitle: string = 'Heat retention score';
  displayTitle: string = 'Your home’s heat retention score';
  toolLink: string = '/pages/tools/estimated-thermal-performance';
  shouldShowToolLink: boolean = false;
  selectedService: any;
  manualHTC: any;
  loadObj;
  HTCDate;
  loading = true;
  htcDefinition: any;
  noManualHTCMessage: string = '<span>No information available; to calculate yours use our tool <a href="/pages/tools/estimated-thermal-performance" class="text-info">here</a>. </span>';
  subscriptionSelectedService: Subscription;
  constructor(private serviceSelectorSvc: ServiceSelectorSvc, private route: ActivatedRoute, private glowService: GlowService, private router: Router, private strapService: StrapiService) {
    this.subscriptionSelectedService = this.serviceSelectorSvc.serviceSet.subscribe((selectedService) => {
      this.selectedService = selectedService;
      this.getLatestHTC();
    });
  }

  ngOnInit() {
    this.strapService.getOneContent('glossary-terms', 10, []).then((t) => (this.htcDefinition = t.attributes.shortDefinition));
    this.selectedService = this.serviceSelectorSvc.getSelectedService();
    // this.checkForManualHTC()
    this.getUrl();
    this.getLatestHTC();
  }

  getLatestHTC() {
    this.glowService.getHTCHistory(this.selectedService.veId).subscribe(
      async (resp: any) => {
        if (resp.valid) {
          this.shouldShowToolLink = true;
          this.manualHTC = resp.data[0];
          this.HTCDate = new Date(resp.data[0].created_date).toLocaleDateString('en-GB');
          this.loading = false;
        } else {
          console.log(this.manualHTC);
          this.loading = false;
          this.shouldShowToolLink = false;
          this.loadObj = { ...this.loadObj, loadError: true, loading: false };
        }
      },
      (err) => {
        this.manualHTC = '';
        this.loading = false;
        this.loadObj = { ...this.loadObj, loadError: true, loading: false };
      }
    );
  }
  regenerateHTC() {
    this.manualHTC = false;
    // this.router.navigateByUrl('/pages/tools/estimated-thermal-performance');
  }
  getUrl() {
    //check if on acc pages
    let url = this.route.snapshot.url.map((segment) => segment.path);
    if (url[0] == 'building-fabric') {
      this.accountPageView = true;
    }
  }

  formatHouseAttachmentString(str) {
    return str.replace(/([A-Z][a-z])/g, ' $1');
  }
}
