import { EventEmitter, Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { caseStudyFields } from '../../assets/content/case-study-fields.json';
import { GlowService } from './glow.service';
import { tCaseStudy } from './glow.typings';
import { ServiceSelectorSvc } from './service-selector.service';
import { Utils } from 'src/utils';
import { CaseStudyService } from './case-study.service';
import { Router } from '@angular/router';
import { FormValidationService } from './form-validation.service';
import { FormValidator } from './user-validators';

@Injectable({
  providedIn: 'root',
})
export class CaseStudyFormService {
  caseStudyFields = caseStudyFields;
  caseStudy: tCaseStudy;
  formattedCaseStudy: any = {};
  journal: any;

  otherInformationForm: FormGroup;

  caseStudyAnnouncer: EventEmitter<any> = new EventEmitter();
  journalAnnouncer: EventEmitter<any> = new EventEmitter();
  formAnnouncer: EventEmitter<any> = new EventEmitter();
  caseStudySubmitSuccessAnnouncer: EventEmitter<any> = new EventEmitter();

  selectedService: any;

  subscriptionSelectedService: any;

  locationForm: FormGroup;

  heatingSystemPreForm: FormGroup;
  heatingSystemPostForm: FormGroup;

  buildingPreForm: FormGroup;
  hasDoneRetrofitForm: FormGroup;
  buildingPostForm: FormGroup;

  microgeneratorPreForm: FormGroup;
  microgeneratorPostForm: FormGroup;

  hasMicrogeneratorPreForm: FormGroup;
  hasMicrogeneratorPostForm: FormGroup;

  hasEnergyStoragePreForm: FormGroup;
  hasEnergyStoragePostForm: FormGroup;

  energyStoragesPreForm: FormGroup;
  energyStoragesPostForm: FormGroup;

  hasCarbonEmissionsPreForm: FormGroup;
  hasCarbonEmissionsPostForm: FormGroup;

  carbonEmissionsPreForm: FormGroup;
  carbonEmissionsPostForm: FormGroup;

  installationForm: FormGroup;
  installerForm: FormGroup;
  financeForm: FormGroup;
  hasReceivedGrantsForm: FormGroup;
  awardedGrantsForm: FormGroup;
  hasReceivedLoansForm: FormGroup;
  awardedLoansForm: FormGroup;
  satisfactionForm: FormGroup;

  formsBuilder: any = [];
  sections: any = [];

  hasInitServiceVariables: boolean = false;
  isInitialisingServiceVariables: boolean = false;

  constructor(private fb: FormBuilder, private glowService: GlowService, private serviceSelectorService: ServiceSelectorSvc, private caseStudyService: CaseStudyService, private router: Router, private formValidationService: FormValidationService) {
    // this.initServiceVariables(this.serviceSelectorService.getSelectedService()).then();
  }

  async initServiceVariables(selectedService) {
    if(this.isInitialisingServiceVariables) return;
    this.hasInitServiceVariables= false;
    this.isInitialisingServiceVariables = true;
    
    this.selectedService = selectedService;
    this.setFormSections();
    const journal = await this.getJournal();
    if (!journal) {
      this.journal = await this.initEmptyJournal();
    }
    let caseStudy = await this.getCaseStudy();
    if (!caseStudy) {
      caseStudy = await this.initEmptyCaseStudy();
    }
    this.setAndAnnounceJournal(journal);
    const formattedCaseStudy = await this.caseStudyService.fetchCaseStudyData(caseStudy);
    this.setFormattedCaseStudy(formattedCaseStudy);
    this.setAndAnnounceCaseStudy(caseStudy, formattedCaseStudy);
    
    await this.setFormsBuilder();
    this.initForms();
    this.announceForm('all', true);
    this.hasInitServiceVariables = true;
    this.isInitialisingServiceVariables = false;
  }

  async setFormsBuilder() {
    this.formsBuilder = [
      { formName: 'locationForm', caseStudyFieldCategory: 'location', value: this.formattedCaseStudy, formSection: 'pre-installation-building', shouldInit: true },
      { formName: 'buildingPreForm', caseStudyFieldCategory: 'building', value: await this.getBuildingPreFormValue(), formSection: 'pre-installation-building', shouldInit: true },
      {
        formName: 'hasDoneRetrofitForm',
        caseStudyFieldCategory: 'hasDoneRetrofit',
        value: { isTrue: this.hasDoneRetrofit() },
        formSection: 'post-installation-building',
        shouldInit: true,
      },
      {
        formName: 'buildingPostForm',
        caseStudyFieldCategory: 'buildingPost',
        value: this.formattedCaseStudy.after.building && this.formattedCaseStudy.after.building[0],
        formSection: 'post-installation-building',
        shouldInit: this.hasDoneRetrofit(),
      },
      {
        formName: 'hasCarbonEmissionsPreForm',
        caseStudyFieldCategory: 'hasCarbonEmissions',
        value: { isTrue: Boolean(this.formattedCaseStudy.before.building && this.formattedCaseStudy.before.building[0].carbon_emissions) },
        formSection: 'pre-installation-building',
        shouldInit: true,
      },
      {
        formName: 'carbonEmissionsPreForm',
        caseStudyFieldCategory: 'carbonEmissions',
        value: this.formattedCaseStudy.before.building && this.formattedCaseStudy.before.building[0].carbon_emissions,
        formSection: 'pre-installation-building',
        shouldInit: Boolean(this.formattedCaseStudy.before.building && this.formattedCaseStudy.before.building[0].carbon_emissions),
      },
      {
        formName: 'hasCarbonEmissionsPostForm',
        caseStudyFieldCategory: 'hasCarbonEmissions',
        value: { isTrue: Boolean(this.formattedCaseStudy.after.building && this.formattedCaseStudy.after.building[0].carbon_emissions) },
        formSection: 'post-installation-building',
        shouldInit: true,
      },
      {
        formName: 'carbonEmissionsPostForm',
        caseStudyFieldCategory: 'carbonEmissions',
        value: this.formattedCaseStudy.after.building && this.formattedCaseStudy.after.building[0].carbon_emissions,
        formSection: 'post-installation-building',
        shouldInit: Boolean(this.formattedCaseStudy.after.building && this.formattedCaseStudy.after.building[0].carbon_emissions),
      },
      { formName: 'heatingSystemPreForm', caseStudyFieldCategory: 'heatingSystem', value: this.formattedCaseStudy.before.heating_system, arrayName: 'heatingSystems', minLen: 1, formSection: 'pre-installation-heating', shouldInit: true },
      { formName: 'heatingSystemPostForm', caseStudyFieldCategory: 'heatingSystem', value: this.formattedCaseStudy.after.heating_system, arrayName: 'heatingSystems', minLen: 1, formSection: 'post-installation-heating', shouldInit: true },
      {
        formName: 'hasMicrogeneratorPreForm',
        caseStudyFieldCategory: 'hasMicrogenerator',
        value: { isTrue: Boolean(this.formattedCaseStudy.before.microgenerator && this.formattedCaseStudy.before.microgenerator.length > 0) },
        formSection: 'pre-installation-heating',
        shouldInit: true,
      },
      {
        formName: 'microgeneratorPreForm',
        caseStudyFieldCategory: 'microgenerator',
        value: this.formattedCaseStudy.before.microgenerator,
        arrayName: 'microgenerators',
        formSection: 'pre-installation-heating',
        shouldInit: Boolean(this.formattedCaseStudy.before.microgenerator && this.formattedCaseStudy.before.microgenerator.length > 0),
      },
      {
        formName: 'hasMicrogeneratorPostForm',
        caseStudyFieldCategory: 'hasMicrogenerator',
        value: { isTrue: Boolean(this.formattedCaseStudy.after.microgenerator && this.formattedCaseStudy.after.microgenerator.length > 0) },
        formSection: 'post-installation-heating',
        shouldInit: true,
      },
      {
        formName: 'microgeneratorPostForm',
        caseStudyFieldCategory: 'microgenerator',
        value: this.formattedCaseStudy.after.microgenerator,
        arrayName: 'microgenerators',
        formSection: 'post-installation-heating',
        shouldInit: Boolean(this.formattedCaseStudy.after.microgenerator && this.formattedCaseStudy.after.microgenerator.length > 0),
      },
      {
        formName: 'hasEnergyStoragePreForm',
        caseStudyFieldCategory: 'hasEnergyStorage',
        value: { isTrue: Boolean(this.formattedCaseStudy.before.energy_storage && this.formattedCaseStudy.before.energy_storage.length > 0) },
        formSection: 'pre-installation-heating',
        shouldInit: true,
      },
      {
        formName: 'energyStoragePreForm',
        caseStudyFieldCategory: 'energyStorage',
        value: this.formattedCaseStudy.before.energy_storage,
        arrayName: 'energyStorages',
        formSection: 'pre-installation-heating',
        shouldInit: Boolean(this.formattedCaseStudy.before.energy_storage && this.formattedCaseStudy.before.energy_storage.length > 0),
      },
      {
        formName: 'hasEnergyStoragePostForm',
        caseStudyFieldCategory: 'hasEnergyStorage',
        value: { isTrue: Boolean(this.formattedCaseStudy.after.energy_storage && this.formattedCaseStudy.after.energy_storage.length > 0) },
        formSection: 'post-installation-heating',
        shouldInit: true,
      },
      {
        formName: 'energyStoragePostForm',
        caseStudyFieldCategory: 'energyStorage',
        value: this.formattedCaseStudy.after.energy_storage,
        arrayName: 'energyStorages',
        formSection: 'post-installation-heating',
        shouldInit: Boolean(this.formattedCaseStudy.after.energy_storage && this.formattedCaseStudy.after.energy_storage.length > 0),
      },
      { formName: 'installationForm', caseStudyFieldCategory: 'installation', value: this.getHPInstallationValueFromCs(), formSection: 'installation', shouldInit: true },
      { formName: 'installerForm', caseStudyFieldCategory: 'installer', value: this.getHPInstallationValueFromCs() && this.getHPInstallationValueFromCs().installers, arrayName: 'installers', minLen: 1, formSection: 'installation', shouldInit: true },
      { formName: 'financeForm', caseStudyFieldCategory: 'finance', value: this.formattedCaseStudy.outcome.heating_system && this.formattedCaseStudy.outcome.heating_system[0].finance, formSection: 'post-installation-outcomes', shouldInit: true },
      {
        formName: 'hasReceivedGrantsForm',
        caseStudyFieldCategory: 'hasReceivedGrants',
        value: {
          isTrue: Boolean(this.getAwardedGrantsValueFromCs() && this.getAwardedGrantsValueFromCs().length > 0),
        },
        formSection: 'post-installation-outcomes',
        shouldInit: true,
      },
      {
        formName: 'awardedGrantsForm',
        caseStudyFieldCategory: 'awardedGrant',
        value: this.getAwardedGrantsValueFromCs(),
        arrayName: 'awardedGrants',
        formSection: 'post-installation-outcomes',
        shouldInit: true,
      },
      {
        formName: 'hasReceivedLoansForm',
        caseStudyFieldCategory: 'hasReceivedLoans',
        value: { isTrue: Boolean(this.getAwardedLoansValueFromCs() && this.getAwardedLoansValueFromCs().length > 0) },
        formSection: 'post-installation-outcomes',
        shouldInit: true,
      },
      {
        formName: 'awardedLoansForm',
        caseStudyFieldCategory: 'awardedLoan',
        value: this.getAwardedLoansValueFromCs(),
        arrayName: 'awardedLoans',
        formSection: 'post-installation-outcomes',
        shouldInit: true,
      },
      {
        formName: 'satisfactionForm',
        caseStudyFieldCategory: 'satisfaction',
        value: this.formattedCaseStudy.outcome.heating_system && this.formattedCaseStudy.outcome.heating_system[0].satisfaction,
        formSection: 'post-installation-outcomes',
        shouldInit: true,
      },
      { formName: 'otherInformationForm', caseStudyFieldCategory: 'otherInformation', value: this.formattedCaseStudy, formSection: 'other-information', shouldInit: true },
    ];
    return this.formsBuilder;
  }

  async getBuildingPreFormValue() {
    const building = this.formattedCaseStudy.before.building && this.formattedCaseStudy.before.building[0];
    if (building && this.formattedCaseStudy.propertyImage && this.formattedCaseStudy.propertyImage.fileId) {
      building['propertyImage'] = this.formattedCaseStudy.propertyImage;
    }
    return building;
  }

  setFormSections() {
    const sections = ['pre-installation-building', 'pre-installation-heating', 'installation', 'post-installation-heating', 'post-installation-building', 'post-installation-outcomes', 'other-information'];
    this.sections = [...sections];
    return sections;
  }

  getHPInstallationValueFromCs() {
    if (!this.formattedCaseStudy.after.heating_system) return;
    const hpInstallation = this.caseStudy.after.find((je) => je.journalEntryDataType === 'installation' && je.componentDataType === 'heating_system');
    return hpInstallation && this.formattedCaseStudy.after.heating_system.find((hp) => hp.componentId === hpInstallation.componentId && hp.componentVersion === hpInstallation.componentVersion).installation;
  }

  getHpSystemFromCs(csSection) {
    return this.formattedCaseStudy[csSection].heating_system && this.formattedCaseStudy[csSection].heating_system.find((item) => item.type.toLowerCase().includes('heat pump'));
  }

  async saveAndContinue(saveCallback, section, loadObj) {
    const validity = this.getSectionValidity(section);
    if (!validity) {
      this.markSectionAsTouched(section);
      loadObj = this.handleLoadObjError(loadObj, 'Please fill out all required fields before continuing.');
      return loadObj;
    }
    const completionStatus = 'completed';
    loadObj = await saveCallback(completionStatus);
    if (loadObj.error) {
      return loadObj;
    }
    this.markSectionAsPristine(section);
    const nextSection = this.nextSection(section);
    this.router.navigate(['/pages/tools/case-study-creator/heat-pump/' + nextSection]);
  }

  getAwardedGrantsValueFromCs() {
    const hpSystem = this.getHpSystemFromCs('outcome');
    if (!hpSystem) return;
    const finance = hpSystem.finance;
    return finance && finance.awardedGrants;
  }

  getAwardedLoansValueFromCs() {
    const hpSystem = this.getHpSystemFromCs('outcome');
    if (!hpSystem) return;
    const finance = hpSystem.finance;
    return finance && finance.awardedLoans;
  }

  hasDoneRetrofit(formattedCaseStudy = this.formattedCaseStudy) {
    const buildingBefore = formattedCaseStudy.before.building && formattedCaseStudy.before.building[0];
    if (!buildingBefore) return false;
    const buildingAfter = formattedCaseStudy.after.building && formattedCaseStudy.after.building[0];
    return buildingAfter && buildingAfter.componentVersion > buildingBefore.componentVersion;
  }

  initForms() {
    
    for (let form of this.formsBuilder) {
      if (!form.shouldInit) continue;
      if (form.arrayName) {
        this.initFormArrayAndSet(form.formName, form.caseStudyFieldCategory, form.arrayName, form.value, form.minLen || 0);
      } else {
        this.initFormAndSet(form.formName, form.caseStudyFieldCategory, form.value);
      }

      this.announceForm(form.formName, this[form.formName]);
    }
  }

  addSummaryToForm(form, formattedComponent) {
    form.patchValue({
      summary: (formattedComponent.text_description && formattedComponent.text_description.description) || formattedComponent.summary,
    });
    form.metadata = {
      componentId: formattedComponent.componentId,
      componentVersion: formattedComponent.componentVersion,
      jeId: formattedComponent.text_description && formattedComponent.text_description.jeId,
    };
  }

  async initEmptyCaseStudy() {
    const veId = this.selectedService.veId;
    const caseStudyBody = {
      veId,
      classifier: 'heating_system.heat_pump',
      canContactOwner: false,
      canVisitProperty: false,
      publishedStatus: 'draft',
      completionStatus: 'in-progress',
    };
    const caseStudy = await this.glowService.createCaseStudy(caseStudyBody).toPromise();
    return this.caseStudyService.fetchCaseStudyData(caseStudy);
  }

  async initEmptyJournal() {
    const veId = this.selectedService.veId;
    const journal = await this.glowService.createJournal(veId).toPromise();
    return journal;
  }

  async getCaseStudy() {
    //todo: get the active case study/ allow selection of existing case studies
    const veId = this.selectedService.veId;
    const all = await this.glowService.getAllCaseStudiesByVeAndClassifier(veId, 'heating_system.heat_pump').toPromise();
    return all.length ? all[0] : null;
  }

  async announceLatestCaseStudy() {
    const cs = await this.getCaseStudy();
    const formattedCaseStudy = await this.caseStudyService.fetchCaseStudyData(cs);
    this.setAndAnnounceCaseStudy(cs, formattedCaseStudy);
  }

  async getJournal() {
    let err, data;
    const veId = this.selectedService.veId;
    [err, data] = await Utils.promiseHandler(this.glowService.getJournal(veId).toPromise());
    if (err && err.status == 404) {
      [err, data] = await Utils.promiseHandler(this.glowService.createJournal(veId).toPromise());
    }
    return data;
  }

  initLoadObj(loading = false) {
    return {
      successMessage: '',
      errorMessage: '',
      errorCode: '',
      error: false,
      loading,
      loadingMessage: '',
    };
  }

  handleLoadObjError(loadObj, errMessage) {
    loadObj.error = true;
    loadObj.errorMessage = errMessage;
    loadObj.loading = false;
    loadObj.loadingMessage = '';
    loadObj.successMessage = '';
    return loadObj;
  }

  handleLoadObjSuccess(loadObj, successMessage) {
    loadObj.error = false;
    loadObj.errorMessage = '';
    loadObj.loading = false;
    loadObj.loadingMessage = '';
    loadObj.successMessage = successMessage;
    return loadObj;
  }

  handleLoadObjLoading(loadObj, loadingMessage) {
    loadObj.error = false;
    loadObj.errorMessage = '';
    loadObj.loading = true;
    loadObj.loadingMessage = loadingMessage;
    loadObj.successMessage = '';
    return loadObj;
  }

  //initialises all forms except microgenerators/energystorage/installers/heating system
  initForm(caseStudyFieldCategory, value, formName?) {
    if (!value) value = {};
    const group = {};
    for (let field in caseStudyFields[caseStudyFieldCategory]) {
      const fieldObj = caseStudyFields[caseStudyFieldCategory][field];
      // get validators and filter out null validators
      const validators = [
        fieldObj.required ? Validators.required : null,
        fieldObj.type == 'number' ? Validators.pattern('^[0-9]*$') : null,
        fieldObj.type == 'email' ? FormValidator.isEmailValid : null,
        fieldObj.minLength ? FormValidator.minLength(+fieldObj.minLength) : null,
        fieldObj.maxLength ? FormValidator.maxLength(+fieldObj.maxLength) : null,
        fieldObj.type == 'file' ? FormValidator.maxFileSize(+fieldObj.maxSize) : null,
        fieldObj.type == 'file' ? FormValidator.allowedFileTypes(new RegExp(fieldObj.accept)) : null,
      ].filter((validator) => validator != null);
      const initialValue = fieldObj.default !== 'undefined' && value[field] == null ? fieldObj.default : value[field];
      group[field] = new FormControl(initialValue, [...validators]);
    }
    const formGroup = this.fb.group(group);
    if (formGroup.controls.summary) {
      this.addSummaryToForm(formGroup, value);
    }
    if (!formGroup.metadata) {
      formGroup.metadata = { componentId: value['componentId'], componentVersion: value['componentVersion'], jeId: value['jeId'] };
    }
    if (formName) {
      formGroup.metadata.formName = formName;
    }

    return formGroup;
  }

  initFormArray(caseStudyFieldCategory, arrayControlName, arrayValues = [], minLen) {
    const arrayForm = this.fb.group({ [arrayControlName]: this.fb.array([]) });
    this.addFormsToFormArray(caseStudyFieldCategory, arrayControlName, arrayForm, arrayValues, minLen);
    return arrayForm;
  }

  addFormsToFormArray(type, arrayName, form: FormGroup, arrayValues = [], minLen = 0) {
    const array = form.get(arrayName) as FormArray;
    arrayValues.forEach((value) => {
      let group = this.initForm(type, value);
      this.addSummaryToForm(group, value);
      array.push(group);
    });

    if (minLen && array.length < minLen) {
      for (let i = 0; i < minLen - array.length; i++) {
        let group = this.initForm(type, {});
        array.push(group);
      }
    }

    return form;
  }

  getSectionValidity(section) {
    const forms = this.formsBuilder.filter((form) => form.formSection === section);
    return forms.every((form) => !this[form.formName] || this[form.formName].valid);
  }

  getSectionValidityForNonEmptyControls(section) {
    const forms = this.formsBuilder.filter((form) => form.formSection === section);
    return forms.every((form) => !this[form.formName] || this.formValidationService.isFormValidForNonEmptyControls(this[form.formName]));
  }

  isSectionEmpty(section) {
    const forms = this.formsBuilder.filter((form) => form.formSection === section);
    return forms.every((form) => this.isFormEmpty(this[form.formName]));
  }

  isSectionPristine(section) {
    const forms = this.formsBuilder.filter((form) => form.formSection === section);
    return forms.every((form) => !this[form.formName] || this[form.formName].pristine);
  }

  markSectionAsTouched(section) {
    
    const forms = this.formsBuilder.filter((form) => form.formSection === section);
    forms.forEach((form) => {
      if (this[form.formName]) {
        this[form.formName].markAllAsTouched();
        this.announceForm(form.formName, this[form.formName]);
      }
    });
  }

  markSectionAsPristine(section) {
    
    const forms = this.formsBuilder.filter((form) => form.formSection === section);
    forms.forEach((form) => {
      if (this[form.formName]) {
        this[form.formName].markAsPristine();
        this.announceForm(form.formName, this[form.formName]);
      }
    });
  }

  getSectionFromRouter() {
    const urlArray = this.router.url.split('/');
    const section = urlArray[urlArray.length - 1];
    return section;
  }

  addGroupToFormArray(type, form: FormGroup, arrayName, value = {}) {
    let group = this.initForm(type, value);
    const array = form.get(arrayName) as FormArray;
    array.push(group);
    return group;
  }

  removeForm(index, form: FormGroup, arrayName) {
    const array = form.get(arrayName) as FormArray;
    array.removeAt(index);
    return form;
  }

  processChangeBooleanControl(type, form, formToUpdate, arrayName, booleanField, formToUpdateName) {
    form.controls[booleanField].valueChanges.subscribe((value) => {
      
      if (value && !formToUpdate) {
        if (!arrayName) {
          this.initFormAndSet(formToUpdateName, type);
          this.announceForm(formToUpdateName, this[formToUpdateName]);
          return;
        }
        this.initFormArrayAndSet(formToUpdateName, type, arrayName, [], 1);
        this.announceForm(formToUpdateName, this[formToUpdateName]);
        return;
      }
      if (!value) {
        this[formToUpdateName] = null;
        this.announceForm(formToUpdateName, this[formToUpdateName]);
        return;
      }
      if (value && arrayName) {
        this.addGroupToFormArray(type, formToUpdate, arrayName);
        return;
      }
    });
    return form;
  }

  initFormAndSet(formName, caseStudyFieldCategory, value = {}) {
    this[formName] = this.initForm(caseStudyFieldCategory, value, formName);
  }

  setForm(formName, form) {
    this[formName] = form;
  }
  setFormAndAnnounce(formName, form) {
    this.setForm(formName, form);
    
    this.announceForm(formName, form);
  }

  initFormArrayAndSet(formName, caseStudyFieldCategory, arrayControlName, arrayValues = [], minLen) {
    this[formName] = this.initFormArray(caseStudyFieldCategory, arrayControlName, arrayValues, minLen);
  }

  announceCaseStudy(caseStudy = this.caseStudy, formattedCaseStudy = this.formattedCaseStudy) {
    this.caseStudyAnnouncer.emit({ caseStudy, formattedCaseStudy });
  }

  announceJournal() {
    this.journalAnnouncer.emit(this.journal);
  }

  announceForm(formName, form) {
    this.formAnnouncer.emit({ formName, form });
  }

  setCaseStudy(caseStudy) {
    this.caseStudy = caseStudy;
  }

  setFormattedCaseStudy(formattedCaseStudy) {
    this.formattedCaseStudy = formattedCaseStudy;
  }

  setAndAnnounceCaseStudy(caseStudy, formattedCaseStudy) {
    this.caseStudy = caseStudy;
    this.formattedCaseStudy = formattedCaseStudy;
    this.announceCaseStudy(caseStudy, formattedCaseStudy);
  }

  async setFormatAnnounceCaseStudy(caseStudy) {
    this.caseStudy = caseStudy;
    this.formattedCaseStudy = await this.caseStudyService.fetchCaseStudyData(caseStudy);
    this.announceCaseStudy(caseStudy, this.formattedCaseStudy);
  }

  setAndAnnounceJournal(journal) {
    this.journal = journal;
    this.announceJournal();
  }

  getAndAnnounceCaseStudySubmitSuccess() {
    const sectionSuccessMap = {};
    for (let section of this.sections) {
      sectionSuccessMap[section] = this.getSectionValidity(section);
    }
    this.caseStudySubmitSuccessAnnouncer.emit(sectionSuccessMap);
  }

  // getFormCategories(form: FormGroup) {
  //   switch (form) {
  //     case this.microgeneratorForm:
  //       return ['microgenerator'];
  //     case this.buildingForm:
  //       return ['building'];
  //     case this.energyStoragesForm:
  //       return ['energyStorage'];
  //     case this.heatingSystemForm:
  //       return ['heatingSystem'];
  //     case this.installationForm:
  //       return ['installer', 'installation'];
  //     // case this.outcomesForm:
  //     //   return ['satisfaction', 'finance'];
  //     case this.buildingPostInstallForm:
  //       return ['buildingPostInstall'];
  //   }
  // }

  isFormEmpty(form) {
    return form && form.value && Object.keys(form.value).every((key) => form.value[key] === '');
  }

  getActiveForms() {
    return this.formsBuilder.map((form) => this[form.formName]).filter((form) => form);
  }

  areFormsPristine() {
    const activeForms = this.getActiveForms();
    return activeForms.every((form) => form.pristine);
  }

  async getOrCreateHpSystem(completionStatus) {
    let hpSystem = this.formattedCaseStudy.after.heating_system && this.formattedCaseStudy.after.heating_system.find((system) => system.type.toLowerCase().includes('heat pump'));
    if (!hpSystem) {
      hpSystem = await this.createComponent({ formValue: { type: 'Air Source Heat Pump' }, fieldsToAdd: [], fieldsToDelete: [], componentDataType: 'heating_system', componentVersion: 1, completionStatus });
      const descriptionJe = await this.createDescriptionJournalEntry({ formValue: {}, componentDataType: 'heating_system', componentVersion: 1, componentId: hpSystem.componentId, completionStatus });
      const r = await this.addJournalEntryToCaseStudy({ journalEntry: descriptionJe, caseStudy: this.caseStudy, section: 'after' });
    }

    await this.announceLatestCaseStudy();
    this.initFormArrayAndSet('heatingSystemPostForm', 'heatingSystem', 'heatingSystems', this.formattedCaseStudy.after.heating_system, 1);
    this.announceForm('heatingSystemPostForm', this.heatingSystemPostForm);
    return this.formattedCaseStudy.after.heating_system && this.formattedCaseStudy.after.heating_system.find((system) => system.type.toLowerCase().includes('heat pump'));
  }

  async processDeletedComponents(componentArray, componentFormArray, formArrayName, csSection) {
    if (!componentArray) return;
    const existingComponentIds = componentArray.map((component) => component.componentId);
    const componentIds = componentFormArray ? componentFormArray.controls[formArrayName]['controls'].map((form) => form.metadata.componentId) : [];
    if (existingComponentIds.length === componentIds.length) return;
    const deletedComponentIds = existingComponentIds.filter((id) => !componentIds.includes(id));
    const journalEntriesToDelete = this.caseStudy[csSection].filter((je) => deletedComponentIds.includes(je.componentId));
    for (let je of journalEntriesToDelete) {
      await this.removeJournalEntryFromCaseStudy({
        caseStudy: this.caseStudy,
        jeId: je.jeId,
        section: csSection,
      });
    }
  }

  async addJournalEntryToCaseStudy({ caseStudy, journalEntry, section }: { caseStudy; journalEntry; section: 'before' | 'after' | 'outcome' }) {
    caseStudy[section].push({
      jeId: journalEntry.jeId,
      componentId: journalEntry.componentId,
      componentVersion: journalEntry.componentVersion,
      componentDataType: journalEntry.componentDataType,
      journalEntryDataType: journalEntry.data.journalEntryDataType,
    });
    return this.glowService.updateCaseStudy(caseStudy.csId, { [section]: caseStudy[section] }).toPromise();
  }

  removeJournalEntryFromCaseStudy({ caseStudy, jeId, section }: { caseStudy; jeId; section: 'before' | 'after' | 'outcome' }) {
    caseStudy[section] = caseStudy[section].filter((je) => je.jeId !== jeId);
    return this.glowService.updateCaseStudy(caseStudy.csId, { [section]: caseStudy[section] }).toPromise();
  }

  async createJournalEntry({ journalEntryData, componentId, componentVersion, componentDataType, completionStatus }) {
    journalEntryData = {
      ...journalEntryData,
      componentId,
      componentVersion,
      componentDataType,
      veId: this.selectedService.veId,
      journalId: this.journal.journalId,
      summary: journalEntryData.summary || '',
      completionStatus,
    };
    return this.glowService.createJournalEntry(journalEntryData).toPromise();
  }

  getDescrptionJournalEntryFromFormValue(formValue) {
    const journalEntryData = {
      data: {
        description: formValue.summary || '',
        journalEntryDataType: 'text_description',
      },
    };
    return journalEntryData;
  }

  async createDescriptionJournalEntry({ formValue, componentId, componentVersion, componentDataType, completionStatus }) {
    const journalEntryData = this.getDescrptionJournalEntryFromFormValue(formValue);
    const journalEntry = await this.createJournalEntry({ journalEntryData, componentId, componentVersion, componentDataType, completionStatus });
    return journalEntry;
  }

  async updateDescriptionJournalEntry({ formValue, existingTextDescription }) {
    const journalEntryData = this.getDescrptionJournalEntryFromFormValue(formValue);
    const jeId = existingTextDescription && existingTextDescription.jeId;
    return this.glowService.updateJournalEntry(jeId, journalEntryData).toPromise();
  }

  getComponentFromFormValue({ formValue, componentDataType, fieldsToDelete, fieldsToAdd }) {
    let componentData = { ...formValue };
    let component: any = {};
    for (let field of fieldsToDelete) {
      delete componentData[field];
    }
    for (let field of fieldsToAdd) {
      component[field] = formValue[field];
    }
    componentData = Utils.setNumbersInObject(componentData);
    component['data'] = componentData;
    component['data']['componentDataType'] = componentDataType;
    return component;
  }

  async updateComponent({ componentId, componentVersion, componentDataType, fieldsToDelete, fieldsToAdd, formValue }) {
    const component = this.getComponentFromFormValue({ formValue, componentDataType, fieldsToDelete, fieldsToAdd });
    return this.glowService.updateComponent(componentId, componentVersion, component).toPromise();
  }

  async uploadComponentMediaFiles(mediaFiles, componentId, componentVersion) {
    if (!(mediaFiles instanceof FileList)) return mediaFiles;
    const formData = Utils.formDataFromFiles(mediaFiles);
    const updatedComponent = await this.glowService.updateComponentMedia(componentId, componentVersion, formData).toPromise();
    return updatedComponent.mediaFiles;
  }

  async uploadComponentMediaFilesFromForm(formValue, componentId, componentVersion) {
    if (formValue.mediaFiles && formValue.mediaFiles.length) {
      const uploadedMediaFiles = await this.uploadComponentMediaFiles(formValue.mediaFiles, componentId, componentVersion);
      formValue.mediaFiles = uploadedMediaFiles;
    }
    return formValue;
  }

  async createComponent({ formValue, fieldsToDelete, fieldsToAdd, componentVersion, componentDataType, completionStatus, componentId }: { formValue; fieldsToDelete; fieldsToAdd; componentVersion; componentDataType; completionStatus; componentId? }) {
    const component = this.getComponentFromFormValue({ formValue, componentDataType, fieldsToDelete, fieldsToAdd });
    component['componentVersion'] = componentVersion;
    if (componentId) {
      component['componentId'] = componentId;
    }
    component['veId'] = this.selectedService.veId;
    component['completionStatus'] = completionStatus;
    return this.glowService.createComponent(component).toPromise();
  }

  nextSection(currentSection) {
    //todo: put pages in this service- set up setter to fetch them from here
    const sections = ['pre-installation-building', 'pre-installation-heating', 'installation', 'post-installation-heating', 'post-installation-building', 'post-installation-outcomes', 'other-information'];
    const index = sections.indexOf(currentSection);
    return sections[index + 1];
  }
}
