import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllNotificationsComponent } from './all-notifications.component';
import { NotificationModule } from 'src/app/components/notification/notification.module';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [AllNotificationsComponent],
  imports: [
    CommonModule, NotificationModule, SharedModule, 
  ],
  exports: [AllNotificationsComponent]
})
export class AllNotificationsModule { }
