import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlowService } from 'src/app/services/glow.service';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
})

export class GeneralComponent implements OnInit {

  pageTitle = 'General';
  displayTitle = 'About your home'
  subscriptionSelectedService: Subscription;
  selectedService;
  passport;
  loading;
  error;
  errorMessage;
  loadingMessage;
  shouldShowToolLink: boolean = true;

  constructor(
    private serviceSelectorSvc: ServiceSelectorSvc,
    private glowService: GlowService,
  ) {

    this.subscriptionSelectedService = this.serviceSelectorSvc.serviceSet.subscribe(selectedService => {
      this.selectedService = selectedService
      this.getPropertyPassport()
    })
  }

  ngOnInit() {
    this.selectedService = this.serviceSelectorSvc.getSelectedService()
    if (this.selectedService && this.selectedService.veId && !this.passport) {
      this.getPropertyPassport()
    }
  }

  getPropertyPassport() {
    this.passport = null 
    this.loadingMessage = 'Loading your details...'
    this.loading = true
    this.glowService.getPropertyPassport(this.selectedService.veId).subscribe((resp) => {
      if (!resp.valid) {
        this.loading = false
        return
      }
      this.passport = this.formatPassportToDisplay(resp);
      this.loading = false;
    }, (err) => {
      this.loading = false
      this.passport = null
      this.error = true;
      this.errorMessage = err && err.error && err.error.error
    })
  }

  formatPassportToDisplay(passport) {
    let formatted: any = { ...passport };
    formatted.address.address = this.formatAddress(passport.address.address);
    formatted.currentEpc = this.getCurrentEpc(passport.epcRecords);
    return formatted;
  }

  getCurrentEpc(epcRecords) {
    return [...epcRecords].sort((a, b) => new Date(b.lodgement_date).valueOf() - new Date(a.lodgement_date).valueOf())[0];
  }

  formatAddress(address: string) {
    return address.split(',').filter((fragment) => fragment).join(',');
  }

}