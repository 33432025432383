import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { tVeTariffsWithTs } from 'src/app/components/component.typings';
import { tLoadingElement } from 'src/app/services/app-config.typings';
import { tResourceAvailabilityElement } from 'src/app/services/app-data.typings';
import { ResourceDataService } from 'src/app/services/data.service';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';
import { TariffService } from 'src/app/services/tariff.service';
import { tTariffPlan } from 'src/app/services/ui.typings';

@Component({
  selector: 'app-tariff',
  templateUrl: './tariff.component.html',
  styleUrls: ['./tariff.component.scss']
})
export class TariffComponent implements OnInit {
  pageTitle = 'Tariff'
  // displayTitle = 'About your home'
  noTariffMessage = "We cannot retrieve your tariff information, please contact your supplier."
  consumptionType = ['electricity.consumption.cost', 'gas.consumption.cost']
  selectedService: any;
  subscriptionSelectedService: Subscription;
  subscriptionTariff: Subscription;
  veTariffs: tVeTariffsWithTs = {};
  mostRecentFutureTime: any = null;
  tariff: tTariffPlan;
  loadObj: tLoadingElement
  electricityTariff;
  gasTariff;

  constructor(public dataService: ResourceDataService,
    private serviceSelectorSvc: ServiceSelectorSvc,
    private tariffService: TariffService) {
    this.subscriptionSelectedService = this.serviceSelectorSvc.serviceSet.subscribe(selectedService => {
      this.selectedService = selectedService;
      this.getVeTariffs()
    })

    this.subscriptionTariff = this.dataService.tariff$.subscribe(tariff => {
      // console.log()
      if (tariff.classifier === 'electricity.consumption.cost') {
        this.electricityTariff = tariff
      } else if (tariff.classifier === 'gas.consumption.cost') {
        this.gasTariff = tariff
      }
    
      this.processData(tariff) 

    })

    this.loadObj = { ...this.loadObj, loaded: true, loading: false }


  }

  ngOnInit() {
    this.selectedService = this.serviceSelectorSvc.getSelectedService();
    this.getVeTariffs()
  }
  getVeTariffs(): void {
    if (this.selectedService && this.selectedService.resources) {
      delete this.veTariffs;
      this.consumptionType.forEach((type) => {
        const cost = this.selectedService.resources.find(x => x.classifier == type)
        if (cost) {
          this.getResourceTariffs(cost, type.split('.')[0]);
        }

      })
    }
  }

  getResourceTariffs(resourceInfo: tResourceAvailabilityElement, dataStreamType: string): void {
    // this.tariffVEInfo[dataStreamType] = this.setTariffElement(resourceInfo.classifier, resourceInfo.resourceId)
    if (resourceInfo && resourceInfo.resourceId) {
      this.dataService.getResourceTariffsPromise(resourceInfo).then(tariffPlan => {
        const ts = new Date().getTime()
        this.veTariffs = { ...this.veTariffs, [dataStreamType]: { ...tariffPlan, ts } }
        this.determineTariffUpdateTime(dataStreamType)
      }).catch(e => {
        console.warn(`Error while getting tariffs`)
      })
    }
  }


  determineTariffUpdateTime(dataStreamType): any {
    const hasFutureRates = (this.veTariffs && this.veTariffs[dataStreamType]
      && Array.isArray(this.veTariffs[dataStreamType].futureRates) && this.veTariffs[dataStreamType].futureRates.length > 0)
    // // console.log(`hasFutureRates:  ${hasFutureRates}. Now is ${new Date()} `)
    if (hasFutureRates) {
      const now = new Date().getTime()
      // const offset = new Date().getTimezoneOffset() * 60 * 1000;
      const futureRates = this.veTariffs[dataStreamType].futureRates.map((elem) => [new Date(`${elem.ts.replace(/ /g, "T")}.000Z`).getTime(), elem.rate, elem.ts])
      const mostRecentFutureRate = futureRates.find(futureRate => {
        if (Number(futureRate[0]) > now) {
          return futureRate
        }
      })
      const mostRecentFutureTime: any = mostRecentFutureRate[0]

      if (mostRecentFutureTime != this.mostRecentFutureTime) {
        this.mostRecentFutureTime = mostRecentFutureTime
        const now = new Date().getTime()
        // // console.log(now)
        const mSecondsToDoCheck = mostRecentFutureTime - now + 1000
        // // console.log(`Setting refresher at ${mSecondsToDoCheck} for ${dataStreamType} tariff. mostRecentFutureTime ${mostRecentFutureTime}`)
        setTimeout(() => {
          const elecCost = this.selectedService.resources.find(x => x.classifier == 'electricity.consumptiom.cost')
          this.getResourceTariffs(elecCost, 'electricity');
        }, mSecondsToDoCheck)
      } else {
        // // console.log(`mostRecentFutureTime ${mostRecentFutureTime} the same not asking for update`)
      }
    }
  }

  processData(tariff) {
    if (tariff.resourceId && this.selectedService.veId) {
      this.tariffService.getTariffPermission(tariff.resourceId, this.selectedService.veId)
      // this.getDeviceByResourceId()
    }
  }
}
