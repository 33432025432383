import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfessionalRegisterStepperComponent } from './professional-register-stepper.component';



@NgModule({
  declarations: [ProfessionalRegisterStepperComponent],
  imports: [
    CommonModule
  ],
  exports: [ProfessionalRegisterStepperComponent]
})
export class ProfessionalRegisterStepperModule { }
