import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { GlowService } from 'src/app/services/glow.service';
import { SchematicsService } from 'src/app/services/schematics.service';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss', '../pages.component.scss'],
})
export class NotificationsComponent implements OnInit {
  hasPassport: boolean;
  passport;
  loading = true;
  schematic;
  isSchematicComplete: boolean;
  selectedService;
  pageTitle = 'Notifications';
  currentPage = 'Notifications';
  subscriptionSelectedService: Subscription;
  schematicsSubscription: Subscription;
  pages = [{ path: '/pages/account/notifications/all-notifications', title: 'Notifications', urlPath: 'all-notifications' }];
  private currentPageAnnouncer = new Subject<any>();
  currentPage$ = this.currentPageAnnouncer.asObservable();

  constructor(public activeModal: NgbActiveModal, private schematicsService: SchematicsService, private serviceSelectorSvc: ServiceSelectorSvc, private glowService: GlowService) {
    this.schematicsSubscription = this.schematicsService.schematicDocument$.subscribe((schematicDocument) => {
      this.schematic = { ...schematicDocument.data };
      this.isSchematicComplete = this.schematic.totalHeatingPowerOutput && this.schematic.totalFloorArea && this.schematic.floors.length;
    });
  }

  ngOnInit() {
    this.selectedService = this.serviceSelectorSvc.getSelectedService();
    // this.schematicsService.setSelectedService(this.selectedService);
    // this.schematicsService.getSchematics();
    this.getPropertyPassport();
  }
  public onRouterOutletActivate(event: any) {
    if (event.pageTitle) {
      this.currentPageAnnouncer.next(event.pageTitle);
      this.currentPage = event.pageTitle;
    }
    // console.log("EVENT",event);
  }
  isCurrentPage(page) {
    return page.title == this.currentPage;
  }

  getOtherPages() {
    return this.pages.filter((p) => p.title != this.currentPage);
  }

getPropertyPassport() {
  this.glowService.getPropertyPassport(this.selectedService.veId).subscribe((resp) => {
    if (resp.valid) {
      this.passport = resp
      this.loading = false;
      // console.log(resp)
    } else    
     this.loading = false;
    return
  });
}
}
