import { Component, OnInit, Output, EventEmitter, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FaqsService } from 'src/app/services/faqs.service';
import { SearchService } from 'src/app/services/search.service';

@Component({
  selector: 'app-faqs-searchbar',
  templateUrl: './faqs-searchbar.component.html',
  styleUrls: ['./faqs-searchbar.component.scss'],
})
export class FaqsSearchbarComponent implements OnInit {
  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    //checks if doc has been clicked to close menu
    this.results = [];
    this.faqHover = '';
  }

  @Output() isSearching: EventEmitter<boolean> = new EventEmitter();
  @Input() placeholder: string = 'Search...';
  results: any = [];
  faqHover;
  selectedQuestion;

  constructor(private faqsService: FaqsService, private router: Router, private searchService: SearchService) {}

  ngOnInit(): void {}

  async searchFAQs(event: any) {
    event.stopPropagation();
    event.target.value
      ? (this.results = (await this.searchService.searchAllFAQs(event.target.value)).hits)
      : (this.results = []);
  }

  navigateToFAQ(faq: any) {
    this.router.navigateByUrl(`/support/${faq.faq_topics[0].slug}/${faq.slug}`);
  }

  updateIsSearching(isSearching: boolean) {
    this.isSearching.emit(isSearching);
  }

  faqOnHover(result) {
    this.faqHover = result;
  }
}
