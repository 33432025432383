import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuoteModalComponent } from './quote-modal.component';
import { FileUploadModule } from '../file-upload/file-upload.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormValidationMessageModule } from '../form-validation-message/form-validation-message.module';



@NgModule({
  declarations: [QuoteModalComponent],
  imports: [
    CommonModule, FileUploadModule, SharedModule, FormValidationMessageModule
  ],
  exports: [QuoteModalComponent],
  entryComponents: [QuoteModalComponent]
})
export class QuoteModalModule { }
