import { Component, Input, OnInit } from '@angular/core';
import { NgbCarouselConfig, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { StrapiService } from 'src/app/services/strapi.service';

@Component({
  selector: 'app-landing-carousel',
  templateUrl: './landing-carousel.component.html',
  styleUrls: ['./landing-carousel.component.scss'],
  providers: [NgbCarouselConfig],
})
export class LandingCarouselComponent implements OnInit {
  showNavigationArrows = false;
  showNavigationIndicators = false;
  featuredContent;
  loading=true;
  constructor(config: NgbCarouselConfig, private strapiService: StrapiService) {
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
    config.wrap = true;
    config.interval = 5000;


    this.getFeaturedContent().then((allContent) => {
      // this.allContent = this.formatContentArray(allContent);
      this.loading=false;
      this.featuredContent = allContent;
    });
  }

  ngOnInit(): void {}

  async getFeaturedContent() {
    const content = await this.strapiService.getContentBySlug('tags', 'featured');
    return content;
  }
}
