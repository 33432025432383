import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { HeatPumpCaseStudyComponent } from '../pages/case-study-creator-tool/heat-pump-case-study/heat-pump-case-study.component';
import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CaseStudyFormService } from './case-study-form.service';
import { BuildingFormComponent } from '../components/case-study/building-form/building-form.component';
import { HeatingSystemFormComponent } from '../components/case-study/heating-system-form/heating-system-form.component';
import { InstallationFormComponent } from '../components/case-study/installation-form/installation-form.component';
import { OutcomesFormComponent } from '../components/case-study/outcomes-form/outcomes-form.component';
import { OtherInfoFormComponent } from '../components/case-study/other-info-form/other-info-form.component';

@Injectable({
  providedIn: 'root',
})
export class FormGuard implements CanDeactivate<BuildingFormComponent | HeatingSystemFormComponent | InstallationFormComponent | OutcomesFormComponent | OtherInfoFormComponent> {
  constructor(private modalService: NgbModal, private caseStudyFormService: CaseStudyFormService) {}
  canDeactivate(
    component: BuildingFormComponent | HeatingSystemFormComponent | InstallationFormComponent | OutcomesFormComponent | OtherInfoFormComponent,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise<boolean>((resolve) => {
      if (!this.caseStudyFormService.isSectionPristine(component.section)) {
        component.saveChanges();
      }
      resolve(true);
    });
  }
}
