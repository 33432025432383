import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadiatorSchematicSelectorModalComponent } from './radiator-schematic-selector-modal.component';
import { RadiatorSchematicCardModule } from '../radiator-schematic-card/radiator-schematic-card.module';

@NgModule({
  declarations: [RadiatorSchematicSelectorModalComponent],
  imports: [CommonModule, RadiatorSchematicCardModule],
  exports: [RadiatorSchematicSelectorModalComponent],
  entryComponents: [RadiatorSchematicSelectorModalComponent],
})
export class RadiatorSchematicSelectorModalModule {}
