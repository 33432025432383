import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { HelpModalComponent } from 'src/app/components/help-modal/help-modal.component';
import { InvitationsService } from 'src/app/services/invitations.service';
import { PdfService } from 'src/app/services/pdf.service';
import { SchematicsService } from 'src/app/services/schematics.service';
import { Utils } from 'src/utils';
@Component({
  selector: 'app-home-energy-data',
  templateUrl: './home-energy-data.component.html',
  styleUrls: ['./home-energy-data.component.scss', '../../pages-professional.component.scss']
})
export class HomeEnergyDataComponent implements OnInit {
  properties;
  selectedAssessmentPeriod
  snapshot;
  HTC = 'Heat Transfer Coefficient (HTC)'
  HLP = 'Heat Loss Parameter (HLP)'
  formInfo;
  pageTitle = 'Home and energy data';
  currentTab = 'Overview'
  assessmentPeriods = []
  modalRef
  property
  estimateRequestId
  loading
  showSchematic: boolean
  downloadSchematic: boolean
  propertyId
  stageSubscription: Subscription
  pages = ['Overview', 'Home data', 'Energy data', 'Heating system']
  snapshotsSubscription: Subscription
  constructor(private formBuilder: FormBuilder,
    private invitationsService: InvitationsService,
    private pdfService: PdfService,
    private modalService: NgbModal,
    private translate: TranslateService,
    private schematicsService: SchematicsService,
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute) {


    this.formInfo = this.formBuilder.group({
      assessmentPeriod: ['', Validators.required],
    })
    this.loading = true
    this.snapshotsSubscription = this.invitationsService.SnapshotAnnouncer.subscribe((resp) => {
      console.log(resp)
      this.properties = resp
      this.property = resp[0]
      this.setForm()
      // console.log(this.properties)
      this.loading = false

    })
    this.stageSubscription = this.schematicsService.stage$.subscribe((schm) => {
      console.log('schematics subscription')
      if (this.downloadSchematic) {
        const dataURL = this.schematicsService.getCanvasDataUrl(2)
        this.pdfService.generatePdf(this.property, dataURL)
        this.showSchematic = false
        this.downloadSchematic = false
      }

    })
  }

  ngOnInit() {
    console.log(this.estimateRequestId)
    this.invitationsService.getSnapshotsByEstimateRequestId(this.estimateRequestId)



  }
  ngAfterViewChecked() {

    this.changeDetector.detectChanges();
  }
  ngOnDestroy() {
    if (this.snapshotsSubscription) {
      this.snapshotsSubscription.unsubscribe()
    }
  }




  setForm() {
    this.properties.forEach(p => {
      this.assessmentPeriods.push(Utils.getReadableDateFromIso(this.property.assessmentPeriodStart) + ' - ' + Utils.getReadableDateFromIso(this.property.assessmentPeriodEnd))
      this.formInfo.setValue({ assessmentPeriod: this.assessmentPeriods[0] })
      this.selectedAssessmentPeriod = this.assessmentPeriods[0]
    })


  }
  showPage(page) {
    this.currentTab = page
  }
  switchSnapshot(event) {
    this.selectedAssessmentPeriod = event

  }


  downloadPDF() {
    if (this.property.heatingSystem) {
      this.downloadSchematic = true
      this.showSchematic = true
    } else this.pdfService.generatePdf(this.property)


  }
  openInfoModal(title) {
    // console.log(title)
    this.modalRef = this.modalService.open(HelpModalComponent, { scrollable: true, centered: true, size: 'lg' });
    this.modalRef.componentInstance.pageTitle = title;
    switch (title) {
      case 'HTC':
        this.modalRef.componentInstance.modalBodyContent = this.translate.instant("HTC_DEFINITION")
        break;
      case 'HLP':
        this.modalRef.componentInstance.modalBodyContent = this.translate.instant("HLP_DEFINITION")
        break;
      case 'ESTIMATED_HEAT_PUMP_RUNNING_COST':
        this.modalRef.componentInstance.modalBodyContent = this.translate.instant("ESTIMATED_HEAT_PUMP_RUNNING_COST_EXPLANATION")
        break;
      case 'THERMAL_PERFORMANCE':
        this.modalRef.componentInstance.modalBodyContent = this.translate.instant("THERMAL_PERFORMANCE_EXPLANATION")
        break;
      case 'PEAK_DEMAND':
        this.modalRef.componentInstance.modalBodyContent = this.translate.instant("PEAK_DEMAND_EXPLANATION")
        break;
    }
  }

  doesObjectHaveValue(obj) {
    if (obj) { return Object.keys(obj).find(key => obj[key] !== '') }
  }
  getReadableDateFromIso(string) {
    return Utils.getReadableDateFromIso(string)
  }
}
