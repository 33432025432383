import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CaseStudyFormService } from 'src/app/services/case-study-form.service';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-heat-pump-case-study',
  templateUrl: './heat-pump-case-study.component.html',
  styleUrls: ['./heat-pump-case-study.component.scss'],
})
export class HeatPumpCaseStudyComponent implements OnInit {
  section: string = 'pre-installation-building';
  savedForm: boolean;
  caseStudy;
  journal;
  formattedCaseStudy;
  children: any[] = [];
  activeChildComponent: any;
  selectedService: any;
  subscriptionSelectedService: Subscription;
  constructor(private route: ActivatedRoute, private caseStudyFormService: CaseStudyFormService, private serviceSelectorSvc: ServiceSelectorSvc) {
    this.route.children.forEach((route) => {
      route.url.subscribe((url) => {
        this.section = url[0].path;
      });
    });
  }

  ngOnInit() {
    this.selectedService = this.serviceSelectorSvc.getSelectedService();
    this.setChildren();
  }

  setChildren() {
    this.children = ['buildingForm', 'heatingSystemForm', 'outcomesForm', 'installationForm', 'otherInfoForm'];
  }

  onRouterOutletActivate(event) {
    this.activeChildComponent = event;
  }

  getActiveForms() {
    return this.caseStudyFormService.getActiveForms();
  }

  async saveChangesToActiveChildComponent() {
    await this.activeChildComponent.saveChanges();
  }
}
