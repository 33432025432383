import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LandingService } from 'src/app/services/landing.service';
import { StrapiService } from 'src/app/services/strapi.service';

@Component({
  selector: 'app-resources-list-content',
  templateUrl: './resources-list-content.component.html',
  styleUrls: ['./resources-list-content.component.scss'],
})
export class ResourcesListContentComponent implements OnInit {
  @Input() resourcesList!: any;
  innerWidth;
  @Input() url;
  @HostListener('window:resize', []) onResize() {
    // checks size of window to view specific menu
    this.innerWidth = window.innerWidth;
  }

  constructor() {}

  ngOnInit() {
    this.onResize();
  }
}
