import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-invitations',
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.scss','../pages.component.scss']
})
export class InvitationsComponent implements OnInit {
  pages = [
    { path: '/pages/account/invitations/sent-invitations', title: 'Sent invitations', urlPath: 'sent-invitations' },
    { path: '/pages/account/invitations/received-invitations', title: 'Received invitations', urlPath: 'received-invitations' },

    // { path: '/pages/account/settings/subscriptions', title: 'Subscriptions', urlPath: 'subscriptions' },
    //  { path: '/pages/account/settings/properties', title: 'Properties', urlPath: 'properties' }
  ]
  pageTitle = "Invitations"
  currentPage;
  private currentPageAnnouncer = new Subject<any>();
  currentPage$ = this.currentPageAnnouncer.asObservable()
  constructor() { }

  ngOnInit() {
  }
  public onRouterOutletActivate(event: any) {
    if (event.pageTitle) {
      this.currentPageAnnouncer.next(event.pageTitle)
      this.currentPage = event.pageTitle;
    }
    // console.log("EVENT",event);
  }
  isCurrentPage(page) {
    return page.title == this.currentPage;
  }

  getOtherPages() {
    return this.pages.filter(p => p.title != this.currentPage)
  
}

}
