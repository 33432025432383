import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-user-story-detailed',
  templateUrl: './user-story-detailed.component.html',
  styleUrls: ['./user-story-detailed.component.scss']
})
export class UserStoryDetailedComponent implements OnInit {
url!:Array<any>
page: String = 'user-story'
  constructor(private route:ActivatedRoute) { }

  ngOnInit(): void {

    this.getUrl()

  }

  getUrl(){
    this.url = this.route.snapshot.url.map(segment=> segment.path.charAt(0).toLowerCase() + segment.path.slice(1))
  }

}
