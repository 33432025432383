import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlowService } from 'src/app/services/glow.service';
import { VirtualEntityService } from 'src/app/services/virtual-entity.service';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ResourceTypeConfigService } from 'src/app/services/configuration.service';
import { tResourceTypeMapping } from 'src/app/services/configuration.typings';
import { tLoadingElement } from 'src/app/services/app-config.typings';
import { CustomUIConfigService } from 'src/app/services/custom-ui-config.service';
import { tVerificationStatus } from 'src/app/services/glow.typings';
import { any } from 'core-js/fn/promise';
import { DateFormatService } from 'src/app/services/date-format.service';
import { MeterPointVerificationConsentManagementService } from 'src/app/services/meter-point-verification-consent-management.service';
import { SetupSmartMeterModalComponent } from 'src/app/components/setup-smart-meter-modal/setup-smart-meter-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss'],
})
export class SitesComponent implements OnInit {
  veNameForm: FormGroup;
  veList: any;
  pageTitle: string = 'Properties and meters';
  loading: boolean = true;
  groupResource: any;
  VEsToDisplay: any = [];
  resourceIdArray: any[];
  dimension: string = 'energy';
  resourceTypeMapping: tResourceTypeMapping;
  subscriptionVirtualEntityList: Subscription;
  loadObj: tLoadingElement;
  isUpdating: any;
  saved: boolean = false;
  meterStatuses: Array<tVerificationStatus>;
  requiresConsentUpdate: boolean = false;
  verificationStatusSubscription: Subscription;
  verificationStatus: tVerificationStatus;
  expiredVerificationStatus = [];
  meterPointRequiringRenewal = [];
  meterPointDoNotRequireRenewal = [];
  errorMessage;
  constructor(
    private glowService: GlowService,
    private uiConfigService: CustomUIConfigService,
    private resourceTypeConfigService: ResourceTypeConfigService,
    private veService: VirtualEntityService,
    private dateFormatService: DateFormatService,
    private modalService: NgbModal,
    private meterPointVerificationConsentSvc: MeterPointVerificationConsentManagementService,
    private route: ActivatedRoute
  ) {
    this.loadObj = this.uiConfigService.initialiseLoadingBooleans(false);
    this.resourceTypeMapping = this.resourceTypeConfigService.loadResourceTypeMappings();

    this.subscriptionVirtualEntityList = this.veService.virtualEntitiesForDashboard$.subscribe(async (veList) => {
      if (veList && Array.isArray(veList)) {
        this.VEsToDisplay = [];
        this.loading = false;
        this.veList = veList;
        this.veList.map(async (ve) => {
          this.VEsToDisplay.push({ veId: ve.veId, name: ve.name });
          ve.resources.map(async (resource) => {
            if (this.resourceTypeMapping.dimension[this.dimension].indexOf(resource.resourceTypeId) > -1) {
              const device = await this.glowService.getResourceDevice(resource.resourceId).toPromise();
              resource.hardwareId = device.hardwareId;
            }
          });
        });
        this.initIsUpdating();
        this.createFormGroup();
      }
    });
    this.verificationStatusSubscription = this.meterPointVerificationConsentSvc.meters.subscribe((resp) => {
      console.log(resp);
      this.verificationStatus = resp;
      this.processVerificationStatus();
    });
  }
  ngOnInit() {
    this.getVerificationStatus();
    this.veService.findVirtualEntities();
  }

  initIsUpdating() {
    this.isUpdating = {};
    this.VEsToDisplay.forEach((ve) => {
      this.isUpdating[ve.veId] = { name: false };
    });
  }

  createFormGroup() {
    const group: any = {};
    this.VEsToDisplay.forEach((ve) => {
      group[ve.veId] = new FormControl(ve.name || '', Validators.required);
    });
    this.veNameForm = new FormGroup(group);
  }

  showUIForm(veId, attribute, isUpdatingAttribute) {
    this.isUpdating[veId][attribute] = isUpdatingAttribute;
  }

  async updateVEName(ve) {
    this.loadObj = this.uiConfigService.initialiseLoadingBooleans(true);
    const updateResponse = await this.glowService.updateVirtualEntity(ve.veId, { name: this.veNameForm.controls[ve.veId].value }).toPromise();
    if (updateResponse) {
      this.veList = this.veList.map((ve) => {
        if (ve.veId === updateResponse.veId) {
          ve.name = updateResponse.name;
        }
        return ve;
      });
    }
    this.showUIForm(ve.veId, 'name', false);
    this.showSavedAlert();
    this.loadObj = { ...this.loadObj, loading: false, loaded: true };
  }

  showSavedAlert() {
    this.saved = true;
    setTimeout(() => {
      this.saved = false;
    }, 2000);
  }

  async updateName() {
    this.loadObj = this.uiConfigService.initialiseLoadingBooleans(true);

    const arrayOfObj = Object.entries(this.veNameForm.controls);

    if (arrayOfObj.length > 0) {
      const updateResponse = [];
      const lastResponse = await arrayOfObj.reduce((accumulatorPromise, ve) => {
        return accumulatorPromise.then(async (response) => {
          if (response != null) updateResponse.push(response);
          return this.glowService.updateVirtualEntity(ve[0], { name: this.veNameForm.controls[ve[0]].value }).toPromise();
        });
      }, Promise.resolve());
      if (lastResponse) updateResponse.push(lastResponse);

      if (updateResponse) {
        this.loadObj = { ...this.loadObj, loading: false, loaded: true };
      }
    }
  }

  getVerificationStatus() {
    this.meterPointVerificationConsentSvc.getVerificationStatus();
  }

  updateMeters(e) {
    this.getVerificationStatus();
  }

  processVerificationStatus() {
    this.expiredVerificationStatus = [];
    this.meterPointRequiringRenewal = [];
    this.meterPointDoNotRequireRenewal = [];
    console.log(this.verificationStatus);
    this.verificationStatus.meterPointVerification.forEach((elem) => {
      if (elem.isVerified) {
        const prettyIsValidUntil = this.dateFormatService.fullDateFormatNoSeconds(new Date(elem.isValidUntil));
        const requiresConsentUpdate = this.meterPointVerificationConsentSvc.checkIfMeterPointConsentRefreshIsRequired(elem);
        this.isPastValidUntilDate(new Date(elem.isValidUntil));
        if (requiresConsentUpdate === true) {
          this.meterPointRequiringRenewal.push({ ...elem, requiresConsentUpdate, loadObj: this.uiConfigService.initialiseLoadingBooleans(false), prettyIsValidUntil });
          // console.log(this.meterPointRequiringRenewal, 'requiring renewal')
        } else if (!requiresConsentUpdate && this.isPastValidUntilDate(new Date(elem.isValidUntil))) {
          this.expiredVerificationStatus.push({ ...elem, loadObj: this.uiConfigService.initialiseLoadingBooleans(false), prettyIsValidUntil });
          // console.log(this.expiredVerificationStatus)
        } else {
          this.meterPointDoNotRequireRenewal.push({ ...elem, loadObj: this.uiConfigService.initialiseLoadingBooleans(false), prettyIsValidUntil });
          // console.log(this.meterPointDoNotRequireRenewal)
        }
      } else {
        this.expiredVerificationStatus.push({ ...elem });
      }
      console.log(this.expiredVerificationStatus);
    });

    // (this.expiredVerificationStatus)
  }

  isPastValidUntilDate(validUntilDate) {
    const today = new Date();
    if (today > validUntilDate) {
      return true;
    } else false;
  }

  openSetUpModal() {
    const modalRef = this.modalService.open(SetupSmartMeterModalComponent, {
      scrollable: true,
      backdrop: 'static',
      centered: true,
      size: 'xl',
    });
    modalRef.componentInstance.parentActivatedRoute = this.route
  }
}
