import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlowService } from 'src/app/services/glow.service';
import { StrapiService } from 'src/app/services/strapi.service';
import { LoginModalComponent } from '../login-modal/login-modal.component';
import { SuccessModalComponent } from '../success-modal/success-modal.component';
import { SystemIncidentsMailSubscriptionModalComponent } from '../system-incidents-mail-subscription-modal/system-incidents-mail-subscription-modal.component';

@Component({
  selector: 'app-system-incidents-content',
  templateUrl: './system-incidents-content.component.html',
  styleUrls: ['./system-incidents-content.component.scss'],
})
export class SystemIncidentsContentComponent implements OnInit {
  subscribePrompt: string;
  isLoggedIn;
  isSubscribed;

  incidentTimeLines = [
    { title: 'Live incidents', id: 'live' },
    { title: 'Upcoming incidents', id: 'upcoming' },
    { title: 'Past incidents', id: 'past' },
  ];
  incidents: any[] = [];
  upcomingIncidents: any[] = [];
  pastIncidents: any[] = [];
  liveIncidents: any[] = [];
  modalReference: any;
  incidentTimeline = { title: 'Live incidents', id: 'live' };

  loading = true;
  error;
  constructor(
    private strapiService: StrapiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private route:  ActivatedRoute,
    private router: Router,
    private glowService: GlowService
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params && params.modal && params.modal === 'incidents-subscription') {
        if (!this.getIsLoggedIn()) {
          this.openLoginModal();
        } else {
          this.openSubscribeModal();
        }
      }
      if (params && params.incidentTimeline) {
        this.incidentTimeline = this.incidentTimeLines.filter((otl) => otl.id === params.incidentTimeline)[0];
      }
    });
  }

  ngOnInit() {
    this.getAllIncidents();
    this.isLoggedIn = this.getIsLoggedIn();
    this.getIsSubscribed();
  }

  // This function checks whether a user is logged in by checking
  // whether the id_token is stored in localStorage.
  getIsLoggedIn() {
    return localStorage.length > 0 && localStorage.getItem('id_token');
  }

  getIsSubscribed() {
    return false
    // this.glowService.getUserActions('IncidentReportingSubscription').subscribe((resp) => {
    //   this.isSubscribed =
    //     resp &&
    //     resp.length > 0 &&
    //     resp.sort((a: any, b: any) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf())[0].data
    //       .subscriptionId;
    // });
  }

  // Get the text for the subscribe button based on whether the user is logged in or not.
  // getSubscribePrompt() {
  //   return this.isLoggedIn() ? '<span> </span>' : 'Sign in and register your details';
  // }

  openSubscribeModal() {
    // Open the modal
    this.modalReference = this.modalService.open(SystemIncidentsMailSubscriptionModalComponent, {
      ariaLabelledBy: 'Subscribe modal',
      backdrop: 'static',
      size: 'md',
    });
    this.modalReference.result.then(
      (result) => {
        if (result.status === 'success') {
          this.isSubscribed = true;
          this.openSuccessModal();
        } else if (result.status === 'dismiss') {
          this.router.navigate(['/support/system-incidents']);
        } else if (result.status === 'error') {
          // Error Modal
        }else{
          this.router.navigate(['/support/system-incidents']);
        }
      },
      (error) => {
        // Error Modal
      }
    );
  }

  openSuccessModal() {
    this.modalReference = this.modalService.open(SuccessModalComponent, {
      ariaLabelledBy: 'Success modal',
      backdrop: 'static',
      size: 'lg',
    });
    this.modalReference.componentInstance.successMessage =
      'You have successfully subscribed to the Glowmarkt System Incidents mailing list!';
    this.modalReference.result.then(
      (result) => {
        this.router.navigateByUrl('/support/system-incidents');
      },
      (error) => {
        // Error Modal
      }
    );
  }

  openLoginModal() {
    this.modalReference = this.modalService.open(LoginModalComponent, {
      ariaLabelledBy: 'Login modal',
      backdrop: 'static',
    });
    this.modalReference.result.then(
      (result) => {
        // Login modal closed
        switch (result) {
          case 'success':
            // User logged in successfully
            this.openSubscribeModal();
            break;
          case 'dismiss':
            // User dismissed login modal
            this.router.navigate(['/support/system-incidents']);
            break;
          default:
            // this.router.navigate(['/support/system-incidents']);
            break;
        }
      },
      (error) => {
        // User logged in, but something went wrong
        // Error Modal
      }
    );
  }

  // This code returns all timelines except the current timeline.
  // The current timeline is the timeline that is currently displayed.
  // The other timelines are timelines that are not currently displayed.
  // The purpose of this code is to allow the user to switch timelines.
  getOtherTimelines() {
    return this.incidentTimeLines.filter((otl) => otl.id !== this.incidentTimeline.id);
  }

  // This code is used to calculate the estimated completion date of an incident.
  // If the incident has a targetCompletionDateTime, return that.
  // If the incident has a targetCompletionHours value, return an estimate based on the incidentDateTime plus the targetCompletionHours.
  // If the incident has neither a targetCompletionDateTime nor a targetCompletionHours, return an estimate based on the incidentDateTime plus 24 hours.

  getCompletionDateTime(incident) {
    const targetCompletionDateTime = incident.attributes.targetCompletionDateTime;
    const targetCompletionHours = incident.attributes.targetCompletionHours;
    const incidentDateTime = incident.attributes.incidentDateTime;

    // If the incident has a targetCompletionDateTime, return that.
    if (targetCompletionDateTime) {
      return targetCompletionDateTime;
    }

    // If the incident has a targetCompletionHours value, return an estimate based on the incidentDateTime plus the targetCompletionHours.
    if (targetCompletionHours && targetCompletionHours > 0) {
      const ms = targetCompletionHours * 3600000;
      return new Date(new Date(incidentDateTime).valueOf() + ms);
    }

    // If the incident has neither a targetCompletionDateTime nor a targetCompletionHours,
    // return an estimate based on the incidentDateTime plus 24 hours.
    const ms = 24 * 3600000;
    return new Date(new Date(incidentDateTime).valueOf() + ms);
  }

  async getAllIncidents() {
    // get all incidents from strapi
    this.loading = true;
    try {
      const incidents = await this.strapiService.getAllOfContentType('system-incidents', null, ['system_incident_affected_areas', 'system_incident_updates']);

      // filter upcoming incidents
      this.upcomingIncidents = incidents
        .filter(
          (incident) =>
            new Date() < new Date(incident.attributes.incidentDateTime) && !incident.attributes.completedDateTime
        )
        .sort(
          (o1, o2) =>
            new Date(o1.attributes.incidentDateTime).valueOf() - new Date(o2.attributes.incidentDateTime).valueOf()
        );

      // filter past incidents
      this.pastIncidents = incidents
        .filter((incident) => incident.attributes.completedDateTime)
        .sort(
          (o1, o2) =>
            new Date(o2.attributes.incidentDateTime).valueOf() - new Date(o1.attributes.incidentDateTime).valueOf()
        );

      // filter live incidents
      this.liveIncidents = incidents
        .filter(
          (incident) =>
            new Date() >= new Date(incident.attributes.incidentDateTime) && !incident.attributes.completedDateTime
        )
        .sort(
          (o1, o2) =>
            new Date(o1.attributes.incidentDateTime).valueOf() - new Date(o2.attributes.incidentDateTime).valueOf()
        );

      this.incidents = incidents;
    } catch (error) {
      this.error = error;
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  // A function that sorts an array of incident updates
  sortedUpdates(incident) {
    // Sort the incident updates by date
    return incident.attributes.system_incident_updates.data.sort(
      // Sort the updates by the date they were updated
      (update1, update2) => {
        // If either of the updates do not have a date, return 0
        if (!update1.attributes.updatedAt || !update2.attributes.updatedAt) return 0;
        // Otherwise, sort the updates by their date
        return new Date(update2.attributes.updatedAt).valueOf() - new Date(update1.attributes.updatedAt).valueOf();
      }
    );
  }
}
