import { Injectable } from '@angular/core';
import {
  tConfig,
  tEnergyCostResourceTypeMapping,
  tVETypeConfiguredGroups,
  tVETypeConfiguredInfo,
  tEventsConfigurations,
  tFunctionalGroupConfiguration,
  tRefreshRateConfigurations,
  tGlowEventConfiguration,
  tSupportedVETypeListing,
} from './app-config.typings';
import { environment } from '../../environments/environment';

@Injectable()
export class ConfigService {
  // private adminConfig: tAdminConfig;
  private appConfig: tConfig;
  public refreshRateConfigurations: tRefreshRateConfigurations;
  public resourceEnergyCostMapping: tEnergyCostResourceTypeMapping[];
  public veTypeConfig: tVETypeConfiguredGroups;
  public supportedVETypes: tSupportedVETypeListing[];
  public veTypeInfoDictionary: tVETypeConfiguredInfo;
  tempAccessAuthenticationTypeServiceName: string;

  constructor() {
    this.loadVeTypeGroupConfigurations();
  }

  loadTempAccessAuthServiceName() {
    this.tempAccessAuthenticationTypeServiceName = 'temp-access';
    return this.tempAccessAuthenticationTypeServiceName;
  }

  public loadConfig(): tConfig {
    this.appConfig = {
      application: {
        applicationId: environment.config.applicationId,
        applicationDisplayName: 'GlowMarkt',
        apipathname: 'glowmarkt-website',
        version: '0.0.1',
      },
      directories: {
        default: {
          directoryId: null,
        },
        glow: {
          directoryId: '951cffa7-863f-4ae7-8f7e-ed682e690f91',
        },
        tempAccess: {
          directoryId: 'f9f89b6f-db18-4c0a-b82b-cd675bc4c8cf',
        },
      },
      content: environment.config.content,
      environments: {
        prod: {
          glow: {
            endpoint: environment.baseUrl,
            rateLimitingHostUrl: environment.rateLimitingHostUrl,
          },
          stripe: {
            publicKey: environment && environment.stripePublicKey,
          },
        },
        dev: {
          glow: {
            endpoint: 'https://dev.glowmarkt.com/api/v0-1/',
            rateLimitingHostUrl: environment.rateLimitingHostUrl,
          },
          stripe: {
            publicKey: environment && environment.stripePublicKey,
          },
        },
        uat: {
          glow: {
            endpoint: 'https://dev.glowmarkt.com/api/v0-1/',
            rateLimitingHostUrl: environment.rateLimitingHostUrl,
          },
          stripe: {
            publicKey: environment && environment.stripePublicKey,
          },
        },
      },
      termsAndConditions: environment.config.termsAndConditions,
      functionalGroups: environment.config.functionalGroups,
    };
    return this.appConfig;
  }

  // Returns the minimum delta time the token should have before expiry in order to be refreshed (in days)
  public getTokenRefreshParameters(): number {
    // If the token expires in less than or equal to 3 days, refresh it.
    return 10;
  }
  public getEventConfigurations(): tEventsConfigurations {
    let eventConfigurations = {
      dataRefresh: 'data_refresh',
      dimensionChange: 'dimension_change',
      loading: 'loading',
      healthCheck: 'health_check',
      reflow: 'reflow', // Event triggered to reflow Highcharts
      tabChange: 'tab_change', // Event emitted when tab changes
      timerObservables: 'timer_observables', // Event emitted when timer_observables need to be turned on/off -WiFi set up
      requestHomeNetworkInfo: 'can_request_wifi_home_credentials',
    };
    return eventConfigurations;
  }

  public getRefreshRateConfigurations(): tRefreshRateConfigurations {
    this.refreshRateConfigurations = {
      authCheck: 10 * 60 * 1000, // 10 minutes
      healthStatus: 5 * 60 * 1000, // 5 minutes
      healthStatusNoIssues: 20 * 60 * 1000, // 20 minutes
      inbox: 10 * 60 * 1000, // 10 minutes
      languageContent: 60 * 60 * 1000, // 60 minutes
      version: 60 * 60 * 1000, // 60 minutes
      virtualEntity: 30 * 60 * 1000, // 30 minutes
      noVirtualEntity: 5 * 60 * 1000, // 5 minutes
      virtualEntityMissingResources: 2 * 60 * 1000, // 2 minutes
      notifications: 2 * 60 * 1000, // 2 minutes,
    };
    return this.refreshRateConfigurations;
  }

  // public loadFunctionalGroupList(): tFunctionalGroupConfiguration[] {
  //     return  environment.functionalGroupConfigs
  // }

  public loadVeTypeGroupConfigurations(): tVETypeConfiguredGroups {
    this.veTypeConfig = environment.veTypeGroupConfig;
    return this.veTypeConfig;
  }

  public loadVeTypeInfo(): tVETypeConfiguredInfo {
    if (!this.veTypeInfoDictionary) {
      this.veTypeInfoDictionary = environment.veTypeConfigDictionary;
    }

    return this.veTypeInfoDictionary;
  }

  public setVeTypeInfo(veTypeInfo: tVETypeConfiguredInfo): void {
    this.veTypeInfoDictionary = veTypeInfo;
  }

  public loadEventsConfigurations(): tGlowEventConfiguration {
    let eventConfigurations = {
      pageView: {
        key: 'page-view',
      },
      formSubmission: {
        key: 'form-submission',
      },
      login: {
        key: 'login',
      },
      logout: {
        key: 'logout',
      },
    };
    return eventConfigurations;
  }

  public loadSupportedVETypes(): tSupportedVETypeListing[] {
    this.supportedVETypes = [
      {
        veTypeId: '6b61c150-34f8-4e1e-9b65-1df2a63611a6',
        priority: 1,
      },
      {
        veTypeId: 'd7cf2c3c-a681-4cde-af24-82582ce6dcfa', // smart meter electricity only
        priority: 2,
      },
      {
        veTypeId: '23fa688b-77e7-4f05-a7c0-c721c38a6de9', // OT electricity only
        priority: 3,
      },
      {
        veTypeId: '5f30742f-ac02-4285-8b7e-51b4f0aecdd4', // DCC
        priority: 3,
      },
      {
        veTypeId: 'bafdfefa-890a-4586-8d9f-0ba358736b25', // AMR
        priority: 4,
      },
      {
        //THis is not added to live
        veTypeId: '338b7b6e-c495-4cd1-9fbd-6dbfe55f067c', // efergy electricity only
        priority: 6,
      },
      {
        veTypeId: '2a63a063-c889-452c-ac04-b8bf4389208e',
        priority: 7,
      },
    ];

    return this.supportedVETypes;
  }
}

/*Functional Groups

Functional groups enable a particular part of the application.

In the case of Reflect the user logging in should firstly have access in the Reflect Functional Group.

In the future should we wish to allow permission of certain aspects of the application to users, we would
add the relevant appFunctional groups to the above configuration.






*/
