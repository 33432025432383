import { Component, HostListener, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-installer-support-modal',
  templateUrl: './installer-support-modal.component.html',
  styleUrls: ['./installer-support-modal.component.scss']
})
export class InstallerSupportModalComponent implements OnInit {
  @HostListener('window:resize', []) onResize() {
    this.innerWidth = window.innerWidth;
  }

  firstSection: boolean = true
  secondSection: boolean
  innerWidth;
  thirdSection: boolean;
  fourthSection: boolean;
  fifthSection: boolean;

  modalTitle: string = 'Welcome to Glowmarkt'
  
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.onResize()
  }

  changeSection(section) {
    console.log(section)
    if (section == this.firstSection) {
  
      this.secondSection = true
      this.modalTitle = 'Step 1: Access customer property data '
      this.firstSection = !this.firstSection
    } else if (section = this.secondSection) {
      this.modalTitle = 'Step 1: Access customer property data'
     
      this.thirdSection = true
      this.secondSection = !this.secondSection
    } else if (section = this.thirdSection) {
      this.modalTitle = 'Step 2: View property data'


  
      this.fourthSection = true
      this.thirdSection = !this.thirdSection
    } else if (section = this.fourthSection) {
      this.modalTitle = 'More coming soon...'

  
      this.fifthSection = true
      this.fourthSection = !this.fourthSection
    }
  }

  back(section) {
    if (section == this.secondSection) {
      this.secondSection = !this.secondSection
      this.modalTitle = 'Welcome to Glowmarkt'
      this.firstSection = true
    } else if (section == this.thirdSection) {
      this.thirdSection = !this.thirdSection
      this.modalTitle = 'Step 1: Access customer property data'
      this.secondSection = true
    } else if (section == this.fourthSection) {
      this.fourthSection = !this.fourthSection
      this.modalTitle = 'Step 1: Access customer property data'
      this.thirdSection = true
    } else if (section == this.fifthSection) {
      this.modalTitle = 'Step 2: View property data'
      this.fifthSection = !this.fifthSection
      this.fourthSection = true
    }
  }

  getNumber() {
    if (this.firstSection) {
      return '1'
    } else if (this.secondSection) {
      return '2'
    } else if (this.thirdSection) {
      return '3'
    } else if (this.fourthSection) {
      return '4'
    } else if (this.fifthSection) {
      return '5'
    }
  }



}
