import { Injectable, EventEmitter } from '@angular/core';
import { tVeIdNameListing } from './app-config.typings';

@Injectable()

export class ServiceSelectorSvc {

    serviceId: string = 'ServiceSelectorSvc';
    selectedService: tVeIdNameListing
    selectedServiceLaoclStorageKey: string = 'selectedService'
    public serviceSet: EventEmitter<tVeIdNameListing> = new EventEmitter();

    constructor() { };

    announceSelectedService(serviceToBeSet: tVeIdNameListing) {
        this.serviceSet.emit(serviceToBeSet)
    }

    getSelectedService() {
        this.selectedService = JSON.parse(localStorage.getItem('selectedService'))
        return this.selectedService
    }

    setSelectedService(serviceToBeSet: tVeIdNameListing) {
        localStorage.setItem(this.selectedServiceLaoclStorageKey, JSON.stringify(serviceToBeSet))
        this.selectedService = serviceToBeSet
        this.announceSelectedService(this.selectedService)
        return { valid: true }
    }
};