import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddRadiatorModalComponent } from './add-radiator-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormValidationMessageModule } from '../form-validation-message/form-validation-message.module';

@NgModule({
  declarations: [AddRadiatorModalComponent],
  imports: [CommonModule, ReactiveFormsModule, FormValidationMessageModule, FormsModule],
  exports: [AddRadiatorModalComponent],
  entryComponents: [AddRadiatorModalComponent],
})
export class AddRadiatorModalModule {}
