import { Input,ViewChild,ElementRef, ChangeDetectorRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LandingService } from 'src/app/services/landing.service';
import { Utils } from 'src/utils';


@Component({
  selector: 'app-user-story-card',
  templateUrl: './user-story-card.component.html',
  styleUrls: ['./user-story-card.component.scss']
})
export class UserStoryCardComponent implements OnInit {
  @Input() userStory!:any


  constructor(private router:Router, private landingService:LandingService, private ref:ChangeDetectorRef) { }

  ngOnInit(): void {
    this.ref.detectChanges()
  }


  getUpdatedAt(){
    return new Date(this.userStory.attributes.updatedAt).toDateString().split(' ').slice(1).join(' ');
  }

  navigateToUserStory(){
    // this.landingService.setUserStoryId(this.userStory.id)
    this.router.navigateByUrl(`home/user-story/${this.userStory.attributes.slug}`);
  }

  userStoryRelativePath(){
    return `/home/user-story/${this.userStory.attributes.slug}`
  }

  getImageURL(userStory:any){
    return Utils.getApiServerAddress()+userStory.attributes.headingImage.data.attributes.url
  }

}
