import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstallerRegisterFormComponent } from './installer-register-form.component';
import { FormValidationMessageModule } from '../form-validation-message/form-validation-message.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ProfessionalRegisterStepperModule } from '../professional-register-stepper/professional-register-stepper.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [InstallerRegisterFormComponent],
  imports: [
    CommonModule, SharedModule, FormsModule, ReactiveFormsModule, FormValidationMessageModule, NgSelectModule, ProfessionalRegisterStepperModule
  ],
  exports: [InstallerRegisterFormComponent]
})
export class InstallerRegisterFormModule { }
