import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlowService } from 'src/app/services/glow.service';
import { tAccount } from 'src/app/services/glow.typings';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss', '../../pages.component.scss']
})
export class AccountSettingsComponent implements OnInit {
  pageTitle = "Account settings"
  currentPage = "";
  pageId: string = "Account Overview"
  account: tAccount
  constructor(private glowService: GlowService,
    private router: Router) { }

  ngOnInit() {
    const accountId = localStorage.getItem("id");
    this.getAccount(accountId)
  }

  getAccount(accountId: string) {
    this.glowService.getAccount().subscribe(account => {
      this.account = account
    }, error => {
      console.warn("An error occurred when retrieving account");
      console.warn(error)
    });
  }

  logOut() {
    this.glowService.logout().subscribe((resp) => {
      this.router.navigateByUrl('/login');
    }, (err) => {
      console.warn(err)
    })
  }
}
