import { Component, HostListener, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { filter } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlowService } from 'src/app/services/glow.service';
import { LandingSearchbarComponent } from '../landing-searchbar/landing-searchbar.component';
import { InvitationsService } from 'src/app/services/invitations.service';
import { Subscription, interval } from 'rxjs';
import { ConfigService } from 'src/app/services/app-config.service';
import { VirtualEntityService } from 'src/app/services/virtual-entity.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

export class HeaderComponent implements OnInit {

  @Input() url;
  @ViewChild('searchBar', { static: true }) searchBar: LandingSearchbarComponent;

  public navbarCollapsed = true;
  loginStatus: string;
  heatPumpServices: boolean = false;
  energyData: boolean = false;
  ourServices: boolean = false
  tools: boolean = false;
  search: boolean = false;
  offCanvas: boolean = false
  accountMenu: boolean = false;
  toolsMenu: boolean = false;
  innerWidth: number;
  virtualEntities
  roles
  user
  notificationLength;
  customerNotificationsLength;
  estimateRequests
  accountPages = false;
  notificationsRefreshSubscription: Subscription
  roleSubscription: Subscription

  notificationsStatusSubscription: Subscription
  subscriptionVirtualEntityList: Subscription
  accountPageNames = ['/pages/account', '/pages/property', '/pages/tools', '/professional/account', 'professional/landing', 'professional/tools']
  lastScrollPos = window.pageYOffset || document.documentElement.scrollTop;
  public hasValidAccessToken = false;
  headerPages = []
  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    //checks if doc has been clicked to close menu
    this.navbarCollapsed = true;
  }
  @HostListener('mouseleave', ['$event']) onLeave(e: MouseEvent) {
    this.closeMenus()
  }

  @HostListener('window:resize', []) onResize() {
    // checks size of window to view specific menu
    this.innerWidth = window.innerWidth;
    if (this.innerWidth >= 992) {
      this.closeMenus()
    }
  }

  @HostListener('window:scroll', []) addStyles() {
    const stickyNav: any = document.querySelector('.navbar-sticky');
    // const stickyHeight = stickyNav.offsetHeight;
    const stickyHeight = stickyNav.scrollHeight;
    // const stickySpace: any = document.querySelector('#sticky-space');
    const scTop = window.pageYOffset || document.documentElement.scrollTop;
    if (stickyNav) {
      if (scTop < this.lastScrollPos && scTop > 0) {
        // stickySpace.addClass('active');
        // (document.querySelector('#sticky-space.active') as any).style.height = stickyHeight + 'px';
        if (!stickyNav.className.includes('fixed-top')) {
          stickyNav.addClass('fixed-top');
          stickyNav.style.top = '0';
        }
        this.lastScrollPos = scTop;
      } else {
        // stickySpace.removeClass('active');
        // stickySpace.style.height = '0px';
        stickyNav.removeClass('fixed-top');
        stickyNav.style.top = `${-stickyHeight}`;
        this.lastScrollPos = scTop;
      }
    }
  }

  constructor(
    private router: Router,
    private glowService: GlowService,
    private auth: AuthenticationService,
    public oauthService: OAuthService,
    private invitationsService: InvitationsService,
    private configService: ConfigService,
    private veService: VirtualEntityService,




  ) {
    this.onResize();


    this.roleSubscription = this.auth.roleSet.subscribe((resp) => {
      this.roles = resp
      this.setPagesOnLoginStatus()
    })

    const navEndEvent$ = this.router.events.pipe(filter((e) => e instanceof NavigationEnd));
    navEndEvent$.subscribe((e: NavigationEnd) => {
      this.hasValidAccessToken = this.oauthService.hasValidAccessToken();
      this.loginStatus = this.hasValidAccessToken ? 'logged in' : localStorage.length > 0 && localStorage.getItem('id_token') ? 'logged in' : 'logged out';
      if (this.hasValidAccessToken) {
        this.getRoleFromLocalStorage()


      }
      this.setPagesOnLoginStatus()
    });




    this.notificationsStatusSubscription = this.invitationsService.PendingAcceptanceEstimateAnnouncer.subscribe((resp) => {
      if (resp.estimateRequests) {
        this.notificationLength = resp.estimateRequests.length
      } else this.notificationLength = 0
    })
    this.subscriptionVirtualEntityList = this.veService.virtualEntitiesForDashboard$.subscribe(async (veList) => {
      if (veList && veList.length) {
        this.processVeList(veList)
        if (this.estimateRequests && this.estimateRequests.length) {
          await this.invitationsService.validateVirtualEntitites(this.virtualEntities)
          if (this.invitationsService.validVEs.length) {
            this.notificationLength = this.estimateRequests.length
          } else {
            this.notificationLength = 1
          }
        } else { this.notificationLength = 0 }
      } else {
        if (this.estimateRequests && this.estimateRequests.length) {
          this.notificationLength = 1

        }
      }
    });
    this.notificationsStatusSubscription = this.invitationsService.EstimatesAnnouncer.subscribe((resp) => {
      if (resp.valid) {
        this.estimateRequests = resp.estimateRequests.filter(estimateRequest => estimateRequest.estimateRequestState == 'pending-user-eligibility')
        this.veService.findVirtualEntities()
      } else {
        this.notificationLength = 0
      }
    })


    this.setPagesOnLoginStatus()

  }

  ngOnInit(): void {

    this.stickyOn();
    this.checkIfUrlIsAccountPage();
    this.hasValidAccessToken = this.oauthService.hasValidAccessToken();
    // this.checkNotifications()
    this.initialiseTimeObservables()
    if (this.hasValidAccessToken) {
      this.invitationsService.getAllEstimateRequestsByState('pending-professional-acceptance')
    } else {
      this.invitationsService.getAllEstimateRequests()
    }
  }
  ngOnDestroy() {
    if (this.notificationsRefreshSubscription) {
      this.notificationsRefreshSubscription.unsubscribe()
    }
    if (this.notificationsStatusSubscription) {
      this.notificationsStatusSubscription.unsubscribe()
    }
  }
  getRoleFromLocalStorage() {
    this.roles = this.auth.getOIDCUserRolesFromLocalStorage()
    this.setPagesOnLoginStatus()

  }


  private initialiseTimeObservables() {
    let eventTimeConfigation = this.configService.getRefreshRateConfigurations();

    if (!this.notificationsRefreshSubscription) {
      this.notificationsRefreshSubscription = interval(eventTimeConfigation.notifications).subscribe(() => {
        this.checkNotifications()
      })
    }
  }
  processVeList(veList) {
    this.virtualEntities = veList;
  }

  checkNotifications() {
    if (this.hasValidAccessToken) {
      // console.log('checking notifs')
      this.invitationsService.getAllEstimateRequestsByState('pending-professional-acceptance')
    } else {

      this.invitationsService.getAllEstimateRequests()
    }
  }
  getLogInStatus() {
    return this.loginStatus
  }
  ngAfterContentChecked() {
    this.checkIfUrlIsAccountPage();
  }

  stickyOn() {
    const stickyNav: any = document.querySelector('.navbar-sticky');
  }

  //determines header on account page on phone screens
  checkIfUrlIsAccountPage() {
    this.accountPages = false
    if (!this.url) {
      this.accountPages = false;
      return;
    }
    const url = this.url
    this.accountPageNames.forEach((page) => {
      if (url.includes(page)) {
        this.accountPages = true;
      }
    })
  }

  goToLanding() {
    this.closeMenus()
    this.router.navigateByUrl('/home');
  }

  goToLogin() {
    this.closeMenus()
    this.router.navigateByUrl('/login');
  }

  logOut() {
    if (this.hasValidAccessToken) {
      this.auth.signout();
      this.router.navigateByUrl('/login');
    } else {
      this.glowService.logout().subscribe(
        (resp) => {
          // // console.log('logout', resp);
          this.router.navigateByUrl('/login');
        },
        (err) => {
          console.warn(err);
        }
      );
    }
  }

  closeMenus(e?) {
    this.heatPumpServices = false
    this.energyData = false
    this.ourServices = false
    this.tools = false
    this.search = false
    this.offCanvas = false
    this.accountMenu = false
  }

  openOffCanvas(event) {
    if (this.offCanvas) {
      this.closeMenus()
      event.stopPropagation();
    } else this.offCanvas = true
  }


  openMenu(event, menu) {
    this.closeMenus()
    event.stopPropagation()
    switch (menu) {
      case 'Heat pump services':
        return this.heatPumpServices = true
      case 'Energy data':
        return this.energyData = true;
      case 'Our services':
        return this.ourServices = true
      case 'Tools':
        return this.tools = true;
      case 'Search':
        return this.search = true
      case 'Account menu':
        return this.accountMenu = true;
      default:
        return;
    }
  }


  setPagesOnLoginStatus() {
    let pages = [{
      name: 'Heat Pump Resources',
      pages: [
        { path: '/community/heat-pump', title: 'Resources' },
        { path: '/case-studies', title: 'Case Studies' },
        { path: '/about-community/heat-pump', title: 'About Heat Pump Community' },
        { path: '/find-a-professional', title: 'Find an installer' },
        { path: this.loginStatus === 'logged in' ? '/pages/tools/heat-pump-cost-estimation' : '/login', title: 'Heat Pump Cost Estimation' },
        { path: this.loginStatus === 'logged in' ? '/pages/tools/heating-by-room' : '/login', title: 'Heating by room' },
        { path: this.loginStatus === 'logged in' ? '/pages/tools/estimated-thermal-performance' : '/login', title: 'Heat  Retention Score' },
      ],
    },
    {
      name: 'Energy data',
      pages: [
        { path: '/community/energy-data', title: 'Resources' },
        { path: '/about-community/energy-data', title: 'About Energy Data' },
        { path: this.loginStatus === 'logged in' ? '/pages/property/energy-data' : '/login', title: 'Download your data' },
      ],
    },
    {
      name: 'Our services',
      pages: [
        { path: '/bright', title: 'Bright' },
        // { path: '/installers', title: 'Installers' },
        { path: 'https://forum.glowmarkt.com/', title: 'Visit our forum' },
        { path: 'https://shop.glowmarkt.com/', title: 'Visit our shop' },


      ],
    },
    {
      name: 'Tools',
      pages: [
        { path: '/pages/tools/heating-by-room', title: 'Heating by Room' },
        { path: '/pages/tools/estimated-thermal-performance', title: 'Heat Retention Score' },
        { path: '/pages/tools/heat-pump-cost-estimation', title: 'Heat Pump Cost Estimation' },
        { path: '/pages/tools/case-study-creator', title: 'Case Study Creator' },
      ]
    },
    {
      name: 'Account pages',
      pages: [
        { path: this.hasValidAccessToken ? 'professional/landing' : '/pages/property', title: this.hasValidAccessToken ? 'Dashboard' : 'Properties', icon: 'bi bi-house-door' },

        { path: this.hasValidAccessToken ? '/professional/account/settings/profile' : '/pages/account/settings/account-settings', title: 'Settings', icon: 'bi bi-gear' },

      ]
    }
    ]

    let propertyPassportPage = { path: '/pages/account/property-passport', title: 'Property Passport', icon: 'bi bi-newspaper' }
    let invitationsPage = { path: this.hasValidAccessToken ? 'professional/account/invitations' : '/pages/account/invitations', title: 'Invitations', icon: 'bi bi-envelope' }
    let professionalNotificationsPage = { path: this.hasValidAccessToken ? '/professional/account/notifications' : '/pages/account/notifications', title: 'Notifications', icon: 'bi bi-bell' }
    let index = pages.findIndex(p => p.name == 'Account pages')

    //inserts property passport page for customers only 
    !this.hasValidAccessToken ? pages[index].pages.splice(1, 0, propertyPassportPage) : null

    // insert invitations and notifications for customers and installer professional accounts only
    !this.hasValidAccessToken || this.hasValidAccessToken && this.roles[0].role == 'installer' ? pages[index].pages.splice(1, 0, invitationsPage) : null

    !this.hasValidAccessToken || this.hasValidAccessToken && this.roles[0].role == 'installer' ? pages[index].pages.splice(1, 0, professionalNotificationsPage) : null

    this.headerPages = pages
  }




}