import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Subject } from 'rxjs';

import { ConfigService } from '../services/app-config.service';
import { tTermsConditions, tTermsConditionsUser } from './glow.typings';
import { ErrorMessageParser } from './error-handler.service';
import { GlowService } from './glow.service';
import { tHasUserAcceptedTermsConditions } from '../components/component.typings';

@Injectable()
export class TermsConditionsService {

    errorString: string;

    termsConditionsRef: string;
    termsConditions: tTermsConditions;

    private TermsConditionsAnnouncer = new Subject<tTermsConditions>();
    private TermsConditionsExistAnnouncer = new Subject<boolean>();

    termsConditions$ = this.TermsConditionsAnnouncer.asObservable();
    termsConditionsExist$ = this.TermsConditionsExistAnnouncer.asObservable();

    constructor(
        private configService: ConfigService,
        private errorMessageParser: ErrorMessageParser,
        private glowService: GlowService,
        private http: HttpClient) {
    };

    announceTermsConditions() {
        // console.log("announceTermsConditions")
        this.TermsConditionsAnnouncer.next(this.termsConditions);
    }

    announceTermsConditionsExit(hasTermsConditions: boolean) {
        this.TermsConditionsExistAnnouncer.next(hasTermsConditions);
    }

    findTermsConditions(reference = null): void {
        // console.log("findTermsConditions")
        if (this.glowService.isLoggedIn() === true) {
            // console.log("Get Terms and conditions via API")
            this.getCurrentTermsConditions();
        } else {
            // console.log("Get Terms and conditions from assets")
            this.getTermsConditionsFromAssets();
        }

    }

    // findCurrentTermsConditions(reference = null): Promise<tTermsConditions> {
    //     // console.log("findTermsConditions")
    //     if (!reference) {
    //         var configuration = this.configService.loadConfig();
    //         reference = configuration.termsAndConditions.reference;
    //     }
    //     if (this.glowService.isLoggedIn() === true) {
    //         // console.log("Get Terms and conditions via API")
    //         this.getTermsConditions();
    //     } else {
    //         // console.log("Get Terms and conditions from assets")
    //         this.getTermsConditionsFromAssets();
    //     }

    // }

    getCurrentTermsConditions(): void {
        var configuration = this.configService.loadConfig();
        this.termsConditionsRef = configuration.termsAndConditions.reference;

        this.glowService
            .getCurrentTermsAndConditions(this.termsConditionsRef)
            .subscribe(termsConditionsList => {

                if (termsConditionsList && Array.isArray(termsConditionsList) && termsConditionsList.length >= 1) {
                    for (var j = 0; j < termsConditionsList.length; j++) {
                        if (termsConditionsList[j].current === true) {
                            this.termsConditions = termsConditionsList[j];
                        }
                    }
                    this.announceTermsConditions();
                } else {
                    this.getTermsConditionsFromAssets()
                }
            },
                err => {
                    // console.log(err)
                    this.errorString = this.errorMessageParser.getErrorMessage(err);
                });
    }

    getTermsConditionsFromAssets(): void {
        this.http.get("assets/content/terms-conditions.json").subscribe((res: tTermsConditions) => {
            this.termsConditions = res;
            this.announceTermsConditions();
        })
    }

    async getCurrentTermsConditionsSync(reference = null): Promise<tTermsConditions> {
        let error;
        if (!reference) {
            const configuration = this.configService.loadConfig();
            reference = configuration.termsAndConditions.reference;
            this.termsConditionsRef = reference
        }
        let currentTermsConditions: tTermsConditions;
        try {
            // With a reference this API should return an array with a single document
            const currentTermsConditionsList: tTermsConditions[] = await this.glowService.getCurrentTermsAndConditions(reference).toPromise()
            if (Array.isArray(currentTermsConditionsList) && currentTermsConditionsList.length === 1) {
                currentTermsConditions = currentTermsConditionsList[0]
            } else {
                error = { isError: true, message: "ERROR" }
                console.warn("Terms and conditions, unexpected return")
                console.warn(currentTermsConditionsList)
            }
        } catch (e) {
            error = { isError: true, message: "ERROR" }
            console.warn(e)
        }

        if (error) {
            return this.getTermsConditionsFromAssetsSync()
        } else {
            return new Promise((resolve, reject) => resolve(currentTermsConditions))
        }
    }

    getTermsConditionsFromAssetsSync(): Promise<tTermsConditions> {
        return this.http.get<tTermsConditions>("assets/content/terms-conditions.json").toPromise()
    }

    checkUserTermsConditions() {
        var configuration = this.configService.loadConfig();
        this.termsConditionsRef = configuration.termsAndConditions.reference;

        this.glowService
            .getCurrentTermsAndConditions(this.termsConditionsRef)
            .subscribe(termsConditionsList => {

                if (termsConditionsList && Array.isArray(termsConditionsList) && termsConditionsList.length >= 1) {
                    for (var j = 0; j < termsConditionsList.length; j++) {
                        if (termsConditionsList[j].current === true) {
                            this.termsConditions = termsConditionsList[j];
                        }
                    }
                }
                this.announceTermsConditions();
                this.getUserTermsConditions();
            }, (err) => {
                // console.log(err)
                this.errorString = this.errorMessageParser.getErrorMessage(err);
            });
    }

    getUserTermsConditions() {
        this.glowService
            .getUserTermsAndConditionsByReference(this.termsConditionsRef)
            .subscribe(termsConditionsUserList => {
                var hasTermsConditions = false;

                if (termsConditionsUserList && Array.isArray(termsConditionsUserList) && termsConditionsUserList.length > 0) {
                    for (let i = 0; i < termsConditionsUserList.length; i++) {
                        var tcUserElem = termsConditionsUserList[i]
                        if (tcUserElem.current === true) {
                            //July 2018 Alex 
                            //Add functionality that would see whether we neeed to update t&cs. Move local storage saving accordingly
                            localStorage.setItem('termsConditionsUser', JSON.stringify(tcUserElem));

                            if (tcUserElem.tcId == this.termsConditions.tcId) {
                                hasTermsConditions = true;

                            } else {
                                //July 2018 Alex
                                //Add functionality that would see whether we neeed to update t&cs
                                hasTermsConditions = true;
                            }
                        } else {
                            continue;
                        }
                    }
                }
                this.announceTermsConditions();
                this.announceTermsConditionsExit(hasTermsConditions);
            }, (err) => {
                this.errorString = this.errorMessageParser.getErrorMessage(err);
            });
    }

    setUserTermsConditions(userTemrsConditions) {
        localStorage.setItem('termsConditionsUser', JSON.stringify(userTemrsConditions));
        this.glowService
            .sendUserTermsAndConditions(userTemrsConditions)
            .subscribe(tcUserStatus => {
                // console.log("setUserTermsConditions success",tcUserStatus)
            }, (err) => {
                console.warn(err);
            })

    }

    async checkIfUserHasAcceptedCurrentTermsAndConditions(): Promise<tHasUserAcceptedTermsConditions> {
        let error;
        const configuration = this.configService.loadConfig();
        this.termsConditionsRef = configuration.termsAndConditions.reference;
        let currentTermsConditions: tTermsConditions;
        try {
            // With a reference this API should return an array with a single document
            const currentTermsConditionsList: tTermsConditions[] = await this.glowService.getCurrentTermsAndConditions(this.termsConditionsRef).toPromise()
            if (Array.isArray(currentTermsConditionsList) && currentTermsConditionsList.length === 1) {
                currentTermsConditions = currentTermsConditionsList[0]
                // console.log("Current Terms & Conditions " + currentTermsConditions.tcId)
            } else {
                error = { isError: true, message: "ERROR" }
                console.warn("Terms and conditions, unxpected return")
                console.warn(currentTermsConditionsList)
            }
        } catch (e) {
            error = { isError: true, message: "ERROR" }
            console.warn(e)
        }

        let userTermsConditionsRecords: tTermsConditionsUser[];
        try {
            userTermsConditionsRecords = await this.glowService.getUserTermsAndConditionsByReference(this.termsConditionsRef).toPromise()
        } catch (e) {
            error = { isError: true, message: "ERROR" }
            console.warn(e)
        }

        return new Promise((resolve, reject) => {
            if (error) {
                reject(error)
            } else {
                let response = { hasUserAcceptedTermsConditions: false, record: null }
                if (Array.isArray(userTermsConditionsRecords)) {
                    let currentTermsConditionUser = userTermsConditionsRecords.find((tcu) => tcu.current === true && tcu.tcId === currentTermsConditions.tcId)

                    if (currentTermsConditionUser && currentTermsConditionUser.tcId === currentTermsConditions.tcId) {
                        // console.log("User has accepted terms and conditions")
                        response = { hasUserAcceptedTermsConditions: true, record: currentTermsConditionUser }
                    } else {
                        // console.log("User has accepted previous version of terms and conditions")
                        console.warn(currentTermsConditionUser)
                        response = { hasUserAcceptedTermsConditions: false, record: currentTermsConditionUser }
                    }
                }
                resolve(response)
            }
        })
    }
}


