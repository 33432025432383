import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountPagesComponent } from './account-pages.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { HelpModalComponent } from 'src/app/components/help-modal/help-modal.component';
import { HelpModalModule } from 'src/app/components/help-modal/help-modal.module';



@NgModule({
  declarations: [AccountPagesComponent],
  imports: [
    CommonModule, SharedModule, HelpModalModule
  ],
  exports: [AccountPagesComponent]
})
export class AccountPagesModule { }
