import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HpjInfographicComponent } from './hpj-infographic.component';



@NgModule({
  declarations: [HpjInfographicComponent],
  imports: [
    CommonModule],
  exports: [HpjInfographicComponent]
})
export class HpjInfographicModule { }
