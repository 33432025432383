import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-case-study-filter',
  templateUrl: './case-study-filter.component.html',
  styleUrls: ['./case-study-filter.component.scss'],
})
export class CaseStudyFilterComponent implements OnInit {
  @Input() caseStudies = [];
  @Output() filter: EventEmitter<any> = new EventEmitter();
  locationForm: FormGroup;
  attachmentTypeForm: FormGroup;
  locations = [];
  attachmentTypes = [];
  locationsSelected = 0;
  attachmentTypesSelected = 0;
  constructor(private fb: FormBuilder) {
    // this.locations = this.getLocationOptions();
    // // console.log(this.locations);
    // this.initLocationForm();
    // this.initAttachmentTypeForm();
  }

  ngOnInit() {}

  ngOnChanges() {
    if (this.caseStudies.length > 0 && this.locations.length === 0 && this.attachmentTypes.length === 0) {
      this.locations = this.getLocationOptions();
      this.attachmentTypes = this.getAttachmentTypeOptions();
      this.initLocationForm();
      this.initAttachmentTypeForm();
    }
  }

  getLocationOptions() {
    return Array.from(new Set(this.caseStudies.map((cs) => cs.postcodeDistrict)));
  }

  getAttachmentTypeOptions() {
    return Array.from(new Set(this.caseStudies.map((cs) => cs.before.building[0].builtForm)));
  }

  initLocationForm() {
    const group = {};
    this.locations.forEach((l) => {
      group[l] = new FormControl('', []);
    });
    this.locationForm = this.fb.group(group);
  }

  initAttachmentTypeForm() {
    const group = {};
    this.attachmentTypes.forEach((t) => {
      group[t] = new FormControl('', []);
    });
    this.attachmentTypeForm = this.fb.group(group);
  }
  resetForm(formGroup) {
    formGroup.reset();
    this.countSelected(formGroup);
  }

  countSelected(formGroup) {
    // console.log("selection", formGroup.value)
    let selected = Object.keys(formGroup.value).filter((key) => {
      return formGroup.value[key] === true;
    });
    switch (formGroup) {
      case this.locationForm:
        this.locationsSelected = selected.length;
        break;
      case this.attachmentTypeForm:
        this.attachmentTypesSelected = selected.length;
        break;
    }
    this.sendFilter();
  }

  sendFilter() {
    this.filter.emit({
      postcodeDistrict: this.locationForm.value,
      builtForm: this.attachmentTypeForm.value,
    })
  }
}
