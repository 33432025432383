import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-validation-message',
  templateUrl: './form-validation-message.component.html',
  styleUrls: ['./form-validation-message.component.scss']
})
export class FormValidationMessageComponent implements OnInit {
  @Input() error;
  @Input()success;
  constructor() { }

  ngOnInit() {
  }

}
