import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormValidationMessageComponent } from './form-validation-message.component';



@NgModule({
  declarations: [FormValidationMessageComponent],
  imports: [
    CommonModule
  ],
  exports: [FormValidationMessageComponent]
})
export class FormValidationMessageModule { }
