import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TariffComponent } from './tariff.component';
import { AccountNoDataCardModule } from 'src/app/components/account-no-data-card/account-no-data-card.module';



@NgModule({
  declarations: [TariffComponent],
  imports: [
    CommonModule, AccountNoDataCardModule
  ],
  exports: [TariffComponent]
})
export class TariffModule { }
