import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemIncidentSubscriptionManagementComponent } from './system-incident-subscription-management.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { FormValidationMessageModule } from '../form-validation-message/form-validation-message.module';

@NgModule({
  declarations: [SystemIncidentSubscriptionManagementComponent],
  imports: [CommonModule, SharedModule, SpinnerModule, FormValidationMessageModule],
  exports: [SystemIncidentSubscriptionManagementComponent],
})
export class SystemIncidentSubscriptionManagementModule {}
