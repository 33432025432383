import { EventEmitter, Injectable } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, from, Observable } from 'rxjs';

import { StatehandlerService } from './statehandler.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  roleSet: EventEmitter<any> = new EventEmitter()
  role;
  private _authenticated: boolean = false;
  private readonly _authenticationChanged: BehaviorSubject<boolean> = new BehaviorSubject(this.authenticated);

  constructor(private oauthService: OAuthService, private authConfig: AuthConfig, private statehandler: StatehandlerService) { }

  public get authenticated(): boolean {
    return this._authenticated;
  }

  public get authenticationChanged(): Observable<boolean> {
    return this._authenticationChanged;
  }

  checkIsRole(role: string) {
    const roles = this.getOIDCUserRolesFromLocalStorage();
    return roles && roles.some((elem) => elem.role === role);
  }

  public getOIDCUser(): Observable<any> {
    return from(this.oauthService.loadUserProfile());
  }

  roleAnnouncer(role) {
    this.roleSet.emit(role)
  }

  setOIDCUserRoles(user: any) {
    //exclude the orgIds
    const roles = this.getOIDCUserRoles(user).map((elem) => ({ role: elem.role }));
    localStorage.setItem('urn:zitadel:iam:org:project:roles', JSON.stringify(roles));
    this.roleAnnouncer(roles)
    return roles;
  }

  getOIDCUserRolesFromLocalStorage() {
    this.role = JSON.parse(localStorage.getItem('urn:zitadel:iam:org:project:roles'))
    return this.role
  }



  public getOIDCUserRoles(user: any) {
    return Object.entries(user['urn:zitadel:iam:org:project:roles']).reduce((acc, elem) => {
      const [key, value] = elem;
      const orgs = Object.keys(value);
      return [...acc, ...orgs.map((elem1) => ({ zitadelOrgId: elem1, role: key }))];
    }, []);
  }

  public async authenticate(setState: boolean = true, params: any = {}): Promise<boolean> {
    this.oauthService.configure(this.authConfig);
    this.oauthService.setupAutomaticSilentRefresh();

    this.oauthService.strictDiscoveryDocumentValidation = false;
    await this.oauthService.loadDiscoveryDocumentAndTryLogin();

    this._authenticated = this.oauthService.hasValidAccessToken();

    if (!this.oauthService.hasValidIdToken() || !this.authenticated) {
      const newState = setState ? await this.statehandler.createState().toPromise() : undefined;

      this.oauthService.initCodeFlow(newState, params);
    }
    this._authenticationChanged.next(this.authenticated);

    return this.authenticated;
  }

  async markAsAuthenticated(setState: boolean = true) {
    this.oauthService.configure(this.authConfig);
    this.oauthService.setupAutomaticSilentRefresh();

    this.oauthService.strictDiscoveryDocumentValidation = false;
    await this.oauthService.loadDiscoveryDocumentAndTryLogin();

    this._authenticated = this.oauthService.hasValidAccessToken();

    this._authenticationChanged.next(this.authenticated);

    return this.authenticated;
  }

  public signout(): void {
    // console.log('signout')
    this._authenticated = false;
    localStorage.removeItem('urn:zitadel:iam:org:project:roles');
    this.oauthService.logOut();
    this._authenticationChanged.next(false);
  }

  authorizationHeader() {
    return this.oauthService.authorizationHeader();
  }
}
