import { Component, Input, OnInit } from '@angular/core';
import { StrapiService } from 'src/app/services/strapi.service';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-left-horizontal-hero',
  templateUrl: './left-horizontal-hero.component.html',
  styleUrls: ['./left-horizontal-hero.component.scss'],
})
export class LeftHorizontalHeroComponent implements OnInit {
  @Input() allContent: any[] = [];
  @Input() heroContent: any;
  sideContent = [];
  bottomContent;
  constructor(private strapiService: StrapiService) {}

  ngOnInit() {
    this.sideContent = this.allContent.length == 4 ? this.allContent.slice(0, 2) : this.allContent.slice(0, 3);
    this.bottomContent = this.allContent.length == 4 ? this.allContent.slice(2) : this.allContent.slice(3);
  }
  contentRelativePath(content: any) {
    return `/home/${content.contentType}/${content.attributes.slug}`;
  }

  getTimeToRead(content) {
    if (content.contentType === 'tutorials') {
      let totalMin = 1;
      content.attributes.tutorial_sections.data.forEach((c: any) => {
        totalMin = totalMin + Utils.getTimeToRead(c.attributes.content);
      });
      return totalMin;
    } else {
      return content.attributes.content ? Utils.getTimeToRead(content.attributes.content) : 3;
    }
  }

  getDisplayImageUrl(content) {
    const url = this.strapiService.getSmallDisplayImageUrl(content);
    return { 'background-image': `url(${url})` };
  }
}
