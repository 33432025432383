// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: true,
  baseUrl: 'https://api.glowmarkt.com/api/v0-1/',
  cookieDomain: 'glowmarkt.com',
  rateLimitingHostUrl: 'https://apil.glowmarkt.com/api/v0-1/',
  contentSystemBaseUrl: 'https://strapi.glowmarkt.com',
  contentSystemReadBearer:
    'Bearer 2d876f172d992953b74082ebcd35a34a53e448368552cad2b4451dc2953e8e17c6194b0ecdaf5f3f33999caab4b5997e8071b984499c11fa22c5c682e172599efcd42532499bae2a1dbd6191d374015005bc6c1b08fbb06131a0c15ad845e94974bfcbb723e4329bcf6f46c34fad92a620f519f06be823a8af4387ac6dddbfb7',
  contentSystemPostBearer:
    'Bearer ec986b9a717920bc5324b3b5ca36c0e14533144b2242587d387055a818edd4d787bfe17795ecb5bd2cc0cf42608adc650d8423c3cb2d9bc27cb471d096c1e931edba6f327f38c4783e55c0acd213cdd70ab4a177879ef5e374459419ec4fb3ad6c9a8b6043e20555292364a76145d30663e98b4ce496e05bc56d72eb875ac614',
  contentSystemDeleteBearer:
    'Bearer 6a53dca1577ff538af957d5dd256e6eb6c68176e320d703b93b51ec9c479a457a8e906a48026b07e01d0cea21a878b8d1ffbc9b924d502325ef8813f7b2384cacdc16e12170353d09af1531ee60ef2fb6637a425fcee177e5affbb58949479aca26caec87701bdb06b33a9ed87e3f4d978bc6a695debf094d0e1fce79dd7667c',
  contentSearchBaseUrl: 'https://meilisearch.glowmarkt.com',
  contentSearchApiKey: '4175657805154f4bbd249ece5229eb1e824308de8ee629c992631582c0812586',
  stripePublicKey: 'pk_live_Q4aFriIQFFI0vkz3N2SeSDzJ',
  config: {
    applicationId: 'b0f1b774-a586-4f72-9edd-27ead8aa7a8d',
    content: {
      supportDetails: {
        reference: 'Bright_support_details_v1',
      },
      faqs: {
        reference: 'GlowProFaqWeb',
      },
      languageContent: {
        reference: 'GlowmarktLanguageContent',
        defaultLanguageCode: 'en-GB',
        version: '1.0.0',
      },
    },
    termsAndConditions: {
      reference: 'GlowmarktWebTermsConditions',
      version: '1.0.0',
    },
    functionalGroups: {
      // auth: "962167bd-b1b9-4300-99d2-9f7ba067c73e",
      // estates: "c80ffc08-f1d7-43ab-98c2-b70c17768217",
      // finance: "415dd8e2-a215-4134-97f9-3c4a10d21d09",
      // repairs: "0ede7ec5-b40d-4918-a511-01334001df5d",
      // sustainability: "57e925f5-b405-4d03-b43c-8f65823280a4",
      // support: "a4c39708-87f4-47e4-a094-44edcc439fc4"
    },
  },
  charts: {
    colour: {
      energy: ['#E77C2B', '#154E72', '#9F9F9F', '#CC4528', '#8bbc21', '#FFBD33', '#33FF71'],
      temperature: ['#FF5733', '#e7da2b', '#ffbd33'],
    },
    enrollmentAnalysis: {
      pending: '#FFB847',
      enrolled: '#24b795',
      issues: '#E32B4D',
    },
  },
  veTypeGroupConfig: {
    singleSite: [
      'ba4c37f3-aa21-464e-b9da-8995074b2c10',
      '741a9e86-431f-4833-8983-9d412b92f873',
      'e5b81686-3dfd-4737-a799-7900c65f48d0',
      '2440ddfe-098e-4934-b75a-6cfe9b98d430',
      '9319234a-0182-4a27-9034-b5f167421835',
      '7ffead48-2df8-42be-849d-dcff35c6311d',
    ],
    groups: ['f8ea110a-a012-4d84-a835-7dfbb26e97f6'],
    groupOfGroups: ['0665eee0-08bd-4915-b50d-39878f560317'],
  },
  veTypeConfigDictionary: {
    '7ffead48-2df8-42be-849d-dcff35c6311d': {
      name: 'Properties',
      reference: 'properties',
    },
    'f8ea110a-a012-4d84-a835-7dfbb26e97f6': {
      name: 'Groups',
      reference: 'groups',
    },
  },
  zitadel: {
    clientId: '225750021423851812@glowmarkt',
    issuer: 'https://auth.glowmarkt.com',
    redirectUri: 'https://glowmarkt.com/auth/callback',
    postLogoutRedirectUri: 'https://glowmarkt.com/signedout',
    requireHttps: true,
    allowedUrls: ['https://auth.glowmarkt.com/admin/v1', 'https://auth.glowmarkt.com/management/v1', 'https://auth.glowmarkt.com/auth/v1/', 'https://auth.glowmarkt.com'],
  },
};
