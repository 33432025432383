import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeatingByRoomComponent } from './heating-by-room.component';
import { RadiatorSchematicModule } from 'src/app/components/radiator-schematic/radiator-schematic.module';

@NgModule({
  declarations: [HeatingByRoomComponent],
  imports: [CommonModule, RadiatorSchematicModule],
})
export class HeatingByRoomModule {}
