import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StrapiService } from 'src/app/services/strapi.service';

@Component({
  selector: 'app-glossary-sidebar',
  templateUrl: './glossary-sidebar.component.html',
  styleUrls: ['./glossary-sidebar.component.scss'],
})
export class GlossarySidebarComponent implements OnInit {
  @Input() communities;
  content;

  constructor(private strapiService: StrapiService, private router: Router) {}

  ngOnInit() {
    if (!this.communities) {
      this.getCommunities().then((communities) => {
        this.communities = communities;
      });
    }
  }
  async getCommunities() {
    const communities = await this.strapiService.getAllOfContentType('communities', null, ['top_glossary_term']);
    return communities;
  }

  navigateToTopTermsPage(slug) {
    this.router.navigateByUrl(`/glossary/${slug}`);
  }
}
