import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { GlowService } from './glow.service';
import { VirtualEntityService } from './virtual-entity.service';
import { ServiceSelectorSvc } from './service-selector.service';
import { v4 as uuidv4 } from 'uuid';
@Injectable({
  providedIn: 'root',
})
export class SchematicsService {
  schematicDocument: any = { status: 'in-progress', data: { floors: [], totalFloorArea: 0, totalHeatingPowerOutput: 0, heatingSystem: {}, version: 2.0 } };
  schematicsDocuments: any[];
  virtualEntities: any[] = [];
  saved: boolean = false;
  isEditing: boolean = false;
  selectedService: any;
  radiatorConstants: any = {};
  stage: any;

  roomOptions = [
    { imgPath: '../../../assets/images/schematics/bedroom.jpeg', title: 'Bedroom', id: 'bedroom' },
    { imgPath: '../../../assets/images/schematics/living-room.jpeg', title: 'Living Room', id: 'livingRoom' },
    { imgPath: '../../../assets/images/schematics/kitchen.jpeg', title: 'Kitchen', id: 'kitchen' },
    { imgPath: '../../../assets/images/schematics/bathroom.jpeg', title: 'Bathroom', id: 'bathroom' },
    { imgPath: '../../../assets/images/schematics/other.jpeg', title: 'Other', id: 'other' },
  ];

  SchematicsAnnouncer = new Subject<any>();
  schematicDocument$ = this.SchematicsAnnouncer.asObservable();

  schematicDocumentsAnnouncer = new Subject<any>();
  schematicDocuments$ = this.schematicDocumentsAnnouncer.asObservable();

  stageAnnouncer = new Subject<any>();
  stage$ = this.stageAnnouncer.asObservable();

  subscriptionVirtualEntityList: Subscription;
  subscriptionSelectedService: Subscription;

  constructor(private glowService: GlowService, private veService: VirtualEntityService, private serviceSelectorSvc: ServiceSelectorSvc) {}

  setStage(stage) {
    this.stage = stage;
    this.announceStage(stage);
  }

  getCanvasDataUrl(pixelRatio = 1) {
    const url = this.stage.toDataURL({ mimeType: 'image/jpeg', pixelRatio });
    return url;
  }

  setSelectedService(selectedService) {
    this.selectedService = selectedService;
  }

  getSchematicsDocuments() {
    this.veService.findVirtualEntities();
    this.veService.virtualEntitiesForDashboard$.subscribe(async (veList) => {
      console.log(veList && Array.isArray(veList) && veList.length > 0);
      if (veList && Array.isArray(veList) && veList.length > 0) {
        this.setVirtualEntities(veList);
        this.virtualEntities = veList;
        this.schematicsDocuments = await this.getDocumentList(this.selectedService.veId);
        this.announceSchematicDocuments(this.schematicsDocuments);
      }
    });
  }

  async getSchematic(veId: string, documentId: string) {
    const schematicRaw = await this.glowService.getVEDocumentByID(veId, documentId).toPromise();
    // this.announceSchematicDocument(JSON.parse(schematicRaw));

    return JSON.parse(schematicRaw);
  }

  addUnitsSchematicDocument(schematicDocument) {
    schematicDocument.data.floors = schematicDocument.data.floors.map((floor) => {
      floor.rooms = floor.rooms.map((room) => {
        room.radiators = room.radiators.map((rad) => {
          if (!rad.units) {
            rad.units = {
              width: rad.type === 'underFloorHeating' ? 'm' : 'cm',
              length: rad.type === 'underFloorHeating' ? 'm' : 'cm',
              output: 'W',
            };
          }
          return rad;
        });
        return room;
      });
      return floor;
    });
    return schematicDocument;
  }

  addVersionSchematicDocument(schematicDocument) {
    if (!schematicDocument.data.version) {
      schematicDocument.data.version = 1.0;
    }
    return schematicDocument;
  }

  async getDocumentList(veId: string, queryString = '', documentType = 'heating.element.schematics') {
    const query = `?${queryString}&&documentType=${documentType}`;
    const documents = await this.glowService.getVEDocumentList(veId, query).toPromise();
    return documents;
  }

  async getSchematicsByStatus(status: string, veId?: string) {
    const veIdToUse = veId || this.serviceSelectorSvc.getSelectedService().veId;
    const documentList = await this.getDocumentList(veIdToUse, `status=${status}`);
    const refCreatedMap = {};
    for (const document of documentList) {
      if (!refCreatedMap[document.reference]) {
        refCreatedMap[document.reference] = document;
      } else if (new Date(document.createdAt).getTime() > new Date(refCreatedMap[document.reference].createdAt).getTime()) {
        refCreatedMap[document.reference] = document;
      }
    }
    this.announceSchematicDocuments(Object.values(refCreatedMap))
    return Object.values(refCreatedMap);
  }

  async getSchematicsCountByStatus(status: string, veId?: string) {
    const veIdToUse = veId || this.serviceSelectorSvc.getSelectedService().veId;
    const countObj = await this.glowService.getVirtualEntityDocumentCount(veIdToUse, `documentType=heating.element.schematics&status=${status}`).toPromise();
    return countObj.count;
  }

  announceSchematicDocument(schematicDocument) {
    console.log('announceSchematics');
    this.SchematicsAnnouncer.next({ ...schematicDocument });
  }

  announceSchematicDocuments(schematicsDocuments) {
    this.schematicDocumentsAnnouncer.next(schematicsDocuments);
  }

  announceStage(stage) {
    this.stageAnnouncer.next(stage);
  }

  setVirtualEntities(ves: any[]) {
    this.virtualEntities = [...ves];
  }

  setSchematicsDocuments(documents: any[]) {
    this.schematicsDocuments = [...documents];
  }

  setSchematicDocument(schematicDocument) {
    this.schematicDocument = { ...schematicDocument };
  }

  setIsEditing(isEditing: boolean) {
    this.isEditing = isEditing;
  }

  async createSchematicsDocument(schematics) {
    return this.glowService.createVirtualEntityDocument(this.selectedService.veId, this.getBody(schematics)).toPromise();
  }

  async createNewSchematicFromExisting(schematicDocument) {
    const newSchematic: any = { data: { ...schematicDocument.data } };
    newSchematic.status = 'in-progress';
    newSchematic.reference = schematicDocument.reference;
    return this.createSchematicsDocument(newSchematic);
  }

  async updateSchematicsDocument(veId, documentId, updateBody) {
    const resp = await this.glowService
      .updateVirtualEntityDocument(veId, documentId, updateBody)
      .toPromise()
      .catch((e) => console.warn('ERROR', e));
    return resp;
  }

  async setSchematicsAndUpdateSchematicsDocument(schematicDocument, documentId) {
    if (!this.selectedService) {
      this.selectedService = this.serviceSelectorSvc.getSelectedService();
    }
    this.saved = true;
    this.setSchematicDocument(schematicDocument);
    this.announceSchematicDocument(schematicDocument);
    await this.updateSchematicsDocument(this.selectedService.veId, documentId, this.getBody(schematicDocument));
    setTimeout(() => {
      this.saved = false;
    }, 1500);
  }

  async updateSchematicsDocumentStatus(schematicDocument, documentId, status) {
    if (!this.selectedService) {
      this.selectedService = this.serviceSelectorSvc.getSelectedService();
    }
    this.saved = true;
    await this.glowService.updateVEDocumentStatus(this.selectedService.veId, documentId, status).toPromise();
    schematicDocument.status = status;
    this.setSchematicDocument(schematicDocument);
    this.announceSchematicDocument(schematicDocument);
    setTimeout(() => {
      this.saved = false;
    }, 1500);
  }

  getBody(schematicDocument) {
    return { reference: schematicDocument.reference || uuidv4(), status: schematicDocument.status || 'in-progress', documentType: 'heating.element.schematics', contentType: 'json', data: { ...schematicDocument.data } };
  }

  updateRoom(schematicDocument, schematicId, room, floor) {
    const index = floor.rooms.map((r) => r.id).indexOf(room.id);
    if (index > -1) {
      room.radiators = room.radiators.map((rad) => {
        const updated = { ...rad };
        updated.id = rad.id + '-' + room.id;
        return updated;
      });
      floor.rooms[index] = { ...room };
    } else {
      let numOfRoomsOfRoomType = floor.rooms.filter((r) => r.type === room.type).length + 1;
      room.id = `${room.type}-${floor.id}-${numOfRoomsOfRoomType}`;
      room.title = this.roomOptions.find((roomType) => roomType.id === room.type).title + ` ${numOfRoomsOfRoomType}`;
      room.radiators = room.radiators.map((rad) => {
        const updated = { ...rad };
        updated.id = rad.id + '-' + room.id;
        return updated;
      });
      floor.rooms.push(room);
    }
    floor.estimatedArea = this.getEstimatedFloorArea(floor);
    schematicDocument.data.totalFloorArea = this.getEstimatedTotalFloorArea(schematicDocument.data);
    schematicDocument.data.totalHeatingPowerOutput = this.getEstimatedTotalHeatingPowerOutput(schematicDocument.data);
    this.updateSchematic(schematicDocument, schematicId, floor);
  }

  removeRoom(schematic, schematicId, room, floor) {
    const index = floor.rooms.map((r) => r.id).indexOf(room.id);
    const roomNum = room.title.split(' ').slice(-1)[0];
    floor.rooms.splice(index, 1);
    floor.rooms = floor.rooms.map((r) => {
      const rNum = r.title.split(' ').slice(-1)[0];
      if (rNum > roomNum && r.type == room.type) {
        const updatedRoom = { ...r };
        const splitTitle = room.title.split(' ');
        splitTitle[splitTitle.length - 1] = String(Number(rNum) - 1);
        updatedRoom.title = splitTitle.join(' ');
        updatedRoom.id = splitTitle.join('');
        return updatedRoom;
      } else {
        return r;
      }
    });
    floor.estimatedArea = this.getEstimatedFloorArea(floor);
    schematic.totalFloorArea = this.getEstimatedTotalFloorArea(schematic);
    schematic.totalHeatingPowerOutput = this.getEstimatedTotalHeatingPowerOutput(schematic);
    this.updateSchematic(schematic, schematicId, floor);
  }

  addFloor(schematicDocument: any, schematicsId, floorSelection: any) {
    schematicDocument.data.floors.push(floorSelection);
    schematicDocument.data.totalFloorArea = this.getEstimatedTotalFloorArea(schematicDocument.data);
    schematicDocument.data.totalHeatingPowerOutput = this.getEstimatedTotalHeatingPowerOutput(schematicDocument.data);
    this.setSchematicsAndUpdateSchematicsDocument(schematicDocument, schematicsId);
  }

  removeFloor(schematicDocument, schematicsId, floorId: string) {
    const indexInSchematics = schematicDocument.data.floors.map((floor) => floor.id).indexOf(floorId);
    if (indexInSchematics > -1) {
      schematicDocument.data.floors.splice(indexInSchematics, 1);
      schematicDocument.data.floors = this.updateFloorNumbersFromIndex(indexInSchematics, [...schematicDocument.data.floors]);
    }
    schematicDocument.data.totalFloorArea = this.getEstimatedTotalFloorArea(schematicDocument.data);
    schematicDocument.data.totalHeatingPowerOutput = this.getEstimatedTotalHeatingPowerOutput(schematicDocument.data);
    this.setSchematicsAndUpdateSchematicsDocument(schematicDocument, schematicsId);
  }

  removeRadiator(schematics: any, schematicsId, radiatorId, roomId) {
    const floor = schematics.floors.find((floor) => floor.rooms.find((room) => room.id === roomId));
    const room = floor.rooms.find((room) => room.id === roomId);
    const radiatorIndex = room.radiators.map((radiator) => radiator.id).indexOf(radiatorId);
    room.radiators.splice(radiatorIndex, 1);
    floor.estimatedArea = this.getEstimatedFloorArea(floor);
    schematics.totalFloorArea = this.getEstimatedTotalFloorArea(schematics);
    schematics.totalHeatingPowerOutput = this.getEstimatedTotalHeatingPowerOutput(schematics);
    this.updateSchematic(schematics, schematicsId, floor);
  }

  updateFloorNumbersFromIndex(index, floorsArray) {
    return floorsArray.map((floor, i) => {
      if (i >= index) {
        const updatedFloor = { ...floor };
        const splitTitle = updatedFloor.title.split(' ');
        updatedFloor.title = splitTitle[0] + ' ' + (Number(splitTitle.slice(-1)[0]) - 1);
        updatedFloor.id = updatedFloor.title.toLowerCase().split(' ').join('');
        return updatedFloor;
      } else {
        return floor;
      }
    });
  }

  getEstimatedFloorArea(floor) {
    return floor.rooms
      .map((room) => {
        if (room.floorArea) {
          return room.floorArea;
        }
        return room.length * room.width;
      })
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }

  getEstimatedTotalFloorArea(schematics) {
    return schematics.floors.map((floor) => floor.estimatedArea).reduce((a, b) => a + b, 0);
  }

  getEstimatedTotalHeatingPowerOutput(schematics) {
    if (!schematics.floors || schematics.floors.length < 1) return 0;
    const output = schematics.floors
      .map((floor) => floor.rooms.map((room) => room.radiators.map((rad) => (isNaN(rad.output) ? 0 : Number(rad.output)))))
      .flat(3)
      .reduce((a, b) => a + b, 0);
    return output ? output.toFixed(2) : 0;
  }

  updateSchematic(schematicDocument, schematicId, floor) {
    schematicDocument.data.floors = schematicDocument.data.floors.map((f) => {
      if (f.id === floor.id) {
        f = { ...floor };
      }
      return f;
    });
    this.setSchematicsAndUpdateSchematicsDocument(schematicDocument, schematicId);
  }

  updateHeatingSystem(schematicDocument, schematicId, heatingSystem) {
    schematicDocument.data.heatingSystem = { ...heatingSystem };
    if (!heatingSystem.units) {
      schematicDocument.data.heatingSystem.units = { DHWCylinderSize: 'l' };
    }
    this.setSchematicsAndUpdateSchematicsDocument(schematicDocument, schematicId);
  }
}
