import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-professional-account-side-menu',
  templateUrl: './professional-account-side-menu.component.html',
  styleUrls: ['./professional-account-side-menu.component.scss']
})
export class ProfessionalAccountSideMenuComponent implements OnInit {
  @Input() pageTitle
  @Input() currentPage
  @Input() estimateRequestId;
  pages: any = []

  constructor(private router: Router, private route: ActivatedRoute) {
    route.url.subscribe((url) => {
      console.log(this.route.snapshot.url.map((segment) => segment.path))
     console.log('params',url)
   
    })

   }
  ngOnInit() {
    this.setPages()
    console.log(this.currentPage)
  }

  setPages (){
    this.pages = [
      {
        name: 'Home and energy data',
        path: 'home-energy-data',
      },
      {
        name: 'Manage quotes and estimates',
        path: 'estimate-quotes',
      },
      {
        name: 'Update customer heating system',
        path: '',
        disabled: true
      },
      {
        name: 'Submit to DNO, share with peers',
        path: '',
        disabled: true
      },
    ]


  }
  navigateToPage(path) {
    this.router.navigate([`professional/property/${this.estimateRequestId}/`+ path]);
  }

}
