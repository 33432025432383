import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InstallerSupportModalComponent } from '../components/installer-support-modal/installer-support-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscribable, Subscription } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-pages-professional',
  templateUrl: './pages-professional.component.html',
  styleUrls: ['./pages-professional.component.scss'],
})

export class PagesProfessionalComponent implements OnInit {

  url!: String;
  pageTitle!: string;
  currentPage!: string;
  roles;
  roleSubscription: Subscription
  constructor(
    private router: Router, private modalService: NgbModal, private auth: AuthenticationService
  ) {
    this.roleSubscription = this.auth.roleSet.subscribe((resp) => {
      this.roles = resp
      this.roles[0].role == 'installer' ? this.checkLogin() : null
    })
    this.url = this.router.url;
  }

  ngOnInit(): void {
  
  }

  public onRouterOutletActivate(event: any) {
    if (event.pageTitle) {
      this.pageTitle = event.pageTitle;
    }
    if (event.currentPage$) {
      event.currentPage$.subscribe((currPage) => {
        this.currentPage = currPage;
      });
    }
  }
  openModal() {
    this.modalService.open(InstallerSupportModalComponent, {
      scrollable: true,
      centered: true,
      size: 'lg',
    });
  }



  checkLogin() {
    if (this.getLoginFirstTimeLocalStorage()) {
      return
    } else {
      this.setLoginFirstTimeLocalStorage()
      this.openModal()
    }

  }

  getLoginFirstTimeLocalStorage() {
    return JSON.parse(localStorage.getItem('login'))

  }

  setLoginFirstTimeLocalStorage() {
    localStorage.setItem('login', JSON.stringify({ firstTime: true }));
  }
}