
import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  template: `<span class="{{className}}">{{renderValue | date: "dd MMM yyyy HH:mm"}}</span>`
})

export class CustomRenderDateComponent implements ViewCell, OnInit {

  renderValue: Date;
  className: string;

  @Input() value: any;
  @Input() rowData: any;

  day

  ngOnInit() {
    this.renderValue = this.value ? new Date(this.value) : null

    var today = new Date();
    var priorDate = new Date(new Date().setDate(today.getDate() - 30)).getTime();
    this.className = priorDate > new Date(this.value).getTime() ? 'date-nowrap error-messages' : 'date-nowrap'

  }
}