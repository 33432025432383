import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-professional-register-form',
  templateUrl: './professional-register-form.component.html',
  styleUrls: ['./professional-register-form.component.scss', '../register/register.component.scss']
})
export class ProfessionalRegisterFormComponent implements OnInit {
role: String;
  constructor( private route: ActivatedRoute) {  
    
    this.route.params.subscribe((params) => {
      this.role = params.role 
      // console.log(this.role)
   })
  

}
  ngOnInit() {
  }
}