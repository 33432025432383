import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlowService } from 'src/app/services/glow.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit {
  userEmail = ''

  constructor(private route: ActivatedRoute, private glow: GlowService) {
    route.queryParams.subscribe((params) => {
      this.userEmail = params['email']
    })
  }

  ngOnInit() { }

  loginRelativePath() {
    if (this.userEmail){
    return `/login?email=${this.userEmail}`
    } else return `/login`
  }
}
