import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { GlowService } from 'src/app/services/glow.service';
import { InvitationsService } from 'src/app/services/invitations.service';
@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss']
})
export class PropertyComponent implements OnInit {
  property;
  currentPage = ''
  estimateRequestId;
  private currentPageAnnouncer = new Subject<any>();
  currentPage$ = this.currentPageAnnouncer.asObservable()

  constructor(private route: ActivatedRoute,
   private glowService: GlowService, private invitationsService: InvitationsService) {
    route.params.subscribe((params) => {
      this.estimateRequestId = params.id
      this.getProperty(params.id)
    })

  }

  ngOnInit() {
  }

  getProperty(id) {
    this.invitationsService.getSnapshotsByEstimateRequestId(id)
  
  }
  public onRouterOutletActivate(event: any) {
    if (event.pageTitle) {
      this.currentPageAnnouncer.next(event.pageTitle)
      this.currentPage = event.pageTitle;
    }
    event.property = this.property
    event.estimateRequestId = this.estimateRequestId
  }
}
