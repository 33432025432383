import { Component, Input, OnInit } from '@angular/core';
import { LandingService } from 'src/app/services/landing.service';
import { Utils } from 'src/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { MetaTagsService } from 'src/app/services/meta-tags.service';
import { Subscription } from 'rxjs';
import { StrapiService } from 'src/app/services/strapi.service';

@Component({
  selector: 'app-blog-content',
  templateUrl: './blog-content.component.html',
  styleUrls: ['./blog-content.component.scss'],
})
export class BlogContentComponent implements OnInit {
  @Input() blog: any;
  // blog!: any;
  subscriptionSelectedBlog: Subscription;
  @Input() displayTimeToRead: boolean = true;

  timeToRead = 1;
  createdAt: string;
  constructor(
    private landingService: LandingService,
    private strapiService: StrapiService,
    private router: Router,
    private route: ActivatedRoute,
    private metaTagsService: MetaTagsService
  ) {}

  ngOnInit() {
    this.timeToRead = this.getTimeToRead();
    this.createdAt = this.getCreatedAt();
  }

  getUpdatedAt() {
    return Utils.formatZDate(this.blog.attributes.updatedAt);
  }

  getCreatedAt() {
    return Utils.formatZDate(this.blog.attributes.createdAt);
  }
  getTimeToRead() {
    return Utils.getTimeToRead(this.blog.attributes.content);
  }

  getForumUserProfileHref(handle) {
    return this.landingService.getForumUserProfileUrl(handle);
  }

  isBookmarked(){
    return this.strapiService.isContentBookmarked(this.blog.attributes.slug)
  }

  bookmarkBlog(){
    this.strapiService.bookmarkContent(this.blog.attributes.slug, 'blogs')
  }

  unbookmarkBlog(){
    this.strapiService.unbookmarkContent(this.blog.attributes.slug)
  }

  processContentToInnerHTML(content: any) {
    let result = content.replaceAll('<figcaption', '<figcaption class=" fw-bolder mb-3"');
    result = result.replaceAll('<figcaption', '<figcaption class=" fw-bolder mb-3"');
    result = result.replaceAll('<caption', '<figcaption class=" fw-bolder mb-3"');
    result = result.replaceAll('</caption>', '</figcaption>');
    result = result.replaceAll('<img', '<img class="pt-3"');
    //temporarily replaces h4 with h5
    result = result.replaceAll('<h4>', '<h5>');
    result = result.replaceAll('</h4>', '</h5>');
    return result;
  }
}
