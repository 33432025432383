import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MicrogeneratorFormComponent } from './microgenerator-form.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormTemplateModule } from '../form-template/form-template.module';



@NgModule({
  declarations: [MicrogeneratorFormComponent],
  imports: [
    CommonModule, SharedModule, FormTemplateModule
  ],
  exports: [MicrogeneratorFormComponent]
})
export class MicrogeneratorFormModule { }
