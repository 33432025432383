import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SitesComponent } from './sites.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SpinnerModule } from 'src/app/components/spinner/spinner.module';
import { ConsentMeterCardModule } from 'src/app/components/consent-meter-card/consent-meter-card.module';
import { SetupSmartMeterModalModule } from 'src/app/components/setup-smart-meter-modal/setup-smart-meter-modal.module';
import { SetupSmartMeterModule } from 'src/app/components/setup-smart-meter/setup-smart-meter.module';

@NgModule({
  declarations: [SitesComponent],
  imports: [CommonModule, SharedModule, SpinnerModule, ConsentMeterCardModule, SetupSmartMeterModule],
  exports: [SitesComponent],
})
export class SitesModule {}
