import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { StrapiService } from 'src/app/services/strapi.service';
import { ScrollSpyService } from '@uniprank/ngx-scrollspy';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { GlossarySearchbarComponent } from 'src/app/components/glossary-searchbar/glossary-searchbar.component';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-glossary',
  templateUrl: './glossary.component.html',
  styleUrls: ['./glossary.component.scss'],
})
export class GlossaryComponent implements OnInit {
  //placeholder array
  letters = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];
  mappedGlossaryTermsToLetters = {};
  loading: boolean = true;
  searchTerm: string = '';
  tagsToFilter: any[] = [];
  innerWidth: number;
  communities;
  invalidQuery: boolean = true;

  public activeSection: BehaviorSubject<{ id?: string; elementId?: string; nativeElement?: HTMLElement }> =
    new BehaviorSubject({});

  private _subscription: Subscription;

  @ViewChild('searchBar', { static: true }) searchBar: GlossarySearchbarComponent;

  @HostListener('window:resize', []) onResize() {
    // checks size of window to view specific menu
    this.innerWidth = window.innerWidth;
  }
  constructor(
    private strapiService: StrapiService,
    private _scrollSpyService: ScrollSpyService,
    private viewportScroller: ViewportScroller,
    private route: ActivatedRoute
  ) {
    route.queryParams.subscribe(async (params) => {
      if ( params['q[tags]']  || params['q[s]'] ) {
        this.invalidQuery = false;
        const tagsAsArray = params['q[tags]']?params['q[tags]'].split(','):[]
        const qFormatted = { label: params['q[s]'] };
        Utils.waitForElemById('searchBar').then((el) => {
          this.searchBar.setSearchParameters([...tagsAsArray, qFormatted]);
          this.searchBar.search();
        });
      } else if (!Object.keys(params).length) {
        this.invalidQuery = false;
        this.strapiService.getGlossaryTermsAlphabetically().then((glossaryTerms) => {
          this.mappedGlossaryTermsToLetters = this.mapGlossaryTermsToLetters(glossaryTerms, this.letters);
        });
        this.loading = false;
        this.initScrollSpy();
        this.innerWidth = window.innerWidth;
      }
    });
  }

  ngOnInit() {
    this.onResize();
  }

  ngOnDestroy() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  initScrollSpy() {
    // set offset because 2 sticky menu bars width single height of 50px
    this._scrollSpyService.setOffset('window', 100);
    // subscribe to window scroll listener, it is also possible to use an ScrollSpyElement id
    this._subscription = this._scrollSpyService.observe('window').subscribe((item) => {
      if (item != null) {
        const _nextSection = {
          id: item.id,
          elementId: item.scrollElementId,
        };
        this.activeSection.next(_nextSection);
        console.info(`ScrollSpyService: item:`, item);
      }
    });
  }

  getTermsStartingWithLetter(letter: string, glossaryTerms) {
    return glossaryTerms.filter((term) => term.attributes.term[0].toLowerCase() === letter.toLowerCase());
  }

  mapGlossaryTermsToLetters(glossaryTerms: any[], letters: any[]) {
    const mappedTerms = {};
    letters.forEach((letter) => (mappedTerms[letter] = this.getTermsStartingWithLetter(letter, glossaryTerms)));
    // // console.log("mappedTerms")
    return mappedTerms;
  }
  scrollToLetter(letter) {
    document.getElementById(letter).scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
  }

  updateGlossaryTerms(results) {
    this.loading = true;
    this.mappedGlossaryTermsToLetters = this.mapGlossaryTermsToLetters(results.glossaryTerms, this.letters);
    this.searchTerm = results.searchTerm;
    this.tagsToFilter = results.tagsToFilter;
    this.loading = false;
  }

  shouldDisplayNoResults() {
    return !this.loading && Object.values(this.mappedGlossaryTermsToLetters).flat().length === 0;
  }
}
