import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsentModalComponent } from './consent-modal.component';
import { FormValidationMessageModule } from '../form-validation-message/form-validation-message.module';



@NgModule({
  declarations: [ConsentModalComponent],
  imports: [
    CommonModule,
    FormValidationMessageModule
  ],
  exports: [ConsentModalComponent],
  entryComponents:[ConsentModalComponent]
})
export class ConsentModalModule { }
