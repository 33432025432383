import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { caseStudyFields } from '../../../../assets/content/case-study-fields.json';
import { CaseStudyFormService } from 'src/app/services/case-study-form.service';
import { Utils } from 'src/utils';
import { GlowService } from 'src/app/services/glow.service';
import { Router } from '@angular/router';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-outcomes-form',
  templateUrl: './outcomes-form.component.html',
  styleUrls: ['./outcomes-form.component.scss'],
})
export class OutcomesFormComponent implements OnInit {
  section;
  caseStudy;
  formattedCaseStudy;
  csSection: any = 'outcome';
  completionStatus: 'completed' | 'in-progress' = 'in-progress';
  loadObj: any;

  financeForm: FormGroup;
  satisfactionForm: FormGroup;
  hasReceivedGrantsForm: FormGroup;
  awardedGrantsForm: FormGroup;
  hasReceivedLoansForm: FormGroup;
  awardedLoansForm: FormGroup;

  financeFormName = 'financeForm';
  satisfactionFormName = 'satisfactionForm';
  hasReceivedGrantsFormName = 'hasReceivedGrantsForm';
  awardedGrantsFormName = 'awardedGrantsForm';
  hasReceivedLoansFormName = 'hasReceivedLoansForm';
  awardedLoansFormName = 'awardedLoansForm';
  caseStudyFields = caseStudyFields;

  finance: any;
  satisfaction: any;
  selectedService: any;
  subscriptionSelectedService: Subscription
  constructor(private fb: FormBuilder, private caseStudyFormService: CaseStudyFormService, private glowService: GlowService, private router: Router, private serviceSelectorSvc: ServiceSelectorSvc) {
    this.loadObj = this.caseStudyFormService.initLoadObj();
    this.section = this.caseStudyFormService.getSectionFromRouter();
    this.caseStudyFormService.caseStudyAnnouncer.subscribe((data) => {
      if (data) {
        this.caseStudy = data.caseStudy;
        this.formattedCaseStudy = data.formattedCaseStudy;
        this.processChanges();
      }
    });

    this.caseStudyFormService.formAnnouncer.subscribe((data) => {
      if (data && data.formName === this.financeFormName) {
        this.financeForm = data.form;
      }
      if (data && data.formName === this.satisfactionFormName) {
        this.satisfactionForm = data.form;
      }
      if (data && data.formName === this.hasReceivedGrantsFormName) {
        this.hasReceivedGrantsForm = data.form;
      }
      if (data && data.formName === this.awardedGrantsFormName) {
        this.awardedGrantsForm = data.form;
      }
      if (data && data.formName === this.hasReceivedLoansFormName) {
        this.hasReceivedLoansForm = data.form;
      }
      if (data && data.formName === this.awardedLoansFormName) {
        this.awardedLoansForm = data.form;
      }
      if (this.hasReceivedGrantsForm) this.caseStudyFormService.processChangeBooleanControl('awardedGrant', this.hasReceivedGrantsForm, this.awardedGrantsForm, 'awardedGrants', 'isTrue', this.awardedGrantsFormName);
      if (this.hasReceivedLoansForm) this.caseStudyFormService.processChangeBooleanControl('awardedLoan', this.hasReceivedLoansForm, this.awardedLoansForm, 'awardedLoans', 'isTrue', this.awardedLoansFormName);
    });
    this.subscriptionSelectedService = this.serviceSelectorSvc.serviceSet.subscribe((selectedService) => {
      this.selectedService = selectedService;
      this.caseStudyFormService.initServiceVariables(selectedService).then();
      this.loadObj = this.caseStudyFormService.initLoadObj();
    });
  }

  ngOnInit() {
    this.selectedService = this.serviceSelectorSvc.getSelectedService();
    if (!this.caseStudyFormService.hasInitServiceVariables) {
      this.caseStudyFormService.initServiceVariables(this.selectedService).then();
    } else {
      this.caseStudyFormService.announceLatestCaseStudy();
    }
    this.processChanges();
    this.initForm();
  }

  processChanges() {
    const hpSystem = this.caseStudyFormService.getHpSystemFromCs(this.csSection);
    this.finance = hpSystem && hpSystem.finance;
    this.satisfaction = hpSystem && hpSystem.satisfaction;
  }

  initForm() {
    this.caseStudyFormService.announceForm(this.financeFormName, this.caseStudyFormService.financeForm);
    this.caseStudyFormService.announceForm(this.satisfactionFormName, this.caseStudyFormService.satisfactionForm);
    this.caseStudyFormService.announceForm(this.hasReceivedGrantsFormName, this.caseStudyFormService.hasReceivedGrantsForm);
    this.caseStudyFormService.announceForm(this.awardedGrantsFormName, this.caseStudyFormService.awardedGrantsForm);
    this.caseStudyFormService.announceForm(this.hasReceivedLoansFormName, this.caseStudyFormService.hasReceivedLoansForm);
    this.caseStudyFormService.announceForm(this.awardedLoansFormName, this.caseStudyFormService.awardedLoansForm);
  }

  async saveChanges(completionStatus: 'completed' | 'in-progress' = 'in-progress') {
    this.completionStatus = completionStatus;

    let err, data;
    this.loadObj = this.caseStudyFormService.handleLoadObjLoading(this.loadObj, 'Saving changes...');
    const hpSystem = await this.caseStudyFormService.getOrCreateHpSystem(this.completionStatus);
    [err, data] = await Utils.promiseHandler(this.finance ? this.processExistingFinance() : this.processNewFinance(hpSystem));
    if (err) return (this.loadObj = this.caseStudyFormService.handleLoadObjError(this.loadObj, "Couldn't save changes - please try again later."));
    [err, data] = await Utils.promiseHandler(this.satisfaction ? this.processExistingSatisfaction() : this.processNewSatisfaction(hpSystem));
    if (err) {
      this.loadObj = this.caseStudyFormService.handleLoadObjError(this.loadObj, "Couldn't save changes - please try again later.");
      return this.loadObj;
    }
    this.caseStudyFormService.setFormAndAnnounce(this.financeFormName, this.financeForm);
    this.caseStudyFormService.setFormAndAnnounce(this.satisfactionFormName, this.satisfactionForm);
    this.caseStudyFormService.setFormAndAnnounce(this.hasReceivedGrantsFormName, this.hasReceivedGrantsForm);
    this.caseStudyFormService.setFormAndAnnounce(this.awardedGrantsFormName, this.awardedGrantsForm);
    await this.caseStudyFormService.announceLatestCaseStudy();
    this.caseStudyFormService.markSectionAsPristine(this.section);
    this.loadObj = this.caseStudyFormService.handleLoadObjSuccess(this.loadObj, 'Changes saved successfully.');
    return this.loadObj;
  }

  async saveAndContinue() {
    this.completionStatus = 'completed';
    this.loadObj = await this.caseStudyFormService.saveAndContinue(this.saveChanges.bind(this), this.section, this.loadObj);
  }

  getFinanceData() {
    const awardedGrants = (this.awardedGrantsForm && this.awardedGrantsForm.value.awardedGrants) || [];
    const awardedLoans = (this.awardedLoansForm && this.awardedLoansForm.value.awardedLoans) || [];
    let data = {
      journalEntryDataType: 'finance',
      ...this.financeForm.value,
      awardedGrants,
      awardedLoans,
    };
    data = Utils.setNumbersInObject(data);
    return { data };
  }

  getSatisfactionData() {
    let data = {
      journalEntryDataType: 'satisfaction',
      ...this.satisfactionForm.value,
    };
    data = Utils.setNumbersInObject(data);
    return { data };
  }

  async createFinanceJe(component) {
    const data = this.getFinanceData();
    return this.caseStudyFormService.createJournalEntry({
      journalEntryData: { ...data },
      componentDataType: component.componentDataType,
      componentVersion: component.componentVersion,
      componentId: component.componentId,
      completionStatus: this.completionStatus,
    });
  }

  async processNewFinance(component) {
    const financeJe = await this.createFinanceJe(component);
    await this.caseStudyFormService.addJournalEntryToCaseStudy({ journalEntry: financeJe, caseStudy: this.caseStudy, section: this.csSection });
  }

  async processExistingFinance() {
    const data = this.getFinanceData();
    await this.glowService.updateJournalEntry(this.finance.jeId, data).toPromise();
  }

  async processExistingSatisfaction() {
    const data = this.getSatisfactionData();
    await this.glowService.updateJournalEntry(this.satisfaction.jeId, data).toPromise();
  }

  async processNewSatisfaction(component) {
    const satisfactionJe = await this.caseStudyFormService.createJournalEntry({
      journalEntryData: this.getSatisfactionData(),
      componentDataType: component.componentDataType,
      componentVersion: component.componentVersion,
      componentId: component.componentId,
      completionStatus: this.completionStatus,
    });
    await this.caseStudyFormService.addJournalEntryToCaseStudy({ journalEntry: satisfactionJe, caseStudy: this.caseStudy, section: this.csSection });
  }

  removeForm(i, form, arrayName) {
    form = this.caseStudyFormService.removeForm(i, form, arrayName);
  }
  addForm(type, form, arrayName) {
    this.caseStudyFormService.addGroupToFormArray(type, form, arrayName);
  }
}
