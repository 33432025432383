import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsentMeterCardComponent } from './consent-meter-card.component';
import { FormValidationMessageModule } from '../form-validation-message/form-validation-message.module';
import { ConsentModalModule } from '../consent-modal/consent-modal.module';



@NgModule({
  declarations: [ConsentMeterCardComponent],
  imports: [
    CommonModule, FormValidationMessageModule, ConsentModalModule
  ],
  exports: [ConsentMeterCardComponent]
})
export class ConsentMeterCardModule { }
