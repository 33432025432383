import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstallerSupportModalComponent } from './installer-support-modal.component';
import { InstallerJourneyInfographicComponent } from '../infographics/installer-journey-infographic/installer-journey-infographic.component';
import { InstallerJourneyInfographicModule } from '../infographics/installer-journey-infographic/installer-journey-infographic.module';



@NgModule({
  declarations: [InstallerSupportModalComponent],
  imports: [
    CommonModule,
    InstallerJourneyInfographicModule
  ],
  exports: [InstallerSupportModalComponent],
  entryComponents: [InstallerSupportModalComponent]
})
export class InstallerSupportModalModule { }
