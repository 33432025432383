import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  template: `
    <clr-icon shape="check-circle" size="20" style="width: 20px; height: 20px; position: relative;
    left: -2px;" class="is-solid is-success" *ngIf="renderValue"></clr-icon>
    <clr-icon shape="times-circle" size="16" style="width: 16px; height: 16px;" class="is-solid is-error" *ngIf="!renderValue"></clr-icon>
  `,
})

export class CustomRenderTickboxIconComponent implements ViewCell, OnInit {

  renderValue: number;

  @Input() value: any;
  @Input() rowData: any;

  ngOnInit() {
    this.renderValue = this.value
  }
}