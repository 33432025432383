import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';
import { GlowService } from './glow.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: GlowService) {}

  canActivate() {
    // console.log("Authguard")
  	return this.authService.isAuthenticated()
  	.pipe(tap(authenticated => {
      // console.log("can Autheticate", authenticated)
        if (!authenticated) {
          this.authService.logout();
          this.router.navigate(['login']);
          return false;
          //   this.authService.logout().subscribe(response =>{
          //   this.router.navigate(['login']);
          //   return false;
          //   },
          //   error =>{
          //     this.router.navigate(['login']);
          //     return false;
          //   }
          // )
        } else {
          return true;
        }
      })
    );
  }
}
