import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { tLoadingElement } from 'src/app/services/app-config.typings';
import { CustomUIConfigService } from 'src/app/services/custom-ui-config.service';
import { GlowService } from 'src/app/services/glow.service';

@Component({
  selector: 'app-htc-modal',
  templateUrl: './htc-modal.component.html',
  styleUrls: ['./htc-modal.component.scss', '../../pages/pages.component.scss'], // shared css file
})

export class HtcModalComponent implements OnInit {

  @Input() veId: string;
  @Input() propertyPassportId: string;
  @Input() resources: any;
  @Input() epc: any;
  @Output() updatePassport: EventEmitter<any> = new EventEmitter();

  temperatureResources: any = [];
  loadObj: tLoadingElement;
  formInfo: FormGroup;
  maxDate: string = new Date(Date.now() - 86400000).toISOString().split("T")[0];
  passport: any;
  currentHtc: any;
  showSuccess: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private uiConfigService: CustomUIConfigService,
    private formBuilder: FormBuilder,
    private glowService: GlowService,
    private ccService: NgcCookieConsentService
  ) {
    this.formInfo = this.formBuilder.group({
      toDate: ['', [Validators.required]]
    })

    this.formInfo.controls['toDate'].setValue(this.maxDate)
  }

  ngOnInit() {
    this.loadObj = this.uiConfigService.initialiseLoadingBooleans();

    const elecMatch = this.resources.find(x => x.classifier == "electricity.consumption")

    if (Array.isArray(this.resources) && this.resources.length > 0 && elecMatch && this.epc) {
      this.getDevices()
    } else {
      let errorString = '';

      if (!this.epc) {
        errorString += "<li>We can't find an EPC attached to your property; we need it to calculate your Smart HTC value. For more information please email <a href='mailto:support@glowmarkt.com'>support@glowmarkt.com</a></li>";
      }

      if (!elecMatch) {
        errorString += "<li>We can't find a smart electricity meter assigned to your account; we need it to calculate your Smart HTC value. For more information please email <a href='mailto:support@glowmarkt.com'>support@glowmarkt.com</a></li>";
      }
      this.loadObj = { ...this.loadObj, loadError: true, loading: false, errorString }
    }
  }

  getDevices() {

    this.glowService.getDevices().subscribe(async resp => {
      // // console.log('getDevices', resp);

      const sensorTypeArr = ["05a115ed-0135-45ad-b677-130a5e971998", "ba06dbab-e759-4ad9-8b0e-e56a45b56a91"]
      const tempSensor = resp.filter(x => sensorTypeArr.includes(x.deviceTypeId));
      // // console.log('tempSensor', tempSensor);

      if (Array.isArray(tempSensor) && tempSensor.length > 0) {

        let resourceArray = []

        await tempSensor.map(x => {
          x.protocol.sensors.map(resource => {
            if (resource.protocolId == 'temp' || resource.protocolId == 'temperature') {
              resourceArray.push({ resourceId: resource.resourceId })
            }
          })
        });

        if (Array.isArray(resourceArray) && resourceArray.length > 0) {

          this.temperatureResources = resourceArray;
          this.loadObj.loaded = true;

        } else {
          this.loadObj = { ...this.loadObj, loadError: true, loading: false, errorString: "We can't find a temperature sensor assigned to your account; we need it to calculate your Smart HTC value. Order one <a href='https://shop.glowmarkt.com/products/sensors?_pos=1&_sid=7e262533f&_ss=r'>here</a>." }
        }
      } else {
        this.loadObj = { ...this.loadObj, loadError: true, loading: false, errorString: "We can't find a temperature sensor assigned to your account; we need it to calculate your Smart HTC value. Order one <a href='https://shop.glowmarkt.com/products/sensors?_pos=1&_sid=7e262533f&_ss=r'>here</a>." }
      }

    }, (err) => {
      console.warn(err);
      this.loadObj = { ...this.loadObj, loadError: true, loading: false, errorString: "We can't find a temperature sensor assigned to your account; we need it to calculate your Smart HTC value. Order one <a href='https://shop.glowmarkt.com/products/sensors?_pos=1&_sid=7e262533f&_ss=r'>here</a>." }
    });
  }

  getCurrentHtc(htcRecords) {
    return htcRecords ? htcRecords.sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf())[0] : null
  }

  calculateHTC() {

    const payload = {
      veId: this.veId,
      propertyPassportId: this.propertyPassportId,
      temperatureResources: this.temperatureResources,
      to: new Date(this.formInfo.controls['toDate'].value).toISOString()
    }
    // // console.log('calculateHTC', payload)

    this.loadObj = this.uiConfigService.initialiseLoadingBooleans();;
    this.sendGAEvent({ event: 'generateHTC' })

    this.glowService.calculateHTC(payload).subscribe(async resp => {
      // // console.log('calculateHTC resp', resp)
      if (resp.valid) {
        this.passport = resp;
        this.currentHtc = this.getCurrentHtc(resp.htcRecords)
        this.showSuccess = true;
      } else {
        this.loadObj = { ...this.loadObj, loadError: true, loading: false, errorString: resp.errorMsg }
      }
    }, (err) => {
      console.warn(err);
      this.loadObj = { ...this.loadObj, loadError: true, loading: false, errorString: 'Looks like we are having trouble generating your HTC value! Please try again later.' }
    });
  }

  close() {
    this.updatePassport.emit(this.passport);
  }

  sendGAEvent(params) {
    if (this.ccService.hasAnswered() && this.ccService.hasConsented()) {
      window.dataLayer.push(params)
    }
  }

}