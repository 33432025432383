import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { set } from 'core-js/core/dict';
import { Subscription } from 'rxjs';
import { CaseStudyFormService } from 'src/app/services/case-study-form.service';

@Component({
  selector: 'app-case-study-form-stepper',
  templateUrl: './case-study-form-stepper.component.html',
  styleUrls: ['./case-study-form-stepper.component.scss'],
})
export class CaseStudyFormStepperComponent implements OnInit {

  @Input() section;

  pages = [];
  sectionSuccessMap
  innerWidth;
  subscriptionCaseStudy: Subscription;
  subscriptionForm: Subscription;
  caseStudySubmitSuccessSubscription: Subscription;
  @HostListener('window:resize', []) onResize() {
    this.innerWidth = window.innerWidth;
  }
  constructor(private router: Router, private caseStudyFormService: CaseStudyFormService) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.section = this.caseStudyFormService.getSectionFromRouter();
      }
    })
    this.subscriptionForm = this.caseStudyFormService.formAnnouncer.subscribe((data) => {
      this.setPages();
    });
    this.caseStudySubmitSuccessSubscription = this.caseStudyFormService.caseStudySubmitSuccessAnnouncer.subscribe((data) => {
      this.sectionSuccessMap = data
    });
  }

  ngOnInit() {
    this.onResize()
  }

  getSectionStatus(section) {
    const empty = this.caseStudyFormService.isSectionEmpty(section);
    const validity = this.caseStudyFormService.getSectionValidity(section);
    if (empty) {
      return 'Not started';
    }
    if (validity) {
      return 'Completed';
    }
    return 'In progress';
  }

  goToPath(path) {
    this.router.navigate(path)
  }

  setPages() {
    this.pages = [
      {
        page: 'Pre-install building information',
        path: 'pre-installation-building',
        icon: 'bi bi-1-circle',
        status: this.getSectionStatus('pre-installation-building'),
      },
      {
        page: 'Pre-install heating system',
        path: 'pre-installation-heating',
        icon: 'bi bi-2-circle',
        status: this.getSectionStatus('pre-installation-heating'),
      },
      {
        page: 'Installation information',
        path: 'installation',
        icon: 'bi bi-3-circle',
        status: this.getSectionStatus('installation'),
      },
      {
        page: 'Post-install heating system',
        path: 'post-installation-heating',
        icon: 'bi bi-4-circle',
        status: this.getSectionStatus('post-installation-heating'),
      },
      {
        page: 'Post-install building information',
        path: 'post-installation-building',
        icon: 'bi bi-5-circle',
        status: this.getSectionStatus('post-installation-building'),
      },

      {
        page: 'Costs and outcomes',
        path: 'post-installation-outcomes',
        icon: 'bi bi-6-circle',
        status: this.getSectionStatus('post-installation-outcomes'),
      },
      { page: 'Other information', path: 'other-information', icon: 'bi bi-7-circle', status: this.getSectionStatus('other-information') },
    ];
  }

  navigateToPage(path) {
    //todo: check for unsaved changes and do a warning
    return this.router.navigate(['/pages/tools/case-study-creator/heat-pump'], { queryParams: { section: path } });
  }

  getNextPage() {
    const index = this.getSectionIndex()
    this.router.navigate([`/pages/tools/case-study-creator/heat-pump/${this.pages[index - 1].path}`])
  }

  getSectionIndex() {
    return this.pages.findIndex((page) => page.path === this.section)
  }

  getProgressBarPercentage() {
    const index = this.getSectionIndex()+1
   
    return `${index / 7 * 100}%` 
  }
}
