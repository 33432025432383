import { NgModule } from '@angular/core'
import { ObjToKeysPipe } from './obj-to-key.pipe';
import { ReversePipe } from './reverse-array.pipe';
import { TypeOfPipe } from './typeof.pipe';
import { DisplayGreaterDatePipe } from './date-sort-pipe'
import { SanitizeHtmlPipe } from './sanitise-html.pipe';
import { RangePipe } from './range-pipe';
import { AbsolutePipe } from './absolute-pipe';
import { sanitizeUrlPipe } from './sanitise-url.pipe';

const PIPES = [
    ObjToKeysPipe,
    ReversePipe,
    SanitizeHtmlPipe,
    TypeOfPipe,
    DisplayGreaterDatePipe,
    RangePipe,
    AbsolutePipe,
    sanitizeUrlPipe
];

@NgModule({
    imports: [],
    declarations: [...PIPES],
    providers: [],
    exports: [...PIPES]
})

export class PipesModule { }