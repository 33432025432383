import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseStudyAccountPageComponent } from './case-study-account-page.component';
import { HeatPumpCaseStudyAccountModule } from './heat-pump-case-study-account/heat-pump-case-study-account.module';
import { AccountNoDataCardModule } from 'src/app/components/account-no-data-card/account-no-data-card.module';
import { SpinnerModule } from 'src/app/components/spinner/spinner.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [CaseStudyAccountPageComponent],
  imports: [CommonModule, HeatPumpCaseStudyAccountModule, AccountNoDataCardModule, SpinnerModule, SharedModule],
  exports: [CaseStudyAccountPageComponent],
})
export class CaseStudyAccountPageModule {}
