import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SentInvitationsComponent } from './sent-invitations.component';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [SentInvitationsComponent],
  imports: [
    CommonModule, SharedModule
  ],
  exports: [SentInvitationsComponent]
})
export class SentInvitationsModule { }
