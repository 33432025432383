import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlowService } from 'src/app/services/glow.service';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';

@Component({
  selector: 'app-case-study-creator-tool',
  templateUrl: './case-study-creator-tool.component.html',
  styleUrls: ['./case-study-creator-tool.component.scss'],
})
export class CaseStudyCreatorToolComponent implements OnInit {
  pageTitle = 'Case Study Creator';
  caseStudy;
  selectedService;
  subscriptionSelectedService: Subscription;
  constructor(private router: Router, private glowService: GlowService, private serviceSelectorSvc: ServiceSelectorSvc) {
    this.subscriptionSelectedService = this.serviceSelectorSvc.serviceSet.subscribe((selectedService) => {
      this.selectedService = selectedService;
      this.checkCaseStudyExists(selectedService);
    });
  }

  ngOnInit() {
    this.selectedService = this.serviceSelectorSvc.getSelectedService();

    this.checkCaseStudyExists(this.selectedService);
  }

  checkCaseStudyExists(selectedService) {
    this.glowService.getAllCaseStudiesByVe(selectedService.veId).subscribe((cs) => {
      this.caseStudy = cs;
    });
  }

  navToPage() {
    this.router.navigate(['/pages/tools/case-study-creator/heat-pump']);
  }
}
