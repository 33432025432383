import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SetupSmartMeterModalComponent } from '../setup-smart-meter-modal/setup-smart-meter-modal.component';

@Component({
  selector: 'app-setup-smart-meter',
  templateUrl: './setup-smart-meter.component.html',
  styleUrls: ['./setup-smart-meter.component.scss'],
})
export class SetupSmartMeterComponent implements OnInit {
  modalRef: any;
  constructor(private modalService: NgbModal, private route: ActivatedRoute, private router: Router) {
    console.log('Constructing')

    route.queryParams.subscribe((params) => {
      this.modalRef ? this.modalService.dismissAll() : null;
      this.open(params.type);
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.modalService.dismissAll();
  }
  open(type) {
    this.modalRef = this.modalService.open(SetupSmartMeterModalComponent, {
      scrollable: true,
      backdrop: 'static',
      centered: true,
      size: 'xl',
    });
    this.modalRef.componentInstance.type = type;
    this.modalRef.componentInstance.parentActivatedRoute = this.route;
  }
}
