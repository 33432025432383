import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeatingSystemComponent } from './heating-system.component';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [HeatingSystemComponent],
  imports: [SharedModule,
    CommonModule
  ],
  exports: [HeatingSystemComponent]
})
export class HeatingSystemModule { }
