import { Component, HostListener, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';

@Component({
  selector: 'app-account-side-menu',
  templateUrl: './account-side-menu.component.html',
  styleUrls: ['./account-side-menu.component.scss'],
})

export class AccountSideMenuComponent implements OnInit {

  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    this.menuCollapsed = true;
  }
  @HostListener('window:resize', []) onResize() {
    this.innerWidth = window.innerWidth;
  }

  @Input() dropdownOptions: any;
  @Input() pageTitle: string;
  @Input() currentPage: string;
  @Output() switchServiceEvent: EventEmitter<any> = new EventEmitter();

  innerWidth: number;
  selectedService: any
  formInfo: FormGroup;
  menuCollapsed = true;
  pages: any = [
    {
      name: 'Your home',
      icon: 'bi bi-house-door',
      pages: [
        { path: '/pages/property/general-attributes', title: 'General attributes' },
        { path: '/pages/property/heating-system', title: 'Heating system' }
      ],
    },
    {
      name: 'Energy',
      icon: 'bi bi-lightning',
      pages: [
        { path: '/pages/property/energy-overview', title: 'Energy overview' },
        { path: '/pages/property/energy-data', title: "Download your data" }
      ],
    },
    {
      name: 'Devices',
      icon: 'bi bi-tv',
      pages: [
        // { path: '', title: 'IHD' },
        { path: 'pages/property/temperature-sensors', title: 'Temperature sensors' },
        // { path: '', title: 'Battery' },
        // { path: '', title: 'Solar' },
        // { path: '', title: 'EV' },
        // { path: '', title: 'Charge point' },

      ],
    },
    {
      name: 'Case study',
      icon: 'bi bi-journal-text',
      pages: [
        { path: 'pages/property/case-studies', title: 'Your case studies' },
      ],
    },
    
    {
      name: 'Activities',
      icon: 'bi bi-puzzle',
      pages: [
        { path: 'pages/property/activities/heat-pump-journey', title: 'Heat pump journey' }
      ]
    }

  ]

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private serviceSelectorSvc: ServiceSelectorSvc
  ) {
    this.formInfo = this.formBuilder.group({
      selectedService: ['', Validators.required],
    })
  }

  ngOnInit() {
    this.selectedService = this.serviceSelectorSvc.getSelectedService()
    if (this.selectedService) {
      this.formInfo.setValue({ selectedService: this.selectedService.name })
    }
    this.onResize()
  }

  ngOnChanges(){
    this.selectedService = this.serviceSelectorSvc.getSelectedService()
    if (this.selectedService) {
      this.formInfo.setValue({ selectedService: this.selectedService.name })
    }
    this.onResize()
  }

  navigateToPage(path) {
    this.router.navigate([path]);
  }

  openMenu(event) {
    event.stopPropagation();
    this.menuCollapsed = !this.menuCollapsed;
  }

  switchService(event) {
    const match = this.dropdownOptions.find(x => { return x.name == event.name })
    if (match) {
      this.dropdownOptions.map(x => x.active = false);
      match.active = true;
      this.selectedService = match;
      this.switchServiceEvent.emit(this.selectedService.veId);
    }
  }

}