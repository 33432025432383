import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JsonLdService } from 'src/app/services/json-ld.service';
import { SeoService } from 'src/app/services/seo.service';
import { StrapiService } from 'src/app/services/strapi.service';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss']
})
export class TutorialComponent implements OnInit {
  tutorial: any;
  url;
  loading: boolean = true;
  constructor(
    private route: ActivatedRoute,
    private strapiService: StrapiService,
    private router: Router,
    private seoService: SeoService
  ) {
    route.params.subscribe((params) => {
      this.getUrl();
      this.getTutorial(params.tutorial).then((tutorial) => {
        this.tutorial = tutorial;
        if (tutorial) {
          this.configureSEO(tutorial);
        }
        this.loading = false;
      });
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.seoService.resetSEOconfig();
  }
  async getTutorial(slug) {
    const tutorial = await this.strapiService.getContentBySlug('tutorials', slug);
    return tutorial;
  }

  configureSEO(tutorial) {
    const title = Utils.removeHTMLFromString(tutorial.attributes.title);
    const summary = Utils.removeHTMLFromString(tutorial.attributes.summary);
    const authorNames = tutorial.attributes.authors.data.map((author) => author.attributes.name);
    const image = this.tutorial.attributes.displayImage.data
      ? this.strapiService.getFileFullUrl(this.tutorial.attributes.displayImage.data.attributes.url)
      : '';
    const schema = JsonLdService.articleSchema(title, summary, [image], authorNames);
    this.seoService.configureSEO(title, summary, `https://glowmarkt.com${this.router.url}`, schema);
  }
  getUrl() {
    this.url = this.route.snapshot.url.map((segment) => segment.path);
  }

}
