import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { tResourceReadings, tResourceInstantReadings, tResourceSummaryReadings, tResourceMeterReading, tResource, tResourceListing, tResourceLastTime } from './glow.typings';
import { ErrorMessageParser } from './error-handler.service';
import { GlowService } from './glow.service';
import { tResourceTypeMapping, tResourceTypeRestrictionConfig } from './configuration.typings';
import { ResourceTypeConfigService } from './configuration.service';
import { tResourceError, tVeCumulativeValues, tTariffPlan, tVeCumulativeValuesWithEstimationFlag, tResourceAvailabilityElement } from '../components/component.typings';
import { DateFormatService } from './date-format.service';

@Injectable()
export class ResourceDataService {

  serviceId: string = "ResourceDataService"

  // Observable string sources
  private ReadingsAnnouncer = new Subject<tResourceReadings>();
  private CumulativeReadingsAnnouncer = new Subject<tVeCumulativeValues>();
  private CumulativeReadingsWithEstimationFlagAnnouncer = new Subject<tVeCumulativeValuesWithEstimationFlag>();
  private OffsetCumulativeReadingsWithEstimationFlagAnnouncer = new Subject<tVeCumulativeValuesWithEstimationFlag>();
  private SummaryReadingsAnnouncer = new Subject<tResourceSummaryReadings>();
  private TariffAnnouncer = new Subject<tTariffPlan>();
  private InstantReadingsAnnouncer = new Subject<tResourceInstantReadings>();
  private LastTimeAnnouncer = new Subject<tResourceLastTime>();
  private LastTimeErrorAnnouncer = new Subject<tResourceError>();
  private InstantReadingsErrorAnnouncer = new Subject<tResourceError>();
  private MeterReadingsAnnouncer = new Subject<tResourceMeterReading>();
  private MeterReadingsErrorAnnouncer = new Subject<tResourceError>();

  // Observable string streams
  readings$ = this.ReadingsAnnouncer.asObservable();
  instantReadings$ = this.InstantReadingsAnnouncer.asObservable();
  instantReadingsError$ = this.InstantReadingsErrorAnnouncer.asObservable();
  lastTime$ = this.LastTimeAnnouncer.asObservable();
  lastTimeError$ = this.LastTimeErrorAnnouncer.asObservable();
  meterReadings$ = this.MeterReadingsAnnouncer.asObservable();
  meterReadingsError$ = this.MeterReadingsErrorAnnouncer.asObservable();

  summaryReadings$ = this.SummaryReadingsAnnouncer.asObservable();
  cumulativeReadings$ = this.CumulativeReadingsAnnouncer.asObservable();
  cumulativeReadingsWithEstimationFlag$ = this.CumulativeReadingsWithEstimationFlagAnnouncer.asObservable();
  offsetCumulativeReadingsWithEstimationFlag$ = this.OffsetCumulativeReadingsWithEstimationFlagAnnouncer.asObservable();
  tariff$ = this.TariffAnnouncer.asObservable();

  resourceTypeMapping: tResourceTypeMapping
  resourceTypeRestrictionConfig: tResourceTypeRestrictionConfig
  constructor(
    private dateFormatService: DateFormatService,
    private errorMessageParser: ErrorMessageParser,
    private glowService: GlowService,
    private resourceTypeConfigService: ResourceTypeConfigService) {
    this.resourceTypeMapping = this.resourceTypeConfigService.loadResourceTypeMappings()
    this.resourceTypeRestrictionConfig = this.resourceTypeConfigService.loadResourceTypeRestrictionConfig()

  }

  announceReadings(readings: tResourceReadings) {
    this.ReadingsAnnouncer.next(readings);
  }
  announceInstantReadings(instantReadingsRaw: tResourceInstantReadings) {
    this.InstantReadingsAnnouncer.next(instantReadingsRaw);
  }
  announceInstantReadingsError(instantReadingsError: tResourceError) {
    this.InstantReadingsErrorAnnouncer.next(instantReadingsError);
  }
  announceLastTime(lastTime: tResourceLastTime) {
    this.LastTimeAnnouncer.next(lastTime);
  }
  announceLastTimeError(error: tResourceError) {
    this.LastTimeErrorAnnouncer.next(error);
  }
  announceMeterRead(meterRead: tResourceMeterReading) {
    this.MeterReadingsAnnouncer.next(meterRead);
  }
  announceMeterReadError(resourceDataError: tResourceError) {
    this.MeterReadingsErrorAnnouncer.next(resourceDataError);
  }
  announceCumulativeReadings(cumulativeReadings: tVeCumulativeValues) {
    this.CumulativeReadingsAnnouncer.next(cumulativeReadings);
  }
  announceCumulativeReadingsWithEstimationFlag(cumulativeReadings: tVeCumulativeValuesWithEstimationFlag) {
    this.CumulativeReadingsWithEstimationFlagAnnouncer.next(cumulativeReadings);
  }

  announceOffsetCumulativeReadingsWithEstimationFlag(cumulativeReadings: tVeCumulativeValuesWithEstimationFlag) {
    this.OffsetCumulativeReadingsWithEstimationFlagAnnouncer.next(cumulativeReadings);
  }
  announceTariff(tariffData: tTariffPlan) {
    this.TariffAnnouncer.next(tariffData);
  }
  announceSummaryReadings(summaryReadings: tResourceSummaryReadings) {
    this.SummaryReadingsAnnouncer.next(summaryReadings);
  }

  getInstanteneousReadings(resourceId: string) {
    this.glowService.getResourceCurrentReading(resourceId)
      .subscribe(instantReadings => {
        if (instantReadings) {
          if (instantReadings.data) {
            if (Array.isArray(instantReadings.data)) {
              this.announceInstantReadings(instantReadings);
            } else {
              console.warn("Instant Readings are not in array")
            }
          } else {
            // console.log("no data attached to instant readings")
          }
        } else {
          // console.log("Instant readings are null")
        }

      }, (error: any) => {
        console.warn(error);
        let errorMsg = this.errorMessageParser.getErrorMessage(error)
        console.warn(errorMsg);

        let errorResource = {
          error: errorMsg,
          resourceId
        }
        this.announceInstantReadingsError(errorResource)
      });
  }

  // async inferLatestAvailableReading(resourceListing: tResourceListing) {
  //   const to = this.dateFormatService.apiTimeToFormat(new Date())
  //   const from = this.dateFormatService.apiTimeFromFormat(this.dateFormatService.slideInTime('past', 'month', new Date()))
  //   let period: string = 'PT1H';

  //   if (this.resourceTypeRestrictionConfig[resourceListing.resourceTypeId]) {
  //     const minimumPeriod: string = this.resourceTypeRestrictionConfig[resourceListing.resourceTypeId].minimumAggPeriod || ""
  //     if (minimumPeriod != 'PT1H' && minimumPeriod !== 'PT30M' && minimumPeriod !== "PT1M") {
  //       period = minimumPeriod
  //     }
  //     try {
  //       const resourceReadings$ = this.glowService.getResourceReadings(resourceListing.resourceId, from, to, period)
  //       const readings = await resourceReadings$.toPromise();
  //       let firstReading;
  //       if (readings && Array.isArray(readings.data) && readings.data.length > 0) {
  //         firstReading = readings.data.reduce((acc, reading) => {
  //           if (reading && reading[1]) acc = [reading[0] / 1000, reading[1]]
  //           return acc
  //         }, null);
  //       }
  //       // console.log("inferLatestAvailableReading")
  //       if (firstReading) {
  //         this.announceInstantReadings({ ...readings, data: [firstReading] })
  //       } else {
  //         console.warn(`inferLatestAvailableReading no data`)
  //         let errorResource = {
  //           error: 'No data found',
  //           resourceId: resourceListing.resourceId
  //         }
  //         this.announceInstantReadingsError(errorResource)
  //       }
  //     } catch (error: any) {
  //       console.warn(error)
  //       let errorResource = {
  //         error: 'Unable to receive status information.',
  //         resourceId: resourceListing.resourceId
  //       }
  //       this.announceInstantReadingsError(errorResource)
  //     }
  //   }
  // }

  getLastReadingTs(resourceListing: tResourceListing) {

    this.glowService.getResourceLastTime(resourceListing.resourceId).toPromise()
      .then(readings => {

        if (readings) {
          this.announceLastTime(readings)
        } else {
          console.warn(`getLastReadingTs no data`)
          let errorResource = {
            error: 'No data found',
            resourceId: resourceListing.resourceId
          }
          this.announceLastTimeError(errorResource)
        }
      })
      .catch(error => {
        console.warn(error)
        let errorResource = {
          error: 'Unable to receive status information.',
          resourceId: resourceListing.resourceId
        }
        this.announceLastTimeError(errorResource)
      })

  }
  // async getCurrentReadingsPromise(resourceId: string): Promise<tResourceInstantReadings> {

  //   let error: any;
  //   let resourceCurrentReadings: tResourceInstantReadings;
  //   const resourceCurrentReadings$ = this.glowService.getResourceCurrentReading(resourceId)
  //   try {
  //     resourceCurrentReadings = await resourceCurrentReadings$.toPromise();
  //   } catch (e: any) {
  //     error = e
  //   }

  //   return new Promise<tResourceInstantReadings>((resolve, reject) => {

  //     if (resourceCurrentReadings && resourceCurrentReadings.resourceId) {
  //       resolve(resourceCurrentReadings)
  //     } else {
  //       console.warn(this.serviceId + " resource data returned in unexpected format")
  //       console.warn(error)
  //       error = {
  //         resourceId: resourceId,
  //         hasError: true,
  //         error: (error.error) ? error.error : 'ERROR'
  //       }
  //       resourceCurrentReadings = { ...resourceCurrentReadings, ...error }
  //       resolve(resourceCurrentReadings)

  //     }
  //   });
  // }

  async getLastMeterReadingsPromise(resourceId: string): Promise<tResourceMeterReading | undefined> {
    let error;
    let meterReading;
    try {
      meterReading = await this.glowService.getResourceMeterReading(resourceId).toPromise()
    } catch (e) {
      console.warn(error)
    }
    // console.log("getLastMeterReadingsPromise")
    // console.log(meterReading)

    return meterReading
  }

  setTariffElement({ classifier = null, resourceId = null, rate = null, structure = null, standing = null, futureRates = null, type = null, hasError = false, errorString = null }: any): tTariffPlan {
    return { classifier, resourceId, rate, standing, hasError, errorString, futureRates, type, structure }
  }


  getResourceTariffs(resourceInfo: tResourceAvailabilityElement): void {
    this.getResourceTariffsPromise(resourceInfo)

    // if (resourceInfo && resourceInfo.resourceId) {
    //   let tariffInfo: tTariffPlan = this.setTariffElement({ ...resourceInfo })
    //   this.glowService.getResourceTariff(resourceInfo.resourceId)
    //     .subscribe(resourceTariff => {
    //       if (resourceTariff.data && resourceTariff.data.length > 0) {
    //         const [ tariffElem ] = resourceTariff.data
    //         if (tariffElem.currentRates) {
    //           tariffInfo.rate = tariffElem.currentRates.rate;
    //           tariffInfo.standing = tariffElem.currentRates.standingCharge;
    //         } 
    //         tariffInfo.type = tariffElem.type;
    //         tariffInfo.futureRates = tariffElem.futureRates;
    //         // console.log(tariffInfo)

    //       } else {
    //         tariffInfo = { ...tariffInfo, hasError: true, errorString: 'TARIFF_NOT_FOUND' }
    //       }

    //       this.announceTariff(tariffInfo);

    //     }, (error: any) => {
    //       console.warn('Tariff loading error is : ' + error);
    //       tariffInfo = { ...tariffInfo, hasError: true, errorString: 'TARIFF_ERROR' }
    //       // console.log(error);
    //     });
    // }
  }

  async getResourceTariffsPromise(resourceInfo: tResourceAvailabilityElement): Promise<tTariffPlan> {

    if (!(resourceInfo && resourceInfo.resourceId)) {
      console.warn(`No resource info found`)
      throw new Error("Bad Input")
    }
    let tariffInfo: tTariffPlan = this.setTariffElement({ ...resourceInfo })

    try {

      this.glowService.getResourceTariff(resourceInfo.resourceId).subscribe(resourceTariff => {

        if (resourceTariff.data && resourceTariff.data.length > 0) {
          const [tariffElem] = resourceTariff.data
          if (tariffElem.currentRates) {
            tariffInfo.rate = tariffElem.currentRates.rate;
            tariffInfo.standing = tariffElem.currentRates.standingCharge;
          }
          tariffInfo.type = tariffElem.type;
          tariffInfo.structure = tariffElem.structure;
          tariffInfo.futureRates = tariffElem.futureRates;

        } else {
          tariffInfo = { ...tariffInfo, hasError: true, errorString: 'TARIFF_NOT_FOUND' }
        }

        this.announceTariff(tariffInfo);

      })

    } catch (e) {
      console.warn('Tariff loading error is : ' + e);
      tariffInfo = { ...tariffInfo, hasError: true, errorString: 'TARIFF_ERROR' }
      // console.log(e);
    }
    return tariffInfo

  }

  getResourceMeterRead(resource: tResource): void {
    if (resource && resource.resourceId) {
      let errorDataRetrieval
      this.glowService.getResourceMeterReading(resource.resourceId)
        .subscribe((meterRead) => {
          if (meterRead.data && meterRead.data.length > 0) {
            this.announceMeterRead(meterRead);
          } else {
            errorDataRetrieval = {
              resourceId: resource.resourceId,
              hasError: true,
              error: 'DATA_NOT_FOUND'
            }
            this.announceMeterReadError(errorDataRetrieval)
          }
        }, (errorMsg) => {

          let errorDataRetrieval = {
            resourceId: resource.resourceId,
            hasError: true,
            error: 'ERROR'
          }
          this.announceMeterReadError(errorDataRetrieval)
          console.warn('Meter Read loading error is : ');
          // console.log(errorMsg);
        });
    }
  }

  async getResourceMeterReadPromise(resource: tResource): Promise<any> {

    if (resource && resource.resourceId) {
      let errorDataRetrieval;
      let meterRead;

      const resourceMeterReading$ = this.glowService.getResourceMeterReading(resource.resourceId)
      try {
        meterRead = await resourceMeterReading$.toPromise();
        console.warn('Meter Read loading error is : ');

      } catch (e) {
        console.warn(e);
        errorDataRetrieval = {
          resourceId: resource.resourceId,
          hasError: true,
          error: 'ERROR'
        }
      }

      if (meterRead && meterRead.data && meterRead.data.length > 0) {
        return meterRead;
      } else {
        if (!errorDataRetrieval) {
          errorDataRetrieval = {
            resourceId: resource.resourceId,
            hasError: true,
            error: 'DATA_NOT_FOUND'
          }
        }

        return errorDataRetrieval;

      }
    }
  }
  /**
 * July 2018: Get Resource Readings defaults to the sum function.
 * For some reasourceTypes this is not correct. If we need any other function, this method is responsible for setting it.
 * 
 */
  public getFunctionTypeByResourceType(resourceTypeId: string) {
    let functionType = 'sum';
    // // console.log(this.resourceTypeMapping)
    if (this.resourceTypeMapping['dataStreamType']["temperature"] && this.resourceTypeMapping['dataStreamType']["temperature"].indexOf(resourceTypeId) > -1) {
      functionType = 'avg';
    } else if (this.resourceTypeMapping['dataStreamType']["illuminance"] && this.resourceTypeMapping['dataStreamType']["illuminance"].indexOf(resourceTypeId) > -1) {
      functionType = 'avg';
    }
    return functionType;
  }

  async getResourceReadings(resourceId: string, fromDate: string, toDate: string, period: string, funct: string = 'sum', getFromHttp = false, dimension = null): Promise<tResourceReadings> {
    let error: any;

    const resourceReadings$ = this.glowService.getResourceReadings(resourceId, fromDate, toDate, period, funct, getFromHttp, dimension)
    let resourceReadings = await resourceReadings$.toPromise();

    return new Promise<tResourceReadings>((resolve, reject) => {

      if (resourceReadings && resourceReadings.resourceId) {
        resolve(resourceReadings)

      } else {
        console.warn(this.serviceId + " resource data returned in unexpected format")
        console.warn(error)
        error = {
          resourceId: resourceId,
          hasError: true,
          error: (error.error) ? error.error : 'ERROR'
        }
        resourceReadings = { ...resourceReadings, ...error }
        resolve(resourceReadings)

      }

    });
  }
}





