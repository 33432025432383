import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseStudyInstallatonHeatingSystemTechnicalComponent } from './case-study-installaton-heating-system-technical.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CaseStudyRelationTableModule } from '../case-study-relation-table/case-study-relation-table.module';

@NgModule({
  declarations: [CaseStudyInstallatonHeatingSystemTechnicalComponent],
  imports: [CommonModule, SharedModule, CaseStudyRelationTableModule],
  exports: [CaseStudyInstallatonHeatingSystemTechnicalComponent],
})
export class CaseStudyInstallatonHeatingSystemTechnicalModule {}
