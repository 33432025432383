import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-general-attributes',
  templateUrl: './general-attributes.component.html',
  styleUrls: ['./general-attributes.component.scss', '../pages.component.scss'],
})
export class GeneralAttributesComponent implements OnInit {
  pageTitle = 'General attributes';
  currentPage = 'General';
  shouldShowToolLink;
  toolLink;
  subTitle
  innerWidth;
  passport;
  pages = [
    { path: '/pages/property/general-attributes/general', title: 'General', urlPath: 'general' },
    {
      path: '/pages/property/general-attributes/building-fabric',
      title: 'Heat retention score',
      urlPath: 'building-fabric',
    },
    // { path: '/pages/my-home/schematics', title: 'Heating By Room', urlPath: 'schematics' },
  ];
  @HostListener('window:resize', []) onResize() {
    this.innerWidth = window.innerWidth;
  }
  private currentPageAnnouncer = new Subject<any>();
  currentPage$ = this.currentPageAnnouncer.asObservable();

  constructor(private router: Router) {}

  ngOnInit() {
    this.onResize()
  }
  public onRouterOutletActivate(event: any) {
    // console.log(event)
    if (event.pageTitle) {
      this.currentPage = event.pageTitle;
    }
    if (event.displayTitle) {
      this.subTitle = event.displayTitle;
    }
    if (event.passport){
      // console.log(event.passport, 'passport')
      this.passport = event.passport
    }
   else {
      this.toolLink = null;
    }
  }
  isCurrentPage(page) {
    return page.title == this.currentPage;
  }

  getOtherPages() {
    return this.pages.filter((p) => p.title != this.currentPage);
  }
  navigateTool(){
    this.router.navigateByUrl(`pages/tools/estimated-thermal-performance`)
    
  }
}
