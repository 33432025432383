import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertyPassportComponent } from './property-passport.component';
import { AccountSideMenuModule } from 'src/app/components/account-side-menu/account-side-menu.module';
import { SpinnerModule } from 'src/app/components/spinner/spinner.module';
import { EpcCertificateModule } from 'src/app/components/epc-certificate/epc-certificate.module';
import { HtcModalModule } from 'src/app/components/htc-modal/htc-modal.module';
import { HeatLossParameterScaleModule } from 'src/app/components/heat-loss-parameter-scale/heat-loss-parameter-scale.module';

import { HtcCardModule } from 'src/app/components/htc-card/htc-card.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormValidationMessageModule } from 'src/app/components/form-validation-message/form-validation-message.module';

@NgModule({
  declarations: [PropertyPassportComponent],
  imports: [
    CommonModule,
    SharedModule,
    AccountSideMenuModule,
    SpinnerModule,
    EpcCertificateModule,
    HtcModalModule,
    HeatLossParameterScaleModule,
    HtcCardModule,
    FormValidationMessageModule
  ],
  exports: [PropertyPassportComponent]
})

export class PropertyPassportModule { }