import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MetaTagsService } from 'src/app/services/meta-tags.service';
import { StrapiService } from 'src/app/services/strapi.service';

@Component({
  selector: 'app-top-glossary-terms',
  templateUrl: './top-glossary-terms.component.html',
  styleUrls: ['./top-glossary-terms.component.scss'],
})
export class TopGlossaryTermsComponent implements OnInit {
  url;
  topTerms;
  title = '';
  loading: boolean = true;
  constructor(private strapiService: StrapiService, private route: ActivatedRoute, private router: Router, private metaTagsService: MetaTagsService) {
    route.params.subscribe((params) => {
      this.getUrl();
      this.getTopTerms(params.topGlossaryTerms).then((terms) => {
        this.topTerms = terms;
        this.title = 'Top 7 Terms for ' + this.topTerms.attributes.title;
        this.loading = false;
      });
    });
  }

  ngOnInit() {}
  async getTopTerms(slug) {
    const page = await this.strapiService.getContentBySlug('top-glossary-terms', slug, ['glossary_terms']);
    // console.log('page', page);
    return page;
  }
  getUrl() {
    this.url = this.route.snapshot.url.map((segment) => segment.path);
  }
}
