import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { StrapiService } from './strapi.service';

@Injectable({
  providedIn: 'root',
})
export class FaqsService {
  contentSystemBaseUrl: string = environment.contentSystemBaseUrl;
  relatedContentTypes: Array<string> = ['related-faqs'];

  constructor(private strapiService: StrapiService) {}

  async getFAQ(id: string | number) {
    const url = `${this.contentSystemBaseUrl}/api/faqs/${id}?populate[faq_topics][populate]=*`;
    const response = await this.strapiService.getDataFromStrapi(url);
    return response;
  }

  async getFAQBySlug(slug: string) {
    const url = `${this.contentSystemBaseUrl}/api/faqs?filters\[slug\][$eq]=${slug}&&populate[faq_topics][populate]=*&&populate[related_faqs][populate]=*`;
    const faq = (await this.strapiService.getDataFromStrapi(url))[0];
    return faq;
  }

  async getFAQsTopics() {
    const url = `${this.contentSystemBaseUrl}/api/faq-topics`;
    const response = await this.strapiService.getDataFromStrapi(url);
    return response;
  }

  async getFAQsTopic(id: string | number) {
    const url = `${this.contentSystemBaseUrl}/api/faq-topics/${id}?populate[faq_sub_topics][populate]=*`;
    const response = await this.strapiService.getDataFromStrapi(url);
    return response;
  }

  async getFAQsTopicBySlug(slug: string) {
    const url = `${this.contentSystemBaseUrl}/api/faq-topics?filters\[slug\][$eq]=${slug}&&populate[faq_sub_topics][populate]=*`;
    const topic = (await this.strapiService.getDataFromStrapi(url))[0];
    return topic;
  }

  async postFeedback(id, feedback, isPositive, reason?) {
    const body = {
      faq: Number(id),
      feedback: feedback,
      isPositive: isPositive,
      reason: reason ? reason : '',
    };
    await this.strapiService.postDataToStrapi(`${this.contentSystemBaseUrl}/api/faq-feedbacks`, body);
  }

  async postSuggestion(suggestion) {
    const body = {
      suggestion: suggestion,
    };
    await this.strapiService.postDataToStrapi(`${this.contentSystemBaseUrl}/api/faq-suggestions`, body);
  }
}
