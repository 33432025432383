import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})

export class BreadcrumbComponent implements OnInit {

  @Input() url!: Array<string>
  @Input() page!: String
  @Input() style!: String
  @Input() title;
  @Input() slug;

  breadcrumb: any;

  constructor(private router: Router) { }

  ngOnInit(): void {
    if (this.url) {
      this.createBreadcrumb()
    }

  }

  ngOnChanges() {
    if (this.url) {
      this.createBreadcrumb()
    }
  }

  createBreadcrumb() {
    this.capitaliseFirstLetter()
    this.breadcrumb = []
    const lastKey = (this.url.length - 1)
    this.url.map((x, i) => {
      let urlToDisplay;
      let url;
      switch (x) {
        case 'Blogs':
        case 'User-story':
        case 'Resources-lists':
        case 'Articles':
          url = '';
          break;
        default:
          urlToDisplay = x.split('-').join(' ')
          url = i > 0 && this.url[0] == 'Support' ? "/support/" + x : "/" + x;
          break;
      }
      if (i == lastKey) { url = ''; }
      // this.breadcrumb.push({ display: x.replace('-', ' '), url })
      urlToDisplay ? this.breadcrumb.push({ display: urlToDisplay, url }) : null
    });

  }

  goToPage(url) {
    const formatURL = url.toLowerCase()
    this.router.navigateByUrl(formatURL);
  }

  pageUrl(url) {
    const formatedURL = "" + url.toLowerCase()
    return formatedURL
  }

  capitaliseFirstLetter() {
    this.url = this.url.map(page => page[0].toUpperCase() + page.slice(1, page.length))
  }

  getStyle(page) {
    const lastUrlPart = this.url[this.url.length - 1].split("-").join(" ")
    return this.style ? lastUrlPart == page ? this.style : 'text-white' : lastUrlPart == page ? 'text-primary' : ''
    // return this.url[this.url.length - 1] == page ? this.style : this.style && this.url[this.url.length - 1] != page? '': 'text-primary'
  }

}