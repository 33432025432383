import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CaseStudyFormService } from 'src/app/services/case-study-form.service';
import { GlowService } from 'src/app/services/glow.service';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-other-info-form',
  templateUrl: './other-info-form.component.html',
  styleUrls: ['./other-info-form.component.scss'],
})
export class OtherInfoFormComponent implements OnInit {
  section;
  caseStudy;
  formattedCaseStudy;
  completionStatus: 'completed' | 'in-progress' = 'in-progress';
  otherInformationForm: FormGroup;
  otherInformationFormName = 'otherInformationForm';

  selectedService: any;
  subscriptionSelectedService: Subscription;
  loadObj: any;
  constructor(private caseStudyFormService: CaseStudyFormService, private glowService: GlowService, private router: Router, private serviceSelectorSvc: ServiceSelectorSvc) {
    this.loadObj = this.caseStudyFormService.initLoadObj();
    this.section = this.caseStudyFormService.getSectionFromRouter();
    this.caseStudyFormService.caseStudyAnnouncer.subscribe((data) => {
      if (data) {
        this.caseStudy = data.caseStudy;
        this.formattedCaseStudy = data.formattedCaseStudy;
      }
    });

    this.caseStudyFormService.formAnnouncer.subscribe((data) => {
      if (data && data.formName === this.otherInformationFormName) this.otherInformationForm = data.form;
    });
    this.subscriptionSelectedService = this.serviceSelectorSvc.serviceSet.subscribe((selectedService) => {
      this.selectedService = selectedService;
      this.caseStudyFormService.initServiceVariables(selectedService).then();
      this.loadObj = this.caseStudyFormService.initLoadObj();

    });
  }

  ngOnInit() {
    this.selectedService = this.serviceSelectorSvc.getSelectedService();
    if (!this.caseStudyFormService.hasInitServiceVariables) {
      this.caseStudyFormService.initServiceVariables(this.selectedService).then();
    } else {
      this.caseStudyFormService.announceLatestCaseStudy();
    }
    this.caseStudyFormService.announceForm(this.otherInformationFormName, this.caseStudyFormService[this.otherInformationFormName]);
  }

  async saveChanges(completionStatus: 'completed' | 'in-progress' = 'in-progress') {
    this.completionStatus = completionStatus;
    this.loadObj = this.caseStudyFormService.handleLoadObjLoading(this.loadObj, 'Saving changes...');
    let err, data;
    [err, data] = await Utils.promiseHandler(this.glowService.updateCaseStudy(this.caseStudy.csId, { ...this.otherInformationForm.value, completionStatus: this.completionStatus }).toPromise());
    if (err) {
      this.loadObj = this.caseStudyFormService.handleLoadObjError(this.loadObj, "Couldn't save changes. Please try again later.");
      return;
    }
    await this.caseStudyFormService.announceLatestCaseStudy();
    this.caseStudyFormService.markSectionAsPristine(this.section);
    this.loadObj = this.caseStudyFormService.handleLoadObjSuccess(this.loadObj, 'Changes saved successfully.');
  }

  async submitForm() {
    this.completionStatus = 'completed';
    this.loadObj = this.caseStudyFormService.handleLoadObjLoading(this.loadObj, 'Checking form validity...');
    const validity = this.caseStudyFormService.sections.map((section) => this.caseStudyFormService.getSectionValidity(section)).every((validity) => validity);
    if (!validity) {
      this.caseStudyFormService.markSectionAsTouched(this.section);
      this.caseStudyFormService.getAndAnnounceCaseStudySubmitSuccess();
      this.loadObj = this.caseStudyFormService.handleLoadObjError(this.loadObj, 'Please fill all the required fields.');
      return;
    }
    await this.saveChanges(this.completionStatus);
    if (this.loadObj.error) {
      this.loadObj = this.caseStudyFormService.handleLoadObjError(this.loadObj, 'An error occurred. Please try again later.');
      return;
    }
    this.loadObj = this.caseStudyFormService.handleLoadObjSuccess(this.loadObj, 'Submitted');
    this.router.navigate(['/pages/property/case-studies/' + this.caseStudy.csRef]);
  }
}
