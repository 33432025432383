import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LandingService } from 'src/app/services/landing.service';
import { MetaTagsService } from 'src/app/services/meta-tags.service';
import { SeoService } from 'src/app/services/seo.service';
import { StrapiService } from 'src/app/services/strapi.service';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-about-community',
  templateUrl: './about-community.component.html',
  styleUrls: ['./about-community.component.scss'],
})
export class AboutCommunityComponent implements OnInit {
  community: any;
  loading: boolean = true;
  constructor(
    private landingService: LandingService,
    private strapiService: StrapiService,
    private router: Router,
    private route: ActivatedRoute,
    private seoService: SeoService
  ) {
    route.params.subscribe((params) => {
      this.fetchCommunity(params.community).then((community) => {
        this.community = community;
        if (community) {
          this.seoService.configureSEO(
            Utils.removeHTMLFromString('About ' + community.attributes.name + ' Community'),
            Utils.removeHTMLFromString(community.attributes.description),
            `https://glowmarkt.com${this.router.url}`
          );
        }
        this.loading = false;
      });
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.seoService.resetSEOconfig();
  }

  async fetchCommunity(slug: string) {
    const community = await this.strapiService.getContentBySlug('communities', slug, []);

    return community;
  }
}
