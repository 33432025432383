import { Component, HostListener, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { GlowService } from '../../services/glow.service';
import { VirtualEntityService } from '../../services/virtual-entity.service';
import { tLoadingElement } from '../../services/app-config.typings';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';
import { CustomUIConfigService } from '../../services/custom-ui-config.service';
import { PicardService } from 'src/app/services/picard.service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

@Component({
  selector: 'app-temperature-sensors',
  templateUrl: './temperature-sensors.component.html',
  styleUrls: ['./temperature-sensors.component.scss', '../pages.component.scss']
})

export class TemperatureSensorsComponent implements OnInit {

  @HostListener('window:resize', []) onResize() {
    this.innerWidth = window.innerWidth;
  }

  private currentPageAnnouncer = new Subject<any>();
  currentPage$ = this.currentPageAnnouncer.asObservable()

  subscriptionGroupResourceData: Subscription;
  subscriptionSensorVirtualEntityList: Subscription;
  subscriptionSelectedService: Subscription;

  noTemperatureSensorMessage: string = "<p>You don't have temperature sensor data. Visit our <a class='text-info' href='https://shop.glowmarkt.com/products/sensors'> shop </a>to buy a temperature sensor.</p> <p>Temperature sensors are used to generate your <a class='text-info' href='/home/articles/getting-an-efficiency-score-for-your-home'> building fabric score. </a> </p>"
  currentPage: string = ""
  pageTitle: string = "Temperature sensors"
  loading: boolean = true;
  loadObj: tLoadingElement;
  groupResource: any;
  sensorVEList: any;
  pages: any = [];
  selectedService: any;
  allVESensorList: any;
  innerWidth: number;
  cookieClass: boolean = false;

  constructor(
    private glowService: GlowService,
    private uiConfigService: CustomUIConfigService,
    private veService: VirtualEntityService,
    private serviceSelectorSvc: ServiceSelectorSvc,
    private picardService: PicardService,
    private ccService: NgcCookieConsentService
  ) {

    this.loadObj = this.uiConfigService.initialiseLoadingBooleans(false);

    this.subscriptionSensorVirtualEntityList = this.veService.sensorVirtualEntities$.subscribe(async (sensorList) => {
      if (sensorList && Array.isArray(sensorList)) {
        this.allVESensorList = sensorList;
        this.selectedService = this.serviceSelectorSvc.getSelectedService()
        if (this.allVESensorList && this.selectedService) {
          this.createSensorPages()
        }
      }
    });

    this.subscriptionSelectedService = this.serviceSelectorSvc.serviceSet.subscribe(selectedService => {
      this.selectedService = selectedService
      if (this.allVESensorList && this.selectedService) {
        this.createSensorPages()
      }
    })

    this.subscriptionGroupResourceData = this.picardService.groupResourceData$.subscribe((groupResource) => {
      if (groupResource) {
        this.groupResource = groupResource;
        this.allVESensorList.map(async (resource) => {
          const match = this.groupResource.find((x) => x.resourceId == resource.resourceId);
          const device = await this.glowService.getResourceDevice(resource.resourceId).toPromise();
          resource.description = device.description;
          resource.hardwareId = device.hardwareId;
          resource.firstReadingTime = match.firstReadingTime;
          resource.downloadAvailable = match ? true : false;
        });
      }
    });

    if (this.ccService.hasAnswered() && this.ccService.hasConsented()) {
      this.cookieClass = true;
    }

  }

  ngOnInit() {
    this.veService.findVirtualEntities();
    this.onResize()
  }

  ngOnDestroy() {
    if (this.subscriptionSelectedService) {
      this.subscriptionSelectedService.unsubscribe();
    }
    if (this.subscriptionGroupResourceData) {
      this.subscriptionGroupResourceData.unsubscribe();
    }
    if (this.subscriptionSensorVirtualEntityList) {
      this.subscriptionSensorVirtualEntityList.unsubscribe();
    }
  }

  public onRouterOutletActivate(event: any) {
    if (event.pageTitle) {
      this.currentPageAnnouncer.next(event.pageTitle)
      this.currentPage = event.pageTitle;
      this.sensorVEList = event.sensorVEList
    }
  }

  createSensorPages() {
    this.sensorVEList = this.allVESensorList.filter(sensor => {
      return this.selectedService.veChildren.find(x => x.veId == sensor.veId)
    });

    this.pages = [];

    this.sensorVEList.map(sensor => {
      const path = sensor.name.split(' ').join('-');
      this.pages.push(Object.assign({}, { path: `/pages/property/temperature-sensors/${path}`, title: sensor.name }))
    })
    this.currentPage = this.pages.length > 0 ? this.pages[0].title : ''
  }

  update(title) {
    this.currentPage = title
  }

}