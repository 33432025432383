import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlowService } from 'src/app/services/glow.service';
import { InvitationsService } from 'src/app/services/invitations.service';

@Component({
  selector: 'app-all-notifications',
  templateUrl: './all-notifications.component.html',
  styleUrls: ['./all-notifications.component.scss']
})
export class AllNotificationsComponent implements OnInit {
  invitationAccepted: boolean = false
  invitationDeclined: boolean = false
  pendingRequests;
  currentPage;
  loading
  pendingRequestSubscription: Subscription
  pageTitle: string = 'Notifications'

  constructor(private invitationsService: InvitationsService, private glowService: GlowService) {
    this.loading = true
    this.pendingRequestSubscription = this.invitationsService.PendingAcceptanceEstimateAnnouncer.subscribe((er) => {
      if (er.valid) {
        this.loading = false
        this.pendingRequests = er.estimateRequests
      } else {
        this.loading = false
      }
    })
  }

  ngOnInit() {

    this.invitationsService.getAllEstimateRequestsByState('pending-professional-acceptance')
  }

  ngOnDestroy() {
    if (this.pendingRequestSubscription) {
      this.pendingRequestSubscription.unsubscribe()
    }
  }
  acceptInvitation() {
    this.invitationAccepted = true
  }
  denyInvitation() {
    this.invitationDeclined = true
  }


 
}
