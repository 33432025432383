import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvitationsComponent } from './invitations.component';
import { SentInvitationsModule } from './sent-invitations/sent-invitations.module';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [InvitationsComponent],
  imports: [
    CommonModule, SentInvitationsModule, SharedModule
  ],
  exports: [InvitationsComponent]
})
export class InvitationsModule { }
