import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tApiErrorResponse } from './glow.typings';
// import { tApiErrorResponse } from '../../services/glowservices-mobile-sdk/glow.typings';

@Injectable()
export class ErrorMessageParser {

    private ErrorAnnouncer = new Subject<tApiErrorResponse>();

    errorMsg$ = this.ErrorAnnouncer.asObservable();

    constructor() {
    };

    private apiErrorMessagesMap = {
        "An error has occurred": "ERROR",
        "incorrect elements -hardwareId": "HARDWARE_INCORRECT_DETAILS",
        "No interner Access": "ERROR_NO_NETWORK",
        "username already exists": "ERROR_USERNAME_ALREADY_EXISTS",
        "email address already verified": "ERROR_EMAIL_ALREADY_VERIFIED",
        "incorrect elements -activationCode, customerCode": "ERROR_REVIEW_ACTIVATION_CODE_CUSTOMER_CODE",
        "incorrect elements -customerCode, activationCode": "ERROR_REVIEW_ACTIVATION_CODE_CUSTOMER_CODE",
        "incorrect elements activationCode, customerCode combination": "ERROR_REVIEW_ACTIVATION_CODE_CUSTOMER_CODE_COMBINATION",
        "incorrect elements customerCode, activationCode combination": "ERROR_REVIEW_ACTIVATION_CODE_CUSTOMER_CODE_COMBINATION",
        "not implemented": "ERROR_NOT_IMPLEMENTED",
        'invalid email address' : "ERROR_INVALID_EMAIL_ADDRESS",
        "incorrect elements -invalid email": "ERROR_INVALID_EMAIL_ADDRESS",
        "registration type not implemented": "ERROR_REGISTRATION_TYPE_NOT_IMPLEMENTED",
    }

    getErrorMessage(err: any, method=null): string {

        var errorKey = "ERROR";

        if (err && err.hasOwnProperty("isError") && err.hasOwnProperty("message")) {
            errorKey = err.message

        } else {
            // Check if the error message already has been formatted
            if (err && (err.status || err.statusCode)) {
                var statusCode = err.status || err.statusCode;
                let msg = err.error && err.error.error
                if (statusCode == 400) {
                    errorKey = "ERROR_CLIENT_SIDE"
                    if (this.apiErrorMessagesMap.hasOwnProperty(msg)) {
                        errorKey = this.apiErrorMessagesMap[msg]
                    }
                } else if (statusCode == 429 && method === 'login') {
                    errorKey = "ERROR_ACCOUNT_LOCKED_TOO_MANY_FAILED_ATTEMPTS"
                } else if (statusCode == 401) {
                    errorKey = "ERROR_UNAUTHORISED"
                } else if (statusCode == 500) {
                    errorKey = "ERROR_SERVER_SIDE"
                }
            }
        }
        // console.log("ErrorMessageParser:  error key")
        // console.log(errorKey)

        return errorKey;
    }

    announceErrorMessage(error: tApiErrorResponse) {
        this.ErrorAnnouncer.next(error)
    }

}