import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalAuthorityRegisterFormComponent } from './local-authority-register-form.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormValidationMessageModule } from '../form-validation-message/form-validation-message.module';



@NgModule({
  declarations: [LocalAuthorityRegisterFormComponent],
  imports: [
    CommonModule, SharedModule, FormValidationMessageModule
  ],
  exports: [LocalAuthorityRegisterFormComponent]
})
export class LocalAuthorityRegisterFormModule { }
