import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { HeatPumpCaseStudyComponent } from '../pages/case-study-creator-tool/heat-pump-case-study/heat-pump-case-study.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';
import { CaseStudyFormService } from '../services/case-study-form.service';

@Injectable({
  providedIn: 'root',
})
export class HeatPumpCsFormGuard implements CanDeactivate<HeatPumpCaseStudyComponent> {
  constructor(private modalService: NgbModal, private caseStudyFormService: CaseStudyFormService) {}
  canDeactivate(component: HeatPumpCaseStudyComponent, next: ActivatedRouteSnapshot, state: RouterStateSnapshot, ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise<boolean>((resolve) => {
      if (this.caseStudyFormService.isSectionPristine(component.section)) {
        resolve(true);
      } else {
        const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true, size: 'lg' });
        modalRef.componentInstance.confirmationTitle = 'Unsaved changes';
        modalRef.componentInstance.confirmationText = 'You have unsaved changes. Do you want to leave without saving?';
        modalRef.componentInstance.confirmationButtonText = 'Save';
        modalRef.componentInstance.dismissButtonText = "Don't save";
        modalRef.result.then((result) => {
          if (result === 'confirm') {
            component.saveChangesToActiveChildComponent()
          }
          resolve(result === 'confirm');
        });
      }
    });
  }
}
