import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormValidationService } from 'src/app/services/form-validation.service';
import { GlowService } from 'src/app/services/glow.service';
import { ProfessionalGlowService } from 'src/app/services/professional-glow.service';
import { FormValidator } from 'src/app/services/user-validators';

@Component({
  selector: 'app-local-authority-register-form',
  templateUrl: './local-authority-register-form.component.html',
  styleUrls: ['./local-authority-register-form.component.scss']
})
export class LocalAuthorityRegisterFormComponent implements OnInit {
  registerForm: FormGroup
  loadObj;
  viewPassword: boolean = false;

  error;
  constructor(private formBuilder: FormBuilder,
    private professionalGlowService: ProfessionalGlowService,
    private formValidationService: FormValidationService, private glowService: GlowService, private router: Router) {
    this.registerForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      workEmail: ['', [Validators.required, FormValidator.isEmailValid]],
      password: ['', [Validators.required, FormValidator.isPasswordStrong]],
      confirmPassword: ['', [Validators.required, FormValidator.isPasswordSame]],
      localAuthority: ['', [Validators.required]],
      jobRole: ['', Validators.required],
      role: ['Local Authority', Validators.required],

    })
  }

  ngOnInit() {
  }
  checkFormValidity(formControlName, translateKey?) {

    return this.formValidationService.checkFormValidity(this.registerForm, formControlName, translateKey)

  }
  doRegister() {
    const role = this.registerForm.get('role').value;
    const firstName = this.registerForm.get('firstName').value;
    const lastName = this.registerForm.get('lastName').value;
    const workEmail = this.registerForm.get('workEmail').value;
    const password = this.registerForm.get('password').value;
    const jobRole = this.registerForm.get('jobRole').value;
    const businessName = this.registerForm.get('localAuthority').value;

    // console.log(this.registerForm)
    const body = { role, firstName, lastName, workEmail, password, jobRole, businessName }
    if (this.registerForm.valid) {
      // console.log(body)
      this.professionalGlowService.registerServiceAgent(body).subscribe(
        (response) => {
          // console.log('registerServiceAgent', response);
          if (response.valid) {
            this.loadObj = {
              ...this.loadObj,
              loading: false,
              loaded: true,
              loadedMessage: 'Professional account successfully created.',
            };
            this.router.navigateByUrl('/login')
          } else {
            this.loadObj = {
              ...this.loadObj,
              loading: false,
              loadError: true,
              errorString: 'Failed to create a professional account.',
            };
          }
        },
        (errResp) => {
          console.warn(errResp.error.error);
          this.loadObj = {
            ...this.loadObj,
            loading: false,
            loadError: true,
            errorString: 'Failed to create a professional account.',
          };
        }
      );
    } else
      this.error = true
    this.formValidationService.markFormGroup(this.registerForm, 'touched')

  }
  resetError() {

    this.error = false
  }
  togglePassword(viewPassword) {
    this.viewPassword = viewPassword ? false : true;
  }

}
