import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SeoService } from 'src/app/services/seo.service';
import { StrapiService } from 'src/app/services/strapi.service';
import _ from 'underscore';

@Component({
  selector: 'app-community-home',
  templateUrl: './community-home.component.html',
  styleUrls: ['./community-home.component.scss'],
})
export class CommunityHomeComponent implements OnInit {
  community;
  featuredContent: any[] = [];
  loading: boolean = true;
  _images: any[] = [];
  constructor(private strapiService: StrapiService, private route: ActivatedRoute, private seoService: SeoService) {
    route.params.subscribe((params) => {
      this.getCommunity(params.community).then((community) => {
        this.community = community;
        if (community) {
          // this.featuredContent = this.getFeaturedContent(community);
          this.configureSEO(community);
        }
        this.loading = false;
      });
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.seoService.resetSEOconfig();
  }

  configureSEO(community) {
    this.seoService.configureSEO(community.attributes.name, community.attributes.description, `https://glowmarkt.com/community/${community.attributes.slug}`);
  }

  async getCommunity(slug) {
    const community = await this.strapiService.getContentBySlug('communities', slug, ['audience_categories', 'tags']);
    // // console.log(community)
    return community;
  }

  getFeaturedContent(community) {
    const allContent = [
      ...community.attributes.featured_blogs.data.map((blog) => Object.assign(blog, { contentType: 'blogs' })),
      ...community.attributes.featured_articles.data.map((article) => Object.assign(article, { contentType: 'articles' })),
    ];
    for (let i = 0; i < allContent.length; i++) {
      this._images.push({ path: '../../../assets/images/transparent.png', width: 0, height: 0 });
    }
    return _.shuffle(allContent);
  }

  contentRelativePath(content) {
    return `/home/${content.contentType}/${content.attributes.slug}`;
  }

  communityRelativePath(community) {
    return `/about-community/${community.attributes.slug}`;
  }

  getDisplayImageUrl(content) {
    const url = this.strapiService.getSmallDisplayImageUrl(content);
    return { 'background-image': `url(${url})` };
  }
}
