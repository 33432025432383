import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ClarityModule } from '@clr/angular';
import { PipesModule } from './pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { CustomRenderDateComponent } from './ng2-smart-table/custom-date-editor.component';
import { CustomRenderTickboxIconComponent } from './ng2-smart-table/custom-tickbox-icon';
import { CustomRenderNumberOfChildrenComponent } from './ng2-smart-table/custom-number-children';
import { CustomViewPropertyButtonComponent } from './ng2-smart-table/custom-view-property-button';
import { CustomRenderNumberOfDigitsComponent } from './ng2-smart-table/custom-number-digits';
import { CustomGroupTagButtonsComponent } from './ng2-smart-table/custom-group-tag-buttons';
import { CustomDownloadButtonComponent } from './ng2-smart-table/custom-download-button';
import { CustomPropertyAttributeButtonsComponent } from './ng2-smart-table/custom-property-attribute-buttons';
import { NgSelectModule } from '@ng-select/ng-select';
import { SidebarContentCardModule } from '../components/sidebar-content-card/sidebar-content-card.module';
import { SuccessModalComponent } from '../components/success-modal/success-modal.component';
import { SuccessModalModule } from '../components/success-modal/success-modal.module';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { HtcFullInfoModule } from '../components/htc-full-info/htc-full-info.module';
import { SpinnerModule } from '../components/spinner/spinner.module';
import { CaseStudySummaryModule } from '../components/case-study/case-study-summary/case-study-summary.module';

import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from '../components/file-upload/file-upload.module';
const MODULES = [
    ClarityModule,
    CommonModule,
    FormsModule,
    PipesModule,
    TranslateModule,
    ReactiveFormsModule,
    RouterModule,
    NgSelectModule,
    FormsModule,
    SidebarContentCardModule,
    SuccessModalModule,
    HtcFullInfoModule,
    NgxSkeletonLoaderModule,
    SpinnerModule,
    NgbPopoverModule,
    FileUploadModule
];

const SMART_TABLE_COMPONENTS = [
  CustomRenderDateComponent,
  CustomRenderTickboxIconComponent,
  CustomRenderNumberOfChildrenComponent,
  CustomRenderNumberOfDigitsComponent,
  CustomViewPropertyButtonComponent,
  CustomGroupTagButtonsComponent,
  CustomPropertyAttributeButtonsComponent,
  CustomDownloadButtonComponent,
];

@NgModule({
  imports: [...MODULES],
  declarations: [...SMART_TABLE_COMPONENTS],
  exports: [...MODULES],
  providers: [FormBuilder],
})
export class SharedModule {}
