import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { JsonLdService } from './json-ld.service';
import { MetaTagsService } from './meta-tags.service';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(@Inject(DOCUMENT) private document: Document, private metaTagsService: MetaTagsService, private titleService: Title) {}

  configureSEO(title: string, summary: string, url: string,schema?:any) {
    this.metaTagsService.addMetaTags(title, summary, url);
    this.titleService.setTitle(title);
    schema?this.insertSchema(schema):null
  }

  resetSEOconfig() {
    this.metaTagsService.removeMetaTags();
    this.titleService.setTitle('Glowmarkt');
    this.removeStructuredData()
  }


	removeStructuredData(): void {
		const els = [];
		[ 'structured-data', 'structured-data-org' ].forEach(c => {
			els.push(...Array.from(this.document.head.getElementsByClassName(c)));
		});
		els.forEach(el => this.document.head.removeChild(el));
	}

	insertSchema(schema: Record<string, any>, className = 'structured-data'): void {
		let script;
		let shouldAppend = false;
		if (this.document.head.getElementsByClassName(className).length) {
			script = this.document.head.getElementsByClassName(className)[0];
		} else {
			script = this.document.createElement('script');
			shouldAppend = true;
		}
		script.setAttribute('class', className);
		script.type = JsonLdService.scriptType;
		script.text = JSON.stringify(schema);
		if (shouldAppend) {
			this.document.head.appendChild(script);
		}
	}

}
