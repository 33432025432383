import { Component, OnInit } from '@angular/core';
import { FaqsService } from 'src/app/services/faqs.service';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-faq-question-form',
  templateUrl: './faq-question-form.component.html',
  styleUrls: ['./faq-question-form.component.scss']
})
export class FaqQuestionFormComponent implements OnInit {
  suggestionForm: FormGroup
  showWarning:boolean=false
  showThankyouMessage: boolean = false
  constructor(private faqsService: FaqsService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.createSuggestionForm()
  }

  createSuggestionForm() {
    this.suggestionForm = this.fb.group({
      suggestion: ['', [
      Validators.required,
      Validators.minLength(1),
      Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)
    ]]
    })
  }

  postSuggestion(suggestion) {
    if (!this.suggestionForm.valid){
      this.showWarning=true
      return false
    } else {
    this.faqsService.postSuggestion(suggestion)
    this.displayThankyouMessage()
    }
  }
  displayThankyouMessage() {
    this.showThankyouMessage = true
    // this.showNegativeFeedbackForm = false
    // this.showPositiveFeedbackForm = false
  }
  
}
