import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LandingService } from 'src/app/services/landing.service';

@Component({
  selector: 'app-resources-list-card',
  templateUrl: './resources-list-card.component.html',
  styleUrls: ['./resources-list-card.component.scss'],
})
export class ResourcesListCardComponent implements OnInit {
  @Input() resourcesList!: any;
  @Input() blog!: any;
  @Input() shouldDisplaySummary: boolean = false;
  @Input() shouldDisplayCommunityTag: boolean = false;
  @Input() titleStyle: any = {};
  @Input() backgroundStyle: any = {}
  @Input() titleLineHeight: any = {}
  @Input() displayTimeToRead: boolean = true
  
  constructor(private router: Router, private landingService: LandingService) {}

  ngOnInit() {
  }

  navigateToResources() {
    this.router.navigateByUrl(`home/resources-lists/${this.resourcesList.attributes.slug}` );
  }

  resourcesRelativePath(){
    return `/home/resources-lists/${this.resourcesList.attributes.slug}`
  }
}
