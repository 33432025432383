import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtcFullInfoComponent } from './htc-full-info.component';
import { HtcCardModule } from '../htc-card/htc-card.module';
import { AccountNoDataCardModule } from '../account-no-data-card/account-no-data-card.module';
import { SpinnerModule } from '../spinner/spinner.module';

@NgModule({
  declarations: [HtcFullInfoComponent],
  imports: [
    CommonModule, HtcCardModule, AccountNoDataCardModule,SpinnerModule
  ],
  exports: [HtcFullInfoComponent]
})

export class HtcFullInfoModule { }