import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { stringify } from 'querystring';
import { GlowService } from 'src/app/services/glow.service';
import { caseStudyRelatedContentConfig } from '../../../../assets/content/case-study-content-config.json';

@Component({
  selector: 'app-case-study-other-content',
  templateUrl: './case-study-other-content.component.html',
  styleUrls: ['./case-study-other-content.component.scss'],
})
export class CaseStudyOtherContentComponent implements OnInit {
  questionForm: FormGroup;
  submitMessage: string
  @Input() caseStudy;
  caseStudyId: string;
  caseStudyRelatedContentConfig = caseStudyRelatedContentConfig;
  @Input() isAccountView;
  loading = false;
  constructor(private formBuilder: FormBuilder, private glow: GlowService) {
    this.questionForm = this.formBuilder.group({
      question: ['', Validators.required],
    });
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.caseStudyId = this.caseStudy.csId;
  }


  setInputs({ caseStudy, isAccountView }) {
    this.caseStudy = caseStudy;
    this.caseStudyId = caseStudy.csId;
    this.isAccountView = isAccountView
  }

  postQuestion(question) {
    this.loading = true;
    const data = {
      'Case Study Id': this.caseStudyId,
      "The customer's question and input": question,
    };

    let body = {
      description: JSON.stringify(data),
      subject: 'Glowmarkt Question for',
      type: 'contactUsGlowmarkt',
    };

    this.glow.postTicket(body).subscribe((response) => {
      // // console.log(response);
      if (response.valid) {
        this.submitMessage = 'Thank you, your question has been sent';
      } else this.submitMessage = 'There was an error sending your message';
      this.loading = false;
    });
  }
}
