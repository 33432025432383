import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, HostListener } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { LandingService } from 'src/app/services/landing.service';
import { StrapiService } from 'src/app/services/strapi.service';
import { Utils } from 'src/utils';
import * as _ from 'underscore';
import { filter } from 'rxjs/operators';

import { ContentType } from 'src/app/services/strapi.service';
import { LandingSearchbarComponent } from 'src/app/components/landing-searchbar/landing-searchbar.component';
import { MetaTagsService } from 'src/app/services/meta-tags.service';
import { SearchService } from 'src/app/services/search.service';
import { SeoService } from 'src/app/services/seo.service';
import { NavigationEnd, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { GlowService } from 'src/app/services/glow.service';
import { CaseStudyService } from 'src/app/services/case-study.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { InvitationsService } from 'src/app/services/invitations.service';
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  @ViewChild('anchor', { static: true }) anchor!: ElementRef<HTMLElement>;
  @ViewChild('searchBar', { static: true }) searchBar: LandingSearchbarComponent;

  innerWidth;
  @HostListener('window:resize', []) onResize() {
    // checks size of window to view specific menu
    this.innerWidth = window.innerWidth;
  }

  doubledContentTypes = ['resources-list', 'user-story'];
  contentTypesToBeDisplayed: Array<ContentType> = ['blogs', 'user-stories', 'resources-lists'];

  loading = true;
  blogs!: any[];
  userStories!: any[];
  page = 1;
  allContent: any[] = [];
  observer!: IntersectionObserver;
  resourcesList!: any;
  results: any = {};
  isRegisteredPreliminary;
  communityTag;
  caseStudies =[]
  liveIncidents: any[] = [];
  hasValidAccessToken;
  loginStatus;
  caseStudy;
  constructor(
    private landingService: LandingService,
    private strapiService: StrapiService,
    private searchService: SearchService,
    private host: ElementRef,
    private cookieService: CookieService,
    private seoService: SeoService,
    private router: Router,
    public oauthService: OAuthService,
    private glowService: GlowService,
    private caseStudyService: CaseStudyService,
    config: NgbCarouselConfig
  ) {
    config.showNavigationArrows = false;

    
    const navEndEvent$ = this.router.events.pipe(filter((e) => e instanceof NavigationEnd));
    navEndEvent$.subscribe((e: NavigationEnd) => {
      this.hasValidAccessToken = this.oauthService.hasValidAccessToken();
      this.loginStatus = this.hasValidAccessToken
        ? 'logged in'
        : localStorage.length > 0 && localStorage.getItem('id_token')
          ? 'logged in'
          : 'logged out';
    });
  }

  ngOnInit(): void {
    // this.glowService
    //   .getAllCaseStudies('heating_system.heatPump')
    //   .toPromise()
    //   .then(async (caseStudies) => {
    //     this.caseStudies = await Promise.all(
    //       caseStudies.map(async (caseStudy) => {
    //         const fullCaseStudy = await this.caseStudyService.fetchCaseStudyData(caseStudy);
    //         return fullCaseStudy;
    //       })
    //     );
    //   });

    this.glowService
      .getAllCaseStudies('heating_system.heat_pump')
      .toPromise()
      .then(async (caseStudies) => {
        await caseStudies.slice(1,3).forEach((caseStudy)=>{
          this.caseStudyService.fetchCaseStudyData(caseStudy).then((cs)=>{
            // console.log(cs)
          this.caseStudies.push(cs)
          })   
               // console.log(this.caseStudies)

        })
      }).catch((err) => {
        // console.log("err", err)
        this.loading = false
      })

    // this.searchService.updateSearchSettingsForSearchableContent()
    this.getLiveIncidents();
    this.getAllCommunities().then((allContent) => {
      // this.allContent = this.formatContentArray(allContent);
      this.allContent = allContent;
      this.loading = false;
    });

    this.configureSEO();
    // this.runObserver();
    this.isRegisteredPreliminary = this.getIsRegsteredCookie();
    this.onResize();

    // this.searchService.updateSearchSettingsForSearchableContent()

  }

  ngOnDestroy() {
    // this.observer.disconnect();
    this.seoService.resetSEOconfig();
  }

  configureSEO() {
    this.seoService.configureSEO('Glowmarkt', 'Homepage for Glowmarkt', 'https://glowmarkt.com/home');
  }

  get element() {
    return this.host.nativeElement;
  }

  async getLiveIncidents() {
    const incidents = await this.strapiService.getAllOfContentType('system-incidents', null,[]);
    this.liveIncidents = incidents.filter(
      (incident) =>
        new Date() >= new Date(incident.attributes.incidentDateTime) && !incident.attributes.completedDateTime
    );
  }

  getIsRegsteredCookie() {
    return this.cookieService.get('preliminaryRegister');
  }

  //checks if window has scrolled to anchor element
  runObserver() {
    const options = {
      root: null,
    };

    this.observer = new IntersectionObserver(([entry]) => {
      entry.isIntersecting && this.scrolledToAnchor();
    }, options);

    this.observer.observe(this.anchor.nativeElement);
  }

  // increment page & load more content if window is scrolled to anchor
  scrolledToAnchor() {
    this.page += 1;
    if (this.allContent) {
      this.getAllContent(this.page).then(
        (allContent) => (this.allContent = [...this.allContent, ...this.formatContentArray(allContent)])
      );
    }
  }

  //gets all the content from strapi - not formatted for display
  async getAllContent(page: number | string) {
    const tutorials = await this.strapiService.getAllOfContentType('tutorials', page);
    const blogs = await this.strapiService.getAllOfContentType('blogs', page);
    const resourcesList = await this.strapiService.getAllOfContentType('resources-lists', page);
    const articles = await this.strapiService.getAllOfContentType('articles', page);
    return [
      ...blogs.map((blog: any) => Object.assign(blog, { contentType: 'blog' })),
      ...tutorials.map((tutorial: any) => Object.assign(tutorial, { contentType: 'tutorials' })),
      ...resourcesList.map((userStory: any) => Object.assign(userStory, { contentType: 'resources-list' })),
      ...articles.map((userStory: any) => Object.assign(userStory, { contentType: 'article' })),
    ];
  }

  async getAllCommunities() {
    const communities = await this.strapiService.getAllOfContentType('communities', null, ["featured_articles", "featured_blogs", "featured_infographics"]);
    //hard coded to put heat pump first in the array
    const x = communities.shift();
    communities.push(x);
    return communities;
  }

  formatContentArray(contentArray) {
    let toFormat = [];
    //get single display contents:formattedArray has format [[{content6}],[{content5}]]
    let formattedArray = contentArray
      .filter((content) => !this.doubledContentTypes.includes(content.contentType))
      .map((content) => [content]);
    //get content to be displayed doubled
    this.doubledContentTypes.forEach((type) => {
      toFormat.push(...contentArray.filter((content) => content.contentType == type));
    });
    //shuffle so mixed content is displayed on doubled row
    toFormat = _.shuffle(toFormat);
    let formatted = [];
    //populated formatted with [[{content1},{content2}],[{content3},{content4}]]
    for (let i = 0; i < toFormat.length; i++) {
      if (i % 2 == 0) {
        toFormat[i + 1] ? (formatted = [...formatted, [toFormat[i], toFormat[i + 1]]]) : formatted.push([toFormat[i]]);
      }
    }
    //add the formatted doubled to the formattedArray => [[{content5}],[{content1},{content4}],[{content3},{content2}],[{content6}]]
    formattedArray.push(...formatted);
    return _.shuffle(formattedArray);
  }

  searchCommunity(community) {
    this.searchBar.setSearchParametersAndSearch([community.attributes.name], community.attributes.name);
    window.scrollTo(0, 0);
  }
  sanitizeCaseStudy(caseStudy, relationKey?: string) {
    let sanitizedCaseStudy: any = {};
    if (caseStudy) {
      for (let [key, val] of Object.entries(caseStudy)) {
        let value = val as any;
        if (sanitizedCaseStudy[key] || key == 'case_study' || key == relationKey) {
          continue;
        } else if (!value || typeof value !== 'object') {
          sanitizedCaseStudy[key] = value;
        } else if (key == 'attributes' && !Array.isArray(value)) {
          sanitizedCaseStudy = this.sanitizeCaseStudy({ ...sanitizedCaseStudy, ...value });
        } else if (value.data && !Array.isArray(value.data)) {
          sanitizedCaseStudy[key] = this.sanitizeCaseStudy({ ...value.data.id, ...value.data.attributes });
        } else if (value.data && Array.isArray(value.data)) {
          sanitizedCaseStudy[key] = value.data.map((item) => this.sanitizeCaseStudy(item));
        }
      }
    }
    return sanitizedCaseStudy;
  }

  navigateSignUp() {
    this.router.navigateByUrl('/register');
  }

  navigate(path) {
      this.router.navigateByUrl(path);
 
  }
}
