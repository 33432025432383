import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { ConfigService } from './app-config.service';
import { tEventsConfigurations, tConfig, tGlowEventConfiguration } from './app-config.typings';
import { GlowService } from './glow.service';
import { tGlowEventReq } from './glow.typings';

@Injectable()
export class EventService {

    eventTopicConfig: tEventsConfigurations

    private DataRefreshAnnouncer = new Subject<any>();
    private HealthCheckAnnouncer = new Subject<any>();
    private LoadingEventAnnouncer = new Subject<any>();
    private DimensionChangeEventAnnouncer = new Subject<any>();
    private TimerObservablesAnnouncer = new Subject<any>();
    private AppEventAnnouncer = new Subject<any>();


    $dataRefresh = this.DataRefreshAnnouncer.asObservable();
    $loading = this.LoadingEventAnnouncer.asObservable();
    $dimensionChange = this.DimensionChangeEventAnnouncer.asObservable();
    $timerObservables = this.TimerObservablesAnnouncer.asObservable();
    $appEvent = this.AppEventAnnouncer.asObservable();
    $healthCheck = this.HealthCheckAnnouncer.asObservable();



    constructor(
        private configService: ConfigService) {
        this.eventTopicConfig = this.configService.getEventConfigurations();
    };



    publish(topic, event) {
        // console.log(event)
        event.topic = topic;

        if (topic == this.eventTopicConfig.dataRefresh) {
            this.DataRefreshAnnouncer.next(event);

        } else if (topic == this.eventTopicConfig.loading) {
            this.LoadingEventAnnouncer.next(event);

        } else if (topic == this.eventTopicConfig.dimensionChange) {
            this.DimensionChangeEventAnnouncer.next(event);

        } else if (topic == this.eventTopicConfig.timerObservables) {
            this.TimerObservablesAnnouncer.next(event);

        } else if (topic == this.eventTopicConfig.healthCheck) {
            this.HealthCheckAnnouncer.next(event);

        } else {
            this.AppEventAnnouncer.next(event);
        }
    }



}

@Injectable()
export class GlowEventService {
    liveSystemApi: string;
    eventPreText: string = 'reflect'

    config: tConfig
    eventConfiguration: tGlowEventConfiguration

    constructor(
        private configService: ConfigService,
        private glowService: GlowService) {

        this.config = this.configService.loadConfig()
        this.eventConfiguration = this.configService.loadEventsConfigurations()
    };

    sendEvent(data: any, category, event = null) {
        let glowEvent = {
            data: data,
            categoryReference: category,
            event: null
        }
        if (this.glowService.isLoggedIn() === true) {

            let hostUrl = this.glowService.getHostUrl();
            if (hostUrl != this.config.environments.prod.glow.endpoint) {
                let env;
                try {
                    env = (hostUrl.split('//')[1]).split('.glowmarkt.com')[0]

                } catch (e) {
                    console.warn(e)
                    env = 'not_prod'
                }
                glowEvent.event = (event)?  env + '.' + this.eventPreText + '.' + event : env + '.' + this.eventPreText;

            } else {
                glowEvent.event = (event)? this.eventPreText + '.' + event: this.eventPreText;
            }

            this.glowService.sendGlowEvent(glowEvent).subscribe((response) => {
                // console.log("event submitted")
            }, (error) => {
                console.warn(error)
            })
        }
    }

    submitFormSubmissionEvent(data, event) {
        this.sendEvent(data, this.eventConfiguration.formSubmission.key, event)
    }

}