import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProfileModalModule } from 'src/app/components/profile-modal/profile-modal.module';
import { FileUploadModule } from 'src/app/components/file-upload/file-upload.module';



@NgModule({
  declarations: [ProfileComponent],
  imports: [
    CommonModule, SharedModule, ProfileModalModule, FileUploadModule 
  ],
  exports: [ProfileComponent]
})
export class ProfileModule { }
