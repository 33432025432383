import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { SharedModule } from '../../shared/shared.module';
import { AccountSideMenuModule } from 'src/app/components/account-side-menu/account-side-menu.module';
import { TemperatureSensorDataTableModule } from 'src/app/components/temperature-sensor-data-table/temperature-sensor-data-table.module';
import { SpinnerModule } from 'src/app/components/spinner/spinner.module';
import { HeatLossParameterScaleModule } from 'src/app/components/heat-loss-parameter-scale/heat-loss-parameter-scale.module';
import { EpcCertificateModule } from 'src/app/components/epc-certificate/epc-certificate.module';
import { SetupSmartMeterModule } from 'src/app/components/setup-smart-meter/setup-smart-meter.module';
import { ResponseModalModule } from 'src/app/components/response-modal/response-modal.module';
import { AccountNoDataCardModule } from 'src/app/components/account-no-data-card/account-no-data-card.module';
// const routes: Routes = [
//   {
//     path: '',
//     component: DashboardComponent,
//   },
// ];

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    SharedModule,
    AccountSideMenuModule,
    TemperatureSensorDataTableModule,
    SpinnerModule,
    HeatLossParameterScaleModule,
    EpcCertificateModule,
    ResponseModalModule,
    SetupSmartMeterModule,
    AccountNoDataCardModule
  ],
  providers: [],
  exports: [DashboardComponent]
})
export class DashboardModule { }
