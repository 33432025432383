import { EventEmitter, Injectable } from '@angular/core';
import { tMeterPointVerificationElement, tVerificationStatus } from './glow.typings';
import { max } from 'underscore';
import { GlowService } from './glow.service';

@Injectable({
  providedIn: 'root'
})
export class MeterPointVerificationConsentManagementService {
  meters: EventEmitter<any> = new EventEmitter()
  constructor(private glowService: GlowService) { }

  timeBeforeExpires: number = 15552000 // 6 months in seconds
  checkIfMeterPointConsentRefreshIsRequired(meterPointVerificationElem: tMeterPointVerificationElement): boolean {
    let requiresConsentUpdate = false

    if (meterPointVerificationElem.isValidUntil && meterPointVerificationElem.isVerified) {
      const maxExpiryDateTs = new Date().getTime() + this.timeBeforeExpires * 1000
      const maxExpiryDate = new Date(maxExpiryDateTs)// 
      try {
        const isValidUntilDate = new Date(meterPointVerificationElem.isValidUntil);
        // console.log( "isValidUntilDate maxExpiryDate", isValidUntilDate, maxExpiryDate)

        if (isValidUntilDate > new Date()) {
          if (isValidUntilDate < maxExpiryDate) {

            console.log(`${meterPointVerificationElem.mpxn} checkIfConsentRefreshIsRequired is true | isValidUntilDate ${isValidUntilDate} | maxExpiryDate ${maxExpiryDate}`)
            requiresConsentUpdate = true
          }
        } else {
          requiresConsentUpdate = null
        }

      } catch (e) {
        console.warn(e)
      }

    }
    // console.log(`${meterPointVerificationElem.mpxn} checkIfConsentRefreshIsRequired is true because meter point is not verified`)


    return requiresConsentUpdate

  }

  // returns a boolean if at least one of the meter points requires verification
  checkIfConsentRefreshIsRequired(verificationStatus: tVerificationStatus): boolean {

    let requiresConsentUpdate = false
    if (Array.isArray(verificationStatus.meterPointVerification)) {
      verificationStatus.meterPointVerification.forEach(elem => {
        requiresConsentUpdate = requiresConsentUpdate || this.checkIfMeterPointConsentRefreshIsRequired(elem)
      });
    }
    console.log("checkIfConsentRefreshIsRequired " + requiresConsentUpdate)
    return requiresConsentUpdate;
  }

  getVerificationStatus() {
    this.glowService.getUserVerificationStatus().subscribe((resp: any) => {
      console.log(resp)
      this.announceMeters(resp)   
    }, (err) => {
      this.announceMeters(err)   

    })
  }

  announceMeters(meters) {
    this.meters.emit(meters)
  }
}
