import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagesProfessionalComponent } from './pages-professional.component';
import { LandingComponent } from './landing/landing.component';
import { AccountPagesComponent } from './account-pages/account-pages.component';
import { ProfessionalSettingsComponent } from './account-pages/professional-settings/professional-settings.component';
import { PersonalDetailsComponent } from './account-pages/professional-settings/personal-details/personal-details.component';
import { SentInvitationsComponent } from './account-pages/invitations/sent-invitations/sent-invitations.component';
import { NotificationsComponent } from './account-pages/notifications/notifications.component';
import { PropertyComponent } from './property/property.component';
import { HomeEnergyDataComponent } from './property/home-energy-data/home-energy-data.component';
import { QuotesComponent } from './property/quotes/quotes.component';
import { AllNotificationsComponent } from './account-pages/notifications/all-notifications/all-notifications.component';
import { InvitationsComponent } from './account-pages/invitations/invitations.component';
import { ReceivedInvitationsComponent } from './account-pages/invitations/received-invitations/received-invitations.component';
import { ProfileComponent } from './account-pages/professional-settings/profile/profile.component';

export const routes: Routes = [
  {
    path: '',
    component: PagesProfessionalComponent,
    children: [
      { path: 'landing', component: LandingComponent },
      {
        path: 'account',
        component: AccountPagesComponent,
        children: [
          {
            path: 'settings', component: ProfessionalSettingsComponent,
            children: [
              { path: 'account-information', component: PersonalDetailsComponent },
              { path: 'profile', component: ProfileComponent },
              { path: '', redirectTo: 'account-information', pathMatch: 'full' }
            ]
          },
          {
            path: 'invitations', component: InvitationsComponent,

            children: [
              { path: 'sent-invitations', component: SentInvitationsComponent },
              { path: 'received-invitations', component: ReceivedInvitationsComponent },
              { path: '', redirectTo: 'sent-invitations', pathMatch: 'full' },
            ]
          },

          {
            path: 'notifications', component: NotificationsComponent,
            children: [
              { path: 'all-notifications', component: AllNotificationsComponent },
              { path: '', redirectTo: 'all-notifications', pathMatch: 'full' }

            ]
          }
          // { path: 'bookmarks', component: BookmarksComponent }
        ]
      },
      {
        path: 'property/:id', component: PropertyComponent,
        children: [
          { path: 'home-energy-data', component: HomeEnergyDataComponent },
          { path: 'estimate-quotes', component: QuotesComponent },
          { path: '', redirectTo: 'home-energy-data', pathMatch: 'full' }
        ]
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class PagesProfessionalRouting { }