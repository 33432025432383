import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SetupSmartMeterComponent } from '../components/setup-smart-meter/setup-smart-meter.component';
import { GeneralAttributesComponent } from './general-attributes/general-attributes.component';
import { GeneralComponent } from './general-attributes/general/general.component';
import { HeatingSystemComponent } from './heating-system/heating-system.component';
import { PagesComponent } from './pages.component';
import { PropertyPagesComponent } from './property-pages/property-pages.component';
import { SchematicsComponent } from './schematics/schematics.component';
import { EnergyDataComponent } from './energy-data/energy-data.component';
import { TemperatureSensorsComponent } from './temperature-sensors/temperature-sensors.component';
import { TemperatureSensorDataTableComponent } from '../components/temperature-sensor-data-table/temperature-sensor-data-table.component';
import { EnergyOverviewComponent } from './energy-overview/energy-overview.component';
import { AccountPagesComponent } from './account-pages/account-pages.component';
import { AccountSettingsComponent } from './settings/account-settings/account-settings.component';
import { PropertyPassportComponent } from './property-passport/property-passport.component';
import { SettingsComponent } from './settings/settings.component';
import { SubscriptionsComponent } from './settings/subscriptions/subscriptions.component';
import { ToolsPagesComponent } from './tools-pages/tools-pages.component';
import { RadiatorSchematicComponent } from '../components/radiator-schematic/radiator-schematic.component';
import { HtcComponent } from './htc/htc.component';
import { HtcFullInfoComponent } from '../components/htc-full-info/htc-full-info.component';
import { HeatPumpCostEstimationToolComponent } from './heat-pump-cost-estimation-tool/heat-pump-cost-estimation-tool.component';
import { TariffComponent } from './energy-overview/tariff/tariff.component';
import { SitesComponent } from './settings/sites/sites.component';
import { SchematicToolComponent } from '../components/schematic-tool/schematic-tool.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { SentNotificationsComponent } from './invitations/sent-invitations/sent-invitations.component';
import { AllNotificationsComponent } from './notifications/all-notifications/all-notifications.component';
import { InvitationsComponent } from './invitations/invitations.component';
import { CaseStudyAccountPageComponent } from './case-study-account-page/case-study-account-page.component';
import { CaseStudyCreatorToolComponent } from './case-study-creator-tool/case-study-creator-tool.component';
import { HeatPumpCaseStudyComponent } from './case-study-creator-tool/heat-pump-case-study/heat-pump-case-study.component';
import { FormGuard } from '../services/form.guard';
import { HeatPumpCaseStudyAccountComponent } from './case-study-account-page/heat-pump-case-study-account/heat-pump-case-study-account.component';
import { ReceivedInvitationsComponent } from './invitations/received-invitations/received-invitations.component';
import { HeatingByRoomComponent } from './heating-system/heating-by-room/heating-by-room.component';
import { HeatPumpJourneyComponent } from './heat-pump-journey/heat-pump-journey.component';

export const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: 'property',
        component: PropertyPagesComponent,
        children: [
          {
            path: 'general-attributes',
            component: GeneralAttributesComponent,
            children: [
              { path: 'general', component: GeneralComponent },
              { path: 'building-fabric', component: HtcFullInfoComponent },
              { path: '', redirectTo: 'general', pathMatch: 'full' },
            ],
          },
          { path: '', redirectTo: 'general-attributes', pathMatch: 'full' },

          {
            path: 'heating-system',
            component: HeatingSystemComponent,
            children: [
              { path: 'heating-by-room', component: HeatingByRoomComponent },
              { path: '', redirectTo: 'heating-by-room', pathMatch: 'full' },
            ],
          },
          {
            path: 'energy-data',
            component: EnergyDataComponent,
            children: [
              {
                path: 'setup-meter',
                component: SetupSmartMeterComponent,
              },
            ],
          },
          {
            path: 'energy-overview',
            component: EnergyOverviewComponent,
            children: [
              { path: 'tariff', component: TariffComponent },
              { path: '', redirectTo: 'tariff', pathMatch: 'full' },
            ],
          },
          {
            path: 'temperature-sensors',
            component: TemperatureSensorsComponent,
            children: [{ path: ':sensor', component: TemperatureSensorDataTableComponent }],
          },
          {
            path: 'case-studies',
            component: CaseStudyAccountPageComponent,
            children: [
              {
                path: ':caseStudy',
                loadChildren: () => import('../public/case-study/case-study.module').then((m) => m.CaseStudyModule),
              },
            ],
          },
          {
            path: 'activities',
            children: [
              {path: 'heat-pump-journey', component: HeatPumpJourneyComponent }
            ]
          }
        ],
      },
      {
        path: 'account',
        component: AccountPagesComponent,
        children: [
          {
            path: 'settings',
            component: SettingsComponent,
            children: [
              { path: 'account-settings', component: AccountSettingsComponent },
              { path: 'subscriptions', component: SubscriptionsComponent },
              {
                path: 'properties',
                component: SitesComponent,
                children: [
                  {
                    path: 'setup-meter',
                    component: SetupSmartMeterComponent,
                  },
                ],
              },
            ],
          },
          {
            path: 'property-passport',
            component: PropertyPassportComponent,
          },
          {
            path: 'notifications',
            component: NotificationsComponent,
            children: [
              { path: 'all-notifications', component: AllNotificationsComponent },
              { path: '', redirectTo: 'all-notifications', pathMatch: 'full' },
            ],
          },
          {
            path: 'invitations',
            component: InvitationsComponent,

            children: [
              { path: 'sent-invitations', component: SentNotificationsComponent },
              { path: 'received-invitations', component: ReceivedInvitationsComponent },
              { path: '', redirectTo: 'sent-invitations', pathMatch: 'full' },
            ],
          },
          // { path: 'bookmarks', component: BookmarksComponent }
        ],
      },
      {
        path: 'tools',
        component: ToolsPagesComponent,
        children: [
          {
            path: 'heating-by-room',
            loadChildren: () => import('./schematics/schematics.module').then((m) => m.SchematicsModule),
          },
          { path: 'heating-by-room/schematic/:id', component: SchematicToolComponent },
          {
            path: 'estimated-thermal-performance',
            component: HtcComponent,
          },
          {
            path: 'heat-pump-cost-estimation',
            component: HeatPumpCostEstimationToolComponent,
          },
          {
            path: 'case-study-creator',
            component: CaseStudyCreatorToolComponent,
          },
          {
            path: 'case-study-creator/heat-pump',
            loadChildren: () => import('./case-study-creator-tool/heat-pump-case-study/heat-pump-case-study.module').then((m) => m.HeatPumpCaseStudyModule),
          },
        ],
      },
      { path: '', redirectTo: 'property', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRouting {}
