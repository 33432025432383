import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HpJourneyModalComponent } from './hp-journey-modal.component';
import { HpJourneyStepperModule } from '../hp-journey-stepper/hp-journey-stepper.module';
import { PropertyPassportModule } from 'src/app/pages/property-passport/property-passport.module';
import { RadiatorSchematicSelectorModalModule } from '../radiator-schematic-selector-modal/radiator-schematic-selector-modal.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormValidationMessageModule } from '../form-validation-message/form-validation-message.module';
import { RadiatorSchematicCardModule } from '../radiator-schematic-card/radiator-schematic-card.module';
import { EstimateRequestCardModule } from '../estimate-request-card/estimate-request-card.module';



@NgModule({
  declarations: [HpJourneyModalComponent],
  imports: [
    CommonModule, HpJourneyStepperModule, PropertyPassportModule, SharedModule, FormValidationMessageModule, RadiatorSchematicCardModule, EstimateRequestCardModule
  ],
  exports: [HpJourneyModalComponent],
  entryComponents: [HpJourneyModalComponent]
})
export class HpJourneyModalModule { }
