import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FloorComponent } from './floor.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddRadiatorModalComponent } from '../add-radiator-modal/add-radiator-modal.component';
import { AddRadiatorModalModule } from '../add-radiator-modal/add-radiator-modal.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AddHeatingSystemModalComponent } from '../add-heating-system-modal/add-heating-system-modal.component';

@NgModule({
  declarations: [FloorComponent],
  imports: [CommonModule,NgbModule, AddRadiatorModalModule, AngularSvgIconModule],
  exports: [FloorComponent],
  entryComponents:[FloorComponent,AddRadiatorModalComponent]
})
export class FloorModule {}
