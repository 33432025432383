import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StrapiService } from 'src/app/services/strapi.service';

@Component({
  selector: 'app-unsubscribe-page',
  templateUrl: './unsubscribe-page.component.html',
  styleUrls: ['./unsubscribe-page.component.scss'],
})
export class UnsubscribePageComponent implements OnInit {
  id;
  success: boolean = false;
  loading: boolean = true;
  errorMsg = '';
  constructor(private strapiService: StrapiService, private route: ActivatedRoute) {
    this.route.params.subscribe((params) => {
      this.id = params.id;
      this.strapiService.getOneContent('system-incidents-mail-subscribers', this.id, []).then((resp) => {
        if (!resp) {
          this.success = true;
        }else{
          this.success=false
        }
      }).finally(()=>this.loading=false);
    });
  }

  ngOnInit() {}

  async findEmail(id) {
    this.strapiService.getOneContent('system-incidents-mail-subscribers', id, []).then((resp) => {
      if (!resp) {
        this.success = true;
      }
    });
  }

  async unsubscribe() {
    this.loading = true;
    this.strapiService
      .deleteSystemIncidentsMailSubscriber(this.id)
      .then((resp) => {
        this.success = true;
      })
      .catch((err) => {
        this.errorMsg = "Due to an error we couldn't unsubscribe you. Please try again later.";
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
