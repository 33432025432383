import { Component, Input, OnInit } from '@angular/core';
import { caseStudyRelatedContentConfig } from '../../../../assets/content/case-study-content-config.json';
import { caseStudyConfig } from '../../../../assets/content/case-study-config-example.json';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CaseStudyService } from 'src/app/services/case-study.service';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-heat-pump-case-study-account',
  templateUrl: './heat-pump-case-study-account.component.html',
  styleUrls: ['./heat-pump-case-study-account.component.scss'],
})
export class HeatPumpCaseStudyAccountComponent implements OnInit {
  @Input() caseStudy;
  caseStudyRelatedContentConfig = caseStudyRelatedContentConfig;
  caseStudyConfig = caseStudyConfig;
  imageOrientation;
  categories = {
    summary: {
      title: 'Summary',
      id: 'summary',
      colour: 'light-primary',
    },

    'pre-installation': {
      title: 'Building and heating pre-installation',
      id: 'pre-installation',
      caseStudySection: 'before',
      caseStudyConfigSection: 'before',
      colour: 'light-secondary',
    },

    installation: {
      title: 'Installation details',
      id: 'installation',
      caseStudySection: 'after',
      caseStudyConfigSection: 'after',
      colour: 'light-success',
    },
    technical: {
      title: 'Installed heating system technical details',
      id: 'technical',
      caseStudySection: '',
      caseStudyConfigSection: '',
      colour: 'light-info',
      restrictedView: true,
      restrictedViewPermission: ['installer'],
    },
    outcomes: {
      title: 'Costs and outcomes',
      id: 'outcomes',
      caseStudySection: 'outcome',
      caseStudyConfigSection: 'outcome',
      colour: 'light-warning',
    },
    // content: {
    //   title: 'Other content',
    //   id: 'content',
    //   caseStudySection: '',
    //   caseStudyConfigSection: '',
    //   colour: 'light-primary',
    //   restrictedView: true,
    //   restrictedViewPermission: ['all'],
    // },
    // { title: 'Leave feedback', id: 'feedback' },
  };
  currentRoute;
  category = 'summary';
  navBackground;
  categoryPage;
  constructor(private router: Router, private caseStudyService: CaseStudyService) {
    const navEndEvent$ = this.router.events.pipe(filter((e) => e instanceof NavigationEnd));
    navEndEvent$.subscribe((e: NavigationEnd) => {
      this.currentRoute = e.url;
      //last part of url
      this.category = this.currentRoute.split('/').pop();
      if (!this.categories[this.category]) {
        this.category = 'summary';
      }
      this.navBackground = this.categories[this.category].colour;
    });
    this.getCategoryPage();
  }

  ngOnInit() {
    if (this.caseStudy.propertyImage) {
      this.setImageOrientation(this.caseStudy.propertyImage);
    }
  }

  getCategoryPage() {
    const x = Object.values(this.categories).find((x) => x.id == this.category);
    this.navBackground = x.colour;
    this.categoryPage = x.title;
    return this.categoryPage.slice(0, 1).toUpperCase() + this.categoryPage.slice(1, this.categoryPage.length);
  }

  setImageOrientation(imageInfo) {
    this.imageOrientation = Utils.getImageOrientation(imageInfo);
  }

}
