import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountSideMenuComponent } from './account-side-menu.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [AccountSideMenuComponent],
  imports: [
    CommonModule,
    NgbModule,
    SharedModule
  ],
  exports: [AccountSideMenuComponent]
})
export class AccountSideMenuModule { }
