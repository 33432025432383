import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SchematicsService } from 'src/app/services/schematics.service';

@Component({
  selector: 'app-radiator-schematic-card',
  templateUrl: './radiator-schematic-card.component.html',
  styleUrls: ['./radiator-schematic-card.component.scss'],
})
export class RadiatorSchematicCardComponent implements OnInit {
  @Input() schematicDocument: any;
  @Input() isNewAction: boolean;
  completionStatus = 'In progress';
  completionStatusColor = 'warning';
  heatEmitterNumber = 0;
  constructor() {}

  ngOnInit() {
    this.completionStatus = this.getCompletionStatus();
    this.completionStatusColor = this.getCompletionStatusColor();
    if (this.schematicDocument) this.heatEmitterNumber = this.getHeatEmitterNumber();
  }

  
  getCompletionStatus() {
    if (!this.schematicDocument) return 'Not started';
    if (!this.schematicDocument.status) {
      return this.completionStatus;
    }
    return this.schematicDocument.status.replace('-', ' ').charAt(0).toUpperCase() + this.schematicDocument.status.slice(1);
  }

  getCompletionStatusColor() {
    if (!this.schematicDocument) return 'danger';
    if (!this.schematicDocument.status) {
      return this.completionStatusColor;
    }
    switch (this.schematicDocument.status) {
      case 'complete':
        return 'success';
      case 'in-progress':
        return 'warning';
      case 'not-started':
        return 'danger';
      default:
        return 'warning';
    }
  }

  getHeatEmitterNumber() {
    return this.schematicDocument.data.floors.reduce((acc, floor) => {
      return (
        acc +
        floor.rooms.reduce((acc, room) => {
          return acc + room.radiators.length;
        }, 0)
      );
    }, 0);
  }
}
