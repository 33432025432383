import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TermsconditionsModalComponent } from '../termsconditions-modal/termsconditions-modal.component';
import { OAuthService } from 'angular-oauth2-oidc';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  loading = true;
  public hasValidAccessToken = false;
  loginStatus: string;

  // year for copyright
  year = new Date().getFullYear();
  pages = []
  innerWidth;
  @HostListener('window:resize', []) onResize() {
    // checks size of window to view specific menu
    this.innerWidth = window.innerWidth;
  }
  constructor(private modalService: NgbModal, private router: Router, public oauthService: OAuthService) {

    const navEndEvent$ = this.router.events.pipe(filter((e) => e instanceof NavigationEnd));
    navEndEvent$.subscribe((e: NavigationEnd) => {
      this.hasValidAccessToken = this.oauthService.hasValidAccessToken();
      this.loginStatus = this.hasValidAccessToken ? 'logged in' : localStorage.length > 0 && localStorage.getItem('id_token') ? 'logged in' : 'logged out';
      this.setPagesOnLoginStatus()
    });
   }

  ngOnInit() { 
    this.hasValidAccessToken = this.oauthService.hasValidAccessToken();
    // console.log(this.hasValidAccessToken)
    this.onResize()

  }
  ngAfterViewInit() {
    this.loading = true;
  }

  openTermsConditions() {
    const termsconditionsRef = this.modalService.open(TermsconditionsModalComponent, {
      scrollable: true,
      centered: true,
      size: 'xl',
    });
    termsconditionsRef.componentInstance.displayForm = false
    termsconditionsRef.componentInstance.title = 'Glow'


  }

  navigateToCookiePolicy() {
    this.router.navigate(['/cookies'])

  }

setPagesOnLoginStatus(){
  this.pages = [{
    name: 'Heat Pump Resources',
    pages: [
      { title: 'Resources', path: '/community/heat-pump' },
      { title: 'Case Studies', path: '/case-studies' },
      { path: '/find-a-professional', title: 'Find an installer' },

      { title: 'Heat Pump Cost Estimation', path:  this.loginStatus === 'logged in' ?  '/pages/tools/heat-pump-cost-estimation' : '/login',  },
      { title: 'Heat Retention Score', path:  this.loginStatus === 'logged in' ? '/pages/tools/estimated-thermal-performance' : '/login' },
      { title: 'Heating by Room', path:  this.loginStatus === 'logged in' ? '/pages/tools/heating-by-room': '/login' },
    ]
  },
  {
    name: 'Energy Data',
    pages: [
      { title: 'Resources', path: '/community/energy-data' },
      { title: 'Download your data', path:  this.loginStatus === 'logged in'? '/pages/property/energy-data' : '/login'},
      // { title: 'About Energy Data', path: '' }
    ]
  },
  {
    name: 'Our Services',
    pages: [
      { title: 'Bright', path: '/bright' },
      // { title: 'Installers', path: '/installers' },
      { title: 'Visit our forum', path: 'https://forum.glowmarkt.com/' },
      { title: 'Visit our shop', path: 'https://shop.glowmarkt.com/' }
    ]
  },
  {
    name: 'Support',
    pages: [
      { title: 'Apps', path: '/support/apps' },
      { title: 'Data', path: '/support/data' },
      { title: 'Heat pump', path: '/support/heat-pump' },
      { title: 'Hardware', path: '/support/hardware' },
      { title: 'System incidents', path: '/support/system-incidents' },
      { title: 'Glossary', path: '/glossary' }
    ]
  },
  // {
  //   name: 'About',
  //   pages: [
  //     { title: 'About us', path: '' },
      
  //   ]
  // },
]
}

}
