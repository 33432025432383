import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-energy-overview',
  templateUrl: './energy-overview.component.html',
  styleUrls: ['./energy-overview.component.scss', '../pages.component.scss']
})

export class EnergyOverviewComponent implements OnInit {

  @HostListener('window:resize', []) onResize() {
    this.innerWidth = window.innerWidth;
  }
pageTitle="Energy overview"
  currentPage: string = 'Tariff';
  innerWidth: number;
  pages: any = [
    { path: '/pages/property/energy-overview/tariff', title: 'Tariff', urlPath: 'tariff' },
  ]

  constructor() { }

  ngOnInit() {
    this.onResize()
  }

  onRouterOutletActivate(event) {
    if (event.pageTitle) {
      this.currentPage = event.pageTitle;
    }
  }

  isCurrentPage(page) {
    return page.title == this.currentPage;
  }

}