import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormValidationService } from 'src/app/services/form-validation.service';
import { GlowService } from 'src/app/services/glow.service';
import { StrapiService } from 'src/app/services/strapi.service';
import { FormValidator } from 'src/app/services/user-validators';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { SystemIncidentsMailSubscriptionModalComponent } from '../system-incidents-mail-subscription-modal/system-incidents-mail-subscription-modal.component';

@Component({
  selector: 'app-system-incident-subscription-management',
  templateUrl: './system-incident-subscription-management.component.html',
  styleUrls: ['./system-incident-subscription-management.component.scss'],
})
export class SystemIncidentSubscriptionManagementComponent implements OnInit {
  detailsForm: FormGroup;
  subscriptionId: string | number;
  saved = false;
  loadObj = { loading: true, error: false, success: false, errorMessage: '' };

  modalReference: any;
  constructor(
    private formBuilder: FormBuilder,
    private strapiService: StrapiService,
    private modalService: NgbModal,
    private glowService: GlowService,
    private formValidationService: FormValidationService

  ) {
    this.initDetailsForm();
    this.getSubscriptionId();
  }

  ngOnInit() {
    
    // Initialize the form
  }

  getSubscriptionId() {
    this.glowService.getUserActions('IncidentReportingSubscription').subscribe((resp) => {
      if (resp && resp.length > 0) {
        this.subscriptionId = resp.sort(
          (a: any, b: any) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
        )[0].data.subscriptionId; // Get the latest subscription id (if there are multiple
        if (this.subscriptionId) {
          this.getSubscriberDetails(this.subscriptionId);
        }
      }
      this.loadObj.loading = false;
    });
  }

  initDetailsForm() {
    this.detailsForm = this.formBuilder.group({
      email: [
        '',
        [Validators.required,FormValidator.isEmailValid],
      ],
      noticeOfCompletion: [false],
    });
  }

  getSubscriberDetails(subscriptionId: string | number) {
    this.loadObj.loading = true;
    this.strapiService
      .getOneContent('system-incidents-mail-subscribers', subscriptionId, [])
      .then((resp) => {
        if (resp) {
          this.updateDetails(resp.attributes.email, resp.attributes.noticeOfCompletion);
        } else {
          this.subscriptionId = null;
          this.sendUserAction(this.userActionBody(null));
        }
        this.loadObj.loading = false;
      })
      .catch((err) => {
        this.loadObj.loading = false;
        this.loadObj.error = true;
        this.loadObj.errorMessage =
          "We couldn't load your details. Please try again later. If the problem persists, please contact us at support@glowmarkt.com";
      });
  }
  checkFormValidity(formControlName, translateKey?){
    return this.formValidationService.checkFormValidity(this.detailsForm,formControlName, translateKey)
   }

  updateDetails(email, noticeOfCompletion) {
    this.detailsForm.patchValue({
      email,
      noticeOfCompletion,
    });
  }

  userActionBody(id) {
    return {
      isNew: true,
      reference: 'IncidentReportingSubscription',
      data: {
        subscriptionId: id,
      },
    };
  }

  onSubmit() {
    if (this.detailsForm.invalid) {
      return;
    }
    this.strapiService
      .updateContent('system-incidents-mail-subscribers', this.subscriptionId, this.detailsForm.value)
      .then((resp) => {
        this.detailsForm.markAsPristine();
        this.saved = !this.saved;
        setTimeout(() => {
          this.saved = !this.saved;
        }, 1500);
      })
      .catch((err) => {
        this.loadObj.error = true;
        this.loadObj.errorMessage =
          "We couldn't save your changes. Please try again later. If the problem persists, please contact us at support@glowmarkt.com";
      });
  }

  unsubscribe() {
    this.loadObj.loading = true;
    this.strapiService
      .deleteSystemIncidentsMailSubscriber(this.subscriptionId)
      .then((resp) => {
        this.subscriptionId = null;
        this.sendUserAction(this.userActionBody(this.subscriptionId));
        this.loadObj.loading = false;
        this.loadObj.success = true;
      })
      .catch((err) => {
        this.loadObj.loading = false;
        this.loadObj.error = true;
        this.loadObj.errorMessage =
          "We couldn't unsubscribe you. Please try again later. If the problem persists, please contact us at support@glowmarkt.com";
      });
  }

  openConfirmationModal() {
    this.modalReference = this.modalService.open(ConfirmationModalComponent, { centered: true, size: 'lg' });
    this.modalReference.componentInstance.confirmationTitle = 'Are you sure you want to unsubscribe?';
    this.modalReference.componentInstance.confirmationText =
      'You will no longer receive updates about upcoming system incidents/completions. If you wish to continue press "Unsubscribe".';
    this.modalReference.componentInstance.confirmationButtonText = 'Unsubscribe';
    this.modalReference.result.then((result) => {
      switch (result) {
        case 'confirm':
          this.unsubscribe();
          break;
        default:
          break;
      }
    });
  }

  openSubscribeModal() {
    // Open the modal
    this.modalReference = this.modalService.open(SystemIncidentsMailSubscriptionModalComponent, {
      ariaLabelledBy: 'Subscribe modal',
      backdrop: 'static',
      size: 'md',
    });
    this.modalReference.result.then(
      (result) => {
        if (result.status == 'success') {
          this.subscriptionId = result.response.data.id;
          this.updateDetails(result.response.data.attributes.email, result.response.data.attributes.noticeOfCompletion);
        } else if (result.error && result.status == 'error') {
          this.loadObj.error = true;
          this.loadObj.errorMessage =
            "We couldn't subscribe you. Please try again later. If the problem persists, please contact us at support@glowmarkt.com";
        }
      },
      (error) => {
        this.loadObj.error = true;
        this.loadObj.errorMessage =
          "We couldn't subscribe you. Please try again later. If the problem persists, please contact us at support@glowmarkt.com";
      }
    );
  }

  sendUserAction(action) {
    this.glowService.sendUserAction(action).subscribe(
      (resp) => {
        this.loadObj.loading = false;
      },
      (error) => {
        this.loadObj.loading = false;
      }
    );
  }
}
