import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralAttributesComponent } from './general-attributes.component';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [GeneralAttributesComponent],
  imports: [SharedModule,
    CommonModule
  ],
  exports: [GeneralAttributesComponent]
})
export class GeneralAttributesModule { }
