import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlowService } from 'src/app/services/glow.service';

@Component({
  selector: 'app-delight-event-participation',
  templateUrl: './delight-event-participation.component.html',
  styleUrls: ['./delight-event-participation.component.scss']
})
export class DelightEventParticipationComponent implements OnInit {
  participation;
  constructor(private route: ActivatedRoute, private authService: GlowService
  ) {
    route.queryParams.subscribe((params) => {
      this.participation = params.participation

      const missingElements = [];
      const incorrectElements = [];
      // scope ccould also be a property, but it is not required
      const incomingRequiredProperties = ['event', 'key', 'participation']
      const replaceCharacters = [/%0D/g, /%0A/g, /%0D%0A/g, /\r/g, /\n/g]
      const incomingValues: any = Object.keys(params).reduce((acc, incomingProperty) => {
        const sanitisedPropertyName = replaceCharacters.reduce((acc, characterToReplace) => acc.replace(characterToReplace, ''), incomingProperty)
        if (incomingRequiredProperties.indexOf(sanitisedPropertyName) > -1 || incomingRequiredProperties.indexOf(incomingProperty) > -1) {
          const sanitisedValue = replaceCharacters.reduce((acc, characterToReplace) => acc.replace(characterToReplace, ''), params[incomingProperty] || params[sanitisedPropertyName])
          acc[sanitisedPropertyName] = sanitisedValue;
        }
        // // console.log(acc)
        return acc
      }, {})
      // // console.log(incomingValues, 'incoming values')
      incomingRequiredProperties.forEach((property) => {
        // // console.log(incomingValues[property])
        if (!incomingValues[property]) {
          missingElements.push(property)
          console.warn("Missing element: " + property);
        }
      });

      // // console.log(incomingValues)
      const dfsEventId = incomingValues['event']
      const dfsUserId = incomingValues['key']
      const participation = incomingValues['participation']


      const dfsEventIncomingParams = { dfsEventId, dfsUserId }
      // // console.log(dfsEventIncomingParams)
      // if (missingElements.length === 0 && incorrectElements.length === 0) {
      //   this.submitEmailVerificationInformation(emailVerificationIncomingParams)
      // }

      if (participation === true || participation == 'true') {
        this.submitDFSEventRegistration(dfsEventIncomingParams)
      } else {
        console.warn('User will not participate')
      }
    })

  }
  ngOnInit() {
  }

  submitDFSEventRegistration(eventInfo) {
    this.authService.registerDFSEventParticipation(eventInfo).subscribe((verificationResponse) => {
      // // console.log(verificationResponse)
      if (verificationResponse.valid === true) {
        // console.log("User registered to event")

      } else {
        // // console.log("Unable to register User to event", verificationResponse)

      }
    }, err => {
      // // console.log("register User to event error", err)
      console.warn(err.error)
    });


  }
}
