import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GlowService } from './glow.service';

export type ContentType =
  | 'tags'
  | 'blogs'
  | 'user-stories'
  | 'tutorials'
  | 'resources-lists'
  | 'glossary-terms'
  | 'communities'
  | 'articles'
  | 'system-incidents'
  | 'top-glossary-terms'
  | 'featured-contents'
  | 'infographics'
  | 'system-incidents-mail-subscribers'
  | 'system-incident-updates'
  | 'system-incident-affected-areas'
  | 'case-studies'
  | 'audience-categories';

type RelatedContentTypes =
  | 'blogs'
  | 'resources_lists'
  | 'tags'
  | 'authors'
  | 'articles'
  | 'glossary_terms'
  | 'resources'
  | 'featured_blogs'
  | 'featured_articles'
  | 'featured_tutorials'
  | 'featured_infographics'
  | 'featured_resource_lists'
  | 'top_glossary_term'
  | 'hero_blog'
  | 'hero_article'
  | 'hero_tutorial'
  | 'hero_infographic'
  | 'infographics'
  | 'system_incident_updates'
  | 'system_incident_affected_areas'
  | 'audience_categories';

@Injectable({
  providedIn: 'root',
})
export class StrapiService {
  private BookmarkedContentAnnouncer = new Subject<any>();
  bookmarkedContent$ = this.BookmarkedContentAnnouncer.asObservable();

  bookmarkedContent = [];
  contentSystemBaseUrl: string = environment.contentSystemBaseUrl;

  authMap = {
    GET: environment.contentSystemReadBearer,
    POST: environment.contentSystemPostBearer,
    DELETE: environment.contentSystemDeleteBearer,
  };

  populatedPublishedFilter = '';

  constructor(private glowService: GlowService) {
    this.populatedPublishedFilter = this.populatePublishedFilter();
  }

  getAuthHeader(htmlMethod: 'GET' | 'POST' | 'PUT' | 'DELETE') {
    const authorization = this.authMap[htmlMethod];
    return {
      'Content-Type': 'application/json',
      authorization,
    };
  }

  setBookmarkedContent(bookmarkedContent: any[]) {
    this.bookmarkedContent = bookmarkedContent;
    this.announceBookmarkedContent();
  }

  getBookmarkedContent() {
    this.glowService.getProfile('bookmarks').subscribe(
      (bookmarks) => {
        this.setBookmarkedContent(bookmarks.data.data);
        this.announceBookmarkedContent();
      },
      (error) => {}
    );
  }
  announceBookmarkedContent() {
    this.BookmarkedContentAnnouncer.next(this.bookmarkedContent);
  }

  // Bookmark a content item
  bookmarkContent(slug: string, contentType: string) {
    // Create a content object with the slug and content type
    const content = { slug, contentType };
    // Add the content to the bookmarked content array
    this.setBookmarkedContent([...this.bookmarkedContent, content]);
    // Create a body for the request to add the profile
    const body = { profileName: 'bookmarks', data: [...this.bookmarkedContent] };
    // Send the request to add the profile
    this.glowService.addProfile(body).subscribe(
      (resp) => resp, // console.log(resp),
      (error) => error // console.log(error)
    );
  }
  unbookmarkContent(slug: string) {
    // remove the content from the bookmarkedContent array
    const updatedArray = this.bookmarkedContent.filter((content) => content.slug !== slug);
    this.setBookmarkedContent(updatedArray);

    // update the bookmarks of the user
    const body = { profileName: 'bookmarks', data: [...this.bookmarkedContent] };
    this.glowService.addProfile(body).subscribe((resp) => resp);
  }

  isContentBookmarked(slug: string) {
    return this.bookmarkedContent.some((content) => content.slug === slug);
  }

  /**
   * Returns a filter string to be used with the populate query parameter.
   * The filter string will include a filter for each related content type
   * that will only include related content that has a publishedAt date.
   * @return {string} The filter string.
   */
  populatePublishedFilter(relatedContentTypes: RelatedContentTypes[] = []) {
    let filter = [];
    relatedContentTypes.forEach((type) => filter.push(`populate[${type}][filters][publishedAt][$notNull]=true&populate[${type}][populate]=*`));
    let result = filter.join('&');
    return result;
  }

  /**
   * Fetches all of the content of a given type from the Strapi content system.
   *
   * @param contentType The content type to fetch.
   * @param page The page number to fetch.
   * @returns An array of content.
   */
  async getAllOfContentType(contentType: ContentType, page?: string | number, relatedContentTypes: RelatedContentTypes[] = []) {
    const populatedPublishedFilter = this.populatePublishedFilter(relatedContentTypes);
    const url = page ? `${this.contentSystemBaseUrl}/api/${contentType}?pagination[page]=${page}&&${populatedPublishedFilter}` : `${this.contentSystemBaseUrl}/api/${contentType}?${populatedPublishedFilter}`;
    const content = await this.getDataFromStrapi(url, page && true);
    return content;
  }

  getFileFullUrl(fileUrl) {
    return `${this.contentSystemBaseUrl}${fileUrl}`;
  }

  /**
   * Get one content item from Strapi content system.
   * @param contentType - The content type.
   * @param id - The content item ID.
   * @returns - The content item.
   */
  async getOneContent(contentType: ContentType, id: string | number, relatedContentTypes: RelatedContentTypes[] = []) {
    const populatedPublishedFilter = this.populatePublishedFilter(relatedContentTypes);
    const url = `${this.contentSystemBaseUrl}/api/${contentType}/${id}?${populatedPublishedFilter}&&populate=displayImage`;
    const content = await this.getDataFromStrapi(url);
    return content;
  }

  /**
   * Update a content item of a given type
   * @param {ContentType} contentType - Content type name
   * @param {string | number} id - Content item ID
   * @param {any} data - Content item data
   * @returns {Promise<any>}
   */
  async updateContent(contentType: ContentType, id: string | number, data: any) {
    const url = `${this.contentSystemBaseUrl}/api/${contentType}/${id}`;
    const content = await this.putDataToStrapi(url, data);
    return content;
  }

  /**
   * This function is used to get the content from strapi based on the content type and the slug
   * @param contentType is the type of content that you want to get
   * @param slug is the slug of the content that you want to get
   * @returns the content from strapi
   */
  async getContentBySlug(contentType: ContentType, slug: string, relatedContentTypes: RelatedContentTypes[] = []) {
    const populatedPublishedFilter = this.populatePublishedFilter(relatedContentTypes);
    const url = `${this.contentSystemBaseUrl}/api/${contentType}?filters\[slug\][$eq]=${slug}&&${populatedPublishedFilter}&&populate=displayImage`;
    const [content] = await this.getDataFromStrapi(url);
    return content;
  }

  async getCaseStudyBySlug(slug: string) {
    const url = `${this.contentSystemBaseUrl}/api/case-studies?filters\[slug\][$eq]=${slug}&&populate=deep`;
    const [content] = await this.getDataFromStrapi(url);
    return content;
  }

  async getAllCaseStudies() {
    const url = `${this.contentSystemBaseUrl}/api/case-studies?populate=deep`;
    const content = await this.getDataFromStrapi(url);
    return content;
  }

  async getGlossaryTermsAlphabetically() {
    let terms = [];
    let page = 1;
    let shouldFetchNextPage = true;
    let url = `${this.contentSystemBaseUrl}/api/glossary-terms?pagination[page]=${page}&&sort=term:asc&&populate=*`;

    while (shouldFetchNextPage) {
      const nextPageTerms = await this.getDataFromStrapi(url, true);
      if (nextPageTerms.length) {
        terms = [...terms, ...nextPageTerms];
        page += 1;
        url = `${this.contentSystemBaseUrl}/api/glossary-terms?pagination[page]=${page}&&sort=term:asc&&populate=*`;
      } else {
        shouldFetchNextPage = false;
      }
    }
    return terms;
  }

  async getGlowmarktAbout() {
    const url = `${this.contentSystemBaseUrl}/api/glowmarkt`;
    const content = await this.getDataFromStrapi(url);
    return content;
  }
  async getPrivacyPolicy() {
    const url = `${this.contentSystemBaseUrl}/api/privacy-policy`;
    const content = await this.getDataFromStrapi(url);
    return content;
  }
  async addUpdatesInstallerSubscriber(data) {
    const url = `${this.contentSystemBaseUrl}/api/updates-installer-subscribers`;
    const response = await this.postDataToStrapi(url, data);
    return response;
  }

  async addSystemIncidentsMailSubscriber(data) {
    const url = `${this.contentSystemBaseUrl}/api/system-incidents-mail-subscribers`;
    const response = await this.postDataToStrapi(url, data);
    return response;
  }

  async postCaseStudyFeedback(data) {
    const url = `${this.contentSystemBaseUrl}/api/case-study-feedbacks`;
    const response = await this.postDataToStrapi(url, data);
    // console.log(response, 'response');
    return response;
  }
  async deleteSystemIncidentsMailSubscriber(id) {
    const url = `${this.contentSystemBaseUrl}/api/system-incidents-mail-subscribers/${id}`;
    const response = await fetch(url, {
      method: 'DELETE',
      headers: this.getAuthHeader('DELETE'),
    });
    if (response.status !== 200) {
      return throwError(response).toPromise();
    } else {
      const data = await response.json();
      const content = data.data;
      return content;
    }
  }

  async getDataFromStrapi(url: string, paginated = false) {
    const response = await fetch(url, {
      method: 'GET',
      headers: this.getAuthHeader('GET'),
    });
    const data = await response.json();
    let content = data.data;
    console.log(data)
    if (!paginated && data.meta.pagination && data.meta.pagination.pageCount > 1) {
      for (let i = 2; i <= data.meta.pagination.pageCount; i++) {
        const nextPageUrl = `${url}&pagination[page]=${i}`;
        const nextPageData = await this.getDataFromStrapi(nextPageUrl, true);
        content = [...content, ...nextPageData];
      }
    }
    return content;
  }

  async postDataToStrapi(url: string, bodyData) {
    const raw = await fetch(url, {
      method: 'POST',
      headers: this.getAuthHeader('POST'),
      body: JSON.stringify({ data: bodyData }),
    });
    const response = await raw.json();
    // // console.log(response)
    return response;
  }

  async putDataToStrapi(url: string, bodyData) {
    const raw = await fetch(url, {
      method: 'PUT',
      headers: this.getAuthHeader('PUT'),
      body: JSON.stringify({ data: bodyData }),
    });
    const response = await raw.json();
    // // console.log(response)
    return response;
  }

  async deleteDataFromStrapi(url: string) {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: this.getAuthHeader('DELETE'),
    });
    if (response.status !== 200) {
      return throwError(response).toPromise();
    } else {
      const data = await response.json();
      const content = data.data;
      return content;
    }
  }

  getSmallDisplayImageUrl(content) {
    if (content && content.attributes && content.attributes.displayImage && content.attributes.displayImage.data) {
      if (content.attributes.displayImage.data.attributes.formats.small) {
        return this.getFileFullUrl(content.attributes.displayImage.data.attributes.formats.small.url);
      }
      return this.getFileFullUrl(content.attributes.displayImage.data.attributes.url);
    }
    return '../../../assets/images/soft-orange.jpg';
  }

  getThumbnailDisplayImageUrl(content) {
    if (content.attributes.displayImage.data) {
      if (content.attributes.displayImage.data.attributes.formats.thumbnail) {
        return this.getFileFullUrl(content.attributes.displayImage.data.attributes.formats.thumbnail.url);
      }
      return this.getFileFullUrl(content.attributes.displayImage.data.attributes.url);
    }
    return '../../../assets/images/soft-orange.jpg';
  }
}
