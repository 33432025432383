import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { tApiErrorResponse } from './glow.typings';
import { ConfigService } from './app-config.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ProfessionalGlowService {
  private hostUrl
  private devUrl 
  private applicationId: string;
  private authFunctionalGroupId: string;
  constructor(private http: HttpClient, private configService: ConfigService, private auth: AuthenticationService) {
    const configuration = this.configService.loadConfig();
    this.hostUrl = configuration.environments.prod.glow.endpoint;
    // this.devUrl = 'https://api.glowtest.com/api/v0-1/'
    this.applicationId = configuration.application.applicationId;
    if (configuration.hasOwnProperty('functionalGroups') && configuration.functionalGroups.hasOwnProperty('auth')) {
      this.authFunctionalGroupId = configuration.functionalGroups.auth;
    }
  }
  //******************** Headers *************/
  public getNoAuthHeaders(): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      applicationId: this.applicationId,
    });
    if (this.authFunctionalGroupId) {
      headers = headers.append('functionalGroupId', this.authFunctionalGroupId);
    }
    return headers;
  }
  public getOAuthHeaders() {
    let headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      applicationId: this.applicationId,
      Authorization: this.auth.authorizationHeader(),
    });
    return headers;
  }
  public getOAuthHeadersWithoutContentType() {
    let headers: HttpHeaders = new HttpHeaders({
      applicationId: this.applicationId,
      Authorization: this.auth.authorizationHeader(),
    })
    return headers
  }
  public getNoAuthOptions() {
    const headers = this.getNoAuthHeaders();
    return { headers };
  }
  public getOAuthOptions() {
    const headers = this.getOAuthHeaders();
    return { headers };
  }
  public getOAuthOptionsWithoutContentType() {
    const headers = this.getOAuthHeadersWithoutContentType()
    return { headers }
  }
  performNetworkCheck(): tApiErrorResponse {
    let response = {
      isError: false,
      messages: [],
      data: '',
    };
    // if (this.networkActive === false) {
    //   response.isError = true
    //   response.messages = ['ERROR_NO_NETWORK']
    // }
    return response;
  }

  performPreAPIChecksWithoutAuth(): tApiErrorResponse {
    return this.performNetworkCheck();
  }



  public registerServiceAgent(body): Observable<any> {
    // console.log('registerServiceAgent');
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return this.http.post<any>(this.hostUrl + 'service-agent/register', body, this.getNoAuthOptions());
    } else {
      return throwError(response);
    }
  }
  getServiceAgent(): Observable<any> {
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return this.http.get<any>(`${this.hostUrl}service-agent`, this.getOAuthOptions());
    } else {
      return throwError(response);
    }
  }
  getSnapshotByEstimateId(estimateId, includeHeatingSystem) {
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      console.log(response);
      return this.http.get(this.hostUrl + 'service-agent/estimate-request/' + estimateId + '/snapshot?includeHeatingSystem=' + includeHeatingSystem, this.getOAuthOptions());
    } else {
      return throwError(response);
    }
  }

  findAllEstimateRequestsByState(state) {
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return this.http.get<any>(this.hostUrl + 'service-agent/estimate-requests?state=' + state, this.getOAuthOptions());
    } else {
      return throwError(response);
    }
  }
  updateEstimateRequestProfessionalState(body, estimateRequestId) {
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return this.http.post<any>(this.hostUrl + 'service-agent/estimate-request/' + estimateRequestId + '/state', JSON.stringify(body), this.getOAuthOptions());
    } else {
      return throwError(response);
    }
  }
  createEstimateRequestForCustomer(body) {
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return this.http.post<any>(this.hostUrl + 'service-agent/estimate-request', JSON.stringify(body), this.getOAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getAllEstimateRequestForProfessionals() {
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return this.http.get(this.hostUrl + 'service-agent/estimate-requests', this.getOAuthOptions());
    } else {
      return throwError(response);
    }
  }
  updateExternalReferenceEstimateRequest(estimateRequestId, body) {
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return this.http.post(this.hostUrl + 'service-agent/estimate-request/' + estimateRequestId + '/update', JSON.stringify(body), this.getOAuthOptions());
    } else {
      return throwError(response);
    }
  }
  updateServiceAgentDetails(body) {
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return this.http.post(this.hostUrl + 'service-agent/update', JSON.stringify(body), this.getOAuthOptions());
    } else {
      return throwError(response);
    }
  }
  updateServiceAgentPhone(body) {
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return this.http.post(this.hostUrl + 'service-agent/phone/update', JSON.stringify(body), this.getOAuthOptions());
    } else {
      return throwError(response);
    }
  }
  updateServiceAgentEmail(body) {
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return this.http.post(this.hostUrl + 'service-agent/email/update', JSON.stringify(body), this.getOAuthOptions());
    } else {
      return throwError(response);
    }

  }
  updateServiceAgentPassword(body) {
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return this.http.post(this.hostUrl + 'service-agent/password/update', JSON.stringify(body), this.getOAuthOptions());
    } else {
      return throwError(response);
    }

  }

  updateServiceAgentPhoto(body, imageType) {
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return this.http.post(this.hostUrl + 'service-agent/upload/' + imageType, body, this.getOAuthOptionsWithoutContentType());
    } else {
      return throwError(response);
    }

  }

  getServiceAgentFile(docId): Promise<any> {
    const headers = {
      headers: {
        applicationId: this.applicationId,
        Authorization: this.auth.authorizationHeader()
      }
    }
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return fetch(this.hostUrl + 'service-agent/document/' + docId, headers).then((res) => res.blob())
        .then((blob) => {
          return blob;
        });
    }
  }

  uploadQuoteOrEstimate(body, estimateRequestId) {
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return this.http.post(this.hostUrl + 'service-agent/estimate-request/' + estimateRequestId + '/estimate', body, this.getOAuthOptionsWithoutContentType());
    } else {
      return throwError(response);
    }
  }
  getEstimateProfessional(estimateId) {
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return this.http.get(this.hostUrl + 'service-agent/estimate/' + estimateId, this.getOAuthOptions());
    } else {
      return throwError(response);
    }
  }
  getEstimateRequestByIdProfessional(estimateId) {
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return this.http.get(this.hostUrl + 'service-agent/estimate-request/' + estimateId, this.getOAuthOptions());
    } else {
      return throwError(response);
    }
  }
  updateEstimateRequestByProfessional(estimateRequestId, body) {
    const response = this.performPreAPIChecksWithoutAuth();
    if (response && response.isError === false) {
      return this.http.post<any>(this.hostUrl + 'service-agent/estimate-request/' + estimateRequestId + '/update', JSON.stringify(body), this.getOAuthOptions());
    } else {
      return throwError(response);
    }
  }

  getListOfInstallers(body){
  const response = this.performPreAPIChecksWithoutAuth();
  if (response && response.isError === false) {
    return this.http.post<any>(this.hostUrl + 'service-agent/professional/geolookup', JSON.stringify(body), this.getOAuthOptions());
  } else {
    return throwError(response);
  }
}
}
