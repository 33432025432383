import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
declare const MeiliSearch: any;

export type Page = 'landing' | 'community' | 'faq';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  contentSearchBaseUrl: string = environment.contentSearchBaseUrl;

  searchableContentLanding: Array<string> = [
    'blog',
    'user-story',
    'resources-list',
    'article',
    'infographic',
  ];
  searchableContentCommunity: Array<string> = ['blog', 'user-story', 'resources-list', 'article', 'infographic'];

  client = new MeiliSearch({
    host: this.contentSearchBaseUrl,
    apiKey: environment.contentSearchApiKey,
  });

  prodClient = new MeiliSearch({
    host: 'https://meilisearch.glowmarkt.com/',
    apiKey: 'e8cfeefcc7bb1eb44fd7a9aaa8f081694a44260b9e2e6e08c7c92fff77d9be89',
  });
  constructor() {}

  async searchAllFAQs(searchTerm: string) {
    const index = this.client.index('faq', {});
    const search = await index.search(searchTerm, { limit: 10 });
    return search;
  }

  async searchAllContent(searchTerm: string) {
    let result = [];
    for (let content of this.searchableContentLanding) {
      result = [...result, ...(await this.searchContent(content, searchTerm))];
    }
    return result;
  }

  async searchContent(content: string, searchTerm: string) {
    const index = this.client.index(content);
    const search = (await index.search(searchTerm)).hits;
    return search;
  }

  async filterAllCommunityContentWithTags(communitySlug: string, searchTerm: string, tagsTitles: string[]) {
    let result = [];
    for (let content of this.searchableContentCommunity) {
      result = [
        ...result,
        ...(await this.filterCommunityContentWithTags(communitySlug, content, searchTerm, tagsTitles)),
      ];
    }
    return result;
  }

  async filterAllContentWithTags(page: Page, searchTerm: string, tagsTitles: string[]) {
    const pageSearchableContentMap = {
      landing: this.searchableContentLanding,
      community: this.searchableContentCommunity,
    };
    let result = [];
    for (let content of pageSearchableContentMap[page]) {
      result = [...result, ...(await this.filterContentWithTags(content, searchTerm, tagsTitles))];
    }
    return result;
  }

  async filterContentWithTags(content: string, searchTerm: string, tagsTitles: string[]) {
    const index = this.client.index(content);
    const filter = [];
    tagsTitles.forEach((tagTitle) => {
      filter.push(`tags.title="${tagTitle}"`);
    });
    const search = await index.search(searchTerm, {
      // filter: [filter], //OR
      filter: filter, //AND
      matchingStrategy: 'all',
      limit: 100,
    });
    return search.hits;
  }

  async filterCommunityContentWithTags(
    communitySlug: string,
    content: string,
    searchTerm: string,
    tagsTitles: string[]
  ) {
    const index = this.client.index(content);
    const filter = [`community.slug="${communitySlug}"`];
    tagsTitles.forEach((tagTitle) => {
      filter.push(`tags.title="${tagTitle}"`);
    });
    const search = await index.search(searchTerm, {
      // filter: [filter], //OR
      filter: filter, //AND
      matchingStrategy: 'all',
      limit: 100,
    });
    return search.hits;
  }

  //update search settings for index - to be run once
  async setCommunityAsFilterableAttribute(content, client) {
    const index = client.index(content);
    await index.updateFilterableAttributes(['community.slug']);
  }

  async setTagsAsFilterableAttribute(content: string, client) {
    const index = client.index(content);
    await index.updateFilterableAttributes(['tags.title']);
  }
  async updateDistinctAttributes(content: string, client) {
    const index = client.index(content);
    await index.updateDistinctAttribute('id');
  }
  async updateSearchableAttributes(content: string, searchableAttributes: string[], client) {
    const index = client.index(content);
    await index.updateSearchableAttributes(searchableAttributes);
  }

  async updateSearchSettingsForCommunityContent(client) {
    this.searchableContentCommunity.forEach((c) => {
      this.setCommunityAsFilterableAttribute(c, client);
    });
  }

  async updateDistinctAttributesForSearchableContent(client) {
    this.searchableContentLanding.forEach((c) => {
      this.updateDistinctAttributes(c, client);
    });
  }

  async updateSearchableAttributesForSearchableContent(client) {
    this.searchableContentLanding.forEach((c) => {
      this.updateSearchableAttributes(c, ['title', 'summary'], client);
    });
  }

  async setTagsAsFilteringForSearchableContent(client) {
    this.searchableContentLanding.forEach((c) => {
      this.setTagsAsFilterableAttribute(c, client);
    });
  }

  async updateSearchSettingsForSearchableContent() {
    const client = this.prodClient;
    // const client = this.client;
    await this.setTagsAsFilteringForSearchableContent(client);
    await this.updateSearchableAttributesForSearchableContent(client);
    await this.updateDistinctAttributesForSearchableContent(client);
    await this.updateSearchSettingsForCommunityContent(client);
    // console.log('done');
  }
}
