import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LandingService } from 'src/app/services/landing.service';
import { StrapiService } from 'src/app/services/strapi.service';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-user-story-detailed-content',
  templateUrl: './user-story-detailed-content.component.html',
  styleUrls: ['./user-story-detailed-content.component.scss'],
})
export class UserStoryDetailedContentComponent implements OnInit {
  userStory!: any;

  constructor(private route: ActivatedRoute, private landingService: LandingService, private strapiService:StrapiService) {
    this.getUserStory().then((userStory) => {
      this.userStory = userStory;
    });
  }

  ngOnInit(): void {}

  getUpdatedAt(){
    return new Date(this.userStory.attributes.updatedAt).toDateString().split(' ').slice(1).join(' ');
  }

  async getUserStory() {

  }
  
  getImageURL(userStory: any) {
    return Utils.getApiServerAddress() + userStory.attributes.headingImage.data.attributes.url;
  }
}
