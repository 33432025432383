import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { tLoadingElement } from 'src/app/services/app-config.typings';
import { HeatingSystemService } from 'src/app/services/heating-system.service';
import { CustomUIConfigService } from 'src/app/services/custom-ui-config.service';
import { GlowService } from 'src/app/services/glow.service';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';
import { PostcodeCheckerService } from 'src/app/services/postcode-checker.service';
import { FormValidationService } from 'src/app/services/form-validation.service';

@Component({
  selector: 'app-epc-info-modal',
  templateUrl: './epc-info-modal.component.html',
  styleUrls: ['./epc-info-modal.component.scss'],
})

export class EpcInfoModalComponent implements OnInit {

  @Input() veId: string;
  @Input() propertyPassportId: string;
  @Input() resources: any;
  @Input() postcode: string;
  @Input() propertyPassport: any;
  @Output() updateHtcCard: EventEmitter<any> = new EventEmitter();

  houseAttachments = [
    { displayName: 'DETACHED', value: "Detached" },
    { displayName: 'SEMIDETACHED', value: "SemiDetached" },
    { displayName: 'SEMIDETACHEDSOLIDWALL', value: "SemiDetachedSolidWall" },
    { displayName: 'ENDTERRACE', value: "EndTerrace" },
    { displayName: 'ENDTERRACESOLIDWALL', value: "EndTerraceSolidWall" },
    { displayName: 'MIDTERRACE', value: "MidTerrace" },
    { displayName: 'MIDTERRACESOLIDWALL', value: "MidTerraceSolidWall" },
    { displayName: 'GROUNDTOPFLOORMIDFLAT', value: "GroundTopFloorMidFlat" },
    { displayName: 'GROUNDTOPFLOORENDFLAT', value: "GroundTopFloorEndFlat" },
    { displayName: 'MIDFLOORMIDFLAT', value: "MidFloorMidFlat" }
  ];
  heating = ['Electric', 'Gas', 'Other'];
  renewableOptions = ['Yes', 'No'];
  wallTypes = ['Cavity', 'Solid', 'Other'];
  formInfo: FormGroup;
  formErrorMessage: boolean = false;
  loadObj: tLoadingElement;
  temperatureResources: any = [];
  currentHtc: any;
  showSuccess: boolean = false;
  maxDate: string = new Date(Date.now() - 86400000).toISOString().split('T')[0];
  manualHTC: any;
  cookieClass: boolean = false;
  selectedService: any;
  subscriptionSelectedService: Subscription;
  boilerManufacturers: any[];
  boilerModels: any[];
  boilerModelOptions: any[];
  selectedManufacturer: string;
  postcodeObj: any = { area: '', isValid: null, error: false };

  constructor(
    public activeModal: NgbActiveModal,
    private uiConfigService: CustomUIConfigService,
    private glowService: GlowService,
    private postcodeCheckerService: PostcodeCheckerService,
    private ccService: NgcCookieConsentService,
    private serviceSelectorSvc: ServiceSelectorSvc,
    private formBuilder: FormBuilder,
    private heatingSystemService: HeatingSystemService,
    private formValidationService: FormValidationService
  ) {
    this.formInfo = this.formBuilder.group({
      houseAttachment: ['', Validators.required],
      postcode: ['', Validators.required],
      floorArea: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      toDate: ['', Validators.required],
      wallType: ['', Validators.required],
      renewables: [''],
      heating: ['', Validators.required],
      boilerManufacturer: [{ value: {}, disabled: true }],
      boilerModel: [{ value: {}, disabled: true }],
    });

    if (this.ccService.hasAnswered() && this.ccService.hasConsented()) {
      this.cookieClass = true;
    }

    this.subscriptionSelectedService = this.serviceSelectorSvc.serviceSet.subscribe((selectedService) => {
      this.selectedService = selectedService;
    });
  }

  ngOnInit() {

    this.selectedService = this.serviceSelectorSvc.getSelectedService();
    this.loadObj = this.uiConfigService.initialiseLoadingBooleans(false);

    this.postcode
      ? this.formInfo.controls['postcode'].setValue(this.postcode)
      : this.formInfo.controls['postcode'].setValue('');
    const elecMatch = this.resources.find((x) => x.classifier == 'electricity.consumption');
    if (Array.isArray(this.resources) && this.resources.length > 0 && elecMatch) {
      this.getDevices();
    } else {
      let errorString = '';
      if (!elecMatch) {
        errorString += "<li>We can't find a smart electricity meter assigned to your account; we need it to calculate your building fabric score. For more information please email <a class='text-info' href='mailto:support@glowmarkt.com'>support@glowmarkt.com</a></li>";
      }
      this.loadObj = { ...this.loadObj, loadError: true, loading: false, errorString };
    }
  }

  async onHeatingChange(event) {
    if (event && event.target && event.target.value) {
      this.formInfo.controls['boilerManufacturer'].setValue('');
      this.formInfo.controls['boilerModel'].setValue('');
      const value = event.target.value;
      if (value == 'Electric') {
        this.formInfo.controls['boilerManufacturer'].disable();
        this.formInfo.controls['boilerModel'].disable();
        return;
      }

      if (value == 'Gas') {
        const boilers = await this.heatingSystemService.getGasAndOilBoilers(true, true);
        this.boilerModelOptions = boilers;
      } else if (value == 'Other') {
        const oilBoilers = await this.heatingSystemService.getGasAndOilBoilers(true, false, true);
        const solidFuelBoilers = await this.heatingSystemService.getSolidFuelBoilers(true);
        const twinBurnerBoilers = await this.heatingSystemService.getTwinBurnerBoilers(true);
        this.boilerModelOptions = oilBoilers.concat(solidFuelBoilers).concat(twinBurnerBoilers);
      }

      this.boilerManufacturers = this.heatingSystemService.getManufacturersFromArray(this.boilerModelOptions);

      this.formInfo.controls['boilerManufacturer'].enable();
      this.formInfo.controls['boilerModel'].enable();
    }
  }

  onManufacturerChange(event) {

    if (!(event && event.target && event.target.value)) {
      this.boilerModels = [];
      this.formInfo.controls['boilerModel'].setValue({});
      return;
    }
    const manufacturerName = event.target.value;
    this.boilerModels = this.boilerModelOptions.filter((boiler) =>
      boiler.brand.toLowerCase().split(' ').join('').includes(manufacturerName.toLowerCase().split(' ').join(''))
    );
    this.formInfo.controls['boilerModel'].setValue({});
  }

  getDevices() {
    this.glowService.getDevices().subscribe(async (resp) => {
      const sensorTypeArr = ['05a115ed-0135-45ad-b677-130a5e971998', 'ba06dbab-e759-4ad9-8b0e-e56a45b56a91', "bc458eab-bf1d-4f49-834c-cfdabfb5aab0"];
      const tempSensor = resp.filter((x) => sensorTypeArr.includes(x.deviceTypeId));
      if (Array.isArray(tempSensor) && tempSensor.length > 0) {
        let resourceArray = [];

        await tempSensor.map((x) => {
          x.protocol.sensors.map((resource) => {
            if (resource.protocolId == 'temp' || resource.protocolId == 'temperature') {
              resourceArray.push({ resourceId: resource.resourceId });
            }
          })
        });

        if (Array.isArray(resourceArray) && resourceArray.length > 0) {
          this.temperatureResources = resourceArray;
          this.loadObj.loaded = true;
        } else {
          this.loadObj = {
            ...this.loadObj,
            loadError: true,
            loading: false,
            errorString: "We can't find a temperature sensor assigned to your account; we need it to calculate your building fabric score. Order one <a class='text-info' href='https://shop.glowmarkt.com/products/sensors?_pos=1&_sid=7e262533f&_ss=r'>here</a>.",
          };
        }
      } else {
        this.loadObj = {
          ...this.loadObj,
          loadError: true,
          loading: false,
          errorString: "We can't find a temperature sensor assigned to your account; we need it to calculate your building fabric score. Order one <a class='text-info' href='https://shop.glowmarkt.com/products/sensors?_pos=1&_sid=7e262533f&_ss=r'>here</a>.",
        };
      }
    }, (err) => {
      console.warn(err);
      this.loadObj = {
        ...this.loadObj,
        loadError: true,
        loading: false,
        errorString: "We can't find a temperature sensor assigned to your account; we need it to calculate your building fabric score. Order one <a  class='text-info' href='https://shop.glowmarkt.com/products/sensors?_pos=1&_sid=7e262533f&_ss=r'>here</a>.",
      };
    });
  }

  getSeasonalEfficiency() {
    const formValue = this.formInfo.controls['boilerModel'].value
    if (formValue && formValue.sapEfficiencies && formValue.sapEfficiencies.winterSeasonal) {
      return formValue.sapEfficiencies.winterSeasonal;
    }
    return null
  }

  calculateHTC() {

    if (!this.formInfo.valid) {
      this.showErrorMessage()
    } else {
      const boilerWinterSeasonalEffciency = this.getSeasonalEfficiency();
      const payload = {
        veId: this.veId,
        propertyPassportId: this.propertyPassportId,
        temperatureResources: this.temperatureResources,
        to: new Date(this.formInfo.controls['toDate'].value).toISOString(),
        manualHTC: {
          attachment: this.formInfo.get('houseAttachment').value,
          location: { postcode: this.formInfo.get('postcode').value },
          floor_area: this.formInfo.get('floorArea').value,
          heating: {
            type: this.formInfo.get('heating').value,
            boiler: { winter_seasonal_efficiency: boilerWinterSeasonalEffciency },
          },
          renewables: this.formInfo.get('renewables').value == 'yes' ? true : false,
        },
      };

      this.sendGAEvent({ event: 'generateHTC' });
      this.loadObj = this.uiConfigService.initialiseLoadingBooleans();

      this.glowService.calculateHTC(payload).subscribe(async (resp) => {
        if (resp.valid) {
          localStorage.setItem('manualHTC', JSON.stringify({ ...resp, veId: this.selectedService.veId }));
          this.currentHtc = resp;
          this.showSuccess = true;
        } else {
          this.loadObj = { ...this.loadObj, loadError: true, loading: false, errorString: resp.errorMsg };
        }
      }, (err) => {
        console.warn(err);
        this.loadObj = {
          ...this.loadObj,
          loadError: true,
          loading: false,
          errorString: 'Looks like we are having trouble generating your building fabric score! Please try again later.'
        };
      });
    }
  }

  formatToPascalCase(string) {
    return string
      .replace(/\w+/g, function (w) {
        return w[0].toUpperCase() + w.slice(1).toLowerCase();
      })
      .replace(/-|\s+/g, '');
  }

  showErrorMessage() {
    if (!this.formInfo.valid) {
      this.formErrorMessage = true
      this.formValidationService.markFormGroup(this.formInfo, 'touched')
    }
  }

  resetError() {
    this.formErrorMessage = false
  }

  checkFormValidity(formControlName, translateKey?) {
    return this.formValidationService.checkFormValidity(this.formInfo, formControlName, translateKey)
  }

  async checkPostcode() {
    let messages = [];
    await this.checkPostcodeisValid().then((resp) => {
      if (!this.postcodeObj.isValid) {
        messages.push('Invalid postcode');
      }
      return messages
    });
    return
  }

  async checkPostcodeisValid() {
    let postcode;
    if (this.formInfo.get('postcode').touched) {
      postcode = this.formInfo.value.postcode;

      await this.postcodeCheckerService
        .checkPostcode(postcode)
        .then((resp: any) => {
          // console.log('IS POSTCODEVALID', resp);
          this.postcodeObj.area = resp.result['admin_district'];
          this.postcodeObj.isValid = true;
          this.postcodeObj.error = false;
        })
        .catch((err) => {
          console.warn('postcode errr', err);
          this.postcodeObj.area = '';
          this.postcodeObj.isValid = false;
          this.postcodeObj.error = true;

        });
    }
  }

  close() {
    if (this.currentHtc) {
      this.updateHtcCard.emit(this.currentHtc);
    }
    this.activeModal.dismiss();
  }

  sendGAEvent(params) {
    if (this.ccService.hasAnswered() && this.ccService.hasConsented()) {
      window.dataLayer.push(params);
    }
  }

}