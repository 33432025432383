
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  template: `<div class='center'>
    <clr-icon shape="pencil" aria-label="details" (click)="onEdit()" style="margin-right: 10px"></clr-icon>
    <clr-icon shape="trash" aria-label="details" (click)="onRemove()"></clr-icon>
  </div>`,
})

export class CustomPropertyAttributeButtonsComponent implements OnInit {

  renderValue: string;

  @Input() value;
  @Input() rowData: any;
  @Output() remove = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    this.renderValue = this.value;
  }

  onRemove() {
    // console.log('onRemove', this.rowData)
    this.remove.emit(this.rowData);
  }

  onEdit() {
    // console.log('onEdit', this.rowData)
    this.edit.emit(this.rowData);
  }
}