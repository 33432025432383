import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OAuthService } from 'angular-oauth2-oidc';
import { GlowService } from 'src/app/services/glow.service';
import { InvitationsService } from 'src/app/services/invitations.service';
import { ProfessionalGlowService } from 'src/app/services/professional-glow.service';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  confirmationText: string;
  confirmationButtonText: string;
  confirmationTitle: string;
  dismissButtonText: string
  estimateRequestId;
  public hasValidAccessToken = false;
  error;
  errorMessage;
  loading
  constructor(private activeModal: NgbActiveModal,
    private professionalGlowService: ProfessionalGlowService,
    private glowService: GlowService, private invitationsService: InvitationsService, public oauthService: OAuthService) { }

  ngOnInit() {
    this.hasValidAccessToken = this.oauthService.hasValidAccessToken();
  }

  removeAccess() {
    this.loading = true

    let state = this.hasValidAccessToken ? "estimate-request-revoked-by-professional" : "estimate-request-revoked-by-customer"
    const body = {
      "estimateRequestState": state
    }
    if (this.hasValidAccessToken) {
      this.professionalGlowService.updateEstimateRequestProfessionalState(body, this.estimateRequestId).subscribe(async (resp) => {
        if (resp.valid) {
          this.error = false
          await this.invitationsService.getAllProfessionalEstimateRequests()
          this.loading = false
          this.activeModal.dismiss()

        } else {
          this.error = true
          this.errorMessage = 'Something went wrong please try again later'
          this.loading = false

        }
      })

    } else {
      this.glowService.updateEstimateRequestUserState(body, this.estimateRequestId).subscribe((resp) => {
        if (resp.valid) {
          this.error = false
          this.invitationsService.getAllEstimateRequests()
          this.loading = false
          this.activeModal.dismiss()

        } else {
          this.error = true
          this.errorMessage = 'Something went wrong please try again later'
          this.loading = false

        }
      })
    }
  }
}
