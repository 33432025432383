import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FindAProfessionalComponent } from './find-a-professional.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { InstallerCardModule } from 'src/app/components/installer-card/installer-card.module';



@NgModule({
  declarations: [FindAProfessionalComponent],
  imports: [
    CommonModule,
    SharedModule,
    InstallerCardModule
  ],
  exports: [FindAProfessionalComponent]
})
export class FindAProfessionalModule { }
