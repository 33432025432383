import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { GlowService } from '../../services/glow.service';
import { tLoginInfo } from '../../services/glow.typings';
import { CustomUIConfigService } from '../../services/custom-ui-config.service';
import { tLoadingElementWithSuccess } from '../../services/app-config.typings';
import { TermsConditionsService } from '../../services/termsconditions.service';
import { tTermsConditionsUiConfig } from 'src/app/components/component.typings';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TermsconditionsModalComponent } from 'src/app/components/termsconditions-modal/termsconditions-modal.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { VirtualEntityService } from 'src/app/services/virtual-entity.service';
import { Subscription } from 'rxjs';

@Component({
  styleUrls: ['./login.component.scss', '../public-components.scss'],
  templateUrl: './login.component.html',
})
export class LoginComponent {
  public hasValidAccessToken = false;
  loginDetailsForm: FormGroup;
  loadObj: tLoadingElementWithSuccess;
  isOpenTermsAndConditionsModal: boolean = false;
  termsConditionsUiConfig: tTermsConditionsUiConfig = {
    allowModalToExit: false,
    showPermissions: true,
    requireAccept: true,
  };
  viewPassword: boolean = false;
  serviceAgentActive: boolean = false;
  userActive: boolean = true;
  termsconditionsRef: any;
  showPasswordForm: boolean = false;
  subscriptionVirtualEntityList: Subscription;

  constructor(
    public auth: AuthenticationService,
    public oauthService: OAuthService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private authService: GlowService,
    private termsConditionsService: TermsConditionsService,
    private route: ActivatedRoute,
    private router: Router,
    private uiConfigService: CustomUIConfigService,
    private veService: VirtualEntityService
  ) {
    this.subscriptionVirtualEntityList = this.veService.virtualEntitiesForDashboard$.subscribe(async (veList) => {
      if (veList.length) {
        this.router.navigateByUrl('/pages/property');
      } else {
        this.router.navigateByUrl('/pages/property/energy-data');
      }
    });

    const accessToken = this.oauthService.getAccessToken();
    const hasIdToken = this.oauthService.hasValidIdToken();
    this.hasValidAccessToken = this.oauthService.hasValidAccessToken();

    if (this.hasValidAccessToken && hasIdToken && accessToken) {
      this.router.navigateByUrl('/professional/landing');
    }

    this.loadObj = this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false);

    this.loginDetailsForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.route.queryParams.subscribe(async (params) => {
      if (params.code && params.state) {
        this.serviceAgentActive = true;
        this.userActive = false;
        this.loadObj = { ...this.loadObj, loading: true, loadedMessage: 'authorising...' };

        if (!this.auth.authenticated) {
          this.auth.markAsAuthenticated();
        }
      } else {
        this.loginDetailsForm.get('username').setValue(params['email']);
        const missingElements = [];
        const incomingRequiredProperties = ['applicationId', 'token', 'userKey'];
        const replaceCharacters = [/%0D/g, /%0A/g, /%0D%0A/g, /\r/g, /\n/g];
        const incomingValues: any = Object.keys(params).reduce((acc, incomingProperty) => {
          const sanitisedPropertyName = replaceCharacters.reduce((acc, characterToReplace) => acc.replace(characterToReplace, ''), incomingProperty);
          if (incomingRequiredProperties.indexOf(sanitisedPropertyName) > -1 || incomingRequiredProperties.indexOf(incomingProperty) > -1) {
            const sanitisedValue = replaceCharacters.reduce((acc, characterToReplace) => acc.replace(characterToReplace, ''), params[incomingProperty] || params[sanitisedPropertyName]);
            acc[sanitisedPropertyName] = sanitisedValue;
          }
          return acc;
        }, {});
        incomingRequiredProperties.forEach((property) => {
          if (!incomingValues[property]) {
            missingElements.push(property);
            console.warn('Missing element: ' + property);
          }
        });
        const { applicationId, token, userKey } = incomingValues;
        const emailVerificationIncomingParams = { applicationId, token, usernameHash: userKey };

        this.submitEmailVerificationInformation(emailVerificationIncomingParams);
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.subscriptionVirtualEntityList) {
      this.subscriptionVirtualEntityList.unsubscribe();
    }
  }

  submitEmailVerificationInformation(emailVerificationIncomingParams) {
    this.authService.verify(emailVerificationIncomingParams).subscribe(
      (verificationResponse) => {
        // if (verificationResponse.valid === true) {
        //   console.log("Email successfully verified")
        // } else {
        //   console.log("email address could not be verified.")
        // }
      },
      (err) => {
        console.warn(err.error);
      }
    );
  }

  sanitiseDetails(loginDetailsForm: tLoginInfo): tLoginInfo {
    if (loginDetailsForm && loginDetailsForm.username) {
      loginDetailsForm.username = loginDetailsForm.username.toLowerCase();
      loginDetailsForm.username = loginDetailsForm.username.replace(/\s+/g, '');
    }
    if (loginDetailsForm.password) {
      loginDetailsForm.password = loginDetailsForm.password.replace(/\s+$/, ''); // Trailing spaces
      loginDetailsForm.password = loginDetailsForm.password.replace(/^\s+/g, ''); // Spaces at the start of the string
    }
    return loginDetailsForm;
  }

  togglePassword(viewPassword: string) {
    this.viewPassword = viewPassword ? false : true;
  }

  logOut() {
    this.authService.logout().subscribe(
      (resp) => {
        console.log('logout', resp);
      },
      (err) => {
        console.warn(err);
      }
    );
  }

  checkEmail() {
    if (this.loginDetailsForm.value.username == '' || this.loginDetailsForm.value.username == undefined || this.loginDetailsForm.value.username == null) {
      this.loadObj.errorString = 'Enter username';
    } else {
      const body = { username: this.loginDetailsForm.value.username };
      this.authService.checkUserEmailLogin(body).subscribe((resp: any) => {
        if (resp.authenticationTypeService == 'glow-oicd') {
          const params = { login_hint: body.username };
          this.auth.authenticate(true, params);
          this.loginDetailsForm.reset();
        } else {
          this.showPasswordForm = true;
        }
      });
    }
  }

  openTermsConditionsModal() {
    this.termsconditionsRef = this.modalService.open(TermsconditionsModalComponent, {
      scrollable: true,
      backdrop: 'static',
      centered: true,
      size: 'xl',
    });
  }

  findUserOrganizations() {
    this.authService.getUserOrganizationsOfUser().subscribe(
      (userOrganizations) => {
        this.loadObj = {
          ...this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false),
          loaded: true,
          loadedMessage: 'Success',
        };
        this.veService.findVirtualEntities();
      },
      (error) => {
        console.warn(error);
      }
    );
  }

  async checkTermsConditions() {
    let userTcObj: any;
    try {
      userTcObj = await this.termsConditionsService.checkIfUserHasAcceptedCurrentTermsAndConditions();
    } catch (e) {
      console.warn(e);
      if (e && e.message) {
        this.loadObj = {
          ...this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false),
          loadError: true,
          errorString: e.message,
        };
      } else {
        this.loadObj = {
          ...this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false),
          loadError: true,
          errorString: 'ERROR',
        };
      }
    }
    if (userTcObj && userTcObj.hasUserAcceptedTermsConditions === true) {
      this.findUserOrganizations();
    } else {
      this.isOpenTermsAndConditionsModal = true;
      this.openTermsConditionsModal();
      this.termsconditionsRef.result.then(
        (data) => {
          if (data.reference && data.data) {
            this.termsConditionsService.setUserTermsConditions(data);
            this.findUserOrganizations();
          } else {
            this.loadObj = {
              ...this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false),
              loadError: true,
              errorString: 'Terms and conditions must be accepted to continue',
            };
          }
        },
        (err: any) => {
          console.warn(err);
        }
      );
    }
  }

  doLogin() {
    this.loadObj = this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(true);
    let loginDetails = this.sanitiseDetails({ ...this.loginDetailsForm.value });
    this.authService.login(loginDetails).subscribe(
      (authResponse) => {
        if (authResponse === true) {
          const isTempAuth = this.authService.isTempAuth();
          if (isTempAuth === true) {
            this.findUserOrganizations();
          } else {
            this.checkTermsConditions();
          }
        } else {
          this.loadObj = {
            ...this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false),
            loadError: true,
            errorString: 'Access Denied',
          };
        }
      },
      (err) => {
        console.warn(err);
        let errorString = 'An error has occurred';
        try {
          if (err.error && typeof err.error === 'string') {
            errorString = err.error;
          } else if ((err.error && !err.error.valid) || err.valid === false) {
            errorString = 'Invalid username or password';
          }
        } catch (e) {
          console.warn(e);
        }
        this.loadObj = {
          ...this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false),
          loadError: true,
          errorString,
        };
      }
    );
  }

  toggleLoginType(type) {
    this.serviceAgentActive = type == 'serviceAgent' ? true : false;
    this.userActive = type == 'user' ? true : false;
  }
}
