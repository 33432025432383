import { Component, OnInit } from '@angular/core';
import { StrapiService } from 'src/app/services/strapi.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  content;
  constructor(private strapiService: StrapiService) {
    this.getContent().then((content) => {
      this.content = content;
    });
  }

  ngOnInit() {
  }

  async getContent() {
    const content = await this.strapiService.getPrivacyPolicy();
    // console.log(content)
    return content
  }

}
