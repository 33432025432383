import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class JsonLdService {
  constructor() {}

  static scriptType = "application/ld+json";

  static articleSchema(title: string, summary: string, imagesUrls:string[], authorNames?:string[]) {
    let authors;
    if (authorNames){
    authors=authorNames.map(name=>{return {'@type':'Person', 'name':name}})
    }
    return {
      '@context': 'http://schema.org',
      '@type': 'Article',
      headline: title,
      abstract:summary,
      image:imagesUrls,
      author:authors
    };
  }

  static websiteSchema(url: string, name: string) {
    return {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: url || 'https://glowmarkt.com/',
      name: name || 'Glowmarkt',
      // "sameAs": ["https://facebook.com/google",
      //      "https://instagram.com/google",
      //      "https://twitter.com/google"]
    };
  }

  static organisationSchema() {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      url: 'https://www.hildebrand.co.uk/',
      name: 'Hildebrand',
      contactPoint: {
        '@type': 'ContactPoint',
        telephone: '0207 486 1020',
        contactType: 'Customer service',
      },
    };
  }
}
