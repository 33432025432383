import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { HelpModalComponent } from 'src/app/components/help-modal/help-modal.component';
import { InstallerInvitationModalComponent } from 'src/app/components/installer-invitation-modal/installer-invitation-modal.component';

@Component({
  selector: 'app-account-pages',
  templateUrl: './account-pages.component.html',
  styleUrls: ['./account-pages.component.scss']
})
export class AccountPagesComponent implements OnInit {
  pageTitle = 'Account';
  currentPage = '';
  private currentPageAnnouncer = new Subject<any>();
  currentPage$ = this.currentPageAnnouncer.asObservable();
  constructor(private modalService: NgbModal,  private translate: TranslateService) {}

  ngOnInit() {}
  public onRouterOutletActivate(event: any) {
    if (event.pageTitle) {
      this.currentPageAnnouncer.next(event.pageTitle);
      this.currentPage = event.pageTitle;
    }

    // // console.log("EVENT",event);
  }
  openInvitationModal(){
    this.modalService.open(InstallerInvitationModalComponent, { scrollable: true, centered: true, size: 'lg', backdrop: 'static' })
  }
  openInfoModal() {
    const modalRef = this.modalService.open(HelpModalComponent, { scrollable: true, centered: true, size: 'lg' });
    modalRef.componentInstance.pageTitle = 'Invitations';
    modalRef.componentInstance.modalBodyContent = this.translate.instant('INVITATIONS_PROFESSIONAL_EXPLANATION')



  }
}
