import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupName, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CaseStudyFormService } from 'src/app/services/case-study-form.service';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-energy-storage-form',
  templateUrl: './energy-storage-form.component.html',
  styleUrls: ['./energy-storage-form.component.scss'],
})
export class EnergyStorageFormComponent implements OnInit {
  @Input() caseStudyFields;
  @Input() section;
  @Input() caseStudy;
  @Input() formattedCaseStudy;
  @Input() journal;

  hasEnergyStorageFormName = 'hasEnergyStoragePreForm';
  hasEnergyStorageForm: FormGroup;
  energyStorageFormName = 'energyStoragePreForm';
  energyStorageForm: FormGroup;

  components: any[];
  completionStatus: 'completed' | 'in-progress' = 'in-progress';

  subscriptionSelectedService: Subscription;
  csSection;
  selectedService: any;
  isSubscribedToBooleanControl = {};
  constructor(private fb: FormBuilder, private caseStudyFormService: CaseStudyFormService, private serviceSelectorSvc: ServiceSelectorSvc) {
    this.subscriptionSelectedService = this.serviceSelectorSvc.serviceSet.subscribe((selectedService) => {
      this.selectedService = selectedService;
    });
    this.caseStudyFormService.formAnnouncer.subscribe((data) => {
      if (data && data.formName === this.energyStorageFormName) {
        this.energyStorageForm = data.form;
      }
      if (data && data.formName === this.hasEnergyStorageFormName) {
        this.hasEnergyStorageForm = data.form;
      }
      if (this.hasEnergyStorageForm && !this.isSubscribedToBooleanControl[this.hasEnergyStorageFormName]) {
        this.isSubscribedToBooleanControl[this.hasEnergyStorageFormName] = true;
        this.caseStudyFormService.processChangeBooleanControl('energyStorage', this.hasEnergyStorageForm, this.energyStorageForm, 'energyStorages', 'isTrue', this.energyStorageFormName);
      }
    });
  }

  ngOnInit() {
    this.selectedService = this.serviceSelectorSvc.getSelectedService();
    this.caseStudyFormService.announceForm(this.hasEnergyStorageFormName, this.caseStudyFormService[this.hasEnergyStorageFormName]);
    this.caseStudyFormService.announceForm(this.energyStorageFormName, this.caseStudyFormService[this.energyStorageFormName]);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.section && changes.section.currentValue) {
      this.csSection = this.section === 'pre-installation-heating' ? 'before' : 'after';
      this.energyStorageFormName = this.csSection === 'before' ? 'energyStoragePreForm' : 'energyStoragePostForm';
      this.hasEnergyStorageFormName = this.csSection === 'before' ? 'hasEnergyStoragePreForm' : 'hasEnergyStoragePostForm';

      if (this.formattedCaseStudy) {
        this.components = this.formattedCaseStudy[this.csSection].energy_storage;
      }
    }
    if (changes.formattedCaseStudy && changes.formattedCaseStudy.currentValue) {
      this.components = this.formattedCaseStudy[this.csSection].energy_storage;
    }
  }

  ngOnDestroy() {
    if (this.subscriptionSelectedService) this.subscriptionSelectedService.unsubscribe();
  }

  async saveChanges(completionStatus: 'completed' | 'in-progress'='in-progress') {
    this.completionStatus = completionStatus;

    let err, data;
    [err, data] = await Utils.promiseHandler(this.caseStudyFormService.processDeletedComponents(this.components, this.energyStorageForm, 'energyStorages', this.csSection));
    if (this.energyStorageForm) {
      for (let form of this.energyStorageForm.controls.energyStorages['controls']) {
        const existingComponent = this.components && this.components.find((component) => component.componentId === form.metadata.componentId && component.componentVersion === form.metadata.componentVersion);
        if (existingComponent) {
          [err, data] = await Utils.promiseHandler(this.processExistingEnergyStorage(form.value, existingComponent));
        } else {
          [err, data] = await Utils.promiseHandler(this.processNewEnergyStorage(form.value));
        }
        if (err) throw err;
      }
    }
    this.caseStudyFormService.setFormAndAnnounce(this.energyStorageFormName, this.energyStorageForm);
    return true;
  }

  async processExistingEnergyStorage(formValue, existingComponent) {
    const componentDataType = 'energy_storage';
    const component = await this.caseStudyFormService.updateComponent({
      formValue,
      componentDataType,
      componentVersion: existingComponent.componentVersion,
      componentId: existingComponent.componentId,
      fieldsToDelete: ['installedAt'],
      fieldsToAdd: ['installedAt'],
    });
    const descriptionJe = await this.caseStudyFormService.updateDescriptionJournalEntry({
      formValue,
      existingTextDescription: existingComponent.text_description,
    });
  }

  async processNewEnergyStorage(formValue) {
    const componentDataType = 'energy_storage';
    const component = await this.caseStudyFormService.createComponent({ formValue, componentDataType, componentVersion: 1, fieldsToDelete: ['installedAt'], fieldsToAdd: ['installedAt'], completionStatus: this.completionStatus });
    const descriptionJe = await this.caseStudyFormService.createDescriptionJournalEntry({
      formValue,
      componentId: component.componentId,
      componentDataType,
      componentVersion: component.componentVersion,
      completionStatus: this.completionStatus,
    });
    await this.caseStudyFormService.addJournalEntryToCaseStudy({ caseStudy: this.caseStudy, journalEntry: descriptionJe, section: this.csSection });
  }

  addForm() {
    return this.caseStudyFormService.addGroupToFormArray('energyStorage', this.energyStorageForm, 'energyStorages');
  }

  removeForm(index) {
    this.caseStudyFormService.removeForm(index, this.energyStorageForm, 'energyStorages');
  }
}
