import { Component, Input, OnInit } from '@angular/core';
import { StrapiService } from 'src/app/services/strapi.service';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-left-vertical-hero',
  templateUrl: './left-vertical-hero.component.html',
  styleUrls: ['./left-vertical-hero.component.scss'],
})
export class LeftVerticalHeroComponent implements OnInit {
  @Input() allContent;
  @Input() heroContent:any
  constructor(private strapiService: StrapiService) {}

  ngOnInit() {}

  contentRelativePath(content: any) {
    return `/home/${content.contentType}/${content.attributes.slug}`;
  }


  getTimeToRead(content) {
    if (content.contentType === 'tutorials') {
      let totalMin = 1;
      content.attributes.tutorial_sections.data.forEach((c: any) => {
        totalMin = totalMin + Utils.getTimeToRead(c.attributes.content);
      });
      return totalMin;
    } else {
      return content.attributes.content?Utils.getTimeToRead(content.attributes.content):3
    }
  }

  getDisplayImageUrl(content) {
    const url = this.strapiService.getSmallDisplayImageUrl(content);
    return { 'background-image': `url(${url})` };
  }
}
