import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormTemplateModule } from '../form-template/form-template.module';
import { EnergyStorageFormComponent } from './energy-storage-form.component';



@NgModule({
  declarations: [EnergyStorageFormComponent],
  imports: [
    CommonModule, SharedModule, FormTemplateModule
  ],
  exports: [EnergyStorageFormComponent]
})
export class EnergyStorageFormModule { }
