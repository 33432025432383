import { Component, OnInit, Input } from '@angular/core';
import { LandingService } from 'src/app/services/landing.service';
import { StrapiService } from 'src/app/services/strapi.service';
import _ from 'underscore';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
  @Input() content: any;
  userStories: any = [];
  blogs: any = [];
  articles: any = [];
  infographics: any = [];
  resourceLists: any = [];
  relatedContent: any = [];

  loading = true;
  constructor(private strapiService: StrapiService) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.getAllRelatedContent().then(() => (this.loading = false));
  }

  async getAllRelatedContent(content?) {
    content ? (this.content = content) : null;
    this.blogs = this.getRelatedContent('blogs');
    this.articles = this.getRelatedContent('articles');
    this.infographics = this.getRelatedContent('infographics');
    this.relatedContent = _.shuffle([...this.blogs, ...this.articles]);
  }

  getContentObject(content, contentType) {
    return { ...content, contentType };
  }
  getRelatedContent(contentType) {
    const contents = this.content.attributes[contentType] && this.content.attributes[contentType].data ? this.content.attributes[contentType].data : [];
    return contents.map((content) => this.getContentObject(content, contentType));
  }
}
