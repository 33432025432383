import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { caseStudyFields } from '../../../../assets/content/case-study-fields.json';
import { FormValidationService } from 'src/app/services/form-validation.service';
import { Utils } from 'src/utils';
import { GlowService } from 'src/app/services/glow.service';
@Component({
  selector: 'app-form-template',
  templateUrl: './form-template.component.html',
  styleUrls: ['./form-template.component.scss'],
})
export class FormTemplateComponent implements OnInit {
  @Input() formGroup: FormGroup;
  caseStudyFields = caseStudyFields;
  @Input() title;
  @Input() categories: [];
  @Input() completionStatus: string;
  remainingCharactersMap = {};
  constructor(private formValidationService: FormValidationService, private glowService: GlowService) {}

  ngOnInit() {
    this.setImageSources().then();
  }

  checkFormValidity(formControlName, translateKey?) {
    const formValidity =this.formValidationService.checkFormValidity(this.formGroup, formControlName, translateKey);
    return formValidity
  }

  async setImageSources() {
    for (let category of this.categories) {
      for (let field in this.caseStudyFields[category] as any) {
        const info: any = this.caseStudyFields[category][field];
        if (info.type != 'file') continue;
        let files = this.formGroup.get(field).value;
        if (!files) continue;
        if (!info.multiple) {
          files = [files];
        }
        for (let i = 0; i < files.length; i++) {
          const blob = await this.glowService.getImage(files[i].fileId);
          if (blob) {
            await this.setImageSourceAndClass(blob, field + i + this.formGroup.metadata.componentId, { width: files[i].width, height: files[i].height });
          }
        }
      }
    }
  }

  async addDescriptionToImage(event, controlName, index) {
    let files = this.formGroup.get(controlName).value;
    let description = event.target.value;
    if (!(files && files[index])) return;
    files[index].description = description;
    this.formGroup.get(controlName).setValue(files);
  }

  removeImage(controlName, index) {
    const dt = new DataTransfer();
    const fileList = this.formGroup.get(controlName).value;
    if (fileList instanceof FileList) {
      for (let i = 0; i < fileList.length; i++) {
        if (i != index) {
          dt.items.add(fileList[i]);
        }
      }
      this.formGroup.get(controlName).setValue(dt.files);
      //reset file input
      const elem = document.getElementById(controlName) as HTMLInputElement;
      elem.files = dt.files;
    } else {
      fileList.splice(index, 1);
      this.formGroup.get(controlName).setValue(fileList);
    }
  }

  getRemainingCharacters(controlName, maxLength) {
    if (!maxLength) return;
    const control = this.formGroup.get(controlName);
    this.remainingCharactersMap[controlName] = maxLength;
    if (control && control.value) {
      this.remainingCharactersMap[controlName] = maxLength - control.value.length;
    }
    return this.remainingCharactersMap[controlName];
  }

  async setImageSourceAndClass(blob, id, imgInfo) {
    const imgElm = await Utils.waitForElemById(id);
    if (!imgElm) return;
    const scr = (await Utils.getBlobImageSource(blob)) as string;
    const imageOrientation = Utils.getImageOrientation(imgInfo);
    imgElm.setAttribute('class', imageOrientation);
    imgElm.setAttribute('src', scr);
  }

  async fileInputChange(event, controlName, defaultImageDescription) {
    const files = event.target.files;
    if (!files || files.length == 0) return;
    for (let i = 0; i < files.length; i++) {
      const fileDimensions = await Utils.getFileDimensions({ file: files[i] });
      files[i].width = fileDimensions.width;
      files[i].height = fileDimensions.height;
      if(defaultImageDescription) files[i].description = defaultImageDescription;
      await this.setImageSourceAndClass(files[i], controlName + i + this.formGroup.metadata.componentId, fileDimensions);
    }
    this.formGroup.get(controlName).setValue(files);
  }
}
