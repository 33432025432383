import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ProfileModalComponent } from 'src/app/components/profile-modal/profile-modal.component';
import { GlowService } from 'src/app/services/glow.service';
import { HeatingSystemService } from 'src/app/services/heating-system.service';
import { ProfessionalGlowService } from 'src/app/services/professional-glow.service';
import { ServiceAgentService } from 'src/app/services/service-agent.service';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  pageTitle = 'Profile'
  serviceAgent
  loading: boolean = true
  serviceAgentSubscription: Subscription
  imageSrc;
  logoSrc;
  heatPumpManufacturerList: Array<object>
  constructor(private glowService: GlowService,
    private modalService: NgbModal, 
    private serviceAgentService: ServiceAgentService, 
    private heatingSystemService: HeatingSystemService,
    private professionalGlowService: ProfessionalGlowService) {
    this.serviceAgentSubscription = this.serviceAgentService.serviceAgentAnnouncer.subscribe(resp => {
      this.serviceAgent = resp
      if (this.serviceAgent.photoId) {
        this.getImage(this.serviceAgent.photoId).then(resp => {
          this.imageSrc = resp
          this.loading = false
        })
      }   
      if (this.serviceAgent.logoId) {
        this.getImage(this.serviceAgent.logoId).then(resp => {
          this.logoSrc = resp
          this.loading = false
        })
      } 
      if (!this.serviceAgent.photoId && !this.serviceAgent.logoId){
          this.loading = false
      }
      
      
    })
  }

  ngOnInit() {
    this.serviceAgentService.getServiceAgent()
    this.getHeatPumpBrands()
  }

  async getImage(id) {
    const blob = await this.professionalGlowService.getServiceAgentFile(id)
    const src = await Utils.getBlobImageSource((blob)) as string
    return src
  }

  async getHeatPumpBrands() {
    const resp = await this.heatingSystemService.getHeatPumps(true)
    const manufacturers = await this.heatingSystemService.getManufacturersFromArray(resp)
    const formattedManufacturers = manufacturers.map(x => x.name)
    this.heatPumpManufacturerList = formattedManufacturers
  }

  openModal(formToShow) {
    const modalRef = this.modalService.open(ProfileModalComponent, { scrollable: true, centered: true, size: 'lg', backdrop: 'static' })
    modalRef.componentInstance.serviceAgent = this.serviceAgent
    modalRef.componentInstance[formToShow] = true
    modalRef.componentInstance.heatPumpManufacturerList = this.heatPumpManufacturerList
    modalRef.componentInstance.logoImageSrc = this.logoSrc
    modalRef.componentInstance.profileImageSrc = this.imageSrc
  }
}
