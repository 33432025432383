import { Component, HostListener, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { HpJourneyModalComponent } from 'src/app/components/hp-journey-modal/hp-journey-modal.component';
import { GlowService } from 'src/app/services/glow.service';
import { InvitationsService } from 'src/app/services/invitations.service';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';

@Component({
  selector: 'app-heat-pump-journey',
  templateUrl: './heat-pump-journey.component.html',
  styleUrls: ['./heat-pump-journey.component.scss']
})
export class HeatPumpJourneyComponent implements OnInit {
  @HostListener('window:resize', []) onResize() {
    this.innerWidth = window.innerWidth;
  }
  pageTitle = 'Heat pump journey';
  currentPage = '';
  innerWidth
  estimateRequestSubscription: Subscription
  estimateRequests
  steps;
  journeyStarted: boolean
  // continueJourney: boolean
  // notEligible: boolean
  hasPassport;
  selectedService: any
  validVE: boolean;
  invalidVE: any
  virtualEntityRequired: boolean
  gasResourceRequired: boolean
  propertyPassportRequired: boolean
  radiatorSchematicRequired: boolean
  acceptedEstimateRequests = []
  constructor(private invitationsService: InvitationsService, private serviceSelectorSvc: ServiceSelectorSvc,
    private glowService: GlowService, private modalService: NgbModal) {
    this.estimateRequestSubscription = this.invitationsService.EstimateRequestsByVeIdAnnouncer.subscribe(resp => {
      this.acceptedEstimateRequests = []
      this.estimateRequests = resp.estimateRequests

      this.acceptedEstimateRequests = this.invitationsService.getAllActiveEstimateRequests(resp)

    })
  }

  ngOnInit() {
    this.onResize()
    this.getEstimateRequestsByVeIdAndState()
    this.checkVeValidity()

  }


  getEstimateRequestsByVeIdAndState() {
    const ve = this.serviceSelectorSvc.getSelectedService()
    if (ve) {
      this.invitationsService.getEstimateRequestsByVeIdAndState(ve.veId, true)

    } else this.virtualEntityRequired = true

  }

  checkVeValidity() {
    const ve = this.serviceSelectorSvc.getSelectedService()
    this.invitationsService.validateVirtualEntitites([ve]).then(resp => {
      if (resp && resp.length) {
        this.invalidVE = resp
        console.log('invalidVE', this.invalidVE)
        this.validVE = false
        this.gasResourceRequired = this.checkVe(resp[0], 'virtualEntity.gasResource')
        this.virtualEntityRequired = this.checkVe(resp[0], 'virtualEntity')
        this.propertyPassportRequired = this.checkVe(resp[0], "propertyPassport")
        this.radiatorSchematicRequired = this.checkVe(resp[0], 'radiatorSchematics')
      } else {
        this.validVE = true
        console.log('validVE', this.validVE)
      }
    })
  }


  checkVe(ve, requirement) {
    return ve.requirements.find(req => req.requirement == requirement && !req.valid)
  }

  changeSection(e) {
    console.log(e)
  }

  openModal() {
    this.modalService.open(HpJourneyModalComponent, {
      scrollable: true,
      centered: true,
      size: 'xl',
    });
  }



}
