import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupName, Validators } from '@angular/forms';
import { FormValidator } from 'src/app/services/user-validators';
import { tLoadingElementWithSuccess } from 'src/app/services/app-config.typings';
import { PostcodeCheckerService } from 'src/app/services/postcode-checker.service';
import { CustomUIConfigService } from 'src/app/services/custom-ui-config.service';
import { TermsconditionsModalComponent } from 'src/app/components/termsconditions-modal/termsconditions-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlowService } from 'src/app/services/glow.service';
import { FormValidationService } from 'src/app/services/form-validation.service';
import { ProfessionalGlowService } from 'src/app/services/professional-glow.service';


@Component({
  selector: 'app-installer-register-form',
  templateUrl: './installer-register-form.component.html',
  styleUrls: ['./installer-register-form.component.scss']
})
export class InstallerRegisterFormComponent implements OnInit {

  registerForm: FormGroup;
  viewPassword: boolean = false;
  error = false
  postcodeObj: any = { area: '', isValid: null, error: false };
  installerCertifications: Array<string> = [
    'GasSafe',
    'NICEIC',
    'The IAA',
    'OFTEC',
    'NAPIT',
    'MSC',
    'HEATAS',
    'APHC',
    'CIPHA',
    'Install under umbrella scheme'
  ];
  skills: Array<string> = ['System design', 'System installation', 'Retrofit']
  technologies: Array<string> = ['Air source heat pump', 'Exhaust air heat pump', 'Gas absorption heat pump', 'Solar assisted heat pump']
  regions: Array<string> = ['East of England', 'East Midlands', 'London', 'North East', 'North West', 'South East', 'South West', 'West Midlands', 'Yorkshire and Humberside', 'Scotland', 'Wales', 'Northern Ireland']
  roles: Array<string> = ['Installer'];
  options = ['Yes', 'No']
  roleSelected = '';
  loadObj: tLoadingElementWithSuccess;
  type: 'personal-details'
  success = false;
  constructor(
    private formBuilder: FormBuilder,
    private uiConfigService: CustomUIConfigService,
    private postcodeCheckerService: PostcodeCheckerService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private glowService: GlowService,
    private formValidationService: FormValidationService,
    private professionalGlowService: ProfessionalGlowService

  ) {
    this.type = 'personal-details'
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.getParams()
      }
    })
    this.loadObj = this.uiConfigService.initialiseLoadingBooleansWithLoadedMsgErrorObj(false);
    this.registerForm = this.formBuilder.group({
      role: ['Installer', Validators.required],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      workEmail: ['', [Validators.required, FormValidator.isEmailValid]],
      password: ['', [Validators.required, FormValidator.isPasswordStrong]],
      confirmPassword: ['', [Validators.required, FormValidator.isPasswordSame]],
      businessEmail: ['', []],
      businessPhoneNumber: ['', [Validators.required, FormValidator.isPhoneNumberValid]],
      businessName: ['', [Validators.required]],
      mcsNumber: ['', []],
      businessURL: ['', []],
      location: ['', [Validators.required]],
      regionsCovered: ['', [Validators.required]],
      technologies: ['', [Validators.required]],
      skills: ['', [Validators.required]],
      certifications: ['', [Validators.required]],
      isOrganisation: ['', [Validators.required]],
      boilerUpgradeScheme: ['', [Validators.required]]
    })
  }




  ngOnInit() {
    // resets form to beginning on refresh
    this.router.navigateByUrl('/professionals-register/installer?type=personal-details');
    this.getParams()
  }

  back() {
    history.back()
  }

  togglePassword(viewPassword) {
    this.viewPassword = viewPassword ? false : true;
  }

  selectRole(role) {
    this.roleSelected = role;
  }

  checkFormValidity(formControlName, translateKey?) {
    return this.formValidationService.checkFormValidity(this.registerForm, formControlName, translateKey)
  }

  resetError() {
    this.postcodeObj.error = false
    this.error = false
  }
  doRegister() {

    const role = this.registerForm.get('role').value;
    const firstName = this.registerForm.get('firstName').value;
    const lastName = this.registerForm.get('lastName').value;
    const workEmail = this.registerForm.get('workEmail').value;
    const password = this.registerForm.get('password').value;
    const businessEmail = this.registerForm.get('businessEmail').value;
    const businessPhoneNumber = '+44' + this.registerForm.get('businessPhoneNumber').value.substring(1);
    const businessName = this.registerForm.get('businessName').value;
    const mcsNumber = this.registerForm.get('mcsNumber').value;
    const businessURL = this.registerForm.get('businessURL').value;
    const location = this.registerForm.get('location').value;
    const regionsCovered = this.registerForm.get('regionsCovered').value;
    const technologies = this.registerForm.get('technologies').value;
    const skills = this.registerForm.get('skills').value;
    const certifications = this.registerForm.get('certifications').value;
    const isOrganisation = this.registerForm.get('isOrganisation').value;
    const boilerUpgradeScheme = this.registerForm.get('boilerUpgradeScheme').value == 'Yes' ? true : false;
    const body = { role, firstName, lastName, workEmail, password, businessEmail, businessPhoneNumber, businessName, mcsNumber, businessURL, location, regionsCovered, technologies, skills, certifications, isOrganisation, boilerUpgradeScheme }
    // console.log(body)

    if (this.registerForm.valid) {
      // console.log(body)
      this.professionalGlowService.registerServiceAgent(body).subscribe(
        (response) => {
          // console.log('registerServiceAgent', response);
          if (response.valid) {
            this.loadObj = {
              ...this.loadObj,
              loading: false,
              loaded: true,
              loadedMessage: 'Professional account successfully created.',
            };
            this.router.navigateByUrl('/login')
          } else {
            this.loadObj = {
              ...this.loadObj,
              loading: false,
              loadError: true,
              errorString: 'Failed to create a professional account.',
            };
          }
        },
        (errResp) => {
          console.warn(errResp.error.error);
          this.loadObj = {
            ...this.loadObj,
            loading: false,
            loadError: true,
            errorString: 'Failed to create a professional account.',
          };
        }
      );
    } else
      this.error = true
    this.markFormGroup(this.registerForm, 'touched')

  }

  async checkPostcode() {
    let messages = [];
    await this.checkPostcodeisValid().then((resp) => {
      if (!this.postcodeObj.isValid) {
        messages.push('Invalid postcode');
      }
      return messages
    });
    return
  }

  async checkPostcodeisValid() {
    let postcode;
    if (this.registerForm.get('location').touched) {
      postcode = this.registerForm.value.location;

      await this.postcodeCheckerService
        .checkPostcode(postcode)
        .then((resp: any) => {
          // console.log('IS POSTCODEVALID', resp);
          this.postcodeObj.area = resp.result['admin_district'];
          this.postcodeObj.isValid = true;
          this.postcodeObj.error = false;
        })
        .catch((err) => {
          console.warn('postcode errr', err);
          this.postcodeObj.area = '';
          this.postcodeObj.isValid = false;
          this.postcodeObj.error = true;

        });
    }
  }

  openTermsConditions() {
    const termsconditionsRef = this.modalService.open(TermsconditionsModalComponent, {
      scrollable: true,
      centered: true,
      size: 'xl',
    });
    termsconditionsRef.componentInstance.displayForm = false;
    termsconditionsRef.componentInstance.title = 'Glow';
    termsconditionsRef.componentInstance.professional = true
  }

  navigateForm(param) {
    this.error = false
    if (param == 'business' && this.registerForm.get('firstName').valid && this.registerForm.get('lastName').valid && this.registerForm.get('password').valid && this.registerForm.get('confirmPassword').valid) {
      //untouches form when navigating so errors don't display on next page
      this.markFormGroup(this.registerForm, 'untouched')
      this.router.navigateByUrl('/professionals-register/installer?type=business-details');

    } else if (param == 'skills' && this.registerForm.get('businessName').valid && this.registerForm.get('businessPhoneNumber').valid) {
      //untouches form when navigating so errors don't display on next page
      this.markFormGroup(this.registerForm, 'untouched')
      this.router.navigateByUrl('/professionals-register/installer?type=skills');
    }
    else {
      this.error = true
      this.markFormGroup(this.registerForm, 'touched')
    }
    this.getParams()
  }

  markFormGroup(formGroup: FormGroup, type) {
    this.formValidationService.markFormGroup(formGroup, type)
  }

  getParams() {
    this.route.queryParams.subscribe((params) => {
      if (params.type) {
        this.type = params.type
      }
      else this.type = 'personal-details'
    });

  }

}
