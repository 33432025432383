import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralComponent } from './general.component';
import { SpinnerModule } from 'src/app/components/spinner/spinner.module';



@NgModule({
  declarations: [GeneralComponent],
  imports: [
    CommonModule, SpinnerModule
  ]
  , exports: [GeneralComponent
  ]
})
export class GeneralModule { }
