import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SentNotificationsComponent } from './sent-invitations.component';
import { ConfirmationModalModule } from 'src/app/components/confirmation-modal/confirmation-modal.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { RadiatorSchematicModule } from 'src/app/components/radiator-schematic/radiator-schematic.module';



@NgModule({
  declarations: [SentNotificationsComponent],
  imports: [
    CommonModule, ConfirmationModalModule, SharedModule, RadiatorSchematicModule
  ],
  exports:[SentNotificationsComponent]
})
export class SentNotificationsModule { }
