import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolsPagesComponent } from './tools-pages.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SpinnerModule } from 'src/app/components/spinner/spinner.module';



@NgModule({
  declarations: [ToolsPagesComponent],
  imports: [
    SharedModule,
    CommonModule, SpinnerModule
    
  ],
  exports: [ToolsPagesComponent]
})
export class ToolsPagesModule { }
