import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfessionalSettingsComponent } from './professional-settings.component';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [ProfessionalSettingsComponent],
  imports: [
    CommonModule, SharedModule
  ],
  exports: [ProfessionalSettingsComponent]
})
export class ProfessionalSettingsModule { }
