import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { tLoadingElementWithSuccess } from 'src/app/services/app-config.typings';
import { GlowService } from 'src/app/services/glow.service';
import { tTermsConditions } from 'src/app/services/glow.typings';
import { TermsConditionsService } from 'src/app/services/termsconditions.service';
import { tTermsConditionsUiConfig } from '../component.typings';
import { data } from '../../../assets/content/professional-terms-conditions.json'
@Component({
  selector: 'app-termsconditions-modal',
  templateUrl: './termsconditions-modal.component.html',
  styleUrls: ['./termsconditions-modal.component.scss'],
})
export class TermsconditionsModalComponent implements OnInit {
  @Input() options: tTermsConditionsUiConfig | any = {
    allowModalToExit: true,
    showPermissions: true,
    requireAccept: true,
    referenceKey: null,
  };
  @Input() title;
  professionalTerms = data
  loadObj: tLoadingElementWithSuccess;
  requiresPermisssionReq: boolean = false;
  hasTermConditionDetails: boolean = false;
  termsConditionsRef: string;
  termsConditions: tTermsConditions;
  termsConditionsDetailList: any[];
  hasTermsConditions: boolean;
  termsConditionsForm: FormGroup;
  // formPermissionMetaData: tTermsConditionPermissionsDisplayData;
  formPermissionMetaData: any;
  displayForm: boolean = true
  @Input() professional: boolean = false
  constructor(
    private termsConditionsService: TermsConditionsService,
    private glowService: GlowService,
    private modalService: NgbModal,
    private router: Router,
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.termsConditionsService.getCurrentTermsConditionsSync(this.options.referenceKey).then((tc) => {
      this.termsConditions = tc;
      this.processTC();
    });


  }

  processTC() {
    //July 2018: more strigent checks to be added
    // // console.log('processTC');
    // // console.log(this.termsConditions);
    // // console.log(this.options);

    if (this.termsConditions && this.termsConditions.reference) {
      this.termsConditionsRef = this.termsConditions.reference
    }

    if (this.termsConditions && this.termsConditions.hasOwnProperty('data')) {
      var permissionsForm = {};
      this.requiresPermisssionReq = false;
      this.formPermissionMetaData = {
        textStart: null,
        textEnd: null,
        permissionList: [],
      };
      if (this.termsConditions.data.hasOwnProperty('permissions') && this.options.showPermissions === true) {
        if (Array.isArray(this.termsConditions.data.permissions)) {
          this.termsConditions.data.permissions.forEach((permission) => {
            if (permission.hasOwnProperty('reference')) {
              //TO do check if you overwrtting the reference in PermisssionsForm
              this.formPermissionMetaData.permissionList.push(permission);
            } else {
              console.warn('No reference in permission element');
              console.warn(permission);
            }
          });
        } else if (this.termsConditions.data && this.termsConditions.data.permissions && Array.isArray(this.termsConditions.data.permissions.permissionList)) {
          this.formPermissionMetaData = {
            ...this.formPermissionMetaData,
            ...this.termsConditions.data.permissions,
            ...{ permissionList: [] },
          };
          this.termsConditions.data.permissions.permissionList.forEach((permission) => {
            if (permission.hasOwnProperty('reference')) {
              //TO do check if you overwrtting the reference in PermisssionsForm
              this.formPermissionMetaData.permissionList.push(permission);
            } else {
              console.warn('No reference in permission element');
              console.warn(permission);
            }
          });
        }
        this.formPermissionMetaData.permissionList.forEach((permission) => {
          const isRequiredPermission = permission.hasOwnProperty('isRequired') ? permission.isRequired : true;
          if (isRequiredPermission === true) {
            // // console.log('Permission is required' + permission.reference);
            permissionsForm[permission.reference] = [false, Validators.requiredTrue];
          } else {
            permissionsForm[permission.reference] = [false];
          }
        });
        // // console.log('this.formPermissionMetaData');

        // // console.log(this.formPermissionMetaData);
        this.requiresPermisssionReq = true;
        this.termsConditionsForm = this.formBuilder.group(permissionsForm);
        // // console.log(this.termsConditionsForm);

        this.hasTermsConditions = true;
      } else {
        if (this.options.requireAccept === true) {
          this.termsConditionsForm = this.formBuilder.group([]);
          this.requiresPermisssionReq = true;
        }
      }
      if (this.termsConditions.data.hasOwnProperty('details') && Array.isArray(this.termsConditions.data.details)) {
        // TO DO validate it is valid an a parsable
        this.hasTermConditionDetails = true;
        this.hasTermsConditions = true;
        this.termsConditionsDetailList = this.termsConditions.data.details;
      }
      this.loadObj = { ...this.loadObj, ...{ loaded: true, loading: false } };
    } else {
      console.warn('Unexpected Terms and condition object');
      console.warn(this.termsConditions);
      this.loadObj = { ...this.loadObj, ...{ loadError: true, loading: false, errorString: 'ERROR' } };
    }
  }
}
