import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OtherInfoFormComponent } from './other-info-form.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormTemplateModule } from '../form-template/form-template.module';
import { FormValidationMessageModule } from '../../form-validation-message/form-validation-message.module';



@NgModule({
  declarations: [OtherInfoFormComponent],
  imports: [
    CommonModule, SharedModule, FormTemplateModule, FormValidationMessageModule
  ],
  exports: [OtherInfoFormComponent]
})
export class OtherInfoFormModule { }
