import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { EpcInfoModalComponent } from 'src/app/components/epc-info-modal/epc-info-modal.component';
import { GlowService } from 'src/app/services/glow.service';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';
import { StrapiService } from 'src/app/services/strapi.service';
import { VirtualEntityService } from 'src/app/services/virtual-entity.service';

@Component({
  selector: 'app-htc',
  templateUrl: './htc.component.html',
  styleUrls: ['./htc.component.scss'],
})
export class HtcComponent implements OnInit {
  pageTitle: string = 'Your home’s heat retention score';
  loading: boolean;
  loadingMessage: string = 'Loading details...';
  manualHTC: any;
  selectedService: any;
  passport: any;
  error: boolean;
  errorMessage: string;
  htcDefinition: any;
  virtualEntities: any[];
  manualHTCSubscription: Subscription;

  subscriptionVirtualEntityList: Subscription;
  subscriptionSelectedService: Subscription;

  constructor(private modalService: NgbModal, private veService: VirtualEntityService, private serviceSelectorSvc: ServiceSelectorSvc, private glowService: GlowService, private strapiService: StrapiService) {
    this.loading = true;

    this.subscriptionVirtualEntityList = this.veService.virtualEntitiesForDashboard$.subscribe(async (veList) => {
      if (veList && Array.isArray(veList) && veList.length > 0) {
        this.virtualEntities = veList;
      }
    });

    this.subscriptionSelectedService = this.serviceSelectorSvc.serviceSet.subscribe((selectedService) => {
      this.selectedService = selectedService;
      this.loading = true;
      this.getPropertyPassport();
    });
  }

  ngOnInit() {
    this.strapiService.getOneContent('glossary-terms', 10, []).then((t) => (this.htcDefinition = t.attributes.shortDefinition));

    this.selectedService = this.serviceSelectorSvc.getSelectedService();
    if (this.selectedService && this.selectedService.veId) {
      this.getPropertyPassport();
    }
    this.veService.findVirtualEntities();
  }


  getPropertyPassport() {
    this.loading = true;
    this.error = false;
    this.glowService.getPropertyPassport(this.selectedService.veId).subscribe(
      (resp) => {
        if (!resp.valid) {
          this.loading = false;
          this.error = true;
          return;
        }
        this.passport = resp;
        this.loading = false;
      },
      (err) => {
        this.error = true;
        this.passport = null;
        this.errorMessage = err && err.error && err.error.error;
        this.loading = false;
      }
    );
  }

  openHTCModal() {
    const modalRef = this.modalService.open(EpcInfoModalComponent, { scrollable: true, centered: true, size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.propertyPassportId = this.passport.propertyPassportId;
    modalRef.componentInstance.veId = this.virtualEntities[0].veId;
    modalRef.componentInstance.resources = this.virtualEntities[0].resources;
    modalRef.componentInstance.postcode = this.passport.address.postcode;
    modalRef.componentInstance.updateHtcCard.subscribe((htc) => {
      if (htc) {
        this.manualHTC = htc;
        localStorage.setItem('manualHTC', JSON.stringify(htc));
      }
      modalRef.dismiss();
    });
  }

  regenerateHTC() {
    this.manualHTC = false;
  }

  formatHouseAttachmentString(str) {
    return str.replace(/([A-Z][a-z])/g, ' $1');
  }
  navigateToPropertyPassport() {
    return `/pages/account/property-passport`;
  }
}
