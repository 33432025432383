import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeatPumpCaseStudyAccountComponent } from './heat-pump-case-study-account.component';

import { CaseStudyModule } from 'src/app/public/case-study/case-study.module';
import { CaseStudyHeaderModule } from 'src/app/components/case-study/case-study-header/case-study-header.module';
import { CaseStudyInstallatonHeatingSystemTechnicalModule } from 'src/app/components/case-study/case-study-installaton-heating-system-technical/case-study-installaton-heating-system-technical.module';
import { CaseStudySummaryModule } from 'src/app/components/case-study/case-study-summary/case-study-summary.module';
import { CaseStudyDetailsModule } from 'src/app/components/case-study/case-study-details/case-study-details.module';
import { CaseStudyOutcomeModule } from 'src/app/components/case-study/case-study-outcome/case-study-outcome.module';
import { CaseStudyOtherContentModule } from 'src/app/components/case-study/case-study-other-content/case-study-other-content.module';

@NgModule({
  declarations: [HeatPumpCaseStudyAccountComponent],
  imports: [CommonModule, CaseStudyHeaderModule, CaseStudyInstallatonHeatingSystemTechnicalModule, CaseStudySummaryModule, CaseStudyDetailsModule, CaseStudyOutcomeModule, CaseStudyOtherContentModule],
  exports: [HeatPumpCaseStudyAccountComponent],
})
export class HeatPumpCaseStudyAccountModule {}
