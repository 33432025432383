import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SchematicsService } from 'src/app/services/schematics.service';
import { Utils } from 'src/utils';

declare var bootstrap: any;

@Component({
  selector: 'app-schematics',
  templateUrl: './schematics.component.html',
  styleUrls: ['./schematics.component.scss'],
})
export class SchematicsComponent implements OnInit {
  pageTitle = 'Heating by Room';
  currentPage = 'Heating by Room';
  pingSubscription: Subscription;
  shouldPing: boolean = false;
  statusToPing: string;
  status: string;

  sideMenuItems = [
    { url: 'in-progress', icon: 'bi bi-clock-history', title: 'In Progress' },
    { url: 'complete', icon: 'bi bi-file-earmark-check', title: 'Completed' },
  ];

  constructor(private schematicsService: SchematicsService, private router: Router) {}

  ngOnInit() {}

  ngOnDestroy() {
    if (this.pingSubscription) this.pingSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    // Bootstrap tooltip initialization
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
  }

  async hideAnimationAfterStop() {
    const heartbeat = await Utils.waitForElemById('heartbeat');
    if (!heartbeat) return;
    // Add an event listener to the animation end event
    heartbeat.addEventListener('animationend', () => {
      // Set display to none when animation stops
      // heartbeat.style.display = 'none';
      this.shouldPing = false;
    });
  }

  async createNewSchematic() {
    // note on versioning: we are currently on version 2.0 of the schematics document, earlier versions did not have: version, completionStatus, units
    const emptySchematic = await this.schematicsService.createSchematicsDocument({ data: { floors: [], totalFloorArea: 0, totalHeatingPowerOutput: 0, heatingSystem: {}, version: 2.0 } });
    this.router.navigate(['/pages/tools/heating-by-room/schematic/' + emptySchematic.documentId], { queryParams: { edit: true } });

    // remove tooltip
    const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    tooltips.forEach((tooltip) => {
      const tooltipInstance = bootstrap.Tooltip.getInstance(tooltip);
      if (tooltipInstance) tooltipInstance.dispose();
    });
  }

  onRouterOutletActivate(event: any) {
    this.status = event.statusToFilterBy;
    console.log('onRouterOutletActivate', this.status);
    this.pingSubscription = event.ping.subscribe(async (ping) => {
      this.shouldPing = ping.shouldPing;
      this.statusToPing = ping.statusToPing;
      if (this.shouldPing) {
        await this.hideAnimationAfterStop();
      }
    });
  }
}
