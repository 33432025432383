import { Component, OnInit } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { th } from 'date-fns/locale';
import { HeatingSystemService } from 'src/app/services/heating-system.service';

@Component({
  selector: 'app-add-heating-system-modal',
  templateUrl: './add-heating-system-modal.component.html',
  styleUrls: ['./add-heating-system-modal.component.scss'],
})
export class AddHeatingSystemModalComponent implements OnInit {
  private heatingSystem;
  typeOptions = [
    { id: 'boiler', title: 'Boiler' },
    { id: 'heatpump', title: 'Air Source Heat Pump' },
    { id: 'heatpump', title: 'Ground Source Heat Pump' },
    { id: 'heatpump', title: 'Water Source Heat Pump' },
  ];

  typeOptionsMap = {
    boiler: 'getBoilerOptions',
    heatpump: 'getHeatPumpOptions',
  };

  heatingSystemForm: FormGroup;
  domesticHotWaterForm: FormGroup;
  heatingSystemManufacturerOptions: any[];
  heatingSystemModelOptions: any[];
  heatingSystemOptions: any[];
  constructor(
    private fb: FormBuilder,
    private activeModal: NgbActiveModal,
    private heatingSystemService: HeatingSystemService
  ) {}

  ngOnInit() {
    this.initHeatingSystemForm();
    this.initDomesticHotWaterForm();
    if (this.heatingSystem && Object.values(this.heatingSystem).length > 0) {
      this.setFormValues(this.heatingSystem);
    }
  }

  async setFormValues(heatingSystem) {
    const { type, manufacturer, model, size, qualifier } = heatingSystem;
    const { DHWHasCylinder, DHWCylinderSize } = heatingSystem;
    this.heatingSystemForm.enable();
    this.heatingSystemForm.patchValue({ type, manufacturer, model, size, qualifier });
    this.domesticHotWaterForm.patchValue({ DHWHasCylinder, DHWCylinderSize });
    await this.onTypeChange(false, false);
    this.onManufacturerChange(false);
    this.onDHWHasCylinderChange();
  }

  initHeatingSystemForm() {
    this.heatingSystemForm = this.fb.group({
      type: ['', [Validators.required]],
      manufacturer: [{ value: '', disabled: true }],
      model: [{ value: '', disabled: true }],
      qualifier: [{ value: '' }],
      registerNumber: [''],
      size: [''],
      validFrom: [''],
      validTo: [null],
    });
  }

  initDomesticHotWaterForm() {
    this.domesticHotWaterForm = this.fb.group({
      DHWHasCylinder: ['', [Validators.required]],
      DHWCylinderSize: [{ value: '', disabled: true }],
    });
  }

  closeModal(msg?: string) {
    this.activeModal.close({
      msg,
      heatingSystem: this.heatingSystemForm.value,
      domesticHotWater: this.domesticHotWaterForm.value,
    });
  }

  async onTypeChange(resetManufacturer = true, resetModel = true) {
    if (this.heatingSystemForm.value.type) {
      const selectedType = this.typeOptions.find((option) => option.title === this.heatingSystemForm.value.type);
      await this[this.typeOptionsMap[selectedType.id]]();
      this.heatingSystemForm.controls.manufacturer.enable();
    } else {
      this.heatingSystemOptions = [];
      this.heatingSystemForm.patchValue({ manufacturer: '', model: '', size: '', qualifier: '' });
      this.heatingSystemForm.controls.model.disable();
      this.heatingSystemForm.controls.manufacturer.disable();
    }
    if (resetManufacturer) {
      this.heatingSystemForm.patchValue({ manufacturer: '' });
    }
    if (resetModel) {
      this.heatingSystemForm.patchValue({ model: '', size: '', qualifier: '' });
      this.heatingSystemForm.controls.model.disable();
    }
    this.heatingSystemManufacturerOptions = this.heatingSystemService.getManufacturersFromArray(
      this.heatingSystemOptions
    );
  }

  onManufacturerChange(resetModel = true) {
    if (this.heatingSystemForm.value.manufacturer) {
      this.heatingSystemModelOptions = this.heatingSystemOptions.filter((option) => {
        const manufacturer = option.brand || option.manufacturer;
        return manufacturer === this.heatingSystemForm.value.manufacturer;
      });
      // console.log('system model options', this.heatingSystemModelOptions);
      this.heatingSystemForm.controls.model.enable();
    } else {
      this.heatingSystemForm.controls.model.disable();
    }
    if (resetModel) {
      this.heatingSystemForm.patchValue({ model: '', size: '', qualifier: '' });
    }
  }

  onModelChange() {
    const hsOption = this.heatingSystemModelOptions.find((option) => {
      const model = option.model.name || option.model;
      return model === this.heatingSystemForm.value.model;
    });
    const qualifier = hsOption.model.qualifier || hsOption.qualifier;
    this.heatingSystemForm.patchValue({ qualifier, registerNumber: hsOption.registerNumber });
  }

  onDHWHasCylinderChange() {
    this.domesticHotWaterForm.value.DHWHasCylinder == 'Yes'
      ? this.domesticHotWaterForm.controls.DHWCylinderSize.enable()
      : this.domesticHotWaterForm.controls.DHWCylinderSize.disable();
  }

  async getBoilerOptions() {
    const gasBoilers = await this.heatingSystemService.getGasAndOilBoilers(false);
    const twinBurnerBoilers = await this.heatingSystemService.getTwinBurnerBoilers(false);
    const solidFuelBoilers = await this.heatingSystemService.getSolidFuelBoilers(false);
    this.heatingSystemOptions = [...gasBoilers, ...twinBurnerBoilers, ...solidFuelBoilers];
  }

  getHeatPumpOptions() {
    const heatPumps = this.heatingSystemService
      .getHeatPumpsFromMcs(false)
      .filter((hp) => hp.type === this.heatingSystemForm.value.type);
    this.heatingSystemOptions = [...heatPumps];
  }
}
