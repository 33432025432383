import { Component, Input, OnInit } from '@angular/core';
import { TermsConditionsService } from 'src/app/services/termsconditions.service';
import { tTermsConditionsUiConfig } from '../component.typings';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  @Input() options: tTermsConditionsUiConfig | any = {
    allowModalToExit: true,
    showPermissions: true,
    requireAccept: true,
    referenceKey: null,
  };
  termsConditions
  constructor(private termsConditionsService: TermsConditionsService) { }

  ngOnInit() {
    this.termsConditionsService.getCurrentTermsConditionsSync(this.options.referenceKey).then((tc) => {
      this.termsConditions = tc;
    });


  }
}