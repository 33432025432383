import { EventEmitter, Injectable } from '@angular/core';
import { GlowService } from './glow.service';
import { any } from 'core-js/fn/promise';
import { ProfessionalGlowService } from './professional-glow.service';

@Injectable({
  providedIn: 'root'
})

export class InvitationsService {
  activeEstimateRequestsStates = [
    'estimate-request-accepted-by-professional',
    'estimate-response-submitted',
    'estimate-response-accepted',
    'estimate-response-user-questioned',
    'site-visit-arranged',
    'quote-pending-user-acceptance',
    'quote-accepted',
    'quote-declined',
    'installation-complete',
    'estimate-request-accepted-by-user',
  ]


  estimatesRequests;
  EstimatesAnnouncer: EventEmitter<any> = new EventEmitter();
  ProfessionalEstimatesAnnouncer: EventEmitter<any> = new EventEmitter()
  SnapshotAnnouncer: EventEmitter<any> = new EventEmitter();
  AcceptedEstimatesAnnouncer: EventEmitter<any> = new EventEmitter();
  PendingAcceptanceEstimateAnnouncer: EventEmitter<any> = new EventEmitter();
  EstimateRequestAnnouncer: EventEmitter<any> = new EventEmitter
  EstimateRequestsByVeIdAnnouncer: EventEmitter<any> = new EventEmitter
  invalidVEs
  validVEs


  constructor(private glowService: GlowService, private professionalGlowService: ProfessionalGlowService) { }



  ///---- for user accounts ----
  async getAllEstimateRequests(showProfessionalMeta?) {
    console.log(showProfessionalMeta)
    const resp = await this.glowService.findAllEstimateRequestsByUserId(showProfessionalMeta).toPromise()
    console.log(resp, 'resp')
    this.announceEstimates(resp)

  }

  async getAllProfessionalEstimateRequests() {
    return this.professionalGlowService.getAllEstimateRequestForProfessionals().toPromise().then((resp: any) => {
      this.announceProfessionalEstimateRequests(resp)
    }).catch((err) =>
      this.announceProfessionalEstimateRequests(err)
    )

  }

  getEstimateRequestsByVeIdAndState(veId, showProfessionalMeta) {
    this.glowService.findEstimateRequestsByVeIdAndState(veId, showProfessionalMeta).subscribe(resp => {
      this.announceEstimateRequestsByVeId(resp)
    })
  }


  announceEstimateRequestsByVeId(estimateRequests) {
    console.log(estimateRequests, 'er by veId')
    this.EstimateRequestsByVeIdAnnouncer.emit(estimateRequests)
  }
  announceEstimates(estimatesRequests) {
    console.log(estimatesRequests)
    this.EstimatesAnnouncer.emit(estimatesRequests)
  }


  ///---- for professional accounts ---- 

  //get snapshots per estimate request
  getSnapshotsByEstimateRequestId(id) {
    this.professionalGlowService.getSnapshotByEstimateId(id, true).subscribe((resp: any) => {
      this.announceSnapshots(resp.snapshots)
    })
  }

  announceSnapshots(snapshots) {
    this.SnapshotAnnouncer.emit(snapshots)

  }

  //get estimateRequest by estimateId
  getEstimateRequestById(estimateId) {
    this.professionalGlowService.getEstimateRequestByIdProfessional(estimateId).subscribe(resp => {
      this.announceEstimateRequest(resp)
    })
  }

  announceEstimateRequest(estimateRequest) {
    this.EstimateRequestAnnouncer.emit(estimateRequest)
  }

  //get estimate requests for professional by state
  async getAllEstimateRequestsByState(state) {
    const resp = await this.professionalGlowService.findAllEstimateRequestsByState(state).toPromise().then(resp => {
      if (state == 'estimate-request-accepted-by-professional') {
        this.announceAcceptedByProfessionalEstimates(resp)
      } else if (state == 'pending-professional-acceptance') {
        this.announcePendingAcceptanceEstimates(resp)
      }
    }).catch((err) =>
      this.announcePendingAcceptanceEstimates(err)
    )
  }


  //-----------
  announceAcceptedByProfessionalEstimates(estimates) {
    this.AcceptedEstimatesAnnouncer.emit(estimates)
  }

  announcePendingAcceptanceEstimates(estimates) {
    this.PendingAcceptanceEstimateAnnouncer.emit(estimates)
  }
  announceProfessionalEstimateRequests(estimates) {
    this.ProfessionalEstimatesAnnouncer.emit(estimates)
  }


  async validateVirtualEntityRequirements(ve) {
    let veRequirement;
    try {
      veRequirement = await this.glowService.validateVirtualEntityRequirements(ve.veId).toPromise();
      // console.log(veRequirement)
    } catch (e) {
      // console.warn(e)
    }
    if (veRequirement) {
      return veRequirement.requirements.every(x => x.valid) ? { valid: true, ...ve } : { valid: false, ...ve, ...veRequirement }
    }
  };


  async validateVirtualEntitites(virtualEntities: any) {
    console.log('validateVirtualEntitites', virtualEntities)

    let requirements = [];
    const lastRequirement = await virtualEntities.reduce((accumulatorPromise, ve) => {
      return accumulatorPromise.then(response => {
        if (response) requirements.push(response)
        // console.log(requirements)
        return this.validateVirtualEntityRequirements(ve);
      })
    }, Promise.resolve());
    if (lastRequirement) requirements.push(lastRequirement)
    // console.log(requirements)
    this.validVEs = [];
    this.invalidVEs = [];

    requirements.map(x => {
      // console.log(x)
      x.valid ? this.validVEs.push({ ...x }) : this.invalidVEs.push({ ...x })
    })
    console.log(this.validVEs)
    console.log('this.invalidVEs', this.invalidVEs)

    return this.invalidVEs


  }


  formatEstimateRequestState(state) {
    let stateObj = {
      name: '',
      colour: ''
    }

    switch (state) {
      case 'pending-professional-registration':
        stateObj.name = 'Pending Installer acceptance';
        stateObj.colour = 'warning';
        break;
      case 'pending-professional-acceptance':
        stateObj.name = 'Pending Installer acceptance';
        stateObj.colour = 'warning';
        break;
      case 'estimation-request-cancelled-professional-unfit':
        stateObj.name = 'Cancelled';
        stateObj.colour = 'danger';
        break;
      case 'estimate-request-rejected-by-professional':
        stateObj.name = 'Denied';
        stateObj.colour = 'danger';
        break;
      case 'estimate-request-rejected-by-professional':
        stateObj.name = 'Denied';
        stateObj.colour = 'danger';
        break;
      case 'estimate-request-accepted-by-professional':
        stateObj.name = 'Accepted request';
        stateObj.colour = 'success';
        break;
      case 'estimate-request-revoked-by-customer':
        stateObj.name = 'Customer revoked access';
        stateObj.colour = 'danger';
        break;
      case 'estimate-response-submitted':
        stateObj.name = 'Estimate received';
        stateObj.colour = 'success';
        break;
      case 'estimate-response-accepted':
        stateObj.name = 'Estimate sent';
        stateObj.colour = 'success';
        break;
      case 'estimate-response-user-questioned':
        stateObj.name = 'Questioned';
        stateObj.colour = 'warning';
        break;
      case 'estimate-response-declined':
        stateObj.name = 'Estimate declined';
        stateObj.colour = 'danger';
        break;
      case 'site-visit-arranged':
        stateObj.name = 'Site visit arranged';
        stateObj.colour = 'success';
        break;
      case 'quote-pending-user-acceptance':
        stateObj.name = 'Pending acceptance';
        stateObj.colour = 'warning';
        break;
      case 'quote-accepted':
        stateObj.name = 'Quote accepted';
        stateObj.colour = 'success';
        break;
      case 'installation-complete':
        stateObj.name = 'Installation complete';
        stateObj.colour = 'success';
        break;
      case 'pending-user-registration':
        stateObj.name = 'Pending acceptance';
        stateObj.colour = 'warning';
        break;
      case 'pending-user-eligibility':
        stateObj.name = 'Pending acceptance';
        stateObj.colour = 'warning';
        break;
      case 'pending-user-acceptance':
        stateObj.name = 'Pending acceptance';
        stateObj.colour = 'warning';
        break;
      case 'estimate-request-rejected-by-user':
        stateObj.name = 'Request denied';
        stateObj.colour = 'danger';
        break;
      case 'estimate-request-accepted-by-user':
        stateObj.name = 'Accepted request';
        stateObj.colour = 'success';
        break;
      case 'estimate-request-revoked-by-professional':
        stateObj.name = 'Installer revoked access';
        stateObj.colour = 'danger';
        break;
    }
    return stateObj;
  }


  getAllActiveEstimateRequests(resp) {
    let acceptedEstimateRequests = []
    this.activeEstimateRequestsStates.forEach(state => {
      resp.estimateRequests.forEach(estimateRequest => {
        if (state == estimateRequest.estimateRequestState) {
          acceptedEstimateRequests.push(estimateRequest)
        }
      })
    })
    return acceptedEstimateRequests
  }
}
