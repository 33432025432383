import { invalid } from '@angular/compiler/src/render3/view/util';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchService } from 'src/app/services/search.service';
import { StrapiService } from 'src/app/services/strapi.service';
import { Utils } from 'src/utils';
@Component({
  selector: 'app-glossary-searchbar',
  templateUrl: './glossary-searchbar.component.html',
  styleUrls: ['./glossary-searchbar.component.scss'],
})
export class GlossarySearchbarComponent implements OnInit {
  @Output() resultsChange = new EventEmitter();
  tagsToFilter: any[] = [];
  searchTerm: string = '';
  tags: any[];

  searchParametersForm: FormGroup;

  constructor(private searchService: SearchService, private strapiService: StrapiService, private fb: FormBuilder, private router: Router) {

  }

  ngOnInit() {
    this.initTagsForm()
    this.strapiService
      .getAllOfContentType('tags')
      .then((tags) => (this.tags = tags.filter((tag) => tag.attributes.isCommunity)));
  }

  initTagsForm() {
    this.searchParametersForm = this.fb.group({
      tags: [[], Validators.required],
      searchTerm: [''],
    });
  }

  async search() {
    const results = await this.searchService.filterContentWithTags('glossary-term', this.searchTerm, this.tagsToFilter);
    this.resultsChange.emit({
      glossaryTerms: results.map((result) => Utils.fromatSearchContent(result)),
      searchTerm: this.searchTerm,
      tagsToFilter: this.tagsToFilter,
    });
  }

  async redirectToGlossaryResults() {
    this.router.navigateByUrl(`/glossary?q[s]=${this.searchTerm}&q[tags]=${this.tagsToFilter.join(',')}`);
  }

  setTagsAndSearchTerm(tags: any[]) {
    const tagsWithoutNull = tags.filter((tag) => tag && (typeof tag === 'string' || tag.label));
    if (tags.length || tagsWithoutNull.length) {
      this.tagsToFilter = tagsWithoutNull.filter((tag) => typeof tag === 'string');
      this.searchTerm = tagsWithoutNull
        .filter((tag) => typeof tag != 'string')
        .map((tag) => tag.label)
        .join(' ');
    }
    return tagsWithoutNull;
  }

  setSearchParameters(searchParameters) {
  const tagsWithoutNull = this.setTagsAndSearchTerm(searchParameters);
    if (searchParameters.length || tagsWithoutNull.length) {
      this.searchParametersForm.setValue({ searchTerm: this.searchTerm, tags: this.tagsToFilter });
    }
  }

  setTagsToFilter(tags:string[]){
    this.tagsToFilter=tags
    this.searchParametersForm.setValue({tags:tags})
  }

  setSearchTerm(inputEvent) {
    this.searchTerm = inputEvent.target.value;
    if (!this.searchTerm) this.search();
  }
}
