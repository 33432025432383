import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CaseStudyService } from 'src/app/services/case-study.service';
import { GlowService } from 'src/app/services/glow.service';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';

@Component({
  selector: 'app-case-study-account-page',
  templateUrl: './case-study-account-page.component.html',
  styleUrls: ['./case-study-account-page.component.scss', '../pages.component.scss'],
})
export class CaseStudyAccountPageComponent implements OnInit {
  selectedService;
  category = 'summary';
  currentPage;
  pageTitle = 'Your case studies';
  loading: boolean = true;
  caseStudy;
  imageOrientation;
  categoryPage;
  allCaseStudies;
  navBackground;
  currentRoute;
  pages = [];
  heatPumpCaseStudy;
  locationImageUrl;
  innerWidth;
  routeSubscription: Subscription;
  childParamsSubscription: Subscription;
  @HostListener('window:resize', []) onResize() {
    this.innerWidth = window.innerWidth;
  }
  constructor(private glowService: GlowService, private caseStudyService: CaseStudyService, private serviceSelector: ServiceSelectorSvc, private router: Router, private route: ActivatedRoute) {
    this.routeSubscription = this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        if (!this.route.firstChild && this.allCaseStudies) {
          this.goToFirstCaseStudy();
        } else if (!this.allCaseStudies) {
          this.getAllCaseStudies();
        }
        if (this.route.firstChild) {
          this.childParamsSubscription = this.route.firstChild.params.subscribe((params) => {
            this.currentPage = params.caseStudy;
            this.loading = false;
          });
        }
      }
    });
  }
  ngOnInit() {
    this.onResize();
  }
  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.childParamsSubscription) {
      this.childParamsSubscription.unsubscribe();
    }
  }

  goToFirstCaseStudy() {
    this.currentPage = this.allCaseStudies[0].csRef;
    this.router.navigateByUrl('/pages/property/case-studies/' + this.allCaseStudies[0].csRef);
  }

  getAllCaseStudies() {
    this.loading = true;
    this.selectedService = this.serviceSelector.getSelectedService();
    this.glowService.getAllCaseStudiesByVe(this.selectedService.veId).subscribe(async (cs) => {
      this.allCaseStudies = cs;
      if (cs && cs.length) {
        this.goToFirstCaseStudy();
      }
      await this.createPages(cs);
    });
  }

  async createPages(caseStudies) {
    if (caseStudies && caseStudies.length) {
      await Promise.all(
        caseStudies.map(async (cs) => {
          // if (cs.classifier == 'heating_system.heat_pump') {
          // const caseStudy = this.getCaseStudy(cs);
          const caseStudy = await this.caseStudyService.fetchCaseStudyData(cs);
          this.pages.push({ path: 'pages/property/case-studies/' + caseStudy.csRef, title: cs.classifier.split('.').pop().split("_").join(" ").toUpperCase(), id: caseStudy.csRef });
          return caseStudy;
          // }
        })
      );
    }
    this.loading = false;
  }

  async getCaseStudy(cs) {
    return this.caseStudyService.fetchCaseStudyData(cs);
  }

  setImageOrientation(imageInfo) {
    if (imageInfo.height && imageInfo.width && imageInfo.height > imageInfo.width) {
      this.imageOrientation = 'portrait';
    } else {
      this.imageOrientation = 'landscape';
    }
  }

  onRouterOutletActivate(component) {
    component.isAccountView = true;
  }
}
