import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeatingSystemFormComponent } from './heating-system-form.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MicrogeneratorFormModule } from '../microgenerator-form/microgenerator-form.module';
import { FormTemplateModule } from '../form-template/form-template.module';
import { EnergyStorageFormModule } from '../energy-storage-form/energy-storage-form.module';
import { FormValidationMessageModule } from '../../form-validation-message/form-validation-message.module';
import { RadiatorSchematicSelectorModalModule } from '../../radiator-schematic-selector-modal/radiator-schematic-selector-modal.module';



@NgModule({
  declarations: [HeatingSystemFormComponent],
  imports: [
    CommonModule,
    SharedModule, MicrogeneratorFormModule, FormTemplateModule, EnergyStorageFormModule, FormValidationMessageModule, RadiatorSchematicSelectorModalModule
  ],
  exports: [HeatingSystemFormComponent],

})
export class HeatingSystemFormModule { }
