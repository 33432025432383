import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-professional-register-stepper',
  templateUrl: './professional-register-stepper.component.html',
  styleUrls: ['./professional-register-stepper.component.scss']
})
export class ProfessionalRegisterStepperComponent implements OnInit {
  innerWidth;
  // @HostListener('window:resize', []) onResize() {
  //   // checks size of window to view specific menu
  //   this.innerWidth = window.innerWidth;
  //   if (this.innerWidth <= 360) {
  //     this.removeLinesOnSmallerScreens()
  //   }
  // }
  constructor() { }
  @Input() state;
  ngOnInit() {

  }
  // removeLinesOnSmallerScreens() {
  //   const lineOne = document.getElementById('line-one')
  //   const lineTwo = document.getElementById('line-two')
  //   lineOne.remove()
  //   lineTwo.remove()

  // }

}
