
import { Injectable } from '@angular/core';
import { ResourceTypeConfigService } from './configuration.service';
import { tPeriodRangeMapping, tResourceAvailability, tBusinessCaseTypeMapping, tVeTypeAdditionalInfo, tDataStreamIconMapping } from './ui.typings';
import { tVE } from './glow.typings';
import { tEnergyCostResourceTypeMapping, tLoadingElement, tLoadingElementWithSuccess, tLoadingElementWithSuccessAndErrorObj } from './app-config.typings';
import { tResourceTypeMapping } from './configuration.typings';
import { ConfigService } from './app-config.service';
import { environment } from '../../environments/environment';

@Injectable()
export class CustomUIConfigService {

  //This service classifies the resource types as are required for the vatious vetypes
  periodRangeMapping: tPeriodRangeMapping | undefined;
  businessCasePlanTypes: tBusinessCaseTypeMapping[] = [];

  dataStreamIconsMapping: tDataStreamIconMapping | undefined;
  // resourceAvailability: tResourceAvailability;
  //   resourceTypeMappings: tResourceTypeSolarMapping;
  veTypeUIInfo: any;
  veTypeAdditionalInfo: tVeTypeAdditionalInfo | undefined;

  resourceTypeMappings: tResourceTypeMapping | undefined;
  resourceEnergyCostMapping: tEnergyCostResourceTypeMapping[] = [];

  constructor(
    private configService: ConfigService,
    private resourceTypeConfiguration: ResourceTypeConfigService) {

  }

  public initialiseLoadingBooleans(loading = true): tLoadingElement {
    return {
      errorString: '',
      loaded: false,
      loadError: false,
      loading: loading,
    }
  }

  public initialiseLoadingBooleansWithLoadedMsg(loading = true): tLoadingElementWithSuccess {
    return { ...this.initialiseLoadingBooleans(loading), loadedMessage: '' };
  }

  public initialiseLoadingBooleansWithLoadedMsgErrorObj(loading = true): tLoadingElementWithSuccessAndErrorObj {
    return { ...this.initialiseLoadingBooleansWithLoadedMsg(loading), loadedMessage: '', errorObj: {} };
  }

  getPeriodRangeConfigurations() {

    this.periodRangeMapping = {
      "minute": {
        "range": "day",
        "apiFormat": "PT1M",
        "datePipeFormat": "dd-MM-yyyy HH:mm",
        refreshRate: 5*60*1000 // 5 minutes
      },
      "30minute": {
        "range": "day",
        "apiFormat": "PT30M",
        "datePipeFormat": "dd-MM-yyyy HH:mm",
        refreshRate:  5*60*1000 // 5 minutes
      },
      "hour": {
        "range": "day",
        "apiFormat": "PT1H",
        "datePipeFormat": "dd-MM-yyyy HH:mm",
        refreshRate: 5*60*1000 // 5 minutes
      },
      "day": {
        "range": "week",
        "apiFormat": "P1D",
        "datePipeFormat": "EEE dd MMM y",
        refreshRate:  30*60*1000 // 30 minutes
      },
      "week": {
        "range": "month",
        "apiFormat": "P1W",
        "datePipeFormat": "EEE dd MMM y",
        refreshRate: 30*60*1000 // 30 minutes
      },
      "weekday": {
        "range": "month",
        "apiFormat": "P1W",
        "datePipeFormat": "EEE dd MMM y",
        refreshRate: 30*60*1000 // 30 minutes
      },
      "month": {
        "range": "year",
        "apiFormat": "P1M",
        "datePipeFormat": "MMM y",
        refreshRate: 30*60*1000 // 30 minutes
      }
    }
    return this.periodRangeMapping;
  };

  getPeriodRangeConsumptionConfigurations() {

    this.periodRangeMapping = {
      "minute": {
        "range": "day",
        "apiFormat": "PT1M",
        "datePipeFormat": "dd-MM-yyyy HH:mm",
        refreshRate: 5*60*1000 // 5 minutes
      },
      "30minute": {
        "range": "day",
        "apiFormat": "PT30M",
        "datePipeFormat": "dd-MM-yyyy HH:mm",
        refreshRate:  5*60*1000 // 5 minutes
      },
      "hour": {
        "range": "day",
        "apiFormat": "PT1H",
        "datePipeFormat": "dd-MM-yyyy HH:mm",
        refreshRate: 5*60*1000 // 5 minutes
      },
      "day": {
        "range": "day",
        "apiFormat": "PT1H",
        "datePipeFormat": "dd-MM-yyyy HH:mm",
        refreshRate: 5*60*1000 // 5 minutes
      },
      "week": {
        "range": "week",
        "apiFormat": "P1D",
        "datePipeFormat": "EEE dd MMM y",
        refreshRate:  30*60*1000 // 30 minutes
      },
      "weekday": {
        "range": "week",
        "apiFormat": "P1D",
        "datePipeFormat": "EEE dd MMM y",
        refreshRate:  30*60*1000 // 30 minutes
      },
      "month": {
        "range": "month",
        "apiFormat": "P1W",
        "datePipeFormat": "EEE dd MMM y",
        refreshRate: 30*60*1000 // 30 minutes
      }
    }
    return this.periodRangeMapping;
  };

  getResourceClassifierConfigurations() {

    return {
      "electricity.consumption": {
        showPT1M: true,
        resourceTypesPriority: ['ea02304a-2820-4ea0-8399-f1d1b430c3a0','bb0b2429-7776-44da-a3b4-f87ce3a47dd0', '7969e3c4-66cf-4568-be29-4913023faa7e']
      },
      "electricity.consumption.cost": {
        showPT1M: false
      },
      "gas.consumption": {
        showPT1M: false
      },
      "gas.consumption.cost": {
        showPT1M: false
      },
      "temperature": {
        showPT1M: false
      }
    }
  };

  public loadEnergyCostResourceMapping(): tEnergyCostResourceTypeMapping[] {

    // const chartColours = [...environment.charts.colour.energy]
    // const [df1, df2, df3, df4, df5, df6] = chartColours
    this.resourceEnergyCostMapping = [
      {
        energy: "ea02304a-2820-4ea0-8399-f1d1b430c3a0",
        cost: "b4158501-a678-484a-837a-874194d3bd48",
        label: "smart electicity",
        count: 0,
        defaultColour: '#CC4528',
        resourceListings: [],
      },
      {
        energy: "672b8071-44ff-4f23-bca2-f50c6a3ddd02",
        cost: "32d9821b-34fd-46d1-9ba1-56259afd4734",
        label: "smart gas",
        defaultColour: "#154e72",
        resourceListings: [],
        count: 0

      },
      {
        energy: "bb0b2429-7776-44da-a3b4-f87ce3a47dd0",
        cost: "b3c80261-f9d3-4453-a6e7-5fd02e89fc67",
        label: "ot total power",
        defaultColour: '#E77C2B',
        resourceListings: [],
        count: 0
      },
      {
        energy: "b5791d9a-6f58-4372-bae4-ae79d1cc0712",
        cost: "c58b4039-3cdf-425a-873d-f9e82d8c4009",
        label: "ot power 1",
        count: 0,
        defaultColour: '#8bbc21',
        resourceListings: [],
      },
      {
        energy: "23e9084a-19d4-4849-ab78-bd22753457f5",
        cost: "b8a8b8ca-ee59-4fc9-97c1-77dfa6bd9b46",
        label: "ot power 2",
        count: 0,
        defaultColour: '#ffbd33',
        resourceListings: []
      },
      {
        energy: "9ec2bd19-bfa3-430b-9f08-9550c8cd4bbe",
        cost: "177a0707-154b-4666-be59-2a5eff88a160",
        label: "ot power 3",
        count: 0,
        defaultColour: '#9f9f9f',
        resourceListings: []
      }
    ]

    return this.resourceEnergyCostMapping;
  };

  getVeTypeAdditionalInfoForm(): tVeTypeAdditionalInfo {
    this.veTypeAdditionalInfo = {
      "retail": {
        appliances: {
          group: {  isInGroup: false, reference: "appliances", name: "Appliances" },
          categoryInfo: { reference: "appliances", name: "Appliances"},
          "categories": [
            // { displayKey: "Extractor fans", reference: "extractorFans", values: [{ reference: "capacity", displayKey: "Capacitiy in W", valueType: "number" }, { reference: "energyRating", displayKey: "Energy Rating", valueType: "text" }] }
          ]
        },
        lighting: {
          "group": { isInGroup: false, reference: "lighting", name: "Lighting" },
          categoryInfo: { reference: "lighting", name: "Lighting"},
          "categories": [
            { displayKey: "LED spot lights", reference: "ledSpotLights", values: [{ reference: "capacityPerUnit", displayKey: "Capacitiy of bulb in W", valueType: "number" }, { reference: "numberOfUnits", displayKey: "Capacitiy in W per unit", valueType: "number" }] },
            { displayKey: "Neon stip lights", reference: "neonStripLights", values: [{ reference: "capacityPerUnit", displayKey: "Capacitiy of bulb in W", valueType: "number" }, { reference: "numberOfUnits", displayKey: "Capacitiy in W per unit", valueType: "number" }] },
            { displayKey: "Other", reference: "otherLighting", values: [{ reference: "lightingType", displayKey: "Lighting Type", valueType: "number" }, { reference: "capacityPerUnit", displayKey: "Capacitiy of bulb in W", valueType: "number" }, { reference: "numberOfUnits", displayKey: "Capacitiy in W per unit", valueType: "number" }] }
          ]
        },
        gasHeating: {
          "group": {  isInGroup: false, reference: "heating", name: "Heating" },
          categoryInfo: { reference: "gasHeating", name: "Gas Heating"},
          "categories": [
            { displayKey: "Wall heaters / radiators", reference: "wallHeaterRadiators", values: [{ reference: "capacity", displayKey: "Capacitiy in W", valueType: "number" }] }
          ]
        },
        elecHeating: {
          "group": {  isInGroup: false, reference: "heating", name: "Heating" },
          categoryInfo: { reference: "elecHeating", name: "Electricity Heating"},
          "categories": [
            { displayKey: "Wall heaters / radiators", reference: "wallHeaterRadiators", values: [{ reference: "capacity", displayKey: "Capacitiy in W", valueType: "number" }] },
            { displayKey: "Fan heaters", reference: "fanHeaters", values: [{ reference: "capacity", displayKey: "Capacitiy in W", valueType: "number" }, { reference: "energyRating", displayKey: "Energy Rating", valueType: "text" }] }
          ]
        },
        otherHeating: {
          "group": {  isInGroup: false, reference: "otherHeating", name: "Other" },
          categoryInfo: { reference: "otherHeating", name: "Heating"},
          "categories": [
            { displayKey: "Other", reference: "otherHeating",  values: [{ reference: "heatingType", displayKey: "Heating Type", valueType: "string" }, { reference: "capacity", displayKey: "Capacitiy in W", valueType: "number" } ] }
          ]
        },
        cooling: {
          "group": {  isInGroup: false, reference: "cooling", name: "Cooling" },
          categoryInfo: { reference: "cooling", name: "Cooling"},
          "categories": [
            { displayKey: "Other", reference: "airConditioning",  values: [{ reference: "capacity", displayKey: "Capacitiy in W", valueType: "number" }, { reference: "energyRating", displayKey: "Energy Rating", valueType: "text" }] }
          ]
        }
      },
      "hospitality": {
        appliances: {
          group: {  isInGroup: false, reference: "appliances", name: "Appliances" },
          categoryInfo: { reference: "appliances", name: "Appliances"},
          "categories": [
            { displayKey: "Extractor fans", reference: "extractorFans", values: [{ reference: "capacity", displayKey: "Capacitiy in W", valueType: "number" }, { reference: "energyRating", displayKey: "Energy Rating", valueType: "text" }] },
            { displayKey: "Fridge", reference: "fridge", values: [{ reference: "capacity", displayKey: "Capacitiy in W", valueType: "number" }, { reference: "energyRating", displayKey: "Energy Rating", valueType: "text" }, { reference: "fidgeType", displayKey: "Fridge Type", valueType: "enum", values: ["walk-in", "normal"] }] },
            { displayKey: "Freezer", reference: "freezer", values: [{ reference: "capacity", displayKey: "Capacitiy in W", valueType: "number" }, { reference: "energyRating", displayKey: "Energy Rating", valueType: "text" }, { reference: "freezerType", displayKey: "Freezer Type", valueType: "enum", values: ["walk-in", "normal"] }] },
            { displayKey: "Ice Machine", reference: "iceMachine", values: [{ reference: "capacity", displayKey: "Capacitiy in W", valueType: "number" }, { reference: "energyRating", displayKey: "Energy Rating", valueType: "text" }] },
            { displayKey: "Dishwasher", reference: "dishWasher", values: [{ reference: "capacity", displayKey: "Capacitiy in W", valueType: "number" }, { reference: "energyRating", displayKey: "Energy Rating", valueType: "text" }] },
            { displayKey: "Oven", reference: "oven", values: [{ reference: "capacity", displayKey: "Capacitiy in W", valueType: "number" }, { reference: "energyRating", displayKey: "Energy Rating", valueType: "text" }, { reference: "energyType", displayKey: "Energy Type", valueType: "text", values: ["electricity", "gas", "coal", "pellet"] }] },
            { displayKey: "Grill", reference: "grill", values: [{ reference: "capacity", displayKey: "Capacitiy in W", valueType: "number" }, { reference: "energyRating", displayKey: "Energy Rating", valueType: "text" }, { reference: "energyType", displayKey: "Energy Type", valueType: "text", values: ["electricity", "gas", "coal", "pellet"] }] },
            { displayKey: "Hobs", reference: "hobs", values: [{ reference: "capacity", displayKey: "Capacitiy in W", valueType: "number" }, { reference: "energyRating", displayKey: "Energy Rating", valueType: "text" }, { reference: "energyType", displayKey: "Energy Type", valueType: "text", values: ["electricity", "gas", "coal", "pellet"] }] },
            { displayKey: "Deep Fryer", reference: "deepFryer", values: [{ reference: "capacity", displayKey: "Capacitiy in W", valueType: "number" }, { reference: "energyRating", displayKey: "Energy Rating", valueType: "text" }, { reference: "energyType", displayKey: "Energy Type", valueType: "text", values: ["electricity", "gas"] }] },
            { displayKey: "Microwave", reference: "microwave", values: [{ reference: "capacity", displayKey: "Capacitiy in W", valueType: "number" }, { reference: "energyRating", displayKey: "Energy Rating", valueType: "text" }] },
            { displayKey: "Steamer", reference: "steamer", values: [{ reference: "capacity", displayKey: "Capacitiy in W", valueType: "number" }, { reference: "energyRating", displayKey: "Energy Rating", valueType: "text" }] },
          ]
        },
        lighting: {
          "group": { isInGroup: false, reference: "lighting", name: "Lighting" },
          categoryInfo: { reference: "lighting", name: "Lighting"},
          "categories": [
            { displayKey: "LED spot lights", reference: "ledSpotLights", values: [{ reference: "capacityPerUnit", displayKey: "Capacitiy of bulb in W", valueType: "number" }, { reference: "numberOfUnits", displayKey: "Capacitiy in W per unit", valueType: "number" }] },
            { displayKey: "Neon stip lights", reference: "neonStripLights", values: [{ reference: "capacityPerUnit", displayKey: "Capacitiy of bulb in W", valueType: "number" }, { reference: "numberOfUnits", displayKey: "Capacitiy in W per unit", valueType: "number" }] },
            { displayKey: "Other", reference: "otherLighting", values: [{ reference: "lightingType", displayKey: "Lighting Type", valueType: "number" }, { reference: "capacityPerUnit", displayKey: "Capacitiy of bulb in W", valueType: "number" }, { reference: "numberOfUnits", displayKey: "Capacitiy in W per unit", valueType: "number" }] }
          ]
        },
        gasHeating: {
          "group": {  isInGroup: false, reference: "heating", name: "Heating" },
          categoryInfo: { reference: "gasHeating", name: "Gas Heating"},
          "categories": [
            { displayKey: "Wall heaters / radiators", reference: "wallHeaterRadiators", values: [{ reference: "capacity", displayKey: "Capacitiy in W", valueType: "number" }] }
          ]
        },
        elecHeating: {
          "group": {  isInGroup: false, reference: "heating", name: "Heating" },
          categoryInfo: { reference: "elecHeating", name: "Electricity Heating"},
          "categories": [
            { displayKey: "Wall heaters / radiators", reference: "wallHeaterRadiators", values: [{ reference: "capacity", displayKey: "Capacitiy in W", valueType: "number" }] },
            { displayKey: "Fan heaters", reference: "fanHeaters", values: [{ reference: "capacity", displayKey: "Capacitiy in W", valueType: "number" }, { reference: "energyRating", displayKey: "Energy Rating", valueType: "text" }] }
          ]
        },
        otherHeating: {
          "group": {  isInGroup: false, reference: "otherHeating", name: "Other" },
          categoryInfo: { reference: "otherHeating", name: "Heating"},
          "categories": [
            { displayKey: "Other", reference: "otherHeating",  values: [{ reference: "heatingType", displayKey: "Heating Type", valueType: "string" }, { reference: "capacity", displayKey: "Capacitiy in W", valueType: "number" } ] }
          ]
        },
        cooling: {
          "group": {  isInGroup: false, reference: "cooling", name: "Cooling" },
          categoryInfo: { reference: "cooling", name: "Cooling"},
          "categories": [
            { displayKey: "Other", reference: "airConditioning",  values: [{ reference: "capacity", displayKey: "Capacitiy in W", valueType: "number" }, { reference: "energyRating", displayKey: "Energy Rating", valueType: "text" }] }
          ]
        }
      },
      "spaTreatment": {
        appliances: {
          group: {  isInGroup: false, reference: "appliances", name: "Appliances" },
          categoryInfo: { reference: "appliances", name: "Appliances"},
          "categories": [
            // { displayKey: "Extractor fans", reference: "extractorFans", values: [{ reference: "capacity", displayKey: "Capacitiy in W", valueType: "number" }, { reference: "energyRating", displayKey: "Energy Rating", valueType: "text" }] }
          ]
        },
        lighting: {
          "group": { isInGroup: false, reference: "lighting", name: "Lighting" },
          categoryInfo: { reference: "lighting", name: "Lighting"},
          "categories": [
            { displayKey: "LED spot lights", reference: "ledSpotLights", values: [{ reference: "capacityPerUnit", displayKey: "Capacitiy of bulb in W", valueType: "number" }, { reference: "numberOfUnits", displayKey: "Capacitiy in W per unit", valueType: "number" }] },
            { displayKey: "Neon stip lights", reference: "neonStripLights", values: [{ reference: "capacityPerUnit", displayKey: "Capacitiy of bulb in W", valueType: "number" }, { reference: "numberOfUnits", displayKey: "Capacitiy in W per unit", valueType: "number" }] },
            { displayKey: "Other", reference: "otherLighting", values: [{ reference: "lightingType", displayKey: "Lighting Type", valueType: "number" }, { reference: "capacityPerUnit", displayKey: "Capacitiy of bulb in W", valueType: "number" }, { reference: "numberOfUnits", displayKey: "Capacitiy in W per unit", valueType: "number" }] }
          ]
        },
        gasHeating: {
          "group": {  isInGroup: false, reference: "heating", name: "Heating" },
          categoryInfo: { reference: "gasHeating", name: "Gas Heating"},
          "categories": [
            { displayKey: "Wall heaters / radiators", reference: "wallHeaterRadiators", values: [{ reference: "capacity", displayKey: "Capacitiy in W", valueType: "number" }] }
          ]
        },
        elecHeating: {
          "group": {  isInGroup: false, reference: "heating", name: "Heating" },
          categoryInfo: { reference: "elecHeating", name: "Electricity Heating"},
          "categories": [
            { displayKey: "Wall heaters / radiators", reference: "wallHeaterRadiators", values: [{ reference: "capacity", displayKey: "Capacitiy in W", valueType: "number" }] },
            { displayKey: "Fan heaters", reference: "fanHeaters", values: [{ reference: "capacity", displayKey: "Capacitiy in W", valueType: "number" }, { reference: "energyRating", displayKey: "Energy Rating", valueType: "text" }] }
          ]
        },
        otherHeating: {
          "group": {  isInGroup: false, reference: "otherHeating", name: "Other" },
          categoryInfo: { reference: "otherHeating", name: "Heating"},
          "categories": [
            { displayKey: "Other", reference: "otherHeating",  values: [{ reference: "heatingType", displayKey: "Heating Type", valueType: "string" }, { reference: "capacity", displayKey: "Capacitiy in W", valueType: "number" } ] }
          ]
        },
        cooling: {
          "group": {  isInGroup: false, reference: "cooling", name: "Cooling" },
          categoryInfo: { reference: "cooling", name: "Cooling"},
          "categories": [
            { displayKey: "Other", reference: "airConditioning",  values: [{ reference: "capacity", displayKey: "Capacitiy in W", valueType: "number" }, { reference: "energyRating", displayKey: "Energy Rating", valueType: "text" }] }
          ]
        }
      }
    }
    return this.veTypeAdditionalInfo
  }

  public loadDataStreamTypeIcons(): tDataStreamIconMapping {
    this.dataStreamIconsMapping = {
      
        electricity: {
          classifiers: ['electricity.consumption', 'electricity.consumption.cost'],
          icon: 'lightbulb'
        },
        gas: {
          classifiers: ['gas.consumption', 'gas.consumption.cost'],
          icon: 'flame'
        },
        temperature: {
          classifiers: ["temperature"],
          icon: 'thermometer'
        },
        relativeHumidity: {
          classifiers: ["relative_humidity"],
          icon: 'cloud'
        },
        generation: {
          classifiers: ["electricity.generation"],
          icon: 'sun'
        },
        diversion: {
          classifiers: ["electricity.diversion"],
          icon: "battery"
        },
        import: {
          classifiers: ["electricity.import"],
          icon: 'rewind'
        },
        export: {
          classifiers: ["electricity.export"],
          icon: 'fast-foward'
        },
        illuminance: {
          classifiers: ["illuminance"],
          icon: 'eye'
        },
      
    }
    return this.dataStreamIconsMapping;
  };

  setResourceAvailability(selectedVE: tVE): tResourceAvailability {
    this.resourceTypeMappings = this.resourceTypeConfiguration.loadResourceTypeMappings();

    var resourceAvailability = {
      type: {
        electricity: {
          type: "electricity",
          availability: false,
          function: 'sum',
          resourceListings: [],
          resourceIds: [],
          resourceTypeIds: []
        },
        consumption: {
          type: "consumption",
          availability: false,
          function: 'sum',
          resourceListings: [],
          resourceIds: [],
          resourceTypeIds: []
        },
        gas: {
          type: "electricity",
          availability: false,
          function: 'sum',
          resourceListings: [],
          resourceIds: [],
          resourceTypeIds: []
        },
        generation: {
          type: "generation",
          availability: false,
          function: 'sum',
          resourceListings: [],
          resourceIds: [],
          resourceTypeIds: []
        },
        diversion: {
          type: "diversion",
          availability: false,
          function: 'sum',
          resourceListings: [],
          resourceIds: [],
          resourceTypeIds: []
        },
        import: {
          type: "import",
          availability: false,
          function: 'sum',
          resourceListings: [],
          resourceIds: [],
          resourceTypeIds: []
        },
        export: {
          type: "export",
          availability: false,
          function: 'sum',
          resourceListings: [],
          resourceIds: [],
          resourceTypeIds: []
        },
        temperature: {
          type: "temperature",
          availability: false,
          function: 'avg',
          resourceListings: [],
          resourceIds: [],
          resourceTypeIds: []
        },
        relativeHumidity: {
          type: "relativeHumidity",
          availability: false,
          function: 'avg',
          resourceListings: [],
          resourceIds: [],
          resourceTypeIds: []
        },
        daylight: {
          type: "daylight",
          availability: false,
          function: 'sum',
          resourceListings: [],
          resourceIds: [],
          resourceTypeIds: []
        }
      },
      dimension: {
        energy: {
          type: "energy",
          availability: false,
          function: 'sum',
          resourceListings: [],
          resourceIds: [],
          resourceTypeIds: []
        },
        cost: {
          type: "cost",
          availability: false,
          function: 'sum',
          resourceListings: [],
          resourceIds: [],
          resourceTypeIds: []
        }
      }
    }

    for (let dataStreamType in resourceAvailability.type) {
      // Step 1. Get all neccessary resource info
      // console.log(dataStreamType)

      for (let i = 0; i < selectedVE.resources.length; i++) {
        // // console.log(resourceAvailability.type.hasOwnProperty(dataStreamType))
        if (resourceAvailability.type.hasOwnProperty(dataStreamType)) {
          // // console.log(selectedVE.resources[i].resourceTypeId, dataStreamType )
          if (this.resourceTypeMappings.dataStreamType[dataStreamType].indexOf(selectedVE.resources[i].resourceTypeId) > -1) {
            resourceAvailability.type[dataStreamType].availability = true;
            resourceAvailability.type[dataStreamType].resourceIds.push(selectedVE.resources[i].resourceId);
            resourceAvailability.type[dataStreamType].resourceTypeIds.push(selectedVE.resources[i].resourceTypeId);
          }
        }

      }


    }
    for (let dataStreamType in resourceAvailability.dimension) {
      // Step 1. Get all neccessary resource info
      // // console.log(dataStreamType)
      for (let i = 0; i < selectedVE.resources.length; i++) {
        if (resourceAvailability.dimension.hasOwnProperty(dataStreamType)) {
          if (this.resourceTypeMappings.dimension[dataStreamType].indexOf(selectedVE.resources[i].resourceTypeId) > -1) {
            resourceAvailability.dimension[dataStreamType].availability = true;
            resourceAvailability.dimension[dataStreamType].resourceIds.push(selectedVE.resources[i].resourceId);
            resourceAvailability.dimension[dataStreamType].resourceTypeIds.push(selectedVE.resources[i].resourceTypeId);
            resourceAvailability.dimension[dataStreamType].resourceListings.push(selectedVE.resources[i]);

          }
        }
      }
    }
    return resourceAvailability;

  }

  setResourceEnergyCostGroups(resourceAvailability: tResourceAvailability): tEnergyCostResourceTypeMapping[] {
    // console.log(resourceAvailability)
    if (resourceAvailability) {
      var energyResourceListings = resourceAvailability.dimension.energy.resourceListings;
      var costsResourceListings = resourceAvailability.dimension.cost.resourceListings;

      this.resourceEnergyCostMapping = this.loadEnergyCostResourceMapping();

      //add energy resource listsing to groups
      for (let i = 0; i < energyResourceListings.length; i++) {
        var resourceTypeId = energyResourceListings[i].resourceTypeId;

        for (let j = 0; j < this.resourceEnergyCostMapping.length; j++) {

          var resourceGroupElem = this.resourceEnergyCostMapping[j];
          if (resourceTypeId === resourceGroupElem.energy) {

            this.resourceEnergyCostMapping[j].resourceListings.push(JSON.parse(JSON.stringify(energyResourceListings[i])));
            break;
          }

        }
      }
      //add cost resource listsing to groups

      for (let i = 0; i < costsResourceListings.length; i++) {
        var resourceTypeId = costsResourceListings[i].resourceTypeId;

        for (let j = 0; j < this.resourceEnergyCostMapping.length; j++) {
          if (resourceTypeId === this.resourceEnergyCostMapping[j].cost) {
            this.resourceEnergyCostMapping[j].resourceListings.push(costsResourceListings[i]);
            break;
          }

        }
      }
    }


    return this.resourceEnergyCostMapping;
  }

  getBusinessCasePlanTypeInfo(): tBusinessCaseTypeMapping[] {
    // this return an object that informs how the form should react and also provides some placeholder data (i.e. breakeven)

    this.businessCasePlanTypes = [
      {
        type: "LightingUpgrade",
        name: "Lighting Upgrade",
        formOptions: {
          existingUnitType: {
            active: true,
            options: ["Halogen bulbs 100W", "Halogen bulbs 50W"]
          },
          newUnitType: {
            active: true,
            options: ["LED bulbs 10W", "LED bulbs 5W"]
          }
        },
        breakeven: 450, //15 months,
        outcome: {
          yAxisTitle: "Consumption [£]"
        },
        text: "Starting on '{{businessCase.startDate | date: 'EEEE dd MMMM y'}}', the works will approximately last '{{businessCase.duration}}' days at the location '{{businessCase.virtualEntity.name}}', focusing on '{{indexedBusinessCasePlanTypes[businessCase.businessCaseType].name}}'. The plan involves changing '{{businessCase.unitNumber}}' units of '{{businessCase.existingUnitType}}' to '{{businessCase.newUnitType}}' due to '{{businessCase.projectRationale}}', with a hypothesis of '{{businessCase.projectHypothesis}}' at a cost of '{{businessCase.projectCost  | currency:'GBP':'symbol'}}'.",
        comparisonText: {
          units: "Replacing  '{{unitNumber}}' units of '{{existingUnitType}}' to '{{newUnitType}}'"
        }
      },
      {
        type: "SolarPVInstallation",
        name: "Solar PV Installation",
        formOptions: {
          existingUnitType: {
            active: false,
            options: []
          },
          newUnitType: {
            active: true,
            options: ["Monocrystalline PV panels", "Polycrystalline PV panels", "Thin-Film Solar Panels"]
          }
        },
        breakeven: 3650, //10 years 
        outcome: {
          yAxisTitle: "Generation [£]"
        },
        text: "Starting on '{{businessCase.startDate | date: 'EEEE dd MMMM y'}}', the works will approximately last '{{businessCase.duration}}' days at the location '{{businessCase.virtualEntity.name}}', focusing on '{{indexedBusinessCasePlanTypes[businessCase.businessCaseType].name}}'. The plan involves installing '{{businessCase.unitNumber}}' kWh of '{{businessCase.newUnitType}}' due to '{{businessCase.projectRationale}}', with a hypothesis of '{{businessCase.projectHypothesis}}' at a cost of '{{businessCase.projectCost | currency:'GBP':'symbol'}}'.",
        comparisonText: {
          units: "Installing '{{unitNumber}}' kWh of '{{newUnitType}}'"
        }
      },
      // {å
      //   type: "SolarThermalHeating",
      //   name: "Solar Thermal Heating",
      //   formOptions: {
      //     existingUnitType:{
      //       active: false,
      //       options: []
      //     },
      //     newUnitType: {
      //       active: true,
      //       options: ["Evacuated Tube", "Flat Plate", "Batch"]
      //     }
      //   },
      //   breakeven: 1825 //5 years         
      // }
    ];

    return this.businessCasePlanTypes;
  };

};




