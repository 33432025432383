import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { keyFor } from 'core-js/fn/symbol';
import { HeatingSystemService } from 'src/app/services/heating-system.service';
import { ProfessionalGlowService } from 'src/app/services/professional-glow.service';
import { StrapiService } from 'src/app/services/strapi.service';

@Component({
  selector: 'app-find-a-professional',
  templateUrl: './find-a-professional.component.html',
  styleUrls: ['./find-a-professional.component.scss']
})
export class FindAProfessionalComponent implements OnInit {
  technologies = ['Air source heat pump', 'Ground water source heat pump', 'Solar assisted heat pump', 'Ground source heat pump', 'Gas absorption heat pump', 'Exhaust air heat pump']
  filteredValues = []
  installerForm: FormGroup
  heatPumpManufacturerList: Array<object>
  listOfProfessionals = []
  errorMessage: string
  showError: boolean = false;
  pagination;
  count = 0
  postcode: String;
  relatedContent = []
  skills = ['System design', "System installation"]
  constructor(private fb: FormBuilder, private heatingSystemService: HeatingSystemService,
    private professionalGlowService: ProfessionalGlowService, private strapiService: StrapiService) {
    this.getHeatPumpBrands()
  }

  ngOnInit() {
    this.initInstallerForm()
    this.getRelatedArticles()
  }

  initInstallerForm() {
    const group = {
      // brands: ['', []],
      postcode: ['', [Validators.required]],
      boilerUpgradeScheme: ['', []],
      MCSCertified: ['', []],
      technologies: new FormGroup(this.getTechnologiesFormGroup()),
      skills: new FormGroup(this.getSkillsFormGroup()),
      glowInstaller: ['', []]
    };

    this.installerForm = this.fb.group(group);
  }


  getTechnologiesFormGroup() {
    const formGroupFields = {};
    this.technologies.forEach(tech => {
      formGroupFields[tech] = new FormControl("");
    })

    return formGroupFields;
  }

  getSkillsFormGroup() {
    const formGroupFields = {};
    this.skills.forEach(skill => {
      formGroupFields[skill] = new FormControl("");
    })

    return formGroupFields;
  }

  async getHeatPumpBrands() {
    const resp = await this.heatingSystemService.getHeatPumps(true)
    const manufacturers = await this.heatingSystemService.getManufacturersFromArray(resp)
    const formattedManufacturers = manufacturers.map(x => x.name)
    this.heatPumpManufacturerList = formattedManufacturers
  }

  search() {
    delete this.pagination
    this.listOfProfessionals = []
    this.findInstallers()

  }


  findInstallers() {
    this.showError = false
    const filteredValues = []
    let technologies = []
    let skills = []
    const techFormGroup = this.installerForm.get('technologies') as FormGroup
    const skillsFormGroup = this.installerForm.get('skills') as FormGroup
    if (techFormGroup.controls) {
      this.technologies.forEach(tech => {
        if (techFormGroup.controls[tech].value == true) {
          technologies.push(tech)
        }
      })
    }

    if (skillsFormGroup.controls) {
      this.skills.forEach(skill => {
        if (skillsFormGroup.controls[skill].value == true) {
          skills.push(skill)
        }

      })

    }
    this.postcode = this.installerForm.value.postcode.replace(/^\s+|\s+$/g, "")
    const data = {
      "postcode": this.postcode,
      "technologies": technologies,
      "skills": skills,
      "boilerUpgradeScheme": this.installerForm.value.boilerUpgradeScheme,
      "mcsCertificate": this.installerForm.value.MCSCertified,
      "pagination": this.pagination ? this.pagination : {}
    }
    this.getInstallers(data)
    if (this.installerForm.value.MCSCertified) {
      filteredValues.push({ name: 'MCS Certified', key: 'MCSCertified' })
    }
    if (this.installerForm.value.boilerUpgradeScheme) {
      filteredValues.push({ name: 'Boiler Upgrade Scheme', key: 'boilerUpgradeScheme' })
    }

    technologies.length ? filteredValues.push({ name: technologies, key: 'technologies' }) : ''
    skills.length ? filteredValues.push({ name: skills, key: 'skills' }) : ''


    this.filteredValues = filteredValues
  
  }





  removeTag(tag, key?, type?) {
    if (type == 'technology') {
      const formGroup = this.installerForm.get('technologies') as FormGroup
      formGroup.controls[key].setValue(false)
      this.search()
    } else if (type == 'skills') {
      const formGroup = this.installerForm.get('skills') as FormGroup
      formGroup.controls[key].setValue(false)
      this.search()
    } else {
      this.installerForm.get(tag).setValue('')
      this.search()
    }
  }


  getInstallers(data) {
    // this.listOfProfessionals = []
    // console.log(this.listOfProfessionals)
    this.professionalGlowService.getListOfInstallers(data).subscribe(resp => {
      this.listOfProfessionals.push(resp.data)
      this.getLengthOfInstallers()
      // console.log(this.listOfProfessionals)
      this.pagination = resp.pagination
    }, err => {
      this.showError = true
      this.errorMessage = `No results for '${this.postcode}' `
    })

  }

  loadMore() {
    this.findInstallers()
  }

  getLengthOfInstallers() {
    this.count = 0
    this.listOfProfessionals.forEach(array => {
      this.count = this.count + array.length
    })
    return this.count
  }

  getRelatedArticles() {
    this.strapiService.getOneContent('articles', '28', ['authors']).then(resp => {
      this.relatedContent.push(resp)
      // console.log(resp)
    })
  }
}
