import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CaseStudyService } from 'src/app/services/case-study.service';
import { StrapiService } from 'src/app/services/strapi.service';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-case-study-card',
  templateUrl: './case-study-card.component.html',
  styleUrls: ['./case-study-card.component.scss'],
})
export class CaseStudyCardComponent implements OnInit {
  @Input() formattedCaseStudy: any = {};
  netCosts;
  annualSavings;
  preinstallHeatingSystemIconPath: string;
  postinstallHeatingSystemIconPath: string;
  formattedCaseStudyPhotoUrl;
  constructor(private strapiService: StrapiService, private caseStudyService: CaseStudyService, private httpClient: HttpClient) {}

  ngOnInit() {
    if (this.formattedCaseStudy) {
      this.caseStudyService.getImage(this.formattedCaseStudy, 'propertyImage').then(async (blob) => {
        if (!blob) {
          this.formattedCaseStudyPhotoUrl = await this.caseStudyService.getDefaultPropertyImage(this.formattedCaseStudy);
        } else {
      
          this.formattedCaseStudyPhotoUrl = await Utils.getBlobImageSource(blob);
        }
      });
    }
    if (this.formattedCaseStudy.outcome.heating_system) {
      this.netCosts = this.caseStudyService.calculateNetCosts(this.formattedCaseStudy.outcome.heating_system[0].finance.totalCost, this.formattedCaseStudy.outcome.heating_system[0].finance.awardedGrants);
    }
    this.annualSavings = this.calculateSavings(this.formattedCaseStudy.before.heating_system, this.formattedCaseStudy.after.heating_system);
    this.preinstallHeatingSystemIconPath = this.getHeatingSystemIconPath(this.formattedCaseStudy.preinstall_property_profile);
    this.postinstallHeatingSystemIconPath = this.getHeatingSystemIconPath(this.formattedCaseStudy.postinstall_property_profile);
  }

  getHeatingSystemIconPath(propertyProfile) {
    if (!propertyProfile) {
      return;
    }
    const heatingSystemType = propertyProfile.heating_system.type.trim().toLowerCase().split(' ').join('-');
    return `../../assets/images/case-study/${heatingSystemType}.svg`;
  }

  calculateSavings(beforeHeatingSystem, afterHeatingSystem) {
    const preinstallHeatingRunningCost = this.caseStudyService.calculateNetRunningCosts(beforeHeatingSystem);
    const postinstallHeatingRunningCost = this.caseStudyService.calculateNetRunningCosts(afterHeatingSystem);
    return preinstallHeatingRunningCost - postinstallHeatingRunningCost;
  }
}
