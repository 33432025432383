import { Component, OnInit } from '@angular/core';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-faqs-landing',
  templateUrl: './faqs-landing.component.html',
  styleUrls: ['./faqs-landing.component.scss'],
})
export class FaqsLandingComponent implements OnInit {
  isSearching: boolean = false;
  constructor(private seoService: SeoService) {}

  ngOnInit(): void {
    this.seoService.configureSEO(
      'Glowmarkt FAQs',
      'FAQs relating to data, apps, hardware and the heat pump community',
      'https://glowmarkt.com/faqs'
    );
  }

  ngOnDestroy() {
    this.seoService.resetSEOconfig();
  }

  updateIsSearching(isSearching) {
    this.isSearching = isSearching;
  }
}
