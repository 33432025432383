import { Component, HostListener, Input, OnInit } from '@angular/core';
import { StrapiService } from 'src/app/services/strapi.service';
import { Utils } from 'src/utils';

@Component({
  selector: 'app-tutorial-card',
  templateUrl: './tutorial-card.component.html',
  styleUrls: ['./tutorial-card.component.scss'],
})
export class TutorialCardComponent implements OnInit {
  innerWidth;
  @Input() tutorial;
  @Input() shouldDisplaySummary: boolean = true;
  @Input() shouldDisplayCommunityTag: boolean = false;
  @Input() titleStyle: any = {};
  @Input() backgroundStyle: any = {};
  @Input() titleLineHeight: any = {};
  @Input() displayTimeToRead: boolean = true;
  @HostListener('window:resize', []) onResize() {
    // checks size of window to view specific menu
    this.innerWidth = window.innerWidth;
  }

  timeToRead = 1;
  createdAt: string;
  // displayImageUrl = { 'background-image': 'url("../../../assets/images/soft-orange.jpg")' };
  displayImageUrl = "../../../assets/images/soft-orange.jpg"
  relativePath:string
  constructor(private strapiService:StrapiService) {}

  ngOnInit() {
    this.timeToRead=this.getTimeToRead()
    this.createdAt = this.getCreatedAt(this.tutorial)
    this.displayImageUrl=this.getDisplayImageUrl(this.tutorial)
    this.relativePath=this.getRelativePath()
    this.onResize();
  }

  getTimeToRead() {
    let totalMin = 1;
    this.tutorial.attributes.tutorial_sections.data.forEach((c: any) => {
      totalMin = totalMin + Utils.getTimeToRead(c.attributes.content);
    });
    return totalMin;
  }

  getCreatedAt(content) {
    return Utils.formatZDate(content.attributes.createdAt);
  }

  isBookmarked() {
    return this.strapiService.isContentBookmarked(this.tutorial.attributes.slug);
  }

  bookmarkContent() {
    this.strapiService.bookmarkContent(this.tutorial.attributes.slug, 'articles');
  }

  unbookmarkContent() {
    this.strapiService.unbookmarkContent(this.tutorial.attributes.slug);
  }


  getRelativePath(){
    return `/home/tutorials/${this.tutorial.attributes.slug}`
  }

  getDisplayImageUrl(content) {
    return this.strapiService.getSmallDisplayImageUrl(content);
  }
}
