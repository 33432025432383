import { Component, OnInit } from '@angular/core';
import { SchematicsService } from 'src/app/services/schematics.service';

@Component({
  selector: 'app-heating-by-room',
  templateUrl: './heating-by-room.component.html',
  styleUrls: ['./heating-by-room.component.scss'],
})
export class HeatingByRoomComponent implements OnInit {
  schematicDocument;
  schematic;
  loading = true;
  inProgressCount;
  inProgressDocumentId;
  inProgressPath;
  constructor(private schematicService: SchematicsService) {}

  ngOnInit() {
    this.getVariables().then(() => {
      this.loading = false;
    });
  }

  async getVariables() {
    await this.setDefaultSchematic();

    if (!this.schematicDocument) {
      await this.getInProgressSchematic();
      this.inProgressPath = this.inProgressDocumentId ? `/pages/tools/heating-by-room/schematic/${this.inProgressDocumentId}?edit=true` : '/pages/tools/heating-by-room/in-progress';
    }
  }

  async getInProgressSchematic() {
    this.inProgressCount = await this.schematicService.getSchematicsCountByStatus('in-progress');
    if (this.inProgressCount && this.inProgressCount == 1) {
      const inProgress: any = (await this.schematicService.getSchematicsByStatus('in-progress'))[0];
      this.inProgressDocumentId = inProgress.documentId;
    }
    return this.inProgressDocumentId;
  }

  async defaultSchematic() {
    const completed = await this.schematicService.getSchematicsByStatus('complete');
    //get latest completed schematic
    const latest = completed.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0];
    return latest;
  }

  async setDefaultSchematic() {
    const schematicDocument: any = await this.defaultSchematic();
    if (schematicDocument) {
      this.schematicDocument = schematicDocument;
      this.schematic = schematicDocument.data;
    }
  }
}
